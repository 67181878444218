import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class EventHandlerService {

    public keyDownHandlerAttached: boolean = false; // for undo redo

    getPastedDataFromHtmlTextTable(htmlText: string): string[] {
      let returnValues: string[] = [];
      const parser = new DOMParser();
      const htmlDoc = parser.parseFromString(htmlText, 'text/html');
      if (!htmlDoc) return returnValues;
      const tables = htmlDoc.getElementsByTagName('table');
      if (!tables || !tables.length) return returnValues;
      const table = tables[0];
      for (let r = 0, n = table.rows.length; r < n; r++) {
        for (let c = 0, m = table.rows[r].cells.length; c < m; c++) {
          returnValues.push((table.rows[r].cells[c].innerHTML || '').replace(/[^0-9.-]/g, '')); // remove all non numerals and -ve sign
        }
      }
      return returnValues;
    }
}
