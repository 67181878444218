import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class RAndDLyPercentCalculator extends AbstractCalculator {
    // Dependencies
    // Gross Sales LY 
    // Gross Sales 2 Ground
    // RAndDLY


    // Calculates
    // ----------
    // Ground R And D LY Percent Weekly

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();


        if (utils.isNotNullUndefinedOrZero(currentWeekItem.grossSalesLy) && utils.isNotNullUndefinedOrZero(currentWeekItem.rAndDLy))
            value = (currentWeekItem.rAndDLy / currentWeekItem.grossSalesLy);

        currentWeekItem[CalculationDataItemType.RAndDLyPercentWeekly] = value;
    }
}