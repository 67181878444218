import * as _ from 'lodash';
import { AggregatedGrossTaktCalculator } from 'src/app/modules/shared/calculators/aggregated-pms/aggregated-gross-takt-calculator';
import { AggregatedRAndDPercentCalculator } from 'src/app/modules/shared/calculators/aggregated-pms/aggregated-r-and-d-percent-calculator';
import { BoughtOfPlanCalculator } from 'src/app/modules/shared/calculators/buying/bought-of-plan-calculator';
import { CalculatorDriverInterface } from "src/app/modules/shared/calculators/calculator-driver-interface";
import { CalculatorInterface } from "src/app/modules/shared/calculators/calculator-interface";
import { CalculatorTimeSpan } from "src/app/modules/shared/calculators/calculator-timespan";
import { MarketMainSystemGoalCalculator } from 'src/app/modules/shared/calculators/main-system-goal/main-system-goal-calculator';
import { EffectiveSystemGoalPlanCalculator } from "src/app/modules/shared/calculators/others/effective-system-goal-plan-calculator";
import { ForecastGoalCalculator } from "src/app/modules/shared/calculators/others/forecast-goal-calculator";
import { SalesForecastCalculator } from "src/app/modules/shared/calculators/others/sales-forecast-calculator";
import { StockIndexGroundCalculator } from "src/app/modules/shared/calculators/others/stock-index-ground-calculator";
import { GroundRAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/ground-r-and-d-percent-calculator";
import { RAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-percent-calculator";
import { GroundSPercentCalculator } from "src/app/modules/shared/calculators/s-percent-calculators/ground-s-percent-calculator";
import { SPercentCalculator } from "src/app/modules/shared/calculators/s-percent-calculators/s-percent-calculator";
import { SalesSystemGoalCalculator } from 'src/app/modules/shared/calculators/sales-system-goal/sales-system-goal-calculator';
import { OutgoingStockPrognosisCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-forecast-calculator";
import { StockPlanFixedCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { GrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/gross-takt-calculator";
import { GroundGrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/ground-gross-takt-calculator";
import { NetTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/net-takt-calculator";
import { OmniTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/omni-takt-calculator";
import { SystemGoalCalculator } from "src/app/modules/shared/calculators/takt-calculators/system-goal-calculator";
import { DepartmentEventInterface } from "src/app/modules/shared/events/department-events/department-event-interface";
import { DialogBoxService } from "src/app/modules/shared/services/dialog-box-service";
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from "src/app/modules/shared/services/utils.service";
import { ChannelType } from "src/app/modules/shared/types/channel-type";
import { Constants } from "src/app/modules/shared/types/constants";
import { DriverType } from 'src/app/modules/shared/types/driver-type';
import { RetrievalMode } from "src/app/modules/shared/types/retrieval-mode";
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { SeasonPlanningType } from "src/app/modules/shared/types/season-planning-type";
import { SelectedOptions } from "src/app/modules/shared/types/selected-options";
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { WeekDataItem } from "src/app/modules/shared/types/week-data-item";
import { DepartmentPlanningViewDataResponse } from "../../../types/api/planning-view-data/department-planning-view-data-response";
import { CalculationDataItemType } from "../../../types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "../../../types/department-calculation-data-item";
import { DepartmentSaveItem } from "../../../types/department-save-item";
import { InputLockStatus } from "../../../types/input-lock-status-enum";
import { ReturnsInfo } from "../../../types/returns-info";
import { DepartmentCalculationDriver } from './department-calculation-driver';
import { SeasonDataAggregator } from "./season-data-aggregator";

export class TotalDepartmentCalculationDriver implements CalculatorDriverInterface {

    public touched: boolean = false;
    public rPercentDirty: boolean = false;
    public rowInputsDirty: boolean = false;

    // consolidated from 5 separate datasets
    private _aggregatedDataSet: DepartmentCalculationDataItem[] = null;

    private _seasonInfo: SeasonInfo = null;
    private _returnsInfo: ReturnsInfo = null;
    private _viewDate: Date = null;
    private _viewWeekWithYear: number = null;
    private _viewPeriodWeeksWithYear: number[] = null;
    private _utilsService: UtilsService = null;
    private _csPercent: number = null;
    // filtered into separate sets
    private _childDepartmentDrivers: CalculatorDriverInterface[] = [];
    public hasSalesAggregatedPmDataSetLoaded = false;
    public hasSalesSystemGoalInAssortmentLoaded = false;

    _dialogBoxService: DialogBoxService = null;

    private _title: string = null;

    // calculated data set from child drivers
    private _childDataSets: DepartmentCalculationDataItem[][] = [];

    private _calculatorConfiguration: CalculatorInterface[][] = null;
    private _retrievalMode: RetrievalMode = null;

    private _changeEventListeners: any[] = [];
    private _userConfigService: UserConfigService = null;

    selectedSeasons: string[] = null;

    constructor(userConfigService: UserConfigService, utilsService: UtilsService, dialogBoxService: DialogBoxService, selectedSeasons: string[]) {
        this._userConfigService = userConfigService;
        this._utilsService = utilsService;
        this._dialogBoxService = dialogBoxService;
        this.selectedSeasons = selectedSeasons;
    }
    getDriverType(): DriverType {
        return DriverType.DepartmentTotalSeasonDriver;
    }

    getInputLockStatus(): InputLockStatus {
        return InputLockStatus.Locked_Disabled;
    }
    getUserConfig(): UserConfig {
        return this._userConfigService.getUserConfig();
    }

    setInputLockStatus(lockStatus: InputLockStatus): void {
        throw new Error("Method not implemented.");
    }

    getPreviousAdjustedRPercent(): number {
        return null;
    }

    getPreviousRetrievalMode(): RetrievalMode {
        return null;
    }

    getRawDataItems(): WeekDataItem[] {
        return null;
    }

    getDataSetRange(startweek: number, endweek: number): DepartmentCalculationDataItem[] {

        let startWeekIndex = _.findIndex(this._aggregatedDataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == startweek
        });

        let endWeekIndex = _.findIndex(this._aggregatedDataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == endweek
        });

        return this._aggregatedDataSet.slice(startWeekIndex, endWeekIndex + 1);
    }


    getWeekDataItems(): WeekDataItem[] {
        throw new Error("Method not implemented.");
    }

    setWeekDataItems(weekDataItems: WeekDataItem[]) {
        throw new Error("Method not implemented.");
    }

    getPreviousInputItemValue(weekIndex: number, inputType: CalculationDataItemType) {
        throw new Error("Method not implemented.");
    }

    getPreviousDataSet(): DepartmentCalculationDataItem[] {
        throw new Error("Method not implemented.");
    }

    getTitle(areOldSeasonsExcluded?: boolean): string {
        return this._title;
    }

    getPreviousInputItems(): DepartmentCalculationDataItem[] {
        throw new Error("Method not implemented.");
    }

    getInitialInputItems(): DepartmentCalculationDataItem[] {
        throw new Error("Method not implemented.");
    }
    getUtilsService(): UtilsService {
        return this._utilsService;
    }
    getSeasonInfo(): SeasonInfo {
        return this._seasonInfo;
    }
    getDataSet(): DepartmentCalculationDataItem[] {
        return this._aggregatedDataSet;
    }
    setDataSet(dataSet: DepartmentCalculationDataItem[]) {
        this._aggregatedDataSet = dataSet;
    }
    getViewDate(): Date {
        return this._viewDate;
    }
    getRetrievalMode(): RetrievalMode {
        return this._retrievalMode;
    }
    setRetrievalMode(retrievalMode: RetrievalMode) {
        this._retrievalMode = retrievalMode;
    }
    getViewWeekWithYear(): number {
        return this._viewWeekWithYear
    }
    getViewPeriodWeeksWithYear(): number[] {
        return this._viewPeriodWeeksWithYear;
    }
    setCalculatorConfiguration(calculatorConfiguration: CalculatorInterface[][]) {
        this._calculatorConfiguration = calculatorConfiguration;
    }
    handleEvent(event: DepartmentEventInterface) {
        throw new Error("Method not implemented.");
    }
    getItemsForSave(): DepartmentSaveItem {
        throw new Error("Method not implemented.");
    }
    addChangeEventListener(changeHandler: any) {
        this._changeEventListeners.push(changeHandler);
    }
    initDataSet(rawDataItems: DepartmentPlanningViewDataResponse, seasonInfo: SeasonInfo, viewDate: Date) {
        throw new Error("Method not implemented.");
    }
    getReturnsInfo(): ReturnsInfo {
        return this._returnsInfo;
    }
    setReturnsInfo(returnsInfo: ReturnsInfo) {
        this._returnsInfo = returnsInfo
    }

    getCSPercent(): number {

        return this._csPercent;
    }


    setCSPercent(csPercent: number) {
        this._csPercent = csPercent
    }


    public refreshSeasonRawDataItem(selectedOptions: SelectedOptions) {
        throw new Error("Method not implemented.");
    }

    getDialogBoxService(): DialogBoxService {
        return this._dialogBoxService;
    }
    getChildDataSets(): DepartmentCalculationDataItem[][] {
        return this._childDataSets;
    }

    public calculate(): DepartmentCalculationDataItem[] {
        let pass = 0;
        this._calculatorConfiguration.forEach((calculators: CalculatorInterface[], pass: number) => {
            let currentWeekItem: DepartmentCalculationDataItem = null;
            let previousWeekItem: DepartmentCalculationDataItem = null;
            let periodIndexes = null;
            let currentPeriodWeekItems: DepartmentCalculationDataItem[] = null;

            let weekList = this._aggregatedDataSet.map(el => el.weekName);
            let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);

            uniqueWeekList.forEach((weekWithYear: number, index: number) => {
                currentWeekItem = this._aggregatedDataSet[index];
                previousWeekItem = (index == 0) ? null : this._aggregatedDataSet[index - 1];
                periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
                currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

                calculators.forEach((calculator: CalculatorInterface) => {
                    calculator.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
                });

                this._aggregatedDataSet[index][CalculationDataItemType.SystemGoalPeriodic] = this.calculateSystemGoalForAllSeasons(currentPeriodWeekItems);
                this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisWeekly] = this.calculateStockPrognosisWeeklyForAllSeasons(currentWeekItem);
                this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisPeriodic] = this.calculateStockPrognosisPeriodicForAllSeasons(currentPeriodWeekItems);
                this._aggregatedDataSet[index][CalculationDataItemType.SPercentWeekly] = this.calculateSPercentWeeklyForAllSeasons(currentWeekItem);
                this._aggregatedDataSet[index][CalculationDataItemType.StockPerSalesNetWeekly] = this.calculateStockPerSalesNetWeeklyForAllSeasons(currentWeekItem);
            });
        });

        return this._aggregatedDataSet;
    }

    public calculateForSingleSeason() {
        let currentWeekItem: DepartmentCalculationDataItem = null;
        let previousWeekItem: DepartmentCalculationDataItem = null;
        let periodIndexes = null;
        let currentPeriodWeekItems: DepartmentCalculationDataItem[] = null;

        let calcConfiguration: CalculatorInterface[] = [];

        calcConfiguration.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        let weekList = this._aggregatedDataSet.map(el => el.weekName);
        let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);

        uniqueWeekList.forEach((weekWithYear: number, index: number) => {
            currentWeekItem = this._aggregatedDataSet[index];
            previousWeekItem = (index == 0) ? null : this._aggregatedDataSet[index - 1];
            periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
            currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

            calcConfiguration.forEach(calc => {
                calc.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
            })

            this._aggregatedDataSet[index][CalculationDataItemType.SystemGoalPeriodic] = this.calculateSystemGoalForAllSeasons(currentPeriodWeekItems);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisWeekly] = this.calculateStockPrognosisWeeklyForAllSeasons(currentWeekItem);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisPeriodic] = this.calculateStockPrognosisPeriodicForAllSeasons(currentPeriodWeekItems);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPerSalesNetWeekly] = this.calculateStockPerSalesNetWeeklyForAllSeasons(currentWeekItem);
        });
    }


    calculateSPercentWeeklyForAllSeasons(currentWeekItem: DepartmentCalculationDataItem): number {

        let value = 0;
        let valueX = 0;
        let valueY = 0;

        this._childDepartmentDrivers.forEach(driver => {

            if (this.isSeasonValid(driver.getSeasonInfo().seasonCodeNames[0])) {

                let seasonPlanningType = driver.getSeasonInfo().seasonPlanningType;
                let childDepartmentItem = driver.getDataSetRange(currentWeekItem[CalculationDataItemType.WeekName], currentWeekItem[CalculationDataItemType.WeekName])[0];
                let retrievalMode = driver.getRetrievalMode();

                switch (seasonPlanningType) {
                    case SeasonPlanningType.PreviousWithOld:
                    case SeasonPlanningType.Previous:
                        valueX += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                        valueY += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly] + childDepartmentItem[CalculationDataItemType.OutgoingStockPrognosisWeekly];
                        break;
                    case SeasonPlanningType.Actual:
                        if (retrievalMode == RetrievalMode.Plan) {

                            valueX += currentWeekItem[CalculationDataItemType.CubeDemDelPlan] - (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] - currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]);

                            let childDataSet = driver.getDataSet();

                            let addRemoveSum = 0;
                            let addMovesSum = 0;
                            let cubeDemDelPlanSum = 0;
                            let combinedSalesPlanSum = 0;

                            childDataSet.forEach(childDataSetItem => {
                                if (childDataSetItem[CalculationDataItemType.WeekName] <= currentWeekItem[CalculationDataItemType.WeekName]) {
                                    addRemoveSum += childDataSetItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK;
                                    addMovesSum += childDataSetItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK;
                                    cubeDemDelPlanSum += childDataSetItem[CalculationDataItemType.CubeDemDelPlan];
                                    combinedSalesPlanSum += childDataSetItem[CalculationDataItemType.CombinedSalesPlanWeekly];

                                }
                            });

                            valueY += currentWeekItem[CalculationDataItemType.CubeDemDelPlan] -
                                (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] -
                                    currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) +
                                currentWeekItem[CalculationDataItemType.StockPlanFixed] +
                                addRemoveSum + addMovesSum + cubeDemDelPlanSum - combinedSalesPlanSum;
                        }
                        else {
                            valueX += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                            // Plan mode not allowed in Actual
                            valueY += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly] + childDepartmentItem[CalculationDataItemType.OutgoingStockPrognosisWeekly];
                        }
                        break;
                    case SeasonPlanningType.Coming:
                    case SeasonPlanningType.Future:

                        let channelType = driver.getUserConfig().planningViewOptions.channel;
                        valueX += (channelType == ChannelType.Online) ? childDepartmentItem[CalculationDataItemType.SalesForecastWeekly] : childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];

                        if (retrievalMode == RetrievalMode.Plan) {
                            // retrieve the value by inverting the formula
                            valueY += (channelType == ChannelType.Online) ? childDepartmentItem[CalculationDataItemType.SalesForecastWeekly] : childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                            valueY += childDepartmentItem[CalculationDataItemType.StockPlanFixed];

                            let childDataSet = driver.getDataSet();

                            let addRemoveSum = 0;
                            let addMovesSum = 0;
                            let cubeDemDelPlanSum = 0;
                            let combinedSalesPlanSum = 0;

                            childDataSet.forEach(childDataSetItem => {
                                if (childDataSetItem[CalculationDataItemType.WeekName] <= currentWeekItem[CalculationDataItemType.WeekName]) {
                                    addRemoveSum += childDataSetItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK;
                                    addMovesSum += childDataSetItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK;
                                    cubeDemDelPlanSum += childDataSetItem[CalculationDataItemType.CubeDemDelPlan];
                                    combinedSalesPlanSum += childDataSetItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                                }
                            });

                            valueY += addRemoveSum + addMovesSum + cubeDemDelPlanSum - combinedSalesPlanSum;
                        }
                        else {
                            valueY += (channelType == ChannelType.Online) ? childDepartmentItem[CalculationDataItemType.SalesForecastWeekly] : childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                            valueY += childDepartmentItem[CalculationDataItemType.OutgoingStockPrognosisWeekly];
                        }

                        break;
                }
            }
        });

        value = this._utilsService.safeDivide(valueX, valueY) * 100;

        return value;
    }

    calculateSystemGoalForAllSeasons(currentPeriodWeekItems: DepartmentCalculationDataItem[]) {

        let cubeDemDelPlanSum = 0;
        let grossSalesGroundSum = 0;

        this._childDepartmentDrivers.forEach(driver => {

            if (this.isSeasonValid(driver.getSeasonInfo().seasonCodeNames[0])) {
                let seasonInfo = driver.getSeasonInfo();

                let periodDataSet = driver.getDataSetRange(currentPeriodWeekItems[0].weekName, currentPeriodWeekItems[currentPeriodWeekItems.length - 1].weekName);

                let startWeekForCurrentSeason = this._utilsService.getStartWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));

                if (seasonInfo.seasonPlanningType == SeasonPlanningType.Coming || seasonInfo.seasonPlanningType == SeasonPlanningType.Future) {
                    if (periodDataSet[0][CalculationDataItemType.WeekName] >= startWeekForCurrentSeason) {
                        cubeDemDelPlanSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.EffectiveSystemGoalPlanWeekly);
                        grossSalesGroundSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.GrossSalesGround);
                    }
                }
                else {
                    cubeDemDelPlanSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.EffectiveSystemGoalPlanWeekly);
                    grossSalesGroundSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.GrossSalesGround);
                }
            }
        });
        return this._utilsService.safeDivide(cubeDemDelPlanSum, grossSalesGroundSum) * 100;
    }

    calculateStockPerSalesNetWeeklyForAllSeasons(currentWeekItem: DepartmentCalculationDataItem) {

        let effectiveOutgoingStockPrognosisSum = 0;
        let combinedNetSalesWeeklySum = 0;
        let _rawWeekDataItems: WeekDataItem[] = [];
        let weekName = currentWeekItem[CalculationDataItemType.WeekName];

        let combinedReturnsSum = 0;

        let salesNetSum = 0;
        let currentWeek = this.getUtilsService().getWeekNumber(true, null);

        let channelType = this._childDepartmentDrivers[0].getUserConfig().planningViewOptions.channel;


        this._childDataSets.forEach(driver => {
            _rawWeekDataItems.push(_.filter(driver, {
                'weekName': currentWeekItem.weekName,
            }));

            //Add data item for Stock Per Sales Wk51
            //Get the index of the week 
            let index = driver.findIndex(x => x.weekName == weekName);

            //Get the data item that are availalbe in the objects 
            let startWeekIndex = 0;
            if (index > 51) {
                startWeekIndex = index - 51;
            }
            else {
                startWeekIndex = 0;
            }
            for (var i = index; i > startWeekIndex; i--) {
                let weekItem = driver[i - 1];
                if (this._seasonInfo.weeksWithYear.indexOf(weekItem[CalculationDataItemType.WeekName]) > -1) {
                    salesNetSum += weekItem[CalculationDataItemType.CombinedNetSalesWeekly];
                    if (channelType != ChannelType.Store) {
                        let returnMetric =  weekItem[CalculationDataItemType.WeekName] < currentWeek  ?
                                            weekItem[CalculationDataItemType.ReturnsGross] : weekItem[CalculationDataItemType.PlannedReturn];
                        salesNetSum -= (returnMetric *
                            this._utilsService.safeDivide(weekItem[CalculationDataItemType.CombinedNetSalesWeekly], weekItem[CalculationDataItemType.CombinedSalesPlanWeekly]));
                    }
                }
                else if (currentWeek > weekItem[CalculationDataItemType.WeekName]) {
                    salesNetSum += (weekItem[CalculationDataItemType.GrossSales] - weekItem[CalculationDataItemType.RAndD]);
                    if (channelType != ChannelType.Store) {
                        salesNetSum -= (weekItem[CalculationDataItemType.ReturnsGross] *
                            this._utilsService.safeDivide(weekItem[CalculationDataItemType.CombinedNetSalesWeekly], weekItem[CalculationDataItemType.CombinedSalesPlanWeekly]));
                    }
                }
                else {
                    salesNetSum += currentWeekItem[CalculationDataItemType.CombinedNetSalesWeekly];
                    if (channelType != ChannelType.Store) {
                        salesNetSum -= (currentWeekItem[CalculationDataItemType.PlannedReturn] *
                            this._utilsService.safeDivide(weekItem[CalculationDataItemType.NetSalesGround], weekItem[CalculationDataItemType.GrossSalesGround]));
                    }
                }
            }
        });

        _rawWeekDataItems.forEach(driver => {

            effectiveOutgoingStockPrognosisSum += driver[0][CalculationDataItemType.EffectiveOutgoingStockPrognosis];

            combinedNetSalesWeeklySum += driver[0][CalculationDataItemType.CombinedNetSalesWeekly];

            if (channelType != ChannelType.Store) {
                let returnValue =  driver[0][CalculationDataItemType.WeekName] < currentWeek  ?
                                driver[0][CalculationDataItemType.ReturnsGross] : driver[0][CalculationDataItemType.PlannedReturn];
                combinedReturnsSum += returnValue * this._utilsService.safeDivide(driver[0][CalculationDataItemType.CombinedNetSalesWeekly],
                                         driver[0][CalculationDataItemType.CombinedSalesPlanWeekly]);
            }
        });

        return this._utilsService.safeDivide(effectiveOutgoingStockPrognosisSum,
                 (combinedNetSalesWeeklySum - combinedReturnsSum + salesNetSum)) * 100;

    }

    calculateStockPrognosisWeeklyForAllSeasons(currentWeekItem: DepartmentCalculationDataItem) {

        let effectiveOutgoingStockPrognosisSum = 0;
        let stockGroundSum = 0;
        let _rawWeekDataItems: WeekDataItem[] = [];

        this._childDataSets.forEach(driver => {
            _rawWeekDataItems.push(_.filter(driver, {
                'weekName': currentWeekItem.weekName,
            }));
        });

        _rawWeekDataItems.forEach(driver => {

            effectiveOutgoingStockPrognosisSum += driver[0][CalculationDataItemType.EffectiveOutgoingStockPrognosis];

            stockGroundSum += this._utilsService.isNotNullUndefinedOrZero(driver[0][CalculationDataItemType.StockGround]) ? driver[0][CalculationDataItemType.StockGround] : driver[0][CalculationDataItemType.StockPrognosisLy];
        });

        return this._utilsService.safeDivide(effectiveOutgoingStockPrognosisSum, stockGroundSum) * 100;

    }

    calculateStockPrognosisPeriodicForAllSeasons(currentPeriodWeekItems: DepartmentCalculationDataItem[]) {

        let effectiveOutgoingStockPrognosisSum = 0;
        let stockGroundSum = 0;

        this._childDepartmentDrivers.forEach(driver => {

            if (this.isSeasonValid(driver.getSeasonInfo().seasonCodeNames[0])) {
                let periodDataSet = driver.getDataSetRange(currentPeriodWeekItems[0].weekName, currentPeriodWeekItems[currentPeriodWeekItems.length - 1].weekName);

                effectiveOutgoingStockPrognosisSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.EffectiveOutgoingStockPrognosis);
                periodDataSet.forEach(item => {
                    stockGroundSum += this._utilsService.isNotNullUndefinedOrZero(item[CalculationDataItemType.StockGround]) ? item[CalculationDataItemType.StockGround] : item[CalculationDataItemType.StockPrognosisLy];
                });


            }
        });
        let value = this._utilsService.safeDivide(effectiveOutgoingStockPrognosisSum, stockGroundSum) * 100
        return value;
    }

    initTotalDataSet(childDeparmentCalculationDrivers: CalculatorDriverInterface[], seasonInfo: SeasonInfo, viewDate: Date) {

        this._seasonInfo = seasonInfo;
        this._viewDate = viewDate;
        this._viewWeekWithYear = this._utilsService.getWeekNumber(true, this._viewDate);
        this._viewPeriodWeeksWithYear = this._utilsService.getWeeksForPeriod(this._viewDate);
        this._title = "All Seasons";
        // default retrieval mode for Total
        this._retrievalMode = RetrievalMode.Bought;

        // get access to each child driver
        this._childDepartmentDrivers = childDeparmentCalculationDrivers;

        this.calculateAggregatedDataset();

        this._configureCalculatorsForInitialLoad();
    }

    refreshTotalDataSet(seasonsList: string[]) {

        this.selectedSeasons = seasonsList;

        this._childDataSets = [];

        this.calculateAggregatedDataset();

        this._configureCalculatorsForInitialLoad();

        if (this._childDataSets.length == 1) {
            this.setDataSet(this._childDataSets[0]);
            this.calculateForSingleSeason();
        }
        else {
            this.calculate();
        }

        this._changeEventListeners.forEach(changeListener => {
            changeListener();
        });

        // check if all of the child drivers have the PM sales value populated  
        let salesPmloaded = true;
        this._childDepartmentDrivers.forEach((childDriver: DepartmentCalculationDriver) => {
            salesPmloaded = salesPmloaded && childDriver.hasSalesAggregatedPmDataSetLoaded;
        });

        this.hasSalesAggregatedPmDataSetLoaded = salesPmloaded;

        // check if all of the child drivers have the Sales Goal in Assortment Loaded
        let salesGoalInAssortmentLoaded = true;
        this._childDepartmentDrivers.forEach((childDriver: DepartmentCalculationDriver) => {
            salesGoalInAssortmentLoaded = salesGoalInAssortmentLoaded && childDriver.hasSalesSystemGoalInAssortmentLoaded;
        });

        this.hasSalesSystemGoalInAssortmentLoaded = salesGoalInAssortmentLoaded;
    }

    async calculateAggregatedDataset() {
        // get all datasets from the child drivers
        // assuming them to be calculated at this point, since total driver is called last
        this._childDepartmentDrivers.forEach((childDriver: CalculatorDriverInterface) => {

            if (this.isSeasonValid(childDriver.getSeasonInfo().seasonCodeNames[0])) {
                let dataset = childDriver.getDataSet();
                this._childDataSets.push(_.cloneDeep(dataset));
            }
        });

        // insert an empty data set
        if (this._childDataSets.length == 0) {
            this._childDataSets.push(this.generateEmptyDataSet());
        }

        // aggregate individual seasons into one
        let fields = this._getAggregationFields();

        this._aggregatedDataSet = SeasonDataAggregator.aggregate(this._childDataSets, fields, this._seasonInfo, this._utilsService);
    }

    public configureCalculatorsForClear() {
        this._configureCalculatorsForInitialLoad();
    }

    private _configureCalculatorsForInitialLoad() {
        this._calculatorConfiguration = [];

        // ============= //

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        pass1Calculators.push(new SalesForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Stock Plan Fixed Correction - Weekly
        pass1Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, this))
        // Gross takt ground - Weekly
        pass1Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // Gross takt - weekly
        pass1Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective System Goal Plan - Weekly
        pass1Calculators.push(new EffectiveSystemGoalPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // R&D% Ground - Weekly
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // R&D% Ground - Periodic
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // Stock Index Ground - Periodic
        pass1Calculators.push(new StockIndexGroundCalculator(CalculatorTimeSpan.Periodic, this))
        // Forecast goal - Periodic
        pass1Calculators.push(new ForecastGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass1Calculators);

        // ============= //

        // // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // System Goal - Periodic
        pass2Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // Gross takt ground - Periodic
        pass2Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // Ground S percent- Weekly
        pass2Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // // load pass calculators
        this._calculatorConfiguration.push(pass2Calculators);

        // ============= //

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];
        // Gross takt - Periodic
        pass3Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // Omni takt - Periodic
        pass3Calculators.push(new OmniTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // R&D% - Periodic
        pass3Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // Ground S percent- Periodic
        pass3Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass3Calculators);

        // ============= //

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];

        // R&D% - Weekly
        pass4Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // Stock Forecast - Weekly
        pass4Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Outgoing Stock Prognosis - Weekly
        pass4Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass4Calculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];

        // Net takt - weekly
        pass5Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Weekly, this))
        // Net takt - Periodic
        pass5Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, this))


        // load pass calculators
        this._calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];
        // S Percent- Period
        pass6Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, this))

        this._calculatorConfiguration.push(pass6Calculators);


        // pass 7
        let pass7Calculators: CalculatorInterface[] = [];
        pass7Calculators.push(new AggregatedGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        pass7Calculators.push(new AggregatedRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        this._calculatorConfiguration.push(pass7Calculators);

        // pass 8
        let pass8Calculators: CalculatorInterface[] = [];
        pass8Calculators.push(new MarketMainSystemGoalCalculator(CalculatorTimeSpan.Periodic, this));
        pass8Calculators.push(new SalesSystemGoalCalculator(CalculatorTimeSpan.Periodic, this));
        pass8Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, this));

        this._calculatorConfiguration.push(pass8Calculators);

        // ============= //
    }

    private _getAggregationFields(): CalculationDataItemType[] {
        return this._utilsService.getAllCalculationDataItemTypes();
    }

    private _getDataSetItemsByIndexes(indexes: number[]): DepartmentCalculationDataItem[] {
        let dataSetItems: DepartmentCalculationDataItem[] = [];
        indexes.forEach(i => {
            dataSetItems.push(this._aggregatedDataSet[i]);
        });

        return dataSetItems;
    }

    isSeasonValid(seasonName: string) {
        let isValid = true;
        if (this.selectedSeasons) {
            isValid = this.selectedSeasons.filter(x => x.indexOf(seasonName) != -1).length == 1;
        }
        return isValid;
    }

    generateEmptyDataSet() {
        // create an empty dataset based on the data present in the first child driver

        let emptyDataSet: DepartmentCalculationDataItem[] = _.cloneDeep(this._childDepartmentDrivers[0].getDataSet());


        for (let i = 0; i < emptyDataSet.length; i++) {
            for (let property in emptyDataSet[i]) {
                if (property != "weekName" && property != "seasonName") {
                    emptyDataSet[i][property] = 0;
                }
            }
        }

        return emptyDataSet;
    }

    setSalesAggregatedPmDataSet(salesAggregatedDataItems: WeekDataItem[]) {
        throw new Error("Method not implemented.");
    }

    setSalesSystemGoalInAssortmentDataSet(): void {
        throw new Error("Method not implemented.");
    }
}
