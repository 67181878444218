import * as _ from 'lodash';
import { BoughtOfPlanCalculator } from 'src/app/modules/shared/calculators/buying/bought-of-plan-calculator';
import { CalculatorInterface } from "src/app/modules/shared/calculators/calculator-interface";
import { CalculatorTimeSpan } from 'src/app/modules/shared/calculators/calculator-timespan';
import { CSPercentCalculator } from 'src/app/modules/shared/calculators/cs-percent/cs-percent-calculator';
import { AccShrinkLyCalculator } from 'src/app/modules/shared/calculators/others/acc-shrink-ly-calculator';
import { ShrinkPercentLyCalculator } from 'src/app/modules/shared/calculators/others/shrink-percent-ly-calculator';
import { EffectiveRAndDPlanCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/effective-r-and-d-plan-calculator';
import { RAndDPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-percent-calculator';
import { ReadonlyDepartmentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/readonly-department-calculator-driver-interface';
import { SystemGoalCalculator } from 'src/app/modules/shared/calculators/takt-calculators/system-goal-calculator';
import { DialogBoxService } from "src/app/modules/shared/services/dialog-box-service";
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { DriverType } from 'src/app/modules/shared/types/driver-type';
import { RetrievalMode } from "src/app/modules/shared/types/retrieval-mode";
import { SeasonDataItem } from "src/app/modules/shared/types/season-data-item";
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { SelectedOptions } from "src/app/modules/shared/types/selected-options";
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { ReadonlyDepartmentPlanningViewDataResponse } from '../../../types/api/planning-view-data/readonly-department-planning-view-data-response';
import { CalculationDataItemType } from '../../../types/calculation-data-item-type';
import { InputLockStatus } from '../../../types/input-lock-status-enum';
import { ReadonlyDepartmentCalculationDataItem } from '../../../types/readonly-department-calculation-data-item';
import { ReturnsInfo } from "../../../types/returns-info";
import { OrganizationType } from 'src/app/modules/shared/types/organization-type';

export class ReadonlyDepartmentCalculationDriver implements ReadonlyDepartmentCalculatorDriverInterface {
    private _title: string = null;
    private _dataSet: ReadonlyDepartmentCalculationDataItem[] = [];
    private _seasonInfo: SeasonInfo = null;
    private _viewDate: Date = null;
    private _retrievalMode: RetrievalMode = null;
    private _returnsInfo: ReturnsInfo = null;
    private _utilsService: UtilsService = null;
    dialogBoxService: DialogBoxService = null;
    private _viewWeekWithYear: number = null;
    private _viewPeriodWeeksWithYear: number[] = null;
    private _changeEventListeners: any[] = [];
    private _childDataSets: ReadonlyDepartmentCalculationDataItem[][] = [];
    // consolidated from 5 separate datasets
    private _aggregatedDataSet: ReadonlyDepartmentCalculationDataItem[] = null;
    private _calculatorConfiguration: CalculatorInterface[][] = null;
    _dialogBoxService: DialogBoxService = null;
    // filtered into separate sets
    private _childParentDrivers: ReadonlyDepartmentCalculationDriver[] = [];
    private _csPercent: number = null;

    public hasSalesAggregatedPmDataSetLoaded = false;
    public hasSalesSystemGoalInAssortmentLoaded = false;

    private _userConfigService: UserConfigService = null;


    constructor(userConfigService: UserConfigService, utilsService: UtilsService, dialogBoxService: DialogBoxService) {
        this._userConfigService = userConfigService;
        this._utilsService = utilsService;
        this._dialogBoxService = dialogBoxService;
    }
    getDriverType(): DriverType {
        return DriverType.ReadonlySeasonDriver;
    }

    getInputLockStatus(): InputLockStatus {
        return InputLockStatus.Locked;
    }

    setInputLockStatus(lockStatus: InputLockStatus): void {
        throw new Error("Method not implemented.");
    }
    setReturnsInfo(returnsInfo: ReturnsInfo): void {
        throw new Error('Method not implemented.');
    }

    getCSPercent(): number {
        return this._csPercent;
    }
    setCSPercent(csPercent: number) {
        this._csPercent = csPercent
    }


    getDataSetRange(startweek: number, endweek: number): ReadonlyDepartmentCalculationDataItem[] {

        let startWeekIndex = _.findIndex(this._dataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == startweek
        });

        let endWeekIndex = _.findIndex(this._dataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == endweek
        });

        return this._dataSet.slice(startWeekIndex, endWeekIndex + 1);
    }

    getRawDataItems() {
        return null;
    }

    getTitle(areOldSeasonsExcluded?: boolean): string {
        if (this._seasonInfo.seasonCodeNames.length == 1) {
            return this._title;
        }
        else if (this._seasonInfo.seasonCodeNames.length > 1) {
            return "<=" + this._seasonInfo.seasonCodeNames[0] + "(" + this._seasonInfo.seasonCodeNames.slice(1).join(', ') + ")";
        }

    }
    getUtilsService(): UtilsService {
        return this._utilsService;
    }
    getSeasonInfo(): SeasonInfo {
        return this._seasonInfo;
    }
    getDataSet(): ReadonlyDepartmentCalculationDataItem[] {
        return this._dataSet;
    }
    setDataSet(dataSet: ReadonlyDepartmentCalculationDataItem[]) {
        this._dataSet = dataSet;
    }
    getViewDate(): Date {
        return this._viewDate;
    }
    setRetrievalMode(retrievalMode: RetrievalMode) {
        this._retrievalMode = retrievalMode;
    }
    getRetrievalMode(): RetrievalMode {
        return this._retrievalMode;
    }
    getViewPeriodWeeksWithYear(): number[] {
        return this._viewPeriodWeeksWithYear;
    }
    getViewWeekWithYear(): number {
        return this._viewWeekWithYear;
    }
    setCalculatorConfiguration(calculatorConfiguration: CalculatorInterface[][]) {
        this._calculatorConfiguration = calculatorConfiguration;
    }
    refreshTotalDataSet() {
        throw new Error("Method not implemented.");
    }

    getReturnsInfo(): ReturnsInfo {
        return this._returnsInfo;
    }
    getUserConfig(): UserConfig {
        return this._userConfigService.getUserConfig();
    }

    getChildDataSets(): ReadonlyDepartmentCalculationDataItem[][] {
        throw new Error("Method not implemented.");
    }

    initDataSet(readonlyDepartmentPlanningViewDataResponse: ReadonlyDepartmentPlanningViewDataResponse, seasonInfo: SeasonInfo, viewDate: Date, selectedOptions: SelectedOptions) {
        this._dataSet = readonlyDepartmentPlanningViewDataResponse.weekData;
        this._seasonInfo = seasonInfo;
        this._viewDate = viewDate;
        this._viewWeekWithYear = this._utilsService.getWeekNumber(true, this._viewDate);
        this._viewPeriodWeeksWithYear = this._utilsService.getWeeksForPeriod(this._viewDate);
        this._setTitle();
        this._setDefaultRetrievalModes();
        this._configureReturnsInfo(readonlyDepartmentPlanningViewDataResponse.seasonData);

        //configure calculators
        this._configureCalculatorsForInitialLoad();
    }
    public addChangeEventListener(changeHandler: any) {
        this._changeEventListeners.push(changeHandler);
    }
    public handleEvent() {
        this.calculate();
        // trigger change event
        this._changeEventListeners.forEach(changeListener => {
            changeListener();
        });
    }

    calculate() {

        this._calculatorConfiguration.forEach((calculators: CalculatorInterface[], pass: number) => {

            let currentWeekItem: ReadonlyDepartmentCalculationDataItem = null;
            let previousWeekItem: ReadonlyDepartmentCalculationDataItem = null;
            let periodIndexes = null;
            let currentPeriodWeekItems: ReadonlyDepartmentCalculationDataItem[] = null;
            let weekList = this._dataSet.map(el => el.weekName);
            let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);

            uniqueWeekList.forEach((weekWithYear: number, index: number) => {
                calculators.forEach((calculator: CalculatorInterface) => {
                    // if its a weekly calculator - run the calculator for each week 
                    // if its a periodic calculator - run the calculator for every 4th week 
                    if (calculator.timespan == CalculatorTimeSpan.Weekly ||
                        (calculator.timespan == CalculatorTimeSpan.Periodic && index % 4 == 0) ||
                        (calculator.timespan == CalculatorTimeSpan.Seasonal && index == uniqueWeekList.length - 1)) {
                        currentWeekItem = this._dataSet[index];
                        previousWeekItem = (index == 0) ? null : this._dataSet[index - 1];
                        periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
                        currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

                        calculator.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
                    }
                });
            });
        });
    }

    private _getDataSetItemsByIndexes(indexes: number[]): ReadonlyDepartmentCalculationDataItem[] {
        let dataSetItems: ReadonlyDepartmentCalculationDataItem[] = [];
        indexes.forEach(i => {
            dataSetItems.push(this._dataSet[i]);
        });

        return dataSetItems;
    }

    private _configureReturnsInfo(seasonDataItems: SeasonDataItem[]) {

        if (seasonDataItems.length != 0) {
            let seasonDataItem: SeasonDataItem;
            //if its department level then find the data for department else check condition 
            if (this.getUserConfig().planningViewOptions.structureType.startsWith(StructureTypes.Department)) {
                seasonDataItem = _.find(seasonDataItems, (x: SeasonDataItem) => (x.departmentIntegrationKey != 0 || x.departmentIntegrationKey != null));
            }
            else {
                seasonDataItem = _.find(seasonDataItems, (x: SeasonDataItem) => (x.departmentIntegrationKey === 0 || x.departmentIntegrationKey === null));
            }

            if (this._utilsService.isNotNullOrUndefined(seasonDataItem)) {
                let returnInfo: ReturnsInfo = {
                    unAdjustedReturnPercent: this._utilsService.roundNumber(seasonDataItem.unAdjustedReturnPercent, 2),
                    adjustedReturnPercent: seasonDataItem.adjustedReturnPercent == null ? null : this._utilsService.roundNumber(seasonDataItem.adjustedReturnPercent, 2),
                    unAdjustedBudgetedReturnPercent: this._utilsService.roundNumber(seasonDataItem.unAdjustedBudgetedReturnPercent, 2),
                    adjustedBudgetedReturnPercent: seasonDataItem.adjustedBudgetedReturnPercent == null ? null : this._utilsService.roundNumber(seasonDataItem.adjustedBudgetedReturnPercent, 2),
                    seasonName: seasonDataItems[0].seasonName,

                    departmentIntegrationKey: seasonDataItem.departmentIntegrationKey,
                    marketIntegrationKey: seasonDataItem.marketIntegrationKey,
                    marketType: seasonDataItem.marketType,
                    returnDistribution: seasonDataItems[0].returnDistribution,
                    returnFactorPercent: seasonDataItem.returnFactorPercent,
                    sumOfBudgetedReturns: seasonDataItem.sumOfBudgetedReturns,
                    sumOfDemDelFixed: seasonDataItem.sumOfDemDelFixed,
                    sumOfDemDelPlan: seasonDataItem.sumOfDemDelPlan,
                    sumOfReturnFixed: seasonDataItem.sumOfReturnFixed,
                    sumOfReturnPlan: seasonDataItem.sumOfReturnPlan,
                    sumOfDemDelPlanBeforeKPeriod: seasonDataItem.sumOfDemDelPlanBeforeKPeriod,
                    sumOfUnAdjustedBudgetedReturns: seasonDataItem.sumOfUnAdjustedBudgetedReturns
                }
                this._returnsInfo = returnInfo;
            }
            else {
                let aggregatedInfo: ReturnsInfo = seasonDataItems.reduce((a: SeasonDataItem, b: SeasonDataItem) => ({
                    unAdjustedReturnPercent: a.unAdjustedReturnPercent + b.unAdjustedReturnPercent,
                    unAdjustedBudgetedReturnPercent: a.unAdjustedBudgetedReturnPercent + b.unAdjustedBudgetedReturnPercent,
                    seasonName: a.seasonName + b.seasonName
                }))
                this._returnsInfo = aggregatedInfo;
            }
        }
        else {
            this._returnsInfo = new ReturnsInfo();
        }

    }
    private _setDefaultRetrievalModes() {

        let isAssortmentCopy = this._userConfigService.getUserConfig().selectionViewOptions.organization.name == OrganizationType.Assortment;

        this._retrievalMode = this._utilsService.getDefaultRetrievalMode(this._seasonInfo, isAssortmentCopy);
    }

    private _setTitle() {
        this._title = this._seasonInfo.seasonCodeNames.join(', ');
    }

    public configureCalculatorsForClear() {
        this._configureCalculatorsForInitialLoad();
    }

    private _configureCalculatorsForInitialLoad() {
        this._calculatorConfiguration = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];
        // System Goal - Periodic
        pass1Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // Effective Sales Plan - Weekly
        pass1Calculators.push(new EffectiveRAndDPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // R&D% - Periodic
        pass1Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        pass1Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, this)) 

        // CS Percent - Season
        pass1Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, this))    

        // load pass calculators
        this._calculatorConfiguration.push(pass1Calculators);
    }

    setSalesAggregatedPmDataSet(salesAggregatedDataItems:ReadonlyDepartmentCalculationDataItem[])
    {

        this._dataSet.forEach((readonlyDepartmentCalculationDataItem: ReadonlyDepartmentCalculationDataItem) => {

            var weekItemRecord: ReadonlyDepartmentCalculationDataItem = _.find(salesAggregatedDataItems, (x: ReadonlyDepartmentCalculationDataItem) => x.weekName == readonlyDepartmentCalculationDataItem.weekName);
            if(weekItemRecord != null ) {
                //main copy system goal 
                readonlyDepartmentCalculationDataItem.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;

                readonlyDepartmentCalculationDataItem.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                readonlyDepartmentCalculationDataItem.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;

                readonlyDepartmentCalculationDataItem.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                readonlyDepartmentCalculationDataItem.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                readonlyDepartmentCalculationDataItem.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
            }

        });
        this.hasSalesAggregatedPmDataSetLoaded = true;
    }

    setSalesSystemGoalInAssortmentDataSet(salesGoalInAssortmentDataItems:ReadonlyDepartmentCalculationDataItem[]): void
    {
        this._dataSet.forEach((readonlyDepartmentCalculationDataItem: ReadonlyDepartmentCalculationDataItem) => {

            var weekItemRecord: ReadonlyDepartmentCalculationDataItem = _.find(salesGoalInAssortmentDataItems, (x: ReadonlyDepartmentCalculationDataItem) => x.weekName == readonlyDepartmentCalculationDataItem.weekName);

            if(weekItemRecord != null ) {
                readonlyDepartmentCalculationDataItem.salesMainCopyCombinedSalesPlanWeekly = weekItemRecord.salesMainCopyCombinedSalesPlanWeekly
                readonlyDepartmentCalculationDataItem.salesMainCopyGrossSalesGround = weekItemRecord.salesMainCopyGrossSalesGround
                readonlyDepartmentCalculationDataItem.salesMainCopySystemGoal = weekItemRecord.salesMainCopySystemGoal;
            }

        });

        this.hasSalesSystemGoalInAssortmentLoaded = true;
    }
}
