import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class PopulateRAndDForecastInputCellCalculator extends AbstractCalculator {
    // Dependencies
    // Effective Sales Plan Weekly 
    // R&D Plan weeks 

    // Calculates

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = null;
        let demDelSum = 0;
        let rAndDPlanSum = null;
        var utils = this.calculatorDriver.getUtilsService();

        // iterate over each item of the period
        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            if (utils.isNotNullOrUndefined(periodWeekItem[CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround])) {
                demDelSum += periodWeekItem[CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround];
                if (periodWeekItem[CalculationDataItemType.RAndDPlan] != null)
                    rAndDPlanSum += periodWeekItem[CalculationDataItemType.RAndDPlan];
            }
        });

        if (utils.isNotNullOrUndefined(demDelSum) && utils.isNotNullOrUndefined(rAndDPlanSum)) {
            if (demDelSum != 0) {
                // removed rounding
                //value = Math.round((rAndDPlanSum / demDelSum) * 100);
                value = utils.precisionRound((rAndDPlanSum / demDelSum) * 100, 2);
            }
            else {
                //if the demdel/forecast gross is zero, then randd forecast is also zero
                value = 0;
            }
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.InputRAndDForecastPeriodic] = value;
        });
    }
}
