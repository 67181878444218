import { Component, OnInit } from '@angular/core';
import { UserInfo } from 'src/app/modules/shared/types/user-info';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
  selector: 'omnitdp-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {

  userInfo: UserInfo = null;

  systemPermissionColumns: string[] = ['name', 'settings'];
  constructor(
    private _userSettingsService: UserSettingsService) {

  }

  ngOnInit(): void {
    this._userSettingsService.userInfoSubject.subscribe(
      (userInfo: UserInfo) => {
        this.userInfo = userInfo;
      }
    )
  }
}
