import { DatePipe } from '@angular/common';
import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import * as _ from 'lodash';
import { PublishDialogData } from "src/app/modules/planning-view/types/publish-dialog-data";
import { LoadingAnimationsService } from "src/app/modules/shared/services/loading-animations.service";
import { UtilsService } from "src/app/modules/shared/services/utils.service";
import { PublishStatus } from "src/app/modules/shared/types/publish-status";
import { PublishDataService } from "../../services/publish-data-service";
import { PublishAssortmentDataRequest } from "../../types/api/publish-dialog/publish-assortment-data-request";
import { AssortmentPublishedStatusResponseItem } from "../../types/assortment-published-status-response-item";
import { FeatureFlagService } from 'src/app/modules/shared/services/feature-flag.service';
import { Constants } from 'src/app/modules/shared/types/constants';

interface PublishStatusViewModel {
    selected: boolean,
    selectionEnabled: boolean,
    seasonName: string,
    assortmentPublishId: number,
    lastPublishedBy: string,
    lastPublishedTime: string,
    lastPublishedStatusId: number,
    lastPublishedStatus: string,
    viewHistoryEnabled: boolean,
    historyItems: PublishStatusHistoryViewModel[]
}

interface PublishStatusHistoryViewModel {
    statusId: number,
    status: string,
    time: string,
    comments: string,
}

@Component({
    selector: 'publish-dialog',
    templateUrl: "./publish-dialog.component.html",
    styleUrls: ['./publish-dialog.component.css']
})

export class PublishDialogComponent {

    publishDialogType: string;
    publishDialogData: PublishDialogData = null;
    isErrorOccured: boolean = true;
    errorMessage: string;
    historyTableId: number = null;

    publishSeasonStatusResponseDataItems: AssortmentPublishedStatusResponseItem[] = [];
    publishStatusDataItems: PublishStatusViewModel[] = [];
    publishHistoryDataItems: PublishStatusHistoryViewModel[] = [];

    selectedSeasons: boolean[] = [];
    publishDisabledSeasonValues:string[] = [];
    publishDisabledBrandValues:string[] = [];
    responseMessage: string;

    constructor(
        public datepipe: DatePipe,
        private dialogRef: MatDialogRef<PublishDialogComponent>,
        private publishService: PublishDataService,
        private _loadingAnimationService: LoadingAnimationsService,
        private _utilsService: UtilsService,
        private _featureFlagService: FeatureFlagService,
        @Inject(MAT_DIALOG_DATA) public dialogData: PublishDialogData) {

        
        this.publishDialogData = dialogData;
        this.getStatusData();
        this.loadFeatureFlags();
    }

    private loadFeatureFlags() {

        // fetch the required feature flags	
        this._featureFlagService.appConfigDataSubject.subscribe(
          () => {
    
            let featureToggleForSeaons = null;
    
            featureToggleForSeaons = this._featureFlagService.getAppConfigByKey(Constants.PUBLISH_DISABLE_FOR_SEASONS)?.value;
            if (this._utilsService.isNotNullOrUndefined(featureToggleForSeaons)) {
              this.publishDisabledSeasonValues = featureToggleForSeaons;
              this.responseMessage = `Kindly adjust the forecast for seasons ${featureToggleForSeaons} manually in Department Plan`;
            }

            let featureToggleForBrands = null;
  
            featureToggleForBrands = this._featureFlagService.getAppConfigByKey(Constants.PUBLISH_DISABLE_FOR_BRANDS).value;
            if (this._utilsService.isNotNullOrUndefined(featureToggleForBrands)) {
              this.publishDisabledBrandValues = featureToggleForBrands;
            }
          }
        );
    }

    async getStatusData() {

        this.publishSeasonStatusResponseDataItems = await this.publishService.getAssortmentPublishSeasonStatusData(this.publishDialogData.copyId);
        
        this.publishSeasonStatusResponseDataItems.forEach(item => {
            if (item.publishedItem == null || item.publishedItem == undefined) {

                // Not Published Yet
                this.publishStatusDataItems.push(
                    {
                        selected: false,
                        selectionEnabled: this.publishDisabledSeasonValues.indexOf(item.season) > -1 && this.publishDisabledBrandValues.indexOf(this.publishDialogData.brand) > -1 ? false : true,
                        seasonName: item.season,
                        assortmentPublishId: null,
                        lastPublishedBy: "Not Published",
                        lastPublishedTime: "Not Published",
                        lastPublishedStatusId: null,
                        lastPublishedStatus: "Not Published",
                        viewHistoryEnabled: false,
                        historyItems: null
                    }
                )
            }
            else {
                // Published Already
                
                // process history items
                let publishStatus = this._utilsService.enumFromValue(item.publishedItem.statusId, PublishStatus);
                let viewHistoryEnabled = item.publishHistory != null && item.publishHistory.length != 0;
                let historyItems: PublishStatusHistoryViewModel[] = null;
                if (viewHistoryEnabled) {
                    historyItems = [];
                    item.publishHistory.forEach(historyItem => {
                        historyItems.push({
                            statusId: historyItem.statusId,
                            status: this.mapStatusValues(this._utilsService.enumFromValue(historyItem.statusId, PublishStatus)),
                            time: this.datepipe.transform(new Date(historyItem.updatedTime + "Z"), 'MMM dd yyyy, HH:mm:ss'),
                            comments: historyItem.comments,
                        });
                    })
                }

                // process publish item
                this.publishStatusDataItems.push(
                    {
                        selected: false,
                        selectionEnabled: (this.publishDisabledSeasonValues.indexOf(item.season) > -1 && this.publishDisabledBrandValues.indexOf(this.publishDialogData.brand) > -1 ? false : true) 
                                           && !this.publishDialogData.isMainLocked || (this.publishDialogData.isMainLocked && publishStatus == PublishStatus.PublishFailure),
                        seasonName: item.season,
                        assortmentPublishId: item.publishedItem?.assortmentPublishId,
                        lastPublishedBy: item.publishedItem?.publishedBy,
                        lastPublishedTime: this.datepipe.transform(new Date(item.publishedItem.publishedTime + "Z"), 'MMM dd yyyy, HH:mm:ss'),
                        lastPublishedStatusId: item.publishedItem?.statusId,
                        lastPublishedStatus: this.mapStatusValues(publishStatus),
                        viewHistoryEnabled: viewHistoryEnabled,
                        historyItems: historyItems
                    }
                )
            }
        }

        );
    }

    isPublishDisabled() {
        return _.filter(this.publishStatusDataItems, x => {
            return x.selected
        }).length == 0
    }

    onViewHistoryClick(assortmentPublishId: number) {
        if (this.historyTableId == assortmentPublishId) {
            this.historyTableId = null;
        }
        else {
            this.historyTableId = assortmentPublishId;
        }

        this.publishHistoryDataItems = _.find(this.publishStatusDataItems, x => x.assortmentPublishId == assortmentPublishId).historyItems
    }

    publishSeason() {

        this._loadingAnimationService.enableTopNavAnimation();

        let assortmentPublishRequestData = <PublishAssortmentDataRequest>{};
        assortmentPublishRequestData.assortmentCopyId = this.publishDialogData.copyId;
        assortmentPublishRequestData.seasons = _.map(_.filter(this.publishStatusDataItems, x => x.selected), "seasonName");;
        assortmentPublishRequestData.structureId = this.publishDialogData.structureId;
        this.publishService.publishAssortmentData(assortmentPublishRequestData).subscribe(
            (publishData) => {
                if (publishData) {
                    this._loadingAnimationService.disableTopNavAnimation();
                    this.dialogRef.close(publishData);
                }
            },
            (error) => {
                this._loadingAnimationService.disableTopNavAnimation();
                this.errorMessage = error.message;
            }
        )

    }

    mapStatusValues(status: PublishStatus): string {
        let statusString = "";
        switch (status) {
            case PublishStatus.Initialized: statusString = "Initialized";
              break;
            case PublishStatus.CalculatingCoverage: statusString = "Calculating Coverage"
              break;
            case PublishStatus.DepartmentPlanNotified: statusString = "Department Plan Notified";
                break;
            case PublishStatus.DataDeliveredtoDepartmentPlan: statusString = "Data Delivered to Department Plan";
                break;
            case PublishStatus.PublishSuccess: statusString = "Publish Successful";
                break;
            case PublishStatus.PublishFailure: statusString = "Publish Failed";
                break;
            case PublishStatus.PublishSkipped: statusString = "Publish Skipped";
                break;                
            case PublishStatus.NotPublished: statusString = "Not Published";
                break;
            case PublishStatus.PublishPartialSuccess: statusString = "Publish Partial Successful";
                break;
        }
        return statusString;
    }
}
