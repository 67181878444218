import { AggregatedGrossTaktCalculator } from "../../calculators/aggregated-pms/aggregated-gross-takt-calculator";
import { AggregatedRAndDPercentCalculator } from "../../calculators/aggregated-pms/aggregated-r-and-d-percent-calculator";
import { BoughtOfPlanCalculator } from "../../calculators/buying/bought-of-plan-calculator";
import { BuyingCalculator } from "../../calculators/buying/buying-calculator";
import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { CalculatorInterface } from "../../calculators/calculator-interface";
import { CalculatorTimeSpan } from "../../calculators/calculator-timespan";
import { CSPercentCalculator } from "../../calculators/cs-percent/cs-percent-calculator";
import { MarketMainSystemGoalCalculator } from "../../calculators/main-system-goal/main-system-goal-calculator";
import { AccShrinkLyCalculator } from "../../calculators/others/acc-shrink-ly-calculator";
import { ShrinkPercentLyCalculator } from "../../calculators/others/shrink-percent-ly-calculator";
import { SPercentCalculator } from "../../calculators/s-percent-calculators/s-percent-calculator";
import { OutgoingStockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from "../../calculators/stock-prognosis-calculators/stock-forecast-calculator";
import { StockPlanFixedCalculator } from "../../calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { StockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/stock-prognosis-calculator";
import { SystemGoalCalculator } from "../../calculators/takt-calculators/system-goal-calculator";
import { RetrievalMode } from "../../types/retrieval-mode";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";

export class RetrievalModeEvent extends DepartmentEvent {

    getEventType(): DepartmentEventType {

        return DepartmentEventType.RetrievalMode;
    }

    validateEvent(): boolean {
        return true;
    }

    applyEvent(driver: CalculatorDriverInterface) {
        let utils = driver.getUtilsService();

        // get enum value from string value
        driver.setRetrievalMode(utils.enumFromValue(this.eventInfo.newValue, RetrievalMode));
    }

    configureCalculators(driver: CalculatorDriverInterface) {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Acc Shrink Ly - Weekly       
        pass1Calculators.push(new AccShrinkLyCalculator(CalculatorTimeSpan.Weekly, driver));
               
        // Shrink Percent Ly - Weekly
        pass1Calculators.push(new ShrinkPercentLyCalculator(CalculatorTimeSpan.Weekly, driver)); 

        // Stock Plan Fixed - Weekly
        pass1Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, driver));

        // Stock Forecast - Weekly
        pass1Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, driver));

        // Outgoing Stock Prognosis - Weekly
        pass1Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // Gross takt gound - Weekly
        pass2Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver))

        // S Percent- Weekly
        pass2Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass2Calculators);

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];
        // S Percent- Period
        pass3Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, driver))

        pass3Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, driver))

        // System Goal - Periodic
        pass3Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, driver))

        calculatorConfiguration.push(pass3Calculators);

         // pass 4
         let pass4Calculators: CalculatorInterface[] = [];
         // S Percent- Period
         pass4Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, driver))

        
        // buying- weekly
        pass4Calculators.push(new BuyingCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass4Calculators);

        
        // pass 05
        let pass5Calculators: CalculatorInterface[] = [];

        //PM Main Aggregated data
        pass5Calculators.push(new AggregatedGrossTaktCalculator(CalculatorTimeSpan.Periodic, driver))
        pass5Calculators.push(new AggregatedRAndDPercentCalculator(CalculatorTimeSpan.Periodic, driver))
        calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];

        //Main Market System Goal
        pass6Calculators.push(new MarketMainSystemGoalCalculator(CalculatorTimeSpan.Periodic, driver))
        pass6Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, driver))
        calculatorConfiguration.push(pass6Calculators);       

        driver.setCalculatorConfiguration(calculatorConfiguration);
    }
}