import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class RAndDPlanCalculator extends AbstractCalculator
{
    // Dependencies
    // ------------
    // Effective Sales Plan Weekly
    // Input R And D Forecast Periodic    

    // Calculates
    // ----------
    // R And D Plan

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        // default to null
        let value = null;

        let utils = this.calculatorDriver.getUtilsService();
        let effectiveSalesPlanSum = 0;

        if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround]))
            effectiveSalesPlanSum = currentWeekItem[CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround];

        if (!isNaN(parseInt(currentWeekItem[CalculationDataItemType.InputRAndDForecastPeriodic]?.toString())) &&
            utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.InputRAndDForecastPeriodic]) &&
            effectiveSalesPlanSum != 0)
              value = (currentWeekItem[CalculationDataItemType.InputRAndDForecastPeriodic] / 100 * effectiveSalesPlanSum);

        currentWeekItem[CalculationDataItemType.RAndDPlan] = value;
    }
}
