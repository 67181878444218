export enum PublishStatus {
    NotPublished = 0,
    Initialized = 1,
    DepartmentPlanNotified = 2,
    DataDeliveredtoDepartmentPlan = 3,
    PublishSuccess = 4,
    PublishFailure = 5,
    PublishSkipped = 6,
    CalculatingCoverage = 7,
    PublishPartialSuccess = 8
}
