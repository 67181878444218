import { Component, OnInit } from '@angular/core';
import { Constants } from 'src/app/modules/shared/types/constants';
import { UserInfo } from 'src/app/modules/shared/types/user-info';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
  selector: 'omnitdp-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})
export class UserSettingsComponent implements OnInit {
  
  userInfo:UserInfo = null;
  enableImpersonateUser:boolean = false;

  constructor(private _userSettingsService: UserSettingsService) {
    
    
  }

  ngOnInit(): void {
    
    this._userSettingsService.userInfoSubject.subscribe(
      (userInfo:UserInfo) =>
      {
        this.userInfo = userInfo;

        if(this.userInfo)
        {
          this.onUserInfoUpdate();
        }
        
      }
    )
  }


  onUserInfoUpdate() {
    
    // check impersonation access
    let impersonationPermission = this.userInfo.systemPermissions.find(systemPermissions=> systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);

    this.enableImpersonateUser = (impersonationPermission != undefined);    

  }
  
}
