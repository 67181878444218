import { Component, Input } from '@angular/core';

@Component({
  selector: 'omnitdp-skeleton-loader',
  templateUrl: './skeleton-loader.component.html'
})
export class SkeletonLoaderComponent {
  
  @Input() skeletonType: string;
 
}
