import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class EffectiveSystemGoalPlanCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Cube DemDel Plan
    // Gross Sales Ground
    // Start Week Sales Gross Ground

    // Calculates
    // ----------
    // Effective System Goal Plan Weekly

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        if(utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.CubeDemDelPlan]))
        {
            value = currentWeekItem[CalculationDataItemType.CubeDemDelPlan]
        }
        else if(utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGround]))
        {
            value = currentWeekItem[CalculationDataItemType.GrossSalesGround];
        }
        currentWeekItem[CalculationDataItemType.EffectiveSystemGoalPlanWeekly] = value;
    }
}