import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { AbstractCalculator } from "../abstract-calculator";
import { SeasonPlanningType } from "../../types/season-planning-type";

export class ShrinkPercentLyCalculator extends AbstractCalculator {

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem) {


        let value = 100;
        let utils = this.calculatorDriver.getUtilsService();


        let seasonInfo = this.calculatorDriver.getSeasonInfo();

        let seasonPlanningType = seasonInfo.seasonPlanningType;

        let nextSeason = utils.shiftSeasons(seasonInfo.seasonCodeNames[0], 1);

        let startWeekForNextSeason = utils.getStartWeekForSeason(parseInt(nextSeason));

        //shrinkage starts after 13 weeks from next season's startweek

        let validShrinkWeek = utils.shiftWeeks(startWeekForNextSeason, 13);
        let previousViewWeekWithYear = this.calculatorDriver.getViewWeekWithYear() -1;

        let currentWeek = currentWeekItem[CalculationDataItemType.WeekName];

        if (seasonPlanningType == SeasonPlanningType.Actual || seasonPlanningType == SeasonPlanningType.Previous
            || seasonPlanningType == SeasonPlanningType.PreviousWithOld) {
            if (currentWeek >= previousViewWeekWithYear && currentWeek >= validShrinkWeek) {
                let accShrinkLy = currentWeekItem[CalculationDataItemType.AccShrinkLy];
                let denominator = accShrinkLy + currentWeekItem[CalculationDataItemType.StockGrossLy];
                let shrinkPercentLy = 1 - utils.safeDivide(accShrinkLy, denominator);
                //value = shrinkPercentLy <= 0 ? previousWeekItem[CalculationDataItemType.ShrinkPercentLy] : shrinkPercentLy * 100;
                //For negative calculated value,make it default to 100,changed due to super high stock values
                if(shrinkPercentLy > 0 )
                {
                    value =  shrinkPercentLy*100;
                }
            }
        }

        currentWeekItem[CalculationDataItemType.ShrinkPercentLy] = value;

    }
}
