
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoadingAnimationsService } from '../loading-animations.service';
import { UiHelperService } from '../ui-helper.service';
import { UserConfigService } from '../user-config.service';
import { UtilsService } from '../utils.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorNotificationService {

  constructor(public snackBar: MatSnackBar, private _uiHelperService: UiHelperService,
    private _loadingAnimationService: LoadingAnimationsService, private router: Router,
    private _utils: UtilsService, private zone: NgZone, private _userConfigService: UserConfigService) { }

  showSuccess(message: string): void {
    this._uiHelperService.showPopupAlert(message);
  }

  showError(message: string): void {
    console.error(message);

    this._loadingAnimationService.stopAllLoadingAnimations();

    let linkUrl = this._userConfigService.generateLink();
    this.zone.run(() => {
      this.router.navigate(['/error'], { state: { linkUrl: linkUrl } });
    });
  }
}
