import * as _ from 'lodash';
import { AggregatedGrossTaktCalculator } from 'src/app/modules/shared/calculators/aggregated-pms/aggregated-gross-takt-calculator';
import { AggregatedRAndDPercentCalculator } from 'src/app/modules/shared/calculators/aggregated-pms/aggregated-r-and-d-percent-calculator';
import { BuyingCalculator } from 'src/app/modules/shared/calculators/buying/buying-calculator';
import { CalculatorDriverInterface } from "src/app/modules/shared/calculators/calculator-driver-interface";
import { CalculatorInterface } from "src/app/modules/shared/calculators/calculator-interface";
import { CalculatorTimeSpan } from "src/app/modules/shared/calculators/calculator-timespan";
import { CSPercentCalculator } from 'src/app/modules/shared/calculators/cs-percent/cs-percent-calculator';
import { MarketMainSystemGoalCalculator } from 'src/app/modules/shared/calculators/main-system-goal/main-system-goal-calculator';
import { AccShrinkLyCalculator } from 'src/app/modules/shared/calculators/others/acc-shrink-ly-calculator';
import { CombinedNetSalesCalculator } from 'src/app/modules/shared/calculators/others/combined-net-sales-calculator';
import { CombinedSalesPlanCalculator } from 'src/app/modules/shared/calculators/others/combined-sales-plan-calculator';
import { ForecastGoalCalculator } from "src/app/modules/shared/calculators/others/forecast-goal-calculator";
import { PopulateInputCellCalculator } from 'src/app/modules/shared/calculators/others/populate-input-cell-calculator';
import { PopulateRAndDForecastInputCellCalculator } from 'src/app/modules/shared/calculators/others/populate-randd-forecast-input-cell-calculator';
import { SalesForecastCalculator } from 'src/app/modules/shared/calculators/others/sales-forecast-calculator';
import { ShrinkPercentLyCalculator } from 'src/app/modules/shared/calculators/others/shrink-percent-ly-calculator';
import { StockIndexGroundCalculator } from 'src/app/modules/shared/calculators/others/stock-index-ground-calculator';
import { CombinedRAndDCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/combined-r-and-d-calculator';
import { GroundRAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/ground-r-and-d-percent-calculator";
import { RAndDForecastCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-forecast-calculator';
import { RAndDForecastPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-forecast-percent-calculator';
import { RAndDLyPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-ly-percent-calculator';
import { RAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-percent-calculator";
import { GroundSPercentCalculator } from 'src/app/modules/shared/calculators/s-percent-calculators/ground-s-percent-calculator';
import { SPercentCalculator } from 'src/app/modules/shared/calculators/s-percent-calculators/s-percent-calculator';
import { OutgoingStockPrognosisCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator';
import { StockForecastCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/stock-forecast-calculator';
import { StockPlanFixedCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/stock-plan-fixed-calculator';
import { StockPrognosisCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-prognosis-calculator";
import { GrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/gross-takt-calculator";
import { GroundGrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/ground-gross-takt-calculator";
import { NetTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/net-takt-calculator";
import { OmniTaktCalculator } from 'src/app/modules/shared/calculators/takt-calculators/omni-takt-calculator';
import { SystemGoalCalculator } from 'src/app/modules/shared/calculators/takt-calculators/system-goal-calculator';
import { DepartmentEvent } from 'src/app/modules/shared/events/department-events/department-event';
import { DepartmentEventType } from 'src/app/modules/shared/events/department-events/department-event-type';
import { DialogBoxService } from "src/app/modules/shared/services/dialog-box-service";
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { DriverType } from 'src/app/modules/shared/types/driver-type';
import { RetrievalMode } from "src/app/modules/shared/types/retrieval-mode";
import { SeasonDataItem } from 'src/app/modules/shared/types/season-data-item';
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { SeasonPlanningType } from "src/app/modules/shared/types/season-planning-type";
import { SelectedOptions } from 'src/app/modules/shared/types/selected-options';
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { WeekDataItem } from "src/app/modules/shared/types/week-data-item";
import { PlanningViewActionHistoryService } from '../../../services/planning-view-action-history-service';
import { DepartmentPlanningViewDataResponse } from '../../../types/api/planning-view-data/department-planning-view-data-response';
import { CalculationDataItemType } from '../../../types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from "../../../types/department-calculation-data-item";
import { DepartmentSaveItem } from "../../../types/department-save-item";
import { InputLockStatus } from '../../../types/input-lock-status-enum';
import { ReturnsInfo } from '../../../types/returns-info';
import { WeekSaveItem } from '../../../types/save-items/week-save-item';
import { DepartmentCalculationDriver } from "./department-calculation-driver";
import { SeasonDataAggregator } from "./season-data-aggregator";
import { EffectiveSalesPlanCalculator } from 'src/app/modules/shared/calculators/others/effective-sales-plan-calculator';
import { EffectiveSalesPlanWithGroundCalculator } from 'src/app/modules/shared/calculators/others/effective-sales-plan-with-ground-calculator';

export class PreviousAndOldDepartmentCalculationDriver implements CalculatorDriverInterface {

    public touched: boolean = false;
    public rPercentDirty: boolean = false;
    public rowInputsDirty: boolean = false;

    // contains each separate season dataset - should have 5 datasets
    private _individualDataSets: DepartmentCalculationDataItem[][] = [];

    // consolidated from 5 separate datasets
    private _aggregatedDataSet: DepartmentCalculationDataItem[] = null;

    // loaded into object

    // api response
    private _rawWeekDataItems: WeekDataItem[] = [];
    // filtered into separate sets
    _individualrawWeekDataItemsSet: WeekDataItem[][] = [];

    // refs to all individual department drivers
    private _individualDepartmentDrivers: DepartmentCalculationDriver[] = [];

    private _seasonInfo: SeasonInfo = null;
    private _viewDate: Date = null;
    private _returnsInfo: ReturnsInfo = null;
    private _viewWeekWithYear: number = null;
    private _viewPeriodWeeksWithYear: number[] = null;
    private _utilsService: UtilsService = null;
    private _cacheDepartmentSaveItem: DepartmentSaveItem

    private _calculatorConfiguration: CalculatorInterface[][] = null;
    private retrievalMode: RetrievalMode = null;
    public previousRetrievalMode: RetrievalMode = null;
    private previousAdjustedRPercent: number = null;

    private initialInputItems: DepartmentCalculationDataItem[] = null;
    private previousInputItems: DepartmentCalculationDataItem[] = null;
    private _previousDataSet: DepartmentCalculationDataItem[] = null;
    private uniqueWeekList: number[] = null;
    private inputLockStatus: InputLockStatus = InputLockStatus.Locked_Disabled;

    public hasSalesAggregatedPmDataSetLoaded = false;
    public hasSalesSystemGoalInAssortmentLoaded = false;

    private _changeEventListeners: any[] = [];
    _dialogBoxService: DialogBoxService = null;
    _planningViewActionHistoryService: PlanningViewActionHistoryService = null;

    private _title: string = null;
    private _csPercent: number = null;
    private _userConfigService:UserConfigService = null;

    constructor(userConfigService: UserConfigService, utilsService: UtilsService, dialogBoxService: DialogBoxService, planningViewActionHistoryService: PlanningViewActionHistoryService) {
        this._userConfigService = userConfigService;
        this._utilsService = utilsService;
        this._dialogBoxService = dialogBoxService;
        this._planningViewActionHistoryService = planningViewActionHistoryService;
    }
    getDriverType(): DriverType {
        return DriverType.DepartmentPreviousSeasonDriver;
    }

    getInputLockStatus(): InputLockStatus {
        return this.inputLockStatus;
    }

    setInputLockStatus(lockStatus: InputLockStatus): void {
        this.inputLockStatus = lockStatus;
    }
    getPreviousAdjustedRPercent(): number {
        return this.previousAdjustedRPercent;
    }

    getPreviousRetrievalMode(): RetrievalMode {
        return this.previousRetrievalMode;
    }
    getRawDataItems(): WeekDataItem[] {
        return this._rawWeekDataItems;
    }
    getUserConfig(): UserConfig {
        return this._userConfigService.getUserConfig();
    }

    getDataSetRange(startweek: number, endweek: number): DepartmentCalculationDataItem[] {

        let startWeekIndex = _.findIndex(this._aggregatedDataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == startweek
        });

        let endWeekIndex = _.findIndex(this._aggregatedDataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == endweek
        });

        return this._aggregatedDataSet.slice(startWeekIndex, endWeekIndex + 1);
    }

    refreshTotalDataSet() {
        throw new Error("Method not implemented.");
    }

    setDataSet(dataSet: DepartmentCalculationDataItem[]) {
        this._aggregatedDataSet = dataSet;
    }
    setRetrievalMode(retrievalMode: RetrievalMode) {
        this.retrievalMode = retrievalMode;
    }
    getTitle(areOldSeasonsExcluded?: boolean): string {
        if (areOldSeasonsExcluded) {
            return this._seasonInfo.seasonCodeNames[0];
        }
        else {
            return "<=" + this._seasonInfo.seasonCodeNames[0] + "(" + this._seasonInfo.seasonCodeNames.slice(1).join(', ') + ")";
        }
    }

    getInitialInputItems(): DepartmentCalculationDataItem[] {
        throw new Error("Method not implemented.");
    }

    getPreviousInputItemValue(weekIndex: number, inputType: CalculationDataItemType) {
        return this.previousInputItems[weekIndex][inputType];
    }


    getPreviousDataSet(): DepartmentCalculationDataItem[] {
        return this._previousDataSet;
    }

    addChangeEventListener(changeHandler: any) {
        this._changeEventListeners.push(changeHandler);
    }

    getItemsForSave(userConfig: UserConfig): DepartmentSaveItem {

        this._cacheDepartmentSaveItem = null;

        let saveItem: DepartmentSaveItem = {
            weekData: [],
            // periodData: [],
            seasonData: []
        }

        this._individualDepartmentDrivers.forEach(deparmentDriver => {
            let Items = deparmentDriver.getItemsForSave(userConfig);
            saveItem.weekData = [...saveItem.weekData, ...Items.weekData];
            saveItem.seasonData = [...saveItem.seasonData, ...Items.seasonData];
        });

        this._cacheDepartmentSaveItem = saveItem;
        return saveItem;
    }

    getUtilsService(): UtilsService {
        return this._utilsService;
    }
    getSeasonInfo(): SeasonInfo {
        return this._seasonInfo;
    }
    getDataSet(): any[] {
        return this._aggregatedDataSet;
    }
    getViewDate(): Date {
        return this._viewDate;
    }
    getRetrievalMode(): RetrievalMode {
        return this.retrievalMode;
    }
    getViewWeekWithYear(): number {
        return this._viewWeekWithYear
    }
    getViewPeriodWeeksWithYear(): number[] {
        return this._viewPeriodWeeksWithYear
    }
    setCalculatorConfiguration(calculatorConfiguration: CalculatorInterface[][]) {
        this._calculatorConfiguration = calculatorConfiguration;
    }

    getReturnsInfo(): ReturnsInfo {
        return this._returnsInfo;
    }

    setReturnsInfo(returnsInfo: ReturnsInfo) {
        this._returnsInfo = returnsInfo
    }

    getCSPercent(): number {

        return this._csPercent;
    }


    setCSPercent(csPercent: number) {
        this._csPercent = csPercent
    }

    getWeekDataItems(): WeekDataItem[] {
        return this._rawWeekDataItems;
    }

    setWeekDataItems(rawWeekDataItems: WeekDataItem[]) {
        this._rawWeekDataItems = rawWeekDataItems;
    }

    getDialogBoxService(): DialogBoxService {
        return this._dialogBoxService;
    }

    getChildDataSets(): DepartmentCalculationDataItem[][] {
        throw new Error("Method not implemented.");
    }


    // loading data for the first time
    initDataSet(departmentPlanningViewDataResponse: DepartmentPlanningViewDataResponse, seasonInfo: SeasonInfo, viewDate: Date) {
        this._rawWeekDataItems = departmentPlanningViewDataResponse.weekData;
        this._viewDate = viewDate;
        this._seasonInfo = seasonInfo;

        this._configureReturnsInfo(departmentPlanningViewDataResponse.seasonData);

        // default retrieval mode for previous and old
        this.retrievalMode = RetrievalMode.Bought;

        this._viewWeekWithYear = this._utilsService.getWeekNumber(true, this._viewDate);
        this._viewPeriodWeeksWithYear = this._utilsService.getWeeksForPeriod(this._viewDate);

        // separate data items into 5 different raw data items
        this._seasonInfo.seasonCodeNames.forEach((seasonName: string) => {
            this._individualrawWeekDataItemsSet.push(_.filter(this._rawWeekDataItems, {
                'seasonName': parseInt(seasonName),
            }));
        });

        // create a department driver for each and get each processed
        this._individualrawWeekDataItemsSet.forEach((individualrawWeekDataItems: WeekDataItem[]) => {
            let departmentDriver: DepartmentCalculationDriver = new DepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._planningViewActionHistoryService);

            let seasonName = individualrawWeekDataItems[0].seasonName;

            let individualSeasonInfo: SeasonInfo = {
                periods: this._seasonInfo.periods,
                quarters: this._seasonInfo.quarters,
                seasonType: this._utilsService.getSeasonTypeFromSeasonName(seasonName),
                seasonCodeNames: [seasonName.toString()],
                seasonPlanningType: SeasonPlanningType.PreviousWithOldChild,
                weeks: this._seasonInfo.weeks,
                weeksWithYear: this._seasonInfo.weeksWithYear
            }

            let individualPlanningViewDataResponse: DepartmentPlanningViewDataResponse = {
                weekData: individualrawWeekDataItems,
                seasonData: (_.filter(departmentPlanningViewDataResponse.seasonData, {
                    'seasonName': seasonName.toString(),
                })),
                seasonNames: [individualrawWeekDataItems[0].seasonName.toString()]
            }

            departmentDriver.initDataSet(individualPlanningViewDataResponse, individualSeasonInfo, this._viewDate);
            departmentDriver.calculate();

            this._individualDepartmentDrivers.push(departmentDriver);

        });

        // load the datasets into array
        this._individualDepartmentDrivers.forEach((individualDepartmentDriver: DepartmentCalculationDriver) => {
            this._individualDataSets.push(individualDepartmentDriver.getDataSet());
        });

        // aggregate individual seasons into one
        let fields = this._getAggregationFields();

        this._aggregatedDataSet = SeasonDataAggregator.aggregate(this._individualDataSets, fields, this._seasonInfo, this._utilsService);

        this._configureCalculatorsForInitialLoad();

        this.previousAdjustedRPercent = this._returnsInfo != null ? this._returnsInfo.adjustedReturnPercent : 0;

        let weekList = this._aggregatedDataSet.map(el => el.weekName);
        this.uniqueWeekList = weekList.filter((item, i, ar) => ar.indexOf(item) === i);
    }

    private _configureReturnsInfo(seasonDataItem: SeasonDataItem[]) {
        let aggregatedInfo: ReturnsInfo;

        if (seasonDataItem.length > 0) {
            aggregatedInfo = seasonDataItem.reduce((a: SeasonDataItem, b: SeasonDataItem) => ({
                unAdjustedReturnPercent: a.unAdjustedReturnPercent,
                unAdjustedBudgetedReturnPercent: a.unAdjustedBudgetedReturnPercent,
                seasonName: a.seasonName + b.seasonName
            }))

            aggregatedInfo.unAdjustedReturnPercent = this._utilsService.roundNumber(aggregatedInfo.unAdjustedReturnPercent, 2);
            aggregatedInfo.unAdjustedBudgetedReturnPercent = this._utilsService.roundNumber(aggregatedInfo.unAdjustedBudgetedReturnPercent, 2);
        }
        this._returnsInfo = aggregatedInfo;
    }


    private _getAggregationFields(): CalculationDataItemType[] {
        return this._utilsService.getAllCalculationDataItemTypes();
    }

    public configureCalculatorsForClear() {
        this._configureCalculatorsForInitialLoad();
    }

    private _configureCalculatorsForInitialLoad() {
        this._calculatorConfiguration = [];

        // ============= //

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Input Add Moves + Input Add/Remove - Weekly : 
        pass1Calculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Weekly, this))
        // Input Forecast Gross - Periodic 
        pass1Calculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Periodic, this))
        // Stock Plan Fixed Correction - Weekly
        pass1Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, this))
        // Gross takt ground - Weekly
        pass1Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective System Goal Plan - Weekly
        // pass1Calculators.push(new EffectiveSystemGoalPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // Gross takt - weekly
        pass1Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // R&D% Ground - Weekly
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // R&D% Ground - Periodic
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // Stock Index Ground - Periodic
        pass1Calculators.push(new StockIndexGroundCalculator(CalculatorTimeSpan.Periodic, this))
        // Forecast goal - Periodic
        pass1Calculators.push(new ForecastGoalCalculator(CalculatorTimeSpan.Periodic, this))

        pass1Calculators.push(new RAndDLyPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective Sales Plan - Weekly
        pass1Calculators.push(new EffectiveSalesPlanCalculator(CalculatorTimeSpan.Weekly, this));
        pass1Calculators.push(new EffectiveSalesPlanWithGroundCalculator(CalculatorTimeSpan.Weekly, this));

        // load pass calculators
        this._calculatorConfiguration.push(pass1Calculators);

        // ============= //

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];
        // System Goal - Periodic
        pass2Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // Gross takt ground - Periodic
        pass2Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // Ground S percent- Weekly
        pass2Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Weekly, this))

                
        // Acc Shrink Ly - Weekly
        pass2Calculators.push(new AccShrinkLyCalculator(CalculatorTimeSpan.Weekly, this));
               
        // Shrink Percent Ly - Weekly
        pass2Calculators.push(new ShrinkPercentLyCalculator(CalculatorTimeSpan.Weekly, this));


        // load pass calculators
        this._calculatorConfiguration.push(pass2Calculators);

        // ============= //

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];

        // Sales Forecast - Weekly
        pass3Calculators.push(new SalesForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Combined Sales Plan - Weekly
        pass3Calculators.push(new CombinedSalesPlanCalculator(CalculatorTimeSpan.Weekly, this))



        // calculate r and d forecast input cell periodic 
        pass3Calculators.push(new PopulateRAndDForecastInputCellCalculator(CalculatorTimeSpan.Periodic, this))

        // R&D% - Periodic
        pass3Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // Ground S percent- Periodic
        pass3Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass3Calculators);

        // ============= //

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];

        // Gross takt - Periodic
        pass4Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // Omni takt - Periodic
        pass4Calculators.push(new OmniTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // R&D% - Weekly
        pass4Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // Stock Forecast - Weekly
        pass4Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Outgoing Stock Prognosis - Weekly
        pass4Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, this))


        // load pass calculators
        this._calculatorConfiguration.push(pass4Calculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];

        // Stock Prognosis - Weekly
        pass5Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, this))

        // R&D Forecast Percent - Periodic
        pass5Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Periodic, this));
        // R&D Forecast Percent - Weekly
        pass5Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Weekly, this));

        pass5Calculators.push(new RAndDForecastCalculator(CalculatorTimeSpan.Weekly, this))
        // S Percent- Weekly
        pass5Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];
        // Combined RAndD Calculator - Weekly
        pass6Calculators.push(new CombinedRAndDCalculator(CalculatorTimeSpan.Weekly, this))
        // Stock Prognosis - Periodic
        pass6Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, this))

        // S Percent- Period
        pass6Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass6Calculators);


        // pass 7
        let pass7Calculators: CalculatorInterface[] = [];

        // Combined Net Sales - weekly
        pass7Calculators.push(new CombinedNetSalesCalculator(CalculatorTimeSpan.Weekly, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass7Calculators);

        // pass 8
        let pass8Calculators: CalculatorInterface[] = [];
        // Net takt - weekly
        pass8Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Weekly, this))
        // Net takt - Periodic
        pass8Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, this))

        pass8Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, this))
        this._calculatorConfiguration.push(pass8Calculators);

        // pass 9
        let pass9Calculators: CalculatorInterface[] = [];
        // buying- weekly
        pass9Calculators.push(new BuyingCalculator(CalculatorTimeSpan.Weekly, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass9Calculators);

        // pass 10
        let pass10Calculators: CalculatorInterface[] = [];
        pass10Calculators.push(new AggregatedGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        pass10Calculators.push(new AggregatedRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        this._calculatorConfiguration.push(pass10Calculators);

        // pass 11
        let pass11Calculators: CalculatorInterface[] = [];
        pass10Calculators.push(new MarketMainSystemGoalCalculator(CalculatorTimeSpan.Periodic, this))
        this._calculatorConfiguration.push(pass11Calculators);        

        // ============= //
    }

    private _configureCalculatorsForSalesAggregateData() {
        this._calculatorConfiguration = [];
        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];
        pass1Calculators.push(new AggregatedGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        pass1Calculators.push(new AggregatedRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        this._calculatorConfiguration.push(pass1Calculators);  
        
        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];
        pass2Calculators.push(new MarketMainSystemGoalCalculator(CalculatorTimeSpan.Periodic, this))
        this._calculatorConfiguration.push(pass2Calculators);          
    }


    public calculate() {
        let pass = 0;
        this._calculatorConfiguration.forEach((calculators: CalculatorInterface[], pass: number) => {
            let currentWeekItem: DepartmentCalculationDataItem = null;
            let previousWeekItem: DepartmentCalculationDataItem = null;
            let periodIndexes = null;
            let currentPeriodWeekItems: DepartmentCalculationDataItem[] = null;

            this.uniqueWeekList.forEach((weekWithYear: number, index: number) => {
                calculators.forEach((calculator: CalculatorInterface) => {
                    if (calculator.timespan == CalculatorTimeSpan.Weekly ||
                        (calculator.timespan == CalculatorTimeSpan.Seasonal && index == this.uniqueWeekList.length - 1) ||
                        (calculator.timespan == CalculatorTimeSpan.Periodic && index % 4 == 0)) {
                        currentWeekItem = this._aggregatedDataSet[index];
                        previousWeekItem = (index == 0) ? null : this._aggregatedDataSet[index - 1];
                        periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
                        currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

                        calculator.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
                    }
                });
            });
        });

        this.cacheInputRows();

    }

    handleEvent(event: DepartmentEvent) {
        if (event.validateEvent(this)) {

            let isClearAllEvent = event.getEventType() == DepartmentEventType.ClearAll
            // save the input rows so that it can be reassigned back
            this.cacheInputRows();

            // clear the data sets
            this._individualDataSets = [];

            let incomingEvent = event;

            // mirror the event to all individual drivers and get updated values
            this._individualDepartmentDrivers.forEach((departmentDriver: CalculatorDriverInterface, index: number) => {
                //when clear all event is fired get raw data and current data from each driver n pass as inputs
                if (isClearAllEvent && !event.undoEvent) {
                    incomingEvent.eventInfo.newValue = _.cloneDeep(departmentDriver.getRawDataItems());
                    incomingEvent.eventInfo.previousValue = _.cloneDeep(departmentDriver.getDataSet());
                }
                //when undo clear all event is fired ,invert the values 
                //by getting  previous data set and rawdata from each driver as inputs    
                else if (isClearAllEvent && event.undoEvent) {
                    incomingEvent.eventInfo.newValue = _.cloneDeep(departmentDriver.getPreviousDataSet());
                    incomingEvent.eventInfo.previousValue = _.cloneDeep(departmentDriver.getRawDataItems());
                }

                if (
                    ((incomingEvent.getEventType() == DepartmentEventType.AddMovesMsek || incomingEvent.getEventType() == DepartmentEventType.AddRemoveMsek) && index == 0)
                    ||
                    (incomingEvent.getEventType() != DepartmentEventType.AddMovesMsek && incomingEvent.getEventType() != DepartmentEventType.AddRemoveMsek
                    )
                ) {
                    departmentDriver.handleEvent(incomingEvent);
                }

                // load the datasets into array                
                this._individualDataSets.push(departmentDriver.getDataSet());

            });

            // aggregate individual seasons into one

            this.recalculateAggregatedDataset();

            if (!isClearAllEvent) {
                // assign data back to the input fields
                this.reloadInputRowsFromCache();
            }

            // apply the event on the aggregated data set
            event.applyEvent(this);
            this.calculate();

            if (!this._utilsService.isNotNullOrUndefined(event.undoEvent) || !event.undoEvent) {
                // add event to history 
                this._planningViewActionHistoryService.addActionEvent(event, this);
            }


            // trigger change event
            this._changeEventListeners.forEach(changeListener => {
                changeListener();
            });
        }
    }

    cacheInputRows() {
        this.previousInputItems = [];
        this._previousDataSet = [];

        let previousInputItem: DepartmentCalculationDataItem = null;

        // set previous retrieval mode
        this.previousRetrievalMode = _.cloneDeep(this.retrievalMode);

        this.previousAdjustedRPercent = this._returnsInfo != null ? this._returnsInfo.adjustedReturnPercent : 0;

        this.uniqueWeekList.forEach((weekWithYear: number, index: number) => {
            previousInputItem = {
                weekName: weekWithYear,
                inputForecastGrossPeriodic: this._aggregatedDataSet[index][CalculationDataItemType.InputForecastGrossPeriodic],
                inputAddRemoveMSekWeekly: this._aggregatedDataSet[index][CalculationDataItemType.InputAddRemoveMSekWeekly],
                inputAddMovesMSekWeekly: this._aggregatedDataSet[index][CalculationDataItemType.InputAddMovesMSekWeekly],
                inputRAndDForecastPeriodic: this._aggregatedDataSet[index][CalculationDataItemType.InputRAndDForecastPeriodic]
            }

            this.previousInputItems.push(previousInputItem);
        });

        this._previousDataSet = _.cloneDeep(this._aggregatedDataSet);
    }

    reloadInputRowsFromCache() {

        this.uniqueWeekList.forEach((weekWithYear: number, index: number) => {
            this._aggregatedDataSet[index][CalculationDataItemType.InputForecastGrossPeriodic] = this.previousInputItems[index][CalculationDataItemType.InputForecastGrossPeriodic];
            this._aggregatedDataSet[index][CalculationDataItemType.InputAddRemoveMSekWeekly] = this.previousInputItems[index][CalculationDataItemType.InputAddRemoveMSekWeekly];
            this._aggregatedDataSet[index][CalculationDataItemType.InputAddMovesMSekWeekly] = this.previousInputItems[index][CalculationDataItemType.InputAddMovesMSekWeekly];
            this._aggregatedDataSet[index][CalculationDataItemType.InputRAndDForecastPeriodic] = this.previousInputItems[index][CalculationDataItemType.InputRAndDForecastPeriodic];
        });
    }

    private _getDataSetItemsByIndexes(indexes: number[]): DepartmentCalculationDataItem[] {
        let dataSetItems: DepartmentCalculationDataItem[] = [];
        indexes.forEach(i => {
            dataSetItems.push(this._aggregatedDataSet[i]);
        });

        return dataSetItems;
    }

    public refreshSeasonRawDataItem(selectedOptions: SelectedOptions) {
        //get save items and update the season raw data item
        let departmentSaveItem = this._cacheDepartmentSaveItem;

        if (departmentSaveItem.weekData.length > 0) {
            let weekLevelSaveData = departmentSaveItem.weekData;
            this._rawWeekDataItems.forEach((rawDataItemRecord, index) => {
                //find  save item for each season and week
                let departmentDataItem = _.find(weekLevelSaveData, (saveItem: WeekSaveItem) => saveItem.weekId == rawDataItemRecord.weekName && saveItem.seasonId === rawDataItemRecord.seasonName);

                if (this._utilsService.isNotNullOrUndefined(departmentDataItem) && rawDataItemRecord.weekName === departmentDataItem.weekId) {
                    rawDataItemRecord.demDelPlan = this._utilsService.isNotNullOrUndefined(departmentDataItem.demDelPlanTSek) ? departmentDataItem.demDelPlanTSek : null;
                    rawDataItemRecord.plannedReturn = this._utilsService.isNotNullOrUndefined(departmentDataItem.returnPlanPmc) ? departmentDataItem.returnPlanPmc : null;
                    rawDataItemRecord.addRemoveMSek = this._utilsService.isNotNullOrUndefined(departmentDataItem.addRemoveMSek) ? departmentDataItem.addRemoveMSek : null;
                    rawDataItemRecord.addMovesMSek = this._utilsService.isNotNullOrUndefined(departmentDataItem.addMovesMSek) ? departmentDataItem.addMovesMSek : null;
                    rawDataItemRecord.rAndDPlan = this._utilsService.isNotNullOrUndefined(departmentDataItem.rAndDPlanPercent) ? departmentDataItem.rAndDPlan : null;
                }
            });
        }
    }

    setSalesAggregatedPmDataSet(salesAggregatedDataItems: WeekDataItem[])
    {
        //update aggregated data set directly n recalculate

        this._aggregatedDataSet.forEach((departmentCalculationDataItem: DepartmentCalculationDataItem) => {

            const weekItemRecord: WeekDataItem = _.find(salesAggregatedDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            // Update initial data set with aggregated values so they don't dissappear when user click clear
            let initialWeekItemRecord: WeekDataItem = _.find(this._rawWeekDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            if(weekItemRecord != null ) {
                departmentCalculationDataItem.aggregatedPMsRAndDPercentPeriodic = weekItemRecord.aggregatedPMsRAndDPercentPeriodic;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsRAndDPercentPeriodic = weekItemRecord.aggregatedPMsRAndDPercentPeriodic;
                departmentCalculationDataItem.aggregatedPMsGrossTaktPeriodic = weekItemRecord.aggregatedPMsGrossTaktPeriodic;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossTaktPeriodic = weekItemRecord.aggregatedPMsGrossTaktPeriodic;

                departmentCalculationDataItem.aggregatedPMsCombinedSalesPlanWeekly = weekItemRecord.aggregatedPMsCombinedSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsCombinedSalesPlanWeekly = weekItemRecord.aggregatedPMsCombinedSalesPlanWeekly;
                departmentCalculationDataItem.aggregatedPMsEffectiveSalesPlanWeekly = weekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly = weekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly;

                departmentCalculationDataItem.aggregatedPMsGrossSales = weekItemRecord.aggregatedPMsGrossSales;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSales = weekItemRecord.aggregatedPMsGrossSales;
                departmentCalculationDataItem.aggregatedPMsGrossSalesGround = weekItemRecord.aggregatedPMsGrossSalesGround;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSalesGround = weekItemRecord.aggregatedPMsGrossSalesGround;
                departmentCalculationDataItem.aggregatedPMsRAndDPlan = weekItemRecord.aggregatedPMsRAndDPlan;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsRAndDPlan = weekItemRecord.aggregatedPMsRAndDPlan;

                departmentCalculationDataItem.aggregatedPMsGrossSalesLy = weekItemRecord.aggregatedPMsGrossSalesLy;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSalesLy = weekItemRecord.aggregatedPMsGrossSalesLy;
                departmentCalculationDataItem.aggregatedPMsGrossSales2Ground = weekItemRecord.aggregatedPMsGrossSales2Ground;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSales2Ground = weekItemRecord.aggregatedPMsGrossSales2Ground;
                departmentCalculationDataItem.aggregatedPMsIsSaved = weekItemRecord.aggregatedPMsIsSaved;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsIsSaved = weekItemRecord.aggregatedPMsIsSaved;

                //main copy system goal 
                departmentCalculationDataItem.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;

                departmentCalculationDataItem.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                departmentCalculationDataItem.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;

                departmentCalculationDataItem.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                departmentCalculationDataItem.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                departmentCalculationDataItem.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
            }
        });

        //change calculator config to calculate aggregated data 
        this._configureCalculatorsForSalesAggregateData();

        this.calculate();

        //reset calculator config to original state
        this._configureCalculatorsForInitialLoad(); 

        let previousDriver  = this._individualDepartmentDrivers.find(x=> x.getTitle() ==  this._seasonInfo.seasonCodeNames[0]);

        previousDriver.setSalesAggregatedPmDataSet(salesAggregatedDataItems); 

        this.hasSalesAggregatedPmDataSetLoaded = true;
    }

    setSalesSystemGoalInAssortmentDataSet(salesAggregatedDataItems:WeekDataItem[]): void
    {
        this._aggregatedDataSet.forEach((departmentCalculationDataItem: DepartmentCalculationDataItem) => {

            var weekItemRecord: WeekDataItem = _.find(salesAggregatedDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            if(weekItemRecord != null ) {
                departmentCalculationDataItem.salesMainCopyCombinedSalesPlanWeekly = weekItemRecord.salesMainCopyCombinedSalesPlanWeekly;
                departmentCalculationDataItem.salesMainCopyGrossSalesGround = weekItemRecord.salesMainCopyGrossSalesGround;
                departmentCalculationDataItem.salesMainCopySystemGoal = weekItemRecord.salesMainCopySystemGoal;
            }
        });

        this.hasSalesSystemGoalInAssortmentLoaded = true;
    }

    private async recalculateAggregatedDataset()
    {
        // aggregate individual seasons into one
        let fields = this._getAggregationFields();

        let aggregatedDataSet = SeasonDataAggregator.aggregate(this._individualDataSets, fields, this._seasonInfo, this._utilsService);
        // instead of reassigning the ref, set values in the props
        _.map(this._aggregatedDataSet, (dataSetItem, index) => {

            Object.keys(dataSetItem).forEach(key => {
                if (dataSetItem[key] != aggregatedDataSet[index][key]) {
                    dataSetItem[key] = aggregatedDataSet[index][key];
                }
            });
        });
    }


}
