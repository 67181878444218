import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiRoutes } from 'src/app/modules/shared/types/api-routes';
import { environment } from 'src/environments/environment';
import { CubeInfoResponse } from "../types/api/cube-info/cube-info-response";

@Injectable({
    providedIn: 'root'
})
export class CubeInfoService {

    private cubeRefreshInfoUrl = environment.baseApiUrl + ApiRoutes.cubeInformation;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient) { }

    getCubeInformation(): Promise<CubeInfoResponse> {
        return this.http.get<CubeInfoResponse>(this.cubeRefreshInfoUrl, this.httpOptions).toPromise();
    }
}
