import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";
import * as _ from 'lodash';
import { ChannelType } from "../../types/channel-type";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { ReturnsInfo } from "src/app/modules/planning-view/types/returns-info";

export class PlannedReturnCalculator extends AbstractCalculator {

    // Dependencies
    // ------------
    // Effective Sales Plan Weekly
    // Returns Distribution


    // Calculates
    // ----------
    // Planned Return

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem): void {
        let value = 0;
        const utils = this.calculatorDriver.getUtilsService();

        if (this.calculatorDriver.getUserConfig().planningViewOptions.channel == ChannelType.Online) {
            const dataset = this.calculatorDriver.getDataSet();
            const returnsInfo = this.calculatorDriver.getReturnsInfo();

            const currentWeekItemIndex = _.findIndex(dataset, dataItem => {
                return dataItem[CalculationDataItemType.WeekName] == currentWeekItem[CalculationDataItemType.WeekName];
            });

            const weekWiseReturnDistribution = [
                returnsInfo.returnDistribution.returnDistributionMatrix.week1,
                returnsInfo.returnDistribution.returnDistributionMatrix.week2,
                returnsInfo.returnDistribution.returnDistributionMatrix.week3,
                returnsInfo.returnDistribution.returnDistributionMatrix.week4,
                returnsInfo.returnDistribution.returnDistributionMatrix.week5,
                returnsInfo.returnDistribution.returnDistributionMatrix.week6
            ];

            if (currentWeekItemIndex != -1) {
                let weekWiseReturnDistributionIndex = 0;
                let weekIndex = currentWeekItemIndex - 1;

                // Different calculation for
                // Actual season
                // if the start week of the dataset is either 33 or 05
                // first six weeks calculations
                const seasonInfo = this.calculatorDriver.getSeasonInfo();
                const seasonPlanningType = seasonInfo.seasonPlanningType;
                const firstWeek = dataset[0][CalculationDataItemType.WeekName];
                const weekNumber = (firstWeek).toString().substring(4);
                const startWeek = utils.getStartWeekForSeason(Number(seasonInfo.seasonCodeNames[0]));
                let demDelPlanBeforeStartWeek = 0;

                this.calculatorDriver.getDataSetRange(firstWeek, startWeek - 1).forEach(item => {
                    if (utils.isNotNullOrUndefined(item[CalculationDataItemType.DemDelPlanForReturns]))
                        demDelPlanBeforeStartWeek += item[CalculationDataItemType.DemDelPlanForReturns];
                    else
                        demDelPlanBeforeStartWeek += item[CalculationDataItemType.CubeDemDelPlanForReturns];
                });

                if (seasonPlanningType == SeasonPlanningType.Actual &&
                    (weekNumber == "05" || weekNumber == "33") &&
                    (currentWeekItemIndex >= 0 && currentWeekItemIndex < 6))
                    value = this.initialSixWeeksCalculation(currentWeekItemIndex, returnsInfo, weekWiseReturnDistribution, currentWeekItem, startWeek, demDelPlanBeforeStartWeek);
                else {
                    // normal calculation
                    while (weekIndex > 0 && weekWiseReturnDistributionIndex <= 5) {
                        if (seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOld ||
                            seasonInfo.seasonPlanningType == SeasonPlanningType.Previous ||
                            seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOldChild)
                            value += (dataset[weekIndex][CalculationDataItemType.CombinedSalesPlanWeekly] * weekWiseReturnDistribution[weekWiseReturnDistributionIndex]);
                        else {
                            if (utils.isNotNullOrUndefined(dataset[weekIndex][CalculationDataItemType.DemDelPlanForReturns])) {
                                // If start week (21 or 49) then dem del plan for weeks before have been added to this weeks dem del plan, remove that here
                                value += (dataset[weekIndex][CalculationDataItemType.DemDelPlanForReturns] - (dataset[weekIndex][CalculationDataItemType.WeekName] == startWeek ? demDelPlanBeforeStartWeek : 0)) * weekWiseReturnDistribution[weekWiseReturnDistributionIndex];
                            }
                            else
                                value += (dataset[weekIndex][CalculationDataItemType.CubeDemDelPlanForReturns] * weekWiseReturnDistribution[weekWiseReturnDistributionIndex]);
                        }

                        weekIndex--;
                        weekWiseReturnDistributionIndex++;
                    }

                    if (utils.isNotNullOrUndefined(returnsInfo.adjustedReturnPercent) && returnsInfo.adjustedReturnPercent.toString() != "")
                        value = value * parseFloat(returnsInfo.adjustedReturnPercent.toString()) / 100;
                    else
                        value = value * returnsInfo.unAdjustedReturnPercent / 100;
                }
            }
        }

        currentWeekItem[CalculationDataItemType.PlannedReturn] = value;
    }

    initialSixWeeksCalculation(currentWeekItemIndex: number, returns: ReturnsInfo, weekWiseReturnDistribution: number[], currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, startWeek: number, demDelPlanBeforeStartWeek: number): number {
        let value = 0;
        const utils = this.calculatorDriver.getUtilsService();

        const rawDataItems = this.calculatorDriver.getRawDataItems()
        if (rawDataItems == null) return value;

        const rawDataItemIndex = _.findIndex(rawDataItems, item => {
            return item.weekName == currentWeekItem.weekName;
        });

        // index should exist and should not be equal to the dataset index
        if (rawDataItemIndex != -1 && rawDataItemIndex != currentWeekItemIndex) {
            let weekWiseReturnDistributionIndex = 0;
            let weekCounter = rawDataItemIndex - 1;

            while (weekCounter > 0 && weekWiseReturnDistributionIndex <= 5) {
                let rawEffectiveSalesPlanWeekly = 0;

                // calculate effective sales plan for the week, since no calculators run beyond the dataset limits
                if (utils.isNotNullOrUndefined(rawDataItems[weekCounter][CalculationDataItemType.DemDelPlanForReturns])) {
                    // If start week (21 or 49) then dem del plan for weeks before have been added to this weeks dem del plan, remove that here since we will get it from weeks before, otherwise it will be included twice
                    rawEffectiveSalesPlanWeekly = rawDataItems[weekCounter][CalculationDataItemType.DemDelPlanForReturns] - (rawDataItems[weekCounter][CalculationDataItemType.WeekName] == startWeek ? demDelPlanBeforeStartWeek : 0);
                }
                else if (utils.isNotNullOrUndefined(rawDataItems[weekCounter][CalculationDataItemType.CubeDemDelPlanForReturns]))
                    rawEffectiveSalesPlanWeekly = rawDataItems[weekCounter][CalculationDataItemType.CubeDemDelPlanForReturns]

                value += (rawEffectiveSalesPlanWeekly * weekWiseReturnDistribution[weekWiseReturnDistributionIndex]);

                weekCounter--;
                weekWiseReturnDistributionIndex++;
            }

            if (utils.isNotNullOrUndefined(returns.adjustedReturnPercent) && returns.adjustedReturnPercent.toString() != "")
                value = value * parseFloat(returns.adjustedReturnPercent.toString()) / 100;
            else
                value = value * returns.unAdjustedReturnPercent / 100;
        }

        return value;
    }
}
