
<div>
    <h2 mat-dialog-title style="text-align: center;">Copy PM Forecast Goals</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                This will overwrite forecast gross goals with PM forecast goals. Do you want to continue?
            </h3>
            
        </span>
       
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary"
                 [mat-dialog-close]="true">Yes</button>
            <button mat-raised-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>
