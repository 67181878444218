export enum StructureTypes
{
    CustomerGroup = "CustomerGroup",
    GarmentGroup = "GarmentGroup",
    Brand = "Brand",
    Division = "Division",
    Section = "Section",
    Department = "Department",
    Index = "Index",
    SubIndex = "SubIndex",
    Total = "Total"
}