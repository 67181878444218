import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { ChannelType } from "../../types/channel-type";
import { SeasonPlanningType } from "../../types/season-planning-type";

export class StockPlanFixedCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets Stock Plan Fixed 

    // Dependencies
    // ------------
    // Purchase Plan Fixed (raw)
    // Sales Plan Fixed (raw)
    // Start Week Sales Plan Fixed (raw)
    // Cube Dem Del Plan (raw)
    // Start Week Dem Del Plan (raw)
    // Planned Returns (calculated)
    // Effective Sales Plan Weekly (calculated)

    // Calculates
    // ----------
    // Stock Plan Fixed (calculated)



    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();
        let seasonName = parseInt(this.calculatorDriver.getSeasonInfo().seasonCodeNames[0]);

        let startWeekForCurrentSeason = utils.getStartWeekForSeason(seasonName)
        let seasonPlanningType: SeasonPlanningType = this.calculatorDriver.getSeasonInfo().seasonPlanningType;
        let channelType: ChannelType = this.calculatorDriver.getUserConfig().planningViewOptions.channel;
        let seasonEndWeek = utils.getEndWeekForSeason(seasonName);

        if (seasonPlanningType == SeasonPlanningType.Actual) {
            if (channelType === ChannelType.Store) {

                let salesPlanMetric = 0;
                if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.SalesPlanFixed])) {
                    salesPlanMetric = currentWeekItem[CalculationDataItemType.SalesPlanFixed];
                }
                else if (currentWeekItem.weekName > seasonEndWeek + 4) {
                    salesPlanMetric = currentWeekItem[CalculationDataItemType.GrossSalesGround];
                }
                let fixedPlanDifference: number = currentWeekItem[CalculationDataItemType.PurchasePlanFixed] - (salesPlanMetric + currentWeekItem[CalculationDataItemType.StartWeekSalesPlanFixed]);


                // for start week
                if (currentWeekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason) {
                    let startWeekStockPlanFixed = (utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.SalesPlanFixed) + currentWeekItem[CalculationDataItemType.StartWeekSalesPlanFixed]) * 2;
                    value = startWeekStockPlanFixed + currentWeekItem[CalculationDataItemType.PurchasePlanFixed] - currentWeekItem[CalculationDataItemType.CubeDemDelPlan];
                }
                // for second week
                else if (currentWeekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason + 1) {
                    value = ((currentWeekItem[CalculationDataItemType.PurchasePlanFixed] + previousWeekItem[CalculationDataItemType.PurchasePlanFixed])) -
                        ((salesPlanMetric + previousWeekItem[CalculationDataItemType.SalesPlanFixed] + previousWeekItem[CalculationDataItemType.StartWeekSalesPlanFixed]))
                }
                else if (previousWeekItem) {
                    value = previousWeekItem[CalculationDataItemType.StockPlanFixed] + fixedPlanDifference
                }
                else {
                    value = fixedPlanDifference;
                }
            }
            else if (channelType == ChannelType.Online) {

                let cubeDemDelPlanMetric = 0;
                if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.CubeDemDelPlan])) {
                    cubeDemDelPlanMetric = currentWeekItem[CalculationDataItemType.CubeDemDelPlan];
                }
                else if (currentWeekItem.weekName > seasonEndWeek + 4) {
                    cubeDemDelPlanMetric = currentWeekItem[CalculationDataItemType.GrossSalesGround];
                }

                let fixedPlanDifference: number = currentWeekItem[CalculationDataItemType.PurchasePlanFixed] -
                    (cubeDemDelPlanMetric + currentWeekItem[CalculationDataItemType.StartWeekDemPlan]) +
                    currentWeekItem[CalculationDataItemType.PlannedReturn];

                if (currentWeekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason) {
                    let startWeekStockPlanFixed = (utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CubeDemDelPlan) + currentWeekItem[CalculationDataItemType.StartWeekDemPlan]) * 1.5;
                    value = startWeekStockPlanFixed + currentWeekItem[CalculationDataItemType.PurchasePlanFixed] - currentWeekItem[CalculationDataItemType.CubeDemDelPlan] + currentWeekItem[CalculationDataItemType.PlannedReturn];
                }

                else if (currentWeekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason + 1) {

                    let purchasePlanFixedSum = previousWeekItem[CalculationDataItemType.PurchasePlanFixed] + currentWeekItem[CalculationDataItemType.PurchasePlanFixed];
                    let plannedReturnSum = previousWeekItem[CalculationDataItemType.PlannedReturn] + currentWeekItem[CalculationDataItemType.PlannedReturn];
                    let cubeDemDelPlanSum = cubeDemDelPlanMetric + previousWeekItem[CalculationDataItemType.CubeDemDelPlan] + previousWeekItem[CalculationDataItemType.StartWeekDemPlan];

                    value = purchasePlanFixedSum + plannedReturnSum - cubeDemDelPlanSum
                }

                else if (previousWeekItem) {
                    value = previousWeekItem[CalculationDataItemType.StockPlanFixed] + fixedPlanDifference;
                }
                else {
                    value = fixedPlanDifference;
                }
            }
        }
        else {
            let dataSet = this.calculatorDriver.getDataSet();
            let previousPeriodItems = this.calculatorDriver.getDataSetRange(dataSet[0][CalculationDataItemType.WeekName], utils.shiftWeeks(currentWeekItem[CalculationDataItemType.WeekName], - 1));
            let purchasePlanFixed = currentWeekItem[CalculationDataItemType.PurchasePlanFixed];
            let returns = currentWeekItem[CalculationDataItemType.PlannedReturn];
            let cubeDemdelPlan = currentWeekItem[CalculationDataItemType.CubeDemDelPlan];
            let fixedPlanDifference = currentWeekItem[CalculationDataItemType.PurchasePlanFixed] - (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] + currentWeekItem[CalculationDataItemType.StartWeekSalesPlanFixed]);           

            // calculate sales forecast
            let salesForecastSum = utils.sumKpiForPeriod(previousPeriodItems, CalculationDataItemType.SalesForecastWeekly) + currentWeekItem[CalculationDataItemType.SalesForecastWeekly];

            // for coming
            // for start week
            if (currentWeekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason) {

                // sum effective sales plan for the current period
                let cubeDemdelPlanSumForCurrentAndPreviousPeriods = utils.sumKpiForPeriod(previousPeriodItems, CalculationDataItemType.CubeDemDelPlan) + utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CubeDemDelPlan);

                let coverage = channelType == ChannelType.Store ? 2 : 1.5;

                let cubdeDemdelPlanSum = cubeDemdelPlanSumForCurrentAndPreviousPeriods * coverage;

                value = cubdeDemdelPlanSum + purchasePlanFixed + returns - salesForecastSum;
            }
            // for second week
            else if (currentWeekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason + 1) {

                value = utils.sumKpiForPeriod(previousPeriodItems, CalculationDataItemType.PurchasePlanFixed) + purchasePlanFixed - (utils.sumKpiForPeriod(previousPeriodItems, CalculationDataItemType.CubeDemDelPlan) + cubeDemdelPlan) + returns;

            }
            else if (previousWeekItem) {

                value = previousWeekItem[CalculationDataItemType.StockPlanFixed] + fixedPlanDifference + returns;
            }
            else {
                value = fixedPlanDifference + returns;
            }

        }

        currentWeekItem[CalculationDataItemType.StockPlanFixed] = value;
    }
}
