import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";

export class OmniTaktCalculator extends AbstractCalculator {

    // Periodic
    // ------
    // Sets Omni Takt Periodic

    // Dependencies
    // ------------
    // Combined Sales Plan Weekly (calculated)
    // Complementary Combined Sales Plan Weekly (raw)
    // Gross Sales Ground (raw)
    // Complementary Gross Sales Ground (raw)

    // Calculates
    // ----------
    // Omni Takt Periodic (calculated)

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let combinedSalesPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedSalesPlanWeekly);
        let grossSalesGroundSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.GrossSalesGround);

        let complementaryDemDelSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.ComplementaryDemDel);
        let complementaryDemDelGroundSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.ComplementaryDemDelGround);

        let numerator = combinedSalesPlanSum + complementaryDemDelSum;
        let denominator = grossSalesGroundSum + complementaryDemDelGroundSum;
        value = utils.safeDivide(numerator, denominator) * 100;

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.OmniTaktPeriodic] = value;
        });
    }
}