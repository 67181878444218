import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { UserSettingsService } from 'src/app/modules/user-settings/services/user-settings.service';

@Component({
  selector: 'omnitdp-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  isIframe = false;

  constructor(
    private _loadingAnimationsService: LoadingAnimationsService,
    private _userSettingsService: UserSettingsService,
    public _utilsService: UtilsService
  ) { }

  getLoadingState(): boolean {
    return this._loadingAnimationsService.AreLoadingAnimationsInProgress();
  }

  async ngOnInit() {
    this.isIframe = window !== window.parent && !window.opener;

    // load user settings
    await this._userSettingsService.getUserInfo();

    // adjust screen resolution on 
    window.addEventListener('resize', this._utilsService.adjustScreenDimensions);

    this._utilsService.adjustScreenDimensions();
  }
}
