<div>
    <h2 mat-dialog-title style="text-align: center;">Clear All</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                Are you sure you want to clear all inputs  from "<span class='dialogboxname'>{{seasonName}}</span>"?
            </h3>
        </span>
       
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary"
                 [mat-dialog-close]="true">Clear</button>
            <button mat-raised-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>