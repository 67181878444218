
<div>
    <h2 mat-dialog-title style="text-align: center;">Save Copy</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                When saving, goals will be trickled down and overwrite goals on lower levels. Do you want to continue?
            </h3>
            <mat-checkbox [(ngModel)]="trickleDownStatus.dontShowMeAgain" >Don't show again</mat-checkbox>
        </span>
       
        <mat-dialog-actions align="center">
            <button  (click)="saveSetting()" type="button" mat-raised-button color="primary"
                 [mat-dialog-close]="true">Yes</button>
            <button mat-raised-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>
