<div id="selection-page-main">
    <div class="row fullheightrow">
        <div class="col-md-3 selection-page-panel"></div>
        <div class="col-md-6 selection-page-panel">
            <div class="selection-container">
                <mat-card>
                    <mat-card-title>
                        <div><span class="material-icons error-icon"> error_outline
                        </span></div>
                        &nbsp;{{this.errrorMessage}}</mat-card-title>
                    <mat-card-subtitle></mat-card-subtitle>
                    <mat-card-content>
                        <p>We couldnt process the last action successfully.</p>
                        <p>Please click <a (click)="reloadSite()" style="cursor:pointer;text-decoration: underline;">here</a> to navigate back to the application</p>
                        <p *ngIf="linkUrl">To resume from the same planning view and retry again click <a [href]="linkUrl">here</a>
                            <button class="toolbar-left-icons" mat-icon-button title="Copy Link" (click)="copyLinkToClipboard()">
                                <mat-icon>content_copy</mat-icon>
                            </button>
                        </p>
                        <p *ngIf="linkUrl">If this issue recurs, please contact Assortment Support & Learning for additional assistance.</p>
                    </mat-card-content>
                    <mat-card-actions>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
        <div class="col-md-3 selection-page-panel"></div>
    </div>
</div>