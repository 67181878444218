import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { CalculatorInterface } from "../../calculators/calculator-interface";
import { CalculatorTimeSpan } from "../../calculators/calculator-timespan";
import { PlannedCoverageCalculator } from "../../calculators/coverage/planned-coverage-calculator";
import { CSPercentCalculator } from "../../calculators/cs-percent/cs-percent-calculator";
import { BudgetedReturnCalculator } from "../../calculators/others/budgeted-return-calculator";
import { CombinedNetSalesCalculator } from "../../calculators/others/combined-net-sales-calculator";
import { CombinedSalesPlanCalculator } from "../../calculators/others/combined-sales-plan-calculator";
import { EffectiveSalesPlanCalculator } from "../../calculators/others/effective-sales-plan-calculator";
import { EffectiveSalesPlanWithGroundCalculator } from "../../calculators/others/effective-sales-plan-with-ground-calculator";
import { ForecastGoalCalculator } from "../../calculators/others/forecast-goal-calculator";
import { FunctionCalculator } from "../../calculators/others/function-calculator";
import { PlannedReturnCalculator } from "../../calculators/others/planned-return-calculator";
import { SalesForecastCalculator } from "../../calculators/others/sales-forecast-calculator";
import { ParentCalculatorDriverInterface } from "../../calculators/parent-calculator-driver-interface";
import { DemdelPlanCalculator } from "../../calculators/plan-calculators/demdel-plan-calculator";
import { CombinedRAndDCalculator } from "../../calculators/r-and-d-calculators/combined-r-and-d-calculator";
import { EffectiveRAndDPlanCalculator } from "../../calculators/r-and-d-calculators/effective-r-and-d-plan-calculator";
import { RAndDForecastCalculator } from "../../calculators/r-and-d-calculators/r-and-d-forecast-calculator";
import { RAndDForecastPercentCalculator } from "../../calculators/r-and-d-calculators/r-and-d-forecast-percent-calculator";
import { RAndDPlanCalculator } from "../../calculators/r-and-d-calculators/r-and-d-plan-calculator";
import { SPercentCalculator } from "../../calculators/s-percent-calculators/s-percent-calculator";
import { OutgoingStockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from "../../calculators/stock-prognosis-calculators/stock-forecast-calculator";
import { StockPlanFixedCalculator } from "../../calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { StockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/stock-prognosis-calculator";
import { GrossTaktCalculator } from "../../calculators/takt-calculators/gross-takt-calculator";
import { NetTaktCalculator } from "../../calculators/takt-calculators/net-takt-calculator";
import { OmniTaktCalculator } from "../../calculators/takt-calculators/omni-takt-calculator";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";


export class ForecastGrossEvent extends DepartmentEvent {

    getEventType(): DepartmentEventType {

        return DepartmentEventType.ForecastGross
    }

    validateEvent(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface): boolean {
        let dataSet = driver.getDataSet();

        let isGrossSalesGroundNotAvailable = (dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
            dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
            dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
            dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0);

        let isDemDelPlanAvailable = (
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.DemDelPlan] != null ||
            dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.DemDelPlan] != null ||
            dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.DemDelPlan] != null ||
            dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.DemDelPlan] != null
        );
        if (this.eventInfo.newValue != null && isGrossSalesGroundNotAvailable) {
            let dialogBoxService = driver.getDialogBoxService();

            if (isDemDelPlanAvailable) {
                dialogBoxService.showMessage("You cannot set forecast goal when sales gross ground is 0. \n Also DemDelPlan should be cleared where sales ground is zero. Hence kindly save.");
                dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.DemDelPlan] = null;
                dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.DemDelPlan] = null;
                dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.DemDelPlan] = null;
                dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.DemDelPlan] = null;
            }
            else {
                dialogBoxService.showMessage("You cannot set forecast goal when sales gross ground is 0");
            }


            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.InputForecastGrossPeriodic] = null;
            dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.InputForecastGrossPeriodic] = null;
            dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.InputForecastGrossPeriodic] = null;
            dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.InputForecastGrossPeriodic] = null;
            // Update this as well, used for for change factor, might be calculated differently than the other one but should be the same on change
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = null;
            dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = null;
            dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = null;
            dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = null;

            driver.setDataSet(dataSet);
            return false;
        }
        else {
            return true;
        }
    }

    applyEvent(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface) {
        let dataSet = driver.getDataSet();

        this.eventInfo.weekIndexes.forEach((weekIndex: number) => {
            dataSet[weekIndex][CalculationDataItemType.InputForecastGrossPeriodic] = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
            // Update this as well, used for for change factor, might be calculated differently than the other one but should be the same on change
            dataSet[weekIndex][CalculationDataItemType.InputForecastGrossPeriodicPrevious] = dataSet[weekIndex][CalculationDataItemType.InputForecastGrossPeriodicOriginal];
            dataSet[weekIndex][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = dataSet[weekIndex][CalculationDataItemType.InputForecastGrossPeriodic];
        });

        driver.setDataSet(dataSet);
    }

    configureCalculators(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface) {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Demdel plan - Weekly
        pass1Calculators.push(new DemdelPlanCalculator(CalculatorTimeSpan.Weekly, driver));

        // load pass calculators
        calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // Effective Sales Plan - Weekly
        pass2Calculators.push(new EffectiveSalesPlanCalculator(CalculatorTimeSpan.Weekly, driver));
        pass2Calculators.push(new EffectiveSalesPlanWithGroundCalculator(CalculatorTimeSpan.Weekly, driver));

        // load pass calculators
        calculatorConfiguration.push(pass2Calculators);

        let passAlphaCalculators: CalculatorInterface[] = [];
        passAlphaCalculators.push(new ForecastGoalCalculator(CalculatorTimeSpan.Periodic, driver))
        // load pass calculators
        calculatorConfiguration.push(passAlphaCalculators);

        //pass 3 
        let pass3Calculators: CalculatorInterface[] = [];

        pass3Calculators.push(new SalesForecastCalculator(CalculatorTimeSpan.Weekly, driver))
        // load pass calculators
        calculatorConfiguration.push(pass3Calculators);

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];

        // Combined Sales Plan - Weekly
        pass4Calculators.push(new CombinedSalesPlanCalculator(CalculatorTimeSpan.Weekly, driver))

        // Budgeted Returns - Weekly
        pass4Calculators.push(new BudgetedReturnCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass4Calculators);

        // pass function
        let functionPassCalculators: CalculatorInterface[] = [];
        functionPassCalculators.push(new FunctionCalculator(null, driver, this.updateReturnsInfo));
        calculatorConfiguration.push(functionPassCalculators);

        let returnsPassCalculators: CalculatorInterface[] = [];
        // Planned Returns - Weekly
        returnsPassCalculators.push(new PlannedReturnCalculator(CalculatorTimeSpan.Weekly, driver))
        calculatorConfiguration.push(returnsPassCalculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];

        // Gross takt - Periodic
        pass5Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Periodic, driver))
        // Omni takt - Periodic
        pass5Calculators.push(new OmniTaktCalculator(CalculatorTimeSpan.Periodic, driver))
        // R&D Plan - Weekly
        pass5Calculators.push(new RAndDPlanCalculator(CalculatorTimeSpan.Weekly, driver))

        // Effective R&D Plan - Weekly
        pass5Calculators.push(new EffectiveRAndDPlanCalculator(CalculatorTimeSpan.Weekly, driver))

        // Stock Forecast - Weekly
        pass5Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, driver));

        // Stock Plan Fixed - Weekly
        pass5Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, driver));
        // Outgoing Stock Prognosis - Weekly
        pass5Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver));
        // R&D Forecast Percent - Periodic
        pass5Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Periodic, driver))
        // R&D Forecast Percent - Weekly
        pass5Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Weekly, driver));


        // load pass calculators
        calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];

        //Coverage Calculator
        pass6Calculators.push(new PlannedCoverageCalculator(CalculatorTimeSpan.Weekly, driver))

        // Stock Prognosis - Weekly
        pass6Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver))

        // R&D Forecast - Weekly
        pass6Calculators.push(new RAndDForecastCalculator(CalculatorTimeSpan.Weekly, driver))

        // S Percent- Weekly
        pass6Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, driver))

        // CS Percent - Seasonal
        pass6Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, driver))
        // load pass calculators
        calculatorConfiguration.push(pass6Calculators);


        // pass 6
        let pass7Calculators: CalculatorInterface[] = [];
        // Combined RAndD Calculator - Weekly
        pass7Calculators.push(new CombinedRAndDCalculator(CalculatorTimeSpan.Weekly, driver))

        // S Percent- Period
        pass7Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, driver))

        // Stock Prognosis - Periodic
        pass7Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, driver))

        // load pass calculators
        calculatorConfiguration.push(pass7Calculators);


        // pass 8
        let pass8Calculators: CalculatorInterface[] = [];
        // Combined Net Sales - weekly
        pass8Calculators.push(new CombinedNetSalesCalculator(CalculatorTimeSpan.Weekly, driver))
        // load pass calculators
        calculatorConfiguration.push(pass8Calculators);

        // pass 8
        let pass9Calculators: CalculatorInterface[] = [];
        // Net takt - Periodic
        pass9Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, driver))
        // load pass calculators
        calculatorConfiguration.push(pass9Calculators);

        driver.setCalculatorConfiguration(calculatorConfiguration);
    }

    updateReturnsInfo(driver: CalculatorDriverInterface) {

        if (
            driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOldChild &&
            driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOld &&
            driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Previous) {
            let utils = driver.getUtilsService();
            let returnsInfo = driver.getReturnsInfo();

            let seasonInfo = driver.getSeasonInfo();

            // if the event is within the start and end weeks of the current season
            let seasonStartWeek = utils.getStartWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));
            let seasonEndWeek = utils.getEndWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));

            let rangeDataSet = driver.getDataSetRange(seasonStartWeek, seasonEndWeek);
            let effectivePlanSum = 0;
            let budgetedReturnsSum = 0;

            // calculate the effective sales plan sum within the period
            rangeDataSet.forEach(rangeDataSetItem => {
                if (utils.isNotNullOrUndefined(rangeDataSetItem[CalculationDataItemType.DemDelPlanForReturns]))
                    effectivePlanSum += rangeDataSetItem[CalculationDataItemType.DemDelPlanForReturns];
                else
                    effectivePlanSum += rangeDataSetItem[CalculationDataItemType.CubeDemDelPlanForReturns];
            });

            // new range for BR
            seasonStartWeek = utils.getKPeriodStartWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));
            rangeDataSet = driver.getDataSetRange(seasonStartWeek, seasonEndWeek);
            // calculate the effective sales plan sum within the period
            rangeDataSet.forEach(rangeDataSetItem => {
                budgetedReturnsSum += rangeDataSetItem[CalculationDataItemType.UnAdjustedBudgetedReturn];
            });

            // update the sum of dem del plan
            //returnsInfo.sumOfDemDelPlan = returnsInfo.sumOfDemDelPlanBeforeKPeriod + effectivePlanSum;
            returnsInfo.sumOfDemDelPlan = effectivePlanSum;

            // dem del diff = (Dem Del / Dem Del Fixed)
            let demdelDiff = (utils.safeDivide(returnsInfo.sumOfDemDelPlan, returnsInfo.sumOfDemDelFixed));

            // update return factor percent
            // ReturnFactorPercent = Return Plan fixed * dem del diff) / Un Adjusted BR Sum
            returnsInfo.returnFactorPercent = utils.safeDivide(returnsInfo.sumOfReturnFixed * (demdelDiff), budgetedReturnsSum);


            if (utils.isNotNullOrUndefined(returnsInfo.adjustedReturnPercent)) {
                returnsInfo.adjustedBudgetedReturnPercent = utils.roundNumber(utils.safeDivide(returnsInfo.adjustedReturnPercent, returnsInfo.returnFactorPercent), 2);
            }
            else {
                returnsInfo.unAdjustedReturnPercent = utils.roundNumber(returnsInfo.unAdjustedBudgetedReturnPercent * returnsInfo.returnFactorPercent, 2);
            }

            driver.setReturnsInfo(returnsInfo);
        }
    }
}
