import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiRoutes } from '../types/api-routes';
import { AppConfig, AppConfigData, FeatureFlag } from '../types/feature-flag';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {

    private appConfigApiUrl = environment.baseApiUrl + ApiRoutes.appConfigData;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }
    
    private appConfigData: AppConfigData = null;

    public appConfigDataSubject: BehaviorSubject<AppConfigData> = new BehaviorSubject<AppConfigData>(this.appConfigData);

    constructor(private http: HttpClient
    ) {
        this.getAppConfData();
    }


    async getAppConfData() {

        this.appConfigData = await this.http.get<AppConfigData>(this.appConfigApiUrl, this.httpOptions).toPromise();

        this.appConfigDataSubject.next(this.appConfigData);
    }

    getAppConfigByKey(keyName: string): AppConfig
    {
        return this.appConfigData ? (this.appConfigData.appConfigs.find((appConfig:AppConfig)=>{
            return appConfig.name == keyName
        })) : null;
    }


    getFeatureFlagByKey(keyName: string): FeatureFlag {
        return this.appConfigData ? (this.appConfigData.featureFlags.find((featureFlag:FeatureFlag)=>{
            return featureFlag.name == keyName
        })) : null;
    }
}
