import { CalculationDataItemType } from 'src/app/modules/planning-view/types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from 'src/app/modules/planning-view/types/department-calculation-data-item';
import { ParentCalculationDataItem } from 'src/app/modules/planning-view/types/parent-calculation-data-item';

import { AbstractCalculator } from '../abstract-calculator';

export class CombinedRAndDCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    //rAndD Forecast Percent Weekly

    // Calculates
    // ----------
    // Combined R And D Cost Weekly

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();


        if (utils.isNotNullUndefinedOrZero(currentWeekItem.grossSales)) {
            value = currentWeekItem.rAndD ;
        }
        else {
            value = currentWeekItem.rAndDForecastWeekly;
        }
       
        currentWeekItem[CalculationDataItemType.CombinedRAndDWeekly] = value;
        
    }
}