import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class AggregatedGrossTaktCalculator extends AbstractCalculator {

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let combinedSalesPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.AggregatedPMsCombinedSalesPlanWeekly);

        let grossSalesGroundSum = 0;

        currentPeriodWeekItems.forEach(item => {
            if (utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.AggregatedPMsGrossSalesGround]))
                grossSalesGroundSum += item[CalculationDataItemType.AggregatedPMsGrossSalesGround];
            else if (utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.AggregatedPMsGrossSalesLy]))
                grossSalesGroundSum += item[CalculationDataItemType.AggregatedPMsGrossSalesLy];
            else if (utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.AggregatedPMsGrossSales2Ground]))
                grossSalesGroundSum += item[CalculationDataItemType.AggregatedPMsGrossSales2Ground];
        });

        if (utils.isNotNullUndefinedOrZero(grossSalesGroundSum)) {
            value = combinedSalesPlanSum / grossSalesGroundSum * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.AggregatedPMsGrossTaktPeriodic] = value;
        });
    }
}