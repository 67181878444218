import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AssortmentCopy } from "../../selection-view/types/api/copy/get-all-copies/response/assortment-copy";
import { SalesCopy } from "../../selection-view/types/api/copy/get-all-copies/response/sales-copy";
import { UtilsService } from "../../shared/services/utils.service";
import { ApiRoutes } from "../../shared/types/api-routes";
import { MarketType } from "../../shared/types/market-type";
import { OrganizationType } from "../../shared/types/organization-type";
import { SelectedOptions } from "../../shared/types/selected-options";
import { StructureTypes } from "../../shared/types/structure-types";
import { OrganizationSummaryViewDataRequest } from "../types/api/organization-summary-view-data/organization-summary-view-data-request";
import { OrganizationSummaryViewDataResponse } from "../types/api/organization-summary-view-data/organization-summary-view-data-response";
import { TreeNode } from "../types/tree-node";
import * as _ from 'lodash';
import { UserConfigService } from "../../shared/services/user-config.service";
import { SeasonSetting } from "../../shared/types/user-config";
import { SeasonPlanningType } from "../../shared/types/season-planning-type";
import { RetrievalMode } from "../../shared/types/retrieval-mode";
import { Observable, Subject, zip } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class OrganizationSummaryViewService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient, private utilsService: UtilsService, private userConfigService: UserConfigService) { }

    getTotalPlanViewData(selectionOptions: SelectedOptions, childNodes: TreeNode[]): Observable<OrganizationSummaryViewDataResponse[]> {

        let isAssortment = selectionOptions.selectionViewOptions.organization.name == OrganizationType.Assortment;

        let requestData: OrganizationSummaryViewDataRequest = {
            channelId: selectionOptions.planningViewOptions.channel,
            isAssortmentCopy: isAssortment,
            copyId: isAssortment ?
                (<AssortmentCopy>selectionOptions.selectionViewOptions.copy).assortmentCopyId :
                (<SalesCopy>selectionOptions.selectionViewOptions.copy).salesCopyId,
            isChannelSum: selectionOptions.planningViewOptions.isChannelSum,
            corporateBrandId: selectionOptions.selectionViewOptions.brand.id,
            isMain: selectionOptions.selectionViewOptions.copy.isMain,
            isPercentPm: isAssortment ? (selectionOptions.planningViewOptions.isChannelSum ? false : selectionOptions.planningViewOptions.market.marketType == MarketType.PercentPlanningMarket) : selectionOptions.selectionViewOptions.market.marketType == MarketType.PercentPlanningMarket,
            isPrCopy: !isAssortment ? (<SalesCopy>selectionOptions.selectionViewOptions.copy).isPRCopy : false,
            marketId: isAssortment ? (selectionOptions.planningViewOptions.isChannelSum ? 0 : selectionOptions.planningViewOptions.market.marketIntegrationKey) : selectionOptions.planningViewOptions.market.marketIntegrationKey,
            marketType: isAssortment ? (selectionOptions.planningViewOptions.isChannelSum ? '' : MarketType[selectionOptions.planningViewOptions.market.marketType]) : MarketType[selectionOptions.planningViewOptions.market.marketType],
            structureId: selectionOptions.planningViewOptions.activeNode.typeId,
            structureType: this.utilsService.enumFromValue(selectionOptions.planningViewOptions.activeNode.typeName, StructureTypes),
            selectedDate: new Date(),
            isExcludeOldSeasons: selectionOptions.planningViewOptions.areOldSeasonsExcluded,
            parentStructureId: selectionOptions.planningViewOptions.parentStructureId,
            parentStructureType: selectionOptions.planningViewOptions.parentStructureType,
            childStructureIds: _.map(childNodes, (x: TreeNode) => x.typeId),
            childStructureType: this.utilsService.enumFromValue(childNodes[0].typeName, StructureTypes),
            retrievalModes: this._getRetrievalModes(),
            isStockVsSalesNet52Enabled: true//For Organization summary we are not showing StockVsSalesNet52 so no need to fetch extra 52 weeks data 
        }
        var requrl = environment.baseHeavyLoadApiUrl + ApiRoutes.organizationSummaryViewData;

        // Splitting the api response into 2 parts to leverage horizontal scaling
        // in case the CPU is busy thereby reducing time.
        const firstHalfSeasonUrl = requrl + "?splitSeason=f_2";
        const lastHalfSeasonUrl = requrl + "?splitSeason=l_2";
        const firstHalfRequestSubject = new Subject<OrganizationSummaryViewDataResponse[]>();
        const lastHalfRequestSubject = new Subject<OrganizationSummaryViewDataResponse[]>();
        this.http.post<OrganizationSummaryViewDataResponse[]>(firstHalfSeasonUrl, requestData, this.httpOptions).subscribe(
            (data) => firstHalfRequestSubject.next(data)
        );
        const secondRequest = this.http.post<OrganizationSummaryViewDataResponse[]>(lastHalfSeasonUrl, requestData, this.httpOptions);
        // adding a few sec time out to utilize horizontal scaling
        // https://learn.microsoft.com/en-us/azure/azure-functions/event-driven-scaling?tabs=azure-cli#understanding-scaling-behaviors
        setTimeout(() => {
            secondRequest.subscribe(
                (data) => lastHalfRequestSubject.next(data)
            );   
        }, (this.getTimeoutDuration(requestData) *1000));

        // Grouping by child and parent
        return zip(firstHalfRequestSubject, lastHalfRequestSubject).pipe(map((data) => {
            const result = [];
            const totalResponse = data[0].concat(data[1]);
            requestData.childStructureIds.forEach(id => {
                const childBaseData = totalResponse.filter(x => x.structureType == requestData.childStructureType && x.structureId == id);
                childBaseData.forEach(calculationChildResponse => {
                    const childResponse: OrganizationSummaryViewDataResponse =
                    {
                        structureId: id,
                        structureType: requestData.childStructureType,
                        totalPlanViewWeekData: [],
                        seasonPlanningType: calculationChildResponse.seasonPlanningType
                    };
                    childResponse.totalPlanViewWeekData = calculationChildResponse.totalPlanViewWeekData;
                    result.push(childResponse);
                });
            });

            const parentBaseData = totalResponse.filter(x => x.structureId == requestData.structureId && x.structureType == requestData.structureType);
            parentBaseData.forEach(parentBaseResponse => {
                const childResponse: OrganizationSummaryViewDataResponse =
                {
                    structureId: parentBaseResponse.structureId,
                    structureType: requestData.structureType,
                    totalPlanViewWeekData: [],
                    seasonPlanningType: parentBaseResponse.seasonPlanningType
                };
                childResponse.totalPlanViewWeekData = parentBaseResponse.totalPlanViewWeekData;
                result.push(childResponse);
            });

            return result;
        }));
    }

    private getTimeoutDuration(req: OrganizationSummaryViewDataRequest): number {
        const defaultTimeout = 5;
        if(req.structureType === StructureTypes.Total){
            return defaultTimeout+3;
        }
        return defaultTimeout;
    }

    private _getRetrievalModes(): RetrievalMode[] {
        let userConfig = this.userConfigService.getUserConfig();
        let isTotalStructureTypeSelected = userConfig.planningViewOptions.structureType == StructureTypes.Total;
        let previousRetrievalSeasonSetting: SeasonSetting;
        let actualRetrievalSeasonSetting: SeasonSetting;
        let comingRetrievalSeasonSetting: SeasonSetting;
        let futureRetrievalSeasonSetting: SeasonSetting;

        if (isTotalStructureTypeSelected) {
            previousRetrievalSeasonSetting =  this.getRetrievalModelForPreviousWithOldOrPreviousPlanningType(userConfig.planningViewLayoutSettings.totalSeasonSettings);
            actualRetrievalSeasonSetting = this.getRetrievalModelBySeasonPlanningType(userConfig.planningViewLayoutSettings.totalSeasonSettings, SeasonPlanningType.Actual);
            comingRetrievalSeasonSetting = this.getRetrievalModelBySeasonPlanningType(userConfig.planningViewLayoutSettings.totalSeasonSettings, SeasonPlanningType.Coming);
            futureRetrievalSeasonSetting = this.getRetrievalModelBySeasonPlanningType(userConfig.planningViewLayoutSettings.totalSeasonSettings, SeasonPlanningType.Future);
        }
        else {
            previousRetrievalSeasonSetting =  this.getRetrievalModelForPreviousWithOldOrPreviousPlanningType(userConfig.planningViewLayoutSettings.seasonSettings);
            actualRetrievalSeasonSetting = this.getRetrievalModelBySeasonPlanningType(userConfig.planningViewLayoutSettings.seasonSettings, SeasonPlanningType.Actual);
            comingRetrievalSeasonSetting = this.getRetrievalModelBySeasonPlanningType(userConfig.planningViewLayoutSettings.seasonSettings, SeasonPlanningType.Coming);
            futureRetrievalSeasonSetting = this.getRetrievalModelBySeasonPlanningType(userConfig.planningViewLayoutSettings.seasonSettings, SeasonPlanningType.Future);
        }

        if (
            previousRetrievalSeasonSetting != undefined &&
            actualRetrievalSeasonSetting != undefined &&
            comingRetrievalSeasonSetting != undefined &&
            futureRetrievalSeasonSetting != undefined
        ) {
            return [previousRetrievalSeasonSetting.retrievalMode, actualRetrievalSeasonSetting.retrievalMode, comingRetrievalSeasonSetting.retrievalMode, futureRetrievalSeasonSetting.retrievalMode]
        }
        else {
            return [RetrievalMode.Bought, RetrievalMode.Bought, RetrievalMode.Plan, RetrievalMode.Plan]
        }
    }

    private getRetrievalModelForPreviousWithOldOrPreviousPlanningType (seasonSettings: SeasonSetting[]) {
        return _.find(seasonSettings, (x: SeasonSetting) =>  x.seasonPlanningType == SeasonPlanningType.PreviousWithOld || x.seasonPlanningType == SeasonPlanningType.Previous);
    }

    private getRetrievalModelBySeasonPlanningType (seasonSettings: SeasonSetting[], seasonPlanningType: SeasonPlanningType) {
        return _.find(seasonSettings, (x: SeasonSetting) =>  x.seasonPlanningType == seasonPlanningType);
    }
}
