import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class SPercentCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets S Percent Weekly

    // Dependencies
    // ------------
    // Add Remove MSek (raw)
    // Add Move MSek (raw)
    // Effective Sales Plan Weekly (calculated)
    // Cube Dem Del Plan (raw)
    // Sales Forecast Weekly (calculated)
    // Outgoing Stock Prognosis Weekly (calculated)
    // Combined Sales Plan Weekly (calculated)
    // Stock Plan Fixed (calculated)
    // Stock Prognosis Ly (raw)

    // Calculates
    // ----------
    // S Percent Weekly


    // Periodic
    // ------
    // Sets S Percent Periodic

    // Dependencies
    // ------------
    // Average of S Percent Weekly(calculated)

    // Calculates
    // ----------
    // S Percent Periodic (calculated)

    addMovesSum = 0;
    addRemoveSum = 0;
    startWeekSalesPlan = 0;
    cubeDemdelPlanSum = 0;
    salesForecastSum = 0;
    combinedSalesSum = 0;

    reset(): void {
        this.addRemoveSum = 0;
        this.addMovesSum = 0;
        this.startWeekSalesPlan = 0;
        this.cubeDemdelPlanSum = 0;
        this.salesForecastSum = 0;
        this.combinedSalesSum = 0;
    }

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        // default to null
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let retrievalMode: RetrievalMode = this.calculatorDriver.getRetrievalMode();
        let seasonInfo = this.calculatorDriver.getSeasonInfo();

        this.addRemoveSum += utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.AddRemoveMSek]) ? (currentWeekItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK) : 0;
        this.addMovesSum += utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.AddMovesMSek]) ? (currentWeekItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK) : 0;
        this.startWeekSalesPlan += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.StartWeekSalesPlanFixed]) ? (currentWeekItem[CalculationDataItemType.StartWeekSalesPlanFixed]) : 0;
        this.cubeDemdelPlanSum += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.CubeDemDelPlan]) ? (currentWeekItem[CalculationDataItemType.CubeDemDelPlan]) : 0;
        this.salesForecastSum += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.SalesForecastWeekly]) ? (currentWeekItem[CalculationDataItemType.SalesForecastWeekly]) : 0;
        this.combinedSalesSum += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) ? (currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) : 0;

        if (retrievalMode == RetrievalMode.Bought || retrievalMode == RetrievalMode.Initial) {
            if (seasonInfo.seasonPlanningType == SeasonPlanningType.Future) {

                let salesForecast = currentWeekItem[CalculationDataItemType.SalesForecastWeekly];
                if (utils.isNotNullUndefinedOrZero(salesForecast) && utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly])) {
                    value = utils.safeDivide(salesForecast, (salesForecast + currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly])) * 100;
                }
            }
            else {
                let combinedSalesPlan = currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly];

                if (utils.isNotNullUndefinedOrZero(combinedSalesPlan) && utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly])) {
                    value = utils.safeDivide(combinedSalesPlan, (combinedSalesPlan + currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly])) * 100;
                }
            }
        }
        else if (retrievalMode == RetrievalMode.Plan) {

            if (seasonInfo.seasonPlanningType == SeasonPlanningType.Actual) {

                let valueX = currentWeekItem[CalculationDataItemType.CubeDemDelPlan] - (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] - currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]);

                let valueY = currentWeekItem[CalculationDataItemType.CubeDemDelPlan] -
                    (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] -
                        currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) +
                    currentWeekItem[CalculationDataItemType.StockPlanFixed] +
                    this.addRemoveSum + this.addMovesSum + this.cubeDemdelPlanSum - this.startWeekSalesPlan - this.combinedSalesSum;

                value = utils.safeDivide(valueX, valueY) * 100;
            }
            else {
                // x = a - (a -b)
                // a -> Dem Del of that week
                // b -> combined dem del of that week

                // Changed from SalesForecastWeekly to CombinedSalesPlanWeekly just as was previously done for same calculation in SPercentCalculator.cs (to get actual selling for first weeks)
                let valueX = currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly];

                // y = x + a  + b + c + d - e
                // a -> Stock plan fixed of that week = i + ii -iii
                // i -> Sum of Purchase Plan till that week excluding purchase plan of start week
                // ii -> Sum of Return Plan till that week
                // iii -> Sum of Dem Del plan till that week including start week dem del plan
                // b -> Sum of Add Remove till that week
                // c -> Sum of Add Move till that week
                // d -> Sum of Dem Del till that week including start week
                // e -> Sum of Combined dem del till that week including start week 

                let valueY = valueX + currentWeekItem[CalculationDataItemType.StockPlanFixed] + this.addRemoveSum + this.addMovesSum + this.cubeDemdelPlanSum - this.salesForecastSum;

                // S% = x/y

                value = utils.safeDivide(valueX, valueY) * 100;
            }
        }

        currentWeekItem[CalculationDataItemType.SPercentWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let sPercentWeeklySum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.SPercentWeekly);
        value = utils.safeDivide(sPercentWeeklySum, currentPeriodWeekItems.length);


        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.SPercentPeriodic] = value;
        });
    }
}
