import { Injectable } from '@angular/core';
import * as Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { FileType } from '../types/file-type';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    private defaultFileName: string = 'export';

    exportData(settings: any): Promise<any> {
      const exportData: any[] = [];
      var dataSource = settings.fullData;
      Object.assign(exportData, dataSource);

      this.removeFields(exportData, settings.ignoreFields || []);

      switch (settings.fileType) {
          case FileType.XLSX:
              return this.exportXLSX(settings);
          default:
              return Promise.resolve(exportData);
      }
  }

  private removeFields(data: any[], fields: string[]): void {
    data.forEach(row => {
        fields.forEach(field => {
            delete row[field];
        });
    });
}

    private exportXLSX(settings: any): Promise<void> {
      let workbook = new Excel.Workbook();
      const fileName = settings.fileName || this.defaultFileName;

      settings.fullData.forEach(dataItem  =>
      {
        let seasonData = dataItem.seasonData;
        var xlsxData: any = [];
        var xlxsHeader = this.extractHeader(seasonData, settings.headerIndex || 0);
        xlsxData.push(xlxsHeader);

        seasonData.forEach(row => {
            var newRow = [];
            for (let i = 0; i < xlxsHeader.length; i++) {
                var fieldName = xlxsHeader[i];
                var rowValue = row[fieldName];
                if (!rowValue) {
                    rowValue = "";
                }
                newRow.push(rowValue);
            }
            xlsxData.push(newRow);
        });

        let worksheet = workbook.addWorksheet(dataItem.seasonType);

        if (settings.freezeX || settings.freezeY) {
            worksheet.views.push(
                { state: 'frozen', ySplit: settings.freezeY || 0, xSplit: settings.freezeX || 0 }
            );
        }

        xlsxData.forEach((d: any) => {
            worksheet.addRow(d);
        });
      });

      return workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, fileName + '.xlsx');
      });
    }

    private extractHeader(array: any[], headerIndex: number): any[] {
        var headerRow: any[] = [];
        Object.keys(array[headerIndex]).forEach(c => {
            headerRow.push(c);
        });
        return headerRow;
    }
}