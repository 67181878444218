import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class EffectiveSalesPlanWithGroundCalculator extends AbstractCalculator
{
    // Dependencies
    // ------------
    // DemDel Plan
    // Cube DemDel Plan
    // Gross Sales Ground
    // Start Week Sales Gross Ground

    // Calculates
    // ----------
    // Effective Sales Plan Weekly With Ground

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.DemDelPlan]))
            value = currentWeekItem[CalculationDataItemType.DemDelPlan];
        else if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.CubeDemDelPlan]))
            value = currentWeekItem[CalculationDataItemType.CubeDemDelPlan];
        else
            value = currentWeekItem[CalculationDataItemType.GrossSalesGround] + (currentWeekItem[CalculationDataItemType.StartWeekAfterCurrentWeek] ? currentWeekItem[CalculationDataItemType.StartWeekSalesGrossGround] : 0);     
        
        currentWeekItem[CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround] = value;
    }
}
