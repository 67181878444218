import { WeekDataItem } from "src/app/modules/shared/types/week-data-item"
import { ParentPlanningViewDataResponse } from "../planning-view-data/parent-planning-view-data-response"
import { OrganizationSummaryViewDataResponse } from "../organization-summary-view-data/organization-summary-view-data-response"

export interface TotalViewRequestDataResponse
{
    lastProcessedResponse: TotalViewRequestData,
    recentTotalViewRequest: MostRecentRequestData,
    defaultRetrievalMode: string
}

export interface TotalViewRequestData
{
    processedData: TotalViewAndOrganizationSummary
    processedTime: string
}

export interface TotalViewAndOrganizationSummary
{
    totalData: TotalViewRequestDataItem[],
    organizationSummaryChildResponse: OrganizationSummaryViewDataResponse[]
}

export interface TotalViewRequestDataItem
{
    seasonName: string,
    parentSeasonData: ParentPlanningViewDataResponse,
    salesAggregatedData: WeekDataItem[]
}

export interface MostRecentRequestData
{
    status: TotalViewRequestStatus,
    requestedTime: string
}

export enum TotalViewRequestStatus
{
    Requested = 1,
    InProgress = 2,
    Failure = 3,
    Success = 4,
    Invalid = 5
}