import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiRoutes } from 'src/app/modules/shared/types/api-routes';
import { environment } from 'src/environments/environment';
import { CacheService } from '../../shared/services/cache-service';
import { Constants } from '../../shared/types/constants';
import { ImpersonatedUserInfo } from '../../shared/types/impersonated-user-info';
import { GamPermission, UserGamPermission, UserInfo, UserSystemPermission } from '../../shared/types/user-info';
import { UserSettingsApiResponse } from '../types/api/user-settings/response/user-settings-api-response';
import { UserSettingsResponse } from '../types/api/user-settings/response/user-settings-response';
import { ImpersonationSettings } from '../types/impersonation-settings';

@Injectable({
  providedIn: 'root'
})
export class UserSettingsService {

  // to be consumed by components
  public userInfoSubject: BehaviorSubject<UserInfo> = new BehaviorSubject<UserInfo>(null);
  private userSettingResponse: UserSettingsResponse = null;

  private userInfo: UserInfo = null;
  private userSettingsUrl = environment.baseApiUrl + ApiRoutes.usersettings;

  constructor(
    private http: HttpClient,
    private _cacheService: CacheService) {
  }

  async getUserInfo() {
    //check the is currrently impersonating
    let impersonationSettings = this.getImpersonationSettings();

    let userSettingsApiResponse: UserSettingsApiResponse;

    try {
      
      // check if data is cached for the current session
      let cachedValue = this._cacheService.getCachedData<UserSettingsApiResponse>(CacheService.cacheKeys.userSettingsApiResponse);
      if(cachedValue != null)
      {
        userSettingsApiResponse = cachedValue;
      }
      else
      {
        userSettingsApiResponse = await this.getUserSettings(impersonationSettings);
        this._cacheService.setCachedData(CacheService.cacheKeys.userSettingsApiResponse, userSettingsApiResponse);
      }     
      
      let userInfo: UserInfo =
      {
        firstName: userSettingsApiResponse.userInfo.firstName,
        lastName: userSettingsApiResponse.userInfo.lastName,
        userName: userSettingsApiResponse.userInfo.userName,
        userId: userSettingsApiResponse.userInfo.userId,
        email: userSettingsApiResponse.userInfo.upn
      }

      // set system permissions
      let userSystemPermissions: UserSystemPermission[] = this.getSystemPermissions(userSettingsApiResponse.userInfo);

      // set gam permissions
      let userGamPermissions: UserGamPermission[] = this.getGamPermissions(userSettingsApiResponse.userInfo);

      userInfo.systemPermissions = userSystemPermissions;
      userInfo.gamPermissions = userGamPermissions;

      this.userInfo = userInfo;

      //find the same values for the impersonated User as well
      if (userSettingsApiResponse.impersonatedUserInfo) {
        let impersonatedUserInfo: ImpersonatedUserInfo =
        {
          firstName: userSettingsApiResponse.impersonatedUserInfo.firstName,
          lastName: userSettingsApiResponse.impersonatedUserInfo.lastName,
          userName: userSettingsApiResponse.impersonatedUserInfo.userName,
          userId: userSettingsApiResponse.impersonatedUserInfo.userId,
          email: userSettingsApiResponse.impersonatedUserInfo.upn
        }

        // set impersonated user system permissions
        let impersonatedUserSystemPermissions: UserSystemPermission[] = this.getSystemPermissions(userSettingsApiResponse.impersonatedUserInfo);

        // set impersonated user gam permissions
        let impersonatedUserGamPermissions: UserGamPermission[] = this.getGamPermissions(userSettingsApiResponse.impersonatedUserInfo);

        impersonatedUserInfo.systemPermissions = impersonatedUserSystemPermissions;
        impersonatedUserInfo.gamPermissions = impersonatedUserGamPermissions;

        this.userInfo.impersonatedUserInfo = impersonatedUserInfo;
      }

      this.userInfoSubject.next(this.userInfo);
    }
    catch (err) {
      this.userInfoSubject.error(null);
    }
  }

  private getGamPermissions(userSettingsResponse: UserSettingsResponse): UserGamPermission[] {

    let userGamPermissions: UserGamPermission[] = [];

    userSettingsResponse.constraints.forEach((constraint) => {
      let userGamPermission: UserGamPermission = {
        typeName: constraint.typeName
      }
      let gamPermissions: GamPermission[] = [];

      constraint.constraintValues.forEach(constraintValue => {
        gamPermissions.push({
          id: constraintValue.businessId,
          name: constraintValue.value,
          explicitAccess: constraintValue.explicitAccess
        });
      });

      userGamPermission.permissions = gamPermissions;

      userGamPermissions.push(userGamPermission);

    });

    return userGamPermissions;
  }

  enableImpersonationAsUser(impersonatedUserEmail: string) {
    let impersonationPermission = this.userInfo.systemPermissions.find(userSystemPermission => userSystemPermission.name == Constants.FUNCTION_NAME_IMPERSONATE_USER)
    if (impersonationPermission != undefined) {
      // set impersonation settings
      impersonationPermission.settings = {
        isCurrentlyImpersonating: true,
        impersonatedUserEmail: impersonatedUserEmail
      }

      localStorage.setItem(Constants.IMPERSONATION_USER_EMAIL_LOCAL_STORAGE_KEY, impersonatedUserEmail);
      this.userInfoSubject.next(this.userInfo);
    }
  }

  disableImpersonation() {
    let impersonationPermission = this.userInfo.systemPermissions.find(userSystemPermission => userSystemPermission.name == Constants.FUNCTION_NAME_IMPERSONATE_USER)
    if (impersonationPermission != undefined) {
      // set impersonation settings
      impersonationPermission.settings = {
        isCurrentlyImpersonating: false,
        impersonatedUserEmail: null
      }

      localStorage.removeItem(Constants.IMPERSONATION_USER_EMAIL_LOCAL_STORAGE_KEY);
      // remove impersonated user object from userinfo
      delete this.userInfo.impersonatedUserInfo;
      this.userInfoSubject.next(this.userInfo);
    }
  }

  private getSystemPermissions(userSettingsResponse: UserSettingsResponse): UserSystemPermission[] {
    let systemPermissions: UserSystemPermission[] = [];
    // check for impersonation access
    if (this.checkForFunctionExistence(userSettingsResponse, Constants.FUNCTION_NAME_IMPERSONATE_USER)) {
      systemPermissions.push(
        {
          name: Constants.FUNCTION_NAME_IMPERSONATE_USER,
          settings: this.getImpersonationSettings()
        }
      )
    }   
    return systemPermissions;
  }

  private getImpersonationSettings() {
    // check for presence of key in local storage
    let impersonationUserEmail = localStorage.getItem(Constants.IMPERSONATION_USER_EMAIL_LOCAL_STORAGE_KEY);

    let impersonationSettings: ImpersonationSettings;

    if (impersonationUserEmail != null && impersonationUserEmail != undefined) {

      // if key exists, the user is currently impersonating 
      impersonationSettings =
      {
        isCurrentlyImpersonating: true,
        impersonatedUserEmail: impersonationUserEmail
      }

    }
    else {
      impersonationSettings =
      {
        isCurrentlyImpersonating: false,
        impersonatedUserEmail: null
      }
    }

    return impersonationSettings;
  }

  private checkForFunctionExistence(userSettingsResponse: UserSettingsResponse, functionName: string): boolean {
    let funcItem = userSettingsResponse.functions.find(functionItem => functionItem.name == functionName);

    return funcItem == undefined ? false : true;
  }

  private async getUserSettings(impersonationSettings: ImpersonationSettings): Promise<UserSettingsApiResponse> {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post<UserSettingsApiResponse>(this.userSettingsUrl, impersonationSettings, httpOptions).toPromise();
  }
}
