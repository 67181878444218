import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { CalculatorInterface } from "../../calculators/calculator-interface";
import { CalculatorTimeSpan } from "../../calculators/calculator-timespan";
import { PlannedCoverageCalculator } from "../../calculators/coverage/planned-coverage-calculator";
import { CSPercentCalculator } from "../../calculators/cs-percent/cs-percent-calculator";
import { BudgetedReturnCalculator } from "../../calculators/others/budgeted-return-calculator";
import { PlannedReturnCalculator } from "../../calculators/others/planned-return-calculator";
import { SPercentCalculator } from "../../calculators/s-percent-calculators/s-percent-calculator";
import { OutgoingStockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from "../../calculators/stock-prognosis-calculators/stock-forecast-calculator";
import { StockPlanFixedCalculator } from "../../calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { StockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/stock-prognosis-calculator";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";


export class RPercentEvent extends DepartmentEvent {

    getEventType(): DepartmentEventType {

        return DepartmentEventType.RPercent;
    }

    validateEvent(driver: CalculatorDriverInterface): boolean {
        return true;
    }

    applyEvent(driver: CalculatorDriverInterface) {

        let utils = driver.getUtilsService();
        let returnsInfo = driver.getReturnsInfo();
        //Null need to be done before toString to avoid error
        if (!utils.isNotNullOrUndefined(this.eventInfo.newValue)) {
            returnsInfo.adjustedBudgetedReturnPercent = null;
            returnsInfo.adjustedReturnPercent = null;
        }
        else if (this.eventInfo.newValue.toString().trim() == "" || !utils.isNotNullOrUndefined(this.eventInfo.newValue)) {
            returnsInfo.adjustedReturnPercent = null
        }
        else {
            returnsInfo.adjustedReturnPercent = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
        }

        driver.setReturnsInfo(returnsInfo);

        // check if adjusted r percent is not null
        if (utils.isNotNullOrUndefined(returnsInfo.adjustedReturnPercent)) {

            if (this.eventInfo.newValue == null) {
                returnsInfo.adjustedBudgetedReturnPercent = null;
            }
            else {
                let seasonInfo = driver.getSeasonInfo();

                // if the event is within the start and end weeks of the current season
                let seasonStartWeek = utils.getStartWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));
                let seasonEndWeek = utils.getEndWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));

                let rangeDataSet = driver.getDataSetRange(seasonStartWeek, seasonEndWeek);
                let plannedReturnSum = 0;
                let budgetedReturnsSum = 0;
                seasonStartWeek = driver.getDataSet()[0][CalculationDataItemType.WeekName];
                rangeDataSet = driver.getDataSetRange(seasonStartWeek, seasonEndWeek);
                // calculate the effective sales plan sum within the period
                rangeDataSet.forEach(rangeDataSetItem => {
                    budgetedReturnsSum += rangeDataSetItem[CalculationDataItemType.AdjustedBudgetedReturn];
                    plannedReturnSum += rangeDataSetItem[CalculationDataItemType.PlannedReturn];
                });

                returnsInfo.returnFactorPercent = utils.safeDivide(plannedReturnSum, budgetedReturnsSum);
                // calculate adjusted br percent
                returnsInfo.adjustedBudgetedReturnPercent = utils.safeDivide(<number>this.eventInfo.newValue, returnsInfo.returnFactorPercent);

                returnsInfo.adjustedBudgetedReturnPercent = utils.roundNumber(returnsInfo.adjustedBudgetedReturnPercent, 2);
            }
            driver.setReturnsInfo(returnsInfo);
        }
        else {
            returnsInfo.adjustedBudgetedReturnPercent = null;
        }
    }

    configureCalculators(driver: CalculatorDriverInterface) {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Budgeted Returns - Weekly
        pass1Calculators.push(new BudgetedReturnCalculator(CalculatorTimeSpan.Weekly, driver));

        // Planned Returns - Weekly
        pass1Calculators.push(new PlannedReturnCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // Planned Coverage Weekly
        pass2Calculators.push(new PlannedCoverageCalculator(CalculatorTimeSpan.Weekly, driver));

        // Stock Plan Fixed Correction - Weekly
        pass2Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, driver));
        // load pass calculators
        calculatorConfiguration.push(pass2Calculators);

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];

        // Stock Forecast - Weekly
        pass3Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, driver));

        // Outgoing Stock Prognosis - Weekly
        pass3Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver));
        // load pass calculators
        calculatorConfiguration.push(pass3Calculators);


        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];
        // Stock Prognosis - Weekly
        pass4Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver))


        // S Percent- Weekly
        pass4Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, driver))
        // load pass calculators
        calculatorConfiguration.push(pass4Calculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];
        // S Percent- Period
        pass5Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, driver))

        // Stock Prognosis - Periodic
        pass5Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, driver))

        // CS Percent - Seasonal
        pass5Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, driver))

        calculatorConfiguration.push(pass5Calculators);

        driver.setCalculatorConfiguration(calculatorConfiguration);
    }
}