import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { Constants } from "../../types/constants";
import { AbstractCalculator } from "../abstract-calculator";

export class DiffFinPlanCalculator extends AbstractCalculator {

    _calculatePeriodicValue(currentWeekItem: ParentCalculationDataItem, currentPeriodWeekItems: ParentCalculationDataItem[]) {

        let value = null;
        let utils = this.calculatorDriver.getUtilsService();

        let netSalesPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedNetSalesWeekly); // its in tsek
        let finPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.FinPlanMSek);  // its in msek

        if (utils.isNotNullUndefinedOrZero(finPlanSum)) {
            value = (((netSalesPlanSum / Constants.TSEKTOMSEK) / finPlanSum) - 1) * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.DiffFinPlanMSek] = value;
        });
    }
}