export class ApiRoutes {
  static readonly selectionPage: string = 'selection-page';
  static readonly assortmentPlanningView: string = 'assortment-planning-view';
  static readonly assortmentCopyData: string = "assortment-copy-data";
  static readonly assortmentCheckNegativePlans: string = "assortment-check-negative-plans";
  static readonly salesCopyData: string = "sales-copy-data";
  static readonly salesPlanningView: string = 'sales-planning-view';
  static readonly usersettings: string = 'user-settings';
  static readonly validateuserEmail: string = 'validate-user-email';
  static readonly getdataqueries: string = 'raw-data-queries';
  static readonly seasonInfo: string = "season-data";

  static readonly salesplanningviewdata: string = 'sales-planning-view-data';
  static readonly assortmentplanningviewdata: string = 'assortment-planning-view-data';
  static readonly assortmentPublishedSeasonStatusData: string = 'assortment-publish-status';

  static readonly parentSalesplanningviewdata: string = 'sales-parentlevel-planning-view-data';
  static readonly parentAssortmentplanningviewdata: string = 'assortment-parentlevel-planning-view-data';

  static readonly readonlyDepartmentSalesplanningviewdata: string = 'sales-readonly-Department-planning-view-data';
  static readonly readonlyDepartmentAssortmentplanningviewdata: string = 'assortment-readonly-Department-planning-view-data';

  static readonly assortmentaggregatedmainsplanningviewdata: string = 'assortment-aggregated-mains-data';

  static readonly salesaggregatedpmmainsplanningviewdata: string = 'sales-aggregated-pm-main-data';
  static readonly salesSystemGoalInAssortmentData: string = 'sales-system-goal-for-assortment';

  static readonly totalViewRequests: string = "totalview-request-status";
  static readonly generateTotalViewRequest: string = "create-totalview-request";

  static readonly organizationSummaryViewData: string = "organization-summary-view";

  static readonly assortmentUserCopydata: string = 'assortment-copy';
  static readonly assortmentCopyLockStatus: string = 'assortment-copy-lock-status';
  static readonly salesUserCopydata: string = 'sales-copy';
  static readonly salesCopyLockStatus: string = 'sales-copy-lock-status';
  static readonly salesCopyTrickleDownFailedStatus: string = 'sales-copy-trickle-down-failed-status';
  static readonly salesStashStatusByCopy: string = "sales-copy-stash-status";

  static readonly assortmentCheckStashedInput: string = "check-assortment-stash-status";
  static readonly salesCheckStashedInputByCopyMarketStructure: string = "sales-stash-status-by-copy-structure-market";

  static readonly publishAssortmentData: string = 'publish-assortment-data';
  static readonly publishSalesData: string = 'publish-sales-data';
  static readonly mergeassortmentUserCopy: string = 'merge-assortment-copy';
  static readonly mergeSalesUserCopy: string = 'merge-sales-copy';
  static readonly salesParentTrickleDownSaveByCopy: string = 'sales-parent-trickle-down-save-by-copy';
  static readonly salesParentTrickleDownSaveByCopyMarketStructure: string = "sales-parent-trickle-down-save-by-copy-market-structure";
  static readonly appConfigData: string = 'app-conf-data'
  static readonly cubeInformation: string = 'cube-info'
}
