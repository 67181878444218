import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { AbstractCalculator } from "../abstract-calculator";

export class BuyingCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Add/Remove
    // Purchase Plan
    // Bought Gross
    //Ordered Gross Ground

    // Calculates
    // ----------
    // buying


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let retrievalMode: RetrievalMode = this.calculatorDriver.getRetrievalMode();

        let startWeekForCurrentSeason = utils.getStartWeekForSeason(parseInt(this.calculatorDriver.getSeasonInfo().seasonCodeNames[0]));

        let addRemoveMSek = (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.AddRemoveMSek]) ?
            (currentWeekItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK) :
            0);

        if (retrievalMode == RetrievalMode.Initial) {
            let currentPeriodOrderedGrossGroundSum = 0;

            //check if the start week falls within the current period. If so, add up all OrderedGrossGround from past weeks too
            let isStartWeekWithinCurrentPeriod = false;
            currentPeriodWeekItems.forEach(weekItem => {
                if(weekItem[CalculationDataItemType.WeekName] == startWeekForCurrentSeason)
                {
                    isStartWeekWithinCurrentPeriod = true;
                }
            });

            if(isStartWeekWithinCurrentPeriod)
            {
                let orderedGrossGroundForPastWeeks = 0;
                let dataSet = this.calculatorDriver.getDataSet();

                dataSet.forEach(item=>
                    {
                        if(item[CalculationDataItemType.WeekName] < currentPeriodWeekItems[0][CalculationDataItemType.WeekName])
                        {
                            orderedGrossGroundForPastWeeks += item[CalculationDataItemType.OrderedGrossGround]
                        }                            
                    });
                
                currentPeriodOrderedGrossGroundSum += orderedGrossGroundForPastWeeks;
            }

            if(currentWeekItem[CalculationDataItemType.WeekName] >= startWeekForCurrentSeason)
            {
                currentPeriodOrderedGrossGroundSum += utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.OrderedGrossGround);
            }

            value = (currentPeriodOrderedGrossGroundSum / 4) + addRemoveMSek;
        }
        else if (retrievalMode == RetrievalMode.Bought) {
            value = currentWeekItem[CalculationDataItemType.BoughtGross] + addRemoveMSek;
        }
        else if (retrievalMode == RetrievalMode.Plan) {
            value = currentWeekItem[CalculationDataItemType.PurchasePlanFixed] + addRemoveMSek;
        }

        currentWeekItem[CalculationDataItemType.BuyingWeekly] = value;
    }
}