import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class CombinedSalesPlanCalculator extends AbstractCalculator
{
    // Dependencies
    // ------------
    // Gross Sales (raw)
    // Gross Sales Ground (raw)
    // Effective Sales Plan Weekly (calculated)
    // Forecast Goal Periodic (calculated)
    // Sales Forecast Weekly (calculated)


    // Calculates
    // ----------
    // Combined Sales Plan Weekly (calculated)

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        let viewPeriodWeeks = this.calculatorDriver.getViewPeriodWeeksWithYear();

        //If gross is not zero or week is past the current view week
        if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSales]) || currentWeekItem[CalculationDataItemType.WeekName] < this.calculatorDriver.getViewWeekWithYear()) {
            
            value = currentWeekItem[CalculationDataItemType.GrossSales];

        }
        else if(viewPeriodWeeks.indexOf(currentWeekItem[CalculationDataItemType.WeekName]) != -1)
        {
            value = (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.DemDelPlan])) ? 
                            currentWeekItem[CalculationDataItemType.DemDelPlan] : 
                            currentWeekItem[CalculationDataItemType.SalesForecastWeekly]
        }
        else {

            // get the combined sales value for this week
            value = currentWeekItem[CalculationDataItemType.SalesForecastWeekly];
        }

        currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly] = value
    }
}
