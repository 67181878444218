import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { DriverType } from "../../types/driver-type";
import { AbstractCalculator } from "../abstract-calculator";

export class EffectiveSalesPlanCalculator extends AbstractCalculator
{
    // Dependencies
    // ------------
    // DemDel Plan
    // Cube DemDel Plan
    // Gross Sales Ground
    // Start Week Sales Gross Ground

    // Calculates
    // ----------
    // Effective Sales Plan Weekly

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        // On Parent level, If the forecast gross value is removed, then Effective Sales Plan will fall back to an aggregated value of Published demdel plan, which may not always be correct since the precedence order is not taken into consideration.
        // EffectiveSystemGoalPlan has the default value stored in it. Use that instead
        if((this.calculatorDriver.getDriverType() == DriverType.ParentSeasonDriver || this.calculatorDriver.getDriverType() == DriverType.ParentTotalSeasonDriver) && utils.isNullOrUndefined(currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodic]))
        {
            value = currentWeekItem[CalculationDataItemType.EffectiveSystemGoalPlanWeekly];
        }
        else
        {
            if(utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.DemDelPlan]))
            {
                value = currentWeekItem[CalculationDataItemType.DemDelPlan]
            }
            else if(utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.CubeDemDelPlan]))
            {
                value = currentWeekItem[CalculationDataItemType.CubeDemDelPlan]
            }
            //TODO Commented to fix the precedence order 
            // else if(utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGround]))
            // {
            //     value = currentWeekItem[CalculationDataItemType.GrossSalesGround];
            // }
        }       
        
        currentWeekItem[CalculationDataItemType.EffectiveSalesPlanWeekly] = value;
    }
}