import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class CombinedSalesLyCalculator extends AbstractCalculator {
    // Dependencies  
    // ----------


    // Calculates
    // ----------
    // Combined SalesLy Weekly

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        if (utils.isNotNullUndefinedOrZero(currentWeekItem.grossSalesLy)) {

            value = currentWeekItem.grossSalesLy;
        }      
        else {
            value = currentWeekItem.salesPlanLy;
        }
        currentWeekItem[CalculationDataItemType.CombinedSalesLyWeekly] = value
    }
}
