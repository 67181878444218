import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UtilsService } from "src/app/modules/shared/services/utils.service";
import { SaveWarningDialogData, SaveWarningDialogDataDetails } from "../../types/save-warning-dialog-data";

@Component({
    selector: 'save-warning-trickle-down-dialog.component',
    templateUrl: "./save-warning-trickle-down-dialog.component.html",
    styleUrls: ['./save-warning-trickle-down-dialog.component.css']
})

export class SaveWarningTrickleDownDialogComponent {


    isErrorOccured: boolean = true;
    errorMessage: string;

    comingSeasonNegPurchaseWeeks: SaveWarningDialogDataDetails[] = [];
    futureSeasonNegPurchaseWeeks: SaveWarningDialogDataDetails[] = [];
    comingSeasonNegCoverageWeeks: SaveWarningDialogDataDetails[] = [];
    futureSeasonNegCoverageWeeks: SaveWarningDialogDataDetails[] = [];

    constructor(
        private dialogRef: MatDialogRef<SaveWarningTrickleDownDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: SaveWarningDialogData,
        private _utils: UtilsService) {

        this.comingSeasonNegPurchaseWeeks = dialogData.comingSeasonNegPurchaseWeeks;
        this.futureSeasonNegPurchaseWeeks = dialogData.futureSeasonNegPurchaseWeeks;
        this.comingSeasonNegCoverageWeeks = dialogData.comingSeasonNegCoverageWeeks;
        this.futureSeasonNegCoverageWeeks = dialogData.futureSeasonNegCoverageWeeks;
    }
    copyListToClipboard() {
        let negativePlanDetails = this.getConfigurationText();
        this._utils.copyTextToClipboard(negativePlanDetails);
        this._utils.showGeneralMessage("List Copied");
    }


    getConfigurationText(): string {

        let negativePlanDetails = "";

        if (this.comingSeasonNegPurchaseWeeks.length > 0 || this.futureSeasonNegPurchaseWeeks.length > 0) {
            negativePlanDetails += "The below listed weeks and departments have negative purchase plans";

            if (this.comingSeasonNegPurchaseWeeks.length > 0) {
                negativePlanDetails += + "\n" + "\n" + "Season Name:" + this.comingSeasonNegPurchaseWeeks[0].seasonName;

                this.comingSeasonNegPurchaseWeeks.forEach(plan => {
                    negativePlanDetails += "\n" + "------------------------------------------" + "\n";
                    negativePlanDetails += plan.departmentName;
                    plan.weekNames.forEach(weekname => {
                        negativePlanDetails += "\n" + weekname;
                    })
                });
            }

            if (this.futureSeasonNegPurchaseWeeks.length > 0) {
                negativePlanDetails += "\n" + "\n" + "Season Name:" + this.futureSeasonNegPurchaseWeeks[0].seasonName;

                this.futureSeasonNegPurchaseWeeks.forEach(plan => {
                    negativePlanDetails += "\n" + "------------------------------------------" + "\n";
                    negativePlanDetails += plan.departmentName;
                    plan.weekNames.forEach(weekname => {
                        negativePlanDetails += "\n" + weekname;
                    })
                });
            }
        }
        if (this.comingSeasonNegCoverageWeeks.length > 0 || this.futureSeasonNegCoverageWeeks.length > 0) {

            if (this.comingSeasonNegCoverageWeeks.length > 0) {
                negativePlanDetails += "The below listed weeks and departments have negative planned coverage and cannot be published from Department Plan.";

                if (this.comingSeasonNegCoverageWeeks.length > 0) {
                    negativePlanDetails += "\n" + "\n" + "Season Name:" + this.comingSeasonNegCoverageWeeks[0].seasonName;

                    this.comingSeasonNegCoverageWeeks.forEach(plan => {
                        negativePlanDetails += "\n" + "------------------------------------------" + "\n";
                        negativePlanDetails += plan.departmentName;
                        plan.weekNames.forEach(weekname => {
                            negativePlanDetails += "\n" + weekname;
                        })
                    });
                }
            }
            if (this.futureSeasonNegCoverageWeeks.length > 0) {
                negativePlanDetails += "\n" + "\n" + "Season Name:" + this.futureSeasonNegCoverageWeeks[0].seasonName;

                this.futureSeasonNegCoverageWeeks.forEach(plan => {
                    negativePlanDetails += "\n" + "------------------------------------------" + "\n";
                    negativePlanDetails += plan.departmentName;
                    plan.weekNames.forEach(weekname => {
                        negativePlanDetails += "\n" + weekname;
                    })
                });
            }

        }

        return negativePlanDetails;
    }

}