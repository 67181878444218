<table class='seasonTable' attr.data-season-name="{{driver.getSeasonInfo().seasonPlanningType.toString()}}"
    (paste)="pasteIntoCellListener($event)" (copy)="copyFromCellListener($event)">
    <tbody>
        <!-- 1. Title -->
        <tr class='seasonTableRow'>
            <td class='seasonTableRowHeader'>Season</td>
            <td class='seasonTableRowCellSeasonMerge' [attr.colspan]='seasonInfo?.weeks.length'>
                <span style="font-size: 14px"> {{seasonTitle }}</span>
                <span *ngIf="seasonTitle != 'All Seasons'">
                    <span style="float: right; margin-left: 15px;">
                        <select [ngModel]="driver.getRetrievalMode()"
                            (change)="handleEvent($event, null, 'RetrievalMode')">
                            <option *ngFor="let retrievalMode of availableRetrievalModes">{{retrievalMode}}</option>
                        </select>
                    </span>
                    <span style="float: left;">

                        <img alt="clear all" class="clear-all-btn" src="/assets/images/erase.png"
                            *ngIf="!isMain && driver.touched && (driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3)"
                            (click)="openClearDialog($event)" data-toggle="tooltip" title="Clear All" />

                    </span>
                    <span style="float: left; margin-left: 5px;">
                        <mat-icon *ngIf="driver.getInputLockStatus()==0" [inline]="true" (click)="lockInputs()"
                            data-toggle="tooltip" title="click to lock input fields" class="lock-icon">
                            lock_open
                        </mat-icon>
                        <mat-icon *ngIf="driver.getInputLockStatus()==1" [inline]="true" (click)="unlockInputs()"
                            data-toggle="tooltip" title="click to unlock input fields" class="lock-icon">
                            lock
                        </mat-icon>
                        <mat-icon *ngIf="driver.getInputLockStatus()==2" [inline]="true" data-toggle="tooltip"
                            title="click to unlock input fields" class="lock-icon lock-disabled">
                            lock
                        </mat-icon>
                        <mat-icon *ngIf="driver.getInputLockStatus()==3" [inline]="true" data-toggle="tooltip"
                            title="click to lock input fields" class="lock-icon lock-disabled">
                            lock_open
                        </mat-icon>
                        <mat-icon *ngIf="driver.getInputLockStatus()==3" [inline]="true" data-toggle="tooltip"
                        title="click to lock input fields" class="lock-icon lock-disabled">
                        lock_open
                        </mat-icon>
                    </span>
                    <span>
                        <mat-icon *ngIf="!isAssortmentView && isPRCopy && driver.hasSalesAggregatedPmDataSetLoaded
                            && driver.getInputLockStatus()==0" [inline]="true" (click)="openCopyForecastDialog()"
                             data-toggle="tooltip"title="click to copy PM forecast goals" class="copy-goal-btn">
                             low_priority
                        </mat-icon>
                    </span>

                    <div style="float: right; font-weight:normal">
                        <div *ngIf="channel === 2" style="display:inline-block;">
                            <span class="online-returns-section">R% : <span class="online-returns-value"
                                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                                    [title]="returnsInfo?.unAdjustedReturnPercent">{{returnsInfo?.unAdjustedReturnPercent}}</span></span>
                            <span class="material-icons" *ngIf="driver.rowInputsDirty || driver.rPercentDirty"
                                style="vertical-align: middle; cursor: pointer;" data-toggle="tooltip"
                                title="Please save plan to view accurate returns">
                                feedback
                            </span>
                            |
                            <span class="online-returns-section">BR% : <span class="online-returns-value"
                                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                                    [title]="returnsInfo?.unAdjustedBudgetedReturnPercent">{{returnsInfo?.unAdjustedBudgetedReturnPercent}}</span></span>
                            |
                            <span class="online-returns-section">Adj. BR% : <span class="online-returns-value"
                                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                                    [title]="returnsInfo?.adjustedBudgetedReturnPercent">{{returnsInfo?.adjustedBudgetedReturnPercent
                                    == null ? "-" : returnsInfo?.adjustedBudgetedReturnPercent }}</span></span>
                            |
                            <span class="online-returns-section">Adj. R% :
                                <!-- <span class="online-returns-value"
                                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                                    [title]="returnsInfo?.adjustedReturnPercent">{{returnsInfo?.adjustedReturnPercent ==
                                    null ? "-" : returnsInfo?.adjustedReturnPercent }}</span> -->
                                <input *ngIf="returnsInfo!=null && returnsInfo!=undefined" class="online-returns-value"
                                    id="planningview-txtadjrpercent" attr.data-season-name="{{seasonTitle}}"
                                    data-toggle="tooltip" type='text' [(ngModel)]="returnsInfo.adjustedReturnPercent"
                                    [disabled]="isMain || seasonInfo?.seasonPlanningType == 'PreviousWithOld' || seasonInfo?.seasonPlanningType == 'Previous' || returnsInfo?.returnFactorPercent == null || returnsInfo?.returnFactorPercent == 0 || driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2 || driver.rowInputsDirty"
                                    [ngClass]="{'seasonTableRowCellEnabled': (!isMain && seasonInfo?.seasonPlanningType != 'PreviousWithOld' && seasonInfo?.seasonPlanningType != 'Previous' && returnsInfo?.returnFactorPercent != null && returnsInfo?.returnFactorPercent != 0 && (driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3)) && !driver.rowInputsDirty}"
                                    (blur)="handleEvent($event, null, 'RPercent')"
                                    (keydown.enter)="$event.target.blur()" class="season-table-header-input"
                                    oninput="this.value=this.value.replace(/[^0-9.,]/g,'');"
                                    (input)="validateRPercentInput($event, null, 'AdjustedRPercent')" />
                            </span>
                            |
                        </div>
                        <span *ngIf="!isChannelSum && channel !=3" class="online-returns-section">CS% : <span
                                class="online-returns-value" data-toggle="tooltip"
                                [title]="driver.getCSPercent() === null || driver.getCSPercent() === undefined? '': driver.getCSPercent().toString()">{{
                                driver.getCSPercent() === null || driver.getCSPercent() === undefined? '':
                                (driver.getCSPercent().toString() | number: '1.0-0')}}</span></span>
                    </div>

                </span>

            </td>

        </tr>

        <!-- 2. Week -->
        <tr class='seasonTableRow seasonTableRowWeeks'>
            <td class='seasonTableRowHeader'>Week</td>
            <ng-container *ngFor="let weekWithYear of seasonInfo?.weeksWithYear; let index=index;">
                <td data-toggle="tooltip"
                    [title]="weekWithYear != lastWeekInCurrentView ? weekWithYear : weekWithYear + ' - View will change after this week'"
                    class="seasonTableRowCell"
                    [ngClass]="{'currentWeek': weekWithYear == currentWeek ,'quarterBorder': quarterBorderForWeeks[index]==true}">
                    {{weekWithYear.toString().substring(4) }}<span
                        *ngIf="weekWithYear == lastWeekInCurrentView">*</span></td>
            </ng-container>

        </tr>

        <!-- 3. Period -->
        <tr class='seasonTableRow seasonTableRowPeriods'>
            <td class='seasonTableRowHeader'>Period</td>
            <td data-toggle="tooltip" [title]="period" class="seasonTableRowCell4Merge" colspan='4'
                [ngClass]="{'quarterBorder': quarterBorderForPeriods[index]==true}"
                *ngFor="let period of seasonInfo?.periods;let index=index;">
                {{period }}</td>
        </tr>

        <!-- 4. Quarter -->
        <tr class='seasonTableRow seasonTableRowQuarters'>
            <td class='seasonTableRowHeader'>H&M Quarter</td>
            <ng-container *ngFor="let quarter of quarters">
                <td data-toggle="tooltip" [title]="quarter.name" class="seasonTableRowCell12Merge quarterBorder"
                    [attr.colspan]='quarter.colspan'>
                    {{quarter.name }}</td>
            </ng-container>
        </tr>

        <!-- 5. Omni Takt Periodic -->
        <!-- <tr class='seasonTableRow seasonTableRowOmniTaktPeriod'>
            <td class='seasonTableRowHeader'>Omni Takt/Goal</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidOmniTaktWeeks; else validOmniTaktWeeks">
                </ng-container>
                <ng-template #invalidOmniTaktWeeks>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'>
                    </td>
                </ng-template>
                <ng-template #validOmniTaktWeeks>
                    <td data-toggle="tooltip"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.omniTaktPeriodic === null || dataItem.omniTaktPeriodic === undefined? '': dataItem.omniTaktPeriodic.toFixed(3))"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{dataItem.omniTaktPeriodic | number: '1.0-0' }}
                    </td>
                </ng-template>
            </ng-container>
        </tr> -->

        <!-- 5. Omni Takt Periodic -->
        <tr class='seasonTableRow seasonTableRowOmniTaktPeriod'
            *ngIf="selectedKpis.indexOf('Omni Takt/Goal') != -1 && channelType.toString()!='3' && ( brandId != 1 || isAssortmentView)">
            <td class='seasonTableRowHeader'>Omni Takt/Goal</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidOmniTaktWeeks; else validOmniTaktWeeks">
                </ng-container>
                <ng-template #invalidOmniTaktWeeks>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validOmniTaktWeeks>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.omniTaktPeriodic === null || dataItem.omniTaktPeriodic === undefined? '': dataItem.omniTaktPeriodic.toFixed(3))"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{dataItem.omniTaktPeriodic | number: '1.0-0' }}
                    </td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 6. Net Takt Periodic -->
        <tr class='seasonTableRow seasonTableRowNetTaktPeriod' *ngIf="selectedKpis.indexOf('Net Takt/Goal') != -1">
            <td class='seasonTableRowHeader'>Net Takt/Goal</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">

                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidNetTaktWeeks; else validNetTaktWeeks">
                </ng-container>
                <ng-template #invalidNetTaktWeeks>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validNetTaktWeeks>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}" [title]="dataItem.netTaktPeriodic"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>{{dataItem.netTaktPeriodic |
                        number: '1.0-0' }}</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- System Goal Periodic -->
        <tr class='seasonTableRow seasonTableRowSystemGoalPeriodic' *ngIf="selectedKpis.indexOf('System Goal') != -1">
            <td class='seasonTableRowHeader'>System Goal</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidGrossTaktWeeks; else validGrossTaktWeeks">
                </ng-container>
                <ng-template #invalidGrossTaktWeeks>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validGrossTaktWeeks>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}" *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge'
                        colspan='4'>
                        <ng-container
                            *ngIf="!isAssortmentView && !isMain; then sysSalesGoalWithAssortment; else sysAssortmentGoal">
                        </ng-container>
                        <ng-template #sysSalesGoalWithAssortment>
                            <span *ngIf="!isAssortmentView && channel != 3">
                                <img *ngIf="!driver.hasSalesAggregatedPmDataSetLoaded"
                                    src="./assets/images/ellipsis-animated.gif" alt="Loading" />
                                <span
                                    *ngIf="driver.hasSalesAggregatedPmDataSetLoaded && (dataItem.marketMainCopySystemGoal === null || dataItem.marketMainCopySystemGoal === undefined)">-</span>
                                <span
                                    *ngIf="driver.hasSalesAggregatedPmDataSetLoaded && dataItem.marketMainCopySystemGoal != null && dataItem.marketMainCopySystemGoal != undefined"
                                    [title]="(dataItem.marketMainCopySystemGoal === null || dataItem.marketMainCopySystemGoal === undefined? '': dataItem.marketMainCopySystemGoal.toFixed(3))">
                                    {{dataItem.marketMainCopySystemGoal | number: '1.0-0'}}</span>
                            </span>
                            <span
                                [title]="(dataItem.systemGoalPeriodic === null || dataItem.systemGoalPeriodic === undefined? '': dataItem.systemGoalPeriodic.toFixed(3))">
                                ({{dataItem.systemGoalPeriodic | number: '1.0-0' }})
                            </span>
                        </ng-template>
                        <ng-template #sysAssortmentGoal>
                            <span
                                [title]="(dataItem.systemGoalPeriodic === null || dataItem.systemGoalPeriodic === undefined? '': dataItem.systemGoalPeriodic.toFixed(3))">
                                {{dataItem.systemGoalPeriodic | number: '1.0-0' }}
                            </span>
                            <!-- Sales goal for Assortment  -->
                            <span *ngIf="isAssortmentView && areSalesSystemGoalsIncludedForAssortment">
                                (
                                <img *ngIf="!driver.hasSalesSystemGoalInAssortmentLoaded"
                                    src="./assets/images/ellipsis-animated.gif" alt="Loading" />
                                <span
                                    *ngIf="driver.hasSalesSystemGoalInAssortmentLoaded && (dataItem.salesMainCopySystemGoal === null || dataItem.salesMainCopySystemGoal === undefined)">-</span>
                                <span
                                    *ngIf="driver.hasSalesSystemGoalInAssortmentLoaded && dataItem.salesMainCopySystemGoal != null && dataItem.salesMainCopySystemGoal != undefined"
                                    [title]="(dataItem.salesMainCopySystemGoal === null || dataItem.salesMainCopySystemGoal === undefined? '': dataItem.salesMainCopySystemGoal.toFixed(3))">
                                    {{dataItem.salesMainCopySystemGoal | number: '1.0-0'}}
                                </span>
                                )
                            </span>
                        </ng-template>
                    </td>
                </ng-template>
            </ng-container>
        </tr>



        <!-- 7. Gross Takt Periodic -->
        <tr class='seasonTableRow seasonTableRowGrossTaktPeriod' *ngIf="selectedKpis.indexOf('Takt/Goal') != -1">
            <td class='seasonTableRowHeader'>
                <ng-container [ngTemplateOutlet]="(!isAssortmentView && channel != 3 && selectedMartketType== 'PlanningRegion' &&  isPRCopy) ? grossTaktHeaderWithAggregatedPms 
                                            :(!isAssortmentView && channel != 3 && selectedMartketType== 'PlanningMarket' && isPRCopy) ? grossTaktHeaderWithPMMain 
                                            : grossTaktHeader">
                </ng-container>
                <ng-template #grossTaktHeaderWithAggregatedPms>
                    Gross Takt/Goal (PM)
                </ng-template>
                <ng-template #grossTaktHeaderWithPMMain>
                    Gross Takt/Goal (Main)
                </ng-template>
                <ng-template #grossTaktHeader>
                    Gross Takt/Goal
                </ng-template>
            </td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidGrossTaktWeeks; else validGrossTaktWeeks">
                </ng-container>
                <ng-template #invalidGrossTaktWeeks>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validGrossTaktWeeks>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.grossTaktPeriodic === null || dataItem.grossTaktPeriodic === undefined? '': dataItem.grossTaktPeriodic)"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>{{dataItem.grossTaktPeriodic
                        | number: '1.0-0' }}
                        <span *ngIf="!isAssortmentView && channel != 3 && isPRCopy && dataItem.aggregatedPMsIsSaved"
                            class="aggregated-pm-span"
                            [title]="(dataItem.aggregatedPMsGrossTaktPeriodic === null || dataItem.aggregatedPMsGrossTaktPeriodic === undefined? '': dataItem.aggregatedPMsGrossTaktPeriodic)">
                            ({{dataItem.aggregatedPMsGrossTaktPeriodic === null ||
                            dataItem.aggregatedPMsGrossTaktPeriodic === undefined? '-':
                            dataItem.aggregatedPMsGrossTaktPeriodic
                            | number: '1.0-0' }})
                        </span>
                        <span *ngIf="!isAssortmentView && channel != 3 && isPRCopy && !dataItem.aggregatedPMsIsSaved"
                            class="aggregated-pm-span">
                            (<img *ngIf="!driver.hasSalesAggregatedPmDataSetLoaded"
                                src="./assets/images/ellipsis-animated.gif" alt="Loading" />
                            <span *ngIf="driver.hasSalesAggregatedPmDataSetLoaded">-</span>)
                        </span>
                    </td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 8. Forecast Periodic -->
        <ng-container *ngIf="seasonTitle != 'All Seasons'">
            <tr class='seasonTableRow seasonTableRowForecastPeriod'>
                <td class='seasonTableRowHeader'>Forecast Gross</td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <ng-container *ngIf="dataIndex%4==0">
                        <td [attr.data-period-weeks]="driver.getInputLockStatus()"
                            *ngIf="(!isForecastEnabledList[dataIndex])"
                            class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                            [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"><input
                                class="seasonTableRowCellDisabled" disabled type='text' /></td>
                        <td [attr.data-period-weeks]="driver.getInputLockStatus()" data-toggle="tooltip"
                            [title]="(dataItem.inputForecastGrossPeriodic === null || dataItem.inputForecastGrossPeriodic === undefined? '': dataItem.inputForecastGrossPeriodic)"
                            *ngIf="(isForecastEnabledList[dataIndex])" class='seasonTableRowCell4Merge'
                            [ngClass]="{'seasonTableRowCellDisabled': areInputsDisabled,'seasonTableRowCellEnabled':!areInputsDisabled,'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true,
                        'InputFieldsLocked': driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2 || driver.rPercentDirty,'InputFieldsUnLocked':(driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3) && !driver.rPercentDirty}"
                            colspan='4'>
                            <div *ngIf="driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2 || driver.rPercentDirty"
                                style="padding: 1px 2px;">
                                {{dataItem.inputForecastGrossPeriodic}}
                            </div>
                            <input
                                *ngIf="(driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3) && !driver.rPercentDirty"
                                type='text' data-cell-type="forecast-gross-input"
                                [attr.data-season-planning-type]="driver.getSeasonInfo().seasonPlanningType.toString()"
                                [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                                attr.data-season-name="{{seasonTitle}}"
                                [ngClass]="areInputsDisabled || driver.rPercentDirty? 'seasonTableRowCellDisabled' : 'seasonTableRowCellEnabled'"
                                [disabled]="areInputsDisabled || driver.rPercentDirty"
                                [(ngModel)]="dataItem.inputForecastGrossPeriodic"
                                (blur)="handleEvent($event, dataIndex + dataSetIndexOffset, 'ForecastGross')"
                                (keydown.enter)="$event.target.blur()" maxlength="6"
                                oninput="this.value=this.value.replace(/[^0-9.]/g,'');" />
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-container>

        <!-- 8.0 Add/Remove (MSEK)(PM) Weekly -->
        <ng-container *ngIf="!isAssortmentView && seasonTitle != 'All Seasons' && isPRCopy">
            <tr class='seasonTableRow seasonTableRowAddRemoveMsek' >
                <td class='seasonTableRowHeader' style="font-size:0.9em"> <!-- Less font size to make long text fit cell -->
                    <ng-container [ngTemplateOutlet]="channel != 3 ? addRemoveMsekAggregatedPms : addRemoveMsekAggregatedNotNeeded"></ng-container>              
                    <ng-template #addRemoveMsekAggregatedPms>PM Add/Remove (MSEK)</ng-template>                
                </td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <td data-toggle="tooltip" attr.data-week-name="{{dataItem.weekName}}"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                        attr.data-season-name="{{seasonTitle}}"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.aggregatedPMsAddRemoveMSek === null || dataItem.aggregatedPMsAddRemoveMSek === undefined? '': dataItem.aggregatedPMsAddRemoveMSek.toFixed(3)}}"
                        class='seasonTableRowCell'>
                            <div *ngIf="dataItem.aggregatedPMsAddRemoveMSek != 0">{{ dataItem.aggregatedPMsAddRemoveMSek | number: '1.0-0' }}</div>
                    </td>
                </ng-container>
            </tr>
        </ng-container>

        <!-- 8.1 Add/Remove (MSEK) Weekly -->
        <ng-container *ngIf="seasonTitle != 'All Seasons'">
            <tr class='seasonTableRow seasonTableInputRow seasonTableRowAddRemoveMsek'>
                <td class='seasonTableRowHeader'>Add/Remove (MSEK)</td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">

                    <td *ngIf="!isWeeklyInputEnabledList[dataIndex]"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                        class='seasonTableRowCell seasonTableRowCellDisabled'><input class='seasonTableRowCellDisabled'
                            disabled type='text' /></td>

                    <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                        [ngClass]="{'seasonTableRowCellDisabled': (areInputsDisabled || dataItem.weekName > seasonEndWeek),'seasonTableRowCellEnabled':(areInputsDisabled || dataItem.weekName > seasonEndWeek) == false ,'quarterBorder': quarterBorderForWeeks[dataIndex]==true,
                        'InputFieldsLocked': (driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2) || dataItem.weekName > seasonEndWeek,
                        'InputFieldsUnLocked': (driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3 ) && dataItem.weekName <= seasonEndWeek}" data-toggle="tooltip"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.inputAddRemoveMSekWeekly === null || dataItem.inputAddRemoveMSekWeekly === undefined? '': dataItem.inputAddRemoveMSekWeekly}}"
                        *ngIf="isWeeklyInputEnabledList[dataIndex]" class='seasonTableRowCell '>
                        <div
                            *ngIf="driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2  || dataItem.weekName > seasonEndWeek">
                            {{dataItem.inputAddRemoveMSekWeekly}}
                        </div>
                        <input
                            *ngIf="(driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3) && dataItem.weekName <= seasonEndWeek"
                            data-cell-type="add-remove-input"
                            [attr.data-season-planning-type]="driver.getSeasonInfo().seasonPlanningType.toString()"
                            attr.data-week-name="{{dataItem.weekName}}"
                            [ngClass]="(areInputsDisabled || dataItem.weekName > seasonEndWeek) ? 'seasonTableRowCellDisabled' : 'seasonTableRowCellEnabled'"
                            [disabled]="(areInputsDisabled || dataItem.weekName > seasonEndWeek)" type='text'
                            [(ngModel)]="dataItem.inputAddRemoveMSekWeekly"
                            (blur)="handleEvent($event, dataIndex + dataSetIndexOffset, 'AddRemoveMsek')"
                            (keydown.enter)="$event.target.blur()" (keypress)="filterInputCharacters($event)"
                            (input)="validateAddMoveInput($event, dataItem, 'ADDREMOVEMSEK')" />
                    </td>
                </ng-container>
            </tr>
        </ng-container>

        <!-- 8.2 Add Moves (MSEK) Weekly -->
        <ng-container *ngIf="seasonTitle != 'All Seasons'">
            <tr class='seasonTableRow seasonTableInputRow seasonTableRowAddMovesMsek'>
                <td class='seasonTableRowHeader'>Add Moves (MSEK)</td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <td *ngIf="!isWeeklyInputEnabledList[dataIndex]"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                        class='seasonTableRowCell seasonTableRowCellDisabled'><input class='seasonTableRowCellDisabled'
                            disabled type='text' /></td>
                    <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                        data-toggle="tooltip"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.inputAddMovesMSekWeekly === null || dataItem.inputAddMovesMSekWeekly === undefined? '': dataItem.inputAddMovesMSekWeekly}}"
                        *ngIf="isWeeklyInputEnabledList[dataIndex]" class='seasonTableRowCell' [ngClass]="{'seasonTableRowCellDisabled': areInputsDisabled,'seasonTableRowCellEnabled':!areInputsDisabled,'quarterBorder': quarterBorderForWeeks[dataIndex]==true,
                        'InputFieldsLocked': driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2,
                        'InputFieldsUnLocked': driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3}">
                        <div *ngIf="driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2">
                            {{dataItem.inputAddMovesMSekWeekly}}
                        </div>
                        <input *ngIf=" driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3"
                            data-cell-type="add-moves-input"
                            [attr.data-season-planning-type]="driver.getSeasonInfo().seasonPlanningType.toString()"
                            attr.data-week-name="{{dataItem.weekName}}"
                            [ngClass]="areInputsDisabled ? 'seasonTableRowCellDisabled' : 'seasonTableRowCellEnabled'"
                            [disabled]="areInputsDisabled" type='text' [(ngModel)]="dataItem.inputAddMovesMSekWeekly"
                            (blur)="handleEvent($event, dataIndex + dataSetIndexOffset, 'AddMovesMsek')"
                            (keydown.enter)="$event.target.blur()" (keypress)="filterInputCharacters($event)"
                            (input)="validateAddMoveInput($event, dataItem, 'ADDMOVESMSEK')" />
                    </td>
                </ng-container>
            </tr>
        </ng-container>


        <!-- 9. Net Takt Weekly -->
        <tr class='seasonTableRow seasonTableRowNetTakt' *ngIf="selectedKpis.indexOf('Net Takt') != -1">
            <td class='seasonTableRowHeader'>Net Takt</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">

                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidNetTaktWeeklyWeeks; else validNetTaktWeeklyWeeks">
                </ng-container>

                <ng-template #invalidNetTaktWeeklyWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>
                <ng-template #validNetTaktWeeklyWeeks>
                    <ng-container
                        *ngIf="dataItem.weekName < currentWeek; then netTaktCurrentWeeks; else netTaktFutureWeeks">
                    </ng-container>
                    <ng-template #netTaktCurrentWeeks>
                        <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                            title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.netTaktWeekly === null || dataItem.netTaktWeekly === undefined? '': dataItem.netTaktWeekly}}"
                            class='seasonTableRowCell'>{{dataItem.netTaktWeekly |
                            number: '1.0-0' }}</td>
                    </ng-template>
                    <ng-template #netTaktFutureWeeks>
                        <td class='seasonTableRowCell'
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                    </ng-template>
                </ng-template>


            </ng-container>

        </tr>

        <!-- 10. Gross Takt Weekly -->
        <tr class='seasonTableRow seasonTableRowGrossTakt' *ngIf="selectedKpis.indexOf('Gross Takt') != -1">
            <td class='seasonTableRowHeader'>Gross Takt</td>

            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">


                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidGrossTaktWeeklyWeeks; else validGrossTaktWeeklyWeeks">
                </ng-container>
                <ng-template #invalidGrossTaktWeeklyWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>

                <ng-template #validGrossTaktWeeklyWeeks>
                    <ng-container
                        *ngIf="dataItem.weekName < currentWeek; then grossTaktCurrentWeeks; else grossTaktFutureWeeks">
                    </ng-container>
                    <ng-template #grossTaktCurrentWeeks>
                        <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                            title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.grossTaktWeekly === null || dataItem.grossTaktWeekly === undefined? '': dataItem.grossTaktWeekly}}"
                            class='seasonTableRowCell'>{{dataItem.grossTaktWeekly
                            | number: '1.0-0' }}</td>
                    </ng-template>
                    <ng-template #grossTaktFutureWeeks>
                        <td class='seasonTableRowCell'
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                    </ng-template>
                </ng-template>



            </ng-container>

        </tr>

        <!-- 11. Average S% Periodic -->
        <tr class='seasonTableRow seasonTableRowAverageSPercent' *ngIf="selectedKpis.indexOf('Avg. S%') != -1">
            <td class='seasonTableRowHeader'>Avg. S%</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidAverageSPercentPeriod; else validAverageSPercentPeriod">
                </ng-container>
                <ng-template #invalidAverageSPercentPeriod>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validAverageSPercentPeriod>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.sPercentPeriodic === null || dataItem.sPercentPeriodic === undefined? '': dataItem.sPercentPeriodic.toFixed(3))"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{ dataItem.sPercentPeriodic | number: '1.1-1' }}%</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 12. S% Weekly -->
        <tr class='seasonTableRow seasonTableRowSPercent' *ngIf="selectedKpis.indexOf('S%') != -1">
            <td class='seasonTableRowHeader'>S%</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidSpercenttWeeklyWeeks; else validSpercenttWeeklyWeeks">
                </ng-container>
                <ng-template #invalidSpercenttWeeklyWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>
                <ng-template #validSpercenttWeeklyWeeks>
                    <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                        title="{{dataItem.sPercentWeekly === null || dataItem.sPercentWeekly === undefined? '': dataItem.sPercentWeekly.toFixed(3)}}"
                        class='seasonTableRowCell'>
                        {{dataItem.sPercentWeekly | number: '1.0-0' }}%</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 12.1 S% Forecast Periodic -->
        <!-- <ng-container *ngIf="seasonTitle != 'All Seasons' && selectedKpis.indexOf('S% Forecast') != -1">
            <tr class='seasonTableRow seasonTableRowForecastPeriod'>
                <td class='seasonTableRowHeader'>S% Forecast</td>
                <ng-container *ngFor="let rawDataItem of dataSet; let dataIndex=index;">
                    <ng-container *ngIf="dataIndex%4==0">
                        <td *ngIf="(!isForecastEnabledList[dataIndex])"
                            class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'><input
                                class="seasonTableRowCellDisabled" disabled type='text' /></td>

                        <td *ngIf="(isForecastEnabledList[dataIndex])" class='seasonTableRowCell4Merge'
                            [ngClass]="(isForecastDisabled ||isMain) == true ? 'seasonTableRowCellDisabled' : 'seasonTableRowCellEnabled'"
                            colspan='4'>
                            <input data-toggle="tooltip" [title]="rawDataItem.sPercent"
                                [ngClass]="(isForecastDisabled ||isMain) == true ? 'seasonTableRowCellDisabled' : 'seasonTableRowCellEnabled'"
                                [disabled]="(isForecastDisabled ||isMain)" type='text'
                                [(ngModel)]="rawDataItem.sPercent" (keydown.enter)="$event.target.blur()"
                                maxlength="2" style="text-align: end; width: 33%;"
                                oninput="this.value=this.value.replace(/[^0-9]/g,'');"
                                style="text-align: end; width: 33%;" />
                            <span *ngIf="rawDataItem.sPercent">%</span>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-container> -->

        <!-- 13. Average R&D% Periodic -->
        <tr class='seasonTableRow seasonTableRowAverageRDPercent' *ngIf="selectedKpis.indexOf('Avg. R&D%') != -1">
            <td class='seasonTableRowHeader'>
                <ng-container [ngTemplateOutlet]="(!isAssortmentView && channel != 3 && selectedMartketType== 'PlanningRegion' &&  isPRCopy) ? avgRndHeaderWithAggregatedPms 
                                            :(!isAssortmentView && channel != 3 && selectedMartketType== 'PlanningMarket' && isPRCopy) ? avgRndHeaderWithPMMain 
                                            : AvgRndHeader">
                </ng-container>
                <ng-template #avgRndHeaderWithAggregatedPms>
                    Avg. R&D% (PM)
                </ng-template>
                <ng-template #avgRndHeaderWithPMMain>
                    Avg. R&D% (Main)
                </ng-template>
                <ng-template #AvgRndHeader>
                    Avg. R&D%
                </ng-template>
            </td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidAverageRDPercentPeriod; else validAverageRDPercentPeriod">
                </ng-container>
                <ng-template #invalidAverageRDPercentPeriod>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validAverageRDPercentPeriod>
                    <ng-container
                        *ngIf="dataIndex%4==0 && (dataItem.weekName < currentWeek || (dataItem.weekName >= currentWeek && seasonInfo?.seasonPlanningType == 'Total')); then averageRDPercentPeriodWithData; else averageRDPercentPeriodEmpty">
                    </ng-container>
                    <ng-template #averageRDPercentPeriodWithData>
                        <td data-toggle="tooltip"
                            [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                            [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                            attr.data-season-name="{{seasonTitle}}"
                            [title]="(dataItem.rAndDPercentPeriodic === null || dataItem.rAndDPercentPeriodic === undefined? '': dataItem.rAndDPercentPeriodic.toFixed(3))"
                            class='seasonTableRowCell4Merge' colspan='4'>{{ dataItem.rAndDPercentPeriodic | number: '1.1-1' }}%
                            <span *ngIf="!isAssortmentView && channel != 3 && isPRCopy && dataItem.aggregatedPMsIsSaved"
                                class="aggregated-pm-span"
                                [title]="(dataItem.aggregatedPMsRAndDPercentPeriodic === null || dataItem.aggregatedPMsRAndDPercentPeriodic === undefined? '': dataItem.aggregatedPMsRAndDPercentPeriodic.toFixed(3))">
                                ({{ dataItem.aggregatedPMsRAndDPercentPeriodic === null ||
                                dataItem.aggregatedPMsRAndDPercentPeriodic === undefined? '-':
                                dataItem.aggregatedPMsRAndDPercentPeriodic | number: '1.1-1' }}
                              <span *ngIf="dataItem.aggregatedPMsRAndDPercentPeriodic != null ||
                                dataItem.aggregatedPMsRAndDPercentPeriodic != undefined">%</span>)
                            </span>
                            <span
                                *ngIf="!isAssortmentView && channel != 3 && isPRCopy && !dataItem.aggregatedPMsIsSaved"
                                class="aggregated-pm-span">
                                (<img *ngIf="!driver.hasSalesAggregatedPmDataSetLoaded"
                                    src="./assets/images/ellipsis-animated.gif" alt="Loading" />
                                <span *ngIf="driver.hasSalesAggregatedPmDataSetLoaded">-</span>)
                            </span>
                        </td>
                    </ng-template>
                    <ng-template #averageRDPercentPeriodEmpty>
                        <td *ngIf="dataIndex%4==0 && dataItem.weekName >= currentWeek" class='seasonTableRowCell4Merge'
                            [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}" colspan='4'>
                            <span *ngIf="!isAssortmentView && channel != 3 && isPRCopy && dataItem.aggregatedPMsIsSaved"
                                class="aggregated-pm-span"
                                [title]="(dataItem.aggregatedPMsRAndDPercentPeriodic === null || dataItem.aggregatedPMsRAndDPercentPeriodic === undefined? '': dataItem.aggregatedPMsRAndDPercentPeriodic.toFixed(3))">
                                ({{ dataItem.aggregatedPMsRAndDPercentPeriodic === null ||
                                dataItem.aggregatedPMsRAndDPercentPeriodic === undefined? '-':
                                dataItem.aggregatedPMsRAndDPercentPeriodic | number: '1.1-1' }}
                              <span *ngIf="dataItem.aggregatedPMsRAndDPercentPeriodic != null ||
                                dataItem.aggregatedPMsRAndDPercentPeriodic != undefined">%</span>)
                            </span>
                            <span
                                *ngIf="!isAssortmentView && channel != 3 && isPRCopy && !dataItem.aggregatedPMsIsSaved"
                                class="aggregated-pm-span">
                                (<img *ngIf="!driver.hasSalesAggregatedPmDataSetLoaded"
                                    src="./assets/images/ellipsis-animated.gif" alt="Loading" />
                                <span *ngIf="driver.hasSalesAggregatedPmDataSetLoaded">-</span>)
                            </span>
                        </td>
                    </ng-template>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 14. R&D% Weekly -->
        <tr class='seasonTableRow seasonTableRowRDPercent' *ngIf="selectedKpis.indexOf('R&D%') != -1">
            <td class='seasonTableRowHeader'>R&D%</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidRDPercentWeeks; else validRDPercentWeeks">
                </ng-container>
                <ng-template #invalidRDPercentWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>

                <ng-template #validRDPercentWeeks>
                    <ng-container
                        *ngIf="dataItem.weekName < currentWeek; then rAndDPercentCurrentWeeks; else rAndDPercentFutureWeeks">
                    </ng-container>
                    <ng-template #rAndDPercentCurrentWeeks>
                        <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                            title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndDPercentWeekly === null || dataItem.rAndDPercentWeekly === undefined? '': dataItem.rAndDPercentWeekly}}"
                            class='seasonTableRowCell'>
                            {{dataItem.rAndDPercentWeekly | number: '1.0-0' }}%</td>
                    </ng-template>
                    <ng-template #rAndDPercentFutureWeeks>
                        <td class='seasonTableRowCell'
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                    </ng-template>
                </ng-template>


            </ng-container>

        </tr>

        <!-- 14.1 R&D% Forecast Periodic -->
        <ng-container *ngIf="seasonTitle != 'All Seasons'">
            <tr class='seasonTableRow seasonTableRowForecastPeriod'>
                <td class='seasonTableRowHeader'>R&D% Forecast</td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <ng-container *ngIf="dataIndex%4==0">
                        <td *ngIf="(!isForecastEnabledList[dataIndex])"
                            [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                            class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'><input
                                class="seasonTableRowCellDisabled" disabled type='text' /></td>
                        <td *ngIf="(isForecastEnabledList[dataIndex])" class='seasonTableRowCell4Merge'
                            data-toggle="tooltip"
                            [title]="(dataItem.inputRAndDForecastPeriodic === null || dataItem.inputRAndDForecastPeriodic === undefined? '': dataItem.inputRAndDForecastPeriodic)"
                            [ngClass]="{'seasonTableRowCellDisabled': (isMain) == true,'seasonTableRowCellEnabled':(areInputsDisabled) == false ,'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true,
                            'InputFieldsLocked': driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2,'InputFieldsUnLocked':driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3}"
                            colspan='4'>
                            <span style="margin-left: 18px;"
                                *ngIf="driver.getInputLockStatus() == 1 || driver.getInputLockStatus() == 2">
                                {{ dataItem.inputRAndDForecastPeriodic | number: '1.1-1' }}
                            </span>
                            <input *ngIf="driver.getInputLockStatus() == 0 || driver.getInputLockStatus() == 3"
                                type='text' data-cell-type="forecast-rand-input"
                                [attr.data-season-planning-type]="driver.getSeasonInfo().seasonPlanningType.toString()"
                                [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                                attr.data-season-name="{{seasonTitle}}"
                                [ngClass]="areInputsDisabled? 'seasonTableRowCellDisabled' : 'seasonTableRowCellEnabled'"
                                [disabled]="areInputsDisabled" [(ngModel)]="dataItem.inputRAndDForecastPeriodic"
                                (blur)="handleEvent($event, dataIndex + dataSetIndexOffset, 'RAndDForecastPercent')"
                                (keydown.enter)="$event.target.blur()" maxlength="4"
                                style="text-align: end; width: 33%;"
                                oninput="this.value=this.value.replace(/[^0-9.]/g,'');" /><span
                                *ngIf="dataItem.inputRAndDForecastPeriodic!=null">%</span>
                        </td>
                    </ng-container>
                </ng-container>
            </tr>
        </ng-container>

        <!-- 15. Average Stock Prognosis Periodic -->
        <tr class='seasonTableRow seasonTableRowAverageStockPrognosis'
            *ngIf="selectedKpis.indexOf('Avg. Stock Prognosis') != -1">
            <td class='seasonTableRowHeader'>Avg. Stock Prognosis</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidAverageStockPrognosisPeriod; else validAverageStockPrognosisPeriod">
                </ng-container>
                <ng-template #invalidAverageStockPrognosisPeriod>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validAverageStockPrognosisPeriod>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.stockPrognosisPeriodic === null || dataItem.stockPrognosisPeriodic === undefined? '': dataItem.stockPrognosisPeriodic)"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{dataItem.stockPrognosisPeriodic |
                        number: '1.0-0' }}</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 16. Stock Prognosis Weekly -->
        <tr class='seasonTableRow seasonTableRowStockPrognosis' *ngIf="selectedKpis.indexOf('Stock Prognosis') != -1">
            <td class='seasonTableRowHeader'>Stock Prognosis</td>

            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidStockPrognosisWeeks; else validStockPrognosisWeeks">
                </ng-container>
                <ng-template #invalidStockPrognosisWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>

                <ng-template #validStockPrognosisWeeks>
                    <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockPrognosisWeekly === null || dataItem.stockPrognosisWeekly === undefined? '': dataItem.stockPrognosisWeekly}}"
                        class='seasonTableRowCell'>{{dataItem.stockPrognosisWeekly |
                        number: '1.0-0' }}</td>
                </ng-template>

            </ng-container>
        </tr>

        <!-- 16. Bought of plan % Periodic -->
        <ng-container *ngIf="seasonTitle == 'All Seasons' || seasonInfo?.seasonPlanningType == 'Actual' || seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future'">
            <tr class='seasonTableRow seasonTableRowBoughtOfPlan'>
            <td class='seasonTableRowHeader'>Bought of Plan%</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidBoughtOfPlanPeriod; else validBoughtOfPlanPeriod">
                </ng-container>
                <ng-template #invalidBoughtOfPlanPeriod>
                   <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                           [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                   </td>
               </ng-template>
               <ng-template #validBoughtOfPlanPeriod>
                   <td data-toggle="tooltip"
                           [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                           [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                           attr.data-season-name="{{seasonTitle}}"
                           [title]="(dataItem.boughtOfPlanPeriodic === null || dataItem.boughtOfPlanPeriodic === undefined? '': dataItem.boughtOfPlanPeriodic.toFixed(3))"
                           *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                           {{dataItem.boughtOfPlanPeriodic | number: '1.0-0' }}%</td>
               </ng-template>
            </ng-container>
            </tr>
        </ng-container>

        <!-- 17. Stock Per Sales Net Wk51 Weekly -->
        <tr class='seasonTableRow seasonTableRowStockPerSalesNetWeekly'
            *ngIf="isAssortmentView && selectedKpis.indexOf('Stock % SalesNet52') != -1 && seasonInfo?.seasonPlanningType == 'Total'">
            <td class='seasonTableRowHeader'>Stock % SalesNet52</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <td data-toggle="tooltip"
                    [title]="(dataItem.stockPerSalesNetWeekly === null || dataItem.stockPerSalesNetWeekly === undefined? '': dataItem.stockPerSalesNetWeekly.toFixed(3))"
                    class='seasonTableRowCell'>
                    {{dataItem.stockPerSalesNetWeekly | number: '1.0-0' }}%</td>
            </ng-container>
        </tr>

        <!-- 18. Stock Plan Index Weekly -->
        <!-- <tr class='seasonTableRow seasonTableRowStockPlanIndex' *ngIf="selectedKpis.indexOf('Stock Plan Index') != -1">
            <td class='seasonTableRowHeader'>Stock Plan Index</td>

            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">


                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidStockPlanIndexWeeks; else validStockPlanIndexWeeks">
                </ng-container>
                <ng-template #invalidStockPlanIndexWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'></td>
                </ng-template>

                <ng-template #validStockPlanIndexWeeks>
                    <td data-toggle="tooltip" [title]="dataItem.grossTaktWeekly" class='seasonTableRowCell'>{{dataItem.grossTaktWeekly |
                        number: '1.0-0' }}</td>
                </ng-template>

            </ng-container>

        </tr> -->

        <!-- 19. Bought of Plan% Weekly -->
        <!-- <tr class='seasonTableRow seasonTableRowBoughtOfPlanPercent' *ngIf="selectedKpis.indexOf('BOP%') != -1">
            <td class='seasonTableRowHeader'>BOP%</td>

            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">


                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidBoughtOfPlanPercentWeeks; else validBoughtOfPlanPercentWeeks">
                </ng-container>
                <ng-template #invalidBoughtOfPlanPercentWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'></td>
                </ng-template>

                <ng-template #validBoughtOfPlanPercentWeeks>
                    <td data-toggle="tooltip" [title]="dataItem.grossTaktWeekly" class='seasonTableRowCell'>{{dataItem.grossTaktWeekly |
                        number: '1.0-0' }}</td>
                </ng-template>

            </ng-container>

        </tr> -->

        <!-- 20. Takt Ground Periodic -->
        <tr class='seasonTableRow seasonTableRowTaktGroundPeriod' *ngIf="selectedKpis.indexOf('Takt Gnd') != -1">
            <td class='seasonTableRowHeader'>Takt Gnd</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">

                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidTaktGroundPeriod; else validTaktGroundPeriod">
                </ng-container>
                <ng-template #invalidTaktGroundPeriod>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validTaktGroundPeriod>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.groundGrossTaktPeriodic === null || dataItem.groundGrossTaktPeriodic === undefined? '': dataItem.groundGrossTaktPeriodic)"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{dataItem.groundGrossTaktPeriodic | number: '1.0-0' }}</td>
                </ng-template>

            </ng-container>
        </tr>

        <!-- 21. Average S% Ground Periodic -->
        <tr class='seasonTableRow seasonTableRowAverageSPercentGround'
            *ngIf="selectedKpis.indexOf('Avg. S% Gnd') != -1">
            <td class='seasonTableRowHeader'>Avg. S% Gnd</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidGroundSPercentPeriodic; else validGroundSPercentPeriodic">
                </ng-container>
                <ng-template #invalidGroundSPercentPeriodic>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validGroundSPercentPeriodic>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}" [title]="dataItem.groundSPercentPeriodic"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{ dataItem.groundSPercentPeriodic | number: '1.1-1' }}%</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 22. S% Ground Weekly -->
        <tr class='seasonTableRow seasonTableRowSPercentGround' *ngIf="selectedKpis.indexOf('S% Gnd') != -1">
            <td class='seasonTableRowHeader'>S% Gnd</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidSPercentGroundWeeks; else validSPercentGroundWeeks">
                </ng-container>
                <ng-template #invalidSPercentGroundWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>

                <ng-template #validSPercentGroundWeeks>
                    <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.groundSPercentWeekly}}"
                        class='seasonTableRowCell'>{{dataItem.groundSPercentWeekly |
                        number: '1.0-0'}}%</td>
                </ng-template>

            </ng-container>
        </tr>

        <!-- 23. Average R&D% Ground Periodic -->
        <tr class='seasonTableRow seasonTableRowAverageRDPercentGround'
            *ngIf="selectedKpis.indexOf('Avg. R&D% Gnd') != -1">
            <td class='seasonTableRowHeader'>Avg. R&D% Gnd</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidAverageRDPercentGroundPeriod; else validAverageRDPercentGroundPeriod">
                </ng-container>
                <ng-template #invalidAverageRDPercentGroundPeriod>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validAverageRDPercentGroundPeriod>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.groundRAndDPercentPeriodic === null || dataItem.groundRAndDPercentPeriodic === undefined? '': dataItem.groundRAndDPercentPeriodic)"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{ dataItem.groundRAndDPercentPeriodic | number: '1.1-1' }}%</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 24. R&D% Ground Weekly -->
        <tr class='seasonTableRow seasonTableRowRDPercentGround' *ngIf="selectedKpis.indexOf('R&D% Gnd') != -1">
            <td class='seasonTableRowHeader'>R&D% Gnd</td>

            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidRDPercentGroundWeeks; else validRDPercentGroundWeeks">
                </ng-container>
                <ng-template #invalidRDPercentGroundWeeks>
                    <td class='seasonTableRowCell seasonTableRowCellDisabled'
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"></td>
                </ng-template>

                <ng-template #validRDPercentGroundWeeks>
                    <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                        [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.groundRAndDPercentWeekly === null || dataItem.groundRAndDPercentWeekly === undefined? '': dataItem.groundRAndDPercentWeekly}}"
                        class='seasonTableRowCell'>
                        {{dataItem.groundRAndDPercentWeekly | number: '1.0-0' }}%</td>
                </ng-template>
            </ng-container>

        </tr>

        <!-- 25. Stock Index Ground Weekly -->
        <tr class='seasonTableRow seasonTableRowStockIndexGround' *ngIf="selectedKpis.indexOf('Stock Index Gnd') != -1">
            <td class='seasonTableRowHeader'>Stock Index Gnd</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <ng-container
                    *ngIf="(seasonInfo?.seasonPlanningType == 'Coming' || seasonInfo?.seasonPlanningType == 'Future') && dataItem.weekName < disabledTillWeek; then invalidStockIndexGroundPeriod; else validStockIndexGroundPeriod">
                </ng-container>
                <ng-template #invalidStockIndexGroundPeriod>
                    <td *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge seasonTableRowCellDisabled' colspan='4'
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}">
                    </td>
                </ng-template>
                <ng-template #validStockIndexGroundPeriod>
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.groundStockIndexPeriodic === null || dataItem.groundStockIndexPeriodic === undefined? '': dataItem.groundStockIndexPeriodic)"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge' colspan='4'>
                        {{dataItem.groundStockIndexPeriodic | number: '1.0-0' }}</td>
                </ng-template>
            </ng-container>
        </tr>

        <!-- 26. Outgoing Prognosis Weekly -->
        <tr class='seasonTableRow  seasonTableRowOutgoingPrognosis'>
            <td class='seasonTableRowHeader'>Outgoing Prognosis</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.effectiveOutgoingStockPrognosis === null || dataItem.effectiveOutgoingStockPrognosis === undefined? '': dataItem.effectiveOutgoingStockPrognosis}}"
                    class='seasonTableRowCell'>
                    {{ dataItem.effectiveOutgoingStockPrognosis | number: '1.0-0' }}</td>
            </ng-container>
        </tr>

        <!-- 27. Bought Gross Weekly -->
        <tr class='seasonTableRow  seasonTableRowBoughtGrossWeekly' *ngIf="(this.driver.getRetrievalMode()) == 'Bought'">
            <td class='seasonTableRowHeader'>Bought Gross</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.boughtGross === null || dataItem.boughtGross === undefined? '': dataItem.boughtGross}}"
                    class='seasonTableRowCell'>
                    {{ dataItem.boughtGross | number: '1.0-0' }}</td>
            </ng-container>
        </tr>

        <!-- 27. Ordered Gross Ground Weekly -->
        <tr class='seasonTableRow  seasonTableRowOrderedGrossGroundWeekly' *ngIf="(this.driver.getRetrievalMode()) == 'Initial'">
            <td class='seasonTableRowHeader'>Ordered Gross Ground</td>
            <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                <td attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.orderedGrossGround === null || dataItem.orderedGrossGround === undefined? '': dataItem.orderedGrossGround}}"
                    class='seasonTableRowCell'>
                    {{ dataItem.orderedGrossGround | number: '1.0-0' }}</td>
            </ng-container>
        </tr>

        <!-- Fin plan, its only for sales total view; and not for main-->
        <ng-container
            *ngIf="seasonTitle == 'All Seasons' && !isAssortmentView && !isMain && (driver.getUserConfig()?.planningViewOptions?.structureType == 'Total')">
            <tr class='seasonTableRow seasonTableInputRow seasonTableRowFinPlanMSek'>
                <td class='seasonTableRowHeader'>Fin. Plan (MSEK)</td>

                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <td data-toggle="tooltip" [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                        title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.inputFinPlanMSekWeekly === null || dataItem.inputFinPlanMSekWeekly === undefined? '': dataItem.inputFinPlanMSekWeekly}}"
                        class='seasonTableRowCell seasonTableRowCellEnabled'>
                        <input data-cell-type="diff-plan-input"
                            [disabled]="driver.getUserConfig()?.selectionViewOptions.copy.isLocked"
                            [attr.data-season-planning-type]="driver.getSeasonInfo().seasonPlanningType.toString()"
                            attr.data-week-name="{{dataItem.weekName}}" attr.data-season-name="{{seasonTitle}}"
                            class="seasonTableRowCellEnabled" type='text' [(ngModel)]="dataItem.inputFinPlanMSekWeekly"
                            (blur)="handleEvent($event, dataIndex + dataSetIndexOffset, 'FinPlanMSek')"
                            (keydown.enter)="$event.target.blur()" (keypress)="filterInputCharacters($event)"
                            (input)="validateFinPlanInput($event, dataItem)" />
                    </td>
                </ng-container>
            </tr>

            <!-- Diff Fin plan -->
            <tr class='seasonTableRow seasonTableRowDiffFinPlan'>
                <td class='seasonTableRowHeader'>Diff Fin Plan</td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <td data-toggle="tooltip"
                        [ngClass]="{'quarterBorder': quarterBorderForCalcPeriods[dataIndex]==true}"
                        [attr.data-period-weeks]="_utils.getPeriodWeeksfromWeekName(dataItem.weekName, seasonInfo.weeksWithYear)"
                        attr.data-season-name="{{seasonTitle}}"
                        [title]="(dataItem.diffFinPlanMSek === null || dataItem.diffFinPlanMSek === undefined? '': dataItem.diffFinPlanMSek)"
                        *ngIf="dataIndex%4==0" class='seasonTableRowCell4Merge ' colspan='4'>
                        {{dataItem.diffFinPlanMSek | number: '1.0-0' }}
                        <span *ngIf="dataItem.diffFinPlanMSek != null && dataItem.diffFinPlanMSek != undefined">%</span>
                    </td>
                </ng-container>
            </tr>
        </ng-container>

        <!-- Debug section -->
        <ng-container *ngIf="debugMode">

            <!-- Forecasted Data -->

            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>DemDel Plan</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.demDelPlan === null || dataItem.demDelPlan === undefined? '': dataItem.demDelPlan}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.demDelPlan| rawValueConverter }}</td>
            </tr>

            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Forecast Goal</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.forecastGoalPeriodic === null || dataItem.forecastGoalPeriodic === undefined? '': dataItem.forecastGoalPeriodic}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.forecastGoalPeriodic| number: '1.0-0' }}</td>
            </tr>

            <tr
                class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowEffectiveSalesPlanWeekly'>
                <td class='seasonTableRowHeader'>Effective Plan</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.effectiveSalesPlanWeekly === null || dataItem.effectiveSalesPlanWeekly === undefined? '': dataItem.effectiveSalesPlanWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.effectiveSalesPlanWeekly| rawValueConverter }}</td>
            </tr>

            <tr
                class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowEffectiveSalesPlanWeekly'>
                <td class='seasonTableRowHeader'>Sales Forecast Plan</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.salesForecastWeekly === null || dataItem.salesForecastWeekly === undefined? '': dataItem.salesForecastWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.salesForecastWeekly| rawValueConverter }}</td>
            </tr>

            <!-- if 'R&D% Forecast' is null then use 'Avg. R&D% Gnd' else use normal values -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Effective R&D%</td>
                <ng-container *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    <ng-container
                        *ngIf="dataItem.weekName >= disabledTillWeek && (dataItem.inputRAndDForecastPeriodic === null || dataItem.inputRAndDForecastPeriodic === undefined) ; then showAvgRAndDGnd; else showRAndDForecastPercent">
                    </ng-container>
                    <ng-template #showAvgRAndDGnd>
                        <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                            attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                            title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.groundRAndDPercentPeriodic === null || dataItem.groundRAndDPercentPeriodic === undefined? '': dataItem.groundRAndDPercentPeriodic}}">
                            {{ dataItem.groundRAndDPercentPeriodic | number: '1.0-0' }}<span
                                *ngIf="dataItem.groundRAndDPercentPeriodic!=null">%</span></td>
                    </ng-template>
                    <ng-template #showRAndDForecastPercent>
                        <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                            [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                            attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                            title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndDForecastPercentWeekly === null || dataItem.rAndDForecastPercentWeekly === undefined? '': dataItem.rAndDForecastPercentWeekly}}">
                            {{ dataItem.rAndDForecastPercentWeekly | number: '1.0-0' }}<span
                                *ngIf="dataItem.rAndDForecastPercentWeekly!=null">%</span></td>
                    </ng-template>
                </ng-container>
            </tr>

            <!-- create neew calculator to find randplan, if input randd forecast is null then calc with 'Avg. R&D% Gnd' -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Effective R&D Plan</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.effectiveRAndDPlan === null || dataItem.effectiveRAndDPlan === undefined? '': dataItem.effectiveRAndDPlan}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.effectiveRAndDPlan | rawValueConverter }}</td>
            </tr>

            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowrAndDForecast'>
                <td class='seasonTableRowHeader'>R&D Forecast</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndDForecastWeekly === null || dataItem.rAndDForecastWeekly === undefined? '': dataItem.rAndDForecastWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{ dataItem.rAndDForecastWeekly | number:
                    '1.0-0' }}

                </td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowrAndDLyPercentWeekly'>
                <td class='seasonTableRowHeader'>R&D LY%</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndDLyPercentWeekly === null || dataItem.rAndDLyPercentWeekly === undefined? '': dataItem.rAndDLyPercentWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.rAndDLyPercentWeekly| rawValueConverter }}

                </td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowcombinedRAndDWeekly'>
                <td class='seasonTableRowHeader'>Combined R&D</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.combinedRAndDWeekly === null || dataItem.combinedRAndDWeekly === undefined? '': dataItem.combinedRAndDWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{dataItem.combinedRAndDWeekly |
                    rawValueConverter }}
                </td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowcombinedNetSalesWeekly'>
                <td class='seasonTableRowHeader'>Combined SalesNet</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.combinedNetSalesWeekly === null || dataItem.combinedNetSalesWeekly === undefined? '': dataItem.combinedNetSalesWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{dataItem.combinedNetSalesWeekly |
                    rawValueConverter }}
                </td>
            </tr>
            <!-- Intermediate Data -->

            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Combined Sales</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.combinedSalesPlanWeekly === null || dataItem.combinedSalesPlanWeekly === undefined? '': dataItem.combinedSalesPlanWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.combinedSalesPlanWeekly| rawValueConverter }}</td>
            </tr>

            <!-- 24. Net Sales Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowNetSalesWeekly'>
                <td class='seasonTableRowHeader'>Net Sales</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.salesNet === null || dataItem.salesNet === undefined? '': dataItem.salesNet}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.salesNet| rawValueConverter }}</td>
            </tr>

            <!-- 25. Net Sales Ground Weekly -->
            <tr
                class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowNetSalesGroundWeekly'>
                <td class='seasonTableRowHeader'>Net Sales Gnd</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.salesNetGround === null || dataItem.salesNetGround === undefined? '': dataItem.salesNetGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.salesNetGround| rawValueConverter }}</td>
            </tr>

            <!-- 26. Gross Takt Ground Weekly -->
            <tr
                class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowGrossTaktGroundWeekly'>
                <td class='seasonTableRowHeader'>Gross Takt Gnd</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.groundGrossTaktWeekly === null || dataItem.groundGrossTaktWeekly === undefined? '': dataItem.groundGrossTaktWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.groundGrossTaktWeekly| rawValueConverter }}</td>
            </tr>

            <!-- Raw Data -->

            <!-- 27. Gross Sales Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSalesWeekly'>
                <td class='seasonTableRowHeader'>Gross Sales</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.grossSales === null || dataItem.grossSales === undefined? '': dataItem.grossSales}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{dataItem.grossSales| rawValueConverter }}
                </td>
            </tr>

            <!-- 28. Gross Sales Ground Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSalesGroundWeekly'>
                <td class='seasonTableRowHeader'>Gross Sales Gnd</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.grossSalesGround === null || dataItem.grossSalesGround === undefined? '': dataItem.grossSalesGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.grossSalesGround| rawValueConverter }}</td>
            </tr>

            <!-- 28. Gross Sales Ground Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSalesGroundWeekly'>
                <td class='seasonTableRowHeader'>Gross Sales Gnd LY</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.grossSalesGroundLy === null || dataItem.grossSalesGroundLy === undefined? '': dataItem.grossSalesGroundLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.grossSalesGroundLy| rawValueConverter }}</td>
            </tr>

            <!-- 28. Start week Sales Ground Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSalesGroundWeekly'>
                <td class='seasonTableRowHeader'>Start Week Sales</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.startWeekSalesGrossGround === null || dataItem.startWeekSalesGrossGround === undefined? '': dataItem.startWeekSalesGrossGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.startWeekSalesGrossGround| rawValueConverter }}</td>
            </tr>

            <!-- 29. Gross Sales 2-Ground Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSales2GroundWeekly'>
                <td class='seasonTableRowHeader'>Gross Sales 2-Gnd</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.grossSales2Ground === null || dataItem.grossSales2Ground === undefined? '': dataItem.grossSales2Ground}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.grossSales2Ground| rawValueConverter }}</td>
            </tr>

            <!-- 29. Gross Sales Ground LY Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSales2GroundWeekly'>
                <td class='seasonTableRowHeader'>Gross Sales LY</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.grossSalesLy === null || dataItem.grossSalesLy === undefined? '': dataItem.grossSalesLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.grossSalesLy| rawValueConverter }}</td>
            </tr>

            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowGrossSales2GroundWeekly'>
                <td class='seasonTableRowHeader'>Sales Plan LY</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.salesPlanLy === null || dataItem.salesPlanLy === undefined? '': dataItem.salesPlanLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.salesPlanLy| rawValueConverter }}</td>
            </tr>

            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowCombinedSalesLyWeekly'>
                <td class='seasonTableRowHeader'>Combined Sales Ly</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.combinedSalesLyWeekly === null || dataItem.combinedSalesLyWeekly === undefined? '': dataItem.combinedSalesLyWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.combinedSalesLyWeekly | rawValueConverter }}</td>
            </tr>

            <!--30. R&D Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowRAndDWeekly'>
                <td class='seasonTableRowHeader'>R&D</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndD === null || dataItem.rAndD === undefined? '': dataItem.rAndD}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{dataItem.rAndD| rawValueConverter }}</td>
            </tr>

            <!--31. R&D Ground Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowRAndDGroundWeekly'>
                <td class='seasonTableRowHeader'>R&D Gnd</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndDGround === null || dataItem.rAndDGround === undefined? '': dataItem.rAndDGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{dataItem.rAndDGround| rawValueConverter }}
                </td>
            </tr>

            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowRAndDGroundWeekly'>
                <td class='seasonTableRowHeader'>R&D LY</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.rAndDLy === null || dataItem.rAndDLy === undefined? '': dataItem.rAndDLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">{{dataItem.rAndDLy| rawValueConverter }}
                </td>
            </tr>

            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Published DemDelPlan</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.cubeDemDelPlan === null || dataItem.cubeDemDelPlan === undefined? '': dataItem.cubeDemDelPlan}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.cubeDemDelPlan| rawValueConverter }}</td>
            </tr>

            <!-- Stock Gross Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Gross</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockGross === null || dataItem.stockGross === undefined? '': dataItem.stockGross}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.stockGross| rawValueConverter }}</td>
            </tr>

            <!-- Stock Ground Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Ground</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockGround === null || dataItem.stockGround === undefined? '': dataItem.stockGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.stockGround| rawValueConverter }}</td>
            </tr>

            <!-- Stock Prognosis LY Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Prognosis LY</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockPrognosisLy === null || dataItem.stockPrognosisLy === undefined? '': dataItem.stockPrognosisLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.stockPrognosisLy| rawValueConverter }}</td>
            </tr>

            <!-- Stock Gross Ground LY Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Ground LY</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockGrossGroundLy === null || dataItem.stockGrossGroundLy === undefined? '': dataItem.stockGrossGroundLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.stockGrossGroundLy| rawValueConverter }}</td>
            </tr>

            <!-- Stock Ground 2 Ground weekly  -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Gross 2 Ground</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockGross2Ground === null || dataItem.stockGross2Ground === undefined? '': dataItem.stockGross2Ground}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.stockGross2Ground| rawValueConverter }}</td>
            </tr>

            <!-- Purchase Plan Fixed Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Purchase Plan Fixed</td>
                <td class='seasonTableRowCell' [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.purchasePlanFixed === null || dataItem.purchasePlanFixed === undefined? '': dataItem.purchasePlanFixed}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.purchasePlanFixed | rawValueConverter }}</td>
            </tr>
            <!-- Purchase Plan Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Purchase Plan</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.purchasePlan === null || dataItem.purchasePlan === undefined? '': dataItem.purchasePlan}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.purchasePlan | rawValueConverter }}</td>
            </tr>

            <!-- Stock Plan Fixed Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Plan Fixed</td>
                <td class='seasonTableRowCell' [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockPlanFixed === null || dataItem.stockPlanFixed === undefined? '': dataItem.stockPlanFixed}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.stockPlanFixed | rawValueConverter }}</td>
            </tr>

            <!-- Stock Forecast Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Stock Forecast</td>
                <td class='seasonTableRowCell' [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.stockForecastWeekly === null || dataItem.stockForecastWeekly === undefined? '': dataItem.stockForecastWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.stockForecastWeekly | rawValueConverter }}</td>
            </tr>

            <!-- Dem Del Plan Fixed Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>DemDel Plan Fixed</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.salesPlanFixed === null || dataItem.salesPlanFixed === undefined? '': dataItem.salesPlanFixed}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.salesPlanFixed| rawValueConverter }}</td>
            </tr>
            <!-- Return Plan Fixed Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Return Plan Fixed</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.returnPlanFixed === null || dataItem.returnPlanFixed === undefined? '': dataItem.returnPlanFixed}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.returnPlanFixed| rawValueConverter }}</td>
            </tr>
            <!-- Budgeted Return Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Budgeted Returns</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.adjustedBudgetedReturn === null || dataItem.adjustedBudgetedReturn === undefined? '': dataItem.adjustedBudgetedReturn}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.adjustedBudgetedReturn| rawValueConverter }}</td>
            </tr>
            <!-- Planned Returns Weekly -->
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Planned Returns</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.plannedReturn === null || dataItem.plannedReturn === undefined? '': dataItem.plannedReturn}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.plannedReturn| rawValueConverter }}</td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Returns Gross</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.returnsGross === null || dataItem.returnsGross === undefined? '': dataItem.returnsGross}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.returnsGross| rawValueConverter }}</td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>ReturnsGrossGround</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.returnsGrossGround === null || dataItem.returnsGrossGround === undefined? '': dataItem.returnsGrossGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.returnsGrossGround| rawValueConverter }}</td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Buying</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.buyingWeekly === null || dataItem.buyingWeekly === undefined? '': dataItem.buyingWeekly}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.buyingWeekly| rawValueConverter }}</td>
            </tr>
            <tr class='seasonTableRow seasonTableDebugRow seasonTableIntermediateData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'>Bought Gross Gnd</td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.boughtGrossGround === null || dataItem.boughtGrossGround === undefined? '': dataItem.boughtGrossGround}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{dataItem.boughtGrossGround| rawValueConverter }}</td>
            </tr>
            <tr *ngIf="seasonInfo?.seasonPlanningType != 'Total'"
                class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'> Acc Shrink LY </td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.accShrinkLy === null || dataItem.accShrinkLy === undefined? '': dataItem.accShrinkLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.accShrinkLy | rawValueConverter }}</td>
            </tr>
            <tr *ngIf="seasonInfo?.seasonPlanningType != 'Total'"
                class='seasonTableRow seasonTableDebugRow seasonTableRawData seasonTableRowDemDelPlanWeekly'>
                <td class='seasonTableRowHeader'> Shrink% LY </td>
                <td class='seasonTableRowCell' attr.data-week-name="{{dataItem.weekName}}"
                    [ngClass]="{'quarterBorder': quarterBorderForWeeks[dataIndex]==true}"
                    attr.data-season-name="{{seasonTitle}}" data-toggle="tooltip"
                    title="W{{dataItem.weekName.toString().substring(4)}}: {{dataItem.shrinkPercentLy === null || dataItem.shrinkPercentLy === undefined? '': dataItem.shrinkPercentLy}}"
                    *ngFor="let dataItem of dataSet; let dataIndex=index;">
                    {{ dataItem.shrinkPercentLy | rawValueConverter }}</td>
            </tr>
        </ng-container>

    </tbody>
</table>
<br />
