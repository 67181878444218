import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';

@Component({
  selector: 'error-view',
  templateUrl: './error-view.component.html',
  styleUrls: ['./error-view.component.css']
})
export class ErrorViewComponent implements OnInit {

  errrorMessage: string = null;
  linkUrl: string = null;

  errorList = [
    "Oops! Something went wrong",
    "Hmmm. This seems odd!",
  ]

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _utils: UtilsService
  ) {
    let urlOptions = this._router.getCurrentNavigation().extras.state;

    if (this._utils.isNotNullOrUndefined(urlOptions)) {
      this.linkUrl = urlOptions.linkUrl;
    }
    else
    {
      this.reloadSite();
    }
  }

  reloadSite() {
    window.location.href = "/";
  }

  ngOnInit(): void {
    this.errrorMessage = this.errorList[this.getRandomInt(this.errorList.length)];
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  copyLinkToClipboard() {
    this._utils.copyTextToClipboard(this.linkUrl);
    this._utils.showGeneralMessage("Link Copied");
  }
}
