<div>
    <h2 mat-dialog-title >Negative plans found</h2>
    <mat-dialog-content style="max-height: 60vh; overflow-y: auto;">
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <span style="text-align: left;" *ngIf="futureSeasonNegPurchaseWeeks.length > 0 || comingSeasonNegPurchaseWeeks.length > 0 " >
            <h3>
                The below listed weeks have negative purchase plans and cannot be published from Department Plan.
            </h3>
        </span>
        <div *ngIf="comingSeasonNegPurchaseWeeks.length > 0" class="d-flex justify-content-center">
        <table class="table table-bordered" id="negative-coming-purchase-week-table">
            <thead>
                <tr>
                    <th> Season : {{comingSeasonNegPurchaseWeeks[0].seasonName}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let comingSeasonNegPurchaseWeek of comingSeasonNegPurchaseWeeks; let dataIndex=index;">
                    <tr>
                        <td>{{comingSeasonNegPurchaseWeek.weekName}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        </div>

        <div *ngIf="futureSeasonNegPurchaseWeeks.length > 0" >
            <table class="table table-bordered" id="negative-future-purchase-week-table">
                <thead>
                    <tr>
                        <th>Season : {{futureSeasonNegPurchaseWeeks[0].seasonName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let futureSeasonNegPurchaseWeek of futureSeasonNegPurchaseWeeks; let dataIndex=index;">
                        <tr>
                            <td>{{futureSeasonNegPurchaseWeek.weekName}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <span style="text-align: left;" *ngIf="futureSeasonNegCoverageWeeks.length > 0 || comingSeasonNegCoverageWeeks.length > 0 ">
            <h3>
                The below listed weeks have negative planned coverage and cannot be published from Department Plan.
            </h3>
        </span>
        <div *ngIf="comingSeasonNegCoverageWeeks.length > 0" class="d-flex justify-content-center">
        <table class="table table-bordered" id="negative-coming-coverage-week-table">
            <thead>
                <tr>
                    <th> Season : {{comingSeasonNegCoverageWeeks[0].seasonName}}</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let comingSeasonNegCoverageWeek of comingSeasonNegCoverageWeeks; let dataIndex=index;">
                    <tr>
                        <td>{{comingSeasonNegCoverageWeek.weekName}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        </div>

        <div *ngIf="futureSeasonNegCoverageWeeks.length > 0" >
            <table class="table table-bordered" id="negative-future-coverage-week-table">
                <thead>
                    <tr>
                        <th>Season : {{futureSeasonNegCoverageWeeks[0].seasonName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let futureSeasonNegCoverageWeek of futureSeasonNegCoverageWeeks; let dataIndex=index;">
                        <tr>
                            <td>{{futureSeasonNegCoverageWeek.weekName}}</td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <span style="text-align: left;">
            <h3>
                 Would you like to save anyway?
            </h3>
        </span>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary"
                 [mat-dialog-close]="true">Yes</button>
            <button mat-raised-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>
