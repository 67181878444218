import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { DepartmentCalculationDataItem } from "../../../planning-view/types/department-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class GroundRAndDPercentCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Gross Sales LY
    // R And D LY

    // Calculates
    // ----------
    // Ground R And D Percent Weekly
    // Ground R And D Percent Periodic


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let grossSalesLY = 0;

        if (utils.isNotNullUndefinedOrZero(currentWeekItem.grossSalesLy))
            grossSalesLY = currentWeekItem.grossSalesLy;
        else if (utils.isNotNullUndefinedOrZero(currentWeekItem.grossSales2Ground))
            grossSalesLY = currentWeekItem.grossSales2Ground;

        let rAndDLY = 0;

        if (utils.isNotNullUndefinedOrZero(currentWeekItem.rAndDLy))
            rAndDLY = currentWeekItem.rAndDLy;
        else if (utils.isNotNullUndefinedOrZero(currentWeekItem.rAndD2Ground))
            rAndDLY = currentWeekItem.rAndD2Ground;
        if (
            utils.isNotNullUndefinedOrZero(rAndDLY) &&
            utils.isNotNullUndefinedOrZero(grossSalesLY)
        ) {
            value = (rAndDLY / grossSalesLY) * 100;
        }

        currentWeekItem[CalculationDataItemType.GroundRAndDPercentWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        // Average R&D% Ground = (Sum of R&D% Ground for period/ Sum of Gross Sales Ground for period)
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let grossSalesLySum = 0;
        let rAndDLySum = 0;

        currentPeriodWeekItems.forEach(item => {
            if (utils.isNotNullUndefinedOrZero(item.grossSalesLy))
                grossSalesLySum += item.grossSalesLy;
            else if (utils.isNotNullUndefinedOrZero(item.grossSales2Ground))
                grossSalesLySum += item.grossSales2Ground;

            if (utils.isNotNullUndefinedOrZero(item.rAndDLy))
                rAndDLySum += item.rAndDLy;
            else if (utils.isNotNullUndefinedOrZero(item.rAndD2Ground))
                rAndDLySum += item.rAndD2Ground;
        });
            
        if (utils.isNotNullUndefinedOrZero(grossSalesLySum)) {
            value = (rAndDLySum / (grossSalesLySum)) * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.GroundRAndDPercentPeriodic] = value;
        });
    }
}