import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class PopulateInputCellCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets Add moves MSEK input field to the value of the AddMovesMSek field
    // Sets Add Remove MSEK input field to the value of the AddRemoveMSek field

    // Dependencies
    // ------------
    // Add Moves MSek (raw)
    // Add Remove MSek (raw)

    // Calculates
    // ----------
    // Input Add Remove MSek Weekly (calculated)
    // Input Add Remove MSek Weekly (calculated)


    // Periodic
    // ------
    // Sets Forecast Gross Input field based on the dem del field

    // Dependencies
    // ------------
    // DemDel Plan (raw)
    // Effective Sales Plan Weekly (calculated)
    // Gross Sales Ground (raw)
    // Start Week Sales Gross Ground (raw)

    // Calculates
    // ----------
    // Input Forecast Gross Periodic (calculated)


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {

        let utils = this.calculatorDriver.getUtilsService();
        if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.AddMovesMSek])) {
            currentWeekItem[CalculationDataItemType.InputAddMovesMSekWeekly] = Number(currentWeekItem[CalculationDataItemType.AddMovesMSek].toFixed(3));
        }
        if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.AddRemoveMSek])) {
            currentWeekItem[CalculationDataItemType.InputAddRemoveMSekWeekly] = Number(currentWeekItem[CalculationDataItemType.AddRemoveMSek].toFixed(3));
        }
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = null;
        let demDelSum = 0;
        let grossSalesGroundSum = 0;
        let valueWithoutHandlingForOutcomeWeeks = null;
        let demDelSumWithoutHandlingForOutcomeWeeks = 0;
        let grossSalesGroundSumWithoutHandlingForOutcomeWeeks = 0;
        let showValue = false;
        const utils = this.calculatorDriver.getUtilsService();

        // iterate over each item of the period
        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {

            // show the forecast gross input value only if atleast one of the weeks in the period has a non zero value in the demdel plan
            if (utils.isNotNullOrUndefined(periodWeekItem[CalculationDataItemType.DemDelPlan])) {
                showValue = true;
            }

           if (utils.isNotNullOrUndefined(periodWeekItem[CalculationDataItemType.EffectiveSalesPlanWeekly])) {
                demDelSum += periodWeekItem[CalculationDataItemType.EffectiveSalesPlanWeekly];
                let grossSalesGround = periodWeekItem[CalculationDataItemType.GrossSalesGround];
                grossSalesGroundSum += grossSalesGround + (periodWeekItem[CalculationDataItemType.StartWeekAfterCurrentWeek] ? periodWeekItem[CalculationDataItemType.StartWeekSalesGrossGround] : 0);
          }
           else if (utils.isNotNullUndefinedOrZero(periodWeekItem[CalculationDataItemType.GrossSales])) {
             demDelSum += periodWeekItem[CalculationDataItemType.GrossSales];
             grossSalesGroundSum += periodWeekItem[CalculationDataItemType.GrossSalesGround];
           }

            // Get this either way for inputForecastGrossPeriodicOriginal
            if (utils.isNotNullOrUndefined(periodWeekItem[CalculationDataItemType.EffectiveSalesPlanWeekly])) {
                demDelSumWithoutHandlingForOutcomeWeeks += periodWeekItem[CalculationDataItemType.EffectiveSalesPlanWeekly];
                let grossSalesGround2 = periodWeekItem[CalculationDataItemType.GrossSalesGround];
                grossSalesGroundSumWithoutHandlingForOutcomeWeeks += grossSalesGround2 + (periodWeekItem[CalculationDataItemType.StartWeekAfterCurrentWeek] ? periodWeekItem[CalculationDataItemType.StartWeekSalesGrossGround] : 0);
            }
        });

        if (showValue) {
            if (utils.isNotNullUndefinedOrZero(grossSalesGroundSum))
                value = utils.precisionRound((demDelSum / grossSalesGroundSum) * 100, 2);
            if (utils.isNotNullUndefinedOrZero(grossSalesGroundSumWithoutHandlingForOutcomeWeeks))
                valueWithoutHandlingForOutcomeWeeks = utils.precisionRound((demDelSumWithoutHandlingForOutcomeWeeks / grossSalesGroundSumWithoutHandlingForOutcomeWeeks) * 100, 2);
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.InputForecastGrossPeriodic] = value;
            // This is not actually shown but we need to save to get correct factor when editing periods with outcome
            periodWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal] = valueWithoutHandlingForOutcomeWeeks;
            periodWeekItem[CalculationDataItemType.InputForecastGrossPeriodicPrevious] = periodWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal];
        });
    }
}
