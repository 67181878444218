<div id="selection-page-main">
    <div class="row fullheightrow" *ngIf="_authService.isUserLoggedIn() && pageState!='access-denied'">
        <div class="selection-page-sub-container selection-page-panel"></div>
        <div class="selection-page-main-container selection-page-panel">
            <ng-container *ngIf="pageState=='loading'; then selectionViewLoading; else selectionViewLoaded">
            </ng-container>
            <ng-template #selectionViewLoading>
                <div class="col-md-12 selection-container">
                    <omnitdp-skeleton-loader [skeletonType]="'selection-view'"></omnitdp-skeleton-loader>
                </div>
            </ng-template>
            <ng-template #selectionViewLoaded>
                <div class="col-md-12 selection-container" *ngIf="pageState=='loaded'">
                    <div class="col-md-12">
                        <span class="selection-view-heading">Configure Planning View</span>
                        <hr />
                    </div>
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-5">
                                <span class="control-title">Brand<span class="required-asterisk">*</span></span>
                                <mat-form-field appearance="fill">
                                    <mat-select id="selectionview-ddlbrand" [disabled]="!isCopyComponentReady" [hideSingleSelectionIndicator]="true"
                                    [disableOptionCentering]="false"
                                        placeholder="Select Brand" [(ngModel)]="selectedBrand"
                                        (selectionChange)="brandUpdated()">
                                        <mat-option *ngFor="let brand of availableBrands"
                                            [value]="brand">
                                            {{brand.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="form-group organization">
                        <div class="col-md-8">
                            <span class="control-title">Organization<span class="required-asterisk">*</span></span>
                            <button mat-stroked-button id="selectionview-btnassortment" class="btn-organization"
                                id="btnAssortment" color="primary"
                                [ngStyle]="selectedOrganization.name == 'Assortment' && {'background-color': '#3f51b5', 'color': 'white'}"
                                (click)="setSelectedOrganization('Assortment'); organizationUpdated();"
                                [disabled]="!isOrganizationEnabled('Assortment') || !isCopyComponentReady">Assortment</button>
                            <button  mat-stroked-button id="selectionview-btnsales" class="btn-organization"
                                id="btnSales" color="primary"
                                [ngStyle]="selectedOrganization.name == 'Sales' && {'background-color': '#3f51b5', 'color': 'white'}"
                                (click)="setSelectedOrganization('Sales'); organizationUpdated();"
                                [disabled]="!isOrganizationEnabled('Sales') || !isCopyComponentReady">Sales</button>
                        </div>
                    </div>
                    <hr />
                    <div class="form-group" id="selection-assortment" *ngIf="selectedOrganization.name == 'Assortment'">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <span class="control-title">Structure<span class="required-asterisk">*</span></span>
                                    <mat-form-field appearance="fill">
                                        <mat-select id="selectionview-lststructure" [disabled]="!isCopyComponentReady" [hideSingleSelectionIndicator]="true"
                                            placeholder="Select Structure" [(ngModel)]="selectedAssortmentStructureType"
                                            (selectionChange)="assortmentStructureTypeUpdated()">
                                            <mat-option *ngFor="let structure of availableAssortmentStructureTypes"
                                                [value]="structure">
                                                {{structure.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>

                                </div>
                                <div class="col-md-1"></div>
                                <div class="col-md-6">
                                    <div id="section"
                                        *ngIf="selectedAssortmentStructureType.name.startsWith('Section') || selectedAssortmentStructureType.name.startsWith('CustomerGroup')">
                                        <span class="control-title">{{getStructureNameForStructureType()}}<span
                                                class="required-asterisk">*</span></span>
                                        <mat-form-field appearance="fill"
                                            *ngIf="availableAssortmentStructureItems && availableAssortmentStructureItems.length != 0">
                                            <mat-select id="selectionview-ddlsection" [disabled]="!isCopyComponentReady" [hideSingleSelectionIndicator]="true"
                                                placeholder="Select Section" [(ngModel)]="selectedAssortmentStructure">
                                                <mat-option *ngFor="let structure of availableAssortmentStructureItems"
                                                    [value]="structure">
                                                    {{ structure.code + " - " + structure.name }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <span *ngIf="availableAssortmentStructureItems && availableAssortmentStructureItems.length == 0"
                                            class="region-validation-message validation-message">You dont have access</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="no-top-margin" />
                    </div>
                    <div class="form-group" id="selection-sales" *ngIf="selectedOrganization.name == 'Sales'">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-5">
                                    <span class="control-title">Region / PM<span class="required-asterisk">*</span></span>
                                    <mat-selection-list id="selectionview-ddlregion" [disabled]="!isCopyComponentReady" [hideSingleSelectionIndicator]="true"
                                        [multiple]="false" class="background-list" [(ngModel)]="selectedMarketList" (selectionChange)="marketChanged()">
                                        <mat-list-option *ngFor="let market of availableSalesMarkets" [value]="market">
                                            {{ market.marketIntegrationKey + " - " + market.shortName }}
                                        </mat-list-option>
                                    </mat-selection-list>
                                    <span *ngIf="availableSalesMarkets.length == 0" class="region-validation-message validation-message">You dont have access to any markets for the selected brand</span>
                                </div>
                                <div class="col-md-7">
                                    <span class="control-title">Structure<span class="required-asterisk">*</span></span>
                                    <mat-selection-list [multiple]="false" class="background-list" [hideSingleSelectionIndicator]="true"
                                        [(ngModel)]="selectedSalesStructureTypesList" [disabled]="!isCopyComponentReady">
                                        <mat-list-option *ngFor="let structure of availableSalesStructureTypesList"
                                            [value]="structure">
                                            {{ structure.name }}
                                        </mat-list-option>
                                    </mat-selection-list>
                                </div>
                            </div>
                        </div>

                        <hr>

                    </div>
                    <omnitdp-selection-view-copy 
                        [organization]="selectedOrganization"
                        [brand]="selectedBrand" 
                        [assortmentStructureType]="selectedAssortmentStructureType"
                        [assortmentStructure]="selectedAssortmentStructure"
                        [mainMarket]="selectedMarketList && selectedMarketList.length !=0 ? selectedMarketList[0] : null"
                        [salesCopyDialogMarkets]="salesCopyDialogMarkets"
                        [regionMappings]="selectionPageResponseData.sales.regionMappings"

                        (copyComponentReady)="onCopyComponentReady($event)"
                        (copySelected)="onCopySelect($event)"
                        (showOthersCopySelectionChanged)="onShowOthersCopySelectionChange($event)"
                        >
                    </omnitdp-selection-view-copy>
                    <hr />
                    <div class="form-group">
                        <div class="col-12">
                            <button mat-raised-button color="primary" class="btn" (click)="submitForm()"
                                [disabled]="!isCopyComponentReady">Open Planning View</button>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>

        <div class="selection-page-sub-container selection-page-panel"></div>
    </div>

    <div class="row fullheightrow" *ngIf="!_authService.isUserLoggedIn() || pageState=='access-denied'">
        <div class="col-md-3 selection-page-panel"></div>
        <div class="col-md-6 selection-page-panel" *ngIf="pageState=='access-denied'">
            <div class="selection-container">
                <mat-card>
                    <mat-card-title><span class="material-icons" style="font-size: 19px;"> sentiment_very_dissatisfied
                        </span>&nbsp;Sorry, We cant let you in right now</mat-card-title>
                    <mat-card-subtitle></mat-card-subtitle>
                    <mat-card-content>
                        <p>It seems something went wrong or you havent been granted any permissions in GAM</p>
                        <p>Please check with GAM for access related issues.</p>
                    </mat-card-content>
                    <mat-card-actions>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
        <div class="col-md-3 selection-page-panel"></div>
    </div>
</div>