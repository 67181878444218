import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import moment from 'moment';
import { ApiRoutes } from 'src/app/modules/shared/types/api-routes';
import { environment } from 'src/environments/environment';
import { SeasonInfoRequest } from '../../shared/types/api/season-data/season-info-request';
import { SeasonInfo } from '../../shared/types/season-info';
import { WeekDataItem } from '../../shared/types/week-data-item';
import { PlanningViewDataRequest } from '../types/api/planning-view-data-request';
import { DepartmentPlanningViewDataResponse } from '../types/api/planning-view-data/department-planning-view-data-response';
import { ParentPlanningViewDataResponse } from '../types/api/planning-view-data/parent-planning-view-data-response';
import { ReadonlyDepartmentPlanningViewDataResponse } from '../types/api/planning-view-data/readonly-department-planning-view-data-response';

@Injectable({
    providedIn: 'root'
})
export class SeasonDataService {

    private departmentSalesurl = environment.baseApiUrl + ApiRoutes.salesplanningviewdata
    private departmentAssortmenturl = environment.baseApiUrl + ApiRoutes.assortmentplanningviewdata;

    private parentSalesurl = environment.baseHeavyLoadApiUrl + ApiRoutes.parentSalesplanningviewdata
    private parentAssortmenturl = environment.baseApiUrl + ApiRoutes.parentAssortmentplanningviewdata;


    private readonlyDepartmentSalesurl = environment.baseApiUrl + ApiRoutes.readonlyDepartmentSalesplanningviewdata
    private readonlyDepartmentAssortmenturl = environment.baseApiUrl + ApiRoutes.readonlyDepartmentAssortmentplanningviewdata;

    private aggregatedMainsAssortmenturl = environment.baseApiUrl + ApiRoutes.assortmentaggregatedmainsplanningviewdata;

    private salesAggregatedPmMainsUrl = environment.baseApiUrl + ApiRoutes.salesaggregatedpmmainsplanningviewdata;  
    private salesSystemGoalForAssortmentUrl = environment.baseHeavyLoadApiUrl + ApiRoutes.salesSystemGoalInAssortmentData;

    private seasonInfoUrl = environment.baseApiUrl + ApiRoutes.seasonInfo;


    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient) { }

    getDepartmentSeasonData(dataRequest: PlanningViewDataRequest, isAssortmentView: boolean): Promise<DepartmentPlanningViewDataResponse> {

        var requrl = (isAssortmentView) ? this.departmentAssortmenturl : this.departmentSalesurl;
        return this.http.post<DepartmentPlanningViewDataResponse>(requrl, dataRequest, this.httpOptions).toPromise();

    }

    getParentSeasonData(dataRequest: PlanningViewDataRequest, isAssortmentView: boolean): Promise<ParentPlanningViewDataResponse> {

        var requrl = (isAssortmentView) ? this.parentAssortmenturl : this.parentSalesurl;
        return this.http.post<ParentPlanningViewDataResponse>(requrl, dataRequest, this.httpOptions).toPromise();

    }

    getAggregatedMainsSeasonData(dataRequest: PlanningViewDataRequest): Promise<ParentPlanningViewDataResponse> {

        return this.http.post<ParentPlanningViewDataResponse>(this.aggregatedMainsAssortmenturl, dataRequest, this.httpOptions).toPromise();

    }

    getReadonlyDepartmentSeasonData(dataRequest: PlanningViewDataRequest, isAssortmentView: boolean): Promise<ReadonlyDepartmentPlanningViewDataResponse> {

        var requrl = (isAssortmentView) ? this.readonlyDepartmentAssortmenturl : this.readonlyDepartmentSalesurl;
        return this.http.post<ReadonlyDepartmentPlanningViewDataResponse>(requrl, dataRequest, this.httpOptions).toPromise();

    }

    
    getSalesAggregatedPmMainsSeasonData(dataRequest: PlanningViewDataRequest): Promise< WeekDataItem[]> {
        return this.http.post<WeekDataItem[]>(this.salesAggregatedPmMainsUrl, dataRequest, this.httpOptions).toPromise();
    }

    getSalesSystemGoalForAssortmentData(requestData: PlanningViewDataRequest): Promise< WeekDataItem[]>
    {
        return this.http.post<WeekDataItem[]>(this.salesSystemGoalForAssortmentUrl, requestData, this.httpOptions).toPromise();
    }

    getSeasonInfo(request: SeasonInfoRequest): Promise<SeasonInfo[]> {
        let url = "";
        if (request.date != null && request.date != undefined) {
            let dateString = moment(request.date).format('YYYY-MM-DD');
            url = this.seasonInfoUrl + "?date=" + dateString + "&excludeOldSeasons=" + request.excludeOldSeasons
        }
        else {
            url = this.seasonInfoUrl + "?excludeOldSeasons=" + request.excludeOldSeasons
        }

        return this.http.get<SeasonInfo[]>(url, this.httpOptions).toPromise();
    }


}
