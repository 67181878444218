import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { LoadingAnimationsService } from './services/loading-animations.service';
import { AuthenticationService } from './services/authentication.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTreeModule } from '@angular/material/tree';
import { MatCardModule } from '@angular/material/card'
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogBoxComponent } from './components/dialog-box/dialog-box.component';
import { MatTabsModule } from '@angular/material/tabs'
import { MatPaginatorModule } from '@angular/material/paginator';
import { SkeletonLoaderComponent } from './components/skeleton-loader/skeleton-loader.component';
import { FeatureFlagService } from './services/feature-flag.service';
import { DialogBoxService } from './services/dialog-box-service';
import { UiHelperService } from './services/ui-helper.service';
import { UtilsService } from './services/utils.service';
import { MatTooltipModule } from '@angular/material/tooltip'
import { RawValueConverterPipe } from './pipes/raw-value-converter';
import { NgChartsModule } from 'ng2-charts';
import { ThousandSeparatorPipe } from './pipes/thousand-separator-pipe';
import { UserConfigService } from './services/user-config.service';
import {CdkTreeModule} from '@angular/cdk/tree';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatMenuModule,
        MatStepperModule,    
        MatInputModule,
        MatSelectModule,
        MatTableModule,    
        MatSelectModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatTreeModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatCardModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatPaginatorModule,
        MatTooltipModule,
        NgChartsModule,
        CdkTreeModule
    ],
    declarations: [
        DialogBoxComponent,
        SkeletonLoaderComponent,
        RawValueConverterPipe,
        ThousandSeparatorPipe
    ],
    exports: [
        DialogBoxComponent,
        SkeletonLoaderComponent,
        
        CommonModule,
        FormsModule,
        HttpClientModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        
        CommonModule,
        FormsModule,
        MatToolbarModule,
        MatIconModule,
        MatFormFieldModule,
        MatButtonModule,
        MatMenuModule,
        MatListModule,
        MatMenuModule,
        MatStepperModule,    
        MatInputModule,
        MatSelectModule,
        MatTableModule,    
        MatSelectModule,
        MatButtonToggleModule,
        MatCheckboxModule,
        MatTreeModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatSnackBarModule,
        MatCardModule,
        MatSidenavModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatTabsModule,
        MatPaginatorModule,
        MatTooltipModule,        
        RawValueConverterPipe,
        ThousandSeparatorPipe,
        NgChartsModule
    ],
    providers:[
        AuthenticationService,
        LoadingAnimationsService,
        FeatureFlagService,
        DialogBoxService,
        UiHelperService,
        UtilsService,
        DatePipe,
        UserConfigService
    ]
})
export class SharedModule { }
