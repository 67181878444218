import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { CopyDialogBoxComponent } from './components/copy-dialog/copy-dialog.component';
import { CopyComponent } from './components/copy/copy.component';
import { ErrorViewComponent } from './components/error-view/error-view.component';
import { SelectionViewComponent } from './components/selection-view/selection-view.component';
import { SelectionViewRoutingModule } from './selection-view-routing.module';

@NgModule({
    imports: [
        SharedModule,
        SelectionViewRoutingModule,
    ],
    declarations: [
        SelectionViewComponent,
        CopyDialogBoxComponent,
        CopyComponent,
        ErrorViewComponent
    ],
    exports: [        
    ],
    providers:[
    ]
})
export class SelectionViewModule { }
