import { Injectable } from '@angular/core';


// Service to display loading animations
@Injectable({
    providedIn: 'root'
})
export class LoadingAnimationsService {

    topNavAnimation:boolean = false;

    enableTopNavAnimation()
    {
        this.topNavAnimation = true;
    }

    disableTopNavAnimation()
    {
        this.topNavAnimation = false;
    }

    stopAllLoadingAnimations()
    {
        this.topNavAnimation = false;
    }

    AreLoadingAnimationsInProgress():boolean{
        return this.topNavAnimation;
    }
}