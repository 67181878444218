import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import * as _ from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { GamPermission, UserGamPermission, UserInfo } from 'src/app/modules/shared/types/user-info';
import { environment } from 'src/environments/environment';
import { CalculationDataItemType } from '../../planning-view/types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from '../../planning-view/types/department-calculation-data-item';
import { SalesCopy } from '../../selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { RegionMapping } from '../../selection-view/types/api/selection-page-response';
import { ApiRoutes } from '../types/api-routes';
import { Constants } from '../types/constants';
import { Market } from '../types/market';
import { MarketType } from '../types/market-type';
import { PlanningMarket } from '../types/planningMarket';
import { RetrievalMode } from '../types/retrieval-mode';
import { SeasonInfo } from '../types/season-info';
import { SeasonPlanningType } from '../types/season-planning-type';
import { SeasonType } from '../types/season-type';
import { SeasonViewType } from '../types/season-view-type';
import { SeasonSetting } from '../types/user-config';
import { UserConfigService } from './user-config.service';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {

    constructor(
        private http: HttpClient,
        public _snackBar: MatSnackBar,
        private _userConfigService: UserConfigService
    ) { }

    getRegionForPRPM(prpm: Market, regions: Market[]): Market {
        let region = _.find(regions, (region: Market) => {
            return region.description.toLowerCase().indexOf(prpm.shortName.toLowerCase()) != -1
        });

        return (this.isNotNullOrUndefined(region)) ? region : null;
    }

    getWeekNumber(withYear: boolean, date?: Date): number {

        let currentdate: Date = new Date();

        if (date) {
            currentdate = date;
        }

        if (withYear) {
            return parseInt(currentdate.getFullYear().toString() + moment(currentdate).isoWeek().toString().padStart(2, '0'));
        }
        else {
            return parseInt(moment(currentdate).isoWeek().toString());
        }
    }

    getWeeksForPeriod(date?: Date): number[] {
        let periodWeeks: number[] = [];
        let selectedDate: Date = new Date();

        if (date) {
            selectedDate = date;
        }

        let currentDate = new Date(selectedDate.toDateString());

        // get end week for period
        while (moment(currentDate).isoWeek() % 4 != 0) {
            periodWeeks.push(parseInt(currentDate.getFullYear().toString() + moment(currentDate).isoWeek().toString().padStart(2, '0')));
            currentDate = moment(currentDate).add(7, 'days',).toDate();
        }

        periodWeeks.push(parseInt(currentDate.getFullYear().toString() + moment(currentDate).isoWeek().toString().padStart(2, '0')));

        // get start weekk for period
        currentDate = new Date(selectedDate.toDateString());

        currentDate = moment(currentDate).add(-7, 'days',).toDate();
        while (moment(currentDate).isoWeek() % 4 != 0) {
            periodWeeks.push(parseInt(currentDate.getFullYear().toString() + moment(currentDate).isoWeek().toString().padStart(2, '0')));
            currentDate = moment(currentDate).add(-7, 'days',).toDate();
        }

        periodWeeks = periodWeeks.sort((a, b) => (a - b));

        return periodWeeks;

    }

    doesUserHavePrOrPmAccess(selectedMarket: Market, userInfo: UserInfo, marketType: MarketType, regionMappings: RegionMapping[]): boolean {
        let hasAccess: boolean = false;

        if (userInfo && selectedMarket) {
            let userPermissions = [];
            let impersonationPermission = userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);
            // find if the user is impersonating then assign the impersonated user's permission to filter it.
            if (impersonationPermission != undefined && impersonationPermission.settings.isCurrentlyImpersonating && userInfo.impersonatedUserInfo.gamPermissions) {
                userPermissions = userInfo.impersonatedUserInfo.gamPermissions;
            }
            else {
                if (userInfo.gamPermissions) {
                    userPermissions = userInfo.gamPermissions;
                }
            }

            if (marketType == MarketType.PlanningRegion) {
                // find if the user has permission from the User's GAM permissions list

                // 1. Check if the user has direct Planning Region access
                userPermissions.forEach((userGamPermission: UserGamPermission) => {
                    if (userGamPermission.typeName == 'PlanningRegion') {
                        if (userGamPermission.permissions.find((gamPermission: GamPermission) => gamPermission.id == selectedMarket.marketIntegrationKey.toString()) != undefined) {
                            hasAccess = true;
                        }
                    }
                });

                // 2. Check if the user has implicit Planning Region access.
                // Implicit Planning Region Access is granted if the user has access to all the Planning Markets within the Planning Region

                // only check if access is not resolved yet
                if (!hasAccess && this.isNotNullOrUndefined(regionMappings) && regionMappings.length != 0) {
                    // get all the Planning Markets within the Planning Region

                    // get the needed Planning Region
                    let planningRegion = regionMappings.find(r => r.region.marketIntegrationKey == selectedMarket.marketIntegrationKey);

                    // get all the Planning Markets within it
                    if (this.isNotNullOrUndefined(planningRegion)) {
                        let planningMarkets = planningRegion.childMarkets;

                        let implicitRegionAccess = true;

                        // check if user has access to all the Planning Markets within the given Planning Region
                        planningMarkets.forEach(pm => {

                            let pmAccessFound = false;
                            userPermissions.forEach((userGamPermission: UserGamPermission) => {

                                if (userGamPermission.typeName == 'PlanningMarket') {
                                    let permissionFound = userGamPermission.permissions.find(g => g.id == pm.marketIntegrationKey.toString());

                                    if (this.isNotNullOrUndefined(permissionFound)) {
                                        pmAccessFound = true;
                                    }
                                }
                            });

                            if (!pmAccessFound) {
                                implicitRegionAccess = false;
                            }
                        });

                        hasAccess = implicitRegionAccess;
                    }
                }
            }
            else if (marketType == MarketType.PlanningMarket) {
                userPermissions.forEach((userGamPermission: UserGamPermission) => {
                    if (userGamPermission.typeName == 'PlanningMarket') {

                        let permissionFound = userGamPermission.permissions.find((gamPermission: GamPermission) => {
                            return (selectedMarket.marketIntegrationKey && gamPermission.id == selectedMarket.marketIntegrationKey.toString()) || gamPermission.id == selectedMarket.marketIntegrationKey.toString()
                        });

                        hasAccess = this.isNotNullOrUndefined(permissionFound);
                    }
                });
            }

        }
        return hasAccess;
    }

    doesUserHavePmAccess(selectedSalesCopy: SalesCopy, userInfo: UserInfo): boolean {
        let hasAccess: boolean = false;
        if (userInfo && selectedSalesCopy) {
            let userPermissions = [];
            let impersonationPermission = userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);
            //find if the user is impersonating then assign the impersonate user permission to filter it.
            if (impersonationPermission != undefined && impersonationPermission.settings.isCurrentlyImpersonating && userInfo.impersonatedUserInfo.gamPermissions) {
                userPermissions = userInfo.impersonatedUserInfo.gamPermissions;
            }
            else {
                if (userInfo.gamPermissions) {
                    userPermissions = userInfo.gamPermissions;
                }
            }

            userPermissions.forEach((userGamPermission: UserGamPermission) => {
                if (userGamPermission.typeName == 'PlanningMarket') {

                    let permissionFound = userGamPermission.permissions.find((gamPermission: GamPermission) => {
                        return (selectedSalesCopy.pmCode && gamPermission.id == selectedSalesCopy.pmCode.toString()) || gamPermission.id == selectedSalesCopy.marketIntegrationKey.toString()
                    });

                    hasAccess = this.isNotNullOrUndefined(permissionFound);
                }
            });
        }
        return hasAccess;
    }

    doesUserHaveImpersonateUserFunctionAccess(userInfo: UserInfo): boolean {
        let hasAccess: boolean = false;
        if (userInfo) {
            let impersonationPermission = userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);
            hasAccess = this.isNotNullOrUndefined(impersonationPermission);
        }

        return hasAccess;
    }

    getPeriodIndexes(currentIndex: number) {
        let indexes = [];

        let lowerIndex = Math.floor(currentIndex / 4) * 4;

        for (let i = lowerIndex; i < lowerIndex + 4; i++) {
            indexes.push(i);
        }

        return indexes;
    }

    validateUserEmail(userEmail: string): Observable<any> {

        let validateUserEmailurl = environment.baseApiUrl + ApiRoutes.validateuserEmail;
        var req = {
            "userEmail": userEmail
        }

        let httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        }

        return this.http.post(validateUserEmailurl, req, httpOptions);
    }

    enumFromValue = <T extends Record<any, any>>(val: any, _enum: T) => {
        const enumName = (Object.keys(_enum) as Array<keyof T>).find(k => _enum[k] === val)
        if (!enumName) return null;
        return _enum[enumName]
    }

    precisionRound(number: number, precision: number) {
        if (precision < 0) {
            let factor = Math.pow(10, precision);
            return Math.round(number * factor) / factor;
        }
        else
            return +(Math.round(Number(number + "e+" + precision)) +
                "e-" + precision);
    }

    isNotNullUndefinedOrZero(value: number): boolean {
        if (value != null && value != undefined && value != 0) {
            return true;
        }
        else {
            return false;
        }
    }

    isNullUndefinedOrZero(value: number): boolean {
        if (value == null && value == undefined && value != 0) {
            return true;
        }
        else {
            return false;
        }
    }

    isNullOrUndefined(value: any): boolean {
        if (value == null || value == undefined) {
            return true;
        }
        else {
            return false;
        }
    }

    isNotNullOrUndefined(value: any): boolean {
        if (value != null && value != undefined) {
            return true;
        }
        else {
            return false;
        }
    }

    isNullOrEmpty(value): boolean {
        if (value == null) {
            return true;
        }
        else if (typeof value === 'string' && value.trim() == "") {
            return true;
        }

        return false;
    }

    roundNumber(number, decimalPlaces): number {
        let factorOfTen = Math.pow(10, decimalPlaces);
        return Math.round(number * factorOfTen) / factorOfTen;
    }


    sumKpiForPeriod(periodDataItems: DepartmentCalculationDataItem[], kpiType: CalculationDataItemType): number {

        let value = 0;

        periodDataItems.forEach((periodDataItem: DepartmentCalculationDataItem) => {
            if (this.isNotNullUndefinedOrZero(periodDataItem[kpiType])) {
                value += periodDataItem[kpiType];
            }
        });

        return value;
    }

    _getPeriodIndexesByWeekIndex(weekIndex: number): number[] {
        let startWeekIndex = Math.floor(weekIndex / 4) * 4;

        return [startWeekIndex, startWeekIndex + 1, startWeekIndex + 2, startWeekIndex + 3];
    }

    getSeasonTypeFromSeasonName(seasonName: number): SeasonType {
        // fall are even numbers
        // spring are odd numbers
        return (seasonName % 2 == 0) ? SeasonType.Fall : SeasonType.Spring;
    }

    getAllCalculationDataItemTypes(): CalculationDataItemType[] {
        let fields: CalculationDataItemType[] = [];

        fields.push(CalculationDataItemType.GrossSales);
        fields.push(CalculationDataItemType.GrossSalesLy);
        fields.push(CalculationDataItemType.GrossSalesGround);
        fields.push(CalculationDataItemType.GrossSalesGroundLy);
        fields.push(CalculationDataItemType.GrossSales2Ground);
        fields.push(CalculationDataItemType.RAndD);
        fields.push(CalculationDataItemType.RAndDGround);
        fields.push(CalculationDataItemType.RAndDLy);
        fields.push(CalculationDataItemType.NetSales);
        fields.push(CalculationDataItemType.NetSalesGround);
        fields.push(CalculationDataItemType.CubeDemDelPlan);
        fields.push(CalculationDataItemType.DemDelPlan);
        fields.push(CalculationDataItemType.RAndDPlan);
        fields.push(CalculationDataItemType.EffectiveRAndDPlan);
        fields.push(CalculationDataItemType.AddRemoveMSek);
        fields.push(CalculationDataItemType.AddMovesMSek);
        fields.push(CalculationDataItemType.SPercent);
        fields.push(CalculationDataItemType.StockGross);
        fields.push(CalculationDataItemType.StockGrossLy);
        fields.push(CalculationDataItemType.StockGround);
        fields.push(CalculationDataItemType.StockGroundLy);
        fields.push(CalculationDataItemType.Stock2Ground);
        fields.push(CalculationDataItemType.StockPrognosisLy);
        fields.push(CalculationDataItemType.BoughtGross);
        fields.push(CalculationDataItemType.OrderedGrossGround);
        fields.push(CalculationDataItemType.SalesPlanFixed);
        fields.push(CalculationDataItemType.SalesPlanLy);
        fields.push(CalculationDataItemType.StockPlanFixed);
        fields.push(CalculationDataItemType.ReturnPlanFixed);
        fields.push(CalculationDataItemType.ReturnsGross);
        fields.push(CalculationDataItemType.ReturnsGrossGround);
        fields.push(CalculationDataItemType.PurchasePlanFixed);
        fields.push(CalculationDataItemType.PurchasePlan);
        fields.push(CalculationDataItemType.UnAdjustedBudgetedReturn);
        fields.push(CalculationDataItemType.UnAdjustedPlannedReturn);
        fields.push(CalculationDataItemType.PlannedReturn);
        fields.push(CalculationDataItemType.AdjustedBudgetedReturn);
        fields.push(CalculationDataItemType.StartWeekSalesGrossGround);
        fields.push(CalculationDataItemType.StartWeekSalesPlanFixed);
        fields.push(CalculationDataItemType.StartWeekDemPlan);
        fields.push(CalculationDataItemType.StartWeekAfterCurrentWeek);
        fields.push(CalculationDataItemType.EffectiveSalesPlanWeekly);
        fields.push(CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround);
        fields.push(CalculationDataItemType.EffectiveSystemGoalPlanWeekly);
        fields.push(CalculationDataItemType.SalesForecastWeekly);
        fields.push(CalculationDataItemType.CombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.CombinedNetSalesWeekly);
        fields.push(CalculationDataItemType.CombinedRAndDWeekly);
        fields.push(CalculationDataItemType.RAndDLyPercentWeekly);
        fields.push(CalculationDataItemType.RAndDForecastWeekly);
        fields.push(CalculationDataItemType.GroundSPercentWeekly);
        fields.push(CalculationDataItemType.CombinedSalesLyWeekly);
        fields.push(CalculationDataItemType.GroundSPercentPeriodic);
        fields.push(CalculationDataItemType.ComplementaryDemDel);
        fields.push(CalculationDataItemType.ComplementaryDemDelGround);
        fields.push(CalculationDataItemType.BuyingWeekly);
        fields.push(CalculationDataItemType.BoughtGrossGround);

        fields.push(CalculationDataItemType.AggregatedPMsCombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.AggregatedPMsEffectiveSalesPlanWeekly);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSalesGround);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSales);
        fields.push(CalculationDataItemType.AggregatedPMsRAndDPlan);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSales2Ground);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSalesLy);
        fields.push(CalculationDataItemType.AggregatedPMsIsSaved);

        fields.push(CalculationDataItemType.MarketMainCopyCombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.MarketMainCopyEffectiveSalesPlanWeekly);
        fields.push(CalculationDataItemType.MarketMainCopyGrossSalesGround);
        fields.push(CalculationDataItemType.MarketMainCopyGrossSalesLy);
        fields.push(CalculationDataItemType.MarketMainCopyGrossSales2Ground);

        fields.push(CalculationDataItemType.SalesMainCopyCombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.SalesMainCopyGrossSalesGround);
        return fields;
    }

    getAllParentCalculationDataItemTypes(): CalculationDataItemType[] {
        let fields: CalculationDataItemType[] = [];

        fields.push(CalculationDataItemType.GrossSales);
        fields.push(CalculationDataItemType.GrossSalesLy);
        fields.push(CalculationDataItemType.GrossSalesGround);
        fields.push(CalculationDataItemType.GrossSalesGroundLy);
        fields.push(CalculationDataItemType.GrossSales2Ground);
        fields.push(CalculationDataItemType.RAndD);
        fields.push(CalculationDataItemType.EffectiveRAndDPlan);
        fields.push(CalculationDataItemType.RAndDLy);
        fields.push(CalculationDataItemType.RAndDGround);
        fields.push(CalculationDataItemType.NetSales);
        fields.push(CalculationDataItemType.NetSalesGround);
        fields.push(CalculationDataItemType.CubeDemDelPlan);
        fields.push(CalculationDataItemType.DemDelPlan);
        fields.push(CalculationDataItemType.RAndDPlan);
        fields.push(CalculationDataItemType.AddRemoveMSek);
        fields.push(CalculationDataItemType.AddMovesMSek);
        fields.push(CalculationDataItemType.SPercent);
        fields.push(CalculationDataItemType.StockGross);
        fields.push(CalculationDataItemType.StockGrossLy);
        fields.push(CalculationDataItemType.StockGround);
        fields.push(CalculationDataItemType.StockGroundLy);
        fields.push(CalculationDataItemType.Stock2Ground);
        fields.push(CalculationDataItemType.StockPrognosisLy);
        fields.push(CalculationDataItemType.BoughtGross);
        fields.push(CalculationDataItemType.OrderedGrossGround);
        fields.push(CalculationDataItemType.SalesPlanFixed);
        fields.push(CalculationDataItemType.SalesPlanLy);
        fields.push(CalculationDataItemType.StockPlanFixed);
        fields.push(CalculationDataItemType.ReturnPlanFixed);
        fields.push(CalculationDataItemType.ReturnsGross);
        fields.push(CalculationDataItemType.ReturnsGrossGround);
        fields.push(CalculationDataItemType.PurchasePlanFixed);
        fields.push(CalculationDataItemType.PurchasePlan);
        fields.push(CalculationDataItemType.PlannedReturn);
        fields.push(CalculationDataItemType.AdjustedBudgetedReturn);
        fields.push(CalculationDataItemType.StartWeekSalesGrossGround);
        fields.push(CalculationDataItemType.StartWeekSalesPlanFixed);
        fields.push(CalculationDataItemType.StartWeekDemPlan);
        fields.push(CalculationDataItemType.StartWeekAfterCurrentWeek);
        fields.push(CalculationDataItemType.RAndDForecastWeekly);
        fields.push(CalculationDataItemType.CombinedRAndDWeekly);
        fields.push(CalculationDataItemType.RAndDLyPercentWeekly);
        fields.push(CalculationDataItemType.EffectiveSalesPlanWeekly);
        fields.push(CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround);
        fields.push(CalculationDataItemType.EffectiveSystemGoalPlanWeekly);
        fields.push(CalculationDataItemType.SalesForecastWeekly);
        fields.push(CalculationDataItemType.CombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.CombinedNetSalesWeekly);
        fields.push(CalculationDataItemType.GroundSPercentWeekly);
        fields.push(CalculationDataItemType.CombinedSalesLyWeekly);
        fields.push(CalculationDataItemType.GroundSPercentPeriodic);
        fields.push(CalculationDataItemType.ComplementaryDemDel);
        fields.push(CalculationDataItemType.ComplementaryDemDelGround);
        fields.push(CalculationDataItemType.BuyingWeekly);
        fields.push(CalculationDataItemType.BoughtGrossGround);

        fields.push(CalculationDataItemType.AggregatedPMsCombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.AggregatedPMsEffectiveSalesPlanWeekly);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSalesGround);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSales);
        fields.push(CalculationDataItemType.AggregatedPMsRAndDPlan);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSales2Ground);
        fields.push(CalculationDataItemType.AggregatedPMsGrossSalesLy);
        fields.push(CalculationDataItemType.AggregatedPMsIsSaved);

        fields.push(CalculationDataItemType.MarketMainCopyCombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.MarketMainCopyEffectiveSalesPlanWeekly);
        fields.push(CalculationDataItemType.MarketMainCopyGrossSalesGround);
        fields.push(CalculationDataItemType.MarketMainCopyGrossSalesLy);
        fields.push(CalculationDataItemType.MarketMainCopyGrossSales2Ground);

        fields.push(CalculationDataItemType.SalesMainCopyCombinedSalesPlanWeekly);
        fields.push(CalculationDataItemType.SalesMainCopyGrossSalesGround);

        return fields;
    }

    getStartWeekForSeason(seasonName: number) {
        let startWeek: number = null;

        let seasonType: SeasonType = this.getSeasonTypeFromSeasonName(seasonName);

        let year = seasonName.toString().substring(0, 4);

        if (seasonType == SeasonType.Spring) {
            startWeek = parseInt((parseInt(year) - 1).toString() + "49");
        }
        else if (seasonType == SeasonType.Fall) {
            startWeek = parseInt(parseInt(year).toString() + "21");
        }

        return startWeek;
    }

    getKPeriodStartWeekForSeason(seasonName: number) {
        let startWeek: number = null;

        let seasonType: SeasonType = this.getSeasonTypeFromSeasonName(seasonName);

        let year = seasonName.toString().substring(0, 4);

        if (seasonType == SeasonType.Spring) {
            //Example for s7 202307 , it should start with 202229 to 202328
            startWeek = parseInt((parseInt(year) - 1).toString() + "29");
        }
        else if (seasonType == SeasonType.Fall) {
            //Example for s8 202308 , it should start with 202305 to 202404
            startWeek = parseInt(parseInt(year).toString() + "05");
        }

        return startWeek;
    }


    getEndWeekForSeason(seasonName: number) {
        let endWeek: number = null;

        let seasonType: SeasonType = this.getSeasonTypeFromSeasonName(seasonName);

        let year = seasonName.toString().substring(0, 4);

        if (seasonType == SeasonType.Spring) {
            endWeek = parseInt((parseInt(year)).toString() + "28");
        }
        else if (seasonType == SeasonType.Fall) {
            endWeek = parseInt((parseInt(year) + 1).toString() + "04");
        }

        return endWeek;
    }

    getSeasonNameForOtherPlanningTypeInCurrentView(currentSeasonName: string, currentSeasonPlanningType: SeasonPlanningType, otherPlanningType: SeasonPlanningType) {
        // calculate season diff
        let currentSeasonPlanningTypeValue = null;
        let otherSeasonPlanningTypeValue = null;


        switch (currentSeasonPlanningType) {

            case SeasonPlanningType.Previous:
            case SeasonPlanningType.PreviousWithOldChild:
            case SeasonPlanningType.PreviousWithOld:
                currentSeasonPlanningTypeValue = 1;
                break;
            case SeasonPlanningType.Actual:
                currentSeasonPlanningTypeValue = 2;
                break;
            case SeasonPlanningType.Coming:
                currentSeasonPlanningTypeValue = 3;
                break;
            case SeasonPlanningType.Future:
                currentSeasonPlanningTypeValue = 4;
                break;
        }

        switch (otherPlanningType) {

            case SeasonPlanningType.Previous:
            case SeasonPlanningType.PreviousWithOldChild:
            case SeasonPlanningType.PreviousWithOld:
                otherSeasonPlanningTypeValue = 1;
                break;
            case SeasonPlanningType.Actual:
                otherSeasonPlanningTypeValue = 2;
                break;
            case SeasonPlanningType.Coming:
                otherSeasonPlanningTypeValue = 3;
                break;
            case SeasonPlanningType.Future:
                otherSeasonPlanningTypeValue = 4;
                break;
        }

        let seasonDiff = otherSeasonPlanningTypeValue - currentSeasonPlanningTypeValue;

        return this.shiftSeasons(currentSeasonName, seasonDiff);

    }

    shiftSeasons(currentSeasonName: string, shiftBy: number) {
        let absShiftBy = Math.abs(shiftBy);
        let isShiftNegative = (shiftBy < 0);

        let year = null;
        let isEvenFallSeason = null;

        let seasonName = currentSeasonName;
        let seasonCode = null;

        for (let i = 0; i < absShiftBy; i++) {
            // get current year and code
            year = seasonName.substring(0, 4);
            seasonCode = seasonName.substring(4);

            // check if its a even fall season
            isEvenFallSeason = parseInt(seasonName) % 2 == 0;

            // calc next season name  
            if (isEvenFallSeason) {
                // its an even fall season

                if (isShiftNegative) {
                    // year stays same
                    // code name -1
                    seasonCode = parseInt(seasonCode) - 1;
                }
                else {
                    // increment year and code
                    year = parseInt(year) + 1;
                    seasonCode = parseInt(seasonCode) + 1;
                }
            }
            else {
                // its an odd spring season 

                // next season name                
                if (isShiftNegative) {
                    // year - 1
                    // code name -1
                    year = parseInt(year) - 1;
                    seasonCode = parseInt(seasonCode) - 1;
                }
                else {
                    // year stays same
                    // code name + 1
                    seasonCode = parseInt(seasonCode) + 1;
                }
            }

            if (seasonCode == 0) {
                seasonCode = 10;
            }
            else if (seasonCode == 11) {
                seasonCode = 1;
            }

            seasonName = year.toString() + seasonCode.toString().padStart(2, '0');
        }
        return seasonName;
    }


    copyTextToClipboard(text: string) {
        navigator.clipboard.writeText(text);
    }

    getSeasonViewType(viewDate: Date): SeasonViewType {
        let weekNumber = this.getWeekNumber(false, viewDate);
        //Changes in the end week of Timeline view should be updated in the constants.ts and also SeasonHelper.cs GetSeasonViewConfigurations method
        let views = [
            {
                start: 49,
                end: 53,
                viewType: SeasonViewType.View1
            },
            {
                start: 1,
                end: 8,
                viewType: SeasonViewType.View1
            },
            {
                start: 9,
                end: 20,
                viewType: SeasonViewType.View2
            },
            {
                start: 21,
                end: 32,
                viewType: SeasonViewType.View3
            },
            {
                start: 33,
                end: 48,
                viewType: SeasonViewType.View4
            }
        ]

        let currentView = _.find(views, view => {
            return weekNumber >= view.start && weekNumber <= view.end
        });

        return currentView.viewType
    }


    showGeneralMessage(message: string) {
        this._snackBar.open(message, null, {
            panelClass: ['general-snackbar'],
            duration: 2000,
            verticalPosition: 'bottom',
            horizontalPosition: 'right'
        })
    }

    showGenericMessage(message: string, panelClass: string, duration: number,
        verticalPosition: MatSnackBarVerticalPosition = 'top', horizontalPosition: MatSnackBarHorizontalPosition = 'center') {
        this._snackBar.open(message, null, {
            panelClass: [panelClass],
            duration: 2000,
            verticalPosition: verticalPosition,
            horizontalPosition: horizontalPosition
        });
    }
    getPeriodWeeksfromWeekName(weekName: string, weeksWithYear: number[]) {
        let week = parseInt(weekName);
        let weekIndex = weeksWithYear.indexOf(week);

        let periodIndexes = this._getPeriodIndexesByWeekIndex(weekIndex);


        return [weeksWithYear[periodIndexes[0]], weeksWithYear[periodIndexes[1]], weeksWithYear[periodIndexes[2]], weeksWithYear[periodIndexes[3]]];
    }

    safeDivide(numerator: number, denominator: number, nullOnError: boolean = false): number {

        if (this.isNotNullOrUndefined(numerator) && this.isNotNullUndefinedOrZero(denominator)) {
            return numerator / denominator;
        }
        else {

            if (nullOnError) {
                return null;
            }
            return 0;
        }
    }

    // send positive number to shift forward, negative for backward
    shiftWeeks(fullWeekName: number, shiftBy: number = 0): number {
        if (shiftBy == 0) {
            return fullWeekName;
        }
        else if (shiftBy < 0) {
            shiftBy = Math.abs(shiftBy);
            for (let i = 0; i < shiftBy; i++) {
                fullWeekName -= 1;

                if (String(fullWeekName).slice(-2) == "00") {
                    let previousYear = Math.floor(fullWeekName / 100) - 1;
                    fullWeekName = Number(previousYear.toString() + "52");
                }
            }

            return fullWeekName;
        }
        else if (shiftBy > 0) {
            shiftBy = Math.abs(shiftBy);
            for (let i = 0; i < shiftBy; i++) {
                fullWeekName += 1;

                if (String(fullWeekName).slice(-2) == "53") {
                    let nextYear = Math.floor(fullWeekName / 100) + 1;
                    fullWeekName = Number(nextYear.toString() + "01");
                }
            }

            return fullWeekName;
        }
    }


    adjustScreenDimensions() {

        setTimeout(() => {
            let homepageSection = document.getElementById("selection-page-main");
            let planningViewPanes = document.querySelectorAll('.planning-view-main-pane');

            let totalScreenHeight = window.innerHeight;
            let navSectionHeight = document.getElementsByClassName("mat-toolbar-single-row")[0].clientHeight;

            if (document.getElementsByClassName("nav-marquee") != null && document.getElementsByClassName("nav-marquee") != undefined && document.getElementsByClassName("nav-marquee").length != 0) {
                navSectionHeight += document.getElementsByClassName("nav-marquee")[0].clientHeight;
            }

            let toolbarHeight = 0;

            if (document.getElementsByClassName("mat-toolbar-row") != null && document.getElementsByClassName("mat-toolbar-row") != undefined && document.getElementsByClassName("mat-toolbar-row").length != 0) {
                toolbarHeight = document.getElementsByClassName("mat-toolbar-row")[0].clientHeight;
            }

            let tabsHeight = 0;

            if (document.getElementsByClassName("nav-tabs") != null && document.getElementsByClassName("nav-tabs") != undefined && document.getElementsByClassName("nav-tabs").length != 0) {
                tabsHeight = document.getElementsByClassName("nav-tabs")[0].clientHeight;
            }


            if (homepageSection != null && homepageSection != undefined) {
                homepageSection.style.height = (totalScreenHeight - navSectionHeight).toString() + "px";
            }

            if (planningViewPanes != undefined && planningViewPanes != null && planningViewPanes.length != 0) {
                planningViewPanes.forEach((pane: any) => {
                    pane.style.height = (totalScreenHeight - navSectionHeight - toolbarHeight - tabsHeight).toString() + "px";
                })
            }
        }, 1);

    }

    getDefaultRetrievalMode(season: SeasonInfo, isAssortmentCopy: Boolean): RetrievalMode {

        if (isAssortmentCopy) {
            return this.getAssortmentDefaultRetrievalMode(season)
        }

        return this.getSalesDefaultRetrievalMode(season);
    }
    
    getAssortmentDefaultRetrievalMode(season: SeasonInfo): RetrievalMode {
        // check if retrieval mode is saved in the userconfig

        let userConfig = this._userConfigService.getUserConfig();

        if (userConfig && userConfig.planningViewLayoutSettings && userConfig.planningViewLayoutSettings.seasonSettings) {
            let seasonSetting = userConfig.planningViewLayoutSettings.seasonSettings.find((seasonSetting: SeasonSetting) => seasonSetting.seasonPlanningType == season.seasonPlanningType);

            if (this.isNotNullOrUndefined(seasonSetting)) {
                let validRetrievalModes = this.getAssortmentValidRetrievalModes(season);

                if (validRetrievalModes.indexOf(seasonSetting.retrievalMode) != -1) {
                    return seasonSetting.retrievalMode;
                }
            }
        }
        return this.getAssortmentDefaultRetrievalModeBySeasonPlanningType(season.seasonPlanningType);

    }

    getSalesDefaultRetrievalMode(season: SeasonInfo): RetrievalMode {
        // check if retrieval mode is saved in the userconfig

        let userConfig = this._userConfigService.getUserConfig();

        if (userConfig && userConfig.planningViewLayoutSettings && userConfig.planningViewLayoutSettings.seasonSettings) {
            let seasonSetting = userConfig.planningViewLayoutSettings.seasonSettings.find((seasonSetting: SeasonSetting) => seasonSetting.seasonPlanningType == season.seasonPlanningType);

            if (this.isNotNullOrUndefined(seasonSetting)) {
                let validRetrievalModes = this.getSalesValidRetrievalModes(season);

                if (validRetrievalModes.indexOf(seasonSetting.retrievalMode) != -1) {
                    return seasonSetting.retrievalMode;
                }
            }
        }
        return this.getSalesDefaultRetrievalModeBySeasonPlanningType(season.seasonPlanningType);

    }
    
    getValidRetrievalModes(season: SeasonInfo, isAssortmentCopy: boolean): RetrievalMode[] {
    
        if(isAssortmentCopy)
            return this.getAssortmentValidRetrievalModes(season);
        return this.getSalesValidRetrievalModes(season);
    }

    getAssortmentValidRetrievalModes(season: SeasonInfo): RetrievalMode[] {

        let availableRetrievalModes: RetrievalMode[] = []
        if (season.seasonPlanningType == SeasonPlanningType.PreviousWithOld || season.seasonPlanningType == SeasonPlanningType.Previous) {
            availableRetrievalModes = [RetrievalMode.Bought];
        }

        if (season.seasonPlanningType == SeasonPlanningType.Actual) {
            availableRetrievalModes = [RetrievalMode.Bought];
        }

        if (season.seasonPlanningType == SeasonPlanningType.Coming) {
            availableRetrievalModes = [RetrievalMode.Plan, RetrievalMode.Bought];
        }

        if (season.seasonPlanningType == SeasonPlanningType.Future) {
            availableRetrievalModes = [RetrievalMode.Initial, RetrievalMode.Plan, RetrievalMode.Bought];
        }

        return availableRetrievalModes;
    }

    getSalesValidRetrievalModes(season: SeasonInfo): RetrievalMode[] {

        let availableRetrievalModes: RetrievalMode[] = []
        if (season.seasonPlanningType == SeasonPlanningType.PreviousWithOld || season.seasonPlanningType == SeasonPlanningType.Previous) {
            availableRetrievalModes = [RetrievalMode.Bought];
        }

        if (season.seasonPlanningType == SeasonPlanningType.Actual) {
            availableRetrievalModes = [RetrievalMode.Bought];
        }

        if (season.seasonPlanningType == SeasonPlanningType.Coming) {
            availableRetrievalModes = [RetrievalMode.Initial, RetrievalMode.Plan, RetrievalMode.Bought];
        }

        if (season.seasonPlanningType == SeasonPlanningType.Future) {
            availableRetrievalModes = [RetrievalMode.Initial, RetrievalMode.Plan, RetrievalMode.Bought];
        }

        return availableRetrievalModes;
    }

    getDefaultRetrievalModeBySeasonPlanningType(seasonPlanningType: SeasonPlanningType, isAssortmentCopy: Boolean): RetrievalMode {

        if (isAssortmentCopy) {
            return this.getAssortmentDefaultRetrievalModeBySeasonPlanningType(seasonPlanningType)
        }

        return this.getSalesDefaultRetrievalModeBySeasonPlanningType(seasonPlanningType);
    }


    getAssortmentDefaultRetrievalModeBySeasonPlanningType(seasonPlanningType: SeasonPlanningType): RetrievalMode {

        if (
            seasonPlanningType == SeasonPlanningType.PreviousWithOld
            ||
            seasonPlanningType == SeasonPlanningType.PreviousWithOldChild
            ||
            seasonPlanningType == SeasonPlanningType.Previous
        ) {
            return RetrievalMode.Bought;
        }

        if (seasonPlanningType == SeasonPlanningType.Actual) {
            return RetrievalMode.Bought;

        }

        if (seasonPlanningType == SeasonPlanningType.Coming) {
            return RetrievalMode.Plan;
        }

        if (seasonPlanningType == SeasonPlanningType.Future) {
            return RetrievalMode.Initial;
        }
    }

    getSalesDefaultRetrievalModeBySeasonPlanningType(seasonPlanningType: SeasonPlanningType, totalViewDefaultRetrievalmode: string = ""): RetrievalMode {
        if (totalViewDefaultRetrievalmode) {
            let retrievalModes = totalViewDefaultRetrievalmode;
            let seasonTypeWithRetrievalMode = retrievalModes.split(":");
            let seasonTypeWithRetrievalModeDictionary = new Map<string, string>();
            seasonTypeWithRetrievalMode.forEach((item) => {
                seasonTypeWithRetrievalModeDictionary[item.split("_")[0]] = item.split("_")[1];
            });
            if (seasonPlanningType.toString().includes("Previous")) {
                return RetrievalMode[(seasonTypeWithRetrievalModeDictionary["Previous"])];
            }
            else {
                return RetrievalMode[(seasonTypeWithRetrievalModeDictionary[seasonPlanningType.toString()])];
            }
        } else {
            if (
                seasonPlanningType == SeasonPlanningType.PreviousWithOld
                ||
                seasonPlanningType == SeasonPlanningType.PreviousWithOldChild
                ||
                seasonPlanningType == SeasonPlanningType.Previous
            ) {
                return RetrievalMode.Bought;
            }

            if (seasonPlanningType == SeasonPlanningType.Actual) {
                return RetrievalMode.Bought;

            }

            if (seasonPlanningType == SeasonPlanningType.Coming) {
                let timelineView = this.getSeasonViewType(new Date()); 
                if(timelineView == SeasonViewType.View1 || timelineView == SeasonViewType.View3)
                    return RetrievalMode.Plan;
                return RetrievalMode.Initial;
            }

            if (seasonPlanningType == SeasonPlanningType.Future) {
                return RetrievalMode.Initial;
            }
        }
    }

    // add view date for the date picker to work 
    generateRandomIdentifier(length: number): string {
        var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghiklmnopqrstuvwxyz'.split('');
        var str = '';
        for (var i = 0; i < length; i++) {
            str += chars[Math.floor(Math.random() * chars.length)];
        }
        return str;

    }

    replaceFieldInSeasonWeekDataWithoutValueWithRelatedFieldValue(seasonsData: DepartmentCalculationDataItem[][],
         fieldToBeReplaced: CalculationDataItemType,
          relatedfieldInPriorityOrder: CalculationDataItemType[], weekIndex: number):void {
            let indexOfSeasonWithoutDemDelValues = [];
            seasonsData.forEach((ps,seasonIndex) => {
                if(!this.isNotNullOrUndefined(ps[weekIndex][fieldToBeReplaced])){
                    indexOfSeasonWithoutDemDelValues.push(seasonIndex);
                }
            });
            if(indexOfSeasonWithoutDemDelValues.length > 0 && indexOfSeasonWithoutDemDelValues.length< seasonsData.length){
                indexOfSeasonWithoutDemDelValues.forEach(seasonIndex => {
                   let relatedFieldWithValue = relatedfieldInPriorityOrder.find(rf => this.isNotNullOrUndefined(seasonsData[seasonIndex][weekIndex][rf]));
                    seasonsData[seasonIndex][weekIndex][fieldToBeReplaced] = seasonsData[seasonIndex][weekIndex][relatedFieldWithValue];
                });
            }
    }
}
