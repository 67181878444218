import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ImpersonateUserComponent } from './components/impersonate-user/impersonate-user.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserSettingsComponent } from './components/user-settings/user-settings.component';
import { UserSettingsRoutingModule } from './user-settings-routing.module';

@NgModule({
    imports: [
        SharedModule,
        UserSettingsRoutingModule,
    ],
    declarations: [
        ImpersonateUserComponent,
        UserDetailsComponent,
        UserSettingsComponent
    ],
    exports: [        
    ],
    providers:[
    ]
})
export class UserSettingsModule { }
