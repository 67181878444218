import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { CalculatorInterface } from "../../calculators/calculator-interface";
import { CalculatorTimeSpan } from "../../calculators/calculator-timespan";
import { CombinedNetSalesCalculator } from "../../calculators/others/combined-net-sales-calculator";
import { ParentCalculatorDriverInterface } from "../../calculators/parent-calculator-driver-interface";
import { CombinedRAndDCalculator } from "../../calculators/r-and-d-calculators/combined-r-and-d-calculator";
import { EffectiveRAndDPlanCalculator } from "../../calculators/r-and-d-calculators/effective-r-and-d-plan-calculator";
import { RAndDForecastCalculator } from "../../calculators/r-and-d-calculators/r-and-d-forecast-calculator";
import { RAndDForecastPercentCalculator } from "../../calculators/r-and-d-calculators/r-and-d-forecast-percent-calculator";
import { RAndDPlanCalculator } from "../../calculators/r-and-d-calculators/r-and-d-plan-calculator";
import { NetTaktCalculator } from "../../calculators/takt-calculators/net-takt-calculator";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";

export class RAndDForecastPercentEvent extends DepartmentEvent {


    validateEvent(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface): boolean {
        let dataSet = driver.getDataSet();

        let isGrossSalesGroundNotAvailable = (dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
            dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
            dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
            dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0);

        let isGrossSales2GroundNotAvailable = (
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.GrossSales2Ground] == 0 &&
            dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.GrossSales2Ground] == 0 &&
            dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.GrossSales2Ground] == 0 &&
            dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.GrossSales2Ground] == 0
        );


        if ((dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.InputRAndDForecastPeriodic] != null && (isGrossSalesGroundNotAvailable && isGrossSales2GroundNotAvailable)) ||
            (dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                dataSet[this.eventInfo.weekIndexes[1]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                dataSet[this.eventInfo.weekIndexes[2]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                dataSet[this.eventInfo.weekIndexes[3]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0)
        ) {
            let dialogBoxService = driver.getDialogBoxService();
            dialogBoxService.showMessage("You can not set R&D forecast when sales plan is 0.");
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.InputRAndDForecastPeriodic] = null;
            driver.setDataSet(dataSet);
            return false;
        }
        else {
            return true;
        }
    }

    getEventType(): DepartmentEventType {

        return DepartmentEventType.RAndDForecastPercent

    }

    applyEvent(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface) {

        let dataSet = driver.getDataSet();
        this.eventInfo.weekIndexes.forEach((weekIndex: number) => {
            dataSet[weekIndex][CalculationDataItemType.InputRAndDForecastPeriodic] = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
        });

        driver.setDataSet(dataSet);
    }

    configureCalculators(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface) {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // R&D Plan - Weekly
        pass1Calculators.push(new RAndDPlanCalculator(CalculatorTimeSpan.Weekly, driver))

        // Effective R&D Plan - Weekly
        pass1Calculators.push(new EffectiveRAndDPlanCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // R&D Forecast Percent - Periodic
        pass2Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Periodic, driver))
        // R&D Forecast Percent - Weekly
        pass2Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Weekly, driver))

        calculatorConfiguration.push(pass2Calculators);

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];

        // R&D Forecast - Weekly
        pass3Calculators.push(new RAndDForecastCalculator(CalculatorTimeSpan.Weekly, driver))

        calculatorConfiguration.push(pass3Calculators);

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];
        // Combined RAndD Calculator - Weekly
        pass4Calculators.push(new CombinedRAndDCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass4Calculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];

        // Combined Net Sales - weekly
        pass5Calculators.push(new CombinedNetSalesCalculator(CalculatorTimeSpan.Weekly, driver))
        // load pass calculators
        calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];
        // Net takt - Periodic
        pass6Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, driver))

        // load pass calculators
        calculatorConfiguration.push(pass6Calculators);

        driver.setCalculatorConfiguration(calculatorConfiguration);
    }
}
