import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { BoughtOfPlanCalculator } from "../../calculators/buying/bought-of-plan-calculator";
import { BuyingCalculator } from "../../calculators/buying/buying-calculator";
import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { CalculatorInterface } from "../../calculators/calculator-interface";
import { CalculatorTimeSpan } from "../../calculators/calculator-timespan";
import { PlannedCoverageCalculator } from "../../calculators/coverage/planned-coverage-calculator";
import { CSPercentCalculator } from "../../calculators/cs-percent/cs-percent-calculator";
import { SPercentCalculator } from "../../calculators/s-percent-calculators/s-percent-calculator";
import { OutgoingStockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from "../../calculators/stock-prognosis-calculators/stock-forecast-calculator";
import { StockPlanFixedCalculator } from "../../calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { StockPrognosisCalculator } from "../../calculators/stock-prognosis-calculators/stock-prognosis-calculator";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";

export class AddRemoveEvent extends DepartmentEvent {
    addRemoveOrMovePattern: string = "^([0-9]{0,3}|\\-\\b[0-9]{0,3})([.][0-9]{0,3})?$";

    getEventType(): DepartmentEventType {

        return DepartmentEventType.AddRemoveMsek
    }

    validateEvent(driver: CalculatorDriverInterface): boolean {
        var regEx = new RegExp(this.addRemoveOrMovePattern, "g");
        if (regEx.test(this.eventInfo.newValue.toString())) {
            return true;

        }
        else {
            let dataSet = driver.getDataSet();
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.InputAddRemoveMSekWeekly] = null;
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.AddRemoveMSek] = null;

            driver.setDataSet(dataSet);
            return false;
        }
    }

    applyEvent(driver: CalculatorDriverInterface) {
        let dataSet = driver.getDataSet();

        this.eventInfo.weekIndexes.forEach((weekIndex: number) => {
            dataSet[weekIndex][CalculationDataItemType.AddRemoveMSek] = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
            dataSet[weekIndex][CalculationDataItemType.InputAddRemoveMSekWeekly] = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
        });

        driver.setDataSet(dataSet);
    }

    configureCalculators(driver: CalculatorDriverInterface) {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Stock Plan Fixed - Weekly
        pass1Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, driver));

        // Stock Forecast - Weekly
        pass1Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, driver));

        // Outgoing Stock Prognosis - Weekly
        pass1Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // Stock Prognosis - Weekly
        pass2Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, driver))



        //Coverage Calculator
        pass2Calculators.push(new PlannedCoverageCalculator(CalculatorTimeSpan.Weekly, driver))

        // S Percent- Weekly
        pass2Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass2Calculators);

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];
        // S Percent- Period
        pass3Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, driver))

        // Stock Prognosis - Periodic
        pass3Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, driver))
        pass3Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, driver))

        // CS Percent - Seasonal
        pass3Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, driver))
        // load pass calculators
        calculatorConfiguration.push(pass3Calculators);

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];
        // buying- weekly
        pass4Calculators.push(new BuyingCalculator(CalculatorTimeSpan.Weekly, driver))

        // load pass calculators
        calculatorConfiguration.push(pass4Calculators);

        driver.setCalculatorConfiguration(calculatorConfiguration);
    }
}