import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class CombinedNetSalesCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Combined Sales Weekly
    // Combined RAnd Weekly



    // Calculates
    // ----------
    // Combined Net Sales Weekly

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.NetSales])) {
            value = currentWeekItem[CalculationDataItemType.NetSales];
        }
        else {
            
            value = currentWeekItem.combinedSalesPlanWeekly - currentWeekItem.combinedRAndDWeekly;
        }
        currentWeekItem[CalculationDataItemType.CombinedNetSalesWeekly] = value
    }
}