import { Component, Input, OnInit } from '@angular/core';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { ParentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/parent-calculator-driver-interface';
import { ReadonlyDepartmentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/readonly-department-calculator-driver-interface';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import * as _ from 'lodash';
import { OrganizationSummaryViewService } from '../../services/organization-summary-view-service';
import { SelectedOptions } from 'src/app/modules/shared/types/selected-options';
import { OrganizationSummaryViewDataResponse, OrganizationSummaryViewWeekDataItem } from '../../types/api/organization-summary-view-data/organization-summary-view-data-response';
import { TreeNode } from '../../types/tree-node';
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { CalculationDataItemType } from '../../types/calculation-data-item-type';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { DepartmentCalculationDataItem } from '../../types/department-calculation-data-item';
import { ParentCalculationDataItem } from '../../types/parent-calculation-data-item';
import { SimulationViewSeasonMonetaryValues } from '../../types/simulation-view-season-monetary-values';
import { Subscription } from 'rxjs/internal/Subscription';
import { SeasonSetting } from 'src/app/modules/shared/types/user-config';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { FeatureFlagService } from 'src/app/modules/shared/services/feature-flag.service';
import { Constants } from 'src/app/modules/shared/types/constants';

interface TotalPlanRecord {
  name: string;
  grossTakt: number;
  netTakt: number;
  sobPercent?: number;
  sobPercentGround?: number;
  stockGrossIngoingIndex: number;
  stockGrossOutgoingIndex: number;
  avgSPercent?: number;
  avgSPercentGround?: number;
  redTsek: number;
  orderedIndex: number,
  returns: number,
  typeId: number,
  typeName: string,
  stockPerSalesNet?: number,
}

interface OrganizationMonetaryRecord {
  name: string;
  salesGross: number;
  salesGrossGround: number;
  salesNet: number;
  salesNetGround: number;
  stockGrossIngoing: number;
  stockGrossIngoingGround: number;
  stockGrossOutgoing: number;
  stockGrossOutgoingGround: number;
  outgoingStockPrognosis: number;
  stockGrossGround: number;
  reductionAndDiscount: number;
  buyingWeekly: number,
  returns: number,
  typeId: number,
  typeName: string,
  orderGrossGround: number,
}

@Component({
  selector: 'app-organization-summary-view',
  templateUrl: './organization-summary-view.component.html',
  styleUrls: ['./organization-summary-view.component.css']
})
export class OrganizationSummaryViewComponent implements OnInit {

  @Input() PreviousSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() ActualSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() ComingSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() FutureSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;

  @Input() selectionOptions: SelectedOptions;
  @Input() childNodes: TreeNode[];
  @Input() activeNode: TreeNode;
  @Input() organizationSummaryData: OrganizationSummaryViewDataResponse[];

  selectedStartWeek: number = null;
  selectedEndWeek: number = null;

  customSelectStartWeek: number = null;
  customSelectEndWeek: number = null;

  fullWeeksList: number[];
  startweeksList: number[];
  endweeksList: number[];

  math = Math;
  seasonInfo: SeasonInfo;

  totalPlanViewData: TotalPlanRecord[] = null;
  organizationMonetaryData: OrganizationMonetaryRecord[] = null;
  totalPlanViewDataResponse: OrganizationSummaryViewDataResponse[] = null;
  isTotalLevelEnabled: boolean = false;
  isOnline = false;
  isNotStore: boolean = false;

  totalPlanViewLoading: boolean = false;

  totalSelectedRetrievalModeText: string = "";
  userConfigSubjectSubscription: Subscription;

  fullRangeWeeksList: number[] = [];

  seasonSettingPreviousState: SeasonSetting[] = [];
  isExpandMonetaryButton = false;

  constructor(private totalPlanService: OrganizationSummaryViewService, 
    private utilsService: UtilsService, 
    private userConfigService: UserConfigService) {
    this.isTotalLevelEnabled = userConfigService.getUserConfig().planningViewOptions.activeNode.typeName == "Total";
  }

  validateCustomStartWeek(event: any) {
    this.customSelectStartWeek =  event?.target?.value? Number.parseInt( event.target.value): this.customSelectStartWeek;
    if (this.startweeksList.indexOf(this.customSelectStartWeek) != -1) {
      this.selectedStartWeek = this.customSelectStartWeek;
      this.weekSelectionChanged();
    }
    else {
      this.customSelectStartWeek = this.selectedStartWeek;
    }
  }

  validateCustomEndWeek() {
    if (this.endweeksList.indexOf(this.customSelectEndWeek) != -1) {
      this.selectedEndWeek = this.customSelectEndWeek;
      this.weekSelectionChanged();
    }
    else {
      this.customSelectEndWeek = this.selectedEndWeek;
    }
  }

  showOrHideMonetaryValues(showOrHideMonetaryValues: boolean){
    this.isExpandMonetaryButton = showOrHideMonetaryValues;
  }
  ngOnInit(): void {

    this.seasonInfo = this.ActualSeasonDriver.getSeasonInfo();

    this.fullWeeksList = this.seasonInfo.weeksWithYear;

    this.startweeksList = this.fullWeeksList.slice(0, this.seasonInfo.weeksWithYear.length - 1);

    this.updateViewEndWeeks();

    this.selectedStartWeek = this.fullWeeksList[0];
    this.selectedEndWeek = this.fullWeeksList[3];

    this.isOnline = this.selectionOptions.planningViewOptions.channel == ChannelType.Online;

    this.isNotStore = this.selectionOptions.planningViewOptions.channel != ChannelType.Store;

    if (this.isTotalLevelEnabled)
      this.fetchData();

    this.presetCustomWeekSelections();

    this.userConfigSubjectSubscription = this.userConfigService.userConfigSubject.subscribe(userConfig => {     
      let selectedStructureType = userConfig?.planningViewOptions?.structureType;
      let isTotalStructureTypeSelected = selectedStructureType != null && selectedStructureType == StructureTypes.Total;
      if(userConfig) {
        if (isTotalStructureTypeSelected && userConfig?.planningViewLayoutSettings?.totalSeasonSettings) {
          this.setSelectedRetrivelModeText(userConfig.planningViewLayoutSettings.totalSeasonSettings);
        }
        else if(userConfig?.planningViewLayoutSettings?.seasonSettings) {
          this.setSelectedRetrivelModeText(userConfig.planningViewLayoutSettings.seasonSettings);
        }
      }
    });
  }

  private setSelectedRetrivelModeText(seasonSettings: SeasonSetting[]) {
    this.totalSelectedRetrievalModeText = "Selected retrieval modes are ";
    
    seasonSettings.forEach(seasonSetting => {
      if (this.seasonSettingPreviousState.length > 0 && seasonSetting.retrievalMode != this.seasonSettingPreviousState.find(s => s.seasonPlanningType === seasonSetting.seasonPlanningType)?.retrievalMode) {
        this.totalPlanViewData = [];
        this.organizationMonetaryData = [];
      }
      if (seasonSetting.seasonPlanningType == SeasonPlanningType.PreviousWithOld || seasonSetting.seasonPlanningType == SeasonPlanningType.Previous) {
        this.totalSelectedRetrievalModeText += 'Previous&Old' + ':' + seasonSetting.retrievalMode + ', ';
      }
      else {
        this.totalSelectedRetrievalModeText += seasonSetting.seasonPlanningType + ':' + seasonSetting.retrievalMode + ', ';
      }
    });

    this.totalSelectedRetrievalModeText = this.totalSelectedRetrievalModeText.slice(0, -2);
    this.seasonSettingPreviousState = seasonSettings;
  };

  async fetchData(isRefreshButtonClicked = false) {
    this.totalPlanViewLoading = true;
    if (this.isTotalLevelEnabled && !isRefreshButtonClicked) {
      this.handleData(this.organizationSummaryData);
    } else {
      this.totalPlanService.getTotalPlanViewData(this.selectionOptions, this.childNodes).subscribe(data => {
        this.handleData(data);
      });
    }
  }

  handleData(data) {
    this.totalPlanViewDataResponse = data;
    this.totalPlanViewDataResponse[0].totalPlanViewWeekData.forEach(totalViewDataItem => {
      this.fullRangeWeeksList.push(totalViewDataItem.weekName)
    });


    this.processData();
    this.totalPlanViewLoading = false;
  }

  processData() {
    if (this.totalPlanViewDataResponse) {
      this.totalPlanViewData = [];
      this.organizationMonetaryData = [];
      this.childNodes.forEach((node: TreeNode) => {
        let childMonetaryRecord: OrganizationMonetaryRecord = {
          typeId: node.typeId,
          typeName: node.typeName,
          name: node.name,
          salesGross: this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek),
          salesGrossGround : this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "salesGrossGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          salesNet: this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "combinedSalesNetWeekly", this.selectedStartWeek, this.selectedEndWeek),
          salesNetGround : this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "netSalesGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          stockGrossIngoing: this.getStockGrossIngoing(node.typeId,node.typeName),
          stockGrossIngoingGround: this.getStockGrossIngoingGround(node.typeId,node.typeName),
          stockGrossOutgoing: this.getStockGrossOutgoing(node.typeId,node.typeName),
          stockGrossOutgoingGround: this.getStockGrossOutgoingGround(node.typeId,node.typeName),
          outgoingStockPrognosis : this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "outgoingStockPrognosisWeekly", this.selectedStartWeek, this.selectedEndWeek),
          stockGrossGround: this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "stockGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          buyingWeekly: this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "buyingWeekly", this.utilsService.shiftWeeks(this.selectedStartWeek, -4), this.utilsService.shiftWeeks(this.selectedEndWeek, -4)),
          orderGrossGround: this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "orderedGrossGround", this.utilsService.shiftWeeks(this.selectedStartWeek, -4), this.utilsService.shiftWeeks(this.selectedEndWeek, -4)),
          reductionAndDiscount: this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "combinedRandDWeekly", this.selectedStartWeek, this.selectedEndWeek),
          returns: this.selectionOptions.planningViewOptions.channel == ChannelType.Online ? this.reduceDataForDepartmentForAllSeasons(node.typeId, node.typeName, "returns", this.selectedStartWeek, this.selectedEndWeek) : 0
        };
        this.organizationMonetaryData.push(childMonetaryRecord);
        
        let childRecord: TotalPlanRecord = {
          typeId: node.typeId,
          typeName: node.typeName,
          name: node.name,
          grossTakt: this.utilsService.safeDivide(childMonetaryRecord.salesGross, childMonetaryRecord.salesGrossGround) * 100,
          netTakt: this.utilsService.safeDivide(childMonetaryRecord.salesNet, childMonetaryRecord.salesNetGround) * 100,
          orderedIndex: this.utilsService.safeDivide(childMonetaryRecord.buyingWeekly, childMonetaryRecord.orderGrossGround) * 100,
          redTsek: this.utilsService.safeDivide(childMonetaryRecord.reductionAndDiscount, childMonetaryRecord.salesGross) * 100,
          returns: this.selectionOptions.planningViewOptions.channel == ChannelType.Online ? this.calculateReturns(node.typeId, node.typeName) : 0,
          stockGrossIngoingIndex: this.utilsService.safeDivide(childMonetaryRecord.stockGrossIngoing, childMonetaryRecord.stockGrossIngoingGround) * 100,
          stockGrossOutgoingIndex: this.utilsService.safeDivide(childMonetaryRecord.stockGrossOutgoing, childMonetaryRecord.stockGrossOutgoingGround) * 100,
          avgSPercent: this.utilsService.safeDivide(childMonetaryRecord.salesGross, childMonetaryRecord.salesGross + childMonetaryRecord.outgoingStockPrognosis) * 100,
          avgSPercentGround: this.utilsService.safeDivide(childMonetaryRecord.salesGrossGround, childMonetaryRecord.salesGrossGround + childMonetaryRecord.stockGrossGround) * 100,
          stockPerSalesNet: this.calculateStockPerSalesNet(node.typeId, node.typeName)
        }

        this.totalPlanViewData.push(childRecord);
      });

      this.totalPlanViewData.forEach((childRecord: TotalPlanRecord) => {
        childRecord.sobPercent = this.utilsService.safeDivide(
          this.reduceDataForDepartmentForAllSeasons(childRecord.typeId, childRecord.typeName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek),
          this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek)
        ) * 100,
          childRecord.sobPercentGround = this.utilsService.safeDivide(
            this.reduceDataForDepartmentForAllSeasons(childRecord.typeId, childRecord.typeName, "salesGrossGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
            this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "salesGrossGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          ) * 100
      });

      this.organizationMonetaryData.unshift({
        typeId: this.activeNode.typeId,
          typeName: this.activeNode.typeName,
          name: this.activeNode.name,
          salesGross: this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek),
          salesGrossGround : this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "salesGrossGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          salesNet: this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "combinedSalesNetWeekly", this.selectedStartWeek, this.selectedEndWeek),
          salesNetGround : this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "netSalesGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          stockGrossIngoing: this.getStockGrossIngoing(this.activeNode.typeId,this.activeNode.typeName),
          stockGrossIngoingGround: this.getStockGrossIngoingGround(this.activeNode.typeId,this.activeNode.typeName),
          stockGrossOutgoing: this.getStockGrossOutgoing(this.activeNode.typeId,this.activeNode.typeName),
          stockGrossOutgoingGround: this.getStockGrossOutgoingGround(this.activeNode.typeId,this.activeNode.typeName),
          outgoingStockPrognosis : this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "outgoingStockPrognosisWeekly", this.selectedStartWeek, this.selectedEndWeek),
          stockGrossGround: this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "stockGroundWeekly", this.selectedStartWeek, this.selectedEndWeek),
          buyingWeekly: this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "buyingWeekly", this.utilsService.shiftWeeks(this.selectedStartWeek, -4), this.utilsService.shiftWeeks(this.selectedEndWeek, -4)),
          orderGrossGround: this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "orderedGrossGround", this.utilsService.shiftWeeks(this.selectedStartWeek, -4), this.utilsService.shiftWeeks(this.selectedEndWeek, -4)),
          reductionAndDiscount: this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "combinedRandDWeekly", this.selectedStartWeek, this.selectedEndWeek),
          returns: this.selectionOptions.planningViewOptions.channel == ChannelType.Online ? this.reduceDataForDepartmentForAllSeasons(this.activeNode.typeId, this.activeNode.typeName, "returns", this.selectedStartWeek, this.selectedEndWeek) : 0
        
      });

      this.totalPlanViewData.unshift({
        typeId: this.activeNode.typeId,
        typeName: this.activeNode.typeName,
        name: this.activeNode.name,
        grossTakt: this.calculateGrossTakt(this.activeNode.typeId, this.activeNode.typeName) * 100,
        netTakt: this.calculateNetTakt(this.activeNode.typeId, this.activeNode.typeName) * 100,
        orderedIndex: this.calculateOrderedIndex(this.activeNode.typeId, this.activeNode.typeName),
        redTsek: this.calculateReductions(this.activeNode.typeId, this.activeNode.typeName),
        returns: this.selectionOptions.planningViewOptions.channel == ChannelType.Online ? this.calculateReturns(this.activeNode.typeId, this.activeNode.typeName) : 0,
        stockGrossIngoingIndex: this.calculateStockGrossIndexForTotal(true),
        stockGrossOutgoingIndex: this.calculateStockGrossIndexForTotal(false),
        sobPercent: 100,
        sobPercentGround: 100,
        avgSPercent: this.calculateAvgSPercentTotal(),
        avgSPercentGround: this.calculateAvgSPercentGroundTotal(),
        stockPerSalesNet: this.calculateStockPerSalesNet(this.activeNode.typeId, this.activeNode.typeName)
      });
    }
  }

  updateViewEndWeeks() {
    let selectedStartWeekIndex = _.findIndex(this.fullWeeksList, w => w == this.selectedStartWeek);
    this.endweeksList = this.fullWeeksList.slice(selectedStartWeekIndex + 1, this.fullWeeksList.length);

    // check if end week lies within the new range
    if (this.endweeksList.indexOf(this.selectedEndWeek) == -1) {
      // if not, update it to start week + 3
      // check if start week + 4 is within range, if not set to last week
      if (this.fullWeeksList.length - this.fullWeeksList.indexOf(this.selectedStartWeek) > 3) {
        this.selectedEndWeek = this.fullWeeksList[selectedStartWeekIndex + 3];
      }
      else {
        this.selectedEndWeek = this.fullWeeksList[this.fullWeeksList.length - 1];
      }
    }
  }

  // whenever either start week or end week have changed 	
  weekSelectionChanged() {
    this.updateViewEndWeeks();
    this.processData();
  }

  // calculation logic
  reduceDataForDepartmentForAllSeasons(structureId: number, structureName: string, keyName: string, startWeek: number, endWeek: number): number {
    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];

    let value = 0;
    let currentWeek = this.ActualSeasonDriver.getViewWeekWithYear();

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.filter(x => x.structureType == structureName).forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && ((item.structureId == structureId
          && item.structureType == structureName) || structureName == "Total")) {
          // for each weekitem
          item.totalPlanViewWeekData.forEach((weekDataItem: OrganizationSummaryViewWeekDataItem) => {
            // if it lies within range
            if (
              weekDataItem.weekName >= startWeek
              &&
              weekDataItem.weekName <= endWeek) {
              if (keyName == "returns") {
                value += (weekDataItem.weekName < currentWeek) ? weekDataItem["returnGross"] : weekDataItem["plannedReturns"]
              }
              else if (keyName == "stockGroundWeekly") {
                value += this.utilsService.isNotNullUndefinedOrZero(weekDataItem.stockGroundWeekly) ?
                  weekDataItem.stockGroundWeekly : weekDataItem.stockPrognosisLy;
              }
              else {
                // add to result value
                value += weekDataItem[keyName];
              }
            }
          });
        }
      });
    });

    return value;
  }

  calculateGrossTakt(structureId: number, structureName: string): number {
    let salesGrossSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek);
    let salesGrossGroundSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "salesGrossGroundWeekly", this.selectedStartWeek, this.selectedEndWeek);

    return this.utilsService.safeDivide(salesGrossSumAcrossSeasons, salesGrossGroundSumAcrossSeasons);
  }
  calculateNetTakt(structureId: number, structureName: string): number {
    let salesNetSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesNetWeekly", this.selectedStartWeek, this.selectedEndWeek);
    let salesNetGroundSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "netSalesGroundWeekly", this.selectedStartWeek, this.selectedEndWeek);

    let netTakt = this.utilsService.safeDivide(salesNetSumAcrossSeasons, salesNetGroundSumAcrossSeasons);
    return this.utilsService.isNotNullUndefinedOrZero(netTakt) ? netTakt : 1;
  }

  getStockGrossIngoing(structureId: number,structureType: string): number {

    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];

    // Ingoing Stock IDX = (Stock Gross Ingoing TY / Stock Gross Ingoing Ground) * 100    
    // Stock Gross Ingoing TY = Outgoing Stock prognosis of the (start week - 1) of that season     
    // Stock Gross Ingoing Ground = Stock Ground of the (start week - 1) of that season 
    let stockGrossIngoingTy = 0;

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && item.structureId == structureId && item.structureType == structureType) {
          let startWeekMinus1Value = this.utilsService.shiftWeeks(this.selectedStartWeek, -1);
          let weekItem: OrganizationSummaryViewWeekDataItem = _.find(item.totalPlanViewWeekData, (i: OrganizationSummaryViewWeekDataItem) => {
            return i.weekName == startWeekMinus1Value;
          });

          if (this.utilsService.isNotNullOrUndefined(weekItem)) {
            stockGrossIngoingTy += weekItem.outgoingStockPrognosisWeekly;}
        }
      });
    });

    return stockGrossIngoingTy;
  }

  getStockGrossIngoingGround(structureId: number,structureType: string): number {

    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];

    // Ingoing Stock IDX = (Stock Gross Ingoing TY / Stock Gross Ingoing Ground) * 100    
    // Stock Gross Ingoing TY = Outgoing Stock prognosis of the (start week - 1) of that season     
    // Stock Gross Ingoing Ground = Stock Ground of the (start week - 1) of that season
    let stockGrossIngoingGround = 0;

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && item.structureId == structureId && item.structureType == structureType) {
          let startWeekMinus1Value = this.utilsService.shiftWeeks(this.selectedStartWeek, -1);
          let weekItem: OrganizationSummaryViewWeekDataItem = _.find(item.totalPlanViewWeekData, (i: OrganizationSummaryViewWeekDataItem) => {
            return i.weekName == startWeekMinus1Value;
          });

          if (this.utilsService.isNotNullOrUndefined(weekItem)) {
            stockGrossIngoingGround += this.utilsService.isNotNullUndefinedOrZero(weekItem.stockGroundWeekly) ? weekItem.stockGroundWeekly : weekItem.stockPrognosisLy;
          }
        }
      });
    });

    return stockGrossIngoingGround;
  }

  calculateStockGrossIndexForTotal(ingoingIndex: boolean): number {
    let selectedWeek = ingoingIndex ? this.utilsService.shiftWeeks(this.selectedStartWeek, -1) : this.selectedEndWeek;

    let drivers = [this.PreviousSeasonDriver, this.ActualSeasonDriver, this.ComingSeasonDriver, this.FutureSeasonDriver];

    let stockGrossTySumForAllSeasons = 0;
    let stockGrossGroundSumForAllSeasons = 0;

    drivers.forEach(driver => {
      let dataSet = driver.getDataSet();
      let dataItem = _.find(dataSet, x => x.weekName == selectedWeek);
      stockGrossTySumForAllSeasons += dataItem[CalculationDataItemType.EffectiveOutgoingStockPrognosis];

      stockGrossGroundSumForAllSeasons += this.utilsService.isNotNullUndefinedOrZero(dataItem[CalculationDataItemType.StockGround]) ? dataItem[CalculationDataItemType.StockGround] : dataItem[CalculationDataItemType.StockPrognosisLy];
    });

    return this.utilsService.safeDivide(stockGrossTySumForAllSeasons, stockGrossGroundSumForAllSeasons) * 100;
  }

  calculateAvgSPercentTotal(): number {
    let drivers = [this.PreviousSeasonDriver, this.ActualSeasonDriver, this.ComingSeasonDriver, this.FutureSeasonDriver];

    let combinedSalesPlanSumAcrossAllSeasons = 0;
    let accStockGrossAcrossAllSeasons = 0;

    drivers.forEach(driver => {
      let rangeDataSet = driver.getDataSetRange(this.selectedStartWeek, this.selectedEndWeek);

      combinedSalesPlanSumAcrossAllSeasons += this.reduceDataSet(rangeDataSet, CalculationDataItemType.CombinedSalesPlanWeekly);
      accStockGrossAcrossAllSeasons += this.reduceDataSet(rangeDataSet, CalculationDataItemType.EffectiveOutgoingStockPrognosis);

    });

    return this.utilsService.safeDivide(combinedSalesPlanSumAcrossAllSeasons, (combinedSalesPlanSumAcrossAllSeasons + accStockGrossAcrossAllSeasons)) * 100;
  }

  calculateAvgSPercentGroundTotal(): number {
    let drivers = [this.PreviousSeasonDriver, this.ActualSeasonDriver, this.ComingSeasonDriver, this.FutureSeasonDriver];

    let grossSalesGroundSumAcrossAllSeasons = 0;
    let accStockGrossGroundAcrossAllSeasons = 0;

    drivers.forEach(driver => {
      let rangeDataSet = driver.getDataSetRange(this.selectedStartWeek, this.selectedEndWeek);

      grossSalesGroundSumAcrossAllSeasons += this.reduceDataSet(rangeDataSet, CalculationDataItemType.GrossSalesGround);

      rangeDataSet.forEach(dataSetItem => {
        accStockGrossGroundAcrossAllSeasons += this.utilsService.isNotNullUndefinedOrZero(dataSetItem[CalculationDataItemType.StockGround]) ? dataSetItem[CalculationDataItemType.StockGround] : dataSetItem[CalculationDataItemType.StockPrognosisLy];
      });

    });

    return this.utilsService.safeDivide(grossSalesGroundSumAcrossAllSeasons, (grossSalesGroundSumAcrossAllSeasons + accStockGrossGroundAcrossAllSeasons)) * 100;
  }



  reduceDataSet(dataset: DepartmentCalculationDataItem[] | ParentCalculationDataItem[] | SimulationViewSeasonMonetaryValues[], keyName: string): number {
    let sumValue = 0;

    dataset.forEach(datasetItem => {
      sumValue += datasetItem[keyName];
    });

    return sumValue;

  }

  calculateStockGrossOutgoingIndex(structureId: number): number {

    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];

    // Outgoing Stock IDX = (Stock Gross Outgoing TY / Stock Gross Outgoing Ground) * 100  
    // Stock Gross Outgoing TY = Outgoing Stock prognosis of the (End week) of that season   
    // Stock Gross Outgoing Ground = Stock Ground of the (End Week) of that season 

    let stockGrossOutgoingTy = 0;
    let stockGrossOutgoingGround = 0;

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && item.structureId == structureId) {
          let weekItem: OrganizationSummaryViewWeekDataItem = _.find(item.totalPlanViewWeekData, (i: OrganizationSummaryViewWeekDataItem) => {
            return i.weekName == this.selectedEndWeek;
          });

          if (this.utilsService.isNotNullOrUndefined(weekItem)) {
            stockGrossOutgoingTy += weekItem.outgoingStockPrognosisWeekly;
            stockGrossOutgoingGround += this.utilsService.isNotNullUndefinedOrZero(weekItem.stockGroundWeekly) ? weekItem.stockGroundWeekly : weekItem.stockPrognosisLy;
          }
        }
      });
    });

    return this.utilsService.safeDivide(stockGrossOutgoingTy, stockGrossOutgoingGround) * 100;

  }

  getStockGrossOutgoing(structureId: number,structureType: string): number {

    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];

    // Outgoing Stock IDX = (Stock Gross Outgoing TY / Stock Gross Outgoing Ground) * 100  
    // Stock Gross Outgoing TY = Outgoing Stock prognosis of the (End week) of that season   
    // Stock Gross Outgoing Ground = Stock Ground of the (End Week) of that season 

    let stockGrossOutgoingTy = 0;

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && item.structureId == structureId && item.structureType == structureType) {
          let weekItem: OrganizationSummaryViewWeekDataItem = _.find(item.totalPlanViewWeekData, (i: OrganizationSummaryViewWeekDataItem) => {
            return i.weekName == this.selectedEndWeek;
          });

          if (this.utilsService.isNotNullOrUndefined(weekItem)) {
            stockGrossOutgoingTy += weekItem.outgoingStockPrognosisWeekly;
          }
        }
      });
    });

    return stockGrossOutgoingTy;

  }

  getStockGrossOutgoingGround(structureId: number,structureType: string): number {

    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];

    // Outgoing Stock IDX = (Stock Gross Outgoing TY / Stock Gross Outgoing Ground) * 100  
    // Stock Gross Outgoing TY = Outgoing Stock prognosis of the (End week) of that season   
    // Stock Gross Outgoing Ground = Stock Ground of the (End Week) of that season 

    let stockGrossOutgoingGround = 0;

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && item.structureId == structureId && item.structureType == structureType) {
          let weekItem: OrganizationSummaryViewWeekDataItem = _.find(item.totalPlanViewWeekData, (i: OrganizationSummaryViewWeekDataItem) => {
            return i.weekName == this.selectedEndWeek;
          });

          if (this.utilsService.isNotNullOrUndefined(weekItem)) {
            stockGrossOutgoingGround += this.utilsService.isNotNullUndefinedOrZero(weekItem.stockGroundWeekly) ? weekItem.stockGroundWeekly : weekItem.stockPrognosisLy;
          }
        }
      });
    });

    return stockGrossOutgoingGround;

  }

  calculateReductions(structureId: number, structureName: string): number {
    let reductionsSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedRandDWeekly", this.selectedStartWeek, this.selectedEndWeek);
    let salesGrossSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek);

    return this.utilsService.safeDivide(reductionsSumAcrossSeasons, salesGrossSumAcrossSeasons) * 100;
  }
  calculateOrderedIndex(structureId: number, structureName: string): number {
    let buyingWeeklySumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "buyingWeekly", this.utilsService.shiftWeeks(this.selectedStartWeek, -4), this.utilsService.shiftWeeks(this.selectedEndWeek, -4));
    let orderedGrossGroundSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "orderedGrossGround", this.utilsService.shiftWeeks(this.selectedStartWeek, -4), this.utilsService.shiftWeeks(this.selectedEndWeek, -4));

    return this.utilsService.safeDivide(buyingWeeklySumAcrossSeasons, orderedGrossGroundSumAcrossSeasons) * 100;
  }

  calculateAvgSPercent(structureId: number, structureName: string): number {
    let salesGrossSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek);
    let stockGrossTySumForAllSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "outgoingStockPrognosisWeekly", this.selectedStartWeek, this.selectedEndWeek);

    return this.utilsService.safeDivide(salesGrossSumAcrossSeasons, salesGrossSumAcrossSeasons + stockGrossTySumForAllSeasons) * 100;
  }

  calculateAvgSPercentGround(structureId: number, structureName: string): number {
    let salesGrossGroundSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "salesGrossGroundWeekly", this.selectedStartWeek, this.selectedEndWeek);
    let stockGrossGroundSumForAllSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "stockGroundWeekly", this.selectedStartWeek, this.selectedEndWeek);

    return this.utilsService.safeDivide(salesGrossGroundSumAcrossSeasons, salesGrossGroundSumAcrossSeasons + stockGrossGroundSumForAllSeasons) * 100;
  }



  calculateReturns(structureId: number, structureName: string): number {
    let returnsSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "returns", this.selectedStartWeek, this.selectedEndWeek);
    let salesGrossSumAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesPlanWeekly", this.selectedStartWeek, this.selectedEndWeek);

    return this.utilsService.safeDivide(returnsSumAcrossSeasons, salesGrossSumAcrossSeasons) * 100;
  }

  calculateStockPerSalesNet(structureId: number, structureName: string): number {

    let selectedEndWeekIndex = _.findIndex(this.fullRangeWeeksList, w => w == this.selectedEndWeek);
    let currentWeek = this.ActualSeasonDriver.getViewWeekWithYear();

    let previous51WeekIndex = 0;
    if (selectedEndWeekIndex > 51) {
      previous51WeekIndex = selectedEndWeekIndex - 51;
    }

    let selectedPrevious51Week = this.fullRangeWeeksList[previous51WeekIndex];
    let endWeekMinus1Value = this.utilsService.shiftWeeks(this.selectedEndWeek, -1);

    let salesNetSum = 0;

    // get 51 week's sales net data
    let combinedNetSalesAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesNetWeekly", selectedPrevious51Week, endWeekMinus1Value);

    salesNetSum += combinedNetSalesAcrossSeasons;

    if (this.isNotStore) {
      let returnsAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "returns", selectedPrevious51Week, endWeekMinus1Value);

      let grossSalesAcrossSeasons = this.reduceDataForDepartmentForAllSeasons(structureId, structureName, "combinedSalesPlanWeekly", selectedPrevious51Week, endWeekMinus1Value);

      salesNetSum -= (this.utilsService.safeDivide(combinedNetSalesAcrossSeasons, grossSalesAcrossSeasons)
        * returnsAcrossSeasons);
    }

    //get end week's data
    let seasons: SeasonPlanningType[] = [SeasonPlanningType.Actual, SeasonPlanningType.Coming, SeasonPlanningType.Future, SeasonPlanningType.PreviousWithOld, SeasonPlanningType.Previous];
    let outgoingStockPrognosisAcrossSeasons = 0;
    let endWeekCombinedNetSalesAcrossSeasons = 0;
    let endWeekGrossSalesAcrossSeasons = 0;
    let endWeekreturnsAcrossSeasons = 0;

    // across seasons
    seasons.forEach((season: SeasonPlanningType) => {
      // for each entry
      this.totalPlanViewDataResponse.filter(x => x.structureType == structureName).forEach((item: OrganizationSummaryViewDataResponse) => {
        // for the current seasons and given structure id
        if (item.seasonPlanningType == season && ((item.structureId == structureId
          && item.structureType == structureName) || structureName == "Total")) {
          let weekItem: OrganizationSummaryViewWeekDataItem = _.find(item.totalPlanViewWeekData, (i: OrganizationSummaryViewWeekDataItem) => {
            return i.weekName == this.selectedEndWeek;
          });

          if (this.utilsService.isNotNullOrUndefined(weekItem)) {
            outgoingStockPrognosisAcrossSeasons += weekItem.outgoingStockPrognosisWeekly;
            endWeekCombinedNetSalesAcrossSeasons += weekItem.combinedSalesNetWeekly;
          }

          if (this.isNotStore) {
            endWeekGrossSalesAcrossSeasons += weekItem.combinedSalesPlanWeekly
            endWeekreturnsAcrossSeasons += weekItem.weekName < currentWeek ?
              weekItem.returnGross : weekItem.plannedReturns;
          }
        }
      });
    });

    let endWeekCombinedReturnsSum = this.utilsService.safeDivide(endWeekCombinedNetSalesAcrossSeasons,
      endWeekGrossSalesAcrossSeasons) * endWeekreturnsAcrossSeasons;
    //Substracted end week returns net from combinedNETSales which was missing
    let value = this.utilsService.safeDivide(outgoingStockPrognosisAcrossSeasons,
      (endWeekCombinedNetSalesAcrossSeasons - endWeekCombinedReturnsSum + salesNetSum)) * 100;
    return value;

  }

  presetCustomWeekSelections() {
    this.customSelectStartWeek = this.selectedStartWeek;
    this.customSelectEndWeek = this.selectedEndWeek;
  }

  focusCustomStartWeek(event) {
    this.customSelectStartWeek = this.selectedStartWeek;

    if (event) {
      document.getElementById("txtCustomStartWeekSelection")?.focus();
    }
  }

  focusCustomEndWeek(event) {
    this.customSelectEndWeek = this.selectedEndWeek;
    if (event) {
      document.getElementById("txtCustomEndWeekSelection")?.focus();
    }
  }

}
