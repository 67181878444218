<h2>User Details</h2>
<hr>

<ng-container *ngIf="userInfo!=null">
  <p>Name : {{userInfo.firstName + " " + userInfo.lastName}}</p>
  <p>Email : {{userInfo.email}}</p>
  <p>Id : {{userInfo.userId}}</p>
  <p>Login : {{userInfo.userName}}</p>

  <br />

  <h3 class="section-heading">System Permissions</h3>


  <ng-container
    *ngIf="userInfo.systemPermissions != null && userInfo.systemPermissions.length != 0; then systemPermissionSection; else noSystemPermissionsSection">
  </ng-container>

  <ng-template #systemPermissionSection>
    <ul>
      <li *ngFor="let systemPermission of userInfo.systemPermissions">{{systemPermission.name}}</li>
    </ul>
  </ng-template>
  <ng-template #noSystemPermissionsSection>
    <p>No System Permissions Found</p>
  </ng-template>

  <h3 class="section-heading">GAM Permissions</h3>
  <ng-container
    *ngIf="userInfo.gamPermissions != null && userInfo.gamPermissions.length != 0; then gamPermissionSection; else noGamPermissionsSection">
  </ng-container>

  <ng-template #gamPermissionSection>
    <div class="gam-table-div">
      <table class="gam-table">

        <tr>
          <th>Type</th>
          <th>Id</th>
          <th>Name</th>
          <th>Explicit</th>
        </tr>

        <ng-container *ngFor="let gamPermission of userInfo.gamPermissions">
          <ng-container *ngFor="let permission of gamPermission.permissions">
            <tr>
              <td>{{gamPermission.typeName}}</td>
              <td>{{permission.id}}</td>
              <td>{{permission.name}}</td>
              <td>{{permission.explicitAccess}}</td>
            </tr>
          </ng-container>
        </ng-container>

      </table>
    </div>
  </ng-template>
  
  <ng-template #noGamPermissionsSection>
    <p>No GAM Permissions Found</p>
  </ng-template>

</ng-container>