import { ErrorHandler, NgModule, Provider } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { HomeComponent } from './components/home/home.component';
import { NavComponent } from './components/nav/nav.component';
import { SharedModule } from '../shared/shared.module';
import { ApiInterceptor } from '../shared/interceptors/api.interceptor';
import { SelectionViewModule } from '../selection-view/selection-view.module';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from '../shared/services/authentication.service';
import { PlanningViewModule } from '../planning-view/planning-view.module';
import { UserSettingsModule } from '../user-settings/user-settings.module';
import { GlobalErrorHandler } from '../shared/services/error-handling/global-error-handler';

let providers: Provider[] = [
  {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
  },
  {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
  },
  {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
  },
  MsalService,
  MsalGuard,
  MsalBroadcastService,
  { provide: ErrorHandler, useClass: GlobalErrorHandler }
];

// Kind of an ugly solution but HTTP_INTERCEPTORS providers break unit tests and are hard to mock in the tests since they're multi = true so don't include for tests
if (!window['isUnitTest']) {
  providers = providers.concat([
    { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true }
  ]);
}

@NgModule({
  declarations: [
    HomeComponent,
    NavComponent
  ],
  imports: [    
    MsalModule,
    SelectionViewModule,
    PlanningViewModule,
    UserSettingsModule,
    AppRoutingModule,
    SharedModule    
  ],
  providers: providers,
  bootstrap: [HomeComponent, MsalRedirectComponent]
})
export class AppModule { }
