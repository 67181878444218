import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiRoutes } from "../../shared/types/api-routes";
import { PublishAssortmentDataRequest } from "../types/api/publish-dialog/publish-assortment-data-request";
import { AssortmentPublishedStatusResponseItem } from "../types/assortment-published-status-response-item";

@Injectable({
    providedIn: 'root'
})
export class PublishDataService {
    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient) { }

    private assortmentPublishUrl = environment.baseApiUrl + ApiRoutes.publishAssortmentData;

    private assortmentPublishedSeasonStatusDataUrl = environment.baseApiUrl + ApiRoutes.assortmentPublishedSeasonStatusData;
    // private salesPublishUrl = environment.baseApiUrl + ApiRoutes.publishSalesData;

    publishAssortmentData(requestData: PublishAssortmentDataRequest) {
        return this.http.post(this.assortmentPublishUrl, requestData, this.httpOptions);
    }

    getAssortmentPublishSeasonStatusData(assortmentCopyId: number): Promise<AssortmentPublishedStatusResponseItem[]> {
        let url = this.assortmentPublishedSeasonStatusDataUrl + "?copyId=" + assortmentCopyId;        
        return this.http.get<AssortmentPublishedStatusResponseItem[]>(url, this.httpOptions).toPromise();
    }
}
