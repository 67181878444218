import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class StockPrognosisCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets Stock Prognosis Weekly

    // Dependencies
    // ------------
    // Stock Ground (raw)
    // Outgoing Stock Prognosis Weekly (calculated)

    // Calculates
    // ----------
    // Stock Prognosis Weekly


    // Periodic
    // ------
    // Sets Stock Prognosis Periodic

    // Dependencies
    // ------------
    // Stock Ground (raw)
    // Outgoing Stock Prognosis Weekly (calculated)

    // Calculates
    // ----------
    // Stock Prognosis Periodic

    addMovesSum = 0;
    addRemoveSum = 0;
    cubeDemdelPlanSum = 0;
    salesForecastSum = 0;
    combinedSalesSum = 0;

    reset(): void {
        this.addRemoveSum = 0;
        this.addMovesSum = 0;
        this.cubeDemdelPlanSum = 0;
        this.salesForecastSum = 0;
        this.combinedSalesSum = 0;
    }

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {

        let effectiveOutgoingStockPrognosis = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let retrievalMode: RetrievalMode = this.calculatorDriver.getRetrievalMode();

        this.addRemoveSum += utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.AddRemoveMSek]) ? (currentWeekItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK) : 0;
        this.addMovesSum += utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.AddMovesMSek]) ? (currentWeekItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK) : 0;
        this.cubeDemdelPlanSum += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.CubeDemDelPlan]) ? (currentWeekItem[CalculationDataItemType.CubeDemDelPlan]) : 0;
        this.salesForecastSum += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.SalesForecastWeekly]) ? (currentWeekItem[CalculationDataItemType.SalesForecastWeekly]) : 0;
        this.combinedSalesSum += utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) ? (currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) : 0;

        let seasonPlanningType = this.calculatorDriver.getSeasonInfo().seasonPlanningType;

        if (retrievalMode == RetrievalMode.Plan) {

            if (seasonPlanningType == SeasonPlanningType.Actual) {
                // Stock Plan Fixed + Sum(AddRemove) till current week + Sum(add Moves) till current week + Sum(Effective sales plan) till that week - combined sales till that week
                effectiveOutgoingStockPrognosis = currentWeekItem[CalculationDataItemType.StockPlanFixed] + this.addMovesSum + this.addRemoveSum + this.cubeDemdelPlanSum - this.combinedSalesSum;
            }
            else {
                // Stock Plan Fixed + Sum(AddRemove) till current week + Sum(add Moves) till current week + Sum(Effective sales plan) till that week - sales forecast till that week
                effectiveOutgoingStockPrognosis = currentWeekItem[CalculationDataItemType.StockPlanFixed] + this.addMovesSum + this.addRemoveSum + this.cubeDemdelPlanSum - this.salesForecastSum;
            }

            effectiveOutgoingStockPrognosis = effectiveOutgoingStockPrognosis * currentWeekItem[CalculationDataItemType.ShrinkPercentLy]/100;
        }

        else if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly])) {
            effectiveOutgoingStockPrognosis = currentWeekItem[CalculationDataItemType.OutgoingStockPrognosisWeekly];
        }
        
        currentWeekItem[CalculationDataItemType.EffectiveOutgoingStockPrognosis] = effectiveOutgoingStockPrognosis;

        let stockPrognosisLY = utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.StockGround]) ? currentWeekItem[CalculationDataItemType.StockGround] : currentWeekItem[CalculationDataItemType.StockPrognosisLy];
        currentWeekItem[CalculationDataItemType.StockPrognosisWeekly] = utils.safeDivide(effectiveOutgoingStockPrognosis, stockPrognosisLY) * 100;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let stockGroundSum = 0

        currentPeriodWeekItems.forEach(item=>
            {
                stockGroundSum += utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.StockGround]) ? item[CalculationDataItemType.StockGround] : item[CalculationDataItemType.StockPrognosisLy];
            });

        let outgoingStockPrognosisSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.EffectiveOutgoingStockPrognosis);

        // average it
        if (utils.isNotNullUndefinedOrZero(stockGroundSum)) {
            value = (outgoingStockPrognosisSum / stockGroundSum) * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.StockPrognosisPeriodic] = value;
        });

    }


}