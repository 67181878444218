import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { SelectionPageResponse } from "../../selection-view/types/api/selection-page-response";
import { StructureType } from "../../selection-view/types/structure-type";
import { UtilsService } from "../../shared/services/utils.service";
import { CorporateBrand } from "../../shared/types/corporate-brand";
import { CustomerGroup } from "../../shared/types/customer-group";
import { Organization } from "../../shared/types/organization";
import { Section } from "../../shared/types/section";
import { SelectionViewOptions } from "../../shared/types/selection-view-options";
import { Constants } from 'src/app/modules/shared/types/constants';
import * as _ from 'lodash';
import { StructureTypes } from "../../shared/types/structure-types";
import { AssortmentCopy } from "../../selection-view/types/api/copy/get-all-copies/response/assortment-copy";
import { CopyService } from "../../selection-view/services/copy.service";
import { Market } from "../../shared/types/market";
import { SalesCopy } from "../../selection-view/types/api/copy/get-all-copies/response/sales-copy";

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    constructor(private _utilsService: UtilsService, private _copyService: CopyService) {
    }

    private _queryParamKeys =
        {
            brand: "brand",
            organization: "organization",
            structureType: "structureType",
            structure: "structure",
            copy: "copy",
            marketTypeId1: "marketTypeId1",
            marketId1: "marketId1",
            marketTypeId2: "marketTypeId2",
            marketId2: "marketId2"
        }

    private _validateElement(
        elements: CorporateBrand[] | Organization [] | StructureType[] | Section[] | CustomerGroup[] | Market[], 
        queryParams: Params, 
        elementQueryParamKey: string, 
        propertyToCompare: string): CorporateBrand | Organization | StructureType | Section | CustomerGroup | Market{
        
        // check if the list is null or undefined
        if(this._utilsService.isNullOrUndefined(elements))
        {
            return null;
        }

        let elementProperty = queryParams[elementQueryParamKey];

        // check if params exist
        if (this._utilsService.isNullOrEmpty(elementProperty)) {
            return null;
        }

        // check if the user has access to the brand 
        let elementItem = _.find(elements, element => {
            return element[propertyToCompare] == elementProperty
        })

        if (this._utilsService.isNullUndefinedOrZero(elementItem)) {
            return null;
        }

        return elementItem;
    }

    private async _validateAssortmentCopy(queryParams: Params, elementQueryParamKey: string, brand: CorporateBrand, structureType: StructureType, structure: Section|CustomerGroup|CorporateBrand): Promise<AssortmentCopy>
    {
        let selectedAssortmentCopyItem: AssortmentCopy = null;

        let copyId = queryParams[elementQueryParamKey];

        // check if copy exists in the query params and the brand and structure type is valid
        if(this._utilsService.isNullOrUndefined(copyId) || this._utilsService.isNullOrUndefined(brand) || this._utilsService.isNullOrUndefined(structureType) || this._utilsService.isNullOrUndefined(structure))
        {
            return null;
        }

        copyId = parseInt(copyId);

        // get list of all copies for the given brand
        let assortmentCopies = await this._copyService.getAllAssortmentCopiesByBrandAndStructureId(brand.id, structureType.id).toPromise();

        // check if current user has access to the given copy
        // if its a main copy
        if (copyId == 0) {

            let brandId: number = null;

            // get brand id
            if(structureType.name == StructureTypes.Brand)
            {
                brandId = structure.id
            }
            else if(structureType.name == StructureTypes.Section){
                brandId = (<Section>structure).corporateBrandId;
            }
            else if(structureType.name == StructureTypes.CustomerGroup)
            {
                brandId = (<CustomerGroup>structure).corporateBrandId;
            }

            selectedAssortmentCopyItem = {
                assortmentCopyId: 0,
                brandId: brandId,
                name: this._utilsService.isNotNullOrUndefined(structure) ? ("Main - " + structure.code) : null,
                structureId:  this._utilsService.isNotNullOrUndefined(structure) ? structure.id : null,
                isMain: true,
                isDeleted: false,
                currencyId: null,
                createdBy: null,
                createdTime: null,
                modifiedBy: null,
                modifiedTime: null,
                mergedBy: null,
                mergedTime: null,
                isMerged: false,
                isLocked: false
            };
        }
        // not a main copy
        else {
            selectedAssortmentCopyItem = _.find(assortmentCopies, (assortmentCopy: AssortmentCopy) => {
                return assortmentCopy.assortmentCopyId == copyId
            });
        }

        // return null, if its not valid
        if(this._utilsService.isNullOrUndefined(selectedAssortmentCopyItem))
        {
            return null
        }

        // check if the copy belongs to the same section/customer group
        if (structure.name.startsWith(StructureTypes.Section) || structureType.name.startsWith(StructureTypes.CustomerGroup)) {
            if (selectedAssortmentCopyItem.structureId != structure.id) {
                return null;
            }
        }

        return selectedAssortmentCopyItem;
    }

    private async _validateSalesCopy(queryParams: Params, elementQueryParamKey: string, brand: CorporateBrand, market: Market): Promise<SalesCopy>
    {
        let selectedSalesCopyItem: SalesCopy = null;

        let copyId = queryParams[elementQueryParamKey];

        // check if copy exists in the query params and the brand and structure type is valid
        if(this._utilsService.isNullOrUndefined(copyId) || this._utilsService.isNullOrUndefined(brand) || this._utilsService.isNullOrUndefined(market))
        {
            return null;
        }

        copyId = parseInt(copyId);

        // check if current user has access to the given copy
        let salesCopies = await this._copyService.getAllSalesCopiesByBrandId(brand.id).toPromise();

        // main
        if(copyId == 0)
        {
            selectedSalesCopyItem = {
                salesCopyId: 0,
                name: "Main - " + market.shortName,
                brandId: brand.id,
                isPercentPm: false,
                marketType: market.marketType,
                marketIntegrationKey: market.marketIntegrationKey,
                isMain: true,
                isDeleted: false,
                createdBy: null,
                createdTime: null,
                modifiedBy: null,
                modifiedTime: null,
                mergedBy: null,
                mergedTime: null,
                isPRCopy: true,
                isMerged: false,
                isLocked: false
            };
        }
        // not a main copy
        else {
            selectedSalesCopyItem = _.find(salesCopies, salesCopy => {
                return salesCopy.salesCopyId == copyId
            });
        }

        if (this._utilsService.isNullOrUndefined(selectedSalesCopyItem)) {
            return null;
        }

        return selectedSalesCopyItem;
    }



    async validateQueryStringParamsOnSelectionView(selectionPageApiResponse: SelectionPageResponse, queryParams: Params): Promise<SelectionViewOptions> {

        let autoSelectedOptions: SelectionViewOptions = null;

        let item = null;

        // validate brands
        item = this._validateElement(selectionPageApiResponse.brands, queryParams, this._queryParamKeys.brand, "id");
        let selectedBrandItem: CorporateBrand = (this._utilsService.isNotNullOrUndefined(item)) ? <CorporateBrand>item : null;

        // validate organizations
        item = this._validateElement(selectionPageApiResponse.organizations, queryParams, this._queryParamKeys.organization, "id");        
        let selectedOrganizationItem: Organization = (this._utilsService.isNotNullOrUndefined(item)) ? <Organization>item : null;

        // validate structure type
        let structureTypesList:StructureType[] = null;
        let selectedStructureTypeItem: StructureType = null;
        if(selectedOrganizationItem)
        {
            if(selectedOrganizationItem.name == Constants.ASSORTMENT)
            {
                structureTypesList = selectionPageApiResponse.assortment.structureTypes;
            }
            else if(selectedOrganizationItem.name == Constants.SALES)
            {
                structureTypesList = selectionPageApiResponse.sales.structureTypes;
            }

            item = this._validateElement(structureTypesList, queryParams, this._queryParamKeys.structureType, "id"); 

            selectedStructureTypeItem = (this._utilsService.isNotNullOrUndefined(item)) ? <StructureType>item : null;
        }        
        
        let selectedStructureItem: Section | CustomerGroup | CorporateBrand = null;
        let selectedAssortmentCopyItem: AssortmentCopy = null
        let selectedSalesCopyItem: SalesCopy = null

        // Assortment - structure and copy
        if(selectedOrganizationItem.name == Constants.ASSORTMENT)
        {
            if(selectedOrganizationItem && selectedStructureTypeItem)
            {           
                // validate structure item         
                if(selectedStructureTypeItem.name.startsWith(StructureTypes.Section))
                {
                    item = this._validateElement(selectionPageApiResponse.assortment.sections, queryParams, this._queryParamKeys.structure, "id"); 
                    selectedStructureItem = (this._utilsService.isNotNullOrUndefined(item)) ? <Section>item : null;
                }
                else if (selectedStructureTypeItem.name.startsWith(StructureTypes.CustomerGroup))
                {
                    item = this._validateElement(selectionPageApiResponse.assortment.customerGroups, queryParams, this._queryParamKeys.structure, "id"); 
                    selectedStructureItem = (this._utilsService.isNotNullOrUndefined(item)) ? <CustomerGroup>item : null;
                }
                else if(selectedStructureTypeItem.name.startsWith(StructureTypes.Brand))
                {
                    item = this._validateElement(selectionPageApiResponse.brands, queryParams, this._queryParamKeys.brand, "id"); 
                    selectedStructureItem = (this._utilsService.isNotNullOrUndefined(item)) ? <CorporateBrand>item : null;
                }

                // validate copy
                selectedAssortmentCopyItem = await this._validateAssortmentCopy(queryParams, this._queryParamKeys.copy, selectedBrandItem, selectedStructureTypeItem, selectedStructureItem);

                // pack and return selection
                if(selectedBrandItem && selectedOrganizationItem && selectedStructureTypeItem && selectedStructureItem && selectedAssortmentCopyItem)
                {
                    autoSelectedOptions = {
                        brand: selectedBrandItem,
                        organization: selectedOrganizationItem,
                        structure: selectedStructureItem,
                        structureType: selectedStructureTypeItem,
                        copy: selectedAssortmentCopyItem
                    }
                }
            }
        }
        // Sales - Market and copy
        else if(selectedOrganizationItem.name == Constants.SALES)        
        {
            // validate market
            item = this._validateElement(selectionPageApiResponse.sales.markets, queryParams, this._queryParamKeys.marketId1, "marketIntegrationKey");
            let selectedMarket: Market = (this._utilsService.isNotNullOrUndefined(item)) ? <Market>item : null;

            // validate copy
            selectedSalesCopyItem = await this._validateSalesCopy(queryParams, this._queryParamKeys.copy, selectedBrandItem, selectedMarket);

            // pack and return selection
            if(selectedBrandItem && selectedOrganizationItem && selectedStructureTypeItem && selectedSalesCopyItem)
            {
                autoSelectedOptions = {
                    brand: selectedBrandItem,
                    organization: selectedOrganizationItem,
                    market: selectedMarket,
                    structureType: selectedStructureTypeItem,
                    copy: selectedSalesCopyItem
                }
            }
        }

        return autoSelectedOptions;
    }
        
}
