import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { DepartmentCalculationDataItem } from "../../../planning-view/types/department-calculation-data-item";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class StockIndexGroundCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Stock Prognosis LY
    // Stock Gross Ground LY

    // Calculates
    // ----------
    // Ground Stock Index

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        // Stock Prognosis LY / Stock Gross Ground LY

        let seasonPlanningType = this.calculatorDriver.getSeasonInfo().seasonPlanningType;

        if (
            (seasonPlanningType == SeasonPlanningType.Coming ||
                seasonPlanningType == SeasonPlanningType.Future)
            &&
            previousWeekItem != null
            && utils.isNotNullUndefinedOrZero(previousWeekItem[CalculationDataItemType.StockGroundLy])
        ) {
            value = (previousWeekItem[CalculationDataItemType.StockPrognosisLy] / previousWeekItem[CalculationDataItemType.StockGroundLy]) * 100
        }
        else {
            let stockPrognosisLySum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.StockPrognosisLy);
            let stockGrossGroundLySum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.StockGroundLy);

            if (utils.isNotNullUndefinedOrZero(stockGrossGroundLySum)) {
                value = (stockPrognosisLySum / (stockGrossGroundLySum)) * 100;
            }
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.GroundStockIndexPeriodic] = value;
        });
    }
}