import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { ChannelType } from "../../types/channel-type";
import { Constants } from "../../types/constants";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { SeasonType } from "../../types/season-type";
import { AbstractCalculator } from "../abstract-calculator";
import * as _ from 'lodash';

export class PlannedCoverageCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Add/Remove
    // Forecast Goal
    // R% 

    // Calculates
    // ----------
    // Purchase Plan
    // Coverage

    purchasePlanSumTillPreviousPeriod = 0;
    plannedReturnsSumTillPreviousPeriod = 0;
    salesPlanSumTillPreviousPeriod = 0;

    reset(): void {
        this.purchasePlanSumTillPreviousPeriod = 0;
        this.salesPlanSumTillPreviousPeriod = 0;
        this.plannedReturnsSumTillPreviousPeriod = 0;
    }

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {

        let purchasePlanvalue = 0;
        let plannedCoveragevalue = 0;

        let seasonPlanningType = this.calculatorDriver.getSeasonInfo().seasonPlanningType;
        let seasonType = this.calculatorDriver.getSeasonInfo().seasonType;
        let utils = this.calculatorDriver.getUtilsService();
        let userConfig = this.calculatorDriver.getUserConfig();
        let processed = false;
        let defaultCoverage = (userConfig.planningViewOptions.channel === ChannelType.Store) ? 2 : 1.5; // Coverage should be default to 2 for Period 0
        let startWeekRawData : any; 
        // Coverage only for coming and future season
        if (seasonPlanningType == SeasonPlanningType.Coming || seasonPlanningType == SeasonPlanningType.Future) {

            let startWeekForCurrentSeason = utils.getStartWeekForSeason(parseInt(this.calculatorDriver.getSeasonInfo().seasonCodeNames[0]));

            startWeekRawData = _.find(this.calculatorDriver.getDataSet(), (weekRawDataItem: DepartmentCalculationDataItem) => weekRawDataItem.weekName == startWeekForCurrentSeason)
            purchasePlanvalue = (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.PurchasePlanFixed]) ? currentWeekItem[CalculationDataItemType.PurchasePlanFixed] : 0);
            if (currentWeekItem[CalculationDataItemType.WeekName] >= this.calculatorDriver.getViewWeekWithYear()) {
                purchasePlanvalue += (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.AddRemoveMSek]) ?
                    (currentWeekItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK) :
                    0);
            }

            currentWeekItem[CalculationDataItemType.PurchasePlan] = purchasePlanvalue;

            const currentPeriodSalesPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.EffectiveSalesPlanWeekly);
            const currentPeriodPurchasePlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.PurchasePlan);
            const currentPeriodPlannedReturnsSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.PlannedReturn);
            const isBeforeStartWeek = currentWeekItem.weekName < startWeekForCurrentSeason;

            if (!isBeforeStartWeek) {
                if (seasonType == SeasonType.Spring) {
                    if (currentWeekItem.weekName % 100 >= 49 && currentWeekItem.weekName % 100 <= 52) {
                        currentWeekItem[CalculationDataItemType.PlannedCoverage] = defaultCoverage;
                        processed = true;
                    }
                }
                else {
                    if (currentWeekItem.weekName % 100 >= 21 && currentWeekItem.weekName % 100 <= 24) {
                        currentWeekItem[CalculationDataItemType.PlannedCoverage] = defaultCoverage;
                        processed = true;
                    }
                }

                if (!processed) {
                    if (currentPeriodSalesPlanSum != 0) {
                        plannedCoveragevalue = (this.purchasePlanSumTillPreviousPeriod + this.plannedReturnsSumTillPreviousPeriod - this.salesPlanSumTillPreviousPeriod) / currentPeriodSalesPlanSum;
                        currentWeekItem[CalculationDataItemType.PlannedCoverage] = !isNaN(plannedCoveragevalue) && isFinite(plannedCoveragevalue) ? plannedCoveragevalue : null;
                    }
                    else {
                        currentWeekItem[CalculationDataItemType.PlannedCoverage] = 0;
                    }
                } 
            }
            else {
                //set coverage empty for before the k period
                currentWeekItem[CalculationDataItemType.PlannedCoverage] = null;
            }

            if (currentWeekItem.weekName === currentPeriodWeekItems[3].weekName) {
                // Don't add dem del plan for weeks before start week if there is saved dem del plan on first week as it will then already have it added to it
                if (!isBeforeStartWeek || !utils.isNotNullOrUndefined(startWeekRawData[CalculationDataItemType.DemDelPlan])) this.salesPlanSumTillPreviousPeriod += currentPeriodSalesPlanSum;
                this.plannedReturnsSumTillPreviousPeriod += currentPeriodPlannedReturnsSum;
                this.purchasePlanSumTillPreviousPeriod += currentPeriodPurchasePlanSum;
            }
        }
    }
}
