import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import moment from 'moment';
import { PublishDialogComponent } from 'src/app/modules/planning-view/components/publish-dialog/publish-dialog.component';
import { SeasonDataService } from 'src/app/modules/planning-view/services/season-data.service';
import { PublishDialogData } from 'src/app/modules/planning-view/types/publish-dialog-data';
import { DialogBoxService } from 'src/app/modules/shared/services/dialog-box-service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { SeasonInfoRequest } from 'src/app/modules/shared/types/api/season-data/season-info-request';
import { Constants } from 'src/app/modules/shared/types/constants';
import { CorporateBrand } from 'src/app/modules/shared/types/corporate-brand';
import { CustomerGroup } from 'src/app/modules/shared/types/customer-group';
import { Market } from 'src/app/modules/shared/types/market';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { Organization } from 'src/app/modules/shared/types/organization';
import { OrganizationType } from 'src/app/modules/shared/types/organization-type';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import { Section } from 'src/app/modules/shared/types/section';
import { SelectionViewOptions } from 'src/app/modules/shared/types/selection-view-options';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { UserInfo } from 'src/app/modules/shared/types/user-info';
import { UserSettingsService } from 'src/app/modules/user-settings/services/user-settings.service';
import { CopyService } from '../../services/copy.service';
import { AssortmentCopy } from '../../types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from '../../types/api/copy/get-all-copies/response/sales-copy';
import { RegionMapping } from '../../types/api/selection-page-response';
import { CopyDialogData } from '../../types/copy-dialog-data';
import { MergeCopyDialogData } from '../../types/merge-copy-dialog-data';
import { StructureType } from '../../types/structure-type';
import { CopyDialogBoxComponent } from '../copy-dialog/copy-dialog.component';
@Component({
  selector: 'omnitdp-selection-view-copy',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.css']
})
export class CopyComponent {

  // for assortment
  @Input() organization: Organization;
  @Input() brand: CorporateBrand;
  @Input() assortmentStructureType: StructureType;
  @Input() assortmentStructure: Section | CustomerGroup | CorporateBrand;
  @Input() mainMarket: Market;
  @Input() salesCopyDialogMarkets: Market[];
  @Input() regionMappings: RegionMapping[];

  @Output() copyComponentReady = new EventEmitter<boolean>();
  @Output() copySelected = new EventEmitter<AssortmentCopy | SalesCopy>();
  @Output() showOthersCopySelectionChanged = new EventEmitter<boolean>();
  // @Output() isButtonsDisabled = new EventEmitter<boolean>();

  userInfo: UserInfo = null;
  userConfig: UserConfig = null;
  copyCreatedUserEmail: string = null;

  seasons: SeasonInfo[];
  assortmentCopyCache: [{ brandId: number, structureTypeId: number, copies: AssortmentCopy[] }?] = []
  salesCopyCache: [{ brandId: number, copies: SalesCopy[] }?] = []

  // ui variables
  copies: AssortmentCopy[] | SalesCopy[] = [];
  selectedCopyList: AssortmentCopy[] | SalesCopy[] = [];

  isCopyComponentReady: boolean = false;
  selectedShowCopyByOthers: boolean = false;
  selectedcopy: AssortmentCopy | SalesCopy
  alertMessage: string;
  areCopyActionButtonsDisabled = false;
  areCopiesAccessible = true;
  isMergeButtonDisabled: boolean = false;
  savedOptions: SelectionViewOptions = null;
  isSumSimView: boolean = false;
  copiesInitialized: boolean = false;
  isEnabledForAnaplan: boolean = false;
  constructor(
    public dialog: MatDialog,
    private _copyService: CopyService,
    private _dialogBoxService: DialogBoxService,
    private _utilsService: UtilsService,
    private _loadingAnimationService: LoadingAnimationsService,
    private _seasonDataService: SeasonDataService,
    private _userConfigService: UserConfigService,
    private _userSettingsService: UserSettingsService) { }

  ngOnInit(): void {
    this.loadUserDetails();
    this.loadData();
  }

  ngOnChanges() {

    if (this.copiesInitialized) {
      this.loadData();
    }
  }

  loadUserDetails() {


    this._userSettingsService.userInfoSubject.subscribe((userInfo: UserInfo) => {
      if (userInfo) {
        this.userInfo = userInfo;
        this.copyCreatedUserEmail = this.getLoggedUserEmail();
      }
    });

    this.userConfig = this._userConfigService.getUserConfig();
    if (this._utilsService.isNotNullOrUndefined(this.userConfig?.selectionViewOptions)) {
      this.savedOptions = this.userConfig.selectionViewOptions;
      this.selectedShowCopyByOthers = this.userConfig.selectionViewOptions.showOthersCopy;
    }

    this.showOthersCopySelectionChanged.emit(this.selectedShowCopyByOthers);
  }

  getMergeToolTipForAssortment() {
    if (this.organization.name == OrganizationType.Assortment && this.isEnabledForAnaplan)
      return Constants.AnplanEnabledSectionErrorMessage;

    return "Merge";
  }

  getPublishToolTipForAssortment() {
    if (this.organization.name == OrganizationType.Assortment && this.isEnabledForAnaplan)
    return Constants.AnplanEnabledSectionErrorMessage;

    return "Publish";
  }

  async loadData() {

    // to detect whether the currently selected structure is Section > Department or CG>D>S>D(Sum/Sim)
    this.isSumSimView = this.organization.name == OrganizationType.Assortment && !this.assortmentStructureType.name.startsWith('Section');

    this.isCopyComponentReady = false;
    this.copyComponentReady.emit(this.isCopyComponentReady);


    if (this.organization.name == OrganizationType.Assortment) {
      this.areCopiesAccessible = this._utilsService.isNotNullOrUndefined(this.brand) && this._utilsService.isNotNullOrUndefined(this.assortmentStructureType) && this._utilsService.isNotNullOrUndefined(this.assortmentStructure)

      this.isEnabledForAnaplan = !this.isSumSimView && (<Section>this.assortmentStructure).isEnabledForAnaplan;

      if (this.areCopiesAccessible) {
        await this.loadAssortmentCopies();
      }
    }
    else if (this.organization.name == OrganizationType.Sales) {

      this.areCopiesAccessible = this._utilsService.isNotNullOrUndefined(this.brand) && this._utilsService.isNotNullOrUndefined(this.mainMarket);

      if (this.areCopiesAccessible) {
        await this.loadSalesCopies();
      }

    }

    // emit to the parent component and show the pop up after the success.
    this.onLoadComplete();

  }

  formatDate(dateString: string): string {
    //converting utc time to local time
    return (dateString == null || dateString == undefined || dateString == "") ? "-" : moment.utc(dateString).local().format("DD-MMM-YYYY HH:mm:ss");
  }

  async loadAssortmentCopies() {
    //disable icon buttons while loading
    this.areCopyActionButtonsDisabled = true;
    let assortmentCopies: AssortmentCopy[] = [];

    // check if the current brand data is cached already
    let cachedCopies = <AssortmentCopy[]>this.getCachedData(true, this.brand.id, this.assortmentStructure.id, this.assortmentStructureType.id);

    if (this._utilsService.isNotNullOrUndefined(cachedCopies) && cachedCopies.length > 0) {
      assortmentCopies = cachedCopies;
    }
    else {
      assortmentCopies = await this._copyService.getAllAssortmentCopiesByBrandAndStructureId(this.brand.id, this.assortmentStructureType.id).toPromise();

      let cacheItem = {
        brandId: this.brand.id,
        copies: assortmentCopies,
        structureTypeId: this.assortmentStructureType.id
      };

      this.assortmentCopyCache.push(cacheItem);
    }
    this.copies = this.filterAndLoadAssortmentCopies(assortmentCopies);

    // auto select copy
    this._autoselectCopy();
  }

  async loadSalesCopies() {
    //disable icon buttons while loading
    this.areCopyActionButtonsDisabled = true;

    // check if the current brand data is cached already
    let salesCopies: SalesCopy[] = []

    let cachedCopies = <SalesCopy[]>this.getCachedData(false, this.brand.id, null, null);

    if (this._utilsService.isNotNullOrUndefined(cachedCopies) && cachedCopies.length > 0) {
      salesCopies = cachedCopies;
    }
    else {
      salesCopies = await this._copyService.getAllSalesCopiesByBrandId(this.brand.id).toPromise();

      let cacheItem = {
        brandId: this.brand.id,
        copies: salesCopies
      };

      this.salesCopyCache.push(cacheItem);
    }

    this.copies = this.filterAndLoadSalesCopies(salesCopies);

    // auto select copy
    this._autoselectCopy();
  }


  private _autoselectCopy() {

    let copySelectedFromUserConfig = false;
    // check if saved organization type matches the current organization type and brand
    if (
      this.userConfig?.selectionViewOptions &&
      this.organization.name == this.userConfig.selectionViewOptions.organization.name &&
      this.brand.id == this.userConfig.selectionViewOptions.brand.id
    ) {

      // check the organization type saved
      switch (this.userConfig.selectionViewOptions.organization.name) {
        case OrganizationType.Assortment:

          let cachedAssortmentSelectedCopy: AssortmentCopy = <AssortmentCopy>this.userConfig.selectionViewOptions.copy;

          let assortmentSelectedList = _.find(this.copies, (copy: AssortmentCopy) => {
            return copy.assortmentCopyId == cachedAssortmentSelectedCopy.assortmentCopyId;
          });

          if (this._utilsService.isNotNullOrUndefined(assortmentSelectedList)) {
            this.selectedCopyList = [assortmentSelectedList];
            copySelectedFromUserConfig = true;
          }

          break;

        case OrganizationType.Sales:

          let cachedSalesSelectedCopy: SalesCopy = <SalesCopy>this.userConfig.selectionViewOptions.copy;

          let salesSelectedList = _.find(this.copies, (copy: SalesCopy) => {
            return copy.salesCopyId == cachedSalesSelectedCopy.salesCopyId;
          });

          if (this._utilsService.isNotNullOrUndefined(salesSelectedList)) {
            this.selectedCopyList = [salesSelectedList];
            copySelectedFromUserConfig = true;
          }

          break;
      }
    }

    // if nothing has been selected still
    if (!copySelectedFromUserConfig || this.selectedCopyList.length == 0) {
      this.selectedCopyList = this.copies.length > 0 ? <AssortmentCopy[] | SalesCopy[]>[this.copies[0]] : [];
    }
  }


  filterAndLoadAssortmentCopies(copiesByBrand: AssortmentCopy[]): AssortmentCopy[] {

    let assortmentCopies = copiesByBrand.filter((assortmentCopy: AssortmentCopy) => {
      let valid = true;
      // check structure type id
      if (this.assortmentStructureType.name == StructureTypes.Brand) {
        if (assortmentCopy.structureId != this.brand.id) {
          valid = false;
        }
      }
      else {
        if (assortmentCopy.structureId != this.assortmentStructure.id) {
          valid = false;
        }
      }

      // check created by user
      if (!this.selectedShowCopyByOthers)  // if others copy cannot be seen
      {
        // show only own copies and main copies
        valid = valid && (assortmentCopy.createdBy.toLowerCase() == this.copyCreatedUserEmail.toLowerCase() || assortmentCopy.isMain);
      }

      return valid;
    });

    // check if main exists
    let mainExists = assortmentCopies.filter((copy: AssortmentCopy) => copy.isMain).length == 1;

    // create fake main only for section > department structure type
    if (!mainExists) {
      // create a fake main
      // Mains are also created for Sum Sim view, however they are hidden in the UI
      let fakeCopy = this.createFakeAssortmentMainCopy(this.assortmentStructure);
      assortmentCopies.unshift(fakeCopy);
    }

    assortmentCopies = this._sortCopies(assortmentCopies);

    this.copies = assortmentCopies;
    this.selectedCopyList = [this.copies[0]];

    if (this.isSumSimView) {
      let simulationCopies = this.copies.filter((copy: AssortmentCopy) => !copy.isMain);

      if (simulationCopies.length > 0) {
        this.selectedCopyList = [simulationCopies[0]];
        return simulationCopies;
      }
    }
    return assortmentCopies;
  }

  filterAndLoadSalesCopies(copiesByBrand: SalesCopy[]): SalesCopy[] {

    let salesCopies = copiesByBrand.filter((salescopy: SalesCopy) => {

      let valid = true;
      // if only own can be seen
      if (!this.selectedShowCopyByOthers) {
        // show only own copies and main copies
        valid = valid && (salescopy.createdBy.toLowerCase() == this.copyCreatedUserEmail.toLowerCase() || salescopy.isMain);
      }

      // market type and market id should match
      if (salescopy.marketIntegrationKey != this.mainMarket.marketIntegrationKey) {
        valid = false;
      }

      return valid;
    });

    // check if main exists
    let mainExists = salesCopies.filter((copy: SalesCopy) => copy.isMain).length == this.salesCopyDialogMarkets.length;

    if (!mainExists) {
      this.salesCopyDialogMarkets.forEach(market => {
        if (market.marketType == MarketType.PlanningRegion) {
          let mainCopy = salesCopies.filter((copy: SalesCopy) => copy.isMain && copy.pmCode == null && market.marketIntegrationKey == copy.marketIntegrationKey);
          if (mainCopy.length == 0) {
            let fakeCopy = this.createFakeSalesMainCopy(this.brand, market);
            salesCopies.unshift(fakeCopy);
          }
        }
        else if (market.marketType == MarketType.PlanningMarket || market.marketType == MarketType.PercentPlanningMarket) {
          let mainCopy = salesCopies.filter((copy: SalesCopy) => copy.isMain && market.marketIntegrationKey == copy.marketIntegrationKey);
          if (mainCopy.length == 0) {
            let fakeCopy = this.createFakeSalesMainCopy(this.brand, market);
            salesCopies.unshift(fakeCopy);
          }
        }
        else if (market.marketType == MarketType.PlanningRegionPlanningMarket) {
          let mainCopy = salesCopies.filter((copy: SalesCopy) => copy.isMain && market.marketIntegrationKey == copy.pmCode);
          if (mainCopy.length == 0) {
            let fakeCopy = this.createFakeSalesMainCopy(this.brand, market);
            salesCopies.unshift(fakeCopy);
          }
        }
      });

    }

    salesCopies = this._sortCopies(salesCopies);

    this.copies = salesCopies;
    this.selectedCopyList = [this.copies[0]];

    return salesCopies;

  }

  onLoadComplete() {

    this.getSelectedCopy();
    // component is loaded now
    this.areCopyActionButtonsDisabled = false;
    this._loadingAnimationService.disableTopNavAnimation();
    this.isCopyComponentReady = true;
    this.copiesInitialized = true;

    // inform the parent component that a copy has been selected
    this.copySelected.emit(this.selectedCopyList[0]);
    this.copyComponentReady.emit(this.isCopyComponentReady);

    if (this.alertMessage) {
      this._dialogBoxService.showMessage(this.alertMessage);
      //clear the message after the alert.
      this.alertMessage = "";
    }
  }

  private _sortCopies(copies) {
    //sort the sales copies in below order 
    // 1. Pr Main
    // 2. PM Main
    // 3. User Copies
    let mainCopies = copies.filter(copy => copy.isMain);
    let userCopies = copies.filter(copy => !copy.isMain);
    mainCopies = mainCopies.sort(function (x, y) {
      if (x.isMain && x.isPRCopy) {
        return -1;
      }
      return y.isMain ? 1 : 0;
    });
    userCopies = userCopies.sort((a, b) => (a.modifiedTime > b.modifiedTime ? -1 : 1));
    if (userCopies.length > 0) {
      userCopies.forEach(userCopy => {
        mainCopies.push(userCopy);
      });
    }
    return mainCopies;
  }

  createFakeAssortmentMainCopy(structure: Section | CustomerGroup | CorporateBrand): AssortmentCopy {

    this.areCopiesAccessible = (structure != null && structure != undefined) ? true : false;

    let bId = null;

    if ((structure != null && structure != undefined)) {
      if ('corporateBrandId' in structure) {
        bId = structure.corporateBrandId
      }
      else {
        bId = structure.id;
      }
    }

    return {
      assortmentCopyId: 0,
      brandId: bId,
      name: (structure != null && structure != undefined) ? ("Main - " + structure.code) : null,
      structureId: (structure != null && structure != undefined) ? structure.id : null,
      isMain: true,
      isDeleted: false,
      currencyId: null,
      createdBy: null,
      createdTime: null,
      modifiedBy: null,
      modifiedTime: null,
      mergedBy: null,
      mergedTime: null,
      isMerged: false,
      isLocked: false,
    };
  }

  createFakeSalesMainCopy(brand: CorporateBrand, market: Market): SalesCopy {

    //copy section disabled when the market is null or empty (same as selection page)
    this.areCopiesAccessible = (this.mainMarket != null && this.mainMarket != undefined) ? true : false;

    return {
      salesCopyId: 0,
      name: "Main - " + market.shortName,
      brandId: brand.id,
      isPercentPm: this.mainMarket.marketType == MarketType.PercentPlanningMarket,
      marketType: this.mainMarket.marketType,
      marketIntegrationKey: this.mainMarket.marketIntegrationKey,
      isMain: true,
      isDeleted: false,
      createdBy: null,
      createdTime: null,
      modifiedBy: null,
      modifiedTime: null,
      mergedBy: null,
      mergedTime: null,
      isPRCopy: market.marketType == MarketType.PlanningRegion, //if its a region level main then its should be true else false
      isMerged: false,
      isLocked: false,
      pmCode: (this.mainMarket.marketType == MarketType.PlanningRegion && market.marketType == MarketType.PlanningRegionPlanningMarket) ? market.marketIntegrationKey : null,
      pmId: (this.mainMarket.marketType == MarketType.PlanningRegion && market.marketType == MarketType.PlanningRegionPlanningMarket) ? market.marketIntegrationKey : null,
    };
  }

  getLoggedUserEmail() {
    let userEmail = '';
    if (this.userInfo) {
      let impersonationPermission = this.userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);
      if (impersonationPermission != undefined && impersonationPermission.settings.isCurrentlyImpersonating && !this._utilsService.isNullOrEmpty(impersonationPermission.settings.impersonatedUserEmail)) {
        userEmail = impersonationPermission.settings.impersonatedUserEmail.toString().toLowerCase();
      }
      else {
        userEmail = !this._utilsService.isNullOrEmpty(this.userInfo.email) ? this.userInfo.email.toString().toLowerCase() : '';
      }
    }
    return userEmail;
  }

  isSumSimViewStructureTypeSelected() {

  }

  openCreateCopyDialog() {
    this.alertMessage = "";
    let createCopy: CopyDialogData;

    if (this.organization.name == OrganizationType.Assortment) {
      createCopy = {
        copyDialogType: "assortment-create-copy",
        copyDialogDataItem: {
          brand: this.brand,
          structure: this.assortmentStructure,
          structureType: this.assortmentStructureType
        }
      }
    }
    else if (this.organization.name == OrganizationType.Sales) {

      let isPrCopy = this._utilsService.doesUserHavePrOrPmAccess(this.mainMarket, this.userInfo, MarketType.PlanningRegion, this.regionMappings);
      let copiesAvailableMarket: Market[];
      if ((<SalesCopy>this.selectedCopyList[0]).isPRCopy && this._utilsService.isNullOrUndefined((<SalesCopy>this.selectedCopyList[0]).pmCode)) {
        copiesAvailableMarket = this.salesCopyDialogMarkets;
      }
      else if (!(<SalesCopy>this.selectedCopyList[0]).isPRCopy) {
        if (this._utilsService.isNullOrUndefined((<SalesCopy>this.selectedCopyList[0]).pmCode)) {
          copiesAvailableMarket = this.salesCopyDialogMarkets.filter(mkt => mkt.marketIntegrationKey == (<SalesCopy>this.selectedCopyList[0]).marketIntegrationKey);
        }
        else {
          copiesAvailableMarket = this.salesCopyDialogMarkets.filter(mkt => mkt.marketIntegrationKey == (<SalesCopy>this.selectedCopyList[0]).pmCode);
        }
      }

      createCopy = {
        copyDialogType: "sales-create-copy",
        copyDialogDataItem: {
          brand: this.brand,
          isPercentPm: this.mainMarket.marketType == MarketType.PercentPlanningMarket,
          mainMarket: this.mainMarket,
          isPRCopy: isPrCopy,
          pmId: (!isPrCopy) ? this.mainMarket.marketIntegrationKey : null,
          salesCopyDialogMarkets: copiesAvailableMarket
        }
      }
    }

    this.dialog.open<CopyDialogBoxComponent, CopyDialogData>(CopyDialogBoxComponent, {
      data: createCopy,
      disableClose: true
    }).afterClosed().subscribe(async copyResponse => {
      if (copyResponse) {

        // reset the existing copy cache and reinsert it with latest copies
        this.alertMessage = "Copy - <span class='dialogboxname'>" + copyResponse.name + "</span> has been created!";
        this.selectedcopy = copyResponse;
        this.assortmentCopyCache = [];
        this.salesCopyCache = [];
        await this.loadData();
      }
    });
  }

  openEditCopyCopyDialog() {
    this.alertMessage = "";
    let editCopy: CopyDialogData;

    if (this.organization.name == OrganizationType.Assortment) {
      editCopy = {
        copyDialogType: "assortment-edit-copy",
        copyDialogDataItem: {
          copy: <AssortmentCopy>this.selectedCopyList[0],
          brand: this.brand,
          structure: this.assortmentStructure,
          structureType: this.assortmentStructureType
        }
      }
    }
    else if (this.organization.name == OrganizationType.Sales) {
      editCopy = {
        copyDialogType: "sales-edit-copy",
        copyDialogDataItem: {
          copy: <SalesCopy>this.selectedCopyList[0],
          brand: this.brand,
          isPercentPm: this.mainMarket.marketType == MarketType.PercentPlanningMarket,
          mainMarket: this._utilsService.isNotNullOrUndefined((<SalesCopy>this.selectedCopyList[0]).pmCode) ? this.salesCopyDialogMarkets.find((market: Market) => market.marketIntegrationKey == (<SalesCopy>this.selectedCopyList[0]).pmId) : this.mainMarket,
        }
      }
    }

    this.dialog.open<CopyDialogBoxComponent, CopyDialogData>(CopyDialogBoxComponent, {
      data: editCopy,
      disableClose: true
    }).afterClosed().subscribe(async copyResponse => {
      if (copyResponse) {

        // reset the existing copy cache and reinsert it with latest copies
        this.alertMessage = "Copy has been renamed - <span class='dialogboxname'>" + copyResponse.name + "</span>";
        this.selectedcopy = this.selectedCopyList[0];
        this.assortmentCopyCache = [];
        this.salesCopyCache = [];
        await this.loadData();
      }
    });
  }

  openDeleteCopyCopyDialog() {
    this.alertMessage = "";
    let deleteCopy: CopyDialogData;
    if (this.organization.name == OrganizationType.Assortment) {
      deleteCopy = {
        copyDialogType: "assortment-delete-copy",
        copyDialogDataItem: {
          copy: <AssortmentCopy>this.selectedCopyList[0],
          brand: this.brand,
          structure: this.assortmentStructure,
          structureType: this.assortmentStructureType
        }
      }
    }
    else if (this.organization.name == OrganizationType.Sales) {
      deleteCopy = {
        copyDialogType: "sales-delete-copy",
        copyDialogDataItem: {
          copy: <SalesCopy>this.selectedCopyList[0],
          brand: this.brand,
          isPercentPm: this.mainMarket.marketType == MarketType.PercentPlanningMarket,
          mainMarket: this.mainMarket,
        }
      }
    }

    this.dialog.open<CopyDialogBoxComponent, CopyDialogData>(CopyDialogBoxComponent, {
      data: deleteCopy,
      disableClose: true
    }).afterClosed().subscribe(async copyResponse => {
      if (copyResponse) {
        // reset the existing copy cache and reinsert it with latest copies
        this.alertMessage = "Copy - <span class='dialogboxname'>" + copyResponse.copy.name + "</span> has been deleted!";
        this.selectedcopy = null;
        this.assortmentCopyCache = [];
        this.salesCopyCache = [];
        await this.loadData();
      }
    });
  }

  openMergeCopyCopyDialog() {
    this.alertMessage = "";
    let mergeCopy: MergeCopyDialogData;
    if (this.organization.name == OrganizationType.Assortment) {
      mergeCopy = {
        copyDialogType: "assortment-merge-copy",
        copyDialogDataItem: <AssortmentCopy>this.selectedCopyList[0]
      }
    }
    else if (this.organization.name == OrganizationType.Sales) {
      mergeCopy = {
        copyDialogType: "sales-merge-copy",
        copyDialogDataItem: <SalesCopy>this.selectedCopyList[0]
      }
    }

    this.dialog.open<CopyDialogBoxComponent, MergeCopyDialogData>(CopyDialogBoxComponent, {
      data: mergeCopy,
      disableClose: true
    }).afterClosed().subscribe(async copyResponse => {
      if (copyResponse) {
        // reset the existing copy cache and reinsert it with latest copies
        this.selectedcopy = mergeCopy.copyDialogDataItem;
        this.alertMessage = "Your goals have been merged successfully into the Main";
        this.assortmentCopyCache = [];
        this.salesCopyCache = [];
        await this.loadData();
      }
    });
  }

  getSelectedCopy() {
    if (this.selectedcopy) {
      if (this.organization.name == OrganizationType.Assortment) {
        let copyIndex = this.copies.findIndex(x => x.assortmentCopyId === (<AssortmentCopy>this.selectedcopy).assortmentCopyId);
        if (copyIndex > -1) {
          this.selectedCopyList = [<AssortmentCopy>this.copies[copyIndex]];

        }
      }
      else if (this.organization.name == OrganizationType.Sales) {
        let copyIndex = this.copies.findIndex(x => x.salesCopyId === (<SalesCopy>this.selectedcopy).salesCopyId);
        if (copyIndex > -1) {
          this.selectedCopyList = [<SalesCopy>this.copies[copyIndex]];
        }
      }
    }
  }

  changeShowCopiesOfOthers(event: MatCheckboxChange) {
    this.alertMessage = "";
    this.selectedShowCopyByOthers = event.checked;

    this.showOthersCopySelectionChanged.emit(this.selectedShowCopyByOthers);

    this.loadData();
  }



  changeCopy() {
    // reset disabling merge button 
    this.isMergeButtonDisabled = false;

    //check region access/pm access here
    if (this.organization.name == OrganizationType.Sales) {

      let selectedSalesCopy: SalesCopy = <SalesCopy>this.selectedCopyList[0];
      // as a Sales PM user, you should not be able to open Region main and Region level copies

      // If the currently selected copy is a PR or PR Main
      if (selectedSalesCopy.isPRCopy || this.selectedCopyList[0].isMain) {
        // check if the currently selected market is a Planning Region
        if (this._utilsService.doesUserHavePrOrPmAccess(this.mainMarket, this.userInfo, MarketType.PlanningRegion, this.regionMappings)) {
          this.isMergeButtonDisabled = false;
        }
        else {
          this.isMergeButtonDisabled = true;
        }
      }
      // Else if its a PM or PM Main
      else {
        // User can access PM copies either,
        // 1. If they have PR access
        // 2. If they have specific PM access

        if (this._utilsService.doesUserHavePrOrPmAccess(this.mainMarket, this.userInfo, MarketType.PlanningRegion, this.regionMappings) || this._utilsService.doesUserHavePmAccess(selectedSalesCopy, this.userInfo)) {
          this.isMergeButtonDisabled = false;
        }
        else {
          this.isMergeButtonDisabled = true;
        }
      }
    }
    this.copySelected.emit(this.selectedCopyList[0]);
  }


  async openPublishDepartmentPlanDialog() {
    //this.selectedOptions.planningViewOptions.structureId
    let publishDialogData: PublishDialogData;

    if (this.seasons == undefined || this.seasons == null) {
      // create a request object for the season info api
      let seasonInfoRequest: SeasonInfoRequest =
      {
        excludeOldSeasons: true, // send any value
        date: new Date() // send current date
      }

      // get the seasons for the current date
      this.seasons = await this._seasonDataService.getSeasonInfo(seasonInfoRequest);
    }


    publishDialogData = {
      publishDialogType: "Assortment",
      seasonInfos: this.seasons,
      name: this.selectedCopyList[0].name,
      brand: this.brand.name,
      copyId: (<AssortmentCopy>this.selectedCopyList[0]).assortmentCopyId,
      structureId: this.assortmentStructure.id,
      structureCode: this.assortmentStructure.code,
      isMainLocked: this.selectedCopyList[0].isLocked
    }


    this.dialog.open<PublishDialogComponent, PublishDialogData>(PublishDialogComponent, {
      data: publishDialogData,
      disableClose: true
    }).afterClosed().subscribe(copyResponse => {
      if (copyResponse) {

        // reset the existing copy cache and reinsert it with latest copies
        this.selectedcopy = this.selectedCopyList[0];
        this.alertMessage = "Publish request sent successfully!";
        this.assortmentCopyCache = [];
        this.salesCopyCache = [];
        this.loadData();
      }
    });
  }

  private getCachedData(isAssortment: boolean, brandId: number, structureId: number, structureTypeId: number): AssortmentCopy[] | SalesCopy[] {
    let cacheSource;

    if (isAssortment) {
      cacheSource = this.assortmentCopyCache;
    }
    else {
      cacheSource = this.salesCopyCache;
    }
    let cacheItem;

    if (this.organization.name == OrganizationType.Assortment && cacheSource.length > 0) {
      // for assortment, we have to filter the cache list with brand and sturture type
      let assortmentCopiesByBrandAndStructureType = _.find(cacheSource, item => item.brandId == brandId && item.structureTypeId == structureTypeId);
      //if the list contains value then filter with structure Id
      if (this._utilsService.isNotNullOrUndefined(assortmentCopiesByBrandAndStructureType) && assortmentCopiesByBrandAndStructureType.copies.length > 0) {
        cacheItem = assortmentCopiesByBrandAndStructureType.copies.filter(copy => copy.structureId == structureId);
      }
    }
    else if (this.organization.name == OrganizationType.Sales && cacheSource.length > 0) {
      let salesCopiesByBrand = _.find(cacheSource, item => item.brandId == brandId);
      if (this._utilsService.isNotNullOrUndefined(salesCopiesByBrand) && salesCopiesByBrand.copies.length > 0)
        cacheItem = salesCopiesByBrand.copies;
    }

    if (this._utilsService.isNotNullUndefinedOrZero(cacheItem)) {
      return cacheItem;
    }
    else {
      return null;
    }
  }

}
