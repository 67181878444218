import { CalculatorDriverInterface } from '../../calculators/calculator-driver-interface';
import { ParentCalculatorDriverInterface } from '../../calculators/parent-calculator-driver-interface';
import { DepartmentEventInfo } from './department-event-info';
import { DepartmentEventInterface } from './department-event-interface';
import { DepartmentEventType } from './department-event-type';

export abstract class DepartmentEvent implements DepartmentEventInterface {

    eventInfo: DepartmentEventInfo;
    undoEvent?: boolean;
    constructor(eventInfo: DepartmentEventInfo) {
        this.eventInfo = eventInfo;
    }
    getEventType(): DepartmentEventType {
        throw new Error("Method not implemented.");
    }

    applyEvent(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface) {
        throw new Error("Method not implemented.");
    }

    validateEvent(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface): boolean {
        throw new Error("Method not implemented.");
    }

    configureCalculators(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface) {
        throw new Error("Method not implemented.");
    }
}