import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { SaveWarningDialogData, SaveWarningDialogDataDetails } from "../../types/save-warning-dialog-data";

@Component({
    selector: 'save-warning-dialog',
    templateUrl: "./save-warning-dialog.component.html",
    styleUrls: ['./save-warning-dialog.component.css']
})

export class SaveWarningDialogComponent {


    isErrorOccured: boolean = true;
    errorMessage: string;

    comingSeasonNegPurchaseWeeks:  SaveWarningDialogDataDetails[] = [];
    futureSeasonNegPurchaseWeeks: SaveWarningDialogDataDetails[] = [];
    comingSeasonNegCoverageWeeks:  SaveWarningDialogDataDetails[] = [];
    futureSeasonNegCoverageWeeks: SaveWarningDialogDataDetails[] = [];

    constructor(
        private dialogRef: MatDialogRef<SaveWarningDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: SaveWarningDialogData) {

            this.comingSeasonNegPurchaseWeeks = dialogData.comingSeasonNegPurchaseWeeks;
            this.futureSeasonNegPurchaseWeeks = dialogData.futureSeasonNegPurchaseWeeks;
            this.comingSeasonNegCoverageWeeks = dialogData.comingSeasonNegCoverageWeeks;
            this.futureSeasonNegCoverageWeeks = dialogData.futureSeasonNegCoverageWeeks;
    }
}