import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { PlanningViewComponent } from '../components/planning-view/planning-view.component';

@Injectable()
export class PlanningViewDeactivateGuard  {
    component: Object;
    route: ActivatedRouteSnapshot;

    canDeactivate(component: PlanningViewComponent,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        if(component.preventUserFromNavigating())
        {
            return confirm("The copy has unsaved changes. Discard changes?");
        }
        else
        {            
            return true;
        }
    }

}