import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { AssortmentCopy } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { ReadonlyDepartmentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/readonly-department-calculator-driver-interface';
import { DepartmentEventInfo } from 'src/app/modules/shared/events/department-events/department-event-info';
import { DepartmentEventType } from 'src/app/modules/shared/events/department-events/department-event-type';
import { DialogBoxService } from 'src/app/modules/shared/services/dialog-box-service';
import { AppInsightsLoggingService } from 'src/app/modules/shared/services/error-handling/app-insights-logging.service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { Constants } from 'src/app/modules/shared/types/constants';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { RetrievalMode } from 'src/app/modules/shared/types/retrieval-mode';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { SeasonType } from 'src/app/modules/shared/types/season-type';
import { SeasonViewType } from 'src/app/modules/shared/types/season-view-type';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { SeasonDataService } from '../../services/season-data.service';
import { PlanningViewDataRequest } from '../../types/api/planning-view-data-request';
import { ReadonlyDepartmentPlanningViewDataResponse } from '../../types/api/planning-view-data/readonly-department-planning-view-data-response';
import { ReadonlyDepartmentSeasonTableInfo } from '../../types/api/readonly-department-season-table-info';
import { DepartmentCalculationDataItem } from '../../types/department-calculation-data-item';
import { ReturnsInfo } from '../../types/returns-info';

@Component({
  selector: 'app-readonly-season-table',
  templateUrl: './readonly-season-table.component.html',
  styleUrls: ['./readonly-season-table.component.css']
})
export class ReadonlySeasonTableComponent implements OnInit {

  @Input() seasonTableInfo: ReadonlyDepartmentSeasonTableInfo = null;
  @Input() debugMode: boolean = true;
  @Input() currentWeek: number = null;
  @Input() selectedKpis: string[] = null;
  @Input() isMain: boolean;
  @Input() channel: string;
  @Input() isChannelSum: boolean;
  @Input() isAggregatedMainViewEnabled: boolean;
  @Input() usedForSumView: boolean;
  @Input() areSalesSystemGoalsIncludedForAssortment: boolean;


  driver: ReadonlyDepartmentCalculatorDriverInterface = null;
  dataSet: DepartmentCalculationDataItem[] = null;
  seasonInfo: SeasonInfo = null;
  returnsInfo: ReturnsInfo = null;
  isForecastEnabledList = [];
  isWeeklyInputEnabledList = [];
  quarters = [];
  quarterBorderForWeeks: boolean[] = [];
  quarterBorderForPeriods: boolean[] = [];
  quarterBorderForCalcPeriods: boolean[] = [];
  seasonTitle: string = null;

  lastWeekInCurrentView: number = null;

  disabledTillWeek: number = null;

  availableRetrievalModes: RetrievalMode[] = [];
  currentRetrievalMode: RetrievalMode = null;

  isForecastDisabled: boolean = true;
  channelType: ChannelType = null;
  brandId: number = null;
  isAssortmentView: boolean = false;

  userConfigSubjectSubscription: Subscription;

  selectedMartketType: string = null;
  isPRCopy: boolean = false;

  constructor(private _utils: UtilsService,
    private _dialogBoxService: DialogBoxService,
    private _ref: ChangeDetectorRef,
    private _seasonDataService: SeasonDataService,
    private _appInsightsLoggingService: AppInsightsLoggingService,
    private _loadingAnimationService: LoadingAnimationsService,
    private _userConfigService: UserConfigService) {

  }

  ngOnInit(): void {


    this.driver = this.seasonTableInfo.driver;
    this.dataSet = this.driver.getDataSet();
    this.seasonInfo = this.driver.getSeasonInfo();

    let userConfig = this.driver.getUserConfig()
    this.channelType = userConfig.planningViewOptions.channel;
    this.brandId = userConfig.selectionViewOptions.brand.id;
    this.isAssortmentView = userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT;

    this.selectedMartketType = (userConfig.planningViewOptions.isChannelSum) ? "" : MarketType[userConfig.planningViewOptions.market.marketType];
    if (!this.isAssortmentView) {
      this.isPRCopy = (<SalesCopy>(userConfig.selectionViewOptions.copy)).isPRCopy;
    }

    let seasonViewType = this._utils.getSeasonViewType(this.driver.getViewDate());
    switch (seasonViewType) {
      case SeasonViewType.View1:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW1_ENDWEEK))[0];
        break;
      case SeasonViewType.View2:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW2_ENDWEEK))[0];
        break;
      case SeasonViewType.View3:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW3_ENDWEEK))[0];
        break;
      case SeasonViewType.View4:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW4_ENDWEEK))[0];
        break;
    }

    this.seasonTitle = this.driver.getTitle();

    this.setupSeasonTable();
    this.setAvailableRetrievalModes();
    this.returnsInfo = this.driver.getReturnsInfo();

    if (this.seasonInfo.seasonPlanningType == SeasonPlanningType.Total) {
      this.driver.addChangeEventListener(this.refreshDataSet.bind(this));
    }
    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);

    this.userConfigSubjectSubscription = this._userConfigService.userConfigSubject.subscribe(userConfig => {

      // check if it's not null, don't get retrival mode from user config if total view
      if (userConfig && userConfig?.planningViewLayoutSettings?.seasonSettings && userConfig?.planningViewOptions?.structureType != StructureTypes.Total) {
        let seasonSetting = userConfig?.planningViewLayoutSettings?.seasonSettings.find(x => x.seasonPlanningType == this.driver.getSeasonInfo().seasonPlanningType);

        if (this._utils.isNotNullOrUndefined(seasonSetting)) {
          // check if the retrieval mode has changed
          if (seasonSetting.retrievalMode != this.driver.getRetrievalMode()) {
            // trigger a retrieval mode event
            this.handleEvent({
              "target": {
                "value": seasonSetting.retrievalMode
              }
            }, null, 'RetrievalMode');
          }
        }
      }
    })
  }


  async handleEvent(event, weekIndex, type: string) {
    let eventType: DepartmentEventType = this._utils.enumFromValue(type, DepartmentEventType)

    let eventInfo: DepartmentEventInfo = {
      eventType: eventType,
      newValue: event.target.value,
      previousValue: null,
      weekIndexes: null
    }

    switch (eventType) {
      case DepartmentEventType.RetrievalMode:

        this.driver.setRetrievalMode(this._utils.enumFromValue(eventInfo.newValue, RetrievalMode));
        let seasonDataReponse: ReadonlyDepartmentPlanningViewDataResponse = await this.refreshSeasonDataTable(eventInfo);
        if (seasonDataReponse.weekData) {
          this.driver.setDataSet(seasonDataReponse.weekData);
          this.driver.handleEvent();
        }
        break;
    }

    this._loadingAnimationService.disableTopNavAnimation();
    this.dataSet = this.driver.getDataSet();
    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);
  }

  refreshDataSet() {
    this.dataSet = this.driver.getDataSet();
    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);
    this._ref.detectChanges();
  }

  async refreshSeasonDataTable(eventInfo: DepartmentEventInfo) {
    this._loadingAnimationService.enableTopNavAnimation();
    let userConfig = this.driver.getUserConfig();
    let isAssortmentView = false;

    let copyId: number = 0;

    if (userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
      isAssortmentView = true
    }

    if (!this.isAggregatedMainViewEnabled && !this.usedForSumView) {
      copyId = isAssortmentView ? (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId : (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId
    }

    //special case for maintain retrieval mode
    if ((this.isAggregatedMainViewEnabled && this.usedForSumView) || (!this.isAggregatedMainViewEnabled && this.usedForSumView)) {
      copyId = 0;
    }
    else {
      copyId = isAssortmentView ? (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId : (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId
    }

    // create request for season level data // if channel sum is true, then we dont need to consider market. So its sending as 0
    let dataRequest: PlanningViewDataRequest = {
      isAssortmentCopy: isAssortmentView,
      copyId: copyId,
      channelId: parseInt(userConfig.planningViewOptions.channel.toString()),
      marketId: (userConfig.planningViewOptions.isChannelSum) ? 0 : userConfig.planningViewOptions.market.marketIntegrationKey,
      marketType: (userConfig.planningViewOptions.isChannelSum) ? "" : MarketType[userConfig.planningViewOptions.market.marketType],
      planningSeasonType: this.seasonInfo.seasonPlanningType,
      structureId: userConfig.planningViewOptions.structureId,
      structureType: userConfig.planningViewOptions.structureType,
      selectedDate: new Date(),
      parentStructureId: userConfig.planningViewOptions.parentStructureId,
      parentStructureType: userConfig.planningViewOptions.parentStructureType,
      corporateBrandId: userConfig.selectionViewOptions.brand.id,
      retrievalMode: <string>eventInfo.newValue,
      isChannelSum: userConfig.planningViewOptions.isChannelSum,

      //added for creating main if its fake copy for sales
      isMain: userConfig.selectionViewOptions.copy.isMain,
      isPrCopy: !this.isAssortmentView ? (<SalesCopy>userConfig.selectionViewOptions.copy).isPRCopy : false,
      isPercentPm: !this.isAssortmentView ? (<SalesCopy>userConfig.selectionViewOptions.copy).isPercentPm : false,
      copyMarketType: !this.isAssortmentView ? MarketType[(<SalesCopy>userConfig.selectionViewOptions.copy).marketType] : '',
      copyMarketId: !this.isAssortmentView ? (<SalesCopy>userConfig.selectionViewOptions.copy).marketIntegrationKey : null,
      isStockVsSalesNet52Enabled: this.isAssortmentView
    }


    // request for data
    if ((this.isAggregatedMainViewEnabled && this.usedForSumView) || (!this.isAggregatedMainViewEnabled && this.usedForSumView)) {
      this._appInsightsLoggingService.logEvent("Requesting Aggregated Season Data When Retrieval Mode Change", dataRequest);
      return this._seasonDataService.getAggregatedMainsSeasonData(dataRequest);
    }
    else {
      this._appInsightsLoggingService.logEvent("Requesting Parent Season Data When Retrieval Mode Change", dataRequest);
      return this._seasonDataService.getParentSeasonData(dataRequest, isAssortmentView);
    }
  }

  private setupSeasonTable(): void {


    // quarters
    _.map(this.seasonInfo.quarters, quarter => {

      let colspan = (quarter.indexOf("Summer") != -1) ? 16 : 12;

      this.quarters.push({
        "name": quarter,
        "colspan": colspan
      });

    });

    // if the last quarter is summer, reduce 4 weeks from it
    if(this.quarters[this.quarters.length-1]["name"].indexOf("Summer") != -1)
    {
      this.quarters[this.quarters.length-1]["colspan"] = 12;
    }

    // this.quarters.push(
    //   {
    //     "name": "",
    //     "colspan": 4
    //   });


    this.isForecastEnabledList = [];

    this.isWeeklyInputEnabledList = [];

    // spring starts in the previous year
    // fall starts in the same year

    //Spring Season - Disabled till week 45 of previous year
    //Fall Season - Disabled till Week 17 of same year
    if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous) {
      if (this.seasonInfo.seasonType == SeasonType.Spring) {
        // get previous year number and append 45 to it
        this.disabledTillWeek = parseInt((parseInt(this.seasonInfo.seasonCodeNames[0].substring(0, 4)) - 1).toString() + "49");
      }
      else if (this.seasonInfo.seasonType == SeasonType.Fall) {
        // get same year number and append 16 to it
        this.disabledTillWeek = parseInt((parseInt(this.seasonInfo.seasonCodeNames[0].substring(0, 4))).toString() + "21");
      }
    }

    let viewPeriodWeeks = this.driver.getViewPeriodWeeksWithYear();
    // disable all periods before the current period
    for (let i = 0; i < this.seasonInfo.weeksWithYear.length; i++) {

      if (this.seasonInfo.weeksWithYear[i + 4] <= this.currentWeek) {
        this.isForecastEnabledList.push(false);
      }
      else if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this.seasonInfo.weeksWithYear[i + 4] <= this.disabledTillWeek) {
        this.isForecastEnabledList.push(false);
      }
      else {
        this.isForecastEnabledList.push(true);
      }

      // disable all weeks within the view period
      if (viewPeriodWeeks.indexOf(this.seasonInfo.weeksWithYear[i]) != -1  && viewPeriodWeeks.indexOf(this.seasonInfo.weeksWithYear[i]) > this.currentWeek) {
        this.isWeeklyInputEnabledList.push(false);
      }
      else {
        if (this.seasonInfo.weeksWithYear[i] < this.currentWeek) {
          this.isWeeklyInputEnabledList.push(false);
        }
        else if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this.seasonInfo.weeksWithYear[i] < this.disabledTillWeek) {
          this.isWeeklyInputEnabledList.push(false);
        }
        else {
          this.isWeeklyInputEnabledList.push(true);
        }
      }

    }

    //create a new list object and use to draw quarter in the season table
    this.quarters.forEach(quarter => {
      let length = quarter.colspan;
      let j = 1;
      for (let i = 0; i < length; i++) {
        this.quarterBorderForWeeks.push((i == length - 1) ? true : false);
        this.quarterBorderForCalcPeriods.push((i == length - 4) ? true : false);
        if (j % 4 == 0) {
          this.quarterBorderForPeriods.push((i == length - 1) ? true : false);
        }
        j++;
      }
    });
  }

  private setAvailableRetrievalModes(): void {

    const isTotalView = this.driver.getUserConfig().planningViewOptions.structureType == StructureTypes.Total;

    this.availableRetrievalModes = this._utils.getValidRetrievalModes(this.seasonInfo, isTotalView);

    // For total view always use default mode at load since that is the one used when generating total view
    if (isTotalView)
      this.driver.setRetrievalMode(this._utils.getDefaultRetrievalModeBySeasonPlanningType(this.seasonInfo.seasonPlanningType, this.isAssortmentView));
  }

  filterInputCharacters(event) {
    // allow only numbers
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else if (charCode.keyCode === 109 || charCode.keyCode === 189) {
      return true;
    }
    return true;
  }

  ngOnDestroy() {
    this.userConfigSubjectSubscription?.unsubscribe();
  }
}
