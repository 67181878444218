import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { AbstractCalculator } from "../abstract-calculator";
import { CalculatorDriverInterface } from "../calculator-driver-interface";

export class FunctionCalculator extends AbstractCalculator
{
    execute(driver: CalculatorDriverInterface)
    {
        this.functionBody(driver);       
    }
}