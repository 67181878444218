import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ValidationService } from 'src/app/modules/planning-view/services/validation-service';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { CacheService } from 'src/app/modules/shared/services/cache-service';
import { DialogBoxService } from 'src/app/modules/shared/services/dialog-box-service';
import { AppInsightsLoggingService } from 'src/app/modules/shared/services/error-handling/app-insights-logging.service';
import { FeatureFlagService } from 'src/app/modules/shared/services/feature-flag.service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { Constants } from 'src/app/modules/shared/types/constants';
import { CorporateBrand } from 'src/app/modules/shared/types/corporate-brand';
import { CustomerGroup } from 'src/app/modules/shared/types/customer-group';
import { Market } from 'src/app/modules/shared/types/market';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { Organization } from 'src/app/modules/shared/types/organization';
import { OrganizationType } from 'src/app/modules/shared/types/organization-type';
import { Section } from 'src/app/modules/shared/types/section';
import { SelectionViewOptions } from 'src/app/modules/shared/types/selection-view-options';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { UserInfo } from 'src/app/modules/shared/types/user-info';
import { UserSettingsService } from 'src/app/modules/user-settings/services/user-settings.service';
import { CopyService } from '../../services/copy.service';
import { SelectionPageService } from '../../services/selection-page.service';
import { AssortmentCopy } from '../../types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from '../../types/api/copy/get-all-copies/response/sales-copy';
import { RegionMapping, SelectionPageResponse } from '../../types/api/selection-page-response';
import { StructureType } from '../../types/structure-type';

@Component({
  selector: 'selection-view',
  templateUrl: './selection-view.component.html',
  styleUrls: ['./selection-view.component.css']
})
export class SelectionViewComponent implements OnInit {

  // data variables
  selectionPageResponseData: SelectionPageResponse;
  SelectionViewOptions: SelectionViewOptions;

  // ui variables
  pageState: string = "loading";
  selectionPageLoaded = false;
  userInfo: UserInfo = null;
  hasUserHasFunctionImpersonateUserAccess: boolean = false;
  userInfoInitialized: boolean = false;

  // dropdown variables
  availableBrands: CorporateBrand[] = [];
  availableOrganizations: Organization[] = [];
  availableSections: Section[] = [];
  availableAssortmentStructureTypes: StructureType[] = [];
  availableAssortmentStructureItems: Section[] | CustomerGroup[] | CorporateBrand[] = [];

  availableSalesStructureTypesList: StructureType[] = [];
  availableSalesMarkets: Market[] = [];

  // selection variables
  selectedBrand: CorporateBrand = null;
  selectedOrganization: Organization = null;
  selectedAssortmentStructureType: StructureType = null;
  selectedAssortmentStructure: Section | CustomerGroup | CorporateBrand = null; //section,customer,brand
  selectedSalesStructureTypesList: StructureType[] = null;
  selectedMarketList: Market[] = []; // selected items are returned as list from the dropdown

  salesCopyDialogMarkets: Market[]; // its using for create sales copy Dialog box, to select the region or pm or prpm

  selectedCopy: AssortmentCopy | SalesCopy = null;

  isCopyComponentReady: boolean = false;
  isSimulationViewEnabled: boolean = false;
  showOthersCopy: boolean = false;

  // to be loaded from feature flag service
  public get isSalesPercentPmFeatureEnabled(): boolean {
    let featureToggle = this._featureFlagService.getFeatureFlagByKey(Constants.FEATURE_FLAG_SALES_PERCENT_PM_SELECTION);
    if (this._utilsService.isNotNullOrUndefined(featureToggle)) {
      return featureToggle.isEnabled;
    }
    return false;
  };
  isButtonsDisabled: boolean = false;

  savedOptions: SelectionViewOptions = null;

  constructor(
    private _loadingAnimationService: LoadingAnimationsService,
    private _selectionPageService: SelectionPageService,
    private _router: Router,
    public _authService: AuthenticationService,
    private _userSettingsService: UserSettingsService,
    private _appInsightsLoggingService: AppInsightsLoggingService,
    private _dialogBoxService: DialogBoxService,
    private _featureFlagService: FeatureFlagService,
    private _utilsService: UtilsService,
    private _route: ActivatedRoute,
    private _copyService: CopyService,
    private _userConfigService: UserConfigService,
    private _validationService: ValidationService,
    private _cdRef: ChangeDetectorRef,
    private _cacheService: CacheService
  ) {}

  ngOnInit(): void {

    Promise.resolve().then(() => {
      this._loadingAnimationService.enableTopNavAnimation();
    });


    if (this._authService.isUserLoggedIn) {

      this._userSettingsService.userInfoSubject.subscribe((userInfo: UserInfo) => {
        if (userInfo && !this.userInfo) {
          this.userInfo = userInfo;
          this.hasUserHasFunctionImpersonateUserAccess = this._utilsService.doesUserHaveImpersonateUserFunctionAccess(userInfo);
          this.fetchSelectionPageData();
        }
      }, (err) => {
        this.pageState = "access-denied";
        this._loadingAnimationService.disableTopNavAnimation()
        return false;
      });
    }
  }

  async fetchSelectionPageData() {

    // load selection api response from backend
    // check if data is cached for the current session

    let cachedValue = this._cacheService.getCachedData<SelectionPageResponse>(CacheService.cacheKeys.selectionPageResponse);
    if (cachedValue != null) {
      this.selectionPageResponseData = cachedValue;
    }
    else {
      this.selectionPageResponseData = await this._selectionPageService.getSelectionPageData().toPromise();
      this._cacheService.setCachedData(CacheService.cacheKeys.selectionPageResponse, this.selectionPageResponseData);
    }

    // check for querystring params
    if (this._route.snapshot.queryParams['brand']) {
      this.handleQueryStringParams()
    }
    else {
      this.processResponseData();
    }

  }

  async handleQueryStringParams() {
    let autoSelectedOptions = await this._validationService.validateQueryStringParamsOnSelectionView(this.selectionPageResponseData, this._route.snapshot.queryParams);

    if (autoSelectedOptions != null) {
      // valid params
      this.pageState = "loaded";
      this._loadingAnimationService.disableTopNavAnimation();

      this.saveUserConfig(autoSelectedOptions);

      // move to planning view
      let queryParamsStr = "?";
      for (let key in this._route.snapshot.queryParams) {
        queryParamsStr += key + "=" + this._route.snapshot.queryParams[key] + "&";
      }

      queryParamsStr = queryParamsStr.slice(0, -1);

      this._router.navigateByUrl("/planning-view" + queryParamsStr, {
        state: autoSelectedOptions
      });
    }
    // invalid url params
    else {
      // reload
      this._router.navigate(['.'], { relativeTo: this._route, queryParams: {} });
      this.ngOnInit();
    }
  }


  processResponseData() {


    // get saved user config options to autopopulate the ui

    this._userConfigService.userConfigSubject.subscribe((userConfig) => {

      if (userConfig && !this.userInfoInitialized) {

        this.userInfoInitialized = true;

        this.savedOptions = userConfig.selectionViewOptions;


        // no access state
        // The user has no access, if
        // no organizations listed
        // no brands listed 
        if (
          this._utilsService.isNullOrUndefined(this.selectionPageResponseData.organizations) || this.selectionPageResponseData.organizations.length == 0 ||
          this._utilsService.isNullOrUndefined(this.selectionPageResponseData.brands) || this.selectionPageResponseData.brands.length == 0
        ) {
          this.pageState = "access-denied";
          this._loadingAnimationService.disableTopNavAnimation();
          return false;
        }
        else {
          // load dropdown values
          this.availableBrands = this.selectionPageResponseData.brands.slice();
          this.availableOrganizations = this.selectionPageResponseData.organizations.slice();
          this._loadDefaults();

          this.pageState = "loaded";
          this._loadingAnimationService.disableTopNavAnimation();
        }


      }
    });
  }

  private _loadDefaults() {
    // set default brand
    if (this.savedOptions && this.savedOptions.brand) {
      this.selectedBrand = this.availableBrands.find(x => x.id == this.savedOptions.brand.id);
    }

    if (this._utilsService.isNullOrUndefined(this.selectedBrand)) {
      this.selectedBrand = this.availableBrands[0];
    }

    // set default organization
    if (this.savedOptions && this.savedOptions.organization) {
      this.selectedOrganization = this.availableOrganizations.find(x => x.id == this.savedOptions.organization.id);
    }

    if (this._utilsService.isNullOrUndefined(this.selectedOrganization)) {
      this.selectedOrganization = this.availableOrganizations[0];
    }

    if (this.selectedOrganization.name == OrganizationType.Assortment) {
      // load structure types based on the brand selected
      this.availableAssortmentStructureTypes = this._selectionPageService.getAssortmentStructuresForBrand(this.selectionPageResponseData, this.selectedBrand);

      // set default structure type
      if (this.savedOptions && this.savedOptions.structureType) {
        this.selectedAssortmentStructureType = this.availableAssortmentStructureTypes.find(x => x.id == this.savedOptions.structureType.id)
      }

      if (this._utilsService.isNullOrUndefined(this.selectedAssortmentStructureType)) {
        this.selectedAssortmentStructureType = this.availableAssortmentStructureTypes[1];
      }

      // load structure items based on the structure type selected
      this.availableAssortmentStructureItems = this._selectionPageService.getAssortmentStructureItemsForStructureType(this.selectionPageResponseData, this.selectedBrand, this.selectedAssortmentStructureType);

      // load default structure
      if (this.savedOptions && this.savedOptions.structure) {
        for (let i = 0; i < this.availableAssortmentStructureItems.length; i++) {
          if (this.availableAssortmentStructureItems[i].id == this.savedOptions.structure.id) {
            this.selectedAssortmentStructure = this.availableAssortmentStructureItems[i];
          }
        }
      }

      if (this._utilsService.isNullOrUndefined(this.selectedAssortmentStructure)) {
        this.selectedAssortmentStructure = this.availableAssortmentStructureItems[0];
      }
    }
    else if (this.selectedOrganization.name == OrganizationType.Sales) {
      // load structure types based on the brand selected
      this.availableSalesStructureTypesList = this._selectionPageService.getSalesStructuresForBrand(this.selectionPageResponseData, this.selectedBrand);

      if (this.savedOptions && this.savedOptions.structureType) {
        let items = this.availableSalesStructureTypesList.find(x => x.id == this.savedOptions.structureType.id);
        if (items != undefined) {
          this.selectedSalesStructureTypesList = [items]
        }
      }

      if (this._utilsService.isNullOrUndefined(this.selectedSalesStructureTypesList)) {
        this.selectedSalesStructureTypesList = [this.availableSalesStructureTypesList[0]];
      }


      // load markets based on the brand selected
      this.availableSalesMarkets = this._selectionPageService.getSalesMarketsForBrand(this.selectionPageResponseData, this.selectedBrand, this.isSalesPercentPmFeatureEnabled);

      if (this.savedOptions && this.savedOptions.market) {
        let items = this.availableSalesMarkets.find(x => x.marketIntegrationKey == this.savedOptions.market.marketIntegrationKey);
        if (items != undefined) {
          this.selectedMarketList = [items]
        }
        else {
          this.selectedMarketList = (this.availableSalesMarkets && this.availableSalesMarkets.length > 0) ? [this.availableSalesMarkets[0]] : [];
        }
      }

      if (this._utilsService.isNullOrUndefined(this.selectedSalesStructureTypesList)) {
        this.selectedMarketList = (this.availableSalesMarkets && this.availableSalesMarkets.length > 0) ? [this.availableSalesMarkets[0]] : [];
      }

      this.marketChanged();
    }
  }

  // UI events
  brandUpdated() {
    this.organizationUpdated();
  }

  organizationUpdated() {
    if (this.selectedOrganization.name == OrganizationType.Assortment) {
      this.availableAssortmentStructureTypes = this._selectionPageService.getAssortmentStructuresForBrand(this.selectionPageResponseData, this.selectedBrand);
      this.selectedAssortmentStructureType = this.availableAssortmentStructureTypes[0];

      this.assortmentStructureTypeUpdated();
    }
    else if (this.selectedOrganization.name == OrganizationType.Sales) {
      this.availableSalesStructureTypesList = this._selectionPageService.getSalesStructuresForBrand(this.selectionPageResponseData, this.selectedBrand);
      this.selectedSalesStructureTypesList = [this.availableSalesStructureTypesList[0]];

      this.availableSalesMarkets = this._selectionPageService.getSalesMarketsForBrand(this.selectionPageResponseData, this.selectedBrand, this.isSalesPercentPmFeatureEnabled);

      this.selectedMarketList = (this.availableSalesMarkets && this.availableSalesMarkets.length > 0) ? [this.availableSalesMarkets[0]] : [];

      this.marketChanged();
    }
  }

  assortmentStructureTypeUpdated() {
    // load structure items based on the structure type selected
    this.availableAssortmentStructureItems = this._selectionPageService.getAssortmentStructureItemsForStructureType(this.selectionPageResponseData, this.selectedBrand, this.selectedAssortmentStructureType);
    this.selectedAssortmentStructure = this.availableAssortmentStructureItems[0];
  }

  setSelectedOrganization(organizationName: string) {
    if (this.selectedOrganization.name != organizationName) {
      this.selectedOrganization = this.availableOrganizations.find(org => org.name == organizationName);
    }
  }

  isOrganizationEnabled(organizationName: string): boolean {
    return this.availableOrganizations.filter(org => org.name == organizationName).length == 1;
  }

  getStructureNameForStructureType(): string {
    if (this.selectedAssortmentStructureType.name.startsWith(StructureTypes.Section)) {
      return StructureTypes.Section
    }
    else if (this.selectedAssortmentStructureType.name.startsWith(StructureTypes.CustomerGroup)) {
      return StructureTypes.CustomerGroup
    }
  }

  // Copy Component Events
  onCopyComponentReady(isReady: boolean) {
    this.isCopyComponentReady = isReady;
    this._cdRef.detectChanges();
  }

  onCopySelect(copy: AssortmentCopy | SalesCopy) {
    this.selectedCopy = copy;
  }

  onShowOthersCopySelectionChange(isShowOthersCopySelected: boolean) {
    this.showOthersCopy = isShowOthersCopySelected;
  }

  marketChanged() {

    if (this.selectedMarketList && this.selectedMarketList.length > 0) {
      let requiredsalesMarkets: Market[] = []
      if (this.selectedMarketList[0].marketType == MarketType.PlanningRegion) {

        //add region
        if (this.userInfo.gamPermissions && this._utilsService.isNotNullOrUndefined(this.selectedMarketList[0])) {
          if (this._utilsService.doesUserHavePrOrPmAccess(this.selectedMarketList[0], this.userInfo, MarketType.PlanningRegion, this.selectionPageResponseData.sales.regionMappings)) { //check by planning region
            requiredsalesMarkets.push(this.selectedMarketList[0]);
          }
        }

        let childMarkets = this.selectionPageResponseData.sales.regionMappings.find((regionMapping: RegionMapping) => regionMapping.region.marketIntegrationKey == this.selectedMarketList[0].marketIntegrationKey).childMarkets;

        //add markets
        if (childMarkets.length > 0) {
          childMarkets.forEach(market => {
            if (this.userInfo.gamPermissions && this._utilsService.isNotNullOrUndefined(market)) {
              if (this._utilsService.doesUserHavePrOrPmAccess(this.selectedMarketList[0], this.userInfo, MarketType.PlanningRegion, this.selectionPageResponseData.sales.regionMappings)) { //check by planning region
                requiredsalesMarkets.push(market);
              }
              else if (this._utilsService.doesUserHavePrOrPmAccess(market, this.userInfo, MarketType.PlanningMarket, this.selectionPageResponseData.sales.regionMappings)) { // check by planning market
                requiredsalesMarkets.push(market);
              }
            }
          });
        }
      }
      else if (this.selectedMarketList[0].marketType == MarketType.PlanningMarket) {
        requiredsalesMarkets.push(this.selectedMarketList[0]);
      }
      else if (this.selectedMarketList[0].marketType == MarketType.PercentPlanningMarket) {
        requiredsalesMarkets.push(this.selectedMarketList[0]);
      }
      this.salesCopyDialogMarkets = requiredsalesMarkets;
    }
  }

  percentPmSelectionUpdated() {

    this.organizationUpdated();
  }

  validateForm() {

    // check if a brand is selected
    if (this.selectedBrand == null) {
      this._dialogBoxService.showMessage("Please select a Brand");
      return false;
    }

    if (this.selectedOrganization.name == OrganizationType.Assortment) {
      // check structure
      if (this.selectedAssortmentStructureType == null) {
        this._dialogBoxService.showMessage("Please select a Structure");
        return false;
      }

      this.isSimulationViewEnabled = this.selectedAssortmentStructureType.name.startsWith(StructureTypes.Section) ? false : true;

      // check Section/Customer Group
      if (this.selectedAssortmentStructureType && this._utilsService.isNullOrUndefined(this.selectedAssortmentStructure)) {
        if (this.selectedAssortmentStructureType.name.startsWith(StructureTypes.Section)) {
          this._dialogBoxService.showMessage("Please select a Section");
        }
        else if (this.selectedAssortmentStructureType.name.startsWith(StructureTypes.CustomerGroup)) {
          this._dialogBoxService.showMessage("Please select a Customer Group");
        }
        return false;
      }

      if (this.isSimulationViewEnabled && this.selectedCopy != null && this.selectedCopy.isMain) {
        this._dialogBoxService.showMessage("Please select a copy");
        return false;
      }
    }
    else if (this.selectedOrganization.name == OrganizationType.Sales) {
      this.isSimulationViewEnabled = false;

      // Region
      if (this.selectedMarketList == null || this.selectedMarketList.length == 0) {
        this._dialogBoxService.showMessage("Please select a Market");
        return false;
      }

      if (this.selectedSalesStructureTypesList == null || this.selectedSalesStructureTypesList.length == 0) {
        this._dialogBoxService.showMessage("Please select a Structure");
        return false;
      }

      //check if copy selected
      if (this.selectedCopy == null) {
        this._dialogBoxService.showMessage("Please select a copy");
        return false;
      }

      // as a Sales PM user , you should not be able to open main and region level copy
      if (this.userInfo.gamPermissions && this.selectedMarketList &&
        ((<SalesCopy>this.selectedCopy).isPRCopy || this.selectedCopy.isMain)) {
        if (this._utilsService.doesUserHavePrOrPmAccess(this.selectedMarketList[0], this.userInfo, MarketType.PlanningRegion, this.selectionPageResponseData.sales.regionMappings)) {
          return true;
        }
        else if (this.selectedCopy.isMain && !(<SalesCopy>this.selectedCopy).isPRCopy &&
          this._utilsService.doesUserHavePmAccess(<SalesCopy>this.selectedCopy, this.userInfo)) {// else check that user has pm level access for that particular copy
          return true;
        }
        else if (((<SalesCopy>this.selectedCopy).pmId == null && (<SalesCopy>this.selectedCopy).marketType == MarketType.PlanningMarket) &&
          this._utilsService.doesUserHavePmAccess(<SalesCopy>this.selectedCopy, this.userInfo)) {// else check that user has pm level access for that particular copy
          return true;
        }
        else {
          this._dialogBoxService.showMessage("You don't have permission to open");
          return false;
        }
      }

      // check the access at the pm level in the selected region when opening the copy
      if (this.userInfo.gamPermissions && this.selectedMarketList && (<SalesCopy>this.selectedCopy).pmId != null && (<SalesCopy>this.selectedCopy).pmId != undefined) {
        if (this._utilsService.doesUserHavePrOrPmAccess(this.selectedMarketList[0], this.userInfo, MarketType.PlanningRegion, this.selectionPageResponseData.sales.regionMappings)) { //check that user has region level access to selected region if its not a pr copy
          return true;
        }
        else if (this._utilsService.doesUserHavePmAccess(<SalesCopy>this.selectedCopy, this.userInfo)) {// else check that user has pm level access for that particular copy
          return true;
        }
        else {
          this._dialogBoxService.showMessage("You dont have permission to open");
          return false;
        }
      }

    }
    return true;

  }



  submitForm() {
    if (this.validateForm()) {

      this.saveUserConfig();

      this._router.navigateByUrl("/planning-view", {
        state: this.SelectionViewOptions
      });

      this._appInsightsLoggingService.logEvent("Open Planning View clicked", this.SelectionViewOptions);
    }
  }

  // copyDataLoadingChange(loaded) {
  //   this.isCopyComponentReady = loaded
  // }

  // selectedCopyChange(copy) {
  //   this.selectedCopy = copy
  // }

  // showOthersCopyChange(value) {
  //   this.showOthersCopy = value
  // }

  // copyActionButtonsStatus(value) {
  //   this.isButtonsDisabled = value
  // }

  private saveUserConfig(selectionViewOptions?: SelectionViewOptions) {

    if (selectionViewOptions) {
      this._userConfigService.setSelectionViewOptions(selectionViewOptions);
    }
    else {
      if (this.selectedOrganization.name == OrganizationType.Assortment) {

        let selectedOrganization: Organization = this.selectionPageResponseData.organizations.filter((organization: Organization) => organization.name == "Assortment")[0];
        this.SelectionViewOptions = {
          brand: this.selectedBrand,
          organization: selectedOrganization,
          structure: this.selectedAssortmentStructure, // customer group
          structureType: this.selectedAssortmentStructureType,
          copy: this.selectedCopy
        }
      }
      else if (this.selectedOrganization.name == OrganizationType.Sales) {

        let selectedOrganization: Organization = this.selectionPageResponseData.organizations.filter((organization: Organization) => organization.name == "Sales")[0];

        this.SelectionViewOptions = {
          brand: this.selectedBrand,
          organization: selectedOrganization,
          structureType: this.selectedSalesStructureTypesList[0],
          copy: this.selectedCopy
        }

        this.SelectionViewOptions.market = this.selectedMarketList[0];
      }

      this.SelectionViewOptions.showOthersCopy = this.showOthersCopy;

      this._userConfigService.setSelectionViewOptions(this.SelectionViewOptions);
    }

    this._userConfigService.setIsShowDeveloperExceptionPopupEnabled(this.hasUserHasFunctionImpersonateUserAccess);
  }
}
