import { ReturnDistribution } from "../../shared/types/season-data-item";

export class ReturnsInfo
{
    seasonName: string;
    unAdjustedReturnPercent?: number;
    adjustedReturnPercent?: number;
    unAdjustedBudgetedReturnPercent?: number;
    adjustedBudgetedReturnPercent?: number;

    departmentIntegrationKey?: number;
    marketIntegrationKey?: number;
    marketType?: number;
    returnDistribution?: ReturnDistribution;
    returnFactorPercent?: number;
    sumOfBudgetedReturns?: number;
    sumOfDemDelFixed?: number;
    sumOfDemDelPlan?: number;
    sumOfReturnFixed?: number;
    sumOfReturnPlan?: number;

    sumOfDemDelPlanBeforeKPeriod?: number;
    sumOfUnAdjustedBudgetedReturns?: number
}