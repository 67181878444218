import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { ParentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/parent-calculator-driver-interface';
import { ReadonlyDepartmentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/readonly-department-calculator-driver-interface';
import { FeatureFlagService } from 'src/app/modules/shared/services/feature-flag.service';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { Constants } from 'src/app/modules/shared/types/constants';
import { RetrievalMode } from 'src/app/modules/shared/types/retrieval-mode';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { CalculationDataItemType } from '../../types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from '../../types/department-calculation-data-item';
import { ParentCalculationDataItem } from '../../types/parent-calculation-data-item';
import { SimulationViewSeasonIndex } from '../../types/simulation-view-season-index';
import { SimulationViewSeasonMonetaryValues } from '../../types/simulation-view-season-monetary-values';
import { SummaryViewSelection } from '../../types/summary-view-selection';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { SeasonSetting } from 'src/app/modules/shared/types/user-config';

@Component({
  selector: 'app-simulation-view',
  templateUrl: './simulation-view.component.html',
  styleUrls: ['./simulation-view.component.css']
})
export class SimulationViewComponent implements OnInit {

  selectedStartWeek: number = null;
  selectedEndWeek: number = null;

  customSelectStartWeek: number = null;
  customSelectEndWeek: number = null;

  orderIdxStartWeek: number = null;
  orderIdxEndWeek: number = null;

  fullWeeksList: number[];
  startweeksList: number[];
  endweeksList: number[];

  math = Math;
  seasonInfo: SeasonInfo;

  isOnline: boolean = null;

  @Input() PreviousSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() ActualSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() ComingSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() FutureSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
  @Input() updateSubject: BehaviorSubject<boolean>;

  @Input() TotalSeasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;

  @Output() summarySelectionEvent = new EventEmitter<SummaryViewSelection>();

  seasonIndexDataSet: SimulationViewSeasonIndex[] = [];
  seasonMonetaryValuesDataSet: SimulationViewSeasonMonetaryValues[] = [];

  totalSeasonMonetaryValuesDataSet: SimulationViewSeasonMonetaryValues = null;
  userConfigSubjectSubscription: Subscription;
  selectedRetrievalModeText: string = "";

  constructor(
    private ref: ChangeDetectorRef,
    public _utils: UtilsService,
    private _userConfigService: UserConfigService) {
  }

  validateCustomStartWeek(event)
  {
    this.customSelectStartWeek =  event?.target?.value? Number.parseInt(event.target.value): this.customSelectStartWeek;
    if(this.startweeksList.indexOf(this.customSelectStartWeek) != -1)
    {
      this.selectedStartWeek = this.customSelectStartWeek;
      this.updateEndWeeks(); 
      this.weekSelectionChanged(); 
      this.emitSelectionChangedEvent();
    }
    else
    {
      this.customSelectStartWeek = this.selectedStartWeek;
    }
  }

  validateCustomEndWeek()
  {
    if(this.endweeksList.indexOf(this.customSelectEndWeek) != -1)
    {
      this.selectedEndWeek = this.customSelectEndWeek;
      this.updateEndWeeks(); 
      this.weekSelectionChanged(); 
      this.emitSelectionChangedEvent();
    }
    else
    {
      this.customSelectEndWeek = this.selectedEndWeek;
    }
  }

  ngOnInit(): void {

    this.seasonInfo = this.ActualSeasonDriver.getSeasonInfo();

    this.fullWeeksList = this.seasonInfo.weeksWithYear;

    this.startweeksList = this.fullWeeksList.slice(0, this.seasonInfo.weeksWithYear.length - 1);

    this.updateEndWeeks();

    let userConfig = this._userConfigService.getUserConfig();

    this.userConfigSubjectSubscription = this._userConfigService.userConfigSubject.subscribe(userConfig => {
      let selectedStructureType = userConfig?.planningViewOptions?.structureType;
      let isTotalStructureTypeSelected = selectedStructureType != null && selectedStructureType == StructureTypes.Total;

      if(userConfig) {
        if (isTotalStructureTypeSelected && userConfig?.planningViewLayoutSettings?.totalSeasonSettings) {
          this.setSelectedRetrivelModeText(userConfig.planningViewLayoutSettings.totalSeasonSettings);
        }
        else if(userConfig?.planningViewLayoutSettings?.seasonSettings) {
          this.setSelectedRetrivelModeText(userConfig.planningViewLayoutSettings.seasonSettings);
        }
      }
    });


    if (
      this._utils.isNotNullOrUndefined(userConfig.planningViewLayoutSettings.summaryViewSettings.startWeek) &&
      this._utils.isNotNullOrUndefined(userConfig.planningViewLayoutSettings.summaryViewSettings.endWeek)
    ) {
      // validate the start and end weeks
      let validWeekSelection = true;

      // 1. end week should be greater than start week
      if (userConfig.planningViewLayoutSettings.summaryViewSettings.startWeek >= userConfig.planningViewLayoutSettings.summaryViewSettings.endWeek) {
        validWeekSelection = false;
      }

      // 2. start week and end week should be within the full weeks list
      if (this.fullWeeksList.indexOf(userConfig.planningViewLayoutSettings.summaryViewSettings.startWeek) == -1 || this.fullWeeksList.indexOf(userConfig.planningViewLayoutSettings.summaryViewSettings.endWeek) == -1) {
        validWeekSelection = false;
      }

      if (validWeekSelection) {
        this.selectedStartWeek = userConfig.planningViewLayoutSettings.summaryViewSettings.startWeek;
        this.selectedEndWeek = userConfig.planningViewLayoutSettings.summaryViewSettings.endWeek;
      }
      else {
        this.selectedStartWeek = this.fullWeeksList[0];
        this.selectedEndWeek = this.fullWeeksList[3];
        this.summarySelectionEvent.emit({
          startWeek: this.selectedStartWeek,
          endWeek: this.selectedEndWeek
        });
      }
    }
    else {
      this.selectedStartWeek = this.fullWeeksList[0];
      this.selectedEndWeek = this.fullWeeksList[3];
      this.summarySelectionEvent.emit({
        startWeek: this.selectedStartWeek,
        endWeek: this.selectedEndWeek
      });
    }

    this.updateSubject.subscribe(() => {
      this.updateData();
    });

    this.userConfigSubjectSubscription = this._userConfigService.userConfigSubject.subscribe(userConfig => {

      // check if its not null
      if (userConfig && userConfig?.planningViewLayoutSettings?.summaryViewSettings) {

        let userConfig = this._userConfigService.getUserConfig();
        if (userConfig?.planningViewLayoutSettings?.summaryViewSettings?.startWeek && userConfig?.planningViewLayoutSettings?.summaryViewSettings?.endWeek) {
          this.selectedStartWeek = userConfig?.planningViewLayoutSettings?.summaryViewSettings?.startWeek;
          this.selectedEndWeek = userConfig?.planningViewLayoutSettings?.summaryViewSettings?.endWeek;
        }
        else {
          this.selectedStartWeek = this.fullWeeksList[0];
          this.selectedEndWeek = this.fullWeeksList[3];
          this.summarySelectionEvent.emit({
            startWeek: this.selectedStartWeek,
            endWeek: this.selectedEndWeek
          });
        }
        this.ref.detectChanges();
        this.weekSelectionChanged()

      }
    })

    this.isOnline = this.ActualSeasonDriver.getUserConfig().planningViewOptions.channel == ChannelType.Online;

    this.weekSelectionChanged();

    this._utils.adjustScreenDimensions();

    this.presetCustomWeekSelections();

  }

  private setSelectedRetrivelModeText(seasonSettings: SeasonSetting[]) {
    this.selectedRetrievalModeText = "Selected retrieval modes are ";

    seasonSettings.forEach(seasonSetting => {
      if (seasonSetting.seasonPlanningType == SeasonPlanningType.PreviousWithOld || seasonSetting.seasonPlanningType == SeasonPlanningType.Previous) {
        this.selectedRetrievalModeText += 'Previous&Old' + ':' + seasonSetting.retrievalMode + ', ';
      }
      else {
        this.selectedRetrievalModeText += seasonSetting.seasonPlanningType + ':' + seasonSetting.retrievalMode + ', ';
      }
    });
    
    this.selectedRetrievalModeText = this.selectedRetrievalModeText.slice(0, -2);
  };

  calculateDataSets() {
    let seasonMonetaryValues: SimulationViewSeasonMonetaryValues;
    let seasonIndexValues: SimulationViewSeasonIndex;

    this.seasonMonetaryValuesDataSet = [];
    this.seasonIndexDataSet = [];

    let driverSet = [this.PreviousSeasonDriver, this.ActualSeasonDriver, this.ComingSeasonDriver, this.FutureSeasonDriver, this.TotalSeasonDriver];


    driverSet.forEach(driver => {
      if (driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Total) {
        seasonMonetaryValues = this.calculateMonetaryValues(driver);
        this.seasonMonetaryValuesDataSet.push(seasonMonetaryValues);
      }
    });

    // calculate total season monetary values
    this.totalSeasonMonetaryValuesDataSet = this.calculateTotalMonetaryValues(this.seasonMonetaryValuesDataSet);
    this.seasonMonetaryValuesDataSet.push(this.totalSeasonMonetaryValuesDataSet);

    driverSet.forEach((driver, index) => {
      seasonIndexValues = this.calculateIndexValues(driver, this.seasonMonetaryValuesDataSet[index]);
      this.seasonIndexDataSet.push(seasonIndexValues);
    });



    // calculate sob %
    this.calculateSOB();

  }
  calculateTotalMonetaryValues(seasonMonetaryValuesDataSet: SimulationViewSeasonMonetaryValues[]): SimulationViewSeasonMonetaryValues {
    return {
      seasonName: "All Seasons",
      salesDemdelGrossActual: this.reduceDataSet(seasonMonetaryValuesDataSet, "salesDemdelGrossActual"),
      salesDemdelGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "salesDemdelGround"),
      salesDemdelNetTy: this.reduceDataSet(seasonMonetaryValuesDataSet, "salesDemdelNetTy"),
      salesDemdelNetGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "salesDemdelNetGround"),
      stockGrossOutgoingTy: this.reduceDataSet(seasonMonetaryValuesDataSet, "stockGrossOutgoingTy"),
      stockGrossOutgoingGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "stockGrossOutgoingGround"),
      stockGrossIngoingTy: this.reduceDataSet(seasonMonetaryValuesDataSet, "stockGrossIngoingTy"),
      stockGrossIngoingGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "stockGrossIngoingGround"),
      accStockGross: this.reduceDataSet(seasonMonetaryValuesDataSet, "accStockGross"),
      accStockGrossGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "accStockGrossGround"),

      red: this.reduceDataSet(seasonMonetaryValuesDataSet, "red"),
      redGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "redGround"),
      orderedPlanned: this.reduceDataSet(seasonMonetaryValuesDataSet, "orderedPlanned"),
      orderedGround: this.reduceDataSet(seasonMonetaryValuesDataSet, "orderedGround"),
      returns: this.reduceDataSet(seasonMonetaryValuesDataSet, "returns"),
    }
  }

  calculateSOB() {
    // fetch the monetary values data set for the total

    let totalMonetaryValuesDataSet = _.find(this.seasonMonetaryValuesDataSet, dataset => {
      return dataset.seasonName == "All Seasons"
    });

    for (let i = 0; i < this.seasonIndexDataSet.length; i++) {

      // get corresponding monetary dataset and compare with total monetary values dataset      

      // sales SOB Percent      
      this.seasonIndexDataSet[i].salesSOBPercent = this._utils.safeDivide(this.seasonMonetaryValuesDataSet[i].salesDemdelGrossActual, totalMonetaryValuesDataSet.salesDemdelGrossActual, true) * 100;

      // sales SOB Percent Ground
      this.seasonIndexDataSet[i].salesSOBPercentGround = this._utils.safeDivide(this.seasonMonetaryValuesDataSet[i].salesDemdelGround, totalMonetaryValuesDataSet.salesDemdelGround, true) * 100;

      // stock SOB Percent      
      this.seasonIndexDataSet[i].stockSOBPercent = this._utils.safeDivide(this.seasonMonetaryValuesDataSet[i].stockGrossOutgoingTy, totalMonetaryValuesDataSet.stockGrossOutgoingTy, true) * 100;

      // stock SOB Percent Ground
      this.seasonIndexDataSet[i].stockSOBPercentGround = this._utils.safeDivide(this.seasonMonetaryValuesDataSet[i].stockGrossOutgoingGround, totalMonetaryValuesDataSet.stockGrossOutgoingGround, true) * 100;

      this.seasonIndexDataSet[i] = this.cleanValues(this.seasonIndexDataSet[i]);
    }

  }

  calculateMonetaryValues(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface): SimulationViewSeasonMonetaryValues {

    let seasonMonetaryValues: SimulationViewSeasonMonetaryValues =
    {
      seasonName: driver.getTitle(driver.getUserConfig().planningViewOptions.areOldSeasonsExcluded)
    }

    let rangeDataSet = driver.getDataSetRange(this.selectedStartWeek, this.selectedEndWeek);
    let fullDataSet = driver.getDataSet();
    let firstItemInDataSet = fullDataSet[0]

    let retrievalMode: RetrievalMode = driver.getRetrievalMode();
    let startWeekForCurrentSeason = this._utils.getStartWeekForSeason(parseInt(driver.getSeasonInfo().seasonCodeNames[0]));
    let seasonPlanningType: SeasonPlanningType = driver.getSeasonInfo().seasonPlanningType;

    seasonMonetaryValues.salesDemdelGrossActual = this.reduceDataSet(rangeDataSet, CalculationDataItemType.CombinedSalesPlanWeekly);
    seasonMonetaryValues.salesDemdelGround = this.reduceDataSet(rangeDataSet, CalculationDataItemType.GrossSalesGround);
    if ((seasonPlanningType == SeasonPlanningType.Coming || seasonPlanningType == SeasonPlanningType.Future) && this.selectedStartWeek <= startWeekForCurrentSeason && rangeDataSet.some(x => x[CalculationDataItemType.StartWeekAfterCurrentWeek]))
      seasonMonetaryValues.salesDemdelGroundStartWeek = this.reduceDataSet(rangeDataSet, CalculationDataItemType.StartWeekSalesGrossGround);
    seasonMonetaryValues.salesDemdelNetTy = this.reduceDataSet(rangeDataSet, CalculationDataItemType.CombinedNetSalesWeekly);
    seasonMonetaryValues.salesDemdelNetGround = this.reduceDataSet(rangeDataSet, CalculationDataItemType.NetSalesGround);

    // Stock gross outgoing TY : As per excel formulae
    // Previous and Old:
    //     Store/Online: Outgoing Stock prognosis of the selected end week
    // Actual:
    //     Store: 
    //             Bought/Initial: Outgoing Stock prognosis of the selected end week
    //             Plan: 
    //                     Stock Plan Fixed of the selected end week +
    //                     Sum of all add removes till selected end week +
    //                     Sum of all add moves till selected end week +
    //                     Sum of cube dem del plan till selected end week -
    //                     Sum of combined dem del plan till selected end week // different from other formulae
    //     Online:
    //             Bought/Initial: Outgoing Stock prognosis of the selected end week
    //             Plan: 
    //                     Stock Plan Fixed of the selected end week +
    //                     Sum of all add removes till selected end week +
    //                     Sum of all add moves till selected end week +
    //                     Sum of cube dem del plan till selected end week -
    //                     Sum of sales forecast till selected end week
    // Coming:
    //     Store/Online: 
    //             Bought/Initial: Outgoing Stock prognosis of the selected end week
    //             Plan: 
    //                     Stock Plan Fixed of the selected end week +
    //                     Sum of all add removes till selected end week +
    //                     Sum of all add moves till selected end week +
    //                     Sum of cube dem del plan till selected end week -
    //                     Sum of sales forecast till selected end week

    let selectedEndWeekDataItem = rangeDataSet[rangeDataSet.length - 1];

    if (
      seasonPlanningType == SeasonPlanningType.PreviousWithOld
      ||
      seasonPlanningType == SeasonPlanningType.Previous
      ||
      retrievalMode == RetrievalMode.Bought || retrievalMode == RetrievalMode.Initial // irrespective of season planning type
    ) {
      seasonMonetaryValues.stockGrossOutgoingTy = selectedEndWeekDataItem ? selectedEndWeekDataItem[CalculationDataItemType.OutgoingStockPrognosisWeekly] : 0;
    }
    else if (retrievalMode == RetrievalMode.Plan) {
      // all week item data till the selected end week
      let tillSelectedLastWeekDataSet = driver.getDataSetRange(firstItemInDataSet[CalculationDataItemType.WeekName], this.selectedEndWeek);

      // a -> Stock plan fixed of last week 
      let lastWeekStockPlan = selectedEndWeekDataItem ? selectedEndWeekDataItem[CalculationDataItemType.StockPlanFixed] : 0;

      // b -> Sum of Add Remove till selected end week
      let addRemoveSum = (this.reduceDataSet(tillSelectedLastWeekDataSet, CalculationDataItemType.AddRemoveMSek) * Constants.TSEKTOMSEK);

      // c -> Sum of Add Move till selected end week
      let addMoveSum = (this.reduceDataSet(tillSelectedLastWeekDataSet, CalculationDataItemType.AddMovesMSek) * Constants.TSEKTOMSEK);

      // d -> Sum of sales plan till selected end week
      let salesPlanSum = this.reduceDataSet(tillSelectedLastWeekDataSet, CalculationDataItemType.CubeDemDelPlan);

      // e -> Sum of combined sales plan till selected end week
      let combinedSalesPlanSum = this.reduceDataSet(tillSelectedLastWeekDataSet, CalculationDataItemType.CombinedSalesPlanWeekly);

      // f -> Sum of sales forecast till selected end week
      let salesForecastSum = this.reduceDataSet(tillSelectedLastWeekDataSet, CalculationDataItemType.SalesForecastWeekly);

      if (seasonPlanningType == SeasonPlanningType.Actual) {
        // Stock Gross Outgoing TY = a + b + c + d - e
        seasonMonetaryValues.stockGrossOutgoingTy = lastWeekStockPlan + addRemoveSum + addMoveSum + salesPlanSum - combinedSalesPlanSum;
      }
      else {
        // Stock Gross Outgoing TY = a + b + c + d - f
        seasonMonetaryValues.stockGrossOutgoingTy = lastWeekStockPlan + addRemoveSum + addMoveSum + salesPlanSum - salesForecastSum;
      }
    }

    var stockGround = selectedEndWeekDataItem ? selectedEndWeekDataItem[CalculationDataItemType.StockGround] : 0;
    var stockPrognosisLy = selectedEndWeekDataItem ? selectedEndWeekDataItem[CalculationDataItemType.StockPrognosisLy] : 0;

    seasonMonetaryValues.stockGrossOutgoingGround = this._utils.isNotNullUndefinedOrZero(stockGround) ? stockGround : stockPrognosisLy;

    let selectedStartWeekIndex = _.findIndex(fullDataSet, w => w.weekName == this.selectedStartWeek);

    if (selectedStartWeekIndex != 0) {

      seasonMonetaryValues.stockGrossIngoingTy = fullDataSet[selectedStartWeekIndex - 1][CalculationDataItemType.EffectiveOutgoingStockPrognosis];
      seasonMonetaryValues.stockGrossIngoingGround = this._utils.isNotNullUndefinedOrZero(fullDataSet[selectedStartWeekIndex - 1][CalculationDataItemType.StockGround]) ? fullDataSet[selectedStartWeekIndex - 1][CalculationDataItemType.StockGround] : fullDataSet[selectedStartWeekIndex - 1][CalculationDataItemType.StockPrognosisLy];
    }
    else {
      seasonMonetaryValues.stockGrossIngoingTy = 0;
      seasonMonetaryValues.stockGrossIngoingGround = 0;
    }

    if (rangeDataSet.length != 0) {
      seasonMonetaryValues.accStockGross = this.reduceDataSet(rangeDataSet, CalculationDataItemType.EffectiveOutgoingStockPrognosis);

      let accStockGrossGround = 0;
      let accStockPrognosisLy = 0;

      rangeDataSet.forEach(datasetItem => {
        const stockPrognosisLy = datasetItem[CalculationDataItemType.StockPrognosisLy] || 0;
        accStockGrossGround += this._utils.isNotNullUndefinedOrZero(datasetItem[CalculationDataItemType.StockGround]) ? datasetItem[CalculationDataItemType.StockGround] : stockPrognosisLy;
        accStockPrognosisLy += stockPrognosisLy;
      });

      seasonMonetaryValues.accStockGrossGround = accStockGrossGround;
      seasonMonetaryValues.accStockPrognosisLy = accStockPrognosisLy;
    }
    else {
      seasonMonetaryValues.accStockGross = 0;
      seasonMonetaryValues.accStockGrossGround = 0;
      seasonMonetaryValues.accStockPrognosisLy = 0;
    }

    seasonMonetaryValues.red = this.reduceDataSet(rangeDataSet, CalculationDataItemType.CombinedRAndDWeekly);

    let salesGrossLySum = this.reduceDataSet(rangeDataSet, CalculationDataItemType.GrossSalesLy);
    seasonMonetaryValues.redGround = this._utils.safeDivide(seasonMonetaryValues.salesDemdelGround, salesGrossLySum) * this.reduceDataSet(rangeDataSet, CalculationDataItemType.RAndDLy);

    let shiftedRangeDataSet = driver.getDataSetRange(this.orderIdxStartWeek, this.orderIdxEndWeek);

    seasonMonetaryValues.orderedPlanned = this.reduceDataSet(shiftedRangeDataSet, CalculationDataItemType.BuyingWeekly);

    seasonMonetaryValues.orderedGround = this.reduceDataSet(shiftedRangeDataSet, CalculationDataItemType.OrderedGrossGround);

    if (driver.getUserConfig().planningViewOptions.channel == ChannelType.Store) {
      seasonMonetaryValues.returns = 0;
    }
    else {
      let returnsSum = 0;

      let currentWeek = driver.getViewWeekWithYear();

      //Combined Returns = If Returns gross is available, use Returns gross, else, use planned returns
      _.map(rangeDataSet, item => {
        if (item[CalculationDataItemType.WeekName] < currentWeek) {
          returnsSum += item[CalculationDataItemType.ReturnsGross];
        }
        else {
          returnsSum += item[CalculationDataItemType.PlannedReturn];
        }
      });
      seasonMonetaryValues.returns = returnsSum

    }




    return this.cleanValues(seasonMonetaryValues);
  }

  calculateIndexValues(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface, seasonMonetaryValues: SimulationViewSeasonMonetaryValues): SimulationViewSeasonIndex {

    let seasonIndexValues: SimulationViewSeasonIndex =
    {
      seasonName: driver.getTitle(driver.getUserConfig().planningViewOptions.areOldSeasonsExcluded)
    }

    let rangeDataSet = driver.getDataSetRange(this.selectedStartWeek, this.selectedEndWeek);

    let combinedSalesPlanSum = this.reduceDataSet(rangeDataSet, CalculationDataItemType.CombinedSalesPlanWeekly);
    let publishedDemdelPlanSum = this.reduceDataSet(rangeDataSet, CalculationDataItemType.CubeDemDelPlan);
    let stockToUseForAvgSGround = seasonMonetaryValues.accStockGrossGround;

    // for previous and old, if cube plan is zero, ground is considered
    if (driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.PreviousWithOld || driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Previous) {
      publishedDemdelPlanSum = 0;
      rangeDataSet.forEach(item => {
        if (this._utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.CubeDemDelPlan])) {
          publishedDemdelPlanSum += item[CalculationDataItemType.CubeDemDelPlan];
        }
        else if (this._utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.GrossSalesGround])) {
          publishedDemdelPlanSum += item[CalculationDataItemType.GrossSalesGround];
        }
      });
    }

    seasonIndexValues.grossTakt = this._utils.safeDivide(seasonMonetaryValues.salesDemdelGrossActual, (seasonMonetaryValues.salesDemdelGround + (seasonMonetaryValues.salesDemdelGroundStartWeek || 0))) * 100;
    let netTakt = this._utils.safeDivide(seasonMonetaryValues.salesDemdelNetTy, seasonMonetaryValues.salesDemdelNetGround) * 100;
    seasonIndexValues.netTakt = this._utils.isNotNullUndefinedOrZero(netTakt) ? netTakt : 100;
    seasonIndexValues.diffVsPlan = combinedSalesPlanSum - publishedDemdelPlanSum;
    seasonIndexValues.ingoingStockIndex = this._utils.safeDivide(seasonMonetaryValues.stockGrossIngoingTy, seasonMonetaryValues.stockGrossIngoingGround) * 100;
    seasonIndexValues.averageStockIndex = this._utils.safeDivide(seasonMonetaryValues.accStockGross, seasonMonetaryValues.accStockGrossGround) * 100;
    seasonIndexValues.outgoingStockIndex = this._utils.safeDivide(seasonMonetaryValues.stockGrossOutgoingTy, seasonMonetaryValues.stockGrossOutgoingGround) * 100;
    seasonIndexValues.averageSPercent = this._utils.safeDivide(seasonMonetaryValues.salesDemdelGrossActual, (seasonMonetaryValues.salesDemdelGrossActual + seasonMonetaryValues.accStockGross)) * 100;
    seasonIndexValues.averageSPercentGround = this._utils.safeDivide(seasonMonetaryValues.salesDemdelGround, (seasonMonetaryValues.salesDemdelGround + stockToUseForAvgSGround)) * 100;
    seasonIndexValues.redVsLy = this._utils.safeDivide(seasonMonetaryValues.red, seasonMonetaryValues.redGround) * 100;
    seasonIndexValues.redPercent = this._utils.safeDivide(seasonMonetaryValues.red, seasonMonetaryValues.salesDemdelGrossActual) * 100;
    seasonIndexValues.redPercentGround = this._utils.safeDivide(seasonMonetaryValues.redGround, seasonMonetaryValues.salesDemdelGround) * 100;
    seasonIndexValues.orderedIndex = this._utils.safeDivide(seasonMonetaryValues.orderedPlanned, seasonMonetaryValues.orderedGround) * 100;
    seasonIndexValues.returnPercent = this._utils.safeDivide(seasonMonetaryValues.returns, seasonMonetaryValues.salesDemdelGrossActual) * 100;

    return this.cleanValues(seasonIndexValues);
  }

  updateOrderedIndexRange() {

    this.orderIdxStartWeek = this._utils.shiftWeeks(this.selectedStartWeek, -4);
    this.orderIdxEndWeek = this._utils.shiftWeeks(this.selectedEndWeek, -4);

  }


  // method called when the user config subject receives an update	
  updateData() {
    this.updateEndWeeks();
    // check user config to see if start and end weeks are set	
    let userConfig = this._userConfigService.getUserConfig();
    if (userConfig?.planningViewLayoutSettings?.summaryViewSettings?.startWeek && userConfig?.planningViewLayoutSettings?.summaryViewSettings?.endWeek) {
      this.selectedStartWeek = userConfig?.planningViewLayoutSettings?.summaryViewSettings?.startWeek;
      this.selectedEndWeek = userConfig?.planningViewLayoutSettings?.summaryViewSettings?.endWeek;
    }

    this.ref.detectChanges();
    this.weekSelectionChanged()

  }


  // whenever either start week or end week have changed 	
  weekSelectionChanged() {
    this.updateEndWeeks();

    this.updateOrderedIndexRange();
    this.calculateDataSets();
  }

  reduceDataSet(dataset: DepartmentCalculationDataItem[] | ParentCalculationDataItem[] | SimulationViewSeasonMonetaryValues[], keyName: string): number {
    let sumValue = 0;

    dataset.forEach(datasetItem => {
      sumValue += datasetItem[keyName];
    });

    return sumValue;

  }

  cleanValues(dataSet: any) {
    let property: keyof typeof dataSet;

    for (property in dataSet) {
      if (property != "seasonName") {
        if (isNaN(dataSet[property]) || !isFinite(dataSet[property]) || dataSet[property] == null || dataSet[property] == undefined) {
          dataSet[property] = null;
        }
      }
    }

    return dataSet;
  }

  updateEndWeeks() {
    let selectedStartWeekIndex = _.findIndex(this.fullWeeksList, w => w == this.selectedStartWeek);
    this.endweeksList = this.fullWeeksList.slice(selectedStartWeekIndex + 1, this.fullWeeksList.length);

    // check if end week lies within the new range
    if (this.endweeksList.indexOf(this.selectedEndWeek) == -1) {
      // if not, update it to start week + 3
      // check if start week + 4 is within range, if not set to last week
      if (this.fullWeeksList.length - this.fullWeeksList.indexOf(this.selectedStartWeek) > 3) {
        this.selectedEndWeek = this.fullWeeksList[selectedStartWeekIndex + 3];
      }
      else {
        this.selectedEndWeek = this.fullWeeksList[this.fullWeeksList.length - 1];
      }

    }

  }

  emitSelectionChangedEvent() {
    if (this.selectedEndWeek > this.selectedStartWeek) {
      this.summarySelectionEvent.emit({
        startWeek: this.selectedStartWeek,
        endWeek: this.selectedEndWeek
      })
    }
  }

  presetCustomWeekSelections()
  {
    this.customSelectStartWeek = this.selectedStartWeek;
    this.customSelectEndWeek = this.selectedEndWeek;
  }

  focusCustomStartWeek(event)
  {
    this.customSelectStartWeek = this.selectedStartWeek;
    if(event)
    {
      document.getElementById("txtCustomStartWeekSelection")?.focus();
    }
  }

  focusCustomEndWeek(event)
  {
    this.customSelectEndWeek = this.selectedEndWeek;
    if(event)
    {
      document.getElementById("txtCustomEndWeekSelection")?.focus();
    }
  }

}
