import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { PlanningViewComponent } from './components/planning-view/planning-view.component';
import { PlanningViewDeactivateGuard } from './services/planning-view-deactivate-guard';

const routes: Routes = [
  {
    path: 'planning-view',
    component: PlanningViewComponent,
    canActivate: [MsalGuard],
    canDeactivate: [PlanningViewDeactivateGuard]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlanningViewRoutingModule { }
