import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AssortmentCopy } from '../../selection-view/types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from '../../selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { ChannelType } from '../types/channel-type';
import { Constants } from '../types/constants';
import { MarketType } from '../types/market-type';
import { PlanningViewOptions } from '../types/planning-view-options';
import { SelectionViewOptions } from '../types/selection-view-options';
import { StructureTypes } from '../types/structure-types';
import { PlanningViewLayoutSettings, SeasonSetting, UserConfig } from '../types/user-config';
import { FeatureFlagService } from './feature-flag.service';
import { UtilsService } from './utils.service';

@Injectable({
    providedIn: 'root'
})
export class UserConfigService {

    private _userConfig: UserConfig = null;

    public userConfigSubject: BehaviorSubject<UserConfig> = new BehaviorSubject<UserConfig>(this._userConfig);

    constructor(private _featureFlagService: FeatureFlagService) {
        this.loadUserConfig()
    }

    private saveToStorage(key: string, value: string) {
        localStorage.setItem(key, value);
    }

    private loadFromStorage(key: string): any {
        return localStorage.getItem(key);
    }

    private removeFromStorage(key: string): any {
        return localStorage.removeItem(key);
    }

    private isNotNullOrUndefined(value: any): boolean {
        if (value != null && value != undefined) {
            return true;
        }
        else {
            return false;
        }
    }

    private loadUserConfig() {


        //if cache is expired, then clear it (compare with feature flag timestamp value)
        this._featureFlagService.appConfigDataSubject.subscribe(
            () => {
                let cacheTimeStamp = null;

                cacheTimeStamp = this._featureFlagService.getAppConfigByKey(Constants.REFRESHCACHETIMESTAMP);

                let userConfigObj = this.loadFromStorage(Constants.USER_CONFIG);

                //set user config object once the app config is loaded
                if (this.isNotNullOrUndefined(cacheTimeStamp)) {

                    if (this.isNotNullOrUndefined(userConfigObj)) {

                        // we have to clear all the configuration before the 'specfiied time' in the app- config
                        let userConfig = JSON.parse(userConfigObj);
                        if (new Date(cacheTimeStamp.value) > new Date(userConfig.createdTime)) {
                            this.removeFromStorage(Constants.USER_CONFIG);
                            this._userConfig = this.createDefaultUserConfig();
                        }
                        else {
                            this._userConfig = userConfig;
                        }
                    }
                    else {
                        this._userConfig = this.createDefaultUserConfig();
                    }

                    this.userConfigSubject.next(this._userConfig);
                }
            }
        );

    }

    private createDefaultUserConfig(): UserConfig {
        return {
            selectionViewOptions: null,
            planningViewOptions: {
                isChannelSum: false,
                channel: ChannelType.Store
            },
            planningViewLayoutSettings: null,
            createdTime: new Date(),
            IsShowDeveloperExceptionPopupEnabled: false
        }
    }

    getUserConfig(): UserConfig {
        return this._userConfig;
    }

    private saveToCache() {
        this.saveToStorage(Constants.USER_CONFIG, JSON.stringify(this._userConfig));
    }

    private notifySubscribers() {
        this.userConfigSubject.next(this._userConfig);
    }

    setIsShowDeveloperExceptionPopupEnabled(value: boolean) {
        if (this.isNotNullOrUndefined(this._userConfig)) {
            if (this._userConfig.IsShowDeveloperExceptionPopupEnabled != value) {
                this._userConfig.IsShowDeveloperExceptionPopupEnabled = value;
                this.saveToCache();
                this.notifySubscribers();
            }
        }
    }

    setSelectionViewOptions(selectionViewOptions: SelectionViewOptions) {
        if (this.isNotNullOrUndefined(this._userConfig)) {
            // update only if needed
            if (JSON.stringify(this._userConfig.selectionViewOptions) != JSON.stringify(selectionViewOptions)) {
                this._userConfig.selectionViewOptions = _.cloneDeep(selectionViewOptions);
                this.saveToCache();
                this.notifySubscribers();
                
            }
        }
    }

    setPlanningViewOptions(planningViewOptions: PlanningViewOptions) {
        if (this.isNotNullOrUndefined(this._userConfig)) {
            // update only if needed
            if (JSON.stringify(this._userConfig.planningViewOptions) != JSON.stringify(planningViewOptions)) {
                this._userConfig.planningViewOptions = _.cloneDeep(planningViewOptions);
                this.saveToCache();
                this.notifySubscribers();
            }
        }
    }

    setPlanningViewLayoutSettings(planningViewLayoutSettings: PlanningViewLayoutSettings) {
        if (this.isNotNullOrUndefined(this._userConfig)) {
            // update only if needed
            if (JSON.stringify(this._userConfig.planningViewLayoutSettings) != JSON.stringify(planningViewLayoutSettings)) {
                this._userConfig.planningViewLayoutSettings = _.cloneDeep(planningViewLayoutSettings);
                this.saveToCache();
                this.notifySubscribers();                
            }
        }
    }

    setTrickleDownDialogSettings(dontShowMeAgain: boolean) {
        if (this.isNotNullOrUndefined(this._userConfig)) {

            let planningViewLayoutSettings = _.cloneDeep(this._userConfig.planningViewLayoutSettings);
            // update only if needed
            if (planningViewLayoutSettings) {
                planningViewLayoutSettings.showTrickleDownDialog = dontShowMeAgain ? false : true;
                this.setPlanningViewLayoutSettings(planningViewLayoutSettings);
            }
        }
    }

    setSeasonSetting(seasonSetting: SeasonSetting) {
        if (this._userConfig && this._userConfig.planningViewLayoutSettings && this._userConfig.planningViewLayoutSettings.seasonSettings) {
            // check if the season setting already exists
            let seasonSettingIndex = this._userConfig.planningViewLayoutSettings.seasonSettings.findIndex((x: SeasonSetting) => x.seasonPlanningType == seasonSetting.seasonPlanningType);
            if (seasonSettingIndex != -1) {
                // update only if needed
                if (JSON.stringify(this._userConfig.planningViewLayoutSettings.seasonSettings[seasonSettingIndex]) != JSON.stringify(seasonSetting)) {
                    this._userConfig.planningViewLayoutSettings.seasonSettings[seasonSettingIndex] = _.cloneDeep(seasonSetting);
                    this.saveToCache();
                    this.notifySubscribers();
                    
                }
            }
            else {
                this._userConfig.planningViewLayoutSettings.seasonSettings.push(_.cloneDeep(seasonSetting));
                this.saveToCache();
                this.notifySubscribers();                
            }            
        }
    }

    getConfigurationText(): string {

        let planningViewDetails = "=======================================================================" + "\n" +
            "Brand - " + this._userConfig.selectionViewOptions.brand.name + "\n" +
            "Organization - " + this._userConfig.selectionViewOptions.organization.name + "\n";
        if (this._userConfig.selectionViewOptions.organization.name == "Assortment") {
            if (this._userConfig.selectionViewOptions.structureType.name.startsWith(StructureTypes.Section))
                planningViewDetails += "Section - " + this._userConfig.selectionViewOptions.structure.name + "\n";
            else if (this._userConfig.selectionViewOptions.structureType.name.startsWith(StructureTypes.CustomerGroup))
                planningViewDetails += "CustomerGroup - " + this._userConfig.selectionViewOptions.structure.name + "\n";
        }

        planningViewDetails +=
            "Channel - " + (this._userConfig.planningViewOptions.channel == 1 ? "Store" : "Online") + "\n" +
            "Copy - " + this._userConfig.selectionViewOptions.copy.name + "\n" +
            "Structure - " + this._userConfig.selectionViewOptions.structureType.name + "\n";

        if (!this._userConfig.planningViewOptions.isChannelSum) {
            planningViewDetails +=
                "Market Type - " + MarketType[this._userConfig.planningViewOptions.market.marketType] + "\n" +
                "Market Name - " + this._userConfig.planningViewOptions.market.shortName + "\n";
        }
        else {
            planningViewDetails +=
                "Channel Sum" + "\n";
        }
        planningViewDetails += "Structure Type - " + this._userConfig.planningViewOptions.structureType + "\n" +
            "Structure Name - " + this._userConfig.planningViewOptions.activeNode.name + "(" + this._userConfig.planningViewOptions.activeNode.code + ")" + "\n" + // set planning and use planing view active noe
            "=======================================================================" + "\n";

        return planningViewDetails;
    }


    generateLink(): string {
        if (this._userConfig && this.isNotNullOrUndefined(this._userConfig.selectionViewOptions) && this.isNotNullOrUndefined(this._userConfig.planningViewOptions)) {
            let linkUrl = window.location.origin + "/selection-view";
            linkUrl += "?brand=" + this._userConfig.selectionViewOptions.brand.id;
            linkUrl += "&organization=" + this._userConfig.selectionViewOptions.organization.id;
            linkUrl += "&structureType=" + this._userConfig.selectionViewOptions.structureType.id;
            linkUrl += "&channel=" + this._userConfig.planningViewOptions.channel;
            linkUrl += "&excludeOld=" + (this._userConfig.planningViewOptions.areOldSeasonsExcluded ? "1" : "0");
            linkUrl += "&isChannelSum=" + (this._userConfig.planningViewOptions.isChannelSum ? "1" : "0");

            
            if (this._userConfig.selectionViewOptions.organization.name == "Assortment") {
                linkUrl += "&structure=" + this._userConfig.selectionViewOptions.structure.id;
                linkUrl += "&copy=" + (<AssortmentCopy>(this._userConfig.selectionViewOptions.copy)).assortmentCopyId;

                // selection page market is market1. planning page market is market2
                if (!this._userConfig.planningViewOptions.isChannelSum) {
                    // if (this._userConfig.planningViewOptions.marketType == MarketType.PlanningRegion) {
                    //     linkUrl += "&region=";
                    // }
                    // else if (this._userConfig.planningViewOptions.marketType == MarketType.PlanningMarket) {
                    //     linkUrl += "&pm=";
                    // }
                    linkUrl += "&marketId2=" + this._userConfig.planningViewOptions.market.marketIntegrationKey;
                }
            }
            else if (this._userConfig.selectionViewOptions.organization.name == "Sales") {
                linkUrl += "&copy=" + (<SalesCopy>(this._userConfig.selectionViewOptions.copy)).salesCopyId;

                linkUrl += "&marketId1=" + this._userConfig.selectionViewOptions.market.marketIntegrationKey;
                // linkUrl += "&isPercentPm1=" + this._userConfig.selectionViewOptions.isPercentPm;

                // //Selection page region/pm/percentpm
                // if (this._userConfig.selectionViewOptions.isPercentPm) {
                //     linkUrl += "&percentPM1=" + this._userConfig.selectionViewOptions.percentPM.marketIntegrationKey;
                // }
                // else if (this._userConfig.selectionViewOptions.region != null) {
                //     linkUrl += "&region1=" + this._userConfig.selectionViewOptions.region.marketIntegrationKey;
                // }
                // else if (this._userConfig.selectionViewOptions.planningMarket != null) {
                //     linkUrl += "&pm1=" + this._userConfig.selectionViewOptions.planningMarket.marketIntegrationKey;
                // }

                if (!this._userConfig.planningViewOptions.isChannelSum) {

                    linkUrl += "&marketId2=" + this._userConfig.planningViewOptions.market.marketIntegrationKey;
                //     //Planning view page region/pm/percentpm
                //     if (this._userConfig.planningViewOptions.marketType == MarketType.PlanningRegion) {
                //         linkUrl += "&region2=";
                //     }
                //     else if (this._userConfig.planningViewOptions.marketType == MarketType.PlanningMarket) {
                //         linkUrl += "&pm2=";
                //     }
                //     linkUrl += this._userConfig.planningViewOptions.marketId;
                }
            }
            if (this.isNotNullOrUndefined(this._userConfig.planningViewOptions.structureType))
                linkUrl += "&nodeType=" + this._userConfig.planningViewOptions.structureType.toString();
            if (this.isNotNullOrUndefined(this._userConfig.planningViewOptions.structureId))
                linkUrl += "&nodeId=" + this._userConfig.planningViewOptions.structureId.toString();

            return linkUrl;

        }
        return null;
    }

}


