import * as _ from 'lodash';
import { AggregatedGrossTaktCalculator } from 'src/app/modules/shared/calculators/aggregated-pms/aggregated-gross-takt-calculator';
import { AggregatedRAndDPercentCalculator } from 'src/app/modules/shared/calculators/aggregated-pms/aggregated-r-and-d-percent-calculator';
import { BoughtOfPlanCalculator } from 'src/app/modules/shared/calculators/buying/bought-of-plan-calculator';
import { CalculatorInterface } from "src/app/modules/shared/calculators/calculator-interface";
import { CalculatorTimeSpan } from "src/app/modules/shared/calculators/calculator-timespan";
import { MarketMainSystemGoalCalculator } from 'src/app/modules/shared/calculators/main-system-goal/main-system-goal-calculator';
import { ForecastGoalCalculator } from "src/app/modules/shared/calculators/others/forecast-goal-calculator";
import { PopulateInputCellCalculator } from "src/app/modules/shared/calculators/others/populate-input-cell-calculator";
import { StockIndexGroundCalculator } from "src/app/modules/shared/calculators/others/stock-index-ground-calculator";
import { GroundRAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/ground-r-and-d-percent-calculator";
import { RAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-percent-calculator";
import { ReadonlyDepartmentCalculatorDriverInterface } from "src/app/modules/shared/calculators/readonly-department-calculator-driver-interface";
import { GroundSPercentCalculator } from "src/app/modules/shared/calculators/s-percent-calculators/ground-s-percent-calculator";
import { SPercentCalculator } from "src/app/modules/shared/calculators/s-percent-calculators/s-percent-calculator";
import { SalesSystemGoalCalculator } from 'src/app/modules/shared/calculators/sales-system-goal/sales-system-goal-calculator';
import { OutgoingStockPrognosisCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-forecast-calculator";
import { StockPlanFixedCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { GrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/gross-takt-calculator";
import { GroundGrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/ground-gross-takt-calculator";
import { NetTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/net-takt-calculator";
import { OmniTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/omni-takt-calculator";
import { SystemGoalCalculator } from "src/app/modules/shared/calculators/takt-calculators/system-goal-calculator";
import { DialogBoxService } from "src/app/modules/shared/services/dialog-box-service";
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from "src/app/modules/shared/services/utils.service";
import { ChannelType } from "src/app/modules/shared/types/channel-type";
import { Constants } from "src/app/modules/shared/types/constants";
import { DriverType } from 'src/app/modules/shared/types/driver-type';
import { RetrievalMode } from "src/app/modules/shared/types/retrieval-mode";
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { SeasonPlanningType } from "src/app/modules/shared/types/season-planning-type";
import { SelectedOptions } from "src/app/modules/shared/types/selected-options";
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { WeekDataItem } from "src/app/modules/shared/types/week-data-item";
import { ReadonlyDepartmentPlanningViewDataResponse } from "../../../types/api/planning-view-data/readonly-department-planning-view-data-response";
import { CalculationDataItemType } from '../../../types/calculation-data-item-type';
import { InputLockStatus } from "../../../types/input-lock-status-enum";
import { ReadonlyDepartmentCalculationDataItem } from "../../../types/readonly-department-calculation-data-item";
import { ReturnsInfo } from "../../../types/returns-info";
import { SeasonDataAggregator } from "../department-calculation-driver/season-data-aggregator";
import { ReadonlyDepartmentCalculationDriver } from "./readonly-department-calculation-driver";

export class TotalReadonlyDepartmentCalculationDriver implements ReadonlyDepartmentCalculatorDriverInterface {

    private _title: string = null;
    //private _dataSet: ParentCalculationDataItem[];
    private _seasonInfo: SeasonInfo = null;
    private _viewDate: Date = null;
    private _retrievalMode: RetrievalMode = null;
    private _returnsInfo: ReturnsInfo = null;
    private _utilsService: UtilsService = null;
    dialogBoxService: DialogBoxService = null;
    private _viewWeekWithYear: number = null;
    private _viewPeriodWeeksWithYear: number[] = null;
    private _changeEventListeners: any[] = [];
    private _childDataSets: ReadonlyDepartmentCalculationDataItem[][] = [];
    // consolidated from 5 separate datasets
    private _aggregatedDataSet: ReadonlyDepartmentCalculationDataItem[] = null;
    private _calculatorConfiguration: CalculatorInterface[][] = null;
    // filtered into separate sets
    private _childParentDrivers: ReadonlyDepartmentCalculatorDriverInterface[] = [];
    public hasSalesAggregatedPmDataSetLoaded = false;
    public hasSalesSystemGoalInAssortmentLoaded = false;

    private _userConfigService: UserConfigService = null;

    selectedSeasons: string[] = null;

    constructor(userConfigService: UserConfigService, utilsService: UtilsService, dialogBoxService: DialogBoxService, selectedSeasons: string[]) {
        this._userConfigService = userConfigService;
        this._utilsService = utilsService;
        this.dialogBoxService = dialogBoxService;
        this.selectedSeasons = selectedSeasons;
    }
    getDriverType(): DriverType {
        return DriverType.ReadonlyTotalSeasonDriver;
    }
    getInputLockStatus(): InputLockStatus {
        return InputLockStatus.Locked_Disabled;
    }
    setInputLockStatus(lockStatus: InputLockStatus): void {
        throw new Error("Method not implemented.");
    }

    setReturnsInfo(returnsInfo: ReturnsInfo): void {
        throw new Error("Method not implemented.");
    }

    getCSPercent(): number { throw new Error("Method not implemented."); }
    setCSPercent(csPercent: number) { throw new Error("Method not implemented."); }

    getDataSetRange(startweek: number, endweek: number): ReadonlyDepartmentCalculationDataItem[] {
        let startWeekIndex = _.findIndex(this._aggregatedDataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == startweek
        });

        let endWeekIndex = _.findIndex(this._aggregatedDataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == endweek
        });

        return this._aggregatedDataSet.slice(startWeekIndex, endWeekIndex + 1);
    }
    getRawDataItems(): ReadonlyDepartmentCalculationDataItem[] {
        return null;
    }

    getTitle(areOldSeasonsExcluded?: boolean): string {
        return this._title;
    }
    getUtilsService(): UtilsService {
        return this._utilsService;
    }
    getSeasonInfo(): SeasonInfo {
        return this._seasonInfo;
    }
    getDataSet(): ReadonlyDepartmentCalculationDataItem[] {
        return this._aggregatedDataSet;
    }
    setDataSet(dataSet: ReadonlyDepartmentCalculationDataItem[]) {
        this._aggregatedDataSet = dataSet;
    }
    setRetrievalMode(retrievalMode: RetrievalMode) {
        this._retrievalMode = retrievalMode;
    }
    getViewDate(): Date {
        return this._viewDate;
    }

    getRetrievalMode(): RetrievalMode {
        return this._retrievalMode;
    }
    getViewPeriodWeeksWithYear(): number[] {
        return this._viewPeriodWeeksWithYear;
    }
    getViewWeekWithYear(): number {
        return this._viewWeekWithYear;
    }
    getReturnsInfo(): ReturnsInfo {
        return this._returnsInfo;
    }
    getUserConfig(): UserConfig {
        return this._userConfigService.getUserConfig();
    }
    setCalculatorConfiguration(calculatorConfiguration: CalculatorInterface[][]) {
        this._calculatorConfiguration = calculatorConfiguration;
    }

    getChildDataSets(): ReadonlyDepartmentCalculationDataItem[][] {
        return this._childDataSets;
    }

    handleEvent() {
        throw new Error("Method not implemented.");
    }
    addChangeEventListener(changeHandler: any) {
        this._changeEventListeners.push(changeHandler);
    }
    initDataSet(parentPlanningViewDataResponse: ReadonlyDepartmentPlanningViewDataResponse, seasonInfo: SeasonInfo, viewDate: Date, selectedOptions: SelectedOptions) {
        throw new Error("Method not implemented.");
    }
    initTotalDataSet(childParentCalculationDrivers: ReadonlyDepartmentCalculatorDriverInterface[], seasonInfo: SeasonInfo, viewDate: Date) {

        this._seasonInfo = seasonInfo;
        this._viewDate = viewDate;
        this._viewWeekWithYear = this._utilsService.getWeekNumber(true, this._viewDate);
        this._viewPeriodWeeksWithYear = this._utilsService.getWeeksForPeriod(this._viewDate);
        this._title = "All Seasons";
        // default retrieval mode for Total
        this._retrievalMode = RetrievalMode.Bought;

        // get access to each child driver
        this._childParentDrivers = childParentCalculationDrivers;

        // get all datasets from the child drivers
        // assuming them to be calculated at this point, since total driver is called last
        this._childParentDrivers.forEach((childDriver: ReadonlyDepartmentCalculationDriver) => {
            if (this.isSeasonValid(childDriver.getSeasonInfo().seasonCodeNames[0])) {
                let dataset = childDriver.getDataSet();
                this._childDataSets.push(_.cloneDeep(dataset));
            }

        });

        // insert an empty data set
        if (this._childDataSets.length == 0) {
            this._childDataSets.push(this.generateEmptyDataSet());
        }

        // aggregate to a single aggregated data set
        let fields = this._getAggregationFields();

        this._aggregatedDataSet = SeasonDataAggregator.aggregateParent(this._childDataSets, fields, this._seasonInfo, this._utilsService);

        this._configureCalculatorsForInitialLoad();
    }

    refreshTotalDataSet(seasonsList: string[]) {

        this.selectedSeasons = seasonsList;

        this._childDataSets = [];
        this._childParentDrivers.forEach((childDriver: ReadonlyDepartmentCalculationDriver) => {
            if (this.isSeasonValid(childDriver.getSeasonInfo().seasonCodeNames[0])) {
                let dataset = childDriver.getDataSet();
                this._childDataSets.push(_.cloneDeep(dataset));
            }
        });

        // insert an empty data set
        if (this._childDataSets.length == 0) {
            this._childDataSets.push(this.generateEmptyDataSet());
        }

        // aggregate to a single aggregated data set
        let fields = this._getAggregationFields();

        this._aggregatedDataSet = SeasonDataAggregator.aggregateParent(this._childDataSets, fields, this._seasonInfo, this._utilsService);
        this._configureCalculatorsForInitialLoad();

        if (this._childDataSets.length == 1) {
            this.setDataSet(this._childDataSets[0]);
            this.calculateForSingleSeason();
        }
        else {
            this.calculate();
        }

        this._changeEventListeners.forEach(changeListener => {
            changeListener();
        });

        // check if all of the child drivers have the PM sales value populated  
        let salesPmloaded = true;
        this._childParentDrivers.forEach((childDriver: ReadonlyDepartmentCalculationDriver) =>
        {
            salesPmloaded = salesPmloaded && childDriver.hasSalesAggregatedPmDataSetLoaded;
        });

        this.hasSalesAggregatedPmDataSetLoaded = salesPmloaded;

        // check if all of the child drivers have the Sales Goal in Assortment Loaded
        let salesGoalInAssortmentLoaded = true;
        this._childParentDrivers.forEach((childDriver: ReadonlyDepartmentCalculationDriver) =>
        {
            salesGoalInAssortmentLoaded = salesGoalInAssortmentLoaded && childDriver.hasSalesSystemGoalInAssortmentLoaded;
        });

        this.hasSalesSystemGoalInAssortmentLoaded = salesGoalInAssortmentLoaded;
    }

    calculate() {
        // reset all the temp variables used with the calculators before execution
        this._calculatorConfiguration.forEach((passCalculators: CalculatorInterface[], pass: number) => {
            passCalculators.forEach((passCalculator: CalculatorInterface) => {
                try {
                    passCalculator.reset();
                }
                catch { }
            });
        });

        let currentWeekItem: ReadonlyDepartmentCalculationDataItem = null;
        let previousWeekItem: ReadonlyDepartmentCalculationDataItem = null;
        let periodIndexes = null;
        let currentPeriodWeekItems: ReadonlyDepartmentCalculationDataItem[] = null;
        let weekList = this._aggregatedDataSet.map(el => el.weekName);
        let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);

        this._calculatorConfiguration.forEach((calculators: CalculatorInterface[], pass: number) => {
            currentWeekItem = null;
            previousWeekItem = null;
            periodIndexes = null;
            currentPeriodWeekItems = null;
            uniqueWeekList.forEach((weekWithYear: number, index: number) => {
                currentWeekItem = this._aggregatedDataSet[index];
                previousWeekItem = (index == 0) ? null : this._aggregatedDataSet[index - 1];
                periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
                currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);
                calculators.forEach((calculator: CalculatorInterface) => {
                    // if its a weekly calculator - run the calculator for each week 
                    // if its a periodic calculator - run the calculator for every 4th week 
                    if (calculator.timespan == CalculatorTimeSpan.Weekly || (calculator.timespan == CalculatorTimeSpan.Periodic && index % 4 == 0)) {
                        calculator.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
                    }
                });
                this._aggregatedDataSet[index][CalculationDataItemType.SPercentWeekly] = this.calculateSPercentWeeklyForAllSeasons(currentWeekItem);
            });
        });

        currentWeekItem = null;
        previousWeekItem = null;
        periodIndexes = null;
        currentPeriodWeekItems = null;
        uniqueWeekList.forEach((weekWithYear: number, index: number) => {

            currentWeekItem = this._aggregatedDataSet[index];
            previousWeekItem = (index == 0) ? null : this._aggregatedDataSet[index - 1];
            periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
            currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);
            this._aggregatedDataSet[index][CalculationDataItemType.SystemGoalPeriodic] = this.calculateSystemGoalForAllSeasons(currentPeriodWeekItems);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisWeekly] = this.calculateStockPrognosisWeeklyForAllSeasons(currentWeekItem);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisPeriodic] = this.calculateStockPrognosisPeriodicForAllSeasons(currentPeriodWeekItems);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPerSalesNetWeekly] = this.calculateStockPerSalesNetWeeklyForAllSeasons(currentWeekItem);
        });
    }

    public calculateForSingleSeason() {
        let currentWeekItem: ReadonlyDepartmentCalculationDataItem = null;
        let previousWeekItem: ReadonlyDepartmentCalculationDataItem = null;
        let periodIndexes = null;
        let currentPeriodWeekItems: ReadonlyDepartmentCalculationDataItem[] = null;
        let weekList = this._aggregatedDataSet.map(el => el.weekName);
        let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);

        let calcConfiguration: CalculatorInterface[] = [];

        calcConfiguration.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        uniqueWeekList.forEach((weekWithYear: number, index: number) => {
            currentWeekItem = this._aggregatedDataSet[index];
            previousWeekItem = (index == 0) ? null : this._aggregatedDataSet[index - 1];
            periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
            currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

            calcConfiguration.forEach(calc => {
                calc.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
            })

            this._aggregatedDataSet[index][CalculationDataItemType.SystemGoalPeriodic] = this.calculateSystemGoalForAllSeasons(currentPeriodWeekItems);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisWeekly] = this.calculateStockPrognosisWeeklyForAllSeasons(currentWeekItem);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPrognosisPeriodic] = this.calculateStockPrognosisPeriodicForAllSeasons(currentPeriodWeekItems);
            this._aggregatedDataSet[index][CalculationDataItemType.StockPerSalesNetWeekly] = this.calculateStockPerSalesNetWeeklyForAllSeasons(currentWeekItem);
        });
    }


    calculateSPercentWeeklyForAllSeasons(currentWeekItem: ReadonlyDepartmentCalculationDataItem): number {

        let value = 0;
        let valueX = 0;
        let valueY = 0;

        this._childParentDrivers.forEach(driver => {
            if (this.isSeasonValid(driver.getSeasonInfo().seasonCodeNames[0])) {

                let seasonPlanningType = driver.getSeasonInfo().seasonPlanningType;
                let childDepartmentItem = driver.getDataSetRange(currentWeekItem[CalculationDataItemType.WeekName], currentWeekItem[CalculationDataItemType.WeekName])[0];
                let retrievalMode = driver.getRetrievalMode();

                switch (seasonPlanningType) {
                    case SeasonPlanningType.PreviousWithOld:
                    case SeasonPlanningType.Previous:
                        valueX += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                        valueY += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly] + childDepartmentItem[CalculationDataItemType.OutgoingStockPrognosisWeekly];
                        break;
                    case SeasonPlanningType.Actual:
                        if (retrievalMode == RetrievalMode.Plan) {

                            valueX += currentWeekItem[CalculationDataItemType.CubeDemDelPlan] - (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] - currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]);

                            let childDataSet = driver.getDataSet();

                            let addRemoveSum = 0;
                            let addMovesSum = 0;
                            let cubeDemDelPlanSum = 0;
                            let combinedSalesPlanSum = 0;

                            childDataSet.forEach(childDataSetItem => {
                                if (childDataSetItem[CalculationDataItemType.WeekName] <= currentWeekItem[CalculationDataItemType.WeekName]) {
                                    addRemoveSum += childDataSetItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK;
                                    addMovesSum += childDataSetItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK;
                                    cubeDemDelPlanSum += childDataSetItem[CalculationDataItemType.CubeDemDelPlan];
                                    combinedSalesPlanSum += childDataSetItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                                }
                            });

                            valueY += currentWeekItem[CalculationDataItemType.CubeDemDelPlan] -
                                (currentWeekItem[CalculationDataItemType.CubeDemDelPlan] -
                                    currentWeekItem[CalculationDataItemType.CombinedSalesPlanWeekly]) +
                                currentWeekItem[CalculationDataItemType.StockPlanFixed] +
                                addRemoveSum + addMovesSum + cubeDemDelPlanSum - combinedSalesPlanSum;
                        }
                        else {
                            valueX += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                            // Plan mode not allowed in Actual
                            valueY += childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly] + childDepartmentItem[CalculationDataItemType.OutgoingStockPrognosisWeekly];
                        }
                        break;
                    case SeasonPlanningType.Coming:
                    case SeasonPlanningType.Future:

                        let channelType = driver.getUserConfig().planningViewOptions.channel;
                        valueX += (channelType == ChannelType.Online) ? childDepartmentItem[CalculationDataItemType.SalesForecastWeekly] : childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];


                        if (retrievalMode == RetrievalMode.Plan) {
                            // retrieve the value by inverting the formula
                            valueY += (channelType == ChannelType.Online) ? childDepartmentItem[CalculationDataItemType.SalesForecastWeekly] : childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                            valueY += childDepartmentItem[CalculationDataItemType.StockPlanFixed];

                            let childDataSet = driver.getDataSet();

                            let addRemoveSum = 0;
                            let addMovesSum = 0;
                            let cubeDemDelPlanSum = 0;
                            let combinedSalesPlanSum = 0;

                            childDataSet.forEach(childDataSetItem => {
                                if (childDataSetItem[CalculationDataItemType.WeekName] <= currentWeekItem[CalculationDataItemType.WeekName]) {
                                    addRemoveSum += childDataSetItem[CalculationDataItemType.AddRemoveMSek] * Constants.TSEKTOMSEK;
                                    addMovesSum += childDataSetItem[CalculationDataItemType.AddMovesMSek] * Constants.TSEKTOMSEK;
                                    cubeDemDelPlanSum += childDataSetItem[CalculationDataItemType.CubeDemDelPlan];
                                    combinedSalesPlanSum += childDataSetItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                                }
                            });

                            valueY += addRemoveSum + addMovesSum + cubeDemDelPlanSum - combinedSalesPlanSum;
                        }
                        else {
                            valueY += (channelType == ChannelType.Online) ? childDepartmentItem[CalculationDataItemType.SalesForecastWeekly] : childDepartmentItem[CalculationDataItemType.CombinedSalesPlanWeekly];
                            valueY += childDepartmentItem[CalculationDataItemType.OutgoingStockPrognosisWeekly]
                        }

                        break;
                }
            }
        });

        value = this._utilsService.safeDivide(valueX, valueY) * 100;

        return value;
    }

    calculateSystemGoalForAllSeasons(currentPeriodWeekItems: ReadonlyDepartmentCalculationDataItem[]) {

        let cubeDemDelPlanSum = 0;
        let grossSalesGroundSum = 0;

        this._childParentDrivers.forEach(driver => {
            if (this.isSeasonValid(driver.getSeasonInfo().seasonCodeNames[0])) {

                let seasonInfo = driver.getSeasonInfo();

                let periodDataSet = driver.getDataSetRange(currentPeriodWeekItems[0].weekName, currentPeriodWeekItems[currentPeriodWeekItems.length - 1].weekName);

                cubeDemDelPlanSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.EffectiveSystemGoalPlanWeekly);
                grossSalesGroundSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.GrossSalesGround);
            }

        });
        return this._utilsService.safeDivide(cubeDemDelPlanSum, grossSalesGroundSum) * 100;
    }

    calculateStockPrognosisWeeklyForAllSeasons(currentWeekItem: ReadonlyDepartmentCalculationDataItem) {

        let effectiveOutgoingStockPrognosisSum = 0;
        let stockGroundSum = 0;
        let _rawWeekDataItems: WeekDataItem[] = [];

        this._childDataSets.forEach(dataset => {
            _rawWeekDataItems.push(_.filter(dataset, {
                'weekName': currentWeekItem.weekName,
            }));
        });

        _rawWeekDataItems.forEach(driver => {

            effectiveOutgoingStockPrognosisSum += driver[0][CalculationDataItemType.EffectiveOutgoingStockPrognosis];

            stockGroundSum += this._utilsService.isNotNullUndefinedOrZero(driver[0][CalculationDataItemType.StockGround]) ? driver[0][CalculationDataItemType.StockGround] : driver[0][CalculationDataItemType.StockPrognosisLy];
        });

        return this._utilsService.safeDivide(effectiveOutgoingStockPrognosisSum, stockGroundSum) * 100;

    }

    calculateStockPrognosisPeriodicForAllSeasons(currentPeriodWeekItems: ReadonlyDepartmentCalculationDataItem[]) {

        let effectiveOutgoingStockPrognosisSum = 0;
        let stockGroundSum = 0;

        this._childParentDrivers.forEach(driver => {

            if (this.isSeasonValid(driver.getSeasonInfo().seasonCodeNames[0])) {

                let periodDataSet = driver.getDataSetRange(currentPeriodWeekItems[0].weekName, currentPeriodWeekItems[currentPeriodWeekItems.length - 1].weekName);

                effectiveOutgoingStockPrognosisSum += this._utilsService.sumKpiForPeriod(periodDataSet, CalculationDataItemType.EffectiveOutgoingStockPrognosis);
                periodDataSet.forEach(item => {
                    stockGroundSum += this._utilsService.isNotNullUndefinedOrZero(item[CalculationDataItemType.StockGround]) ? item[CalculationDataItemType.StockGround] : item[CalculationDataItemType.StockPrognosisLy];
                });
            }
        });
        let value = this._utilsService.safeDivide(effectiveOutgoingStockPrognosisSum, stockGroundSum) * 100
        return value;
    }

    calculateStockPerSalesNetWeeklyForAllSeasons(currentWeekItem: ReadonlyDepartmentCalculationDataItem) {

      let effectiveOutgoingStockPrognosisSum = 0;
      let combinedNetSalesWeeklySum = 0;
      let _rawWeekDataItems: WeekDataItem[] = [];
      let weekName = currentWeekItem[CalculationDataItemType.WeekName];
      let combinedReturnsSum = 0;

      let salesNetSum = 0;
      let currentWeek = this.getUtilsService().getWeekNumber(true, null);

      let channelType = this._childParentDrivers[0].getUserConfig().planningViewOptions.channel;

      this._childDataSets.forEach(driver => {
        _rawWeekDataItems.push(_.filter(driver, {
          'weekName': currentWeekItem.weekName,
        }));

        //Add data item for Stock Per Sales Wk51
        //Get the index of the week 
        let index = driver.findIndex(x => x.weekName == weekName);

        //Get the data item that are availalbe in the objects 
        let startWeekIndex = 0;
        if (index > 51) {
          startWeekIndex = index - 51;
        }
        else {
          startWeekIndex = 0;
        }
        for (var i = index; i > startWeekIndex; i--) {
          let weekItem = driver[i - 1];
          if (this._seasonInfo.weeksWithYear.indexOf(weekItem[CalculationDataItemType.WeekName]) > -1) {
            salesNetSum += weekItem[CalculationDataItemType.CombinedNetSalesWeekly];
            if (channelType != ChannelType.Store) {
                let returnMetric =  weekItem[CalculationDataItemType.WeekName] < currentWeek  ?
                                    weekItem[CalculationDataItemType.ReturnsGross] : weekItem[CalculationDataItemType.PlannedReturn]
                salesNetSum -= (returnMetric *
                this._utilsService.safeDivide(weekItem[CalculationDataItemType.CombinedNetSalesWeekly], weekItem[CalculationDataItemType.CombinedSalesPlanWeekly]));
            }
          }
          else if (currentWeek > weekItem[CalculationDataItemType.WeekName]) {
            salesNetSum += (weekItem[CalculationDataItemType.GrossSales] - weekItem[CalculationDataItemType.RAndD]);
            if (channelType != ChannelType.Store) {
              salesNetSum -= (weekItem[CalculationDataItemType.ReturnsGross] *
                this._utilsService.safeDivide(weekItem[CalculationDataItemType.CombinedNetSalesWeekly], weekItem[CalculationDataItemType.CombinedSalesPlanWeekly]));
            }
          }
          else {
            salesNetSum += currentWeekItem[CalculationDataItemType.CombinedNetSalesWeekly];
            if (channelType != ChannelType.Store) {
              salesNetSum -= (currentWeekItem[CalculationDataItemType.PlannedReturn] *
                this._utilsService.safeDivide(weekItem[CalculationDataItemType.NetSalesGround], weekItem[CalculationDataItemType.GrossSalesGround]));
            }
          }
        }
      });

      _rawWeekDataItems.forEach(driver => {

        effectiveOutgoingStockPrognosisSum += driver[0][CalculationDataItemType.EffectiveOutgoingStockPrognosis];

        combinedNetSalesWeeklySum += driver[0][CalculationDataItemType.CombinedNetSalesWeekly]

        if (channelType != ChannelType.Store) {

          if (currentWeek > driver[0][CalculationDataItemType.WeekName]) {
            combinedReturnsSum += (driver[0][CalculationDataItemType.ReturnsGross] * this._utilsService.safeDivide(driver[0][CalculationDataItemType.CombinedNetSalesWeekly], driver[0][CalculationDataItemType.CombinedSalesPlanWeekly]));
          }
          else {
            combinedReturnsSum += (driver[0][CalculationDataItemType.PlannedReturn] *
              this._utilsService.safeDivide(driver[0][CalculationDataItemType.NetSalesGround], driver[0][CalculationDataItemType.GrossSalesGround]));
          }
        }
      });

      return this._utilsService.safeDivide(effectiveOutgoingStockPrognosisSum,
                (combinedNetSalesWeeklySum - combinedReturnsSum+ salesNetSum)) * 100;
    }

    private _getAggregationFields(): CalculationDataItemType[] {
        return this._utilsService.getAllParentCalculationDataItemTypes();
    }

    private _getDataSetItemsByIndexes(indexes: number[]): ReadonlyDepartmentCalculationDataItem[] {
        let dataSetItems: ReadonlyDepartmentCalculationDataItem[] = [];
        indexes.forEach(i => {
            dataSetItems.push(this._aggregatedDataSet[i]);
        });

        return dataSetItems;
    }

    public configureCalculatorsForClear() {
        this._configureCalculatorsForInitialLoad();
    }

    private _configureCalculatorsForInitialLoad() {
        this._calculatorConfiguration = [];

        // ============= //

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];


        // calculate input cell weekly : add move/remnove
        pass1Calculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Weekly, this))
        // Stock Plan Fixed Correction - Weekly
        pass1Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, this))
        // Gross takt ground - Weekly
        pass1Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // Gross takt - weekly
        pass1Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Weekly, this))
        // System Goal - Periodic
        pass1Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, this))
        // R&D% Ground - Weekly
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // R&D% Ground - Periodic
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // Stock Index Ground - Periodic
        pass1Calculators.push(new StockIndexGroundCalculator(CalculatorTimeSpan.Periodic, this))
        // Forecast goal - Periodic
        pass1Calculators.push(new ForecastGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];
        // Gross takt ground - Periodic
        pass2Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // Ground S percent- Weekly
        pass2Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // R&D% - Periodic
        pass2Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass2Calculators);

        // ============= //

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];
        // Gross takt - Periodic
        pass3Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // Omni takt - Periodic
        pass3Calculators.push(new OmniTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // R&D% - Weekly
        pass3Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Weekly, this));

        // Stock Forecast - Weekly
        pass3Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Outgoing Stock Prognosis - Weekly
        pass3Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, this))

        // Ground S percent- Periodic
        pass3Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass3Calculators);

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];

        // Net takt - weekly
        pass4Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Weekly, this))
        // Net takt - Periodic
        pass4Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // S Percent- Weekly
        //pass4Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass4Calculators);

        let pass5Calculators: CalculatorInterface[] = [];
        // S Percent- Period
        pass5Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, this))

        this._calculatorConfiguration.push(pass5Calculators);

        // pass 06
        let pass6Calculators: CalculatorInterface[] = [];
        pass6Calculators.push(new AggregatedGrossTaktCalculator(CalculatorTimeSpan.Periodic, this));
        pass6Calculators.push(new AggregatedRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this));
        this._calculatorConfiguration.push(pass6Calculators);

        // pass 7
        let pass7Calculators: CalculatorInterface[] = [];
        pass7Calculators.push(new MarketMainSystemGoalCalculator(CalculatorTimeSpan.Periodic, this));
        pass7Calculators.push(new SalesSystemGoalCalculator(CalculatorTimeSpan.Periodic, this));
        pass7Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, this));
        this._calculatorConfiguration.push(pass7Calculators);          

        // ============= //
    }

    isSeasonValid(seasonName: string) {
        let isValid = true;
        if (this.selectedSeasons) {
            isValid = this.selectedSeasons.filter(x => x.indexOf(seasonName) != -1).length == 1;
        }
        return isValid;
    }

    generateEmptyDataSet() {
        // create an empty dataset based on the data present in the first child driver

        let emptyDataSet: ReadonlyDepartmentCalculationDataItem[] = _.cloneDeep(this._childParentDrivers[0].getDataSet());

        emptyDataSet = emptyDataSet.filter(el => this._seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);
        for (let i = 0; i < emptyDataSet.length; i++) {
            for (let property in emptyDataSet[i]) {
                if (property != "weekName" && property != "seasonName") {
                    emptyDataSet[i][property] = 0;
                }
            }
        }

        return emptyDataSet;
    }

    setSalesAggregatedPmDataSet(salesAggregatedDataItems:ReadonlyDepartmentCalculationDataItem[])
    {
        throw new Error("Method not implemented.");
    }

    setSalesSystemGoalInAssortmentDataSet(): void
    {
        
    }
}
