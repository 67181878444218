import * as _ from 'lodash';
import { BoughtOfPlanCalculator } from 'src/app/modules/shared/calculators/buying/bought-of-plan-calculator';
import { BuyingCalculator } from 'src/app/modules/shared/calculators/buying/buying-calculator';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { CalculatorInterface } from "src/app/modules/shared/calculators/calculator-interface";
import { CalculatorTimeSpan } from 'src/app/modules/shared/calculators/calculator-timespan';
import { CalculatorType } from 'src/app/modules/shared/calculators/calculator-type';
import { PlannedCoverageCalculator } from 'src/app/modules/shared/calculators/coverage/planned-coverage-calculator';
import { CSPercentCalculator } from 'src/app/modules/shared/calculators/cs-percent/cs-percent-calculator';
import { BudgetedReturnCalculator } from 'src/app/modules/shared/calculators/others/budgeted-return-calculator';
import { CombinedNetSalesCalculator } from 'src/app/modules/shared/calculators/others/combined-net-sales-calculator';
import { CombinedSalesPlanCalculator } from 'src/app/modules/shared/calculators/others/combined-sales-plan-calculator';
import { EffectiveSalesPlanCalculator } from 'src/app/modules/shared/calculators/others/effective-sales-plan-calculator';
import { ForecastGoalCalculator } from 'src/app/modules/shared/calculators/others/forecast-goal-calculator';
import { FunctionCalculator } from 'src/app/modules/shared/calculators/others/function-calculator';
import { PlannedReturnCalculator } from 'src/app/modules/shared/calculators/others/planned-return-calculator';
import { PopulateInputCellCalculator } from 'src/app/modules/shared/calculators/others/populate-input-cell-calculator';
import { PopulateRAndDForecastInputCellCalculator } from 'src/app/modules/shared/calculators/others/populate-randd-forecast-input-cell-calculator';
import { SalesForecastCalculator } from 'src/app/modules/shared/calculators/others/sales-forecast-calculator';
import { ParentCalculatorDriverInterface } from "src/app/modules/shared/calculators/parent-calculator-driver-interface";
import { DemdelPlanCalculator } from 'src/app/modules/shared/calculators/plan-calculators/demdel-plan-calculator';
import { CombinedRAndDCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/combined-r-and-d-calculator';
import { EffectiveRAndDPlanCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/effective-r-and-d-plan-calculator';
import { RAndDForecastCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-forecast-calculator';
import { RAndDForecastPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-forecast-percent-calculator';
import { RAndDPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-percent-calculator';
import { RAndDPlanCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-plan-calculator';
import { SPercentCalculator } from 'src/app/modules/shared/calculators/s-percent-calculators/s-percent-calculator';
import { OutgoingStockPrognosisCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator';
import { StockForecastCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/stock-forecast-calculator';
import { StockPlanFixedCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/stock-plan-fixed-calculator';
import { StockPrognosisCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/stock-prognosis-calculator';
import { GrossTaktCalculator } from 'src/app/modules/shared/calculators/takt-calculators/gross-takt-calculator';
import { NetTaktCalculator } from 'src/app/modules/shared/calculators/takt-calculators/net-takt-calculator';
import { OmniTaktCalculator } from 'src/app/modules/shared/calculators/takt-calculators/omni-takt-calculator';
import { SystemGoalCalculator } from 'src/app/modules/shared/calculators/takt-calculators/system-goal-calculator';
import { DepartmentEvent } from 'src/app/modules/shared/events/department-events/department-event';
import { DepartmentEventType } from 'src/app/modules/shared/events/department-events/department-event-type';
import { DialogBoxService } from "src/app/modules/shared/services/dialog-box-service";
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { DriverType } from 'src/app/modules/shared/types/driver-type';
import { RetrievalMode } from "src/app/modules/shared/types/retrieval-mode";
import { SeasonDataItem } from "src/app/modules/shared/types/season-data-item";
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { SelectedOptions } from "src/app/modules/shared/types/selected-options";
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { EffectiveSalesPlanWithGroundCalculator } from '../../../../shared/calculators/others/effective-sales-plan-with-ground-calculator';
import { PlanningViewActionHistoryService } from '../../../services/planning-view-action-history-service';
import { ParentPlanningViewDataResponse } from "../../../types/api/planning-view-data/parent-planning-view-data-response";
import { CalculationDataItemType } from '../../../types/calculation-data-item-type';
import { DepartmentSaveItem } from '../../../types/department-save-item';
import { InputLockStatus } from '../../../types/input-lock-status-enum';
import { ParentCalculationDataItem } from "../../../types/parent-calculation-data-item";
import { ReturnsInfo } from "../../../types/returns-info";
import { DepartmentSeasonSaveItem } from '../../../types/save-items/department-season-save-item';
import { WeekSaveItem } from '../../../types/save-items/week-save-item';
import { OrganizationType } from 'src/app/modules/shared/types/organization-type';

export class ParentCalculationDriver implements ParentCalculatorDriverInterface {
    public _title: string = null;
    private _csPercent: number = null;
    private _dataSet: ParentCalculationDataItem[];
    private _childSeasonsDataSet: SeasonDataItem[];
    private _intialDataSet: ParentCalculationDataItem[];
    private _seasonInfo: SeasonInfo = null;
    private _viewDate: Date = null;
    public _retrievalMode: RetrievalMode = null;
    private _returnsInfo: ReturnsInfo = null;
    private _utilsService: UtilsService = null;
    dialogBoxService: DialogBoxService = null;
    private _viewWeekWithYear: number = null;
    private _viewPeriodWeeksWithYear: number[] = null;
    private _changeEventListeners: any[] = [];
    private _cacheDepartmentSaveItem: DepartmentSaveItem;
    public _departmentsHasDemDelButNoReturns: SeasonDataItem[];
    // consolidated from 5 separate datasets
    private _calculatorConfiguration: CalculatorInterface[][] = null;
    _dialogBoxService: DialogBoxService = null;
    public touched: boolean = false;
    public rPercentDirty: boolean = false;
    public rowInputsDirty: boolean = false;

    // filtered into separate sets
    private _childParentDrivers: ParentCalculationDriver[] = [];
    public retrievalMode: RetrievalMode = null;
    private uniqueWeekList: number[] = null;
    private initialInputItems: ParentCalculationDataItem[] = null;
    private previousInputItems: ParentCalculationDataItem[] = null;
    private _previousDataSet: ParentCalculationDataItem[] = null;
    private previousRetrievalMode: RetrievalMode = null;
    private previousAdjustedRPercent: number = null;
    private initialAdjustedRPercent: number = null;
    public hasSalesAggregatedPmDataSetLoaded = false;
    public hasSalesSystemGoalInAssortmentLoaded = false;

    _planningViewActionHistoryService: PlanningViewActionHistoryService = null;

    private inputLockStatus: InputLockStatus = InputLockStatus.Locked_Disabled;

    private _userConfigService: UserConfigService = null;

    constructor(userConfigService: UserConfigService, utilsService: UtilsService, dialogBoxService: DialogBoxService, planningViewActionHistoryService: PlanningViewActionHistoryService) {
        this._userConfigService = userConfigService;
        this._utilsService = utilsService;
        this._dialogBoxService = dialogBoxService;
        this._planningViewActionHistoryService = planningViewActionHistoryService;
    }
    getDriverType(): DriverType {
        return DriverType.ParentSeasonDriver
    }
    getInputLockStatus(): InputLockStatus {
        return this.inputLockStatus;
    }
    setInputLockStatus(lockStatus: InputLockStatus): void {
        this.inputLockStatus = lockStatus;
    }
    setReturnsInfo(returnsInfo: ReturnsInfo): void {
        this._returnsInfo = returnsInfo
    }

    getPreviousRetrievalMode(): RetrievalMode {
        return this.previousRetrievalMode;
    }

    getDataSetRange(startweek: number, endweek: number): ParentCalculationDataItem[] {

        let startWeekIndex = _.findIndex(this._dataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == startweek
        });
        if (startWeekIndex == -1) {
            startWeekIndex = 0;
        }
        
        let endWeekIndex = _.findIndex(this._dataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == endweek
        });

        return this._dataSet.slice(startWeekIndex, endWeekIndex + 1);
    }

    getRawDataItems() {
        return this._intialDataSet;
    }

    getTitle(areOldSeasonsExcluded?: boolean): string {
        if (this._seasonInfo.seasonCodeNames.length == 1) {
            return this._title;
        }
        else if (this._seasonInfo.seasonCodeNames.length > 1) {
            return "<=" + this._seasonInfo.seasonCodeNames[0] + "(" + this._seasonInfo.seasonCodeNames.slice(1).join(', ') + ")";
        }
    }

    getPreviousInputItemValue(weekIndex: number, inputType: CalculationDataItemType) {
        return this.previousInputItems[weekIndex][inputType];
    }


    getDialogBoxService(): DialogBoxService {
        return this._dialogBoxService;
    }

    getPreviousDataSet(): ParentCalculationDataItem[] {
        return this._previousDataSet;
    }

    getCSPercent(): number {

        return this._csPercent;
    }
    setCSPercent(csPercent: number) {
        this._csPercent = csPercent
    }
    getUtilsService(): UtilsService {
        return this._utilsService;
    }
    getSeasonInfo(): SeasonInfo {
        return this._seasonInfo;
    }
    getDataSet(): ParentCalculationDataItem[] {
        return this._dataSet;
    }
    setDataSet(dataSet: ParentCalculationDataItem[]) {
        this._dataSet = dataSet;
    }
    getViewDate(): Date {
        return this._viewDate;
    }
    setRetrievalMode(retrievalMode: RetrievalMode) {
        this._retrievalMode = retrievalMode;
    }
    getRetrievalMode(): RetrievalMode {
        return this._retrievalMode;
    }
    getViewPeriodWeeksWithYear(): number[] {
        return this._viewPeriodWeeksWithYear;
    }
    getViewWeekWithYear(): number {
        return this._viewWeekWithYear;
    }
    setCalculatorConfiguration(calculatorConfiguration: CalculatorInterface[][]) {
        this._calculatorConfiguration = calculatorConfiguration;
    }
    refreshTotalDataSet() {
        throw new Error("Method not implemented.");
    }

    getReturnsInfo(): ReturnsInfo {
        return this._returnsInfo;
    }
    getUserConfig(): UserConfig {
        return this._userConfigService.getUserConfig();
    }

    getChildDataSets(): ParentCalculationDataItem[][] {
        throw new Error("Method not implemented.");
    }

    getPreviousAdjustedRPercent(): number {
        return this.previousAdjustedRPercent;
    }

    getInitialAdjustedRPercent(): number {
        return this.initialAdjustedRPercent;
    }

    initDataSet(parentPlanningViewDataResponse: ParentPlanningViewDataResponse, seasonInfo: SeasonInfo, viewDate: Date, selectedOptions: SelectedOptions) {
        this._dataSet = parentPlanningViewDataResponse.weekData;
        this._childSeasonsDataSet = parentPlanningViewDataResponse.seasonData.filter((x: SeasonDataItem) => x.seasonName != null);
        this._departmentsHasDemDelButNoReturns = parentPlanningViewDataResponse.seasonData.filter((x: SeasonDataItem) => x.departmentIntegrationKey != null 
                            && (x.sumOfDemDelPlan > 0 && x.unAdjustedBudgetedReturnPercent == 0 && x.unAdjustedReturnPercent == 0));
        this._seasonInfo = seasonInfo;
        this._viewDate = viewDate;
        this._viewWeekWithYear = this._utilsService.getWeekNumber(true, this._viewDate);
        this._viewPeriodWeeksWithYear = this._utilsService.getWeeksForPeriod(this._viewDate);

        this._setTitle();
        this._setDefaultRetrievalModes();
        this._configureReturnsInfo(parentPlanningViewDataResponse.seasonData);
        this._arrangeDataSet();
        //configure calculators
        this._configureCalculatorsForInitialLoad();

        this.previousAdjustedRPercent = this.initialAdjustedRPercent = this._returnsInfo.adjustedReturnPercent;
    }
    private _arrangeDataSet() {

        let weekList = this._dataSet.map(el => el.weekName);
        this.uniqueWeekList = weekList.filter((item, i, ar) => ar.indexOf(item) === i);
    }
    public addChangeEventListener(changeHandler: any) {
        this._changeEventListeners.push(changeHandler);
    }

    private updatePreviousDataset() {
        this._previousDataSet = [];
        this._previousDataSet = _.cloneDeep(this._dataSet);
        if (this._intialDataSet == undefined || this._intialDataSet == null || this._intialDataSet.length == 0) {
            this._intialDataSet = _.cloneDeep(this._dataSet);
        }
    }

    public handleEvent(event: DepartmentEvent) {
        // bypass validation for previous with old child drivers, since the aggregated driver is already validated
        if (this._seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOldChild || event.validateEvent(this)) {

            this.updatePreviousDataset();

            event.applyEvent(this);
            event.configureCalculators(this);
            this.calculate();

            // Retrieval mode change event causes a full refresh of data from the backend, hence the other calculators have to be run again
            if (event.getEventType() == DepartmentEventType.RetrievalMode) {
                this._configureCalculatorsAfterApiFetch();
                this.calculate();
            }

            if (this._seasonInfo.seasonPlanningType != SeasonPlanningType.PreviousWithOldChild && (!this._utilsService.isNotNullOrUndefined(event.undoEvent) || !event.undoEvent)) {
                // add event to history 
                this._planningViewActionHistoryService.addActionEvent(event, this);
            }

            // trigger change event
            this._changeEventListeners.forEach(changeListener => {
                changeListener();
            });
        }
    }

    public getItemsForSave(userConfig: UserConfig): DepartmentSaveItem {

        this._cacheDepartmentSaveItem = null;

        let saveItem: DepartmentSaveItem = {
            weekData: [],
            // periodData: [],
            seasonData: []
        }

        // weekdata calculation
        this._dataSet.forEach((parentCalculationDataItem: ParentCalculationDataItem) => {

            if (this._intialDataSet == undefined || this._intialDataSet == null)
                this._intialDataSet = _.cloneDeep(this._dataSet);
            //#region "Week Level"
            var weekItemRecord: ParentCalculationDataItem = _.find(this._dataSet, (x: ParentCalculationDataItem) => x.weekName == parentCalculationDataItem.weekName);

            var weekIntitItemRecord: ParentCalculationDataItem = _.find(this._intialDataSet, (x: ParentCalculationDataItem) => x.weekName == parentCalculationDataItem.weekName);

            if ((parentCalculationDataItem.demDelPlan != null && weekItemRecord.inputForecastGrossPeriodic != (Math.round(weekIntitItemRecord.inputForecastGrossPeriodic * 1000) / 1000)) ||
                // if demdel is null/deleted and raw season data item has values then
                (parentCalculationDataItem.demDelPlan === null && (weekIntitItemRecord != null && weekIntitItemRecord != undefined && weekIntitItemRecord.demDelPlan != null && weekIntitItemRecord.demDelPlan != undefined)) ||
                (parentCalculationDataItem.inputRAndDForecastPeriodic != null && weekItemRecord.inputRAndDForecastPeriodic != (Math.round(weekIntitItemRecord.inputRAndDForecastPeriodic * 1000) / 1000)) ||
                (parentCalculationDataItem.inputRAndDForecastPeriodic === null &&
                    (weekIntitItemRecord != null && weekIntitItemRecord != undefined && weekIntitItemRecord.rAndDPlan != null && weekIntitItemRecord.rAndDPlan != undefined)) ||
                (parentCalculationDataItem.addRemoveMSek != null && weekItemRecord.addRemoveMSek != weekIntitItemRecord.addRemoveMSek) ||
                // if addRemoveMSek is null/deleted now and raw season data item had values then               
                (parentCalculationDataItem.addRemoveMSek === null && (weekIntitItemRecord != null && weekIntitItemRecord != undefined && weekIntitItemRecord.addRemoveMSek != null)) ||
                (parentCalculationDataItem.addMovesMSek != null && weekItemRecord.addMovesMSek != weekIntitItemRecord.addMovesMSek) ||
                // if addMovesMSek is null/deleted now and raw season data item had values then     
                (parentCalculationDataItem.addMovesMSek === null && (weekIntitItemRecord != null && weekIntitItemRecord != undefined && weekIntitItemRecord.addMovesMSek != null))
            ) {


                let weekLevelSaveItem: WeekSaveItem = {
                    seasonId: weekItemRecord.seasonName,
                    weekId: weekItemRecord.weekName,
                }

                if (this._utilsService.isNotNullOrUndefined(parentCalculationDataItem.demDelPlan)) {
                    weekLevelSaveItem.demDelPlanTSek = parentCalculationDataItem.demDelPlan;
                    weekLevelSaveItem.previousGoal = weekIntitItemRecord.inputForecastGrossPeriodicOriginal || weekIntitItemRecord.inputForecastGrossPeriodic || weekIntitItemRecord.grossTaktPeriodic;
                    weekLevelSaveItem.currentGoal = weekItemRecord.inputForecastGrossPeriodic;
                }

                else if (weekItemRecord.demDelPlan != null && weekItemRecord.demDelPlan != undefined) {
                    weekLevelSaveItem.demDelPlanTSek = null;
                    weekLevelSaveItem.previousGoal = weekIntitItemRecord.inputForecastGrossPeriodicOriginal || weekIntitItemRecord.inputForecastGrossPeriodic || weekIntitItemRecord.grossTaktPeriodic;
                    weekLevelSaveItem.currentGoal = null;
                }

                if (this._utilsService.isNotNullOrUndefined(parentCalculationDataItem.inputRAndDForecastPeriodic)) {
                    weekLevelSaveItem.rAndDPlanPercent = parentCalculationDataItem.inputRAndDForecastPeriodic;
                    weekLevelSaveItem.rAndDGroundPercentWeekly = weekIntitItemRecord.groundRAndDPercentPeriodic;
                    weekLevelSaveItem.previousRAndDPlanPercent = weekIntitItemRecord.inputRAndDForecastPeriodic;
                    weekLevelSaveItem.rAndDPlan = parentCalculationDataItem.rAndDPlan;
                }

                weekLevelSaveItem.previousAddRemoveMSek = weekIntitItemRecord.addRemoveMSek;
                weekLevelSaveItem.addRemoveMSek = parentCalculationDataItem.addRemoveMSek;

                weekLevelSaveItem.previousAddMovesMSek = weekIntitItemRecord.addMovesMSek;
                weekLevelSaveItem.addMovesMSek = parentCalculationDataItem.addMovesMSek;

                saveItem.weekData.push(weekLevelSaveItem);

                let seasonType = this.getSeasonInfo();

                if (seasonType.seasonPlanningType == SeasonPlanningType.PreviousWithOld && userConfig.planningViewOptions.areOldSeasonsExcluded == false) {

                    for (let index = 1; index < seasonType.seasonCodeNames.length; index++) {

                        let oldSeasonId = seasonType.seasonCodeNames[index];

                        let oldweekLevelSaveItem = _.cloneDeep(weekLevelSaveItem);

                        oldweekLevelSaveItem.seasonId = parseInt(oldSeasonId);

                        saveItem.weekData.push(oldweekLevelSaveItem);
                    }

                }
            }
        });

        // to save seasons returns data
        // it should not be previous and old
        // it should not be store and omni
        // the adjusted returns percent should have changed from the initial value

        let uConfig = this._userConfigService.getUserConfig();

        if (
            this._seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this._seasonInfo.seasonPlanningType != SeasonPlanningType.PreviousWithOld
            &&
            uConfig.planningViewOptions.channel != ChannelType.Store && uConfig.planningViewOptions.channel != ChannelType.Omni
            &&
            this._returnsInfo.adjustedReturnPercent != this.initialAdjustedRPercent
        ) {
            let seasonSaveItems: DepartmentSeasonSaveItem[] = [];

            // check if there existed a adjusted returns percent previously, if so rPercentChangeFactor is new r adjusted percent/ inital adjusted r percent
            // else its new r percent/unadjusted r percent
            const rPercentChangeFactor = this._utilsService.isNotNullUndefinedOrZero(this.initialAdjustedRPercent) ? this._returnsInfo.adjustedReturnPercent / this.initialAdjustedRPercent : this._returnsInfo.adjustedReturnPercent / this._returnsInfo.unAdjustedReturnPercent;

            this._childSeasonsDataSet.forEach((item: SeasonDataItem) => {
                const newDepartmentLevelAdjustedReturnPercent = this._utilsService.isNotNullUndefinedOrZero(item.adjustedReturnPercent) ? item.adjustedReturnPercent * rPercentChangeFactor : item.unAdjustedReturnPercent * rPercentChangeFactor;
                seasonSaveItems.push({
                    seasonIntegrationKey: parseInt(item.seasonName),
                    departmentIntegrationKey: item.departmentIntegrationKey,
                    unAdjustedBudgetedReturnPercent: item.unAdjustedBudgetedReturnPercent,
                    unAdjustedReturnPercent: item.unAdjustedReturnPercent,
                    rPercentChangeFactor: rPercentChangeFactor,
                    adjustedReturnPercent: newDepartmentLevelAdjustedReturnPercent,
                    adjustedBudgetedReturnPercent: newDepartmentLevelAdjustedReturnPercent / item.returnFactorPercent,
                    returnFactorPercent: item.returnFactorPercent
                });
            });

            saveItem.seasonData = seasonSaveItems;
        }

        this._cacheDepartmentSaveItem = saveItem;

        return saveItem;
    }

    public refreshSeasonRawDataItem(userConfig: UserConfig) {
        //get save items and update the season raw data item
        let departmentSaveItem = this._cacheDepartmentSaveItem;

        if (departmentSaveItem.weekData.length > 0) {
            let weekLevelSaveData = departmentSaveItem.weekData;
            this._dataSet.forEach((rawDataItemRecord, index) => {

                let departmentDataItem = _.find(weekLevelSaveData, (saveItem: WeekSaveItem) => saveItem.weekId == rawDataItemRecord.weekName);

                if (this._utilsService.isNotNullOrUndefined(departmentDataItem) && rawDataItemRecord.weekName === departmentDataItem.weekId) {
                    rawDataItemRecord.demDelPlan = this._utilsService.isNotNullOrUndefined(departmentDataItem.demDelPlanTSek) ? departmentDataItem.demDelPlanTSek : null;
                    //rawDataItemRecord.plannedReturn = this._utilsService.isNotNullOrUndefined(departmentDataItem.returnPlanPmc) ? departmentDataItem.returnPlanPmc : null;
                    //rawDataItemRecord.addRemoveMSek = this._utilsService.isNotNullOrUndefined(departmentDataItem.addRemoveMSek) ? departmentDataItem.addRemoveMSek : null;
                    //rawDataItemRecord.addMovesMSek = this._utilsService.isNotNullOrUndefined(departmentDataItem.addMovesMSek) ? departmentDataItem.addMovesMSek : null;
                    rawDataItemRecord.rAndDPlan = this._utilsService.isNotNullOrUndefined(departmentDataItem.rAndDPlanPercent) ? departmentDataItem.rAndDPlan : null;
                }
            });
            this._intialDataSet = _.cloneDeep(this._dataSet);
        }

        this.initialAdjustedRPercent = this._returnsInfo.adjustedReturnPercent;

    }

    cacheInputRows() {

        this.previousInputItems = [];

        let previousInputItem: ParentCalculationDataItem = null;

        // set previous retrieval mode
        this.previousRetrievalMode = _.cloneDeep(this.retrievalMode);
        this.previousAdjustedRPercent = this._returnsInfo.adjustedReturnPercent;

        this.uniqueWeekList.forEach((weekWithYear: number, index: number) => {
            if (this._dataSet[index]) {
                previousInputItem = {
                    weekName: weekWithYear,
                    inputForecastGrossPeriodic: this._dataSet[index][CalculationDataItemType.InputForecastGrossPeriodic],
                    inputAddRemoveMSekWeekly: this._dataSet[index][CalculationDataItemType.InputAddRemoveMSekWeekly],
                    inputAddMovesMSekWeekly: this._dataSet[index][CalculationDataItemType.InputAddMovesMSekWeekly],
                    inputRAndDForecastPeriodic: this._dataSet[index][CalculationDataItemType.InputRAndDForecastPeriodic]
                }

                this.previousInputItems.push(previousInputItem);
            }
        });
    }

    calculate() {

        // reset all the temp variables used with the calculators before execution
        this._calculatorConfiguration.forEach((passCalculators: CalculatorInterface[], pass: number) => {
            passCalculators.forEach((passCalculator: CalculatorInterface) => {
                try {
                    if (passCalculator.calculatorType == CalculatorType.KpiCalculator) {
                        passCalculator.reset();
                    }
                }
                catch { }

            });
        });

        this._calculatorConfiguration.forEach((calculators: CalculatorInterface[], pass: number) => {

            let currentWeekItem: ParentCalculationDataItem = null;
            let previousWeekItem: ParentCalculationDataItem = null;
            let periodIndexes = null;
            let currentPeriodWeekItems: ParentCalculationDataItem[] = null;
            let weekList = this._dataSet.map(el => el.weekName);
            let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);


            // check for function calculators. Each function calculator should be in a separate pass. 
            if (calculators.length == 1 && calculators[0].calculatorType == CalculatorType.FunctionCalculator) {
                // execute function with null inputs
                calculators[0].execute(this);
            }
            else {
                uniqueWeekList.forEach((weekWithYear: number, index: number) => {
                    calculators.forEach((calculator: CalculatorInterface) => {
                        // if its a weekly calculator - run the calculator for each week 
                        // if its a periodic calculator - run the calculator for every 4th week 
                        if (calculator.timespan == CalculatorTimeSpan.Weekly ||
                            (calculator.timespan == CalculatorTimeSpan.Seasonal && index == uniqueWeekList.length - 1) ||
                            (calculator.timespan == CalculatorTimeSpan.Periodic && index % 4 == 0)) {
                            currentWeekItem = this._dataSet[index];
                            previousWeekItem = (index == 0) ? null : this._dataSet[index - 1];
                            periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
                            currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

                            calculator.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
                        }
                    });
                });
            }
        });
        if (this._intialDataSet == undefined || this._intialDataSet == null || this._intialDataSet.length == 0) {
            this._intialDataSet = _.cloneDeep(this._dataSet);
        }
        this.cacheInputRows();
    }

    private _getDataSetItemsByIndexes(indexes: number[]): ParentCalculationDataItem[] {
        let dataSetItems: ParentCalculationDataItem[] = [];
        indexes.forEach(i => {
            dataSetItems.push(this._dataSet[i]);
        });

        return dataSetItems;
    }

    private _configureReturnsInfo(seasonDataItems: SeasonDataItem[]) {

        if (seasonDataItems.length != 0) {

            //find the item which doest have departmentintegrationkey
            let seasonDataItem: SeasonDataItem = _.find(seasonDataItems, (x: SeasonDataItem) => (x.departmentIntegrationKey === 0 || x.departmentIntegrationKey === null));

            if (seasonDataItem) {
                let returnInfo: ReturnsInfo = {
                    unAdjustedReturnPercent: this._utilsService.roundNumber(seasonDataItem.unAdjustedReturnPercent, 2),
                    adjustedReturnPercent: seasonDataItem.adjustedReturnPercent == null ? null : this._utilsService.roundNumber(seasonDataItem.adjustedReturnPercent, 2),
                    unAdjustedBudgetedReturnPercent: this._utilsService.roundNumber(seasonDataItem.unAdjustedBudgetedReturnPercent, 2),
                    adjustedBudgetedReturnPercent: seasonDataItem.adjustedBudgetedReturnPercent == null ? null : this._utilsService.roundNumber(seasonDataItem.adjustedBudgetedReturnPercent, 2),
                    seasonName: seasonDataItems[0].seasonName,

                    departmentIntegrationKey: seasonDataItem.departmentIntegrationKey,
                    marketIntegrationKey: seasonDataItem.marketIntegrationKey,
                    marketType: seasonDataItem.marketType,
                    returnDistribution: seasonDataItems[0].returnDistribution,
                    returnFactorPercent: seasonDataItem.returnFactorPercent,
                    sumOfBudgetedReturns: seasonDataItem.sumOfBudgetedReturns,
                    sumOfDemDelFixed: seasonDataItem.sumOfDemDelFixed,
                    sumOfDemDelPlan: seasonDataItem.sumOfDemDelPlan,
                    sumOfReturnFixed: seasonDataItem.sumOfReturnFixed,
                    sumOfReturnPlan: seasonDataItem.sumOfReturnPlan,
                    sumOfDemDelPlanBeforeKPeriod: seasonDataItem.sumOfDemDelPlanBeforeKPeriod,
                    sumOfUnAdjustedBudgetedReturns: seasonDataItem.sumOfUnAdjustedBudgetedReturns
                }
                this._returnsInfo = returnInfo;
            }
            else {
                this._returnsInfo = new ReturnsInfo();
            }

        }
        else {
            this._returnsInfo = new ReturnsInfo();
        }
    }
    private _setDefaultRetrievalModes() {

        let isAssortmentCopy = this._userConfigService.getUserConfig().selectionViewOptions.organization.name == OrganizationType.Assortment;

        this._retrievalMode = this._utilsService.getDefaultRetrievalMode(this._seasonInfo, isAssortmentCopy);
    }

    private _setTitle() {
        this._title = this._seasonInfo.seasonCodeNames.join(', ');
    }

    public configureCalculatorsForClear() {
        this._configureCalculatorsForInitialLoad();
    }

    private _configureCalculatorsForInitialLoad() {
        this._calculatorConfiguration = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Add move/remove Input - weekly
        pass1Calculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Weekly, this))

        // System Goal - Periodic
        pass1Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // calculate r and d forecast input cell periodic 
        pass1Calculators.push(new PopulateRAndDForecastInputCellCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];
        pass2Calculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators

        // R&D% - Periodic
        pass2Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        pass2Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, this))

        this._calculatorConfiguration.push(pass2Calculators);

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];

        // Effective Sales Plan - Weekly
        pass3Calculators.push(new EffectiveRAndDPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // CS Percent - Season
        pass3Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, this))

        this._calculatorConfiguration.push(pass3Calculators);

    }

    private _configureCalculatorsAfterApiFetch() {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];

        // Demdel plan - Weekly
        pass1Calculators.push(new DemdelPlanCalculator(CalculatorTimeSpan.Weekly, this));

        // load pass calculators
        calculatorConfiguration.push(pass1Calculators);

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];

        // Effective Sales Plan - Weekly
        pass2Calculators.push(new EffectiveSalesPlanCalculator(CalculatorTimeSpan.Weekly, this));
        pass2Calculators.push(new EffectiveSalesPlanWithGroundCalculator(CalculatorTimeSpan.Weekly, this));

        // load pass calculators
        calculatorConfiguration.push(pass2Calculators);

        let passAlphaCalculators: CalculatorInterface[] = [];
        passAlphaCalculators.push(new ForecastGoalCalculator(CalculatorTimeSpan.Periodic, this))
        // load pass calculators
        calculatorConfiguration.push(passAlphaCalculators);

        //pass 3 
        let pass3Calculators: CalculatorInterface[] = [];

        pass3Calculators.push(new SalesForecastCalculator(CalculatorTimeSpan.Weekly, this))
        // load pass calculators
        calculatorConfiguration.push(pass3Calculators);

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];

        // Combined Sales Plan - Weekly
        pass4Calculators.push(new CombinedSalesPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // Budgeted Returns - Weekly
        pass4Calculators.push(new BudgetedReturnCalculator(CalculatorTimeSpan.Weekly, this))

        // load pass calculators
        calculatorConfiguration.push(pass4Calculators);

        // pass function
        let functionPassCalculators: CalculatorInterface[] = [];
        functionPassCalculators.push(new FunctionCalculator(null, this, this.updateReturnsInfo));
        calculatorConfiguration.push(functionPassCalculators);

        let returnsPassCalculators: CalculatorInterface[] = [];
        // Planned Returns - Weekly
        returnsPassCalculators.push(new PlannedReturnCalculator(CalculatorTimeSpan.Weekly, this))
        calculatorConfiguration.push(returnsPassCalculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];

        // Gross takt - Periodic
        pass5Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // Omni takt - Periodic
        pass5Calculators.push(new OmniTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // R&D Plan - Weekly
        pass5Calculators.push(new RAndDPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective R&D Plan - Weekly
        pass5Calculators.push(new EffectiveRAndDPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // Stock Forecast - Weekly
        pass5Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Stock Plan Fixed - Weekly
        pass5Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, this));
        // Outgoing Stock Prognosis - Weekly
        pass5Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, this));
        // R&D Forecast Percent - Periodic
        pass5Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // R&D Forecast Percent - Weekly
        pass5Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Weekly, this));


        // load pass calculators
        calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];

        //Coverage Calculator
        pass6Calculators.push(new PlannedCoverageCalculator(CalculatorTimeSpan.Weekly, this))

        // Stock Prognosis - Weekly
        pass6Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, this))

        // R&D Forecast - Weekly
        pass6Calculators.push(new RAndDForecastCalculator(CalculatorTimeSpan.Weekly, this))

        // S Percent- Weekly
        pass6Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // CS Percent - Seasonal
        pass6Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, this))
        // load pass calculators
        calculatorConfiguration.push(pass6Calculators);


        // pass 6
        let pass7Calculators: CalculatorInterface[] = [];
        // Combined RAndD Calculator - Weekly
        pass7Calculators.push(new CombinedRAndDCalculator(CalculatorTimeSpan.Weekly, this))

        // S Percent- Period
        pass7Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // Stock Prognosis - Periodic
        pass7Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        calculatorConfiguration.push(pass7Calculators);


        // pass 8
        let pass8Calculators: CalculatorInterface[] = [];
        // Combined Net Sales - weekly
        pass8Calculators.push(new CombinedNetSalesCalculator(CalculatorTimeSpan.Weekly, this))
        pass8Calculators.push(new BuyingCalculator(CalculatorTimeSpan.Weekly, this))
        // load pass calculators
        calculatorConfiguration.push(pass8Calculators);

        // pass 8
        let pass9Calculators: CalculatorInterface[] = [];
        // Net takt - Periodic
        pass9Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, this))
        // load pass calculators
        calculatorConfiguration.push(pass9Calculators);

        this.setCalculatorConfiguration(calculatorConfiguration);
    }

    updateReturnsInfo(driver: CalculatorDriverInterface) {

        if (
            driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOldChild &&
            driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOld &&
            driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Previous) {
            let utils = driver.getUtilsService();
            let returnsInfo = driver.getReturnsInfo();

            let seasonInfo = driver.getSeasonInfo();

            // if the event is within the start and end weeks of the current season
            let seasonStartWeek = utils.getStartWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));
            let seasonEndWeek = utils.getEndWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));

            let rangeDataSet = driver.getDataSetRange(seasonStartWeek, seasonEndWeek);
            let effectivePlanSum = 0;
            let budgetedReturnsSum = 0;

            // calculate the effective sales plan sum within the period
            rangeDataSet.forEach(rangeDataSetItem => {
                if (utils.isNotNullOrUndefined(rangeDataSetItem[CalculationDataItemType.DemDelPlanForReturns]))
                    effectivePlanSum += rangeDataSetItem[CalculationDataItemType.DemDelPlanForReturns];
                else
                    effectivePlanSum += rangeDataSetItem[CalculationDataItemType.CubeDemDelPlanForReturns];
            });

            // new range for BR
            seasonStartWeek = utils.getKPeriodStartWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));
            rangeDataSet = driver.getDataSetRange(seasonStartWeek, seasonEndWeek);
            // calculate the effective sales plan sum within the period
            rangeDataSet.forEach(rangeDataSetItem => {
                budgetedReturnsSum += rangeDataSetItem[CalculationDataItemType.UnAdjustedBudgetedReturn];
            });

            // update the sum of dem del plan
            //returnsInfo.sumOfDemDelPlan = returnsInfo.sumOfDemDelPlanBeforeKPeriod + effectivePlanSum;
            returnsInfo.sumOfDemDelPlan = effectivePlanSum;

            // dem del diff = (Dem Del / Dem Del Fixed)
            let demdelDiff = (utils.safeDivide(returnsInfo.sumOfDemDelPlan, returnsInfo.sumOfDemDelFixed));

            // update return factor percent
            // ReturnFactorPercent = Return Plan fixed * dem del diff) / Un Adjusted BR Sum
            returnsInfo.returnFactorPercent = utils.safeDivide(returnsInfo.sumOfReturnFixed * (demdelDiff), budgetedReturnsSum);


            if (utils.isNotNullOrUndefined(returnsInfo.adjustedReturnPercent)) {
                returnsInfo.adjustedBudgetedReturnPercent = utils.roundNumber(utils.safeDivide(returnsInfo.adjustedReturnPercent, returnsInfo.returnFactorPercent), 2);
            }
            else {
                returnsInfo.unAdjustedReturnPercent = utils.roundNumber(returnsInfo.unAdjustedBudgetedReturnPercent * returnsInfo.returnFactorPercent, 2);
            }

            driver.setReturnsInfo(returnsInfo);
        }
    }

    setSalesAggregatedPmDataSet(salesAggregatedDataItems: ParentCalculationDataItem[]) {

        this._dataSet.forEach((parentCalculationDataItem: ParentCalculationDataItem) => {

            const weekItemRecord: ParentCalculationDataItem = _.find(salesAggregatedDataItems, (x: ParentCalculationDataItem) => x.weekName == parentCalculationDataItem.weekName);

            // Update initial data set with aggregated values so they don't dissappear when user click clear
            let initialWeekItemRecord: ParentCalculationDataItem = _.find(this._intialDataSet, (x: ParentCalculationDataItem) => x.weekName == parentCalculationDataItem.weekName);

            if (weekItemRecord != null) {
                parentCalculationDataItem.aggregatedPMsAddRemoveMSek = weekItemRecord.aggregatedPMsAddRemoveMSek;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsAddRemoveMSek = weekItemRecord.aggregatedPMsAddRemoveMSek;
                parentCalculationDataItem.aggregatedPMsRAndDPercentPeriodic = weekItemRecord.aggregatedPMsRAndDPercentPeriodic;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsRAndDPercentPeriodic = weekItemRecord.aggregatedPMsRAndDPercentPeriodic;
                parentCalculationDataItem.aggregatedPMsGrossTaktPeriodic = weekItemRecord.aggregatedPMsGrossTaktPeriodic;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossTaktPeriodic = weekItemRecord.aggregatedPMsGrossTaktPeriodic;

                parentCalculationDataItem.aggregatedPMsCombinedSalesPlanWeekly = weekItemRecord.aggregatedPMsCombinedSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsCombinedSalesPlanWeekly = weekItemRecord.aggregatedPMsCombinedSalesPlanWeekly;
                parentCalculationDataItem.aggregatedPMsEffectiveSalesPlanWeekly = weekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly = weekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly;

                parentCalculationDataItem.aggregatedPMsGrossSales = weekItemRecord.aggregatedPMsGrossSales;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSales = weekItemRecord.aggregatedPMsGrossSales;
                parentCalculationDataItem.aggregatedPMsGrossSalesGround = weekItemRecord.aggregatedPMsGrossSalesGround;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSalesGround = weekItemRecord.aggregatedPMsGrossSalesGround;
                parentCalculationDataItem.aggregatedPMsRAndDPlan = weekItemRecord.aggregatedPMsRAndDPlan;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsRAndDPlan = weekItemRecord.aggregatedPMsRAndDPlan;

                parentCalculationDataItem.aggregatedPMsGrossSalesLy = weekItemRecord.aggregatedPMsGrossSalesLy;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSalesLy = weekItemRecord.aggregatedPMsGrossSalesLy;
                parentCalculationDataItem.aggregatedPMsGrossSales2Ground = weekItemRecord.aggregatedPMsGrossSales2Ground;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSales2Ground = weekItemRecord.aggregatedPMsGrossSales2Ground;
                parentCalculationDataItem.aggregatedPMsIsSaved = weekItemRecord.aggregatedPMsIsSaved;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsIsSaved = weekItemRecord.aggregatedPMsIsSaved;

                //main copy system goal 
                parentCalculationDataItem.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;

                parentCalculationDataItem.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                parentCalculationDataItem.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;

                parentCalculationDataItem.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                parentCalculationDataItem.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                parentCalculationDataItem.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
            }
        });

        this.hasSalesAggregatedPmDataSetLoaded = true;
    }

    setStashDataDataSet(stashDataItem: DepartmentSaveItem) {
        //apply the week data changes 
        this._dataSet.forEach((parentCalculationDataItem: ParentCalculationDataItem) => {

            var weekItemRecord: WeekSaveItem = _.find(stashDataItem.weekData, (x: WeekSaveItem) => x.weekId == parentCalculationDataItem.weekName && x.seasonId == parentCalculationDataItem.seasonName);

            if (weekItemRecord != null) {
                parentCalculationDataItem.inputForecastGrossPeriodic = weekItemRecord.currentGoal;
                parentCalculationDataItem.inputForecastGrossPeriodicOriginal = weekItemRecord.currentGoal;
                if (this._utilsService.isNotNullOrUndefined(weekItemRecord.currentGoal)) {
                    this.rowInputsDirty = true;
                    parentCalculationDataItem.forecastGoalPeriodic = weekItemRecord.currentGoal;
                }
                parentCalculationDataItem.demDelPlan = weekItemRecord.demDelPlanTSek;
                parentCalculationDataItem.inputRAndDForecastPeriodic = weekItemRecord.rAndDPlanPercent;
                parentCalculationDataItem.rAndDPlan = weekItemRecord.rAndDPlan;
                parentCalculationDataItem.addRemoveMSek = weekItemRecord.addRemoveMSek;
                parentCalculationDataItem.inputAddRemoveMSekWeekly = weekItemRecord.addRemoveMSek;
                parentCalculationDataItem.inputAddMovesMSekWeekly = weekItemRecord.addMovesMSek;
                parentCalculationDataItem.addMovesMSek = weekItemRecord.addMovesMSek;
                this.touched = true;
            }

        });
        //apply Season level returns changes 
        if (this.getUserConfig().planningViewOptions.channel == ChannelType.Online) {
            var seasonItemRecord: DepartmentSeasonSaveItem = _.find(stashDataItem.seasonData, (x: DepartmentSeasonSaveItem) => x.seasonIntegrationKey == parseInt(this._seasonInfo.seasonCodeNames[0]));

            if (seasonItemRecord != null) {
                this._returnsInfo.adjustedBudgetedReturnPercent = seasonItemRecord.adjustedBudgetedReturnPercent;
                this._returnsInfo.adjustedReturnPercent = this._utilsService.precisionRound(seasonItemRecord.adjustedReturnPercent, 2);
                this._returnsInfo.returnFactorPercent = seasonItemRecord.returnFactorPercent;
                this._returnsInfo.unAdjustedReturnPercent = this._utilsService.precisionRound(seasonItemRecord.unAdjustedReturnPercent, 2);
                this.touched = true;
                this.rPercentDirty = true;
            }
        }
        this._configureCalculatorsAfterApiFetch();
        this.calculate();
    }

    setSalesSystemGoalInAssortmentDataSet(salesGoalInAssortmentDataItems: ParentCalculationDataItem[]) {
        this._dataSet.forEach((parentCalculationDataItem: ParentCalculationDataItem) => {

            var weekItemRecord: ParentCalculationDataItem = _.find(salesGoalInAssortmentDataItems, (x: ParentCalculationDataItem) => x.weekName == parentCalculationDataItem.weekName);

            if (weekItemRecord != null) {
                parentCalculationDataItem.salesMainCopyCombinedSalesPlanWeekly = weekItemRecord.salesMainCopyCombinedSalesPlanWeekly;
                parentCalculationDataItem.salesMainCopyGrossSalesGround = weekItemRecord.salesMainCopyGrossSalesGround;
                parentCalculationDataItem.salesMainCopySystemGoal = weekItemRecord.salesMainCopySystemGoal;
            }

        });

        this.hasSalesSystemGoalInAssortmentLoaded = true;

    }
}
