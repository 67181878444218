import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/modules/shared/services/authentication.service';
import { FeatureFlagService } from 'src/app/modules/shared/services/feature-flag.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { Constants } from 'src/app/modules/shared/types/constants';
import { UserInfo } from 'src/app/modules/shared/types/user-info';
import { UserSettingsService } from 'src/app/modules/user-settings/services/user-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'omnitdp-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  // UI Binding variables
  title: string;
  appVersion: string;
  isImpersonating: boolean;
  impersonatedUserEmail: string;
  bgImgUrl: string = null;

  navMessageControlText: string = "";

  // class variables
  userInfo:UserInfo = null;

  constructor(
    public _authService: AuthenticationService,
    private _userSettingsService: UserSettingsService,
    private _featureFlagService: FeatureFlagService,
    public _utilsService: UtilsService
  ) {

  }

  reloadSite()
  {
    window.location.href="/";
  }

  ngOnInit(): void {
    // set title
    this.title = environment.appTitle;

    // set title and favicon
    document.getElementById('title').innerText = environment.appTitle;
    document.getElementById('tabIcon').setAttribute('href', environment.favIcon);

    this._userSettingsService.userInfoSubject.subscribe(
      (userInfo: UserInfo) => {
        this.userInfo = userInfo;

        if (this.userInfo) {
          this.onUserInfoUpdate();
        }

      }
    )

    // fetch the build number/version number
    this._featureFlagService.appConfigDataSubject.subscribe(
      () => {
        let versionNumber_AppConfig = this._featureFlagService.getAppConfigByKey(Constants.VERSIONNUMBER);
        if(this._utilsService.isNotNullOrUndefined(versionNumber_AppConfig))
        {
          this.appVersion = versionNumber_AppConfig.value;
        }

        let navMessage_AppConfig = this._featureFlagService.getAppConfigByKey(Constants.NAVMESSAGE);
        if(this._utilsService.isNotNullOrUndefined(navMessage_AppConfig) && !this._utilsService.isNullOrEmpty(navMessage_AppConfig.value))
        {
          this.navMessageControlText = navMessage_AppConfig.value;
        }

        let toolBarBackgroundImage_AppConfig = this._featureFlagService.getAppConfigByKey(Constants.TOOLBARBGIMG);
        if(this._utilsService.isNotNullOrUndefined(toolBarBackgroundImage_AppConfig) && !this._utilsService.isNullOrEmpty(toolBarBackgroundImage_AppConfig.value))
        {
          this.bgImgUrl = toolBarBackgroundImage_AppConfig.value;
        }
      }
    );
  }


  onUserInfoUpdate() {
    
    let impersonationPermission = this.userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);

    if (impersonationPermission != undefined) {

      if(impersonationPermission.settings.isCurrentlyImpersonating)
      {        
        this.isImpersonating = true;
        this.impersonatedUserEmail = impersonationPermission.settings.impersonatedUserEmail;
      }
      else
      {
        this.isImpersonating = false;
        this.impersonatedUserEmail = null;
      }

    }
  }
}
