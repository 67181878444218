import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettingsService } from '../../user-settings/services/user-settings.service';
import { UserInfo } from '../types/user-info';
import { Constants } from '../types/constants';


@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  userInfo: UserInfo = null;
  enableInterception: boolean = false;

  constructor(
    private _userSettingsService: UserSettingsService
  ) {

    _userSettingsService.userInfoSubject.subscribe(
      (userInfo: UserInfo) => {
        this.userInfo = userInfo;

        if (this.userInfo) {
          this.onUserInfoUpdate();
        }
      }

    )
  }

  onUserInfoUpdate() {

    let impersonationPermission = this.userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);

    this.enableInterception = (impersonationPermission != undefined)

  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.userInfo) {

      let impersonationPermission = this.userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);

      if (this.enableInterception && impersonationPermission.settings.isCurrentlyImpersonating) {
        let impersonatedUserEmail = impersonationPermission.settings.impersonatedUserEmail;
        request = request.clone({
          setHeaders: {
            ImpersonateUserEmail: impersonatedUserEmail,
          }
        });
      }
    }

    return next.handle(request);
  }
}
