import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class SystemGoalCalculator extends AbstractCalculator {

    // Periodic
    // ------
    // Sets System Goal Periodic

    // Dependencies
    // ------------
    // Cube Dem Del Plan (raw)
    // Gross Sales Ground (raw)

    // Calculates
    // ----------
    // System Goal Periodic (calculated)

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let effectiveSystemGoalPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.EffectiveSystemGoalPlanWeekly);
        let grossSalesGroundSum = 0;

        currentPeriodWeekItems.forEach(item => {
                grossSalesGroundSum += item[CalculationDataItemType.GrossSalesGround];
        });

        if (utils.isNotNullUndefinedOrZero(grossSalesGroundSum)) {
            value = effectiveSystemGoalPlanSum / grossSalesGroundSum * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.SystemGoalPeriodic] = value;
        });
    }
}