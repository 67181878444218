import { DepartmentCalculationDataItem } from "../../planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "../../planning-view/types/parent-calculation-data-item";
import { CalculatorDriverInterface } from "./calculator-driver-interface";
import { CalculatorInterface } from "./calculator-interface";
import { CalculatorTimeSpan } from "./calculator-timespan";
import { CalculatorType } from "./calculator-type";
import { ParentCalculatorDriverInterface } from "./parent-calculator-driver-interface";
import { ReadonlyDepartmentCalculatorDriverInterface } from "./readonly-department-calculator-driver-interface";

export abstract class AbstractCalculator implements CalculatorInterface
{
    timespan: CalculatorTimeSpan;
    protected calculatorDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    calculatorType: CalculatorType = CalculatorType.KpiCalculator;
    functionBody?: (args?: any) => void;

    constructor(timespan: CalculatorTimeSpan, calculatorDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface, functionBody?: (args?: any) => void) {
        this.timespan = timespan;
        this.calculatorDriver = calculatorDriver
        if(functionBody != undefined && functionBody != null)
        {
            this.calculatorType = CalculatorType.FunctionCalculator;
            this.functionBody = functionBody;
        }
    }
        
    reset() {
        throw new Error("Method not implemented.");
    }

    calculate(
                currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem,
                currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], 
                previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem                
            )
    {

        if(this.timespan == CalculatorTimeSpan.Weekly)
        {
            this._calculateWeeklyValue(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
        }
        else if(this.timespan == CalculatorTimeSpan.Periodic)
        {
            this._calculatePeriodicValue(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
        } 
        else if(this.timespan == CalculatorTimeSpan.Seasonal)
        {
            this._calculateSeasonalValue(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
        }       
    }

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        throw new Error("Method not implemented.");
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        throw new Error("Method not implemented.");
    }

    _calculateSeasonalValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        throw new Error("Method not implemented.");
    }
}