import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ClearAllDialogComponent } from './components/clear-all/clear-all-dialog.component';
import { CopyForecastDialogComponent } from './components/copy-forecast-dialog/copy-forecast-dialog.component';
import { ParentSeasonTableComponent } from './components/parent-season-table/parent-season-table.component';
import { PlanningViewComponent } from './components/planning-view/planning-view.component';
import { PublishDialogComponent } from './components/publish-dialog/publish-dialog.component';
import { ReadonlySeasonTableComponent } from './components/readonly-season-table/readonly-season-table.component';
import { SaveTrickleDownDialogComponent } from './components/save-trickle-down-dialog/save-trickle-down-dialog.component';
import { SaveWarningDialogComponent } from './components/save-warning-dialog/save-warning-dialog.component';
import { SaveWarningTrickleDownDialogComponent } from './components/save-warning-trickle-down-dialog/save-warning-trickle-down-dialog.component';
import { SeasonTableComponent } from './components/season-table/season-table.component';
import { SimulationViewComponent } from './components/simulation-view/simulation-view.component';
import { OrganizationSummaryViewComponent } from './components/organization-summary-view/organization-summary-view.component';
import { PlanningViewRoutingModule } from './planning-view-routing.module';
import { PlanningViewDeactivateGuard } from './services/planning-view-deactivate-guard';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
    imports: [
        SharedModule,
        PlanningViewRoutingModule,
        MatDialogModule,
    ],
    declarations: [
        PlanningViewComponent,
        SeasonTableComponent,
        PublishDialogComponent,
        ParentSeasonTableComponent,
        ReadonlySeasonTableComponent,
        SimulationViewComponent,
        SaveWarningDialogComponent,
        ClearAllDialogComponent,
        SaveTrickleDownDialogComponent,
        SaveWarningTrickleDownDialogComponent,
        OrganizationSummaryViewComponent,
        CopyForecastDialogComponent
    ],
    exports: [       
    ],
    providers:[
        PlanningViewDeactivateGuard
    ]
})
export class PlanningViewModule { }
 