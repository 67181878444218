import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogBoxComponent } from '../components/dialog-box/dialog-box.component';


// Service to display loading animations
@Injectable({
    providedIn: 'root'
})
export class UiHelperService {

    dialogRef:MatDialogRef<DialogBoxComponent, any> = null;
    constructor(private _snackBar: MatSnackBar, public dialog: MatDialog) {


    }

    showSnackBarMessage(message: string) {
        this._snackBar.open(message, "OK", {
            duration: 3 * 2000,
            verticalPosition: 'top',
            horizontalPosition: 'center'
        })
    }

    showPopupAlert(message) {
    

        if(this.dialogRef == null || this.dialogRef.getState() == MatDialogState.CLOSED)
        {
            this.dialogRef = this.dialog.open(DialogBoxComponent, {
                data: {
                    message: message,
                    buttonText: {
                        cancel: 'OK'
                    }
                },
            });
        }
        
    }
}


