import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class GroundSPercentCalculator extends AbstractCalculator {
    // Weekly
    // ------
    // Sets Ground S Percent Weekly

    // Dependencies
    // ------------
    // Combined Sales Ly Weekly (calculated)
    // Stock Prognosis Ly (raw)

    // Calculates
    // ----------
    // Ground S Percent Weekly


    // Periodic
    // ------
    // Sets Ground S Percent Periodic

    // Dependencies
    // ------------
    // Average of Ground S Percent Weekly(calculated)

    // Calculates
    // ----------
    // Ground S Percent Periodic (calculated)

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        // default to null
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        let effectiveStockGround =  utils.isNotNullUndefinedOrZero(currentWeekItem.stockGround) ?
                currentWeekItem.stockGround : currentWeekItem.stockPrognosisLy;      

        let combinedAndStockGroundSum = (currentWeekItem.grossSalesGround + effectiveStockGround);

        if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGround]) &&
          utils.isNotNullUndefinedOrZero(combinedAndStockGroundSum)) {
          value = currentWeekItem.grossSalesGround /combinedAndStockGroundSum * 100;
        }

        currentWeekItem[CalculationDataItemType.GroundSPercentWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();


        let sPercentGroundWeeklySum = 0;

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            sPercentGroundWeeklySum += periodWeekItem.groundSPercentWeekly;

        });


        if (utils.isNotNullUndefinedOrZero(sPercentGroundWeeklySum)) {

            value = sPercentGroundWeeklySum / currentPeriodWeekItems.length;
        }
        else {
            value = 0;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.GroundSPercentPeriodic] = value;
        });
    }
}