<div *ngIf="skeletonType == 'planning-table'" class="ph-item seasonTable-placeholder">
    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-12 big"></div>
        </div>
        <div class="ph-picture big"></div>
        <div class="ph-picture big"></div>
        <div class="ph-picture big"></div>
    </div>
</div>

<div *ngIf="skeletonType == 'planning-view-chart'" class="ph-item seasonTable-placeholder">
    <div class="ph-col-12">
        <div class="ph-row">
            <div class="ph-col-4 big"></div>
        </div>
        <div class="ph-row">
            <div class="ph-col-4 big"></div>
        </div>
        <div class="ph-row">
            <div class="ph-col-12 big"></div>
        </div>
        <div class="ph-picture big"></div>
    </div>
</div>

<div *ngIf="skeletonType == 'selection-view'">
    <div class="ph-item">
        <div class="ph-col-12">
            <div class="ph-row">
                <div class="ph-col-12 big"></div>
            </div>
            <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-8 empty big"></div>
            </div>
            <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-6 empty"></div>
            </div>
            <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-8 empty big"></div>
            </div>
            <div class="ph-row">
                <div class="ph-col-6 big"></div>
                <div class="ph-col-6 empty"></div>
            </div>
            <div class="ph-row">
                <div class="ph-col-12 big"></div>
            </div>
            <div class="ph-picture big"></div>
            <div class="ph-row">
                <div class="ph-col-4 big"></div>
                <div class="ph-col-2 empty"></div>
                <div class="ph-col-6 big"></div>
            </div>
            <div class="ph-picture big"></div>
            <div class="ph-row">
                <div class="ph-col-2 big"></div>
            </div>
        </div>
    </div>

</div>


<div class="form-group" *ngIf="skeletonType == 'side-nav'">
    <div class="row">

        <div class="ph-item">
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty big"></div>
                </div>
                <div class="ph-picture big"></div>

                <div class="ph-row">
                    <div class="ph-col-12 big"></div>
                </div>
                <div class="ph-picture big"></div>
                <div class="ph-row">
                    <div class="ph-col-12 big"></div>
                </div>
                <div class="ph-picture big"></div>
                <div class="ph-row">
                    <div class="ph-col-2 big"></div>
                </div>
            </div>
        </div>

    </div>
</div>

<div class="col-12" *ngIf="skeletonType == 'copies'">
    <div class="row">
        <div class="ph-item">
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty big"></div>
                </div>
            </div>
            <div class="ph-col-6">
                <div class="ph-picture big"></div>
            </div>
            <div class="ph-col-6">
                <div class="ph-picture big"></div>
            </div>
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-4 big"></div>
                    <div class="ph-col-8 empty big"></div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="col-12" *ngIf="skeletonType == 'totalviewrequeststable'">
    <div class="row">
        <div class="ph-item seasonTable-placeholder ng-star-inserted">
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-4 empty"></div>
                    <div class="ph-col-4 big
                                      "></div>
                </div>
            </div>
            <div class="ph-col-12">
                <div class="ph-row">
                    <div class="ph-col-2 empty"></div>
                    <div class="ph-col-8 big
                                      "></div>
                </div>
            </div>
        </div>
    </div>
</div>