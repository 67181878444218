import * as _ from 'lodash';
import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { ReturnsInfo } from "src/app/modules/planning-view/types/returns-info";
import { ChannelType } from "../../types/channel-type";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class BudgetedReturnCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // SalesPlanFixed (Demdel plan fixed)
    // EffectiveSalesPlanWeekly
    // Returns Distribution

    // Calculates
    // ----------
    // Budgeted Return
    // UnAdjusted Budgeted Return

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let unAdjustedBudgetedReturnValue = 0;
        let adjustedBudgetedReturnValue = 0;
        const utils = this.calculatorDriver.getUtilsService();

        if (this.calculatorDriver.getUserConfig().planningViewOptions.channel == ChannelType.Online) {
            const dataset = this.calculatorDriver.getDataSet();
            const returnsInfo = this.calculatorDriver.getReturnsInfo();

            const currentWeekItemIndex = _.findIndex(dataset, dataItem => {
                return dataItem[CalculationDataItemType.WeekName] == currentWeekItem[CalculationDataItemType.WeekName];
            });

            const weekWiseReturnDistribution = [
                returnsInfo.returnDistribution.returnDistributionMatrix.week1,
                returnsInfo.returnDistribution.returnDistributionMatrix.week2,
                returnsInfo.returnDistribution.returnDistributionMatrix.week3,
                returnsInfo.returnDistribution.returnDistributionMatrix.week4,
                returnsInfo.returnDistribution.returnDistributionMatrix.week5,
                returnsInfo.returnDistribution.returnDistributionMatrix.week6
            ];

            if (currentWeekItemIndex != -1) {
                let weekWiseReturnDistributionIndex = 0;
                let weekIndex = currentWeekItemIndex - 1;

                // Different calculation for
                // Actual season
                // if the start week of the dataset is either 33 or 05
                // first six weeks calculations
                const seasonInfo = this.calculatorDriver.getSeasonInfo();
                const seasonPlanningType = seasonInfo.seasonPlanningType;
                const firstWeek = dataset[0][CalculationDataItemType.WeekName];
                const weekNumber = (firstWeek).toString().substring(4);
                const startWeek = utils.getStartWeekForSeason(Number(seasonInfo.seasonCodeNames[0]));
                let demDelPlanBeforeStartWeek = 0;

                this.calculatorDriver.getDataSetRange(firstWeek, startWeek - 1).forEach(item => {
                    if (utils.isNotNullOrUndefined(item[CalculationDataItemType.DemDelPlanForReturns]))
                        demDelPlanBeforeStartWeek += item[CalculationDataItemType.DemDelPlanForReturns];
                    else
                        demDelPlanBeforeStartWeek += item[CalculationDataItemType.CubeDemDelPlanForReturns];
                });

                if (seasonPlanningType == SeasonPlanningType.Actual &&
                    (weekNumber == "05" || weekNumber == "33") &&
                    (currentWeekItemIndex >= 0 && currentWeekItemIndex < 6)
                ) {
                    unAdjustedBudgetedReturnValue = this.initialSixWeeksCalculation(currentWeekItemIndex, returnsInfo, currentWeekItem, weekWiseReturnDistribution);
                    adjustedBudgetedReturnValue = this.initialSixWeeksCalculation(currentWeekItemIndex, returnsInfo, currentWeekItem, weekWiseReturnDistribution);
                }
                else {
                    // normal calculation
                    while (weekIndex > 0 && weekWiseReturnDistributionIndex <= 5) {
                        if (utils.isNotNullOrUndefined(dataset[weekIndex][CalculationDataItemType.DemDelPlanForReturns])) {
                            // If start week (21 or 49) then dem del plan for weeks before have been added to this weeks dem del plan, remove that here
                            const demDelPlan = (dataset[weekIndex][CalculationDataItemType.DemDelPlanForReturns] - (dataset[weekIndex][CalculationDataItemType.WeekName] == startWeek ? demDelPlanBeforeStartWeek : 0)) * weekWiseReturnDistribution[weekWiseReturnDistributionIndex];
                            adjustedBudgetedReturnValue += demDelPlan;
                            unAdjustedBudgetedReturnValue += demDelPlan;
                        }
                        else {
                            const cubeDemDelPlan = (dataset[weekIndex][CalculationDataItemType.CubeDemDelPlanForReturns] * weekWiseReturnDistribution[weekWiseReturnDistributionIndex]);
                            adjustedBudgetedReturnValue += cubeDemDelPlan;
                            unAdjustedBudgetedReturnValue += cubeDemDelPlan;
                        }

                        weekIndex--;
                        weekWiseReturnDistributionIndex++;
                    }

                    // check if adjusted r percent is not null
                    if (utils.isNotNullOrUndefined(returnsInfo.adjustedReturnPercent) && returnsInfo.adjustedReturnPercent.toString() != "") {
                        // calculate adjusted br percent
                        let adjustedBRPercent = parseFloat(returnsInfo.adjustedReturnPercent.toString()) / returnsInfo.returnFactorPercent;

                        adjustedBudgetedReturnValue = adjustedBudgetedReturnValue * adjustedBRPercent / 100;
                    }
                    else {
                        adjustedBudgetedReturnValue = adjustedBudgetedReturnValue * returnsInfo.unAdjustedBudgetedReturnPercent / 100;
                    }

                    //Unadjusted budgeted returns 
                    unAdjustedBudgetedReturnValue = unAdjustedBudgetedReturnValue * returnsInfo.unAdjustedBudgetedReturnPercent / 100;
                }
            }
        }

        currentWeekItem[CalculationDataItemType.AdjustedBudgetedReturn] = adjustedBudgetedReturnValue;
        currentWeekItem[CalculationDataItemType.UnAdjustedBudgetedReturn] = unAdjustedBudgetedReturnValue;
    }

    initialSixWeeksCalculation(currentWeekItemIndex: number, returns: ReturnsInfo, currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, weekWiseReturnDistribution: number[]) {
        let value = 0;
        const utils = this.calculatorDriver.getUtilsService();

        const rawDataItems = this.calculatorDriver.getRawDataItems()
        if (rawDataItems == null) {
            return value;
        }

        let rawDataItemIndex = _.findIndex(rawDataItems, item => {
            return item.weekName == currentWeekItem.weekName
        });

        // index should exist and should not be equal to the dataset index
        if (rawDataItemIndex != -1 && rawDataItemIndex != currentWeekItemIndex) {
            let weekWiseReturnDistributionIndex = 0;
            let weekCounter = rawDataItemIndex - 1;

            while (weekCounter > 0 && weekWiseReturnDistributionIndex <= 5) {
                value += (rawDataItems[weekCounter][CalculationDataItemType.SalesPlanFixed] * weekWiseReturnDistribution[weekWiseReturnDistributionIndex]);
                weekCounter--;
                weekWiseReturnDistributionIndex++;
            }

            // check if adjusted r percent is not null
            if (utils.isNotNullOrUndefined(returns.adjustedReturnPercent) && returns.adjustedReturnPercent.toString() != "") {
                // calculate adjusted br percent
                const adjustedBRPercent = parseFloat(returns.adjustedReturnPercent.toString()) / returns.returnFactorPercent;
                value = value * adjustedBRPercent / 100;
            }
            else {
                value = value * returns.unAdjustedBudgetedReturnPercent / 100;
            }
        }

        return value;
    }
}
