import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";
import { SeasonPlanningType } from "../../types/season-planning-type";
import * as _ from 'lodash';
import { ChannelType } from "../../types/channel-type";

export class AccShrinkLyCalculator extends AbstractCalculator {

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem) {


        let accShrinkLy = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let seasonInfo = this.calculatorDriver.getSeasonInfo();

        let seasonPlanningType = seasonInfo.seasonPlanningType;

        let nextSeason = utils.shiftSeasons(seasonInfo.seasonCodeNames[0], 1);

        let startWeekForNextSeason = utils.getStartWeekForSeason(parseInt(nextSeason));

        //shrinkage starts after 13 weeks from next season's startweek

        let validShrinkWeek = utils.shiftWeeks(startWeekForNextSeason, 13);

        let previousViewWeekWithYear = utils.shiftWeeks(this.calculatorDriver.getViewWeekWithYear(), -1);

        let dataSet = this.calculatorDriver.getDataSet();

        let currentWeek = currentWeekItem[CalculationDataItemType.WeekName];

        let channelType: ChannelType = this.calculatorDriver.getUserConfig().planningViewOptions.channel;

        if (seasonPlanningType == SeasonPlanningType.Actual || seasonPlanningType == SeasonPlanningType.Previous
            || seasonPlanningType == SeasonPlanningType.PreviousWithOld) {
            if (currentWeek >= previousViewWeekWithYear && currentWeek >= validShrinkWeek) {
                let nextWeekItem = _.find(dataSet, function (item) {
                    return item.weekName === utils.shiftWeeks(currentWeek, 1);
                });
                if (nextWeekItem) {
                    if (channelType == ChannelType.Online) {
                        accShrinkLy = nextWeekItem[CalculationDataItemType.CombinedSalesLyWeekly]
                            + nextWeekItem[CalculationDataItemType.StockGround]
                            - nextWeekItem[CalculationDataItemType.ReturnsGrossGround]
                            - currentWeekItem[CalculationDataItemType.StockGround]
                            + previousWeekItem[CalculationDataItemType.AccShrinkLy];
                    }
                    else {
                        accShrinkLy = nextWeekItem[CalculationDataItemType.CombinedSalesLyWeekly]
                            + nextWeekItem[CalculationDataItemType.StockPrognosisLy]
                            - nextWeekItem[CalculationDataItemType.ReturnsGrossGround]
                            - currentWeekItem[CalculationDataItemType.StockPrognosisLy]
                            + previousWeekItem[CalculationDataItemType.AccShrinkLy];
                    }
                }
                else {
                    if (channelType == ChannelType.Online) {
                        accShrinkLy = previousWeekItem[CalculationDataItemType.AccShrinkLy]
                            - currentWeekItem[CalculationDataItemType.StockGround];
                    }
                    else {
                        accShrinkLy = previousWeekItem[CalculationDataItemType.AccShrinkLy]
                            - currentWeekItem[CalculationDataItemType.StockPrognosisLy];
                    }
                }
            }
        }

        currentWeekItem[CalculationDataItemType.AccShrinkLy] = Math.max(accShrinkLy, 0);
    }
}
