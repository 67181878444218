import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class RAndDForecastCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // RAndDLY Percent Weekly
    // RAndD Forecast Percent Weekly   

    // Calculates
    // ----------
    // R And D Plan

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        // default to null
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();


        if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.InputRAndDForecastPeriodic] )) {
            value = currentWeekItem.combinedSalesPlanWeekly * (currentWeekItem[CalculationDataItemType.InputRAndDForecastPeriodic]  / 100);
        }

        else {
            value = currentWeekItem.rAndDLyPercentWeekly * currentWeekItem.combinedSalesPlanWeekly;
        }

        currentWeekItem[CalculationDataItemType.RAndDForecastWeekly] = value;
    }
}