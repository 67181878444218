import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class NetTaktCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets Net Takt Weekly

    // Dependencies
    // ------------
    // Net Sales Ground (raw)
    // Net Sales (raw)

    // Calculates
    // ----------
    // Net Takt Weekly


    // Periodic
    // ------
    // Sets Net Takt Periodic

    // Dependencies
    // ------------
    // Combined Net Sales Weekly (calculated)
    // Net Sales Ground (raw)

    // Calculates
    // ----------
    // Net Takt Periodic (calculated)

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        // Net Takt = Net Sales/Net Sales Ground
        if (
            utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.NetSalesGround])
        ) {
            value = (currentWeekItem[CalculationDataItemType.NetSales] / currentWeekItem[CalculationDataItemType.NetSalesGround]) * 100;
        }

        currentWeekItem[CalculationDataItemType.NetTaktWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 100;
        let utils = this.calculatorDriver.getUtilsService();
        let viewWeekWithYear = utils.getWeekNumber(true);
        let netSalesSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedNetSalesWeekly);
        let netSalesGroundSum = 0;

        currentPeriodWeekItems.forEach(item => {
                netSalesGroundSum += item[CalculationDataItemType.NetSalesGround];
        });

        if (utils.isNotNullUndefinedOrZero(netSalesGroundSum)) {
            value = netSalesSum / netSalesGroundSum * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.NetTaktPeriodic] = value;
        });

    }
}