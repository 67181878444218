import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class GroundGrossTaktCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets Ground Gross Takt Weekly

    // Dependencies
    // ------------
    // Gross Sales Ground (raw)
    // Gross Sales 2 Ground (raw)

    // Calculates
    // ----------
    // Ground Gross Takt Weekly


    // Periodic
    // ------
    // Sets Ground Gross Takt Periodic

    // Dependencies
    // ------------
    // Gross Sales Ground (raw)
    // Gross Sales 2 Ground (raw)
    // Start Week Sales Gross Ground (raw)

    // Calculates
    // ----------
    // Ground Gross Takt Periodic (calculated)


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        // Gross Takt = (Gross Sales/Ground Sales) * 100
        if (
            utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGroundLy]) &&
            utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.CombinedSalesLyWeekly])
        ) {
            value = ((currentWeekItem[CalculationDataItemType.CombinedSalesLyWeekly]) / (currentWeekItem[CalculationDataItemType.GrossSalesGroundLy])) * 100;
        }

        currentWeekItem[CalculationDataItemType.GroundGrossTaktWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        // Gross Takt Ground Periodic = Sum of Gross Sales Ground for period / Sum of Gross Sales Ground Ground for period
        //let grossSalesGroundWeekly = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.GrossSalesGround);
        let grossSalesGroundWeekly = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedSalesLyWeekly);
        let grossSales2GroundPeriodSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.GrossSalesGroundLy);
        //let startWeekSalesGrossGroundSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.StartWeekSalesGrossGround);

        if (
            utils.isNotNullUndefinedOrZero(grossSalesGroundWeekly) &&
            utils.isNotNullUndefinedOrZero(grossSales2GroundPeriodSum)
        ) {
            value = (grossSalesGroundWeekly) / grossSales2GroundPeriodSum * 100;
        }
        else {
            value = 0;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.GroundGrossTaktPeriodic] = value;
        });
    }
}