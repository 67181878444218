import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { ClearAllDialogComponent } from 'src/app/modules/planning-view/components/clear-all/clear-all-dialog.component';
import { SalesCopy } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { ClearAllDialogData } from 'src/app/modules/selection-view/types/clear-all-dialog-data';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { AddMovesEvent } from 'src/app/modules/shared/events/department-events/add-moves-event';
import { AddRemoveEvent } from 'src/app/modules/shared/events/department-events/add-remove-event';
import { ClearAllEvent } from 'src/app/modules/shared/events/department-events/clear-all-event';
import { DepartmentEvent } from 'src/app/modules/shared/events/department-events/department-event';
import { DepartmentEventInfo } from 'src/app/modules/shared/events/department-events/department-event-info';
import { DepartmentEventType } from 'src/app/modules/shared/events/department-events/department-event-type';
import { ForecastGrossEvent } from 'src/app/modules/shared/events/department-events/forecast-gross-event';
import { RAndDForecastPercentEvent } from 'src/app/modules/shared/events/department-events/r-and-d-forecast-percent-event';
import { RPercentEvent } from 'src/app/modules/shared/events/department-events/r-percent-event';
import { RetrievalModeEvent } from 'src/app/modules/shared/events/department-events/retrieval-mode-event';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { Constants } from 'src/app/modules/shared/types/constants';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { RetrievalMode } from 'src/app/modules/shared/types/retrieval-mode';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { SeasonType } from 'src/app/modules/shared/types/season-type';
import { SeasonViewType } from 'src/app/modules/shared/types/season-view-type';
import { EventHandlerService } from '../../services/event-handler-service';
import { PlanningViewActionHistoryService } from '../../services/planning-view-action-history-service';
import { SeasonTableInfo } from '../../types/api/season-table-info';
import { CalculationDataItemType } from '../../types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from '../../types/department-calculation-data-item';
import { InputLockStatus } from '../../types/input-lock-status-enum';
import { PlanningViewActionHistoryItem } from '../../types/planning-view-action-history-item';
import { ReturnsInfo } from '../../types/returns-info';
import { CopyForecastDialogComponent } from '../copy-forecast-dialog/copy-forecast-dialog.component';


@Component({
  selector: 'app-season-table',
  templateUrl: './season-table.component.html',
  styleUrls: ['./season-table.component.css']
})
export class SeasonTableComponent implements OnInit {

  @Input() seasonTableInfo: SeasonTableInfo = null;
  @Input() debugMode: boolean = true;
  @Input() currentWeek: number = null;
  @Input() selectedKpis: string[] = null;
  @Input() isMain: boolean;
  @Input() areOldSeasonsExcluded: boolean;
  @Input() channel: string;
  @Input() areSalesSystemGoalsIncludedForAssortment: boolean;


  driver: CalculatorDriverInterface = null;
  dataSet: DepartmentCalculationDataItem[] = null;
  seasonInfo: SeasonInfo = null;
  returnsInfo: ReturnsInfo = null;
  isForecastEnabledList = [];
  isWeeklyInputEnabledList = [];
  quarters = [];
  quarterBorderForWeeks: boolean[] = [];
  quarterBorderForPeriods: boolean[] = [];
  quarterBorderForCalcPeriods: boolean[] = [];
  seasonTitle: string = null;
  disabledTillWeek: number = null;
  inputLockStatus: InputLockStatus = null;
  isAssortmentView: boolean = false;

  dataSetIndexOffset = 0;




  availableRetrievalModes: RetrievalMode[] = [];
  currentRetrievalMode: RetrievalMode = null;
  addRemoveOrMovePattern: string = "^([0-9]{0,3}|\\-\\b[0-9]{0,3})([.|,][0-9]{0,3})?$";

  selectedCellId: string = null;

  channelType: ChannelType = null;
  lastWeekInCurrentView: number = null;
  brandId: number = null;

  userConfigSubjectSubscription: Subscription;

  selectedMartketType: string = null;
  isPRCopy: boolean = false;

  seasonStartWeek: number = null;

  constructor(
    private _utils: UtilsService,
    private _ref: ChangeDetectorRef,
    public dialog: MatDialog,
    private _eventHandlerService: EventHandlerService,
    private _planningViewActionHistoryService: PlanningViewActionHistoryService,
    private _userConfigService: UserConfigService
  ) {

  }

  ngOnInit(): void {

    this.driver = this.seasonTableInfo.driver;
    this.dataSet = this.driver.getDataSet();
    this.seasonInfo = this.driver.getSeasonInfo();
    let userConfig = this.driver.getUserConfig()
    this.channelType = userConfig.planningViewOptions.channel;
    this.brandId = userConfig.selectionViewOptions.brand.id;
    this.isAssortmentView = userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT;

    this.selectedMartketType = MarketType[userConfig.planningViewOptions.market.marketType];
    if (!this.isAssortmentView) {
      this.isPRCopy = (<SalesCopy>(userConfig.selectionViewOptions.copy)).isPRCopy;
    }

    let seasonViewType = this._utils.getSeasonViewType(this.driver.getViewDate());
    switch (seasonViewType) {
      case SeasonViewType.View1:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW1_ENDWEEK))[0];
        break;
      case SeasonViewType.View2:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW2_ENDWEEK))[0];
        break;
      case SeasonViewType.View3:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW3_ENDWEEK))[0];
        break;
      case SeasonViewType.View4:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW4_ENDWEEK))[0];
        break;
    }

    this.seasonTitle = this.driver.getTitle(this.areOldSeasonsExcluded);

    this.setupSeasonTable();
    this.setDefaultRetrievalModes();

    this.returnsInfo = this.driver.getReturnsInfo();

    this.seasonStartWeek = this._utils.getStartWeekForSeason(parseInt(this.seasonInfo.seasonCodeNames[0]));

    if (this.seasonInfo.seasonPlanningType == SeasonPlanningType.Total ||
      this.seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOld || this.seasonInfo.seasonPlanningType == SeasonPlanningType.Previous) {
      this.driver.addChangeEventListener(this.refreshDataSet.bind(this));
    }

    this.dataSetIndexOffset = _.findIndex(this.dataSet, x => x.weekName == this.driver.getSeasonInfo().weeksWithYear[0])

    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);

    this.userConfigSubjectSubscription = this._userConfigService.userConfigSubject.subscribe(userConfig => {

      // check if its not null
      if (userConfig && userConfig?.planningViewLayoutSettings?.seasonSettings) {
        let seasonSetting = userConfig?.planningViewLayoutSettings?.seasonSettings.find(x => x.seasonPlanningType == this.driver.getSeasonInfo().seasonPlanningType);

        if (this._utils.isNotNullOrUndefined(seasonSetting)) {
          // check if the retrieval mode has changed
          if (seasonSetting.retrievalMode != this.driver.getRetrievalMode()) {
            // trigger a retrieval mode event
            this.handleEvent({
              "target": {
                "value": seasonSetting.retrievalMode
              }
            }, null, 'RetrievalMode');
          }
        }
      }
    })

  }

  lockInputs() {
    this.driver.setInputLockStatus(InputLockStatus.Locked);
  }

  unlockInputs() {
    this.driver.setInputLockStatus(InputLockStatus.Unlocked);
  }

  copyFromCellListener(event) {
    let eventText = document.getSelection();

    // skip formatting planning view details text
    if (this._utils.isNotNullOrUndefined(eventText) && !eventText.toString().startsWith("=====")) {
      event.preventDefault();
      event.clipboardData.setData('text/plain', eventText.toString().replace(/\n/g, '\t').replace(/,/g, "").replace(/ /g, ''));
    }
  }

  pasteIntoCellListener(event) {

    // blur out first
    document.getElementById("btnUserSettingMenu").focus();
    // for forecast gross input
    if (event.target && this._utils.isNotNullOrUndefined(event.target.getAttribute("data-cell-type"))) {

      // check if the event is for the current handler
      if (this._utils.isNotNullOrUndefined(event.target.getAttribute("data-season-planning-type")) && event.target.getAttribute("data-season-planning-type") == this.driver.getSeasonInfo().seasonPlanningType.toString()) {

        // if pasted from Excel there will be HTML we can parse to get empty cells etc, otherwise get text     
        let incomingDataSet: string[];
        if (event.clipboardData.types.includes('text/html')) {
          const incomingDataHtml = event.clipboardData.getData('text/html');
          incomingDataSet = this._eventHandlerService.getPastedDataFromHtmlTextTable(incomingDataHtml);
        } else {
          // get the incoming clipboard data in text format
          const incomingData = event.clipboardData.getData('text');

          // remove all non numerals and -ve sign, replace multiple spaces with a single space and split into individual tokens
          incomingDataSet = incomingData.replace(/,/g, "").replace(/[^0-9.-]/g, ' ').replace(/\s\s+/g, ' ').split(" ");
        }

        // prevent the default copy operation
        event.preventDefault();

        // get the data set from the current driver
        let dataSet = this.driver.getDataSet();

        // check to ensure that only numbers go through
        let reg = /^([0-9]{0,5}|\-\b[0-9]{0,5})([.][0-9]{0,3})?$/;

        // remove non number tokens
        incomingDataSet = _.filter(incomingDataSet, (token) => reg.test(token));

        // if there are valid tokens to be pastd
        if (incomingDataSet.length != 0 && this._utils.isNotNullOrUndefined(event.target.getAttribute("data-cell-type"))) {

          let dataCellType = event.target.getAttribute("data-cell-type");

          // if pasted on to forecast gross input field
          if (dataCellType == "forecast-gross-input" || dataCellType == "forecast-rand-input" || dataCellType == "add-remove-input" || dataCellType == "add-moves-input") {

            let actionList: PlanningViewActionHistoryItem[] = [];

            // get the week index where the paste event was fired
            let weekNames = [];
            if (dataCellType == "add-remove-input" || dataCellType == "add-moves-input") {
              weekNames.push(parseInt(event.target.getAttribute("data-week-name")));
            }
            else {
              weekNames = event.target.getAttribute("data-period-weeks").split(',');
            }

            // get the list of valid weeks for the season and also the last possible week
            let validWeeks = this.driver.getSeasonInfo().weeksWithYear;
            let lastWeekOfSeason = validWeeks[validWeeks.length - 1];

            // loop over each token
            for (let i = 0; i < incomingDataSet.length; i++) {

              // to mark valid paste event
              let validEvent = true;

              let currentToken = incomingDataSet[i];

              // shift weeks for each consecutive period
              let adjustedPeriodWeeks = [];

              if (dataCellType == "add-remove-input" || dataCellType == "add-moves-input") {
                adjustedPeriodWeeks.push(this._utils.shiftWeeks(parseInt(weekNames[0]), i));
              }
              else {
                weekNames.forEach(weekName => {
                  adjustedPeriodWeeks.push(this._utils.shiftWeeks(parseInt(weekName), i * 4));
                });
              }



              // check if the period weeks are valid, if valid update the input field
              adjustedPeriodWeeks.forEach(adjustedWeekName => {

                let dataSetItemIndex = _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == adjustedWeekName);

                let validationRegex = null;

                // should be within season range and should exist
                if (parseInt(adjustedWeekName) <= lastWeekOfSeason && dataSetItemIndex != -1) {
                  switch (dataCellType) {
                    case "forecast-gross-input":
                      // validate forecast gross input

                      // only accept numbers of 1 to 5 digits length (6 if decimal)
                      validationRegex = /^[0-9.]{1,6}$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {

                        // get the indexes for all the weeks within the period and check the ground value
                        let periodIndexes = this._utils.getPeriodIndexes(dataSetItemIndex);

                        if (
                          dataSet[periodIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
                          dataSet[periodIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
                          dataSet[periodIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
                          dataSet[periodIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0
                        ) {
                          validEvent = false;
                        }
                        else {
                          dataSet[dataSetItemIndex][CalculationDataItemType.InputForecastGrossPeriodic] = parseFloat(currentToken);
                          // Update this as well, used for for change factor, might be calculated differently than the other one but should be the same on change
                          dataSet[dataSetItemIndex][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = dataSet[dataSetItemIndex][CalculationDataItemType.InputForecastGrossPeriodic];
                        }
                      }
                      else {
                        validEvent = false;
                      }
                      break;
                    case "forecast-rand-input":

                      // only accept numbers of 1 to 2 digits length (and maybe on decimal then it will be max 4 length)
                      validationRegex = /^[0-9.]{1,4}$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {

                        // get the indexes for all the weeks within the period and check the ground value
                        let periodIndexes = this._utils.getPeriodIndexes(dataSetItemIndex);

                        if (
                          (
                            dataSet[periodIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
                            dataSet[periodIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
                            dataSet[periodIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
                            dataSet[periodIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0
                          )
                          ||
                          (
                            dataSet[periodIndexes[0]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                            dataSet[periodIndexes[1]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                            dataSet[periodIndexes[2]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                            dataSet[periodIndexes[3]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0
                          )
                        ) {
                          validEvent = false;
                        }
                        else {
                          dataSet[dataSetItemIndex][CalculationDataItemType.InputRAndDForecastPeriodic] = parseFloat(currentToken);
                        }

                      }
                      else {
                        validEvent = false;
                      }

                      break;
                    case "add-moves-input":

                      validationRegex = /^([0-9]{0,3}|\-\b[0-9]{0,3})([.][0-9]{0,3})?$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {
                        dataSet[dataSetItemIndex][CalculationDataItemType.InputAddMovesMSekWeekly] = parseFloat(currentToken);
                        dataSet[dataSetItemIndex][CalculationDataItemType.AddMovesMSek] = parseFloat(currentToken);
                      }
                      else {
                        validEvent = false;
                      }

                      break;
                    case "add-remove-input":

                      validationRegex = /^([0-9]{0,3}|\-\b[0-9]{0,3})([.][0-9]{0,3})?$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {
                        dataSet[dataSetItemIndex][CalculationDataItemType.InputAddRemoveMSekWeekly] = parseFloat(currentToken);
                        dataSet[dataSetItemIndex][CalculationDataItemType.AddRemoveMSek] = parseFloat(currentToken);
                      }
                      else {
                        validEvent = false;
                      }
                      break;
                  }
                }
                else {
                  validEvent = false;
                }
              });

              if (validEvent) {

                let dataSetItemPeriodIndex = _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == adjustedPeriodWeeks[0]);

                let eventInfo: DepartmentEventInfo;
                let event: DepartmentEvent;
                // create event objects
                if (dataCellType == "forecast-gross-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.ForecastGross,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputForecastGrossPeriodic),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex, dataSetItemPeriodIndex + 1, dataSetItemPeriodIndex + 2, dataSetItemPeriodIndex + 3]
                  }

                  event = new ForecastGrossEvent(eventInfo);
                }
                else if (dataCellType == "forecast-rand-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.RAndDForecastPercent,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputRAndDForecastPeriodic),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex, dataSetItemPeriodIndex + 1, dataSetItemPeriodIndex + 2, dataSetItemPeriodIndex + 3]
                  }
                  event = new RAndDForecastPercentEvent(eventInfo);
                }
                else if (dataCellType == "add-moves-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.AddMovesMsek,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputAddMovesMSekWeekly),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex]
                  }
                  event = new AddMovesEvent(eventInfo);
                }
                else if (dataCellType == "add-remove-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.AddRemoveMsek,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputAddRemoveMSekWeekly),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex]
                  }

                  event = new AddRemoveEvent(eventInfo);
                }


                let eventItem: PlanningViewActionHistoryItem = {
                  driver: this.driver,
                  event: event
                }

                actionList.push(eventItem);

                if (this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.PreviousWithOld || this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Previous) {

                  // for previous and old, fire events as and when they happen

                  let eventObj = {
                    target: {
                      value: event.eventInfo.newValue
                    }
                  }

                  switch (dataCellType) {
                    case "forecast-gross-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
                      break;
                    case "forecast-rand-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.RAndDForecastPercent);
                      break;
                    case "add-moves-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.AddMovesMsek);
                      break;
                    case "add-remove-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.AddRemoveMsek);
                      break;
                  }

                }
              }
            }

            if (actionList.length > 0 && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOld && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Previous) {

              // fire event on the last token, to prevent calculations from happening for each event.            
              let lastEvent = actionList.pop();

              actionList.forEach(actionEvent => {
                this._planningViewActionHistoryService.addActionEvent(actionEvent.event, this.driver);
              });

              // set the dataset with the given values
              this.driver.setDataSet(dataSet);

              let eventObj = {
                target: {
                  value: lastEvent.event.eventInfo.newValue
                }
              }

              switch (dataCellType) {
                case "forecast-gross-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
                  break;
                case "forecast-rand-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.RAndDForecastPercent);
                  break;
                case "add-moves-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.AddMovesMsek);
                  break;
                case "add-remove-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.AddRemoveMsek);
                  break;
              }
            }
          }

        }



      }
    }
  }


  validateForecastInput(event, inputDataItem: DepartmentCalculationDataItem, inputType: string) {
    var regEx = new RegExp(this.addRemoveOrMovePattern, "g");
    if (!regEx.test(event.target.value)) {
      // if test fails
      event.target.value = event.target.value
        .replace(/,/g, ".")
        .replace(/[^(\-)?\d.]/g, '')
        .replace(/(^(-)?[\d]{3})[\d]/g, '$1')
        .replace(/(\..*)\./g, '$1')
        .replace(/(\.[\d]{3})./g, '$1');

      // check and reassign the value to inputdata item
      if (inputType === Constants.ADDREMOVEMSEK && regEx.test(event.target.value)) {
        inputDataItem.addRemoveMSek = event.target.value;
      }
      else if (inputType === Constants.ADDMOVESMSEK && regEx.test(event.target.value)) {
        inputDataItem.addMovesMSek = event.target.value;
      }
      else if (inputType == "AdjustedRPercent") {
        this.returnsInfo.adjustedReturnPercent = event.target.value;
      }
    }
    else {
      // if test passes
      // replace comma wit decimal dots.
      let formattedValue = event.target.value.replace(/,/g, ".")
      if (inputType == "AdjustedRPercent") {
        this.returnsInfo.adjustedReturnPercent = formattedValue;
      }
    }
  }


  handleEvent(event, weekIndex, type: string) {

    let eventType: DepartmentEventType = this._utils.enumFromValue(type, DepartmentEventType);

    let eventObj = null;
    let previousValue = null;


    let eventInfo: DepartmentEventInfo = {
      eventType: eventType,
      newValue: null,
      previousValue: null,
      weekIndexes: null
    }

    let dataSet = this.driver.getDataSet();


    let weekName = weekIndex == null ? null : dataSet[weekIndex][CalculationDataItemType.WeekName];

    //need to find the actual weekindex from the dataset since it is added by 2 more periods
    let actualWeekIndex = weekIndex == null ? null : dataSet.findIndex(x => x.weekName == weekName);


    switch (eventType) {
      case DepartmentEventType.ForecastGross:
        eventInfo.weekIndexes = weekIndex == null ? [] : this._utils.getPeriodIndexes(actualWeekIndex);
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputForecastGrossPeriodic);
        eventInfo.previousValue = previousValue;
        eventObj = new ForecastGrossEvent(eventInfo);
        break;
      case DepartmentEventType.AddMovesMsek:
        eventInfo.weekIndexes = weekIndex == null ? [] : [actualWeekIndex];
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputAddMovesMSekWeekly);
        eventInfo.previousValue = previousValue;
        eventObj = new AddMovesEvent(eventInfo);
        break;
      case DepartmentEventType.AddRemoveMsek:
        eventInfo.weekIndexes = weekIndex == null ? [] : [actualWeekIndex];
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputAddRemoveMSekWeekly);
        eventInfo.previousValue = previousValue;
        eventObj = new AddRemoveEvent(eventInfo);
        break;
      case DepartmentEventType.RAndDForecastPercent:
        eventInfo.weekIndexes = weekIndex == null ? [] : this._utils.getPeriodIndexes(actualWeekIndex);
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputRAndDForecastPeriodic);
        eventInfo.previousValue = previousValue;
        eventObj = new RAndDForecastPercentEvent(eventInfo);
        break;
      case DepartmentEventType.RetrievalMode:
        previousValue = this.driver.getPreviousRetrievalMode();
        eventInfo.previousValue = previousValue;
        eventObj = new RetrievalModeEvent(eventInfo);
        break;
      case DepartmentEventType.RPercent:
        eventInfo.previousValue = this.driver.getPreviousAdjustedRPercent();
        break;
    }

    let processEvent = true;

    if (eventType != DepartmentEventType.ClearAll) {
      eventInfo.newValue = event.target.value;

      // both previous and new values are null and empty
      processEvent = !(this._utils.isNullOrEmpty(eventInfo.newValue.toString()) && this._utils.isNullOrEmpty(eventInfo.previousValue));

      // both values should not match 
      processEvent = processEvent && (parseFloat(eventInfo.newValue.toString()) !== eventInfo.previousValue);
    }

    if (processEvent || eventType == DepartmentEventType.RPercent) {

      if (eventType == DepartmentEventType.RPercent) {
        eventInfo.newValue = eventInfo.newValue.toString().trim() == "" ? null : parseFloat(eventInfo.newValue.toString());
        eventObj = new RPercentEvent(eventInfo);
      }

      if (eventType == DepartmentEventType.ClearAll) {

        eventInfo.previousValue = _.cloneDeep(this.driver.getDataSet());
        eventInfo.newValue = this.driver.getRawDataItems();
        eventObj = new ClearAllEvent(eventInfo);
      }

      this.driver.handleEvent(eventObj);

      if (eventType != DepartmentEventType.RetrievalMode && eventType != DepartmentEventType.ClearAll) {
        this.seasonTableInfo.driver.touched = true;
      }
    }

  }

  openClearDialog(event) {
    let clear: ClearAllDialogData;

    clear = {
      seasonName: this.seasonInfo.seasonCodeNames[0],
      driverDialogDataItem: <CalculatorDriverInterface>this.driver
    }
    this.dialog.open<ClearAllDialogComponent, ClearAllDialogData>(ClearAllDialogComponent, {
      data: clear,
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.handleEvent(event, 0, DepartmentEventType.ClearAll);
      }
    });
  }

  openCopyForecastDialog() {

    this.dialog.open<CopyForecastDialogComponent>(CopyForecastDialogComponent)
    .afterClosed().subscribe(result => {
      if (result) {
        this.copyForecastGoals();
      }
    });
  }

 copyForecastGoals() {

  // get the data set from the current driver
  let dataSet = this.driver.getDataSet();

  let actionList: PlanningViewActionHistoryItem[] = [];

  // get the list of valid weeks for the season and also the last possible week
  let validWeeks = this.driver.getSeasonInfo().weeksWithYear;
  let lastWeekOfSeason = validWeeks[validWeeks.length - 1];

  let startWeekNumber = this.seasonStartWeek > this.currentWeek ? this.seasonStartWeek : this.currentWeek;

  
  let currentWeekItemIndex = _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == startWeekNumber);
  let lastWeekItemIndex =  _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == lastWeekOfSeason);


  // loop over each week
  for (let index = currentWeekItemIndex; index <= lastWeekItemIndex; index++) {

    // to mark valid paste event
    let validEvent = true;

    let currentToken = dataSet[index][CalculationDataItemType.AggregatedPMsGrossTaktPeriodic];

    // get the indexes for all the weeks within the period and check the ground value
    let periodIndexes = this._utils.getPeriodIndexes(index);

    if (
      dataSet[periodIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
      dataSet[periodIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
      dataSet[periodIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
      dataSet[periodIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0
    ) {
      validEvent = false;
    }
    else {
      dataSet[index][CalculationDataItemType.InputForecastGrossPeriodic] = Number(currentToken.toFixed(2));
      // Update this as well, used for for change factor, might be calculated differently than the other one but should be the same on change
      dataSet[index][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = dataSet[index][CalculationDataItemType.InputForecastGrossPeriodic];
    }

    if (validEvent && actionList.findIndex(x => x.event.eventInfo.weekIndexes[0] == periodIndexes[0]) == -1) {

      let eventInfo: DepartmentEventInfo;
      let event: DepartmentEvent;

      // create event objects
      eventInfo = {
        eventType: DepartmentEventType.ForecastGross,
        previousValue: this.driver.getPreviousInputItemValue(index, CalculationDataItemType.InputForecastGrossPeriodic),
        newValue: Number(currentToken.toFixed(2)),
        weekIndexes: [periodIndexes[0], periodIndexes[1], periodIndexes[2], periodIndexes[3]]                  
      }

      event = new ForecastGrossEvent(eventInfo);
      
      let eventItem: PlanningViewActionHistoryItem = {
        driver: this.driver,
        event: event
      }

      actionList.push(eventItem);
      
      if (this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.PreviousWithOld || this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Previous) {

        // for previous and old, fire events as and when they happen
        let eventObj = {
          target: {
            value: event.eventInfo.newValue
          }
        }

        this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
      }     
    }
  }

  if (actionList.length > 0 && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOld && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Previous) {

    // fire event on the last token, to prevent calculations from happening for each event.            
    let lastEvent = actionList.pop();

    actionList.forEach(actionEvent => {
      this._planningViewActionHistoryService.addActionEvent(actionEvent.event, this.driver);
    });

    // set the dataset with the given values
    this.driver.setDataSet(dataSet);

    let eventObj = {
      target: {
        value: lastEvent.event.eventInfo.newValue
      }
    }

    this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);               
  }
}

  refreshDataSet() {
    this.dataSet = this.driver.getDataSet();
    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);
    this._ref.detectChanges();
  }

  private setupSeasonTable(): void {

    // quarters
    _.map(this.seasonInfo.quarters, quarter => {

      let colspan = (quarter.indexOf("Summer") != -1) ? 16 : 12;

      this.quarters.push({
        "name": quarter,
        "colspan": colspan
      });

    });

    // if the last quarter is summer, reduce 4 weeks from it
    if(this.quarters[this.quarters.length-1]["name"].indexOf("Summer") != -1)
    {
      this.quarters[this.quarters.length-1]["colspan"] = 12;
    }

    // this.quarters.push(
    //   {
    //     "name": "",
    //     "colspan": 4
    //   });


    this.isForecastEnabledList = [];

    this.isWeeklyInputEnabledList = [];

    // spring starts in the previous year
    // fall starts in the same year

    //Spring Season - Disabled till week 45 of previous year
    //Fall Season - Disabled till Week 17 of same year
    if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous) {
      if (this.seasonInfo.seasonType == SeasonType.Spring) {
        // get previous year number and append 45 to it
        this.disabledTillWeek = parseInt((parseInt(this.seasonInfo.seasonCodeNames[0].substring(0, 4)) - 1).toString() + "49");
      }
      else if (this.seasonInfo.seasonType == SeasonType.Fall) {
        // get same year number and append 16 to it
        this.disabledTillWeek = parseInt((parseInt(this.seasonInfo.seasonCodeNames[0].substring(0, 4))).toString() + "21");
      }
    }

    let viewPeriodWeeks = this.driver.getViewPeriodWeeksWithYear();
    // disable all periods before the current period
    for (let i = 0; i < this.seasonInfo.weeksWithYear.length; i++) {

      if (this.seasonInfo.weeksWithYear[i + 4] <= this.currentWeek) {
        this.isForecastEnabledList.push(false);
      }
      else if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this.seasonInfo.weeksWithYear[i + 4] <= this.disabledTillWeek) {
        this.isForecastEnabledList.push(false);
      }
      else {
        this.isForecastEnabledList.push(true);
      }

      // disable all weeks within the view period
      if(viewPeriodWeeks.indexOf(this.seasonInfo.weeksWithYear[i]) != -1 && viewPeriodWeeks.indexOf(this.seasonInfo.weeksWithYear[i]) > this.currentWeek)
      {
        this.isWeeklyInputEnabledList.push(false);
      }
      else
      {
        if (this.seasonInfo.weeksWithYear[i] < this.currentWeek) {
          this.isWeeklyInputEnabledList.push(false);
        }
        else if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this.seasonInfo.weeksWithYear[i] < this.disabledTillWeek) {
          this.isWeeklyInputEnabledList.push(false);
        }
        else {
          this.isWeeklyInputEnabledList.push(true);
        }
      }      
    }

    //create a new list object and use to draw quarter in the season table
    this.quarters.forEach(quarter => {
      let length = quarter.colspan;
      let j = 1;
      for (let i = 0; i < length; i++) {
        this.quarterBorderForWeeks.push((i == length - 1) ? true : false);
        this.quarterBorderForCalcPeriods.push((i == length - 4) ? true : false);
        if (j % 4 == 0) {
          this.quarterBorderForPeriods.push((i == length - 1) ? true : false);
        }
        j++;
      }
    });
  }

  private setDefaultRetrievalModes() {

    this.availableRetrievalModes = this._utils.getValidRetrievalModes(this.seasonInfo, false);

    this.currentRetrievalMode = this.driver.getRetrievalMode();
  }

  filterInputCharacters(event) {
    // allow only numbers
    var charCode = (event.which) ? event.which : event.keyCode;

    // allow only numbers, hyphen(45) and period(46)
    if ((charCode >= 48 && charCode <= 57) || charCode == 45 || charCode == 46) {
      return true;
    }
    event.preventDefault();

    return false;
  }

  ngOnDestroy() {
    this.userConfigSubjectSubscription.unsubscribe();
  }

}
