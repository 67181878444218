import * as _ from 'lodash';
import { BoughtOfPlanCalculator } from 'src/app/modules/shared/calculators/buying/bought-of-plan-calculator';
import { BuyingCalculator } from 'src/app/modules/shared/calculators/buying/buying-calculator';
import { CalculatorDriverInterface } from "src/app/modules/shared/calculators/calculator-driver-interface";
import { CalculatorInterface } from "src/app/modules/shared/calculators/calculator-interface";
import { CalculatorTimeSpan } from "src/app/modules/shared/calculators/calculator-timespan";
import { CalculatorType } from 'src/app/modules/shared/calculators/calculator-type';
import { PlannedCoverageCalculator } from 'src/app/modules/shared/calculators/coverage/planned-coverage-calculator';
import { CSPercentCalculator } from 'src/app/modules/shared/calculators/cs-percent/cs-percent-calculator';
import { AccShrinkLyCalculator } from 'src/app/modules/shared/calculators/others/acc-shrink-ly-calculator';
import { CombinedNetSalesCalculator } from 'src/app/modules/shared/calculators/others/combined-net-sales-calculator';
import { CombinedSalesLyCalculator } from 'src/app/modules/shared/calculators/others/combined-sales-ly-calculator';
import { CombinedSalesPlanCalculator } from "src/app/modules/shared/calculators/others/combined-sales-plan-calculator";
import { EffectiveSalesPlanCalculator } from "src/app/modules/shared/calculators/others/effective-sales-plan-calculator";
import { EffectiveSystemGoalPlanCalculator } from 'src/app/modules/shared/calculators/others/effective-system-goal-plan-calculator';
import { ForecastGoalCalculator } from "src/app/modules/shared/calculators/others/forecast-goal-calculator";
import { PopulateInputCellCalculator } from "src/app/modules/shared/calculators/others/populate-input-cell-calculator";
import { PopulateRAndDForecastInputCellCalculator } from "src/app/modules/shared/calculators/others/populate-randd-forecast-input-cell-calculator";
import { SalesForecastCalculator } from 'src/app/modules/shared/calculators/others/sales-forecast-calculator';
import { ShrinkPercentLyCalculator } from 'src/app/modules/shared/calculators/others/shrink-percent-ly-calculator';
import { StockIndexGroundCalculator } from 'src/app/modules/shared/calculators/others/stock-index-ground-calculator';
import { CombinedRAndDCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/combined-r-and-d-calculator';
import { EffectiveRAndDPlanCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/effective-r-and-d-plan-calculator';
import { GroundRAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/ground-r-and-d-percent-calculator";
import { RAndDForecastCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-forecast-calculator';
import { RAndDForecastPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-forecast-percent-calculator';
import { RAndDLyPercentCalculator } from 'src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-ly-percent-calculator';
import { RAndDPercentCalculator } from "src/app/modules/shared/calculators/r-and-d-calculators/r-and-d-percent-calculator";
import { GroundSPercentCalculator } from 'src/app/modules/shared/calculators/s-percent-calculators/ground-s-percent-calculator';
import { SPercentCalculator } from 'src/app/modules/shared/calculators/s-percent-calculators/s-percent-calculator';
import { OutgoingStockPrognosisCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/outgoing-stock-prognosis-calculator";
import { StockForecastCalculator } from 'src/app/modules/shared/calculators/stock-prognosis-calculators/stock-forecast-calculator';
import { StockPlanFixedCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-plan-fixed-calculator";
import { StockPrognosisCalculator } from "src/app/modules/shared/calculators/stock-prognosis-calculators/stock-prognosis-calculator";
import { GrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/gross-takt-calculator";
import { GroundGrossTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/ground-gross-takt-calculator";
import { NetTaktCalculator } from "src/app/modules/shared/calculators/takt-calculators/net-takt-calculator";
import { OmniTaktCalculator } from 'src/app/modules/shared/calculators/takt-calculators/omni-takt-calculator';
import { SystemGoalCalculator } from 'src/app/modules/shared/calculators/takt-calculators/system-goal-calculator';
import { DepartmentEvent } from 'src/app/modules/shared/events/department-events/department-event';
import { DialogBoxService } from "src/app/modules/shared/services/dialog-box-service";
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { DriverType } from 'src/app/modules/shared/types/driver-type';
import { RetrievalMode } from "src/app/modules/shared/types/retrieval-mode";
import { SeasonDataItem } from 'src/app/modules/shared/types/season-data-item';
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { SeasonPlanningType } from "src/app/modules/shared/types/season-planning-type";
import { UserConfig } from 'src/app/modules/shared/types/user-config';
import { WeekDataItem } from "src/app/modules/shared/types/week-data-item";
import { EffectiveSalesPlanWithGroundCalculator } from '../../../../shared/calculators/others/effective-sales-plan-with-ground-calculator';
import { PlanningViewActionHistoryService } from '../../../services/planning-view-action-history-service';
import { DepartmentPlanningViewDataResponse } from '../../../types/api/planning-view-data/department-planning-view-data-response';
import { CalculationDataItemType } from '../../../types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from "../../../types/department-calculation-data-item";
import { DepartmentSaveItem } from "../../../types/department-save-item";
import { InputLockStatus } from '../../../types/input-lock-status-enum';
import { ReturnsInfo } from '../../../types/returns-info';
import { DepartmentSeasonSaveItem } from '../../../types/save-items/department-season-save-item';
import {WeekSaveItem } from '../../../types/save-items/week-save-item';
import { OrganizationType } from 'src/app/modules/shared/types/organization-type';

export class DepartmentCalculationDriver implements CalculatorDriverInterface {

    public touched: boolean = false;
    public rPercentDirty: boolean = false;
    public rowInputsDirty: boolean = false;
    
    // main data container
    private _dataSet: DepartmentCalculationDataItem[] = []

    // loaded into object
    private _rawWeekDataItems: WeekDataItem[] = null;
    private _seasonInfo: SeasonInfo = null;
    private _returnsInfo: ReturnsInfo = null;
    private _csPercent: number = null;
    private _viewDate: Date = null;
    private _viewWeekWithYear: number = null;
    private _viewPeriodWeeksWithYear: number[] = null;
    private _utilsService: UtilsService = null;
    private _cacheDepartmentSaveItem: DepartmentSaveItem

    private _calculatorConfiguration: CalculatorInterface[][] = null;
    public retrievalMode: RetrievalMode = null;

    private initialInputItems: DepartmentCalculationDataItem[] = null;
    private previousInputItems: DepartmentCalculationDataItem[] = null;

    private _previousDataSet: DepartmentCalculationDataItem[] = null;
    private previousRetrievalMode: RetrievalMode = null;
    private previousAdjustedRPercent: number = null;
    private initialAdjustedRPercent: number = null;
    

    public hasSalesAggregatedPmDataSetLoaded = false;
    public hasSalesSystemGoalInAssortmentLoaded = false;

    private _changeEventListeners: any[] = [];
    _dialogBoxService: DialogBoxService = null;
    _planningViewActionHistoryService: PlanningViewActionHistoryService = null;

    private _title: string = null;
    private _seasonType: string = null;
    private uniqueWeekList: number[] = null;
    private _userConfigService: UserConfigService = null;

    private inputLockStatus: InputLockStatus = InputLockStatus.Locked_Disabled;

    constructor(userConfigService: UserConfigService, utilsService: UtilsService, dialogBoxService: DialogBoxService, planningViewActionHistoryService: PlanningViewActionHistoryService) {
        this._userConfigService = userConfigService;
        this._utilsService = utilsService;
        this._dialogBoxService = dialogBoxService;
        this._planningViewActionHistoryService = planningViewActionHistoryService;
    }
    getUserConfig(): UserConfig {
        return this._userConfigService.getUserConfig();
    }
    getDriverType(): DriverType {
        return DriverType.DepartmentSeasonDriver;
    }

    getInputLockStatus(): InputLockStatus {
        return this.inputLockStatus;
    }

    setInputLockStatus(lockStatus: InputLockStatus): void {
        this.inputLockStatus = lockStatus;
    }

    getPreviousAdjustedRPercent(): number {
        return this.previousAdjustedRPercent;
    }

    getInitialAdjustedRPercent(): number {
        return this.initialAdjustedRPercent;
    }

    getPreviousRetrievalMode(): RetrievalMode {
        return this.previousRetrievalMode;
    }

    getDataSetRange(startweek: number, endweek: number): DepartmentCalculationDataItem[] {

        let startWeekIndex = _.findIndex(this._dataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == startweek
        });
        if (startWeekIndex == -1) {
            startWeekIndex = 0;
        }
        let endWeekIndex = _.findIndex(this._dataSet, dataSetItem => {
            return dataSetItem[CalculationDataItemType.WeekName] == endweek
        });

        return this._dataSet.slice(startWeekIndex, endWeekIndex + 1);
    }

    getRawDataItems(): WeekDataItem[] {
        return this._rawWeekDataItems;
    }


    refreshTotalDataSet() {
        throw new Error("Method not implemented.");
    }

    setDataSet(dataSet: DepartmentCalculationDataItem[]) {
        this._dataSet = dataSet;
    }



    setRetrievalMode(retrievalMode: RetrievalMode) {
        this.retrievalMode = retrievalMode;
    }

    getInitialInputItems(): DepartmentCalculationDataItem[] {
        return this.initialInputItems
    }

    getPreviousInputItemValue(weekIndex: number, inputType: CalculationDataItemType) {
        return this.previousInputItems[weekIndex][inputType];
    }


    getPreviousDataSet(): DepartmentCalculationDataItem[] {
        return this._previousDataSet;
    }

    getUtilsService(): UtilsService {
        return this._utilsService;
    }

    getTitle(areOldSeasonsExcluded?: boolean): string {
        return this._title;
    }

    getSeasonInfo(): SeasonInfo {
        return this._seasonInfo;
    }

    getDataSet(): DepartmentCalculationDataItem[] {
        return this._dataSet;
    }

    getViewDate(): Date {
        return this._viewDate;
    }

    getRetrievalMode(): RetrievalMode {
        return this.retrievalMode;
    }

    getViewWeekWithYear(): number {
        return this._viewWeekWithYear;
    }

    public addChangeEventListener(changeHandler: any) {
        this._changeEventListeners.push(changeHandler);
    }

    getViewPeriodWeeksWithYear(): number[] {
        return this._viewPeriodWeeksWithYear;
    }

    setCalculatorConfiguration(calculatorConfiguration: CalculatorInterface[][]) {
        this._calculatorConfiguration = calculatorConfiguration;
    }

    getReturnsInfo(): ReturnsInfo {

        return this._returnsInfo;
    }


    setReturnsInfo(returnsInfo: ReturnsInfo) {
        this._returnsInfo = returnsInfo
    }


    getCSPercent(): number {

        return this._csPercent;
    }


    setCSPercent(csPercent: number) {
        this._csPercent = csPercent
    }

    getWeekDataItems(): WeekDataItem[] {
        return this._rawWeekDataItems;
    }

    setWeekDataItems(rawWeekDataItems: WeekDataItem[]) {
        this._rawWeekDataItems = rawWeekDataItems;
    }

    getDialogBoxService(): DialogBoxService {
        return this._dialogBoxService;
    }

    getChildDataSets(): DepartmentCalculationDataItem[][] {
        throw new Error("Method not implemented.");
    }

    // loading data for the first time
    initDataSet(departmentPlanningViewDataResponse: DepartmentPlanningViewDataResponse, seasonInfo: SeasonInfo, viewDate: Date) {
        this._rawWeekDataItems = departmentPlanningViewDataResponse.weekData;
        this._viewDate = viewDate;
        this._seasonInfo = seasonInfo;
        this._viewWeekWithYear = this._utilsService.getWeekNumber(true, this._viewDate);
        this._viewPeriodWeeksWithYear = this._utilsService.getWeeksForPeriod(this._viewDate);

        this._setTitle();

        // for previous and old season, we need to aggregate it into a single value. That calculation handled in the previous and old season driver.
        if (seasonInfo.seasonPlanningType != SeasonPlanningType.PreviousWithOld && seasonInfo.seasonPlanningType != SeasonPlanningType.Previous) {
            this._configureReturnsInfo(departmentPlanningViewDataResponse.seasonData);
        }

        this.previousAdjustedRPercent = this.initialAdjustedRPercent = this._returnsInfo.adjustedReturnPercent;

        this._processApiResponse();
        this._configureCalculatorsForInitialLoad();
    }

    private _configureReturnsInfo(seasonDataItems: SeasonDataItem[]) {
        if (seasonDataItems.length != 0) {
            let returnInfo: ReturnsInfo = {
                unAdjustedReturnPercent: this._utilsService.roundNumber(seasonDataItems[0].unAdjustedReturnPercent, 2),
                adjustedReturnPercent: seasonDataItems[0].adjustedReturnPercent == null ? null : this._utilsService.roundNumber(seasonDataItems[0].adjustedReturnPercent, 2),
                unAdjustedBudgetedReturnPercent: this._utilsService.roundNumber(seasonDataItems[0].unAdjustedBudgetedReturnPercent, 2),
                adjustedBudgetedReturnPercent: seasonDataItems[0].adjustedBudgetedReturnPercent == null ? null : this._utilsService.roundNumber(seasonDataItems[0].adjustedBudgetedReturnPercent, 2),
                seasonName: seasonDataItems[0].seasonName,

                departmentIntegrationKey: seasonDataItems[0].departmentIntegrationKey,
                marketIntegrationKey: seasonDataItems[0].marketIntegrationKey,
                marketType: seasonDataItems[0].marketType,
                returnDistribution: seasonDataItems[0].returnDistribution,
                returnFactorPercent: seasonDataItems[0].returnFactorPercent,
                sumOfBudgetedReturns: seasonDataItems[0].sumOfBudgetedReturns,
                sumOfDemDelFixed: seasonDataItems[0].sumOfDemDelFixed,
                sumOfDemDelPlan: seasonDataItems[0].sumOfDemDelPlan,
                sumOfReturnFixed: seasonDataItems[0].sumOfReturnFixed,
                sumOfReturnPlan: seasonDataItems[0].sumOfReturnPlan,
                sumOfDemDelPlanBeforeKPeriod: seasonDataItems[0].sumOfDemDelPlanBeforeKPeriod,
                sumOfUnAdjustedBudgetedReturns: seasonDataItems[0].sumOfUnAdjustedBudgetedReturns
            }
            this._returnsInfo = returnInfo;
        }
        else {
            this._returnsInfo = new ReturnsInfo();
        }

    }

    private _setTitle() {
        this._title = this._seasonInfo.seasonCodeNames[0];
    }

    private _processApiResponse() {
        this._arrangeDataSet();
        this._setDefaultRetrievalModes();
    }

    // add missing items into the dataset
    private _arrangeDataSet() {
        let dataSetItem: DepartmentCalculationDataItem = null;

        this._rawWeekDataItems.forEach((weekRecord: WeekDataItem) => {
            dataSetItem = {
                weekName: weekRecord.weekName,

            }

            // get the record for the current week from the raw items
            // let weekRecord = _.find(this._rawWeekDataItems, (weekRawDataItem: WeekDataItem) => weekRawDataItem.weekName == weekWithYear);

            dataSetItem = { ...dataSetItem, ...weekRecord };

            this._dataSet.push(dataSetItem);
        });

        let weekList = this._dataSet.map(el => el.weekName);
        this.uniqueWeekList = weekList.filter((item, i, ar) => ar.indexOf(item) === i);
    }

    private _setDefaultRetrievalModes() {

        let isAssortmentCopy = this._userConfigService.getUserConfig().selectionViewOptions.organization.name == OrganizationType.Assortment;

        this.retrievalMode = this._utilsService.getDefaultRetrievalMode(this._seasonInfo, isAssortmentCopy);

        this.previousRetrievalMode = this.retrievalMode;
    }

    public configureCalculatorsForClear() {
        this._configureCalculatorsForInitialLoad();
    }

    private _configureCalculatorsForInitialLoad() {
        this._calculatorConfiguration = [];

        // ============= //

        // pass 1
        let pass1Calculators: CalculatorInterface[] = [];


        // Add move/remove Input - weekly
        pass1Calculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Weekly, this))
        // Gross takt ground - Weekly
        pass1Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective System Goal Plan - Weekly
        pass1Calculators.push(new EffectiveSystemGoalPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // Gross takt - weekly
        pass1Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Weekly, this))

        // R&D% Ground - Weekly
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // R&D% Ground - Periodic
        pass1Calculators.push(new GroundRAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // Stock Index Ground - Periodic
        pass1Calculators.push(new StockIndexGroundCalculator(CalculatorTimeSpan.Periodic, this))

        // RAndD LY Percent- Weekly
        pass1Calculators.push(new RAndDLyPercentCalculator(CalculatorTimeSpan.Weekly, this))

        // Combined SalesLy- Weekly
        pass1Calculators.push(new CombinedSalesLyCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective Sales Plan - Weekly
        pass1Calculators.push(new EffectiveSalesPlanCalculator(CalculatorTimeSpan.Weekly, this));
        pass1Calculators.push(new EffectiveSalesPlanWithGroundCalculator(CalculatorTimeSpan.Weekly, this));

        // load pass calculators
        this._calculatorConfiguration.push(pass1Calculators);

        // ============= //

        // input periodic calculator pass
        let inputGoalCalculators: CalculatorInterface[] = [];
        // Forecast Gross Input - Periodic
        inputGoalCalculators.push(new PopulateInputCellCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(inputGoalCalculators);

        // ============= //

        // pass 2
        let pass2Calculators: CalculatorInterface[] = [];


        // System Goal - Periodic
        pass2Calculators.push(new SystemGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // Gross takt ground - Periodic
        pass2Calculators.push(new GroundGrossTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // Forecast goal - Periodic
        pass2Calculators.push(new ForecastGoalCalculator(CalculatorTimeSpan.Periodic, this))

        // Ground S percent- Weekly
        pass2Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Weekly, this))

        
        // Acc Shrink Ly - Weekly
        pass2Calculators.push(new AccShrinkLyCalculator(CalculatorTimeSpan.Weekly, this));
               
        // Shrink Percent Ly - Weekly
        pass2Calculators.push(new ShrinkPercentLyCalculator(CalculatorTimeSpan.Weekly, this));

        // load pass calculators
        this._calculatorConfiguration.push(pass2Calculators);

        // ============= //

        // pass 3
        let pass3Calculators: CalculatorInterface[] = [];


        // Sales Forecast - Weekly
        pass3Calculators.push(new SalesForecastCalculator(CalculatorTimeSpan.Weekly, this));
        // Combined Sales Plan - Weekly
        pass3Calculators.push(new CombinedSalesPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // calculate r and d forecast input cell periodic 
        pass3Calculators.push(new PopulateRAndDForecastInputCellCalculator(CalculatorTimeSpan.Periodic, this))

        // R&D% - Periodic. Moved to pass 9
        // pass3Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        //Coverage Calculator
        pass3Calculators.push(new PlannedCoverageCalculator(CalculatorTimeSpan.Weekly, this))

        // Ground S percent- Periodic
        pass3Calculators.push(new GroundSPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // Stock Plan Fixed Correction - Weekly
        pass3Calculators.push(new StockPlanFixedCalculator(CalculatorTimeSpan.Weekly, this))

        // Effective r and plan Plan - Weekly
        pass3Calculators.push(new EffectiveRAndDPlanCalculator(CalculatorTimeSpan.Weekly, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass3Calculators);

        // ============= //

        // pass 4
        let pass4Calculators: CalculatorInterface[] = [];
        // Gross takt - Periodic
        pass4Calculators.push(new GrossTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // Omni takt - Periodic
        pass4Calculators.push(new OmniTaktCalculator(CalculatorTimeSpan.Periodic, this))

        // Stock Forecast - Weekly
        pass4Calculators.push(new StockForecastCalculator(CalculatorTimeSpan.Weekly, this));

        // Outgoing Stock Prognosis - Weekly
        pass4Calculators.push(new OutgoingStockPrognosisCalculator(CalculatorTimeSpan.Weekly, this));

        // R&D Forecast Percent - Periodic
        pass4Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Periodic, this));
        // R&D Forecast Percent - Weekly
        pass4Calculators.push(new RAndDForecastPercentCalculator(CalculatorTimeSpan.Weekly, this));

        // R&D% - Weekly
        pass4Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Weekly, this))


        // load pass calculators
        this._calculatorConfiguration.push(pass4Calculators);

        // pass 5
        let pass5Calculators: CalculatorInterface[] = [];

        // Stock Prognosis - Weekly
        pass5Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Weekly, this))

        // RAndD Forecast - Weekly
        pass5Calculators.push(new RAndDForecastCalculator(CalculatorTimeSpan.Weekly, this))
        // S Percent- Weekly
        pass5Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Weekly, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass5Calculators);

        // pass 6
        let pass6Calculators: CalculatorInterface[] = [];

        // Combined RAndD Calculator - Weekly
        pass6Calculators.push(new CombinedRAndDCalculator(CalculatorTimeSpan.Weekly, this))
        // Stock Prognosis - Periodic
        pass6Calculators.push(new StockPrognosisCalculator(CalculatorTimeSpan.Periodic, this))

        // S Percent- Period
        pass6Calculators.push(new SPercentCalculator(CalculatorTimeSpan.Periodic, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass6Calculators);


        // pass 7
        let pass7Calculators: CalculatorInterface[] = [];

        // Combined Net Sales - weekly
        pass7Calculators.push(new CombinedNetSalesCalculator(CalculatorTimeSpan.Weekly, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass7Calculators);

        // pass 8
        let pass8Calculators: CalculatorInterface[] = [];
        // Net takt - weekly
        pass8Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Weekly, this))
        // Net takt - Periodic
        pass8Calculators.push(new NetTaktCalculator(CalculatorTimeSpan.Periodic, this))

        pass8Calculators.push(new CSPercentCalculator(CalculatorTimeSpan.Seasonal, this))
        // load pass calculators
        this._calculatorConfiguration.push(pass8Calculators);

        // pass 9
        let pass9Calculators: CalculatorInterface[] = [];
        // buying- weekly
        pass9Calculators.push(new BuyingCalculator(CalculatorTimeSpan.Weekly, this))
        // BoughtOfPlan% Weekly
        pass9Calculators.push(new BoughtOfPlanCalculator(CalculatorTimeSpan.Periodic, this))
        // R&D% - Periodic
        pass9Calculators.push(new RAndDPercentCalculator(CalculatorTimeSpan.Periodic, this))

        // load pass calculators
        this._calculatorConfiguration.push(pass9Calculators);


        // ============= //
    }

    private _configureCalculatorsForSalesAggregateData() {


    }

    cacheInputRows() {

        this.previousInputItems = [];

        let previousInputItem: DepartmentCalculationDataItem = null;

        // set previous retrieval mode
        this.previousRetrievalMode = _.cloneDeep(this.retrievalMode);
        this.previousAdjustedRPercent = this._returnsInfo.adjustedReturnPercent;

        this.uniqueWeekList.forEach((weekWithYear: number, index: number) => {
            if (this._dataSet[index]) {
                previousInputItem = {
                    weekName: weekWithYear,
                    inputForecastGrossPeriodic: this._dataSet[index][CalculationDataItemType.InputForecastGrossPeriodic],
                    inputAddRemoveMSekWeekly: this._dataSet[index][CalculationDataItemType.InputAddRemoveMSekWeekly],
                    inputAddMovesMSekWeekly: this._dataSet[index][CalculationDataItemType.InputAddMovesMSekWeekly],
                    inputRAndDForecastPeriodic: this._dataSet[index][CalculationDataItemType.InputRAndDForecastPeriodic],
                }

                this.previousInputItems.push(previousInputItem);
            }
        });
    }

    calculate() {

        // reset all the temp variables used with the calculators before execution
        this._calculatorConfiguration.forEach((passCalculators: CalculatorInterface[], pass: number) => {
            passCalculators.forEach((passCalculator: CalculatorInterface) => {
                try {
                    if (passCalculator.calculatorType == CalculatorType.KpiCalculator) {
                        passCalculator.reset();
                    }
                }
                catch { }

            });
        });


        this._calculatorConfiguration.forEach((passCalculators: CalculatorInterface[], pass: number) => {

            let currentWeekItem: DepartmentCalculationDataItem = null;
            let previousWeekItem: DepartmentCalculationDataItem = null;
            let periodIndexes = null;
            let currentPeriodWeekItems: DepartmentCalculationDataItem[] = null;

            // check for function calculators. Each function calculator should be in a separate pass. 
            if (passCalculators.length == 1 && passCalculators[0].calculatorType == CalculatorType.FunctionCalculator) {
                // execute function with null inputs
                passCalculators[0].execute(this);
            }
            else {
                this.uniqueWeekList.forEach((weekWithYear: number, index: number) => {
                    passCalculators.forEach((calculator: CalculatorInterface) => {
                        // if its a weekly calculator - run the calculator for each week 
                        // if its a periodic calculator - run the calculator for every 4th week 
                        if (calculator.timespan == CalculatorTimeSpan.Weekly ||
                            (calculator.timespan == CalculatorTimeSpan.Seasonal && index == this.uniqueWeekList.length - 1) ||
                            (calculator.timespan == CalculatorTimeSpan.Periodic && index % 4 == 0)) {
                            currentWeekItem = this._dataSet[index];
                            previousWeekItem = (index == 0) ? null : this._dataSet[index - 1];
                            periodIndexes = this._utilsService._getPeriodIndexesByWeekIndex(index);
                            currentPeriodWeekItems = this._getDataSetItemsByIndexes(periodIndexes);

                            calculator.calculate(currentWeekItem, currentPeriodWeekItems, previousWeekItem);
                        }
                    });
                });
            }
        });
        this.cacheInputRows();
    }



    private _getDataSetItemsByIndexes(indexes: number[]): DepartmentCalculationDataItem[] {
        let dataSetItems: DepartmentCalculationDataItem[] = [];
        indexes.forEach(i => {
            dataSetItems.push(this._dataSet[i]);
        });

        return dataSetItems;
    }

    private updatePreviousDataset() {
        this._previousDataSet = [];
        this._previousDataSet = _.cloneDeep(this._dataSet);
    }

    public handleEvent(event: DepartmentEvent) {
        // bypass validation for previous with old child drivers, since the aggregated driver is already validated
        if (this._seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOldChild || event.validateEvent(this)) {

            this.updatePreviousDataset();

            event.applyEvent(this);
            event.configureCalculators(this);
            this.calculate();

            if (this._seasonInfo.seasonPlanningType != SeasonPlanningType.PreviousWithOldChild && (!this._utilsService.isNotNullOrUndefined(event.undoEvent) || !event.undoEvent)) {
                // add event to history 
                this._planningViewActionHistoryService.addActionEvent(event, this);
            }

            // trigger change event
            this._changeEventListeners.forEach(changeListener => {
                changeListener();
            });
        }
    }



    public getItemsForSave(userConfig: UserConfig): DepartmentSaveItem {

        this._cacheDepartmentSaveItem = null;

        let saveItem: DepartmentSaveItem = {
            weekData: [],
            // periodData: [],
            seasonData: []
        }

        let rowCount = 0;

        this._dataSet.forEach((departmentCalculationDataItem: DepartmentCalculationDataItem) => {

            //#region "Week Level"
            var weekItemRecord: WeekDataItem = _.find(this._rawWeekDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            let weekItemExists  = weekItemRecord != null && weekItemRecord != undefined;

            let weekItemTouched  = weekItemExists && weekItemRecord.isSaved;

            if (departmentCalculationDataItem.demDelPlan != null ||
                // if demdel is null/deleted and raw season data item has values then
                (departmentCalculationDataItem.demDelPlan === null && (weekItemTouched)) ||
                departmentCalculationDataItem.rAndDPlan != null || (departmentCalculationDataItem.rAndDPlan === null && (weekItemTouched)) ||
                departmentCalculationDataItem.addRemoveMSek != null ||(departmentCalculationDataItem.addRemoveMSek === null && (weekItemTouched)) ||
                departmentCalculationDataItem.addMovesMSek != null || (departmentCalculationDataItem.addMovesMSek === null && (weekItemTouched))
            ) {

                if(weekItemExists)
                {
                    let weekLevelSaveItem: WeekSaveItem = {

                        seasonId: weekItemRecord.seasonName,
                        weekId: weekItemRecord.weekName,

                    }

                    if (this._utilsService.isNotNullOrUndefined(departmentCalculationDataItem.demDelPlan)) {
                        weekLevelSaveItem.demDelPlanTSek = departmentCalculationDataItem.demDelPlan;
                    }

                    else if ( weekItemRecord.demDelPlan != null && weekItemRecord.demDelPlan != undefined) {
                        weekLevelSaveItem.demDelPlanTSek = null;
                    }

                    if (this._utilsService.isNotNullOrUndefined(departmentCalculationDataItem.inputRAndDForecastPeriodic)) {
                        weekLevelSaveItem.rAndDPlanPercent = departmentCalculationDataItem.inputRAndDForecastPeriodic;
                        weekLevelSaveItem.rAndDPlan = departmentCalculationDataItem.rAndDPlan;
                    }

                    else if (weekItemRecord.rAndDPlan != null && weekItemRecord.rAndDPlan != undefined) {
                        weekLevelSaveItem.rAndDPlanPercent = null;
                        weekLevelSaveItem.rAndDPlan = null;
                    }

                    if (this._utilsService.isNotNullUndefinedOrZero(departmentCalculationDataItem.addRemoveMSek)) {
                        weekLevelSaveItem.addRemoveMSek = departmentCalculationDataItem.addRemoveMSek;
                    }

                    else if (weekItemRecord.addRemoveMSek != null && weekItemRecord.addRemoveMSek != undefined) {
                        weekLevelSaveItem.addRemoveMSek = null;
                    }

                    if (this._utilsService.isNotNullUndefinedOrZero(departmentCalculationDataItem.addMovesMSek)) {
                        weekLevelSaveItem.addMovesMSek = departmentCalculationDataItem.addMovesMSek;
                    }
                    else if (weekItemRecord.addMovesMSek != null && weekItemRecord.addMovesMSek != undefined) {
                        weekLevelSaveItem.addMovesMSek = null;
                    }

                    saveItem.weekData.push(weekLevelSaveItem);
                }
            }
            //#endregion "Week Level"
            rowCount++;
        });

        //#region "Season Level"
        if (userConfig.planningViewOptions.channel === ChannelType.Online) {
            let seasonLevelSaveData: DepartmentSeasonSaveItem = {
                seasonIntegrationKey: Number(this._returnsInfo.seasonName),
                rPercentChangeFactor: null, // not used at department level
                departmentIntegrationKey: this._userConfigService.getUserConfig().planningViewOptions.structureId,
                unAdjustedReturnPercent: this._returnsInfo.unAdjustedReturnPercent,
                adjustedReturnPercent: this._returnsInfo.adjustedReturnPercent,
                unAdjustedBudgetedReturnPercent: this._returnsInfo.unAdjustedBudgetedReturnPercent,
                adjustedBudgetedReturnPercent: this._returnsInfo.adjustedBudgetedReturnPercent,
                returnFactorPercent: this._returnsInfo.returnFactorPercent,
            }
            saveItem.seasonData.push(seasonLevelSaveData);
        }
        //#endregion "Season Level"        

        this._cacheDepartmentSaveItem = saveItem;

        return saveItem;
    }


    public refreshSeasonRawDataItem(userConfig: UserConfig) {
        //get save items and update the season raw data item
        let departmentSaveItem = this._cacheDepartmentSaveItem;

        if (departmentSaveItem.weekData.length > 0) {
            let weekLevelSaveData = departmentSaveItem.weekData;
            this._rawWeekDataItems.forEach((rawDataItemRecord, index) => {

                let departmentDataItem = _.find(weekLevelSaveData, (saveItem: WeekSaveItem) => saveItem.weekId == rawDataItemRecord.weekName);

                if (this._utilsService.isNotNullOrUndefined(departmentDataItem) && rawDataItemRecord.weekName === departmentDataItem.weekId) {
                    rawDataItemRecord.demDelPlan = this._utilsService.isNotNullOrUndefined(departmentDataItem.demDelPlanTSek) ? departmentDataItem.demDelPlanTSek : null;
                    rawDataItemRecord.plannedReturn = this._utilsService.isNotNullOrUndefined(departmentDataItem.returnPlanPmc) ? departmentDataItem.returnPlanPmc : null;
                    rawDataItemRecord.addRemoveMSek = this._utilsService.isNotNullOrUndefined(departmentDataItem.addRemoveMSek) ? departmentDataItem.addRemoveMSek : null;
                    rawDataItemRecord.addMovesMSek = this._utilsService.isNotNullOrUndefined(departmentDataItem.addMovesMSek) ? departmentDataItem.addMovesMSek : null;
                    rawDataItemRecord.rAndDPlan = this._utilsService.isNotNullOrUndefined(departmentDataItem.rAndDPlanPercent) ? departmentDataItem.rAndDPlan : null;
                    rawDataItemRecord.isSaved = true;
                }
            });

            this.initialAdjustedRPercent = this._returnsInfo.adjustedReturnPercent;
        }

    }

    setSalesAggregatedPmDataSet(salesAggregatedDataItems:WeekDataItem[])
    {
        this._dataSet.forEach((departmentCalculationDataItem: DepartmentCalculationDataItem) => {

            const weekItemRecord: WeekDataItem = _.find(salesAggregatedDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            // Update initial data set with aggregated values so they don't dissappear when user click clear
            let initialWeekItemRecord: WeekDataItem = _.find(this._rawWeekDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            if (weekItemRecord != null) {
                departmentCalculationDataItem.aggregatedPMsAddRemoveMSek = weekItemRecord.aggregatedPMsAddRemoveMSek;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsAddRemoveMSek = weekItemRecord.aggregatedPMsAddRemoveMSek;
                departmentCalculationDataItem.aggregatedPMsRAndDPercentPeriodic = weekItemRecord.aggregatedPMsRAndDPercentPeriodic;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsRAndDPercentPeriodic = weekItemRecord.aggregatedPMsRAndDPercentPeriodic;
                departmentCalculationDataItem.aggregatedPMsGrossTaktPeriodic = weekItemRecord.aggregatedPMsGrossTaktPeriodic;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossTaktPeriodic = weekItemRecord.aggregatedPMsGrossTaktPeriodic;

                departmentCalculationDataItem.aggregatedPMsCombinedSalesPlanWeekly = weekItemRecord.aggregatedPMsCombinedSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsCombinedSalesPlanWeekly = weekItemRecord.aggregatedPMsCombinedSalesPlanWeekly;
                departmentCalculationDataItem.aggregatedPMsEffectiveSalesPlanWeekly = weekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly = weekItemRecord.aggregatedPMsEffectiveSalesPlanWeekly;

                departmentCalculationDataItem.aggregatedPMsGrossSales = weekItemRecord.aggregatedPMsGrossSales;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSales = weekItemRecord.aggregatedPMsGrossSales;
                departmentCalculationDataItem.aggregatedPMsGrossSalesGround = weekItemRecord.aggregatedPMsGrossSalesGround;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSalesGround = weekItemRecord.aggregatedPMsGrossSalesGround;
                departmentCalculationDataItem.aggregatedPMsRAndDPlan = weekItemRecord.aggregatedPMsRAndDPlan;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsRAndDPlan = weekItemRecord.aggregatedPMsRAndDPlan;

                departmentCalculationDataItem.aggregatedPMsGrossSalesLy = weekItemRecord.aggregatedPMsGrossSalesLy
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSalesLy = weekItemRecord.aggregatedPMsGrossSalesLy;
                departmentCalculationDataItem.aggregatedPMsGrossSales2Ground = weekItemRecord.aggregatedPMsGrossSales2Ground;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsGrossSales2Ground = weekItemRecord.aggregatedPMsGrossSales2Ground;
                departmentCalculationDataItem.aggregatedPMsIsSaved = weekItemRecord.aggregatedPMsIsSaved;
                if (initialWeekItemRecord) initialWeekItemRecord.aggregatedPMsIsSaved = weekItemRecord.aggregatedPMsIsSaved;

                //main copy system goal 
                departmentCalculationDataItem.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopySystemGoal = weekItemRecord.marketMainCopySystemGoal;

                departmentCalculationDataItem.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyCombinedSalesPlanWeekly = weekItemRecord.marketMainCopyCombinedSalesPlanWeekly;
                departmentCalculationDataItem.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyEffectiveSalesPlanWeekly = weekItemRecord.marketMainCopyEffectiveSalesPlanWeekly;

                departmentCalculationDataItem.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSalesGround = weekItemRecord.marketMainCopyGrossSalesGround;
                departmentCalculationDataItem.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSalesLy = weekItemRecord.marketMainCopyGrossSalesLy;
                departmentCalculationDataItem.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
                if (initialWeekItemRecord) initialWeekItemRecord.marketMainCopyGrossSales2Ground = weekItemRecord.marketMainCopyGrossSales2Ground;
            }
        });

        this.hasSalesAggregatedPmDataSetLoaded = true;
    }

    setSalesSystemGoalInAssortmentDataSet(salesGoalInAssortmentDataItems:WeekDataItem[]): void
    {
        this._dataSet.forEach((departmentCalculationDataItem: DepartmentCalculationDataItem) => {

            var weekItemRecord: WeekDataItem = _.find(salesGoalInAssortmentDataItems, (x: WeekDataItem) => x.weekName == departmentCalculationDataItem.weekName);

            if(weekItemRecord != null ) {
                departmentCalculationDataItem.salesMainCopyCombinedSalesPlanWeekly = weekItemRecord.salesMainCopyCombinedSalesPlanWeekly
                departmentCalculationDataItem.salesMainCopyGrossSalesGround = weekItemRecord.salesMainCopyGrossSalesGround
                departmentCalculationDataItem.salesMainCopySystemGoal = weekItemRecord.salesMainCopySystemGoal;
            }

        });

        this.hasSalesSystemGoalInAssortmentLoaded = true;
    }
}
