export const environment = {
  production: true,
  envName: "Test",
  appTitle:"Omni TDP - Test",
  favIcon: '../assets/favicons/favicon_Test.ico',
  appInsightsInstrumentationKey: "54409ffb-1e92-4160-9599-0b0d91aa5366",
  azureAd: {
    clientId: 'ee99402b-9ed0-4f16-bd59-1732b587b23b',
    tenantId: '30f52344-4663-4c2e-bab3-61bf24ebbed8',
    scopes: ["user.read", "e2892ca5-de05-463e-ba88-274de2c2b28f/API.Access"],
    authority: 'https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8',
    redirectUri: 'https://omnitdp-test.hmgroup.tech/',
    postLogoutRedirectUri: '/',
    loginFailedRoute: '/login-failed'
  },
  baseApiUrl: "https://omnitdp-apim-test.azure-api.net/",
  baseHeavyLoadApiUrl: "https://omnitdp-apim-test.azure-api.net/",
  protectedResourceMap:
  {
    graphResource:
    {
      key: 'https://graph.microsoft.com/v1.0/me',
      value: "user.read"
    },
    apiAccessResource:
    {
      key: "https://omnitdp-apim-test.azure-api.net/",
      value: "e2892ca5-de05-463e-ba88-274de2c2b28f/API.Access"
    },
    heavyLoadApiAccessResource:
    {
      key: "https://omnitdp-apim-test.azure-api.net/",
      value: "e2892ca5-de05-463e-ba88-274de2c2b28f/API.Access"
    }
  }
};
