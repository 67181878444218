import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'thousandSeparator'
})
export class ThousandSeparatorPipe implements PipeTransform {

  transform(val: number): string {
    if (val !== undefined && val !== null) {
      let stringVal = val.toLocaleString('en-US'); // This will be , as thousand separator and . as decimal separator
      while (stringVal.indexOf(',') !== -1) stringVal = stringVal.replace(',', ' ');
      return stringVal;
    } else {
      return '';
    }
  }
}
