import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { SeasonPlanningType } from "../../types/season-planning-type";

export class GrossTaktCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets Gross Takt Weekly

    // Dependencies
    // ------------
    // Gross Sales Ground (raw)
    // Gross Sales (raw)

    // Calculates
    // ----------
    // Gross Takt Weekly


    // Periodic
    // ------
    // Sets Gross Takt Periodic

    // Dependencies
    // ------------
    // Combined Sales Plan Weekly (calculated)
    // Gross Sales Ground (raw)

    // Calculates
    // ----------
    // Gross Takt Periodic (calculated)


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();

        // Gross Takt = (Gross Sales/Ground Sales) * 100
        if (
            utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGround]) &&
            utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSales])
        ) {
            value = ((currentWeekItem[CalculationDataItemType.GrossSales]) / (currentWeekItem[CalculationDataItemType.GrossSalesGround] + (currentWeekItem[CalculationDataItemType.StartWeekAfterCurrentWeek] ? currentWeekItem[CalculationDataItemType.StartWeekSalesGrossGround] : 0))) * 100;
        }

        currentWeekItem[CalculationDataItemType.GrossTaktWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let combinedSalesPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedSalesPlanWeekly);
        let grossSalesGroundSum = 0;
        let startWeekSalesGround = 0;
        let season = parseInt(this.calculatorDriver.getSeasonInfo().seasonCodeNames[0]);

        if ((this.calculatorDriver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Coming || this.calculatorDriver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Future) &&
            parseInt(currentPeriodWeekItems[0][CalculationDataItemType.WeekName].toString()) <= utils.getStartWeekForSeason(season) &&
            currentPeriodWeekItems[0][CalculationDataItemType.StartWeekAfterCurrentWeek]) {
            startWeekSalesGround += currentPeriodWeekItems[0][CalculationDataItemType.StartWeekSalesGrossGround];
        }
        currentPeriodWeekItems.forEach(item => {
                grossSalesGroundSum += item[CalculationDataItemType.GrossSalesGround];
        });

        if (utils.isNotNullUndefinedOrZero(grossSalesGroundSum + startWeekSalesGround)) {
            value = combinedSalesPlanSum / (grossSalesGroundSum + startWeekSalesGround) * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.GrossTaktPeriodic] = value;
        });
    }
}
