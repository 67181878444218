<div *ngIf="dialogData.copyDialogType=='assortment-create-copy'">
    <h2 mat-dialog-title>Create Copy</h2>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-md-3">
                <h3 class="label-name">Brand:</h3>
            </div>
            <div class="col-md-6">
                <h3>{{assortmentCreateCopyDataItem.brand.name}}</h3>
            </div>
        </div>
        <div class="row" *ngIf="assortmentCreateCopyDataItem.structure.name != brandstructurename">
            <ng-container
                *ngIf="assortmentCreateCopyDataItem.structureType.name.startsWith('Section'); then showSection; else showCustomerGroup">
            </ng-container>
            <ng-template #showSection>
                <div class="col-md-3">
                    <h3 class="label-name">Section:</h3>
                </div>
            </ng-template>
            <ng-template #showCustomerGroup>
                <div class="col-md-4">
                    <h3 class="label-name">Customer Group:</h3>
                </div>
            </ng-template>

            <div class="col-md-6">
                <h3>{{assortmentCreateCopyDataItem.structure.code}} - {{assortmentCreateCopyDataItem.structure.name}}
                </h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form class="example-form" [formGroup]="assortmentCopyForm" (ngSubmit)="onAssortmentFormSubmit()">
                    <div class="row">
                        <h3 class="label-name">Copy name:<span class="required-asterisk">*</span></h3>
                        <mat-error>
                            <span
                                *ngIf="!assortmentCopyForm.get('name').value && assortmentCopyForm.get('name').touched"></span>
                            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                                {{errorMessage}}
                            </span>
                        </mat-error>
                        <mat-form-field class="form-field example-full-width" appearance="fill">
                            <input formControlName="name" matInput placeholder="Copy name">
                        </mat-form-field>
                        <md-hint [style.text-align]="'end'" style="color:rgb(245, 33, 33);">Max 25 characters</md-hint>
                    </div>
                    <div class="row">
                        <mat-dialog-actions align="end">
                            <button type="submit" mat-raised-button color="primary"
                                [disabled]="!assortmentCopyForm.valid">Create</button>
                            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
                        </mat-dialog-actions>
                    </div>
                </form>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='assortment-edit-copy'">
    <h2 mat-dialog-title>Edit Copy</h2>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-md-3">
                <h3 class="label-name">Brand:</h3>
            </div>
            <div class="col-md-6">
                <h3>{{assortmentEditCopyDataItem.brand.name}}</h3>
            </div>
        </div>
        <div class="row" *ngIf="assortmentEditCopyDataItem.structure.name != brandstructurename">
            <ng-container
                *ngIf="assortmentEditCopyDataItem.structureType.name.startsWith('Section'); then showSection; else showCustomerGroup">
            </ng-container>
            <ng-template #showSection>
                <div class="col-md-3">
                    <h3 class="label-name">Section:</h3>
                </div>
            </ng-template>
            <ng-template #showCustomerGroup>
                <div class="col-md-4">
                    <h3 class="label-name">Customer Group:</h3>
                </div>
            </ng-template>
            <div class="col-md-6">
                <h3>{{assortmentEditCopyDataItem.structure.code}} -
                    {{assortmentEditCopyDataItem.structure.name}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form class="example-form" [formGroup]="assortmentCopyForm" (ngSubmit)="onAssortmentFormSubmit()">

                    <div class="row">
                        <h3 class="label-name">Copy name:<span class="required-asterisk">*</span></h3>
                        <mat-form-field class="form-field example-full-width" appearance="fill">
                            <input formControlName="name" matInput placeholder="Copy name">
                        </mat-form-field>
                        <md-hint [style.text-align]="'end'" style="color:rgb(245, 33, 33);">Max 25 characters</md-hint>
                    </div>
                    <div class="row">
                        <mat-error>
                            <span
                                *ngIf="!assortmentCopyForm.get('name').value && assortmentCopyForm.get('name').touched"></span>
                            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                                {{errorMessage}}
                            </span>
                        </mat-error>
                    </div>
                    <div class="row">
                        <mat-dialog-actions align="end">
                            <button type="submit" mat-raised-button color="primary"
                                [disabled]="!assortmentCopyForm.valid" cdkFocusInitial>Save</button>
                            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
                        </mat-dialog-actions>
                    </div>
                </form>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='assortment-delete-copy'">
    <h2 mat-dialog-title style="text-align: center;">Delete Copy</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                Are you sure you want to delete this copy "<span
                    class='dialogboxname'>{{assortmentEditCopyDataItem.copy.name}}</span>"?
            </h3>
        </span>
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="warn"
                (click)="deleteAssortmentCopy(assortmentEditCopyDataItem)" [disabled]="deleteButtonEnabled">OK</button>
            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>


<div *ngIf="dialogData.copyDialogType=='sales-create-copy'">
    <h2 mat-dialog-title>Create Copy</h2>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-md-3">
                <h3 class="label-name">Brand:</h3>
            </div>
            <div class="col-md-6">
                <h3>{{salesCreateCopyDataItem.brand.name}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <h3 class="label-name">PR/PM:<span class="required-asterisk">*</span></h3>
            </div>
            <div class="col-md-6">
                <mat-form-field appearance="fill" *ngIf="salesCreateCopyDataItem.salesCopyDialogMarkets.length > 0">
                    <mat-select id="copydialog-ddlmarkets" placeholder="Select Market" [(ngModel)]="selectedMarket" [hideSingleSelectionIndicator]="true">
                        <mat-option *ngFor="let market of salesCreateCopyDataItem.salesCopyDialogMarkets" [value]="market">
                            {{market.shortName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form class="example-form" [formGroup]="salesCopyForm" (ngSubmit)="onSalesFormSubmit()">
                    <div class="row">
                        <h3 class="label-name">Copy name:<span class="required-asterisk">*</span></h3>
                        <mat-form-field class="form-field example-full-width" appearance="fill">
                            <input formControlName="name" matInput placeholder="Copy name">
                        </mat-form-field>
                        <md-hint [style.text-align]="'end'" style="color:rgb(245, 33, 33);">Max 25 characters</md-hint>
                    </div>
                    <div class="row">
                        <mat-error>
                            <span *ngIf="!salesCopyForm.get('name').value && salesCopyForm.get('name').touched"></span>
                            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                                {{errorMessage}}
                            </span>
                        </mat-error>
                    </div>
                    <div class="row">
                        <mat-dialog-actions align="end">
                            <button type="submit" mat-raised-button color="primary"
                                [disabled]="!salesCopyForm.valid">Create</button>
                            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
                        </mat-dialog-actions>
                    </div>
                </form>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='sales-edit-copy'">
    <h2 mat-dialog-title>Edit Copy</h2>
    <mat-dialog-content class="mat-typography">
        <div class="row">
            <div class="col-md-3">
                <h3 class="label-name">Brand:</h3>
            </div>
            <div class="col-md-6">
                <h3>{{salesEditCopyDataItem.brand.name}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <h3 class="label-name">PR/PM:</h3>
            </div>
            <div class="col-md-6">
                <h3>{{salesEditCopyDataItem.mainMarket.marketIntegrationKey}} - {{salesEditCopyDataItem.mainMarket.shortName}}</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <form class="example-form" [formGroup]="salesCopyForm" (ngSubmit)="onSalesFormSubmit()">

                    <div class="row">
                        <h3 class="label-name">Copy name:<span class="required-asterisk">*</span></h3>
                        <mat-form-field class="form-field example-full-width" appearance="fill">
                            <input formControlName="name" matInput placeholder="Copy name">
                        </mat-form-field>
                        <md-hint [style.text-align]="'end'" style="color:rgb(245, 33, 33);">Max 25 characters</md-hint>
                    </div>
                    <div class="row">
                        <mat-error>
                            <span *ngIf="!salesCopyForm.get('name').value && salesCopyForm.get('name').touched"></span>
                            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                                {{errorMessage}}
                            </span>
                        </mat-error>
                    </div>
                    <div class="row">
                        <mat-dialog-actions align="end">
                            <button type="submit" mat-raised-button color="primary" [disabled]="!salesCopyForm.valid"
                                cdkFocusInitial>Save</button>
                            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
                        </mat-dialog-actions>
                    </div>
                </form>
            </div>
        </div>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='sales-delete-copy'">
    <h2 mat-dialog-title style="text-align: center;">Delete Copy</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                Are you sure you want to delete this copy "<span
                    class='dialogboxname'>{{salesEditCopyDataItem.copy.name}}</span>"?
            </h3>
        </span>
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="warn" (click)="deleteSalesCopy(salesEditCopyDataItem)"
                [disabled]="deleteButtonEnabled">OK</button>
            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='assortment-merge-copy'">
    <h2 mat-dialog-title style="text-align: center;">Merge Copy</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                Are you sure you want to merge this copy "<span
                    class='dialogboxname'>{{assortmentCopyData.name}}</span>"?
            </h3>
        </span>
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary" (click)="mergeAssortmentCopy(assortmentCopyData)"
                [disabled]="mergeButtonEnabled">Merge</button>
            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='sales-merge-copy'">
    <h2 mat-dialog-title style="text-align: center;">Merge Copy</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                Are you sure you want to merge this copy "<span class='dialogboxname'>{{salesCopyData.name}}</span>"?
            </h3>
        </span>
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary" (click)="mergeSalesCopy(salesCopyData)"
                [disabled]="mergeButtonEnabled">Merge</button>
            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>

<div *ngIf="dialogData.copyDialogType=='sales-parent-trickle-down-save-by-copy'">
    <h2 mat-dialog-title style="text-align: center;">TrickleDown Copy</h2>
    <mat-dialog-content class="mat-typography">
        <span style="text-align: center;">
            <h3>
                "Are you sure you want to trickle down all your plans for the copy "<span class='dialogboxname'>{{salesCopyData.name}}</span>"? Please note that this will take some time and the copy will be locked meanwhile."
            </h3>
        </span>
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary" (click)="salesParentTrickleDownSaveByCopy(salesCopyData)"
                [disabled]="mergeButtonEnabled">TrickleDown All</button>
            <button mat-raised-button mat-dialog-close [disabled]="cancelButtonDisabled">Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>