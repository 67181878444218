import { Component } from "@angular/core";
import { UserConfigService } from "src/app/modules/shared/services/user-config.service";

export interface TrickleDownDialogStatusSettings {
    dontShowMeAgain: boolean
}


@Component({
    selector: 'save-trickle-down-dialog',
    templateUrl: "./save-trickle-down-dialog.component.html",
    styleUrls: ['./save-trickle-down-dialog.component.css']
})

export class SaveTrickleDownDialogComponent {

    trickleDownStatus: TrickleDownDialogStatusSettings = { dontShowMeAgain: false };

    constructor(
        private _userConfigService: UserConfigService) {

    }

    saveSetting() {
        let dontShowMeAgain = this.trickleDownStatus.dontShowMeAgain;

        if (dontShowMeAgain) {
            this._userConfigService.setTrickleDownDialogSettings(dontShowMeAgain);
        }
    }
}