import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class MarketMainSystemGoalCalculator extends AbstractCalculator {

    // Periodic
    // ------
    // Market Main Sets System Goal Periodic

    // Dependencies
    // ------------
    // Market Main Effectve Plan  (raw)
    // Market Main Gross Sales Ground (raw)

    // Calculates
    // ----------
    // Market Main System Goal Periodic (calculated)

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let marketMainEffectiveSystemGoalPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.MarketMainCopyEffectiveSalesPlanWeekly);
        let marketMainGrossSalesGroundSum = 0;

        currentPeriodWeekItems.forEach(item => {
                marketMainGrossSalesGroundSum += item[CalculationDataItemType.MarketMainCopyGrossSalesGround];
        });

        if (utils.isNotNullUndefinedOrZero(marketMainGrossSalesGroundSum)) {
            value = marketMainEffectiveSystemGoalPlanSum / marketMainGrossSalesGroundSum * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.MarketMainCopySystemGoal] = value;
        });
    }
}