import * as _ from 'lodash';
import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { ChannelType } from "../../types/channel-type";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";
export class CSPercentCalculator extends AbstractCalculator {

    // Weekly
    // ------
    // Sets CS Percent Per each season

    // Dependencies
    // ------------
    // Outgoing Stock Prognosis 
    // Sales Plan + Forecast


    // Calculates
    // ----------
    // CS Percent On season



    _calculateSeasonalValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();
        let retrievalMode = this.calculatorDriver.getRetrievalMode();
        let seasonInfo = this.calculatorDriver.getSeasonInfo();
        let channelType: ChannelType = this.calculatorDriver.getUserConfig().planningViewOptions.channel;

        let actualSeasonName = utils.getSeasonNameForOtherPlanningTypeInCurrentView(seasonInfo.seasonCodeNames[0], seasonInfo.seasonPlanningType, SeasonPlanningType.Actual);

        let seasonStartWeek = utils.getStartWeekForSeason(parseInt(actualSeasonName));

        let seasonEndWeek = utils.getEndWeekForSeason(parseInt(seasonInfo.seasonCodeNames[0]));

        if (seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOld || seasonInfo.seasonPlanningType == SeasonPlanningType.Previous) {
            seasonEndWeek = utils.getEndWeekForSeason(parseInt(actualSeasonName));
        }

        let dataset = this.calculatorDriver.getDataSetRange(seasonStartWeek, seasonEndWeek);

        if (dataset.length > 0) {
            let endWeekDataItem = _.find(dataset, function (obj) {
                return obj.weekName === seasonEndWeek;
            });

            let combinedSalesPlanWeeklySum = utils.sumKpiForPeriod(dataset, CalculationDataItemType.CombinedSalesPlanWeekly);
            let planReturnWeeklySum = utils.sumKpiForPeriod(dataset, CalculationDataItemType.PlannedReturn);

            if (retrievalMode == RetrievalMode.Bought || retrievalMode == RetrievalMode.Initial) {

                let denominator = combinedSalesPlanWeeklySum - planReturnWeeklySum;

                value = utils.safeDivide(endWeekDataItem[CalculationDataItemType.OutgoingStockPrognosisWeekly], denominator) * 100;
            }
            else {
                let endWeekStockPlanFixed = endWeekDataItem[CalculationDataItemType.StockPlanFixed];
                let addMoveSum = utils.sumKpiForPeriod(dataset, CalculationDataItemType.AddMovesMSek)* Constants.TSEKTOMSEK;
                let addRemoveSum = utils.sumKpiForPeriod(dataset, CalculationDataItemType.AddRemoveMSek)* Constants.TSEKTOMSEK;
                let cubeDemDelPlanPlanWeeklySum = utils.sumKpiForPeriod(dataset, CalculationDataItemType.CubeDemDelPlan);

                let numerator = (endWeekStockPlanFixed + addMoveSum + addRemoveSum) + (cubeDemDelPlanPlanWeeklySum - combinedSalesPlanWeeklySum)

                let denominator = cubeDemDelPlanPlanWeeklySum - (cubeDemDelPlanPlanWeeklySum - combinedSalesPlanWeeklySum);

                if (channelType == ChannelType.Online) {
                    let returnPlanFixedWeeklySum = utils.sumKpiForPeriod(dataset, CalculationDataItemType.ReturnPlanFixed);

                    //denominator -= returnPlanFixedWeeklySum + (returnPlanFixedWeeklySum - planReturnWeeklySum);

                    // changed the formula based on Linus suggestion to handle negative CS% values
                    denominator -= returnPlanFixedWeeklySum + (planReturnWeeklySum - returnPlanFixedWeeklySum);
                }

                value = utils.safeDivide(numerator, denominator) * 100;
            }
        }
        this.calculatorDriver.setCSPercent(value);
    }
}
