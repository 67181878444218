import { NestedTreeControl } from '@angular/cdk/tree';
import { DatePipe } from '@angular/common';
import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { Chart, ChartOptions } from 'chart.js';
import { default as Annotation } from 'chartjs-plugin-annotation';
import * as _ from 'lodash';
import { BaseChartDirective } from 'ng2-charts';
import { BehaviorSubject } from 'rxjs';
import { PublishDialogData } from 'src/app/modules/planning-view/types/publish-dialog-data';
import { CopyDialogBoxComponent } from 'src/app/modules/selection-view/components/copy-dialog/copy-dialog.component';
import { AssortmentCopy } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy, SalesCopyTrickleDownFailedStatus } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { TrickleDownAllCopyDialogData } from 'src/app/modules/selection-view/types/copy-dialog-data';
import { MergeCopyDialogData } from 'src/app/modules/selection-view/types/merge-copy-dialog-data';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { ParentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/parent-calculator-driver-interface';
import { ReadonlyDepartmentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/readonly-department-calculator-driver-interface';
import { DialogBoxService } from 'src/app/modules/shared/services/dialog-box-service';
import { AppInsightsLoggingService } from 'src/app/modules/shared/services/error-handling/app-insights-logging.service';
import { ExportService } from 'src/app/modules/shared/services/export.service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { SeasonInfoRequest } from 'src/app/modules/shared/types/api/season-data/season-info-request';
import { BrandType } from 'src/app/modules/shared/types/brand-type';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { Constants } from 'src/app/modules/shared/types/constants';
import { FileType } from 'src/app/modules/shared/types/file-type';
import { Market } from 'src/app/modules/shared/types/market';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { OrganizationType } from 'src/app/modules/shared/types/organization-type';
import { PageState } from 'src/app/modules/shared/types/page-state';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { SelectedOptions } from 'src/app/modules/shared/types/selected-options';
import { SelectionViewOptions } from 'src/app/modules/shared/types/selection-view-options';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { PlanningViewLayoutSettings, SeasonSetting, UserConfig } from 'src/app/modules/shared/types/user-config';
import { WeekDataItem } from 'src/app/modules/shared/types/week-data-item';
import { DepartmentCalculationDriver } from '../../classes/calculation-drivers/department-calculation-driver/department-calculation-driver';
import { PreviousAndOldDepartmentCalculationDriver } from '../../classes/calculation-drivers/department-calculation-driver/previous-and-old-department-calculation-driver';
import { TotalDepartmentCalculationDriver } from '../../classes/calculation-drivers/department-calculation-driver/total-department-calculation-driver';
import { ParentCalculationDriver } from '../../classes/calculation-drivers/parent-calculation-driver/parent-calculation-driver';
import { TotalParentCalculationDriver } from '../../classes/calculation-drivers/parent-calculation-driver/total-parent-calculation-driver';
import { ReadonlyDepartmentCalculationDriver } from '../../classes/calculation-drivers/readonly-department-calculation-driver/readonly-department-calculation-driver';
import { TotalReadonlyDepartmentCalculationDriver } from '../../classes/calculation-drivers/readonly-department-calculation-driver/total-readonly-department-calculation-driver';
import { EventHandlerService } from '../../services/event-handler-service';
import { PlanningViewActionHistoryService } from '../../services/planning-view-action-history-service';
import { PlanningViewService } from '../../services/planning-view.service';
import { SeasonDataService } from '../../services/season-data.service';
import { AssortmentPlanningViewRequest } from '../../types/api/assortment-planning-view/assortment-planning-view-request';
import { AssortmentPlanningViewResponse } from '../../types/api/assortment-planning-view/assortment-planning-view-response';
import { ParentSeasonTableInfo } from '../../types/api/parent-season-table-info';
import { PlanningViewDataRequest } from '../../types/api/planning-view-data-request';
import { DepartmentPlanningViewDataResponse } from '../../types/api/planning-view-data/department-planning-view-data-response';
import { ParentPlanningViewDataResponse } from '../../types/api/planning-view-data/parent-planning-view-data-response';
import { ReadonlyDepartmentPlanningViewDataResponse } from '../../types/api/planning-view-data/readonly-department-planning-view-data-response';
import { ReadonlyDepartmentSeasonTableInfo } from '../../types/api/readonly-department-season-table-info';
import { SalesPlanningViewRequest } from '../../types/api/sales-planning-view/sales-planning-view-request';
import { SalesPlanningViewResponse } from '../../types/api/sales-planning-view/sales-planning-view-response';
import { SeasonTableInfo } from '../../types/api/season-table-info';
import { StashStatusData } from '../../types/api/stash-status-data';
import { OrganizationSummaryViewDataResponse } from '../../types/api/organization-summary-view-data/organization-summary-view-data-response';
import { TotalViewRequestDataItem, TotalViewRequestDataResponse, TotalViewRequestStatus } from '../../types/api/total-view-request-data/total-view-request-data-response';
import { CalculationDataItemType } from '../../types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from '../../types/department-calculation-data-item';
import { DepartmentSaveItem } from '../../types/department-save-item';
import { InputLockStatus } from '../../types/input-lock-status-enum';
import { PlanningViewNavigationTreeNode } from '../../types/planningViewNavigationTreeNode';
import { SaveWarningDialogData } from '../../types/save-warning-dialog-data';
import { SummaryViewSelection } from '../../types/summary-view-selection';
import { TreeNode } from '../../types/tree-node';
import { PublishDialogComponent } from '../publish-dialog/publish-dialog.component';
import { SaveTrickleDownDialogComponent } from '../save-trickle-down-dialog/save-trickle-down-dialog.component';
import { SaveWarningDialogComponent } from '../save-warning-dialog/save-warning-dialog.component';
import { SaveWarningTrickleDownDialogComponent } from '../save-warning-trickle-down-dialog/save-warning-trickle-down-dialog.component';
import { CubeInfoService } from '../../services/cube-info-service';
import { CubeInfoResponse } from '../../types/api/cube-info/cube-info-response';
import { FeatureFlagService } from 'src/app/modules/shared/services/feature-flag.service';
import { Section } from 'src/app/modules/shared/types/section';

@Component({
  selector: 'app-planning-view',
  templateUrl: './planning-view.component.html',
  styleUrls: ['./planning-view.component.css']
})
export class PlanningViewComponent implements OnInit {

  // for access from Template
  lodash = _;

  // holds all the options selected on the UI
  selectedOptions: SelectedOptions;

  //graph
  @ViewChild('chartObj')
  chartObj: BaseChartDirective;
  _cachedchartData: any = null;
  public chartData;
  public chartOptions: ChartOptions;

  // data variables
  assortmentResponseData: AssortmentPlanningViewResponse = null;
  salesResponseData: SalesPlanningViewResponse = null;

  // ui variables
  structureTreeData = new MatTreeNestedDataSource<PlanningViewNavigationTreeNode>();
  treeControl = new NestedTreeControl<PlanningViewNavigationTreeNode>(node => node.children);
  activeNode: any = null;

  assortmentRegionOptions: Market[] = [];
  assortmentPlanningMarketOptions: Market[] = [];

  salesRegionOption: Market;
  salesPlanningMarketOptions: Market[] = [];
  departmentToDisplay: number [] = [];
  departmentcheck  = new Map<string, number[]>();

  // set default initial state
  pageState: PageState = PageState.Loading;
  // to be loaded from feature flag service	
  showDatepicker: boolean = false;
  // Organization booleans
  isSalesView = false;
  isAssortmentView = false;
  // selection variables
  sidenavExpanded = true;
  planningViewTablesInitialized = false;
  planningViewTablesLoaded = false;
  planningViewTableRenderComplete = null;
  // table booleans
  totalSeasonTableRendered = false;
  previousSeasonTableRendered = false;
  actualSeasonTableRendered = false;
  comingSeasonTableRendered = false;
  futureSeasonTableRendered = false;
  enableStoreChannel: boolean;
  enableOnlineChannel: boolean;
  enableOmniChannel: boolean = true;

  //new structure
  isAggregatedMainViewEnabled: boolean = false;
  aggregatedTotalSeasonTableRendered: boolean = false;
  aggregatedPreviousSeasonTableRendered: boolean = false;
  aggregatedActualSeasonTableRendered: boolean = false;
  aggregatedComingSeasonTableRendered: boolean = false;
  aggregatedFutureSeasonTableRendered: boolean = false;

  // debug booleans
  seasonTableDebugMode = false;

  // Selected Regions and PMs
  selectedRegion = null;
  selectedPlanningMarket = null;
  selectedPm = null;
  selectedPercentPlanningMarket = null;

  selectedMarketId;
  selectedMarketType;

  isMergeButtonDisabled: boolean = true;
  isPublishButtonDisabled: boolean = false;
  isSaveButtonDisabled: boolean = false;
  isEnabledForAnaplan: boolean = false;

  selectedDate: Date = null;
  currentWeekWithYear: number = null;
  currentPeriodWeeks: number[] = [];

  seasons: SeasonInfo[];

  userConfig: UserConfig = null;

  summaryViewSelection: SummaryViewSelection = null;

  // Department Level Season table data variables
  previousDepartmentSeasonTableData: SeasonTableInfo = null;
  actualDepartmentSeasonTableData: SeasonTableInfo = null;
  comingDepartmentSeasonTableData: SeasonTableInfo = null;
  futureDepartmentSeasonTableData: SeasonTableInfo = null;
  totalDepartmentSeasonTableData: SeasonTableInfo = null;

  // parent table data variables
  previousParentSeasonTableData: ParentSeasonTableInfo = null;
  actualParentSeasonTableData: ParentSeasonTableInfo = null;
  comingParentSeasonTableData: ParentSeasonTableInfo = null;
  futureParentSeasonTableData: ParentSeasonTableInfo = null;
  totalParentSeasonTableData: ParentSeasonTableInfo = null;

  // Read only Season table data variables
  readonlyDepartmentPreviousSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  readonlyDepartmentActualSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  readonlyDepartmentComingSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  readonlyDepartmentFutureSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  readonlyDepartmentTotalSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;

  // Read only Season table data variables
  aggregatedDepartmentPreviousSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  aggregatedDepartmentActualSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  aggregatedDepartmentComingSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  aggregatedDepartmentFutureSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;
  aggregatedDepartmentTotalSeasonTableData: ReadonlyDepartmentSeasonTableInfo = null;

  // Refs to the table data drivers. At all levels
  previousSeasonTableDataDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface = null;
  actualSeasonTableDataDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface = null;
  comingSeasonTableDataDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface = null;
  futureSeasonTableDataDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface = null;
  totalSeasonTableDataDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface = null;

  aggregatedPreviousSeasonTableDataDriver: ReadonlyDepartmentCalculatorDriverInterface = null;
  aggregatedActualSeasonTableDataDriver: ReadonlyDepartmentCalculatorDriverInterface = null;
  aggregatedComingSeasonTableDataDriver: ReadonlyDepartmentCalculatorDriverInterface = null;
  aggregatedFutureSeasonTableDataDriver: ReadonlyDepartmentCalculatorDriverInterface = null;
  aggregatedTotalSeasonTableDataDriver: ReadonlyDepartmentCalculatorDriverInterface = null;

  isDepartmentLevelSeasonTablesRequested: boolean = false;
  isParentLevelSeasonTablesRequested: boolean = false;
  isReadonlyDepartmentSeasonTablesRequested: boolean = false;
  isAggregatedDepartmentSeasonTablesRequested: boolean = false;

  currentSelectedTab: number = 0;

  brandstructurename: string = Constants.BRAND_STRUCTURE_NAME;

  totalViewRequestsEnabled: boolean = false;
  totalViewRequestData: TotalViewRequestDataResponse = null;
  totalViewRequestStatus: string = '';
  totalViewDefaultRetrievalmode: string = '';
  totalViewRequestedAt: string = ''
  totalViewProcessedAt: string = '';
  totalPlanningViewResponseLoading: boolean = false;
  generateRequestLoading: boolean = false;
  // season selector variables
  availableViewSeasonsList = [];
  selectedViewSeasonsTablesList = [];
  previouslySelectedViewSeasonsTablesList = null;

  assortmentsalestystemgoalresults: { [seasontype: string]: any } = {};
  availableViewKpiListInitial: string[] = [];
  availableViewKpiList: string[] = ["Omni Takt/Goal", "System Goal", "Include Sales System Goals", "Net Takt/Goal", "Takt/Goal", "Net Takt", "Gross Takt", "Avg. S%", "S%", "Avg. R&D%", "R&D%", "Avg. Stock Prognosis", "Bought of Plan%", "Stock Prognosis", "Stock % SalesNet52", "Takt Gnd", "Avg. S% Gnd", "S% Gnd", "Avg. R&D% Gnd", "R&D% Gnd", "Stock Index Gnd"];
  selectedViewKpis = ["Omni Takt/Goal", "System Goal", "Net Takt/Goal", "Takt/Goal", "Net Takt", "Gross Takt", "Avg. S%", "S%", "Avg. R&D%", "R&D%", "Avg. Stock Prognosis", "Bought of Plan%", "Stock Prognosis", "Stock % SalesNet52", "Takt Gnd", "Avg. S% Gnd", "S% Gnd", "Avg. R&D% Gnd", "R&D% Gnd", "Stock Index Gnd"];
  isNodeFound = false;
  isSystemGoalSelected: boolean = false;
  areSalesSystemGoalsIncludedForAssortment: boolean = false;
  hasSalesSystemGoalInAssortmentLoaded: boolean = false;
  isPercentPmFeatureEnabled: boolean = false;
  isPlanningViewGraphVisible: boolean = true;
  showAllLegends: boolean = true;
  isChartPinned: boolean = false;
  showTrickleDownConfirmationDialog: boolean = true;
  kpiSelectionKey: string = "";
  kpiSelection: boolean = false;
  isLatestDataRefreshTimeRendered: boolean = false;
  latestDataRefreshTime: string;

  childNodes: TreeNode[] = null;
  organizationSummaryData: OrganizationSummaryViewDataResponse[] = null;
  public simulationViewUpdateSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  // check children method for tree selector
  hasChild = (__: number, node: PlanningViewNavigationTreeNode) => !!node.children && node.children.length > 0;

  stashDataAvailableByCopy = false;
  stashedDataExists = false;
  stashedData: DepartmentSaveItem = null; // if stashedData is set, it means stashed data exists for the current view
  stashStructureType: string = null;

  salesCopyTrickleDownFailedStatus: SalesCopyTrickleDownFailedStatus = null;

  @HostListener('window:beforeunload', ['$event'])
  WindowBeforeUnload($event: any) {

    if (this.preventUserFromNavigating()) {
      $event.returnValue = true;
    }

  }

  constructor(
    private _router: Router,
    private _planningViewService: PlanningViewService,
    private _loadingAnimationService: LoadingAnimationsService,
    private _seasonDataService: SeasonDataService,
    private _utilsService: UtilsService,
    private _appInsightsLoggingService: AppInsightsLoggingService,
    private _featureFlagService: FeatureFlagService,
    private _dialogBoxService: DialogBoxService,
    private _exportService: ExportService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    private _planningViewActionHistoryService: PlanningViewActionHistoryService,
    private _eventHandlerService: EventHandlerService,
    public _loadingAnimationsService: LoadingAnimationsService,
    private _userConfigService: UserConfigService,
    public datepipe: DatePipe,
    private _utilService: UtilsService,
    private _cubeInfoService: CubeInfoService
  ) {

    // get the incoming options
    let selectionViewOptions: SelectionViewOptions = <SelectionViewOptions>this._router.getCurrentNavigation().extras.state;

    // set selected options
    this.selectedOptions = {
      selectionViewOptions: selectionViewOptions,
      planningViewOptions: {
        // set defaults
        channel: ChannelType.Store,
        isChannelSum: false
      }
    }

    // if incoming options not found redirect to home
    if (selectionViewOptions == undefined || selectionViewOptions == null || !this._router.lastSuccessfulNavigation) {
      this._router.navigateByUrl("");
      return;
    }

    this._userConfigService.userConfigSubject.subscribe((config: UserConfig) => {
      this.userConfig = config;
      if (this.userConfig) {

        if (this.userConfig.planningViewLayoutSettings && this._utilsService.isNotNullOrUndefined(this.userConfig.planningViewLayoutSettings.showTrickleDownDialog)) {
          this.showTrickleDownConfirmationDialog = this.userConfig.planningViewLayoutSettings.showTrickleDownDialog;
        }

        if (this.userConfig.planningViewLayoutSettings) {
          if (this.userConfig.planningViewLayoutSettings.summaryViewSettings) {
            this.summaryViewSelection = this.userConfig.planningViewLayoutSettings.summaryViewSettings;
          }

          if (this.userConfig.planningViewLayoutSettings.graphSettings) {
            this.isChartPinned = this.userConfig.planningViewLayoutSettings.graphSettings.isGraphPinned;
            this.showAllLegends = this.userConfig.planningViewLayoutSettings.graphSettings.isShowAllEnabled;
          }

          if (this.userConfig.planningViewLayoutSettings.toolbarSettings) {
            this.seasonTableDebugMode = this.userConfig.planningViewLayoutSettings.toolbarSettings.areRawValuesEnabled;
            this.isPlanningViewGraphVisible = this.userConfig.planningViewLayoutSettings.toolbarSettings.isGraphEnabled;
            //this.selectedViewSeasonsTablesList = this.userConfig.planningViewLayoutSettings.toolbarSettings.visibleSeasons;
            this.selectedViewKpis = this.userConfig.planningViewLayoutSettings.toolbarSettings.visibleKPIs;
          }

          // season visibility changed
          if (this.userConfig.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons && this.previouslySelectedViewSeasonsTablesList) {
            // check if the visible seasons and the seasons previously selected are the same
            if (
              JSON.stringify(this.userConfig.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons.sort()) != JSON.stringify(this.previouslySelectedViewSeasonsTablesList.map(x => x.seasonDisplayName).sort())) {
              if (this.isDepartmentLevelSeasonTablesRequested) {
                this.refreshDepartmentTotalTable();
              }
              if (this.isParentLevelSeasonTablesRequested) {
                this.refreshParentTotalTable();
              }
              if (this.isReadonlyDepartmentSeasonTablesRequested) {
                this.refreshReadonlyDepartmentTotalTable();
              }
              if (this.isAggregatedDepartmentSeasonTablesRequested) {
                this.refreshAggregatedDepartmentTotalTable();
              }
              this.refreshGraphData();
            }
          }

        }
      }

    });

    // set intial copy
    this.availableViewKpiListInitial = this.availableViewKpiList;

    // set default date values
    var selectedDateTime = new Date(); 
    this.selectedOptions.planningViewOptions.viewDate = new Date(selectedDateTime.getFullYear(), selectedDateTime.getMonth(), selectedDateTime.getDate(), selectedDateTime.getHours(), selectedDateTime.getMinutes());
    this.selectedDate = new Date(selectedDateTime.getFullYear(), selectedDateTime.getMonth(), selectedDateTime.getDate(), selectedDateTime.getHours(), selectedDateTime.getMinutes());
    this.selectedOptions.planningViewOptions.weekNumber = this._utilsService.getWeekNumber(true, null);
    this.selectedOptions.planningViewOptions.periodWeeks = this._utilsService.getWeeksForPeriod(null);

    this.selectedOptions.planningViewOptions.areOldSeasonsExcluded = false;

    // assign merge button status
    this.isMergeButtonDisabled = this.selectedOptions.selectionViewOptions.copy.isMerged;

    // disable the publish button for fake copies and if not merged
    if (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
      if (this.selectedOptions.selectionViewOptions.structureType.name == 'Section-Department')
        this.isEnabledForAnaplan = (<Section>this.selectedOptions.selectionViewOptions.structure).isEnabledForAnaplan;
      if ((<AssortmentCopy>this.selectedOptions.selectionViewOptions.copy).assortmentCopyId == 0) {
        this.isPublishButtonDisabled = true;
      }
      else if ((<AssortmentCopy>this.selectedOptions.selectionViewOptions.copy).assortmentCopyId > 0 && this._utilsService.isNullOrEmpty(this.selectedOptions.selectionViewOptions.copy.modifiedBy)) {
        this.isPublishButtonDisabled = true;
      }
    }

    // load feature flags	
    this.loadFeatureFlags();

    // load data on page
    this.fetchPlanningViewData();

    // load chart
    this.initChart();

    //Remove omni takt from kpi list for COS

    if (this.selectedOptions.selectionViewOptions.brand.id == BrandType.COS && this.isSalesView) {
      this.availableViewKpiList = this.availableViewKpiList.filter(x => !x.startsWith('Omni Takt/Goal'));
      this.selectedViewKpis = this.selectedViewKpis.filter(x => !x.startsWith('Omni Takt/Goal'));

      // keep the omni channel disabled for COS Sales view
      this.enableOmniChannel = false;
    }

    if (this.isSalesView) {
      this.availableViewKpiList = this.availableViewKpiList.filter(x => !(x.startsWith('Stock % SalesNet52') || x.startsWith('Include Sales System Goals')));
      this.selectedViewKpis = this.selectedViewKpis.filter(x => !x.startsWith('Stock % SalesNet52'));
    }

    if (this.isAssortmentView) {
      this.selectedViewKpis = this.selectedViewKpis.filter(x => !x.startsWith('Include Sales System Goals'));
    }

    // reset action history
    this._planningViewActionHistoryService.clearHistory();

    if (!this._eventHandlerService.keyDownHandlerAttached) {
      this.keydownHandler = this.keydownHandler.bind(this);
      this._eventHandlerService.keyDownHandlerAttached = true;
      document.addEventListener("keydown", this.keydownHandler);
    }

  }

  ngOnInit(): void {
    this._utilsService.adjustScreenDimensions();
  }

  reloadSite() {
    window.location.href = "/";
  }

  private loadFeatureFlags() {

    // fetch the required feature flags	
    this._featureFlagService.appConfigDataSubject.subscribe(
      () => {

        let featureToggle = null;

        featureToggle = this._featureFlagService.getFeatureFlagByKey(Constants.FEATURE_FLAG_PLANNING_VIEW_DATEPICKER);
        if (this._utilsService.isNotNullOrUndefined(featureToggle)) {
          this.showDatepicker = featureToggle.isEnabled;
        }

      }
    );
  }

  getMergeAndPublishToolTipForAssortment() {
    if (this.isEnabledForAnaplan)
      return Constants.AnplanEnabledSectionErrorMessage;

    return null;
  }

  processQueryStringParams() {
    let queryParams = this._route.snapshot.queryParams;

    let channel = queryParams["channel"];

    this.selectedOptions.planningViewOptions.channel = parseInt(channel);

    this.changeChannel();

    let excludeOld = queryParams["excludeOld"];

    if (excludeOld == "1") {
      this.selectedOptions.planningViewOptions.areOldSeasonsExcluded = true;
    }

    let isChannelSum = queryParams["isChannelSum"];
    if (isChannelSum == "1") {
      this.selectedOptions.planningViewOptions.isChannelSum = true;
    }

    //if channel sum is true, we dont need to consider about the planning market and regions
    if (!this.selectedOptions.planningViewOptions.isChannelSum) {

      let marketId = queryParams["marketId2"];

      let regionOptions = (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT) ? this.assortmentRegionOptions : (this.salesRegionOption ? [this.salesRegionOption] : []);
      let autoSelectedRegion = _.find(regionOptions, regionOption => {
        return regionOption.marketIntegrationKey == parseInt(marketId);
      });

      if (this._utilsService.isNotNullOrUndefined(autoSelectedRegion)) {
        this.selectedRegion = autoSelectedRegion;
        this.setSelectedMarket(autoSelectedRegion);
      }

      let planningMarketOptions = (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT) ? this.assortmentPlanningMarketOptions : this.salesPlanningMarketOptions;
      let autoSelectedPm = _.find(planningMarketOptions, pmOption => {
        return pmOption.marketIntegrationKey == parseInt(marketId);
      });

      if (this._utilsService.isNotNullOrUndefined(autoSelectedPm) && (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT)) {
        this.selectedPlanningMarket = autoSelectedPm;
        this.setSelectedMarket(autoSelectedPm);
      }
      //if its sales then, the valuw would be available either sales planning markets or sales regions
      else if (this._utilsService.isNotNullOrUndefined(autoSelectedPm) && (this.selectedOptions.selectionViewOptions.organization.name == Constants.SALES)) {
        this.selectedPm = autoSelectedPm;
        this.setSelectedMarket(autoSelectedPm);
      }
      else if (this._utilsService.isNotNullOrUndefined(autoSelectedRegion) && (this.selectedOptions.selectionViewOptions.organization.name == Constants.SALES)) {
        this.selectedPm = autoSelectedRegion;
        this.setSelectedMarket(autoSelectedRegion);
      }
    }

    let nodeType = queryParams["nodeType"];
    let nodeId = queryParams["nodeId"];

    this.isNodeFound = false;

    let currentNode = this.structureTreeData.data[0];

    this.treeControl.expand(currentNode);
    this.selectNode(currentNode, nodeType, parseInt(nodeId));

    this.getPlanningViewData();

    this._router.navigate(['.'], { relativeTo: this._route, queryParams: {} });
  }

  validateQueryStringParams() {
    let queryParams = this._route.snapshot.queryParams;

    let brandId = queryParams["brand"];

    // check if query params exist
    if (brandId == null) {
      return false;
    }

    let excludeOld = queryParams["excludeOld"];

    if (excludeOld == null) {
      return null;
    }

    if (parseInt(excludeOld) != 0 && parseInt(excludeOld) != 1) {
      return false;
    }

    let isChannelSum = queryParams["isChannelSum"];

    if (parseInt(isChannelSum) != 0 && parseInt(isChannelSum) != 1) {
      return false;
    }

    if (!(this._utilsService.isNotNullOrUndefined(isChannelSum) && parseInt(isChannelSum) == 1)) {
      // either region or pm has to exist

      let marketId = queryParams["marketId2"];

      if (this._utilsService.isNullOrUndefined(marketId)) {
        return false;
      }


      let regionOptions = (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT) ? this.assortmentRegionOptions : (this.salesRegionOption ? [this.salesRegionOption] : []);
      let autoSelectedRegion = _.find(regionOptions, regionOption => {
        return regionOption.marketIntegrationKey == parseInt(marketId);
      });

      let planningMarketOptions = (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT) ? this.assortmentPlanningMarketOptions : this.salesPlanningMarketOptions;
      let autoSelectedPm = _.find(planningMarketOptions, pmOption => {
        return pmOption.marketIntegrationKey == parseInt(marketId);
      });

      if (this._utilsService.isNullOrUndefined(autoSelectedRegion) && this._utilsService.isNullOrUndefined(autoSelectedPm)) {
        return false;
      }


    }

    let nodeType = queryParams["nodeType"];
    let nodeId = queryParams["nodeId"];

    // check if nodeType and nodeId exist in the querystring params
    if (nodeType == null || nodeId == null) {
      return false;
    }

    return true;
  }

  configureAssortmentSideNav() {
    // set side nav options as per backend data for assortment
    this.assortmentResponseData.treeData = this._addParentInfo(this.assortmentResponseData.treeData);
    this.assortmentResponseData.treeData = this.updateTreeNodeNames(this.assortmentResponseData.treeData);
    this.structureTreeData.data = this.assortmentResponseData.treeData;
    this.treeControl.dataNodes = this.assortmentResponseData.treeData;
    let currentNode = this.structureTreeData.data[0];
    this.treeControl.expand(currentNode);
    this.assortmentRegionOptions = this.assortmentResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegion);
    this.assortmentPlanningMarketOptions = this.assortmentResponseData.markets.filter(x => x.marketType == MarketType.PlanningMarket);
  }

  configureSalesSideNav() {
    // set side nav options as per backend data for sales
    this.salesResponseData.treeData = this._addParentInfo(this.salesResponseData.treeData);
    this.salesResponseData.treeData = this.updateTreeNodeNames(this.salesResponseData.treeData);
    this.structureTreeData.data = this.salesResponseData.treeData;
    this.treeControl.dataNodes = this.salesResponseData.treeData;
    let currentNode = this.structureTreeData.data[0];
    this.treeControl.expand(currentNode);
    let regionOption = this.salesResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegion);
    this.salesRegionOption = regionOption && regionOption.length == 1 ? regionOption[0] : null;
    this.salesPlanningMarketOptions = this.salesResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegionPlanningMarket || x.marketType == MarketType.PlanningMarket);

    // fetch the required feature flags	
    this._featureFlagService.appConfigDataSubject.subscribe(
      () => {

        let featureToggle = null;

        featureToggle = this._featureFlagService.getFeatureFlagByKey(Constants.FEATURE_FLAG_PLANNING_VIEW_DATEPICKER);
        if (this._utilsService.isNotNullOrUndefined(featureToggle)) {
          this.showDatepicker = featureToggle.isEnabled;
        }

      }
    );
  }

  filterChannelForSales() {

    // check and enable store or online channels

    if (this.isSalesView) {

      // only for sales 
      var storePms = this.salesResponseData.markets.filter(x => x.channel == ChannelType.Store);
      if (storePms != undefined && storePms.length != 0) {
        this.enableStoreChannel = true;
        this.selectedOptions.planningViewOptions.channel = ChannelType.Store;
      }
      var onlinePms = this.salesResponseData.markets.filter(x => x.channel == ChannelType.Online);
      if (onlinePms != undefined && onlinePms.length != 0) {
        this.enableOnlineChannel = true;
        this.selectedOptions.planningViewOptions.channel = ChannelType.Online;
      }
    }
  }


  async fetchPlanningViewData() {

    // load data on page load
    if (this.selectedOptions.selectionViewOptions.organization.name == Constants.ASSORTMENT) {

      // set boolean
      this.isAssortmentView = true;

      // enable both store and online channels 
      this.enableStoreChannel = true;
      this.enableOnlineChannel = true;

      // create a request object to fetch data
      let request: AssortmentPlanningViewRequest = {
        "brandId": this.selectedOptions.selectionViewOptions.brand.id,
        "structureId": this.selectedOptions.selectionViewOptions.structure.id,
        "structureTypeId": this.selectedOptions.selectionViewOptions.structureType.id,
        "assortmentCopyId": (<AssortmentCopy>this.selectedOptions.selectionViewOptions.copy).assortmentCopyId,
        "isMain": this.selectedOptions.selectionViewOptions.copy.isMain
      };

      this.assortmentResponseData = await this._planningViewService.getAssortmentViewData(request).toPromise();

      // data response
      this.pageState = PageState.Loaded;

      // configure side nav for assortment
      this.configureAssortmentSideNav();

      // set default channel to store
      this.selectedOptions.planningViewOptions.channel = ChannelType.Store;

      // check for query string params
      if (this._route.snapshot.queryParams['brand'] && this._route.snapshot.queryParams["channel"]) {

        let channel = this._route.snapshot.queryParams["channel"];

        if (channel == null) {
          this._router.navigateByUrl("/selection-view");
        }

        if (parseInt(channel) != ChannelType.Store && parseInt(channel) != ChannelType.Online && parseInt(channel) != ChannelType.Omni) {
          this._router.navigateByUrl("/selection-view");
        }
        this.selectedOptions.planningViewOptions.channel = this._utilsService.enumFromValue(parseInt(channel), ChannelType);

        this.changeChannel();

        if (this.validateQueryStringParams()) {
          this.processQueryStringParams();
        }
        else {
          this._router.navigateByUrl("/selection-view");
        }
      }
      else {
        // trigger channel change event
        this.changeChannel();
      }


    }
    else if (this.selectedOptions.selectionViewOptions.organization.name == Constants.SALES) {

      // set boolean
      this.isSalesView = true;

      await this.getCopyLockStatus();
      await this.getCopyTrickleDownFailedStatus();
      await this.getStashStatusByCopy();

      // create api request object
      let request: SalesPlanningViewRequest = {
        salesCopyId: (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).salesCopyId,
        structureTypeId: this.selectedOptions.selectionViewOptions.structureType.id,
        market: this.selectedOptions.selectionViewOptions.market
      }
      // subscribe to api and fetch data
      this.salesResponseData = await this._planningViewService.getSalesViewData(request).toPromise();

      // process data response
      this.pageState = PageState.Loaded;

      // configure side nav for sales
      this.configureSalesSideNav();

      // to check and enable store or online channels
      this.filterChannelForSales();

      // check for query string params
      if (this._route.snapshot.queryParams['brand'] && this._route.snapshot.queryParams["channel"]) {

        let channel = this._route.snapshot.queryParams["channel"];

        if (channel == null) {
          this._router.navigateByUrl("/selection-view");
        }

        if (parseInt(channel) != ChannelType.Store && parseInt(channel) != ChannelType.Online && parseInt(channel) != ChannelType.Omni) {
          this._router.navigateByUrl("/selection-view");
        }
        this.selectedOptions.planningViewOptions.channel = this._utilsService.enumFromValue(parseInt(channel), ChannelType);

        this.changeChannel();

        if (this.validateQueryStringParams()) {
          this.processQueryStringParams();
        }
        else {
          this._router.navigateByUrl("/selection-view");
        }
      }
      else {
        // trigger channel change event
        this.changeChannel();
      }
    }
  }

  private _addParentInfo(treeData, parent = null) {
    treeData.forEach(item => {
      if (parent !== null) {
        item.parent = {
          name: parent.name,
          typeId: parent.typeId,
          typeName: parent.typeName,
          code: parent.code
        };
      }
      else {
        item.parent = null;
      }
      if (item.children) {
        this._addParentInfo(item.children, item);
      }
    });

    return treeData;
  }

  private updateTreeNodeNames(treeData) {
    treeData.forEach(node => {
      node.name = (node.code + " - ").replace("Total - ", "") + node.name;
      node.id = this._utilsService.generateRandomIdentifier(5);
      if (node.children) {
        this.updateTreeNodeNames(node.children);
      }
    });

    return treeData;
  }

  getMarketName(): string {
    if (this.userConfig?.planningViewOptions?.market) {
      switch (this.userConfig.planningViewOptions.market.marketType) {
        case MarketType.OmniRegion: return 'O';
        case MarketType.PlanningRegion: return 'R';
        case MarketType.PlanningMarket: return 'PM';
        case MarketType.PlanningRegionPlanningMarket: return 'PM';
      }
    }
    return null;
  }

  // #region sidenav and planning view page click methods
  selectNode(rootNode, nodeType, nodeId) {
    if (rootNode.typeName == nodeType && rootNode.typeId == nodeId) {
      this.treeNodeclick(rootNode);
      this.isNodeFound = true;
    }

    let childNodes = rootNode.children;

    if (!this.isNodeFound) {
      for (let i = 0; i < childNodes.length; i++) {
        if (childNodes[i].typeName == nodeType && childNodes[i].typeId == nodeId) {
          this.treeNodeclick(childNodes[i]);
          this.isNodeFound = true;
          break;
        }

        if (!this.isNodeFound && childNodes[i].children != null) {
          this.treeControl.expand(childNodes[i]);
          this.selectNode(childNodes[i], nodeType, nodeId);
          if (!this.isNodeFound) {
            this.treeControl.collapse(childNodes[i]);
          }
        }
      }
    }


  }


  setSelectedMarket(market: Market) {

    this.selectedOptions.planningViewOptions.market = market;

    if (market.marketType == MarketType.PlanningRegionPlanningMarket || market.marketType == MarketType.PlanningMarket) {
      this.selectedRegion = null;
      this.selectedPercentPlanningMarket = null;
    }
    else if (market.marketType == MarketType.PlanningRegion) {
      this.selectedPlanningMarket = null;
      this.selectedPercentPlanningMarket = null;
    }
    else if (market.marketType == MarketType.PercentPlanningMarket) {
      this.selectedRegion = null;
      this.selectedPlanningMarket = null;
    }
  }

  changeChannel() {

    // on change event handler for channel toggle buttons


    if (this.isAssortmentView) {

      let omniRegions = this.assortmentResponseData.markets.filter(x => x.marketType == MarketType.OmniRegion);
      let regions = this.assortmentResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegion);
      let pMarkets = this.assortmentResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegionPlanningMarket || x.marketType == MarketType.PlanningMarket);

      // for assortment only

      if (this.selectedOptions.planningViewOptions.channel == ChannelType.Omni) {

        // If Omni
        this.assortmentRegionOptions = _.filter(omniRegions, (region: Market) => {
          return region.channel == ChannelType.Omni
        });


        this.assortmentPlanningMarketOptions = _.filter(pMarkets, (pm: Market) => {
          return pm.channel == ChannelType.Omni
        });

        //reset channel sum to false //todo if needed later
        this.selectedOptions.planningViewOptions.isChannelSum = false;

      }
      else if (this.selectedOptions.planningViewOptions.channel == ChannelType.Store) {

        // If Store, select only store regions from the list of regions        

        this.assortmentRegionOptions = _.filter(regions, (region: Market) => {
          return region.channel == ChannelType.Store;
        });

        this.assortmentPlanningMarketOptions = _.filter(pMarkets, (pm: Market) => {
          return pm.channel == ChannelType.Store;
        });
      }
      else if (this.selectedOptions.planningViewOptions.channel == ChannelType.Online) {

        // If Online, select only online regions from the list of regions

        this.assortmentRegionOptions = _.filter(regions, (region: Market) => {
          return region.channel == ChannelType.Online;
        });

        this.assortmentPlanningMarketOptions = _.filter(pMarkets, (pm: Market) => {
          return pm.channel == ChannelType.Online;
        });
      }

      this.selectedRegion = null;
      this.selectedPlanningMarket = null;
      this.selectedPercentPlanningMarket = null;
    }

    if (this.isSalesView) {

      if (this.selectedOptions.planningViewOptions.channel == ChannelType.Omni) {

        // If Omni
        let oregion = this.salesResponseData.markets.filter(x => x.marketType == MarketType.OmniRegion);
        this.salesRegionOption = oregion && oregion.length != 0 ? oregion[0] : null;


        this.salesPlanningMarketOptions = _.filter(this.salesResponseData.markets, (mkt: Market) => {
          return mkt.channel == ChannelType.Omni;
        });

        //reset channel sum to false //todo if needed later
        this.selectedOptions.planningViewOptions.isChannelSum = false;

      }
      else if (this.selectedOptions.planningViewOptions.channel == ChannelType.Store) {

        // If Store, select only store markets from the list of markets
        let region = this.salesResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegion && x.channel == ChannelType.Store);
        this.salesRegionOption = region && region.length != 0 ? region[0] : null;
        this.salesPlanningMarketOptions = _.filter(this.salesResponseData.markets, (mkt: Market) => {
          return mkt.marketType != MarketType.PlanningRegion && mkt.channel == ChannelType.Store;
        });
      }
      else if (this.selectedOptions.planningViewOptions.channel == ChannelType.Online) {

        // If Online, select only online markets from the list of markets
        let region = this.salesResponseData.markets.filter(x => x.marketType == MarketType.PlanningRegion && x.channel == ChannelType.Online);
        this.salesRegionOption = region && region.length != 0 ? region[0] : null;
        this.salesPlanningMarketOptions = _.filter(this.salesResponseData.markets, (pm: Market) => {
          return pm.marketType != MarketType.PlanningRegion && pm.channel == ChannelType.Online;
        });
      }

      this.selectedPm = null;
    }
  }

  treeNodeclick(node) {


    // click handler for structure tree
    this.selectedOptions.planningViewOptions.structureType = this._utilsService.enumFromValue(node.typeName, StructureTypes);
    this.selectedOptions.planningViewOptions.structureId = node.typeId;

    //Below parentStructure is only when Garment Group is selected (Same garment group is tagged to more than 1 Customer Group)
    if (node.parent) {
      this.selectedOptions.planningViewOptions.parentStructureType = node.parent.typeName;
      this.selectedOptions.planningViewOptions.parentStructureId = node.parent.typeId;
    }
    else {
      this.selectedOptions.planningViewOptions.parentStructureType = null;
      this.selectedOptions.planningViewOptions.parentStructureId = null;
    }

    // fetch children

    let actualNode = this.findNodeInResponseData(node)
    this.childNodes = this._utilsService.isNotNullOrUndefined(actualNode) && actualNode.children ? actualNode.children : null;

    this.activeNode = node;
  }

  findNodeInResponseData(node) {
    let responseTreeData = this.selectedOptions.selectionViewOptions.organization.name == OrganizationType.Assortment ? this.assortmentResponseData.treeData : this.salesResponseData.treeData;
    let rootNode = responseTreeData[0];

    return this.searchNode(rootNode, node);
  }

  searchNode(node, searchNode) {
    if (node.id == searchNode.id) {
      return node;
    }
    else {
      if (node.children && node.children.length != 0) {
        let children = node.children;

        for (let i = 0; i < children.length; i++) {
          let node = this.searchNode(children[i], searchNode);
          if (this._utilsService.isNotNullOrUndefined(node)) {
            return node;
          }
        }
      }
    }

    return null
  }

  translateTypeName(nodeTypeName: StructureTypes) {
    switch (nodeTypeName) {
      case StructureTypes.Brand:
        return "BR";
      case StructureTypes.CustomerGroup:
        return "CG";
      case StructureTypes.Department:
        return "DT";
      case StructureTypes.Division:
        return "DV";
      case StructureTypes.GarmentGroup:
        return "GG";
      case StructureTypes.Section:
        return "SC";
      case StructureTypes.Total:
        return "T";
    }
  }

  onSideNavOpened() {
    this.sidenavExpanded = true;
  }

  onSideNavClosed() {
    this.sidenavExpanded = false;
  }

  selectedDateChange(event: MatDatepickerInputEvent<Date>) {
    var currentDateSelection = event.value; 
    this.selectedDate = new Date(currentDateSelection.getFullYear(), currentDateSelection.getMonth(), currentDateSelection.getDate(), new Date().getHours(), new Date().getMinutes());
    this.selectedOptions.planningViewOptions.viewDate = new Date(currentDateSelection.getFullYear(), currentDateSelection.getMonth(), currentDateSelection.getDate(), new Date().getHours(), new Date().getMinutes());
    this.selectedOptions.planningViewOptions.weekNumber = this._utilsService.getWeekNumber(true, this.selectedOptions.planningViewOptions.viewDate);
    this.selectedOptions.planningViewOptions.periodWeeks = this._utilsService.getWeeksForPeriod(this.selectedOptions.planningViewOptions.viewDate);


    this.getPlanningViewData();
  }

  dateChange(event) {

    // on change handler for date selector	
    this._loadingAnimationService.enableTopNavAnimation();
    this.selectedDate = event.value;
    this.selectedOptions.planningViewOptions.viewDate = event.value;
    this.selectedOptions.planningViewOptions.weekNumber = this._utilsService.getWeekNumber(true, this.selectedOptions.planningViewOptions.viewDate);
    this.selectedOptions.planningViewOptions.periodWeeks = this._utilsService.getWeeksForPeriod(this.selectedOptions.planningViewOptions.viewDate);

    setTimeout(() => {
      // call render after a 100 ms pause	
      this.planningViewTableRenderComplete = false;
      this.renderPlanningTables();
    }, 100)
  }

  // #endregion sidenav and planning view page click methods

  // #region go button clicks
  // Click handler for Go button
  getPlanningViewData() {

    if (this.stashedData) {
      if (confirm("The copy has not been trickled down. Are you sure you want to open a new plan?")) {
        this.clearHistoryAndGetPlanningViewData();
      }
    }
    // check if there are unsaved changes
    else if (
      this.preventUserFromNavigating() && confirm("The copy has unsaved changes. Are you sure you want to open a new plan?")
      ||
      !this.preventUserFromNavigating()
    ) {
      this.clearHistoryAndGetPlanningViewData();
    }

  }
  clearHistoryAndGetPlanningViewData() {
    // reset action history
    this._planningViewActionHistoryService.clearHistory();

    // set current date
    this.selectedOptions.planningViewOptions.viewDate = this.selectedDate;

    // set current week and period weeks
    this.selectedOptions.planningViewOptions.weekNumber = this._utilsService.getWeekNumber(true, null);
    this.selectedOptions.planningViewOptions.periodWeeks = this._utilsService.getWeeksForPeriod(null);

    // validate side nav selections
    if (this.validateSideNav()) {

      this.planningViewTableRenderComplete = false;

      this.selectedOptions.planningViewOptions.activeNode = this.activeNode;

      // update the existing local storage
      this._userConfigService.setPlanningViewOptions(this.selectedOptions.planningViewOptions);

      // if valid
      this._loadingAnimationService.enableTopNavAnimation();

      // collapse side nav
      this.sidenavExpanded = false;
      this.planningViewTablesInitialized = true;

      // restart loading
      this.planningViewTablesLoaded = false;

      setTimeout(() => {

        this.planningViewTablesLoaded = true;
        this.sidenavExpanded = false;

        // load planning tables
        this.renderPlanningTables();

      }, 1);
    }
  }
  validateSideNav() {

    // validate channel
    if (this.selectedOptions.planningViewOptions.channel == null) {
      this._dialogBoxService.showMessage("Please select a Channel");
      return false;
    }

    if (this.isAssortmentView) {
      //validate channel summary check. if true skip validation for region and pms. else check
      if (!this.selectedOptions.planningViewOptions.isChannelSum) {
        if (this.selectedRegion == null && this.selectedPlanningMarket == null && this.selectedPercentPlanningMarket == null) {
          let message = "Please select a Region/PM";
          this._dialogBoxService.showMessage(message)
          return false;
        }
      }
    }
    else if (this.isSalesView) {
      if (this.selectedPm == null) {
        this._dialogBoxService.showMessage("Please select a PM");
        return false;
      }
    }

    if (this.selectedOptions.planningViewOptions.structureId == undefined || this.selectedOptions.planningViewOptions.structureId == null) {
      this._dialogBoxService.showMessage("Please select a node from the tree");
      return false;
    }

    return true;
  }

  // function to fetch and render tables based on dept, parent or read only modes
  async renderPlanningTables() {

    // set all tables to loading state
    this.totalSeasonTableRendered = false;
    this.previousSeasonTableRendered = false;
    this.actualSeasonTableRendered = false;
    this.comingSeasonTableRendered = false;
    this.futureSeasonTableRendered = false;

    this.totalViewRequestData = null;

    // set all tables to loading state for the aggregated tables
    this.aggregatedTotalSeasonTableRendered = false;
    this.aggregatedPreviousSeasonTableRendered = false;
    this.aggregatedActualSeasonTableRendered = false;
    this.aggregatedComingSeasonTableRendered = false;
    this.aggregatedFutureSeasonTableRendered = false;

    // clear the seasons dropdown
    this.availableViewSeasonsList = [];
    this.selectedViewSeasonsTablesList = [];

    this.isAggregatedMainViewEnabled = false;
    this.totalViewRequestsEnabled = false;

    this.stashedDataExists = false;
    this.stashedData = null;
    this.stashStructureType = null;
    this.isSaveButtonDisabled = false;

    // create a request object for the season info api. This is needed before actual data is fetched for individual seasons
    let seasonInfoRequest: SeasonInfoRequest =
    {
      excludeOldSeasons: this.selectedOptions.planningViewOptions.areOldSeasonsExcluded,
      date: this.selectedDate
    }

    // get the seasons for the current date
    this.seasons = await this._seasonDataService.getSeasonInfo(seasonInfoRequest);

    // check if total view is requested
    // if organization is sales and level selected is total
    if (this.selectedOptions.selectionViewOptions.organization.name == OrganizationType.Sales &&
      this.selectedOptions.planningViewOptions.structureType == StructureTypes.Total) {
      this.generateRequestLoading = true;
      await this.fetchTotalViewRequestData();
    }

    // check which type of tables are requested
    this.isDepartmentLevelSeasonTablesRequested = this.selectedOptions.planningViewOptions.structureType == StructureTypes.Department &&
      this.selectedOptions.planningViewOptions.channel != ChannelType.Omni && !this.selectedOptions.planningViewOptions.isChannelSum;

    this.isParentLevelSeasonTablesRequested = !(this.selectedOptions.planningViewOptions.structureType == StructureTypes.Department)

    //it would be true if channel sum is enabled or omni - now we have implemented only for dep level. we have to manage both later.
    this.isReadonlyDepartmentSeasonTablesRequested = ((this.selectedOptions.planningViewOptions.structureType == StructureTypes.Department &&
      this.selectedOptions.planningViewOptions.channel == ChannelType.Omni) ||
      (this.selectedOptions.planningViewOptions.structureType == StructureTypes.Department &&
        this.selectedOptions.planningViewOptions.isChannelSum)
    )

    //check aggregated main needed or not and draw graph here

    this.isAggregatedDepartmentSeasonTablesRequested = (this.selectedOptions.selectionViewOptions.structureType.name != 'Section-Department' && this.isAssortmentView) ? true : false;

    // If dept level
    if (this.isDepartmentLevelSeasonTablesRequested) {
      // Render all season tables first and then process total table and graph. Async behaviour
      await Promise.all(this.seasons.map(async (season: SeasonInfo) => {
        // and get data for each season
        await this.renderDepartmentSeasonTable(season, this.selectedOptions.planningViewOptions.viewDate);
      }));
      // once all season data arrives, process total data
      await this.renderDepartmentTotalTable();
      // draw graph
      this.drawGraph();
    }
    // if read only 
    else if (this.isReadonlyDepartmentSeasonTablesRequested) {

      // Render all season tables first and then process total table and graph. Sync behaviour
      for (let k = 0; k < this.seasons.length; k++) {
        await this.renderReadonlyDepartmentSeasonTable(this.seasons[k], this.selectedOptions.planningViewOptions.viewDate);
      }
      this.totalSeasonTableRendered = true;

      //load parent total season table
      await this.renderReadonlyDepartmentTotalTable();

      // draw graph
      this.drawGraph();
    }
    // if parent level
    else if (this.isParentLevelSeasonTablesRequested) {

      if (this.totalViewRequestsEnabled && this.totalViewRequestData.lastProcessedResponse) {
        for (let k = 0; k < this.seasons.length; k++) {
          await this.renderParentSeasonTable(this.seasons[k], this.selectedOptions.planningViewOptions.viewDate);
          await new Promise(resolve => setTimeout(resolve, 1));
        }
        await new Promise(resolve => setTimeout(resolve, 10));

        this.totalSeasonTableRendered = true;
        // once all season data arrives, process total data
        await this.renderParentTotalTable();
        // draw graph
        this.drawGraph();
      }
      else if (!this.totalViewRequestsEnabled) {
        // Render all season tables first and then process total table and graph. Async behaviour
        await Promise.all(this.seasons.map(async (season: SeasonInfo) => {
          // and get data for each season
          await this.renderParentSeasonTable(season, this.selectedOptions.planningViewOptions.viewDate);
        }));

        this.totalSeasonTableRendered = true;
        // once all season data arrives, process total data
        await this.renderParentTotalTable();
        // draw graph
        this.drawGraph();
      }
    }
     
    if (this.isAggregatedDepartmentSeasonTablesRequested) {
      this.isAggregatedMainViewEnabled = false;
      //if its simutation view copy then call aggregated main api as ll
      for (let agg = 0; agg < this.seasons.length; agg++) {
        await this.renderAggregatedMainsSeasonTable(this.seasons[agg], this.selectedOptions.planningViewOptions.viewDate);
      }

      //load aggregated total season table
      await this.renderAggreatedDepartmentTotalTable();

      // draw graph
      this.drawGraph();
    }



    if (
      !this.isAssortmentView &&
      !(this.selectedOptions.selectionViewOptions.copy.isMain && !(<SalesCopy>this.selectedOptions.selectionViewOptions.copy).isPRCopy) &&
      !this.isReadonlyDepartmentSeasonTablesRequested &&
      ((this.totalViewRequestsEnabled && this.totalViewRequestData.lastProcessedResponse) || !this.totalViewRequestsEnabled)
    ) {
      this.renderSalesAggregatedPmData();
    }

    await this.getCopyLockStatus();
    await this.getCopyTrickleDownFailedStatus();
    await this.getStashStatusByCopy();
    // check if there is stashed data saved
    await this.checkAndProcessStashedData();

    if (this.isAssortmentView) {
      this.selectedViewKpis = this.selectedViewKpis.filter(x => !x.startsWith('Include Sales System Goals'));
      this.assortmentsalestystemgoalresults = {};
      this.areSalesSystemGoalsIncludedForAssortment = false;
      this.hasSalesSystemGoalInAssortmentLoaded = false;
    }

    await this.setLatestCubeRefreshTime();
    this._loadingAnimationService.disableTopNavAnimation();

    this.planningViewTableRenderComplete = true;

    this.totalPlanningViewResponseLoading = false;

    if(this.isSalesView && this.selectedOptions.planningViewOptions.structureType != StructureTypes.Department && this.selectedOptions.planningViewOptions.channel == ChannelType.Online && this.departmentcheck.size != 0 ) {
      if(this._userConfigService.getUserConfig()?.IsShowDeveloperExceptionPopupEnabled) {      
        let message = "For below combinations, there exist no return plan but has saved dem del plan. Kindly clear the saved dem del plan, for the users to see valid CS% <br/>";
        this.departmentcheck.forEach((value, key) => {
          message += "Season: " + "{" + key  + "}" + " Departments: " + "{" + value + "}" + "<br/> ";
        });
        this._dialogBoxService.showMessage(message);
      }
      else{
        console.log("For some combinations, there exist no return plan but has saved dem del plan. Kindly clear the saved dem del plan, for the users to see valid CS%")
      }
    }
  }

  // #endregion go button click

  // #region Department Level Methods

  // render a dept season table
  async renderDepartmentSeasonTable(season: SeasonInfo, date: Date) {

    // fetch department level data for the current season
    let seasonDataApiResponse = await this.getDepartmentSeasonDataFromApi(season);

    let seasonTableInfo: SeasonTableInfo = null;
    let driver: CalculatorDriverInterface = null;

    // create driver based on season type and process each individually
    if (season.seasonPlanningType == SeasonPlanningType.PreviousWithOld || season.seasonPlanningType == SeasonPlanningType.Previous) {
      driver = new PreviousAndOldDepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._planningViewActionHistoryService);
      driver.initDataSet(seasonDataApiResponse, season, this.selectedOptions.planningViewOptions.viewDate);
      driver.calculate();
    }
    else {
      driver = new DepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._planningViewActionHistoryService);
      driver.initDataSet(seasonDataApiResponse, season, this.selectedOptions.planningViewOptions.viewDate);
      driver.calculate();
    }

    // create object to pass to the department season table component. Encapsulate driver within the object
    seasonTableInfo = {
      driver: driver
    }

    // set data source for the department tables and enable the rendered flags. Also store a ref to the driver
    switch (season.seasonPlanningType) {
      case SeasonPlanningType.Previous:
      case SeasonPlanningType.PreviousWithOld:
        this.previousDepartmentSeasonTableData = seasonTableInfo;
        this.previousSeasonTableRendered = true;
        this.previousSeasonTableDataDriver = driver;
        break;
      case SeasonPlanningType.Actual:
        this.actualDepartmentSeasonTableData = seasonTableInfo;
        this.actualSeasonTableRendered = true;
        this.actualSeasonTableDataDriver = driver;
        break;
      case SeasonPlanningType.Coming:
        this.comingDepartmentSeasonTableData = seasonTableInfo;
        this.comingSeasonTableRendered = true;
        this.comingSeasonTableDataDriver = driver;
        break;
      case SeasonPlanningType.Future:
        this.futureDepartmentSeasonTableData = seasonTableInfo;
        this.futureSeasonTableRendered = true;
        this.futureSeasonTableDataDriver = driver;
        break;
    }

  }
  // get data at the department level for the given season from server
  async getDepartmentSeasonDataFromApi(season: SeasonInfo): Promise<DepartmentPlanningViewDataResponse> {

    //we dont need to update view again, because it is updating in aggregated view. its its not then
    if (!this.isAggregatedMainViewEnabled)
      this.updateViewLists(season);

    let dataRequest = this.getPlanningViewDataRequest(season);

    this._appInsightsLoggingService.logEvent("Requesting Season Data", dataRequest);

    // request for data
    return this._seasonDataService.getDepartmentSeasonData(dataRequest, this.isAssortmentView);
  }
  // render total table for deparment level
  async renderDepartmentTotalTable() {

    let childDepartmentCalculationDrivers: CalculatorDriverInterface[] = [];

    childDepartmentCalculationDrivers.push(this.previousDepartmentSeasonTableData.driver);
    childDepartmentCalculationDrivers.push(this.actualDepartmentSeasonTableData.driver);
    childDepartmentCalculationDrivers.push(this.comingDepartmentSeasonTableData.driver);
    childDepartmentCalculationDrivers.push(this.futureDepartmentSeasonTableData.driver);

    // default seasonInfo
    let actualSeasonInfo: SeasonInfo = this.actualDepartmentSeasonTableData.driver.getSeasonInfo();

    let totalSeasonInfo: SeasonInfo = {
      periods: this.actualDepartmentSeasonTableData.driver.getSeasonInfo().periods,
      quarters: actualSeasonInfo.quarters,
      seasonType: null,
      seasonCodeNames: ["Total"],
      seasonPlanningType: SeasonPlanningType.Total,
      weeks: actualSeasonInfo.weeks,
      weeksWithYear: actualSeasonInfo.weeksWithYear
    }

    childDepartmentCalculationDrivers.forEach((childDepartmentCalculationDriver: CalculatorDriverInterface) => {
      childDepartmentCalculationDriver.addChangeEventListener(this.refreshDepartmentTotalTable.bind(this));
      childDepartmentCalculationDriver.addChangeEventListener(this.refreshGraphData.bind(this));
    })

    let totalDriver = new TotalDepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
    totalDriver.initTotalDataSet(childDepartmentCalculationDrivers, totalSeasonInfo, this.selectedOptions.planningViewOptions.viewDate);

    //this is the conditions for only one season selected in the seletion list
    let childDatsets = totalDriver.getChildDataSets();
    if (childDatsets.length == 1) {
      totalDriver.setDataSet(childDatsets[0]);
      totalDriver.calculateForSingleSeason();

    }
    else {
      totalDriver.calculate();
    }


    let seasonTableInfo: SeasonTableInfo = {
      driver: totalDriver
    }

    this.totalDepartmentSeasonTableData = seasonTableInfo;
    this.totalSeasonTableRendered = true;

    this.totalSeasonTableDataDriver = totalDriver;

    if (!this.isAggregatedMainViewEnabled) {
      let viewTable = {
        "seasonDisplayName": "All Seasons",
        "seasonType": SeasonPlanningType.Total,
        "seasonNumber": null
      };

      this.availableViewSeasonsList.unshift(viewTable);

      this.checkAndSelectViewSeason(viewTable)

    }
    this.planningViewRenderComplete();
  }
  // Handler to update total Table
  refreshDepartmentTotalTable() {
    this.totalDepartmentSeasonTableData.driver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
    this.planningViewRenderComplete();
  }

  // #endregion Department Level Methods

  async checkAndProcessStashedData() {

    if (!this.isAssortmentView) {
      let stashData: StashStatusData = await this._planningViewService.getStashedData(this.userConfig);

      this.stashedDataExists = stashData.isLocked;
      this.stashedData = stashData.data;
      this.stashStructureType = stashData.structureType;

      let driversList = [
        this.actualSeasonTableDataDriver,
        this.comingSeasonTableDataDriver,
        this.futureSeasonTableDataDriver,
        this.previousSeasonTableDataDriver
      ]

      if (this.stashedData) {
        // apply the stashed data to the view 
        driversList.forEach((driver: ParentCalculatorDriverInterface) => {
          if (driver != null)
            driver.setStashDataDataSet(this.stashedData);
        });
        if (this.totalSeasonTableDataDriver != null)
          (<ParentCalculatorDriverInterface>this.totalSeasonTableDataDriver).setStashDataDataSet(this.stashedData);
      }
    }

    this.unlockSeasonInputs();
  }

  async getStashStatusByCopy() {
    if (!this.isAssortmentView) {
      this.stashDataAvailableByCopy = await this._planningViewService.getStashStatusByCopy(this.userConfig);
    }
  }

  async getCopyLockStatus() {
    if (!this.isAssortmentView) {
      this.selectedOptions.selectionViewOptions.copy.isLocked = await this._planningViewService.getCopyLockStatus(this.userConfig);
    }
  }

  private async getCopyTrickleDownFailedStatus() {
    if (this.isSalesView) {
      this.salesCopyTrickleDownFailedStatus = await this._planningViewService.getCopyTrickleDownFailedStatus(this.userConfig);
      if (this.salesCopyTrickleDownFailedStatus?.trickleDownFailed) this.isSaveButtonDisabled = false; // Enable save button so user can try save + trickle down again
    }
  }

  async getSalesAggregatedPmData(seasonInfo: SeasonInfo): Promise<WeekDataItem[]> {

    if (this.totalViewRequestsEnabled) {
      let response: TotalViewRequestDataItem = _.find(this.totalViewRequestData.lastProcessedResponse.processedData.totalData, (response: TotalViewRequestDataItem) => {
        return response.seasonName == seasonInfo.seasonPlanningType;
      });
      return response.salesAggregatedData
    }
    else {
      let seasonDataRequest = this.getPlanningViewDataRequest(seasonInfo);
      return await this._seasonDataService.getSalesAggregatedPmMainsSeasonData(seasonDataRequest);
    }
  }

  //#region Sales aggregated PM data
  async renderSalesAggregatedPmData() {
    let driversList = [
      this.actualSeasonTableDataDriver,
      this.comingSeasonTableDataDriver,
      this.futureSeasonTableDataDriver,
      this.previousSeasonTableDataDriver
    ]

    await Promise.all(driversList.map(async driver => {
      // and get data for each season
      let aggregatedSalesPmData = await this.getSalesAggregatedPmData(driver.getSeasonInfo());
      driver.setSalesAggregatedPmDataSet(aggregatedSalesPmData);
    }));

    this.totalSeasonTableDataDriver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
  }

  private async setSalesSystemGoalInAssortmentDataSet(driversList: (CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface)[]): Promise<void> {
    await Promise.all(driversList.map(async driver => {
      try {
        const requestData = this.getPlanningViewDataRequest(driver.getSeasonInfo());
        if (this.assortmentsalestystemgoalresults[requestData.planningSeasonType] == null) {
          const salesSystemGoalInAssortmentData = await this._seasonDataService.getSalesSystemGoalForAssortmentData(requestData);
          this.assortmentsalestystemgoalresults[requestData.planningSeasonType] = salesSystemGoalInAssortmentData;
          driver.setSalesSystemGoalInAssortmentDataSet(salesSystemGoalInAssortmentData);
        } else {
          driver.setSalesSystemGoalInAssortmentDataSet(this.assortmentsalestystemgoalresults[requestData.planningSeasonType]);
        }
      } catch {
        this._utilService.showGenericMessage("failed to get the sale system goals. please try again", 'error-snackbar', 8000);
        this.areSalesSystemGoalsIncludedForAssortment = false;
        this.hasSalesSystemGoalInAssortmentLoaded = false;
        this.selectedViewKpis = this.selectedViewKpis.filter(x => !x.startsWith('Include Sales System Goals'));
        this._loadingAnimationService.disableTopNavAnimation();
        return;
      }

    }));
  }

  private async renderSalesSystemGoalInAssortment(): Promise<void> {
    this.hasSalesSystemGoalInAssortmentLoaded = true;
    const driversList = [
      this.actualSeasonTableDataDriver,
      this.comingSeasonTableDataDriver,
      this.futureSeasonTableDataDriver,
      this.previousSeasonTableDataDriver
    ];
    await this.setSalesSystemGoalInAssortmentDataSet(driversList);
    this.totalSeasonTableDataDriver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
  }

  private async renderAggregatedSalesSystemGoalInAssortment(): Promise<void> {
    if (!this.isAggregatedDepartmentSeasonTablesRequested) return;
    const driversList = [
      this.aggregatedActualSeasonTableDataDriver,
      this.aggregatedComingSeasonTableDataDriver,
      this.aggregatedFutureSeasonTableDataDriver,
      this.aggregatedPreviousSeasonTableDataDriver
    ];
    await this.setSalesSystemGoalInAssortmentDataSet(driversList);
    this.aggregatedTotalSeasonTableDataDriver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
  }

  // #region Aggregated Mains Level Methods 

  async renderAggregatedMainsSeasonTable(season: SeasonInfo, date: Date) {

    let aggregatedSeasonDataApiResponse = await this.getAggregatedMainsSeasonDataFromApi(season);

    let seasonTableInfo: ReadonlyDepartmentSeasonTableInfo = null;
    let aggregatedDepartmentCalculationDriver: ReadonlyDepartmentCalculationDriver = null;

    // create driver and process each individually
    aggregatedDepartmentCalculationDriver = new ReadonlyDepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService);
    aggregatedDepartmentCalculationDriver.initDataSet(aggregatedSeasonDataApiResponse, season, date, this.selectedOptions);
    seasonTableInfo = {
      driver: aggregatedDepartmentCalculationDriver
    }

    aggregatedDepartmentCalculationDriver.calculate();

    // set data source for the department tables and enable the rendered flags. Also store a ref to the driver
    switch (season.seasonPlanningType) {
      case SeasonPlanningType.Previous:
      case SeasonPlanningType.PreviousWithOld:
        this.aggregatedDepartmentPreviousSeasonTableData = seasonTableInfo;
        this.aggregatedPreviousSeasonTableRendered = true;
        this.aggregatedPreviousSeasonTableDataDriver = aggregatedDepartmentCalculationDriver;
        break;
      case SeasonPlanningType.Actual:
        this.aggregatedDepartmentActualSeasonTableData = seasonTableInfo;
        this.aggregatedActualSeasonTableRendered = true;
        this.aggregatedActualSeasonTableDataDriver = aggregatedDepartmentCalculationDriver;
        break;
      case SeasonPlanningType.Coming:
        this.aggregatedDepartmentComingSeasonTableData = seasonTableInfo;
        this.aggregatedComingSeasonTableRendered = true;
        this.aggregatedComingSeasonTableDataDriver = aggregatedDepartmentCalculationDriver;
        break;
      case SeasonPlanningType.Future:
        this.aggregatedDepartmentFutureSeasonTableData = seasonTableInfo;
        this.aggregatedFutureSeasonTableRendered = true;
        this.aggregatedFutureSeasonTableDataDriver = aggregatedDepartmentCalculationDriver;
        break;
    }


  }

  async getAggregatedMainsSeasonDataFromApi(season: SeasonInfo): Promise<ReadonlyDepartmentPlanningViewDataResponse> {

    let dataRequest = this.getPlanningViewDataRequest(season);

    this._appInsightsLoggingService.logEvent("Requesting aggreagted mains Season Data", dataRequest);

    return this._seasonDataService.getAggregatedMainsSeasonData(dataRequest);
  }

  async renderAggreatedDepartmentTotalTable() {

    let childAggregatedDepartmentCulationDrivers: ReadonlyDepartmentCalculatorDriverInterface[] = [];

    childAggregatedDepartmentCulationDrivers.push(this.aggregatedDepartmentPreviousSeasonTableData.driver);
    childAggregatedDepartmentCulationDrivers.push(this.aggregatedDepartmentActualSeasonTableData.driver);
    childAggregatedDepartmentCulationDrivers.push(this.aggregatedDepartmentComingSeasonTableData.driver);
    childAggregatedDepartmentCulationDrivers.push(this.aggregatedDepartmentFutureSeasonTableData.driver);

    // default seasonInfo
    let actualSeasonInfo: SeasonInfo = this.aggregatedDepartmentActualSeasonTableData.driver.getSeasonInfo();

    let totalSeasonInfo: SeasonInfo = {
      periods: this.aggregatedDepartmentActualSeasonTableData.driver.getSeasonInfo().periods,
      quarters: actualSeasonInfo.quarters,
      seasonType: null,
      seasonCodeNames: ["Total"],
      seasonPlanningType: SeasonPlanningType.Total,
      weeks: actualSeasonInfo.weeks,
      weeksWithYear: actualSeasonInfo.weeksWithYear
    }

    childAggregatedDepartmentCulationDrivers.forEach((childAggregatedDepartmentCulationDriver: ReadonlyDepartmentCalculatorDriverInterface) => {
      childAggregatedDepartmentCulationDriver.addChangeEventListener(this.refreshAggregatedDepartmentTotalTable.bind(this));
    })

    let aggregatedTotalDriver = new TotalReadonlyDepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
    aggregatedTotalDriver.initTotalDataSet(childAggregatedDepartmentCulationDrivers, totalSeasonInfo, this.selectedOptions.planningViewOptions.viewDate);

    //this is the conditions for only one season selected in the seletion list
    let childDatsets = aggregatedTotalDriver.getChildDataSets();
    if (childDatsets.length == 1) {
      aggregatedTotalDriver.setDataSet(childDatsets[0]);
      aggregatedTotalDriver.calculateForSingleSeason();
    }
    else {
      aggregatedTotalDriver.calculate();
    }

    let parentSeasonTableInfo: ReadonlyDepartmentSeasonTableInfo = {
      driver: aggregatedTotalDriver
    }

    this.aggregatedTotalSeasonTableDataDriver = aggregatedTotalDriver;

    this.aggregatedDepartmentTotalSeasonTableData = parentSeasonTableInfo;
    this.aggregatedTotalSeasonTableRendered = true;

    if (this.aggregatedTotalSeasonTableRendered) {
      this.refreshGraphData();
    }

    this.planningViewRenderComplete();

  }
  // Handler to update total Table
  refreshAggregatedDepartmentTotalTable() {
    this.aggregatedDepartmentTotalSeasonTableData.driver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);

    this.planningViewRenderComplete();
  }

  // #endregion Aggregated Mains Level Methods

  // #region Parent Level Methods

  // render a parent season table
  async renderParentSeasonTable(season: SeasonInfo, date: Date) {


    // fetch parent level data for the current season
    let seasonDataApiResponse = await this.getParentSeasonDataFromApi(season);
    if (seasonDataApiResponse) {
      // create driver and process each individually
      let parentCalculationDriver = new ParentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._planningViewActionHistoryService);
      let seasonTableInfo: ParentSeasonTableInfo = null;
      parentCalculationDriver.initDataSet(seasonDataApiResponse, season, date, this.selectedOptions);
      seasonTableInfo = {
        driver: parentCalculationDriver
      }
      parentCalculationDriver.calculate(); 
      
      if(season.seasonPlanningType != SeasonPlanningType.PreviousWithOld) {
        this.departmentToDisplay = parentCalculationDriver._departmentsHasDemDelButNoReturns.map(x => x.departmentIntegrationKey);
        if(this.departmentToDisplay.length > 0) {
          this.departmentcheck.set(parentCalculationDriver._title, this.departmentToDisplay);
        }
      }      
      
      // set data source for the parent tables and enable the rendered flags. Also store a ref to the driver
      switch (season.seasonPlanningType) {
        case SeasonPlanningType.Previous:
        case SeasonPlanningType.PreviousWithOld:
          this.previousParentSeasonTableData = seasonTableInfo;
          this.previousSeasonTableRendered = true;
          this.previousSeasonTableDataDriver = parentCalculationDriver;
          break;
        case SeasonPlanningType.Actual:
          this.actualParentSeasonTableData = seasonTableInfo;
          this.actualSeasonTableRendered = true;
          this.actualSeasonTableDataDriver = parentCalculationDriver;
          break;
        case SeasonPlanningType.Coming:
          this.comingParentSeasonTableData = seasonTableInfo;
          this.comingSeasonTableRendered = true;
          this.comingSeasonTableDataDriver = parentCalculationDriver;
          break;
        case SeasonPlanningType.Future:
          this.futureParentSeasonTableData = seasonTableInfo;
          this.futureSeasonTableRendered = true;
          this.futureSeasonTableDataDriver = parentCalculationDriver;
          break;
      }
    }
  }
  // get data at the parent level for the given season from server
  async getParentSeasonDataFromApi(season: SeasonInfo): Promise<ParentPlanningViewDataResponse> {

    if (!this.isAggregatedMainViewEnabled)
      this.updateViewLists(season);

    if (this.totalViewRequestsEnabled) {
      await new Promise(resolve => setTimeout(resolve, 1));
      let response: TotalViewRequestDataItem = _.find(this.totalViewRequestData.lastProcessedResponse.processedData.totalData, (response: TotalViewRequestDataItem) => {
        return response.seasonName == season.seasonPlanningType
      });
      return response.parentSeasonData;
    }
    else {
      let dataRequest = this.getPlanningViewDataRequest(season);
      this._appInsightsLoggingService.logEvent("Requesting Parent Season Data", dataRequest);
      // request for data
      return this._seasonDataService.getParentSeasonData(dataRequest, this.isAssortmentView);
    }
  }
  // render total table for parent level
  async renderParentTotalTable() {

    let childParentCalculationDrivers: ParentCalculatorDriverInterface[] = [];

    childParentCalculationDrivers.push(this.previousParentSeasonTableData.driver);
    childParentCalculationDrivers.push(this.actualParentSeasonTableData.driver);
    childParentCalculationDrivers.push(this.comingParentSeasonTableData.driver);
    childParentCalculationDrivers.push(this.futureParentSeasonTableData.driver);

    // default seasonInfo
    let actualSeasonInfo: SeasonInfo = this.actualParentSeasonTableData.driver.getSeasonInfo();

    let totalSeasonInfo: SeasonInfo = {
      periods: this.actualParentSeasonTableData.driver.getSeasonInfo().periods,
      quarters: actualSeasonInfo.quarters,
      seasonType: null,
      seasonCodeNames: ["Total"],
      seasonPlanningType: SeasonPlanningType.Total,
      weeks: actualSeasonInfo.weeks,
      weeksWithYear: actualSeasonInfo.weeksWithYear
    }

    childParentCalculationDrivers.forEach((childParentCalculationDriver: ParentCalculatorDriverInterface) => {
      childParentCalculationDriver.addChangeEventListener(this.refreshParentTotalTable.bind(this));
      childParentCalculationDriver.addChangeEventListener(this.refreshGraphData.bind(this));
    })

    let totalDriver = new TotalParentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
    totalDriver.initTotalDataSet(childParentCalculationDrivers, totalSeasonInfo, this.selectedOptions.planningViewOptions.viewDate);

    //this is the conditions for only one season selected in the seletion list
    let childDatsets = totalDriver.getChildDataSets();
    if (childDatsets.length == 1) {
      totalDriver.setDataSet(childDatsets[0]);
      totalDriver.calculateForSingleSeason();
    }
    else {
      totalDriver.calculate();
    }

    let parentSeasonTableInfo: ParentSeasonTableInfo = {
      driver: totalDriver
    }




    this.totalSeasonTableDataDriver = totalDriver;

    this.totalParentSeasonTableData = parentSeasonTableInfo;
    this.totalSeasonTableRendered = true;


    if (!this.isAggregatedMainViewEnabled) {
      let viewTable = {
        "seasonDisplayName": "All Seasons",
        "seasonType": SeasonPlanningType.Total,
        "seasonNumber": null
      };

      this.availableViewSeasonsList.unshift(viewTable);
      this.checkAndSelectViewSeason(viewTable)
    }

    this.planningViewRenderComplete();
  }
  // Handler to update total Table
  refreshParentTotalTable() {
    this.totalParentSeasonTableData.driver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
    this.planningViewRenderComplete();
  }

  // #endregion Parent Level Methods

  // #region Read Only Level Methods

  // render a read only season table
  async renderReadonlyDepartmentSeasonTable(season: SeasonInfo, date: Date) {

    // fetch read only level data for the current season
    let seasonDataApiResponse = await this.getReadonlyDepartmentSeasonDataFromApi(season);

    // create driver and process each individually
    let readonlyDepartmentCalculationDriver = new ReadonlyDepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService);
    let seasonTableInfo: ReadonlyDepartmentSeasonTableInfo = null;
    readonlyDepartmentCalculationDriver.initDataSet(seasonDataApiResponse, season, date, this.selectedOptions);
    seasonTableInfo = {
      driver: readonlyDepartmentCalculationDriver
    }

    readonlyDepartmentCalculationDriver.calculate();

    // set data source for the department tables and enable the rendered flags. Also store a ref to the driver
    switch (season.seasonPlanningType) {
      case SeasonPlanningType.Previous:
      case SeasonPlanningType.PreviousWithOld:
        this.readonlyDepartmentPreviousSeasonTableData = seasonTableInfo;
        this.previousSeasonTableRendered = true;
        this.previousSeasonTableDataDriver = readonlyDepartmentCalculationDriver;
        break;
      case SeasonPlanningType.Actual:
        this.readonlyDepartmentActualSeasonTableData = seasonTableInfo;
        this.actualSeasonTableRendered = true;

        this.actualSeasonTableDataDriver = readonlyDepartmentCalculationDriver;
        break;
      case SeasonPlanningType.Coming:
        this.readonlyDepartmentComingSeasonTableData = seasonTableInfo;
        this.comingSeasonTableRendered = true;

        this.comingSeasonTableDataDriver = readonlyDepartmentCalculationDriver;
        break;
      case SeasonPlanningType.Future:
        this.readonlyDepartmentFutureSeasonTableData = seasonTableInfo;
        this.futureSeasonTableRendered = true;

        this.futureSeasonTableDataDriver = readonlyDepartmentCalculationDriver;
        break;
    }

  }
  // get data at the read only level for the given season from server
  async getReadonlyDepartmentSeasonDataFromApi(season: SeasonInfo): Promise<ReadonlyDepartmentPlanningViewDataResponse> {

    if (!this.isAggregatedMainViewEnabled)
      this.updateViewLists(season);

    let dataRequest = this.getPlanningViewDataRequest(season);

    this._appInsightsLoggingService.logEvent("Requesting Parent Season Data", dataRequest);

    // request for data
    return this._seasonDataService.getReadonlyDepartmentSeasonData(dataRequest, this.isAssortmentView);
  }


  getPlanningViewDataRequest(season: SeasonInfo): PlanningViewDataRequest {

    let dataRequest: PlanningViewDataRequest = {
      isAssortmentCopy: this.isAssortmentView,
      copyId: this.isAssortmentView ? (<AssortmentCopy>this.selectedOptions.selectionViewOptions.copy).assortmentCopyId : (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).salesCopyId,
      channelId: parseInt(this.selectedOptions.planningViewOptions.channel.toString()),
      marketId: (this.selectedOptions.planningViewOptions.isChannelSum) ? 0 : this.selectedOptions.planningViewOptions.market.marketIntegrationKey,
      marketType: (this.selectedOptions.planningViewOptions.isChannelSum) ? "" : MarketType[this.selectedOptions.planningViewOptions.market.marketType],
      planningSeasonType: season.seasonPlanningType,
      structureId: this.selectedOptions.planningViewOptions.structureId,
      structureType: this.selectedOptions.planningViewOptions.structureType,
      selectedDate: this.selectedDate,
      parentStructureId: this.selectedOptions.planningViewOptions.parentStructureId,
      parentStructureType: this.selectedOptions.planningViewOptions.parentStructureType,
      corporateBrandId: this.selectedOptions.selectionViewOptions.brand.id,
      retrievalMode: this._utilsService.getDefaultRetrievalMode(season, this.isAssortmentView),
      isChannelSum: this.selectedOptions.planningViewOptions.isChannelSum,

      //added for creating main if its fake copy for sales
      isMain: this.selectedOptions.selectionViewOptions.copy.isMain,
      isPrCopy: !this.isAssortmentView ? (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).isPRCopy : false,
      isPercentPm: !this.isAssortmentView ? (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).isPercentPm : false,
      copyMarketType: !this.isAssortmentView ? MarketType[(<SalesCopy>this.selectedOptions.selectionViewOptions.copy).marketType] : '',
      copyMarketId: !this.isAssortmentView ? (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).marketIntegrationKey : null,
      isStockVsSalesNet52Enabled: this.isAssortmentView
    }

    return dataRequest;
  }

  // render total table for read only level
  async renderReadonlyDepartmentTotalTable() {

    let childReadonlyDepartmentCulationDrivers: ReadonlyDepartmentCalculatorDriverInterface[] = [];

    childReadonlyDepartmentCulationDrivers.push(this.readonlyDepartmentPreviousSeasonTableData.driver);
    childReadonlyDepartmentCulationDrivers.push(this.readonlyDepartmentActualSeasonTableData.driver);
    childReadonlyDepartmentCulationDrivers.push(this.readonlyDepartmentComingSeasonTableData.driver);
    childReadonlyDepartmentCulationDrivers.push(this.readonlyDepartmentFutureSeasonTableData.driver);

    // default seasonInfo
    let actualSeasonInfo: SeasonInfo = this.readonlyDepartmentActualSeasonTableData.driver.getSeasonInfo();

    let totalSeasonInfo: SeasonInfo = {
      periods: this.readonlyDepartmentActualSeasonTableData.driver.getSeasonInfo().periods,
      quarters: actualSeasonInfo.quarters,
      seasonType: null,
      seasonCodeNames: ["Total"],
      seasonPlanningType: SeasonPlanningType.Total,
      weeks: actualSeasonInfo.weeks,
      weeksWithYear: actualSeasonInfo.weeksWithYear
    }

    childReadonlyDepartmentCulationDrivers.forEach((childReadonlyDepartmentCalculationDriver: ReadonlyDepartmentCalculatorDriverInterface) => {
      childReadonlyDepartmentCalculationDriver.addChangeEventListener(this.refreshReadonlyDepartmentTotalTable.bind(this));
    })

    let totalDriver = new TotalReadonlyDepartmentCalculationDriver(this._userConfigService, this._utilsService, this._dialogBoxService, this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);
    totalDriver.initTotalDataSet(childReadonlyDepartmentCulationDrivers, totalSeasonInfo, this.selectedOptions.planningViewOptions.viewDate);

    //this is the conditions for only one season selected in the seletion list
    let childDatsets = totalDriver.getChildDataSets();
    if (childDatsets.length == 1) {
      totalDriver.setDataSet(childDatsets[0]);
      totalDriver.calculateForSingleSeason();
    }
    else {
      totalDriver.calculate();
    }

    let parentSeasonTableInfo: ReadonlyDepartmentSeasonTableInfo = {
      driver: totalDriver
    }

    this.totalSeasonTableDataDriver = totalDriver;

    this.readonlyDepartmentTotalSeasonTableData = parentSeasonTableInfo;
    this.totalSeasonTableRendered = true;


    if (!this.isAggregatedMainViewEnabled) {
      let viewTable = {
        "seasonDisplayName": "All Seasons",
        "seasonType": SeasonPlanningType.Total,
        "seasonNumber": null
      };

      this.availableViewSeasonsList.unshift(viewTable);
      this.checkAndSelectViewSeason(viewTable)
    }

    this.planningViewRenderComplete();
  }
  // Handler to update total Table
  refreshReadonlyDepartmentTotalTable() {
    this.readonlyDepartmentTotalSeasonTableData.driver.refreshTotalDataSet(this._userConfigService.getUserConfig()?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons);

    this.planningViewRenderComplete();
  }

  // #endregion Read Only Level Methods

  // #region Graph Area

  private initChart() {
    // set chart properties on initial load
    this.chartData =
    {
      labels: [],
      datasets: [],
    };

    this.chartOptions = {
      elements: {
        point: {
          pointStyle: 'circle',
        }
      },
      responsive: true,
      animation: false,
      layout: {
        padding: {
          left: -11
        },
      },
      scales: {
        x: {
          afterFit: function (xaxis) {
            xaxis.ticks.forEach(tick => {
              tick.label = tick.label.slice(4);;
            });
          },
          ticks: {
            autoSkip: false,
            padding: -2,
            maxRotation: 0,
            minRotation: 0,
            font: {
              size: 10,
              family: "'Helvetica Neue',  sans-serif"
            },
          },
          grid: {
            display: false
          },
        },
        y: {
          min: 0,
          beginAtZero: true,
          ticks: {
            padding: 1,
            minRotation: 90,
            maxRotation: 90,
            font: {
              size: 9,
              family: "'Helvetica Neue',  sans-serif"
            }
          },
          title: {
            display: true,
            text: 'Sales gross: TSEK',
            font: {
              size: 9,
              family: "'Helvetica Neue',  sans-serif"
            },
            padding: -9
          },
          grid: {
            display: true
          },
          type: 'linear',
          display: true,
          position: 'left',
        },
        y1: {
          min: 0,
          beginAtZero: true,
          ticks: {
            padding: -4,
            minRotation: 90,
            maxRotation: 90,
            font: {
              size: 9,
              family: "'Helvetica Neue',  sans-serif"
            }
          },
          title: {
            display: false,
            text: '',// give name here for y1 axis
            font: {
              size: 9,
              family: "'Helvetica Neue',  sans-serif"
            },
            padding: -10
          },
          grid: {
            display: true,
            drawOnChartArea: false,
          },
          type: 'linear',
          display: true,
          position: 'right',
        },
      },
      plugins: {
        tooltip: {
          backgroundColor: '(255,255,255)',
          animation: {
            duration: 0
          },
          callbacks: {
            title: function (context) {
              let label: string;
              let weekName = context[0].label;
              let datasetSplitted = context[0].dataset.label.split('-');
              if (context[0].dataset.type == "bar") {
                label = "Wk " + weekName.slice(-2) + " " + datasetSplitted[0];
              }
              else {
                label = "Wk " + weekName.slice(-2) + " " + "S." + datasetSplitted[0];
              }

              return label;
            },
            label: function (context) {
              let datasetSplitted = context.dataset.label.split('-');
              let label = datasetSplitted[1] || '';
              if (label) {
                label += ': ';
              }
              if (context.parsed.y !== null && context.parsed.y !== undefined) {
                let stringVal = context.parsed.y.toLocaleString('en-US'); // This will be , as thousand separator and . as decimal separator
                while (stringVal.indexOf(',') !== -1) stringVal = stringVal.replace(',', ' ');
                label += stringVal;
              }
              return label;
            }
          }
        },
        title: {
          display: false,
        },
        legend: {
          display: true,
          align: 'start',
          position: 'left',
          labels: {
            usePointStyle: true,
            pointStyle: 'rectRounded',
            font: {
              size: 9,
              family: "'Helvetica Neue',  sans-serif"
            },
            padding: 5,
            boxWidth: 8,
          },
          onHover: function (event) {
            let filterValue = (event.native.target as HTMLElement);
            filterValue.style.cursor = 'pointer';
          },
          onLeave: function (event) {
            let filterValue = (event.native.target as HTMLElement);
            filterValue.style.cursor = 'auto';
          },
          onClick(e, legendItem, legend) {
            var index = legendItem.datasetIndex;
            var ci = this.chart;
            var meta = ci.getDatasetMeta(index);
            meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

            this.chart.data.datasets.forEach(function (dataset, index, object) {
              if (dataset.label === meta.label) {
                dataset.hidden = meta.hidden
              }
            });

            ci.update();
          },
        },
        annotation: {
          annotations: [],
        }

      },
    };

    Chart.register(Annotation);
  }

  pinChart() {
    this.isChartPinned = !this.isChartPinned;
    this.updatePlanningViewLayoutSettings();
  }

  drawGraph() {
    this.refreshGraphData();
    //Loading graph label here
    if (this.isDepartmentLevelSeasonTablesRequested && this.totalSeasonTableRendered) {
      this.chartData.labels = _.map(this.totalDepartmentSeasonTableData.driver.getSeasonInfo().weeksWithYear, weekNumber => {
        return weekNumber.toString();
      });
    }

    else if (this.isReadonlyDepartmentSeasonTablesRequested && this.totalSeasonTableRendered) {
      this.chartData.labels = _.map(this.readonlyDepartmentTotalSeasonTableData.driver.getSeasonInfo().weeksWithYear, weekNumber => {
        return weekNumber.toString();
      });
    }

    else if (this.isParentLevelSeasonTablesRequested && this.totalSeasonTableRendered) {
      this.chartData.labels = _.map(this.totalParentSeasonTableData.driver.getSeasonInfo().weeksWithYear, weekNumber => {
        return weekNumber.toString();
      });
    }

    else if (this.isAggregatedDepartmentSeasonTablesRequested && this.aggregatedTotalSeasonTableRendered) {
      this.chartData.labels = _.map(this.aggregatedDepartmentTotalSeasonTableData.driver.getSeasonInfo().weeksWithYear, weekNumber => {
        return weekNumber.toString();
      });
    }

    //Draw annotations for period and quarter
    let xaxisAnnotations = [];
    let quarterCount = 1;
    let weekCount = 1;
    this.chartData.labels.forEach(_axisItem => {
      if (weekCount % 4 == 0) {
        let xAxisAnnotation =
        {
          type: 'line',
          scaleID: 'x',
          value: _axisItem,
          borderColor: 'rgb(192,192,192)',
          borderWidth: 1,
          shadowBlur: 1,
          borderDash: [2]
        }
        xaxisAnnotations.push(xAxisAnnotation);
      }
      if (quarterCount % 12 == 0) {
        let xAxisAnnotation =
        {
          type: 'line',
          scaleID: 'x',
          value: _axisItem,
          borderColor: 'rgb(112,128,144)',
          borderWidth: 1,
          shadowBlur: 1
        }
        xaxisAnnotations = xaxisAnnotations.slice(0, -1);
        xaxisAnnotations.push(xAxisAnnotation);
        quarterCount = 0;
      }
      //skip for two julys
      if (!(parseInt(_axisItem.toString().slice(-2)) >= 24 && parseInt(_axisItem.toString().slice(-2)) < 28)) {
        quarterCount++;
      }
      weekCount++;
    });

    this.chartOptions.plugins.annotation.annotations = xaxisAnnotations;

  }

  isSeasonSelected(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface) {
    return this._utilsService.isNotNullOrUndefined(this.selectedViewSeasonsTablesList.find(x => driver.getSeasonInfo().seasonPlanningType == x.seasonType));
  }

  refreshGraphData() {
    if (this._utilsService.isNotNullOrUndefined(this.chartObj))
      this._cachedchartData = _.cloneDeep(this.chartData);

    this.chartData.datasets = [];
    //binding the dataset to the graph
    if (this.isDepartmentLevelSeasonTablesRequested && !this.isAggregatedMainViewEnabled) {

      if (this.selectedViewSeasonsTablesList) {
        // check if previous is selected    
        if (this.isSeasonSelected(this.previousDepartmentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.previousDepartmentSeasonTableData.driver));
        // check if actual is selected    
        if (this.isSeasonSelected(this.actualDepartmentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.actualDepartmentSeasonTableData.driver));
        // check if coming is selected    
        if (this.isSeasonSelected(this.comingDepartmentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.comingDepartmentSeasonTableData.driver));
        // check if future is selected    
        if (this.isSeasonSelected(this.futureDepartmentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.futureDepartmentSeasonTableData.driver));
        // check if total is selected    
        if (this.isSeasonSelected(this.totalDepartmentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.totalDepartmentSeasonTableData.driver));
      }
      else {
        let previousSeasonChartData = this.generateGraphSeriesForSeason(this.previousDepartmentSeasonTableData.driver);
        let actualSeasonChartData = this.generateGraphSeriesForSeason(this.actualDepartmentSeasonTableData.driver);
        let comingSeasonChartData = this.generateGraphSeriesForSeason(this.comingDepartmentSeasonTableData.driver);
        let futureSeasonChartData = this.generateGraphSeriesForSeason(this.futureDepartmentSeasonTableData.driver);
        let totalSeasonChartData = this.generateGraphSeriesForSeason(this.totalDepartmentSeasonTableData.driver);

        this.chartData.datasets = [...previousSeasonChartData, ...actualSeasonChartData, ...comingSeasonChartData, ...futureSeasonChartData, ...totalSeasonChartData];
      }
    }
    else if (this.isReadonlyDepartmentSeasonTablesRequested && !this.isAggregatedMainViewEnabled) {
      if (this.selectedViewSeasonsTablesList) {
        // check if previous is selected    
        if (this.isSeasonSelected(this.readonlyDepartmentPreviousSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.readonlyDepartmentPreviousSeasonTableData.driver));
        // check if actual is selected    
        if (this.isSeasonSelected(this.readonlyDepartmentActualSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.readonlyDepartmentActualSeasonTableData.driver));
        // check if coming is selected    
        if (this.isSeasonSelected(this.readonlyDepartmentComingSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.readonlyDepartmentComingSeasonTableData.driver));
        // check if future is selected    
        if (this.isSeasonSelected(this.readonlyDepartmentFutureSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.readonlyDepartmentFutureSeasonTableData.driver));
        // check if total is selected    
        if (this.isSeasonSelected(this.readonlyDepartmentTotalSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.readonlyDepartmentTotalSeasonTableData.driver));
      }
      else {
        let previousSeasonChartData = this.generateGraphSeriesForSeason(this.readonlyDepartmentPreviousSeasonTableData.driver);
        let actualSeasonChartData = this.generateGraphSeriesForSeason(this.readonlyDepartmentActualSeasonTableData.driver);
        let comingSeasonChartData = this.generateGraphSeriesForSeason(this.readonlyDepartmentComingSeasonTableData.driver);
        let futureSeasonChartData = this.generateGraphSeriesForSeason(this.readonlyDepartmentFutureSeasonTableData.driver);
        let totalSeasonChartData = this.generateGraphSeriesForSeason(this.readonlyDepartmentTotalSeasonTableData.driver);

        this.chartData.datasets = [...previousSeasonChartData, ...actualSeasonChartData, ...comingSeasonChartData, ...futureSeasonChartData, ...totalSeasonChartData];
      }
    }
    else if (this.isParentLevelSeasonTablesRequested && !this.isAggregatedMainViewEnabled) {
      if (this.selectedViewSeasonsTablesList) {
        // check if previous is selected    
        if (this.isSeasonSelected(this.previousParentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.previousParentSeasonTableData.driver));
        // check if actual is selected    
        if (this.isSeasonSelected(this.actualParentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.actualParentSeasonTableData.driver));
        // check if coming is selected    
        if (this.isSeasonSelected(this.comingParentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.comingParentSeasonTableData.driver));
        // check if future is selected    
        if (this.isSeasonSelected(this.futureParentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.futureParentSeasonTableData.driver));
        // check if total is selected    
        if (this.isSeasonSelected(this.totalParentSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.totalParentSeasonTableData.driver));
      }
      else {
        let previousSeasonChartData = this.generateGraphSeriesForSeason(this.previousParentSeasonTableData.driver);
        let actualSeasonChartData = this.generateGraphSeriesForSeason(this.actualParentSeasonTableData.driver);
        let comingSeasonChartData = this.generateGraphSeriesForSeason(this.comingParentSeasonTableData.driver);
        let futureSeasonChartData = this.generateGraphSeriesForSeason(this.futureParentSeasonTableData.driver);
        let totalSeasonChartData = this.generateGraphSeriesForSeason(this.totalParentSeasonTableData.driver);

        this.chartData.datasets = [...previousSeasonChartData, ...actualSeasonChartData, ...comingSeasonChartData, ...futureSeasonChartData, ...totalSeasonChartData];
      }

    }
    else if (this.isAggregatedMainViewEnabled) {
      if (this.selectedViewSeasonsTablesList) {
        // check if previous is selected    
        if (this.isSeasonSelected(this.aggregatedDepartmentPreviousSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.aggregatedDepartmentPreviousSeasonTableData.driver));
        // check if actual is selected    
        if (this.isSeasonSelected(this.aggregatedDepartmentActualSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.aggregatedDepartmentActualSeasonTableData.driver));
        // check if coming is selected    
        if (this.isSeasonSelected(this.aggregatedDepartmentComingSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.aggregatedDepartmentComingSeasonTableData.driver));
        // check if future is selected    
        if (this.isSeasonSelected(this.aggregatedDepartmentFutureSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.aggregatedDepartmentFutureSeasonTableData.driver));
        // check if total is selected    
        if (this.isSeasonSelected(this.aggregatedDepartmentTotalSeasonTableData.driver)) this.chartData.datasets.push(...this.generateGraphSeriesForSeason(this.aggregatedDepartmentTotalSeasonTableData.driver));
      }
      else {
        let previousSeasonChartData = this.generateGraphSeriesForSeason(this.aggregatedDepartmentPreviousSeasonTableData.driver);
        let actualSeasonChartData = this.generateGraphSeriesForSeason(this.aggregatedDepartmentActualSeasonTableData.driver);
        let comingSeasonChartData = this.generateGraphSeriesForSeason(this.aggregatedDepartmentComingSeasonTableData.driver);
        let futureSeasonChartData = this.generateGraphSeriesForSeason(this.aggregatedDepartmentFutureSeasonTableData.driver);
        let totalSeasonChartData = this.generateGraphSeriesForSeason(this.aggregatedDepartmentTotalSeasonTableData.driver);

        this.chartData.datasets = [...previousSeasonChartData, ...actualSeasonChartData, ...comingSeasonChartData, ...futureSeasonChartData, ...totalSeasonChartData];
      }

    }

    if (this.chartObj?.chart) {
      this.chartObj.chart.update();
      this.showAllLegends = true;
    }
  }

  generateGraphSeriesForSeason(seasonDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface) {
    let seasonInfo = seasonDriver.getSeasonInfo();
    let startWeekForCurrentSeason = this._utilsService.getStartWeekForSeason(Number(seasonInfo.seasonCodeNames[0]));
    let dataSetForAllWeeks = seasonDriver.getDataSet();

    let dataSet = _.filter(dataSetForAllWeeks, (data: DepartmentCalculationDataItem) => {
      return seasonInfo.weeksWithYear.indexOf(data.weekName) > -1;
    })

    // for coming and future season filter data : value should start from the start week
    if (seasonInfo.seasonPlanningType == SeasonPlanningType.Future || seasonInfo.seasonPlanningType == SeasonPlanningType.Coming) {
      let fiterDataSet: DepartmentCalculationDataItem[] = [];
      let dataSetItem: DepartmentCalculationDataItem;

      dataSet.forEach((departmentCalculationDataItem: DepartmentCalculationDataItem) => {

        if (departmentCalculationDataItem.weekName < startWeekForCurrentSeason) {
          dataSetItem = {
            weekName: departmentCalculationDataItem.weekName,
            grossSales: null,
            combinedSalesPlanWeekly: null,
            grossSalesGround: null,
          }
          fiterDataSet.push(dataSetItem);
        }
        else {
          fiterDataSet.push(departmentCalculationDataItem);
        }
      });

      dataSet = fiterDataSet;
    }

    let grossSalesSeries = _.map(dataSet, CalculationDataItemType.GrossSales);
    let combinedSalesPlanSeries = _.map(dataSet, CalculationDataItemType.CombinedSalesPlanWeekly);

    // remove overlapping values 
    combinedSalesPlanSeries = _.map(combinedSalesPlanSeries, (val, index) => {
      return (val == grossSalesSeries[index]) ? null : Math.round(parseFloat(val));
    });

    grossSalesSeries = _.map(grossSalesSeries, x => (x == 0 || !x) ? null : Math.round(parseFloat(x)));

    // set last value of gross sales as the first value for planned sales to maintain continuity in the line graph
    let lastItemIndex = _.findLastIndex(grossSalesSeries, x => x != null);

    if (lastItemIndex != -1) {
      combinedSalesPlanSeries[lastItemIndex] = grossSalesSeries[lastItemIndex];
    }

    let grossSalesGroundSeries = _.map(dataSet, CalculationDataItemType.GrossSalesGround).map(x => (x == 0 || !x) ? null : Math.round(parseFloat(x)));

    let hideDataSet = false;
    if (!this.chartObj && (seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOld || seasonInfo.seasonPlanningType == SeasonPlanningType.Previous)) //if chart is null meaning its a first load, then we have to hide prev and old season at the begining	
    {
      hideDataSet = true;
    }

    let areOldSeasonsExcluded = this.selectedOptions.planningViewOptions.areOldSeasonsExcluded;

    let title = (seasonInfo.seasonPlanningType != SeasonPlanningType.Total) ? seasonDriver.getTitle(areOldSeasonsExcluded).split("(")[0] : 'All';

    let seasonSalesGross = {
      label: title + (areOldSeasonsExcluded ? "-Sales   " : "-Sales"),
      data: grossSalesSeries,
      tension: 0.3,
      hidden: this.getGraphLegendStatus(title + (areOldSeasonsExcluded ? "-Sales   " : "-Sales"), hideDataSet, seasonInfo),
      borderColor: (seasonInfo.seasonPlanningType == 'Total') ? "rgb(42, 157, 245)" : "red",
      borderWidth: 2.5,
      pointBackgroundColor: (seasonInfo.seasonPlanningType == 'Total') ? "rgb(42, 157, 245)" : "red",
      pointRadius: 3,
      pointHoverRadius: 3,
      yAxisID: 'y',
      type: 'line',
    };

    let seasonSalesPlan = {
      label: title + (areOldSeasonsExcluded ? "-Plan   " : "-Plan"),
      data: combinedSalesPlanSeries,
      tension: 0.5,
      hidden: this.getGraphLegendStatus(title + (areOldSeasonsExcluded ? "-Plan   " : "-Plan"), hideDataSet, seasonInfo),
      borderColor: (seasonInfo.seasonPlanningType == 'Total') ? "rgb(42, 157, 245)" : "red",
      borderWidth: 2.8,
      pointBackgroundColor: (seasonInfo.seasonPlanningType == 'Total') ? "rgb(42, 157, 245)" : "red",
      borderDash: [2.5],
      pointRadius: 3,
      pointHoverRadius: 3,
      yAxisID: 'y',
      type: 'line',
    };


    let seasonSalesGrossGround = {
      label: title + (areOldSeasonsExcluded ? "-Gnd   " : "-Gnd"),
      data: grossSalesGroundSeries,
      tension: 0.5,
      hidden: this.getGraphLegendStatus(title + (areOldSeasonsExcluded ? "-Gnd   " : "-Gnd"), hideDataSet, seasonInfo),
      borderColor: (seasonInfo.seasonPlanningType == 'Total') ? "rgb(94, 95, 96)" : "black",
      borderWidth: 2.5,
      pointBackgroundColor: (seasonInfo.seasonPlanningType == 'Total') ? "rgb(94, 95, 96)" : "black",
      pointRadius: 3,
      pointHoverRadius: 3,
      yAxisID: 'y',
      type: 'line',
    };

    //bar graph section // bar only for total season
    let buyingSeries: any;
    let boughtGrossGroundSeries: any;

    let seasontotalBuying: any;
    let seasontotaloughtGrossGround: any;
    if (seasonInfo.seasonPlanningType == SeasonPlanningType.Total) {
      buyingSeries = _.map(dataSet, CalculationDataItemType.BuyingWeekly).map(x => (x == 0 || !x) ? null : Math.round(parseFloat(x)));
      seasontotalBuying = {
        label: "Buying",
        data: buyingSeries,
        hidden: this.getGraphLegendStatus("Buying", hideDataSet, seasonInfo),
        backgroundColor: "#8f8f8f",
        hoverBackgroundColor: "#8f8f8f",
        yAxisID: 'y1',
        type: 'bar',
        barThickness: 9,
        barPercentage: 0.5,
      };
      boughtGrossGroundSeries = _.map(dataSet, CalculationDataItemType.BoughtGrossGround).map(x => (x == 0 || !x) ? null : Math.round(parseFloat(x)));
      seasontotaloughtGrossGround = {
        label: "Bought-Gnd",
        data: boughtGrossGroundSeries,
        hidden: this.getGraphLegendStatus("Bought-Gnd", hideDataSet, seasonInfo),
        backgroundColor: "#cacdd1",
        hoverBackgroundColor: "#cacdd1",
        yAxisID: 'y1',
        type: 'bar',
        barThickness: 9,
        barPercentage: 0.5,
      };

    }


    if (seasonInfo.seasonPlanningType != SeasonPlanningType.Future && seasonInfo.seasonPlanningType != SeasonPlanningType.Total) {

      return [seasonSalesGross, seasonSalesPlan, seasonSalesGrossGround];
    }
    else if (seasonInfo.seasonPlanningType == SeasonPlanningType.Future) {
      return [seasonSalesPlan, seasonSalesGrossGround];
    }
    else if (seasonInfo.seasonPlanningType == SeasonPlanningType.Total) {
      return [seasonSalesGross, seasonSalesPlan, seasonSalesGrossGround, seasontotalBuying, seasontotaloughtGrossGround];
    }

  }

  getGraphLegendStatus(title: string, hideDataSet: boolean, seasonInfo: SeasonInfo) {

    if (!this.chartObj) {
      return hideDataSet;
    }
    else {
      //if chart obj is not null, which means graph is rendered.
      //we dont have dataset for future sales
      if (this.chartObj && this._cachedchartData) {
        let datasetObj = _.find(this._cachedchartData.datasets, (dataset) => dataset.label == title);
        if (datasetObj != undefined && datasetObj != null) {
          return datasetObj.hidden;
        }
        else {
          return hideDataSet;
        }
      }
      else {
        return hideDataSet;
      }

    }
  }

  changeLegend(event: MatCheckboxChange) {
    this.showAllLegends = event.checked;

    this.updatePlanningViewLayoutSettings();

    if (this.chartObj) {
      let hideLegend = false;
      if (!this.showAllLegends) {
        hideLegend = true;
      }

      let ci = this.chartObj.chart;

      for (let i = 0; i < this.chartData.datasets.length; i++) {
        var meta = ci.getDatasetMeta(i);
        meta.hidden = hideLegend;
      }

      ci.data.datasets.forEach(function (dataset, index, object) {
        dataset.hidden = hideLegend
      });

      this.chartData.datasets.forEach(function (dataset, index, object) {
        dataset.hidden = hideLegend
      });

      ci.update();
    }
  }

  // #endregion Graph Area

  // #region save copy

  checkForNegativePlansForDepartmentLevel() {

    let comingSeasonNegPurchaseWeeks: any[] = [];
    let comingSeasonNegCoverageWeeks: any[] = [];
    let futureSeasonNegPurchaseWeeks: any[] = [];
    let futureSeasonNegCoverageWeeks: any[] = [];
    if (this.isAssortmentView) {
      let comingSeasonData = this.comingDepartmentSeasonTableData.driver.getDataSet();
      let comingSeasonName = this.comingDepartmentSeasonTableData.driver.getSeasonInfo().seasonCodeNames[0];

      let comingStartWeek = this._utilsService.getStartWeekForSeason(parseInt(comingSeasonName));
      let comingEndWeek = this._utilsService.getEndWeekForSeason(parseInt(comingSeasonName));

      comingSeasonData.forEach((deptCalculationDataItem: DepartmentCalculationDataItem) => {
        let isInSeasonRange = deptCalculationDataItem.weekName >= comingStartWeek && deptCalculationDataItem.weekName <= comingEndWeek;
        if (deptCalculationDataItem.purchasePlan < 0 && isInSeasonRange) {
          comingSeasonNegPurchaseWeeks.push({
            seasonName: comingSeasonName, weekName: deptCalculationDataItem.weekName
          });
        }
        if (deptCalculationDataItem.plannedCoverage < 0 && isInSeasonRange) {
          comingSeasonNegCoverageWeeks.push({
            seasonName: comingSeasonName, weekName: deptCalculationDataItem.weekName
          });
        }
      });

      let futureSeasonData = this.futureDepartmentSeasonTableData.driver.getDataSet();
      let futureSeasonName = this.futureDepartmentSeasonTableData.driver.getSeasonInfo().seasonCodeNames[0];

      let futureStartWeek = this._utilsService.getStartWeekForSeason(parseInt(futureSeasonName));
      let futureEndWeek = this._utilsService.getEndWeekForSeason(parseInt(futureSeasonName));

      futureSeasonData.forEach((deptCalculationDataItem: DepartmentCalculationDataItem) => {
        let isInSeasonRange = deptCalculationDataItem.weekName >= futureStartWeek && deptCalculationDataItem.weekName <= futureEndWeek;
        if (deptCalculationDataItem.purchasePlan < 0 && isInSeasonRange) {
          futureSeasonNegPurchaseWeeks.push({
            seasonName: futureSeasonName,
            weekName: deptCalculationDataItem.weekName
          });
        }
        if (deptCalculationDataItem.plannedCoverage < 0 && isInSeasonRange) {
          futureSeasonNegCoverageWeeks.push({
            seasonName: futureSeasonName,
            weekName: deptCalculationDataItem.weekName
          });
        }
      });
    }

    let saveWarningDialogData: SaveWarningDialogData = {
      comingSeasonNegPurchaseWeeks,
      futureSeasonNegPurchaseWeeks,
      comingSeasonNegCoverageWeeks,
      futureSeasonNegCoverageWeeks
    };

    return saveWarningDialogData;

  }

  async checkForNegativePlansForParentLevel() {

    let comingSeasonNegPurchaseWeeks: any[] = [];
    let comingSeasonNegCoverageWeeks: any[] = [];

    let futureSeasonNegPurchaseWeeks: any[] = [];
    let futureSeasonNegCoverageWeeks: any[] = [];

    if (this.isAssortmentView) {
      let departmentSaveItems: DepartmentSaveItem = {
        weekData: [],
        periodData: [],
        seasonData: []
      }
      let comingSeasonSaveItem = this.comingParentSeasonTableData.driver.getItemsForSave(this.userConfig);
      let futureSeasonSaveItem = this.futureParentSeasonTableData.driver.getItemsForSave(this.userConfig);

      departmentSaveItems.weekData = [...comingSeasonSaveItem.weekData, ...futureSeasonSaveItem.weekData];

      let result = await this._planningViewService.checkAssortmentNegativePurchasePlanForTrickleDownCopyData(departmentSaveItems, this.userConfig);
      if (result != null) {
        comingSeasonNegPurchaseWeeks = result.comingSeasonNegPurchaseWeeks;
        comingSeasonNegCoverageWeeks = result.comingSeasonNegCoverageWeeks;
        futureSeasonNegPurchaseWeeks = result.futureSeasonNegPurchaseWeeks;
        futureSeasonNegCoverageWeeks = result.futureSeasonNegCoverageWeeks;
      }
    }

    let saveWarningDialogData: SaveWarningDialogData = {
      comingSeasonNegPurchaseWeeks,
      futureSeasonNegPurchaseWeeks,
      comingSeasonNegCoverageWeeks,
      futureSeasonNegCoverageWeeks
    };

    return saveWarningDialogData;

  }

  async saveDepartmentLevelCopy() {

    let saveWarningDialogData = this.checkForNegativePlansForDepartmentLevel();

    if (saveWarningDialogData.futureSeasonNegPurchaseWeeks.length > 0 || saveWarningDialogData.comingSeasonNegPurchaseWeeks
      .length > 0 || saveWarningDialogData.futureSeasonNegCoverageWeeks.length > 0 ||
      saveWarningDialogData.comingSeasonNegCoverageWeeks.length > 0) {
      this._dialog.open<SaveWarningDialogComponent, SaveWarningDialogData>(SaveWarningDialogComponent, {
        data: saveWarningDialogData,
        autoFocus: false,
      }).afterClosed().subscribe(result => {
        if (result) {
          this.saveDepartmentCopyData();
        }
      });
    }
    else {
      this.saveDepartmentCopyData();
    }


  }

  async stashInputs(showdiaglogueMessage: boolean = true) {
    let departmentSaveItems: DepartmentSaveItem = {
      weekData: [],
      periodData: [],
      seasonData: []
    }

    this.isSaveButtonDisabled = true;
    //disablei input cells
    let prevDriverPreviousLockStatus = this.previousSeasonTableDataDriver.getInputLockStatus();
    let actualDriverPreviousLockStatus = this.actualSeasonTableDataDriver.getInputLockStatus();
    let comingDriverPreviousLockStatus = this.comingSeasonTableDataDriver.getInputLockStatus();
    let futureDriverPreviousLockStatus = this.futureSeasonTableDataDriver.getInputLockStatus();

    this.previousSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.actualSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.comingSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.futureSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);

    let previousSeasonSaveItem = this.previousParentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let actualSeasonSaveItem = this.actualParentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let comingSeasonSaveItem = this.comingParentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let futureSeasonSaveItem = this.futureParentSeasonTableData.driver.getItemsForSave(this.userConfig);

    // merge week level save items
    departmentSaveItems.weekData = [...previousSeasonSaveItem.weekData, ...actualSeasonSaveItem.weekData, ...comingSeasonSaveItem.weekData, ...futureSeasonSaveItem.weekData];

    departmentSaveItems.seasonData = [...previousSeasonSaveItem.seasonData, ...actualSeasonSaveItem.seasonData, ...comingSeasonSaveItem.seasonData, ...futureSeasonSaveItem.seasonData];

    this._loadingAnimationService.enableTopNavAnimation();

    let savedStatus = await this._planningViewService.saveCopyData(departmentSaveItems, this.userConfig, true);

    this._loadingAnimationService.disableTopNavAnimation();
    if (savedStatus) {
      this.stashStructureType = this.userConfig.planningViewOptions.structureType;
      this.stashedDataExists = true;
      this.stashedData = departmentSaveItems;
      // refresh Season Raw data items is not needed here (Because the next time save will not take the unsaved changes)

      this.isSaveButtonDisabled = false;

      this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
      this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
      this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
      this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);
      if (showdiaglogueMessage) {
        this._dialogBoxService.showMessage("Your copy has been saved successfully. Please note that the values have not been trickled down");
      }

      //We no need to reload when stashing since it is going to do the same work again
      // if (this.userConfig.planningViewOptions.channel == ChannelType.Online) {
      //   // reload view
      //   this.renderPlanningTables();
      // }
      await this.getStashStatusByCopy();
    }

    else {
      this.isSaveButtonDisabled = false;

      this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
      this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
      this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
      this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);

      this._dialogBoxService.showMessage("Sorry! Your copy could not be saved successfully. Try again later or contact IT helpdesk");

    }
  }

  async unlockSeasonInputs() {
    if (!this.userConfig.selectionViewOptions.copy.isMain &&
      !this.userConfig.planningViewOptions.isChannelSum &&
      this.userConfig.planningViewOptions.channel != 3) {

      // Unlock inputs if,
      // there is no stashed input
      // or if there is stashed input and its for the current view
      if (!this.stashedDataExists || (this.stashedDataExists && this.stashedData != null) || (this.stashedDataExists && this.stashStructureType == this.userConfig.planningViewOptions.structureType)) {
        this.unlockDriverInputs(this.previousSeasonTableDataDriver);
        this.unlockDriverInputs(this.actualSeasonTableDataDriver);
        this.unlockDriverInputs(this.comingSeasonTableDataDriver);
        this.unlockDriverInputs(this.futureSeasonTableDataDriver);
      }
    }
  }

  async openSaveCopyAndTrickleDown() {
    if (this.selectedOptions.planningViewOptions.structureType == 'CustomerGroup' ||
      this.selectedOptions.planningViewOptions.structureType == 'Total')
      await this.stashInputs(false); // Stash before trickle down if CG or total (not for other levels as this will cause a double save)
    if (this.showTrickleDownConfirmationDialog) {
      this._dialog.open<SaveTrickleDownDialogComponent>(SaveTrickleDownDialogComponent, {
        data: null,
        autoFocus: false,
      }).afterClosed().subscribe(copyResponse => {
        if (copyResponse) {
          this.saveParentLevelCopy();
        }
      });
    }
    else
      this.saveParentLevelCopy();
  }

  async saveParentLevelCopy() {

    let saveWarningDialogData = await this.checkForNegativePlansForParentLevel();
    if (saveWarningDialogData != null && saveWarningDialogData.futureSeasonNegPurchaseWeeks.length > 0 || saveWarningDialogData.comingSeasonNegPurchaseWeeks
      .length > 0 || saveWarningDialogData.futureSeasonNegCoverageWeeks.length > 0 ||
      saveWarningDialogData.comingSeasonNegCoverageWeeks.length > 0) {
      this._dialog.open<SaveWarningTrickleDownDialogComponent, SaveWarningDialogData>(SaveWarningTrickleDownDialogComponent, {
        data: saveWarningDialogData,
        autoFocus: false,
      }).afterClosed().subscribe(result => {
        if (result) {
          this.saveParentCopyData();
        }
      });
    }
    else {
      this.saveParentCopyData();
    }
  }

  async saveDepartmentCopyData() {
    let departmentSaveItems: DepartmentSaveItem = {
      weekData: [],
      periodData: [],
      seasonData: []
    }

    this.isSaveButtonDisabled = true;
    //disablei input cells
    let prevDriverPreviousLockStatus = this.previousSeasonTableDataDriver.getInputLockStatus();
    let actualDriverPreviousLockStatus = this.actualSeasonTableDataDriver.getInputLockStatus();
    let comingDriverPreviousLockStatus = this.comingSeasonTableDataDriver.getInputLockStatus();
    let futureDriverPreviousLockStatus = this.futureSeasonTableDataDriver.getInputLockStatus();

    this.previousSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.actualSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.comingSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.futureSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);

    //since the selected option can be change by user try to get the selected option from cache ==> refer to : https://hmgroup.atlassian.net/browse/BTTDMFP-589
    // let this.userConfig: this.userConfig = this.cachedthis.userConfig;

    let previousSeasonSaveItem = this.previousDepartmentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let actualSeasonSaveItem = this.actualDepartmentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let comingSeasonSaveItem = this.comingDepartmentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let futureSeasonSaveItem = this.futureDepartmentSeasonTableData.driver.getItemsForSave(this.userConfig);

    // merge week level save items
    departmentSaveItems.weekData = [...previousSeasonSaveItem.weekData, ...actualSeasonSaveItem.weekData, ...comingSeasonSaveItem.weekData, ...futureSeasonSaveItem.weekData];
    // merge period level save item
    // departmentSaveItems.periodData = [...previousSeasonSaveItem.periodData, ...actualSeasonSaveItem.periodData, ...comingSeasonSaveItem.periodData, ...futureSeasonSaveItem.periodData];
    // merge season level save item
    //ToDO Need to include R% save 
    departmentSaveItems.seasonData = [...previousSeasonSaveItem.seasonData, ...actualSeasonSaveItem.seasonData, ...comingSeasonSaveItem.seasonData, ...futureSeasonSaveItem.seasonData];

    this._loadingAnimationService.enableTopNavAnimation();


    let savedStatus = await this._planningViewService.saveCopyData(departmentSaveItems, this.userConfig, false);

    this._loadingAnimationService.disableTopNavAnimation();
    if (savedStatus) {

      // refresh Season Raw data items
      this.previousDepartmentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);
      this.actualDepartmentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);
      this.comingDepartmentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);
      this.futureDepartmentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);

      this.previousDepartmentSeasonTableData.driver.touched = false;
      this.actualDepartmentSeasonTableData.driver.touched = false;
      this.comingDepartmentSeasonTableData.driver.touched = false;
      this.futureDepartmentSeasonTableData.driver.touched = false;

      // reset the merge copy button visibity to true
      this.isMergeButtonDisabled = false;
      this._planningViewActionHistoryService.clearHistory();

      this.isSaveButtonDisabled = false;

      this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
      this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
      this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
      this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);

      this._dialogBoxService.showMessage("Your copy has been saved successfully.");
    }

    else {
      this.isSaveButtonDisabled = false;

      this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
      this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
      this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
      this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);

      this._dialogBoxService.showMessage("Sorry! Your copy could not be saved successfully. Try again later or contact IT helpdesk");
    }
  }
  // #endregion save copy

  // #region generate link and copy details
  generateLink() {
    let linkUrl = this._userConfigService.generateLink();
    this._utilsService.copyTextToClipboard(linkUrl);
    this._utilsService.showGeneralMessage("Link Copied");
  }

  copyPlanningViewDetails() {
    let planningViewDetails = this._userConfigService.getConfigurationText();

    this._utilsService.copyTextToClipboard(planningViewDetails);

    this._utilsService.showGeneralMessage("Selected configuration copied");

  }
  // #endregion generate link and copy details

  // #region dialogue box methods
  async openPublishDialog() {
    this.isPublishButtonDisabled = true;
    //this.selectedOptions.planningViewOptions.structureId
    let publishDialogData: PublishDialogData;

    if (this.seasons == undefined || this.seasons == null) {
      // create a request object for the season info api
      let seasonInfoRequest: SeasonInfoRequest =
      {
        excludeOldSeasons: this.selectedOptions.planningViewOptions.areOldSeasonsExcluded,
        date: this.selectedOptions.planningViewOptions.viewDate
      }

      // get the seasons for the current date
      this.seasons = await this._seasonDataService.getSeasonInfo(seasonInfoRequest);
    }

    if (this.isAssortmentView) {
      publishDialogData = {
        publishDialogType: "Assortment",
        seasonInfos: this.seasons,
        name: this.selectedOptions.selectionViewOptions.copy.name,
        brand: this.selectedOptions.selectionViewOptions.brand.name,
        copyId: (<AssortmentCopy>this.selectedOptions.selectionViewOptions.copy).assortmentCopyId,
        structureId: this.selectedOptions.selectionViewOptions.structure.id,
        structureCode: this.selectedOptions.selectionViewOptions.structure.code,
        isMainLocked: this.selectedOptions.selectionViewOptions.copy.isLocked,
      }
    }
    if (this.isSalesView) {
      publishDialogData.publishDialogType = "Sales";
      publishDialogData.seasonInfos = this.seasons,
        publishDialogData.brand = this.selectedOptions.selectionViewOptions.brand.name,
        publishDialogData.copyId = (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).salesCopyId,
        publishDialogData.isPercentMarket = (<SalesCopy>this.selectedOptions.selectionViewOptions.copy).isPercentPm,
        publishDialogData.marketName = this.selectedOptions.selectionViewOptions.market.shortName
    }

    this._dialog.open<PublishDialogComponent, PublishDialogData>(PublishDialogComponent, {
      data: publishDialogData,
      disableClose: true
    }).afterClosed().subscribe(copyResponse => {
      this._loadingAnimationService.disableTopNavAnimation();
      this.isPublishButtonDisabled = false;
      if (copyResponse) {
        this._dialogBoxService.showMessage("Publish request sent successfully!");
      }
    });
  }

  openMergeCopyCopyDialog() {
    this.isMergeButtonDisabled = true;
    let mergeCopy: MergeCopyDialogData;
    if (this.isAssortmentView) {
      mergeCopy = {
        copyDialogType: "assortment-merge-copy",
        copyDialogDataItem: <AssortmentCopy>this.selectedOptions.selectionViewOptions.copy
      }
    }
    else if (this.isSalesView) {
      mergeCopy = {
        copyDialogType: "sales-merge-copy",
        copyDialogDataItem: <SalesCopy>this.selectedOptions.selectionViewOptions.copy
      }
    }

    this._dialog.open<CopyDialogBoxComponent, MergeCopyDialogData>(CopyDialogBoxComponent, {
      data: mergeCopy,
      disableClose: true
    }).afterClosed().subscribe(copyResponse => {
      this._loadingAnimationService.disableTopNavAnimation();
      if (copyResponse) {
        this._dialogBoxService.showMessage("Your goals have been merged successfully into the Main");
      }
      else {
        this.isMergeButtonDisabled = false;
      }
    });
  }

  openSalesParentTrickleDownSaveByCopyDialog() {
    this.selectedOptions.selectionViewOptions.copy.isLocked = true;
    let trickleDownDialog: TrickleDownAllCopyDialogData;
    if (this.isSalesView) {
      trickleDownDialog = {
        copyDialogType: "sales-parent-trickle-down-save-by-copy",
        copyDialogDataItem: <SalesCopy>this.selectedOptions.selectionViewOptions.copy
      }
    }

    this._dialog.open<CopyDialogBoxComponent, TrickleDownAllCopyDialogData>(CopyDialogBoxComponent, {
      data: trickleDownDialog,
      disableClose: true
    }).afterClosed().subscribe(copyResponse => {
      this._loadingAnimationService.disableTopNavAnimation();
      if (copyResponse) {
        this._dialogBoxService.showMessage("Trickle down save started for all the plans in the copy. Please come back later to see the values.");
        // this.stashDataAvailableByCopy = false;
      }
      else {
        this.selectedOptions.selectionViewOptions.copy.isLocked = false;
      }
    });
  }
  // #endregion dialogue box methods

  summaryViewWeeksChanged(summaryViewSelection: SummaryViewSelection) {
    this.summaryViewSelection = summaryViewSelection;

    this.updatePlanningViewLayoutSettings();
  }

  planningViewRenderComplete() {
    // update user config planning view layout settings, if it is not set
    this.previouslySelectedViewSeasonsTablesList = this.selectedViewSeasonsTablesList;
    this.updatePlanningViewLayoutSettings();
  }


  // onSelectionChange value is stored in kpiSelectionKey
  selectOptionChange(event: any) {
    if (event.isUserInput) {
      this.kpiSelectionKey = event.source.value;
      this.kpiSelection = event.source.selected ? true : false;
    }
  }

  // This Method handle the user selection from the KPI's List Dynamically.
  handleKpiSelection() {
    if (this.kpiSelectionKey !== "") {
      if (this.isAssortmentView) {
        switch (this.kpiSelectionKey) {
          case "Include Sales System Goals":
            this.areSalesSystemGoalsIncludedForAssortment = this.kpiSelection;
            if (this.areSalesSystemGoalsIncludedForAssortment) {
              if (!this.hasSalesSystemGoalInAssortmentLoaded) {
                this.renderSalesSystemGoalInAssortment();
                this.renderAggregatedSalesSystemGoalInAssortment();
              }
            }
            break;
          case "System Goal":
            this.isSystemGoalSelected = this.kpiSelection;
            if (this.isSystemGoalSelected) {
              this.availableViewKpiList = this.availableViewKpiListInitial;
            } else {
              this.availableViewKpiList = this.availableViewKpiList.filter(x => !x.startsWith('Include Sales System Goals'));
            }
            this.selectedViewKpis = this.selectedViewKpis.filter(x => !x.startsWith('Include Sales System Goals'));
            break;
        }
      }
      this.kpiSelectionKey = "";
      this.kpiSelection = false;
    }
  }
  updatePlanningViewLayoutSettings() {
    this.handleKpiSelection();

    let planningViewLayoutSettings: PlanningViewLayoutSettings = {
      graphSettings: {
        isGraphPinned: this.isChartPinned,
        isShowAllEnabled: this.showAllLegends,
        selectedLegendItems: []
      },
      seasonSettings: this.getPlanningViewSeasonSettings(),
      totalSeasonSettings: this.getPlanningViewTotalSeasonSettings(),
      summaryViewSettings:
      {
        startWeek: this.summaryViewSelection ? this.summaryViewSelection.startWeek : null,
        endWeek: this.summaryViewSelection ? this.summaryViewSelection.endWeek : null
      },
      toolbarSettings: {
        areRawValuesEnabled: this.seasonTableDebugMode,
        isGraphEnabled: this.isPlanningViewGraphVisible,
        visibleKPIs: this.selectedViewKpis,
        visibleSeasons: _.map(this.selectedViewSeasonsTablesList, "seasonDisplayName"),
        selectedViewDate: this.selectedDate
      },
      showTrickleDownDialog: this.userConfig.planningViewLayoutSettings && this._utilsService.isNotNullOrUndefined(this.userConfig.planningViewLayoutSettings.showTrickleDownDialog) ? this.userConfig.planningViewLayoutSettings.showTrickleDownDialog : true
    };

    this._userConfigService.setPlanningViewLayoutSettings(planningViewLayoutSettings);
  }

  getPlanningViewSeasonSettings(): SeasonSetting[] {

    // if total view is enabled, then return the season settings from the user config
    if (this.totalViewRequestsEnabled) {
      let userConfig = this._userConfigService.getUserConfig();
      let existingSeasonSettings = userConfig?.planningViewLayoutSettings?.seasonSettings;
      if (this._utilsService.isNotNullOrUndefined(existingSeasonSettings))
        return existingSeasonSettings;
    }

    let previousDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    let actualDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    let comingDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    let futureDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;

    if (this.isAggregatedDepartmentSeasonTablesRequested && this.isAggregatedMainViewEnabled) {
      previousDriver = this.aggregatedPreviousSeasonTableDataDriver;
      actualDriver = this.aggregatedActualSeasonTableDataDriver;
      comingDriver = this.aggregatedComingSeasonTableDataDriver;
      futureDriver = this.aggregatedFutureSeasonTableDataDriver;
    }
    else {
      previousDriver = this.previousSeasonTableDataDriver;
      actualDriver = this.actualSeasonTableDataDriver;
      comingDriver = this.comingSeasonTableDataDriver;
      futureDriver = this.futureSeasonTableDataDriver;
    }

    let drivers = [];
    drivers.push(previousDriver);
    drivers.push(actualDriver);
    drivers.push(comingDriver);
    drivers.push(futureDriver);

    let seasonSettings: SeasonSetting[] = []

    drivers.forEach(driver => {
      seasonSettings.push(
        {
          retrievalMode: driver.getRetrievalMode(),
          seasonPlanningType: driver.getSeasonInfo().seasonPlanningType,
        }
      )
    });

    return seasonSettings;
  }

  getPlanningViewTotalSeasonSettings(): SeasonSetting[] {

    // if total view is not enabled, then return the season settings from the user config
    if (!this.totalViewRequestsEnabled) {
      let userConfig = this._userConfigService.getUserConfig();
      let existingTotalSeasonSettings = userConfig?.planningViewLayoutSettings?.totalSeasonSettings;
      if (this._utilsService.isNotNullOrUndefined(existingTotalSeasonSettings))
        return existingTotalSeasonSettings;
    }

    let previousDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    let actualDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    let comingDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;
    let futureDriver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface;

    if (this.isAggregatedDepartmentSeasonTablesRequested && this.isAggregatedMainViewEnabled) {
      previousDriver = this.aggregatedPreviousSeasonTableDataDriver;
      actualDriver = this.aggregatedActualSeasonTableDataDriver;
      comingDriver = this.aggregatedComingSeasonTableDataDriver;
      futureDriver = this.aggregatedFutureSeasonTableDataDriver;
    }
    else {
      previousDriver = this.previousSeasonTableDataDriver;
      actualDriver = this.actualSeasonTableDataDriver;
      comingDriver = this.comingSeasonTableDataDriver;
      futureDriver = this.futureSeasonTableDataDriver;
    }

    let drivers = [];
    drivers.push(previousDriver);
    drivers.push(actualDriver);
    drivers.push(comingDriver);
    drivers.push(futureDriver);

    let seasonSettings: SeasonSetting[] = []

    drivers.forEach(driver => {
      seasonSettings.push(
        {
          retrievalMode: driver.getRetrievalMode(),
          seasonPlanningType: driver.getSeasonInfo().seasonPlanningType,
        }
      )
    });

    return seasonSettings;
  }

  keydownHandler(e) {
    // Undo/Redo not available for parent levels
    if (!this.isParentLevelSeasonTablesRequested && !this.isReadonlyDepartmentSeasonTablesRequested) {
      if (e.ctrlKey && e.code === "KeyZ") {

        e.preventDefault();
        // this line exists to remove focus from any actively typed cells.
        document.getElementById("btnUserSettingMenu").focus();

        this._planningViewActionHistoryService.undoLastAction();
      }

      if (e.ctrlKey && e.code === "KeyY") {

        e.preventDefault();
        // this line exists to remove focus from any actively typed cells.
        document.getElementById("btnUserSettingMenu").focus();

        this._planningViewActionHistoryService.redoLastAction();
      }
    }
  }

  updateSimulationView() {

    if (this.simulationViewUpdateSubject != null) {
      this.simulationViewUpdateSubject.next(true);
    }
  }

  preventUserFromNavigating() {
    if (this.selectedOptions?.selectionViewOptions?.copy.isLocked) {
      return false;
    }
    // check touched values in the tables
    if (this.isDepartmentLevelSeasonTablesRequested) {  // if tables havent been loaded yet
      if (this.previousDepartmentSeasonTableData == null) {
        return false;
      }
      return (this.previousDepartmentSeasonTableData.driver.touched ||
        this.actualDepartmentSeasonTableData.driver.touched ||
        this.comingDepartmentSeasonTableData.driver.touched ||
        this.futureDepartmentSeasonTableData.driver.touched)
    }
    else {
      if (this.previousParentSeasonTableData == null) {
        return false;
      }
      return (this.previousParentSeasonTableData.driver.touched ||
        this.actualParentSeasonTableData.driver.touched ||
        this.comingParentSeasonTableData.driver.touched ||
        this.futureParentSeasonTableData.driver.touched)
    }
  }

  updateViewLists(season: SeasonInfo) {
    // load values into the seasons dropdown
    let viewTable = {
      "seasonDisplayName": season.seasonCodeNames.join(','),
      "seasonType": season.seasonPlanningType,
      "seasonNumber": season.seasonCodeNames[0]
    };

    if (season.seasonPlanningType == SeasonPlanningType.Previous || season.seasonPlanningType == SeasonPlanningType.PreviousWithOld) {
      if (!this.selectedOptions.planningViewOptions.areOldSeasonsExcluded) {
        viewTable.seasonDisplayName = "<=" + viewTable.seasonDisplayName.split(',')[0];
      }
      this.availableViewSeasonsList.unshift(viewTable);
      this.checkAndSelectViewSeason(viewTable)
    }
    else {
      this.availableViewSeasonsList.push(viewTable);
      this.checkAndSelectViewSeason(viewTable)
    }

    // sort by season number
    this.availableViewSeasonsList.sort((a, b) => a.seasonNumber - b.seasonNumber);
  }

  checkAndSelectViewSeason(viewTable) {

    // check if seasons list is saved previously
    if (this.userConfig?.planningViewLayoutSettings?.toolbarSettings?.visibleSeasons) {
      // extract numbers
      let seasonDisplayName = viewTable.seasonDisplayName.replace(/[^0-9]/g, '');

      let seasonFound = false;

      // check for each saved season for a match
      this.userConfig.planningViewLayoutSettings.toolbarSettings.visibleSeasons.forEach(season => {
        if (season.replace(/[^0-9]/g, '') == seasonDisplayName) {
          seasonFound = true;
        }
      });

      if (seasonFound) {
        this.selectedViewSeasonsTablesList.push(viewTable);
      }
    }
    // if there is no saved season list, show everything
    else {
      this.selectedViewSeasonsTablesList.push(viewTable);
    }
  }

  async saveParentCopyData() {
    let departmentSaveItems: DepartmentSaveItem = {
      weekData: [],
      periodData: [],
      seasonData: []
    }

    this.isSaveButtonDisabled = true;
    //disablei input cells
    let prevDriverPreviousLockStatus = this.previousSeasonTableDataDriver.getInputLockStatus();
    let actualDriverPreviousLockStatus = this.actualSeasonTableDataDriver.getInputLockStatus();
    let comingDriverPreviousLockStatus = this.comingSeasonTableDataDriver.getInputLockStatus();
    let futureDriverPreviousLockStatus = this.futureSeasonTableDataDriver.getInputLockStatus();

    this.previousSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.actualSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.comingSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);
    this.futureSeasonTableDataDriver.setInputLockStatus(InputLockStatus.Locked);

    let previousSeasonSaveItem = this.previousParentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let actualSeasonSaveItem = this.actualParentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let comingSeasonSaveItem = this.comingParentSeasonTableData.driver.getItemsForSave(this.userConfig);
    let futureSeasonSaveItem = this.futureParentSeasonTableData.driver.getItemsForSave(this.userConfig);

    // merge week level save items
    departmentSaveItems.weekData = [...previousSeasonSaveItem.weekData, ...actualSeasonSaveItem.weekData, ...comingSeasonSaveItem.weekData, ...futureSeasonSaveItem.weekData];
    // merge period level save item
    // departmentSaveItems.periodData = [...previousSeasonSaveItem.periodData, ...actualSeasonSaveItem.periodData, ...comingSeasonSaveItem.periodData, ...futureSeasonSaveItem.periodData];
    // merge season level save item 
    departmentSaveItems.seasonData = [...previousSeasonSaveItem.seasonData, ...actualSeasonSaveItem.seasonData, ...comingSeasonSaveItem.seasonData, ...futureSeasonSaveItem.seasonData];

    this._loadingAnimationService.enableTopNavAnimation();

    let uniqueArray = departmentSaveItems.weekData.reduce((accumulator, current) => {
      if (!accumulator.find(item => item.weekId === current.weekId)) {
        accumulator.push(current.weekId);
      }
      return accumulator;
    }, []);

    if (this.isSalesView &&
      (this.selectedOptions.planningViewOptions.structureType == 'CustomerGroup' ||
        this.selectedOptions.planningViewOptions.structureType == 'Total') &&
      uniqueArray.length > 20) {
      let isTrickleDownSaveStarted = await this._planningViewService.saveTrickleDownByCopyStructureMarketData(departmentSaveItems, this.userConfig, false);

      if (isTrickleDownSaveStarted) {
        this.selectedOptions.selectionViewOptions.copy.isLocked = true;
        this._dialogBoxService.showMessage("It will take some more time for the copy to be saved. Please revert back later");
      }
      else {
        this.isSaveButtonDisabled = false;

        this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
        this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
        this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
        this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);

        this._dialogBoxService.showMessage("Sorry! Your copy could not be saved successfully. Try again later or contact IT helpdesk");
      }
      this._loadingAnimationService.disableTopNavAnimation();
    }
    else {
      // store the time when save was called
      let saveStartTime = new Date();
      let savedStatus = await this._planningViewService.saveCopyData(departmentSaveItems, this.userConfig, false);
      let saveEndTime = new Date();

      this._loadingAnimationService.disableTopNavAnimation();
      if (savedStatus) {

        // refresh Season Raw data items
        this.previousParentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);
        this.actualParentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);
        this.comingParentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);
        this.futureParentSeasonTableData.driver.refreshSeasonRawDataItem(this.userConfig);

        this.previousParentSeasonTableData.driver.touched = false;
        this.actualParentSeasonTableData.driver.touched = false;
        this.comingParentSeasonTableData.driver.touched = false;
        this.futureParentSeasonTableData.driver.touched = false;

        // reset the merge copy button visibity to true
        this.isMergeButtonDisabled = false;
        this._planningViewActionHistoryService.clearHistory();

        this.isSaveButtonDisabled = false;

        this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
        this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
        this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
        this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);

        this._dialogBoxService.showMessage("Your copy has been saved successfully.");

        this.stashedData = null;
        this.stashedDataExists = false;

        if (this.userConfig.planningViewOptions.channel == ChannelType.Online) {
          // reload view
          this.renderPlanningTables();
        }
        await this.getCopyLockStatus();
        await this.getStashStatusByCopy();
      }

      else {

        // check if save failed after 210 seconds. If so, we assume the failure to be because of APIM timeout limit.
        // We ask the user to wait and return after sometime
        let timeDiff = saveEndTime.getTime() - saveStartTime.getTime(); //in ms
        // strip the ms
        timeDiff /= 1000;
        // get seconds 
        var seconds = Math.round(timeDiff);
        if (seconds >= 210) {
          this.selectedOptions.selectionViewOptions.copy.isLocked = true;
          this._dialogBoxService.showMessage("It will take some more time for the copy to be saved. Please revert back later");
        }
        else {
          this.isSaveButtonDisabled = false;

          this.previousSeasonTableDataDriver.setInputLockStatus(prevDriverPreviousLockStatus);
          this.actualSeasonTableDataDriver.setInputLockStatus(actualDriverPreviousLockStatus);
          this.comingSeasonTableDataDriver.setInputLockStatus(comingDriverPreviousLockStatus);
          this.futureSeasonTableDataDriver.setInputLockStatus(futureDriverPreviousLockStatus);

          this._dialogBoxService.showMessage("Sorry! Your copy could not be saved successfully. Try again later or contact IT helpdesk");
        }
      }
    }


  }

  async exportToExcel() {
    let exportData: any[] = [];

    //if assortment, aggragted view requested and aggregated view enabled then take sum data
    if (this.isAssortmentView && this.isAggregatedDepartmentSeasonTablesRequested && this.isAggregatedMainViewEnabled) {
      exportData.push({
        seasonType: this.aggregatedDepartmentPreviousSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
        seasonData: this.aggregatedDepartmentPreviousSeasonTableData.driver.getDataSet()
      });

      exportData.push({
        seasonType: this.aggregatedDepartmentActualSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
        seasonData: this.aggregatedDepartmentActualSeasonTableData.driver.getDataSet()
      });

      exportData.push({
        seasonType: this.aggregatedDepartmentComingSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
        seasonData: this.aggregatedDepartmentComingSeasonTableData.driver.getDataSet()
      });

      exportData.push({
        seasonType: this.aggregatedDepartmentFutureSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
        seasonData: this.aggregatedDepartmentFutureSeasonTableData.driver.getDataSet()
      })

      exportData.push({
        seasonType: this.aggregatedDepartmentTotalSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
        seasonData: this.aggregatedDepartmentTotalSeasonTableData.driver.getDataSet()
      })
    }
    else {
      if (this.isParentLevelSeasonTablesRequested) {
        exportData.push({
          seasonType: this.previousParentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.previousParentSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.actualParentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.actualParentSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.comingParentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.comingParentSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.futureParentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.futureParentSeasonTableData.driver.getDataSet()
        })

        exportData.push({
          seasonType: this.totalParentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.totalParentSeasonTableData.driver.getDataSet()
        })
      }

      else if (this.isReadonlyDepartmentSeasonTablesRequested) {
        exportData.push({
          seasonType: this.readonlyDepartmentPreviousSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.readonlyDepartmentPreviousSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.readonlyDepartmentActualSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.readonlyDepartmentActualSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.readonlyDepartmentComingSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.readonlyDepartmentComingSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.readonlyDepartmentFutureSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.readonlyDepartmentFutureSeasonTableData.driver.getDataSet()
        })

        exportData.push({
          seasonType: this.readonlyDepartmentTotalSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.readonlyDepartmentTotalSeasonTableData.driver.getDataSet()
        })
      }
      else {
        exportData.push({
          seasonType: this.previousDepartmentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.previousDepartmentSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.actualDepartmentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.actualDepartmentSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.comingDepartmentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.comingDepartmentSeasonTableData.driver.getDataSet()
        });

        exportData.push({
          seasonType: this.futureDepartmentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.futureDepartmentSeasonTableData.driver.getDataSet()
        })

        exportData.push({
          seasonType: this.totalDepartmentSeasonTableData.driver.getSeasonInfo().seasonPlanningType,
          seasonData: this.totalDepartmentSeasonTableData.driver.getDataSet()
        })
      }
    }



    let settings = {
      fileName: `Season_Data_${this.selectedOptions.selectionViewOptions.copy.name}_${this.selectedOptions.planningViewOptions.viewDate.toDateString()}`,
      fileType: FileType.XLSX,
      fullData: exportData,
      headerIndex: 0,
      freezeY: 1,
      // freezeX: this.corporateBrand.brandId === Constants.BRAND_ID_HM ? 14 : 13,
    };

    this._exportService.exportData(settings);


  }

  changeToggleSimOrSum(event) {
    this.isAggregatedMainViewEnabled = event.checked;
    if (this.aggregatedTotalSeasonTableRendered && this.isAggregatedMainViewEnabled)
      this.refreshGraphData();
    else if (!this.isAggregatedMainViewEnabled && this.totalSeasonTableRendered)
      this.refreshGraphData();
  }

  unlockDriverInputs(driver: CalculatorDriverInterface | ParentCalculatorDriverInterface | ReadonlyDepartmentCalculatorDriverInterface) {
    //we dont need to apply the saved lockstatus to the table, we can do it later since it has issues in the parent level
    // init lock status if stored in cache
    // let userConfig = this._userConfigService.getUserConfig();
    // if (userConfig && userConfig.planningViewLayoutSettings && userConfig.planningViewLayoutSettings.seasonSettings) {
    //   let seasonSetting = userConfig.planningViewLayoutSettings.seasonSettings.find(x => x.seasonPlanningType == driver.getSeasonInfo().seasonPlanningType);

    //   if (this._utilsService.isNotNullOrUndefined(seasonSetting) && this._utilsService.isNotNullOrUndefined(seasonSetting.inputLockStatus)) {
    //     let inputLockStatus = this._utilsService.enumFromValue(seasonSetting.inputLockStatus, InputLockStatus);
    //     driver.setInputLockStatus(inputLockStatus);
    //     return;
    //   }
    // }
    if (this._utilsService.isNotNullOrUndefined(driver) && !this.selectedOptions.selectionViewOptions?.copy.isLocked) {
      driver.setInputLockStatus(InputLockStatus.Unlocked);
    }
  }

  resetPlanningViewSettings() {

    let userConfig = this._userConfigService.getUserConfig();
    if (userConfig && userConfig.planningViewLayoutSettings) {

      let defaultPlanningViewLayoutSettings: PlanningViewLayoutSettings = {
        showTrickleDownDialog: true,
        graphSettings: {
          isGraphPinned: false,
          isShowAllEnabled: true,
          selectedLegendItems: []
        },
        seasonSettings: [
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.PreviousWithOld, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.PreviousWithOld,
            inputLockStatus: InputLockStatus.Unlocked
          },
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.Actual, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.Actual,
            inputLockStatus: InputLockStatus.Unlocked
          },
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.Coming, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.Coming,
            inputLockStatus: InputLockStatus.Unlocked
          },
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.Future, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.Future,
            inputLockStatus: InputLockStatus.Unlocked
          }
        ],
        totalSeasonSettings: [
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.PreviousWithOld, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.PreviousWithOld,
            inputLockStatus: InputLockStatus.Unlocked
          },
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.Actual, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.Actual,
            inputLockStatus: InputLockStatus.Unlocked
          },
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.Coming, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.Coming,
            inputLockStatus: InputLockStatus.Unlocked
          },
          {
            retrievalMode: this._utilsService.getDefaultRetrievalModeBySeasonPlanningType(SeasonPlanningType.Future, this.isAssortmentView),
            seasonPlanningType: SeasonPlanningType.Future,
            inputLockStatus: InputLockStatus.Unlocked
          }
        ],
        summaryViewSettings: { startWeek: null, endWeek: null },
        toolbarSettings: {
          areRawValuesEnabled: false,
          isGraphEnabled: true,
          visibleKPIs: this.availableViewKpiList,
          selectedViewDate: null,
          visibleSeasons: _.map(this.availableViewSeasonsList, "seasonDisplayName")
        }
      };
      this.selectedViewSeasonsTablesList = this.availableViewSeasonsList;
      this._userConfigService.setPlanningViewLayoutSettings(defaultPlanningViewLayoutSettings);
    }
  }

  async fetchTotalViewRequestData() {
    this.totalViewRequestsEnabled = true;
    this.totalViewRequestData = await this._planningViewService.getTotalViewData(this.selectedOptions);

    this.totalViewRequestStatus = "-";
    this.totalViewRequestedAt = "-";
    this.totalViewProcessedAt = "-";
    this.totalViewDefaultRetrievalmode = this.totalViewRequestData.defaultRetrievalMode;
    if (this.totalViewRequestData.recentTotalViewRequest) {
      this.totalViewRequestStatus = TotalViewRequestStatus[this.totalViewRequestData.recentTotalViewRequest.status];
      this.totalViewRequestedAt = this.datepipe.transform(new Date(this.totalViewRequestData.recentTotalViewRequest.requestedTime + "Z"), 'MMM dd yyyy, HH:mm:ss');
    }

    if (this.totalViewRequestData.lastProcessedResponse) {
      // call render after a 100 ms pause
      this.totalPlanningViewResponseLoading = true;
      this.organizationSummaryData = this.totalViewRequestData.lastProcessedResponse.processedData.organizationSummaryChildResponse;
      this.totalViewProcessedAt = this.datepipe.transform(new Date(this.totalViewRequestData.lastProcessedResponse.processedTime + "Z"), 'MMM dd yyyy, HH:mm:ss');
    }

    this.generateRequestLoading = false;
  }

  async generateTotalViewRequest() {
    this.generateRequestLoading = true;
    this._loadingAnimationService.enableTopNavAnimation();
    try {
      await this._planningViewService.createNewTotalViewRequest(this.selectedOptions);
      //await this.fetchTotalViewRequestData();
      this._dialogBoxService.showMessage("Total view generation request sent successfully");
      this.totalViewRequestStatus = TotalViewRequestStatus[TotalViewRequestStatus.Requested].toString();
    }
    catch
    {
      this._dialogBoxService.showMessage("There was an error creating a total view generation request. Please try again or contact helpdesk");
      this.generateRequestLoading = false;
    }
    finally {
      this._loadingAnimationService.disableTopNavAnimation();
    }


  }

  // filter recursively on a text string using property object value
  filterRecursive(filterText: string, array: any[], property: string) {
    let filteredData;

    //make a copy of the data so we don't mutate the original
    function copy(o: any) {
      return Object.assign({}, o);
    }

    // has string
    if (filterText) {
      // need the string to match the property value
      filterText = filterText.toLowerCase();
      // copy obj so we don't mutate it and filter
      filteredData = array.map(copy).filter(function x(y) {
        if (y[property].toLowerCase().includes(filterText)) {
          return true;
        }
        // if children match
        if (y.children) {
          return (y.children = y.children.map(copy).filter(x)).length;
        }
      });
      // no string, return whole array
    } else {
      filteredData = array;
    }

    return filteredData;
  }

  // pass mat input string to recursive function and return data
  filterTree(filterText: string) {

    let treeData = this.selectedOptions.selectionViewOptions.organization.name == OrganizationType.Assortment ? this.assortmentResponseData.treeData : this.salesResponseData.treeData;
    let filteredData = this.filterRecursive(filterText, treeData, 'name');

    this.structureTreeData.data = filteredData;
    this.treeControl.dataNodes = filteredData;
  }

  // filter string from mat input filter
  applyFilter(filterText: string) {
    this.filterTree(filterText);
    // show / hide based on state of filter string
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
      let currentNode = this.structureTreeData.data[0];

      this.treeControl.expand(currentNode);
    }
  }

  async setLatestCubeRefreshTime(): Promise<void> {
    let cubeInfoResponse: CubeInfoResponse = null;
    try {
      cubeInfoResponse = await this._cubeInfoService.getCubeInformation();
      this.latestDataRefreshTime = cubeInfoResponse.latestRefreshedTime;
    }
    catch {
      this.latestDataRefreshTime = "Error in fetching Latest Data refreshed Time";
    }

    this.isLatestDataRefreshTimeRendered = true;
  }
}

