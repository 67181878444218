import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class RAndDForecastPercentCalculator extends AbstractCalculator
{
    // Dependencies
    // ------------
    // Effective Sales Plan Weekly
    // R And D Plan
    // R And PercentPeriodic

    // Calculates
    // ----------
    // Ground R And D Percent Weekly
    // Ground R And D Percent Periodic


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem)
    {
        currentWeekItem[CalculationDataItemType.RAndDForecastPercentWeekly] = currentPeriodWeekItems[0][CalculationDataItemType.RAndDForecastPercentPeriodic];
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[])
    {
        // Average R&D% Ground = (Sum of R&D% for period/ Sum of Effective Sales Plan for period)
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        const effectiveSalesPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.EffectiveSalesPlanWeeklyWithGround);
        const rAndDPlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.RAndDPlan);

        if (utils.isNotNullOrUndefined(effectiveSalesPlanSum)) {
            if (effectiveSalesPlanSum != 0) {
                // removed rounding off
                //value = Math.round((rAndDPlanSum / effectiveSalesPlanSum) * 100);
                value = utils.precisionRound((rAndDPlanSum / effectiveSalesPlanSum) * 100, 2);
            }
            else {
                value = 0;
            }        
        }

        currentPeriodWeekItems.forEach((periodWeekItem:DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.RAndDForecastPercentPeriodic] = value;
        });
    }
}
