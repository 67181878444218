import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { CalculatorDriverInterface } from '../../shared/calculators/calculator-driver-interface';
import { ParentCalculatorDriverInterface } from '../../shared/calculators/parent-calculator-driver-interface';
import { DepartmentEvent } from '../../shared/events/department-events/department-event';
import { UtilsService } from '../../shared/services/utils.service';
import { PlanningViewActionHistoryItem } from '../types/planning-view-action-history-item';

@Injectable({
    providedIn: 'root'
})
export class PlanningViewActionHistoryService {

    actionHistoryItems: PlanningViewActionHistoryItem[] = [];
    undoneActionHistoryItems: PlanningViewActionHistoryItem[] = [];

    constructor(private _utilsService: UtilsService) { }

    clearHistory() {
        this.actionHistoryItems = [];
        this.undoneActionHistoryItems = [];
    }

    isUndoAvailable(): boolean {
        return this.actionHistoryItems.length != 0;
    }

    isRedoAvailable(): boolean {
        return this.undoneActionHistoryItems.length != 0;
    }

    addActionEvent(event: DepartmentEvent, driver: CalculatorDriverInterface| ParentCalculatorDriverInterface) {
        this.actionHistoryItems.push(
            {
                driver: driver,
                event: event
            }
        );
    }

    getActionHistory(): PlanningViewActionHistoryItem[] {
        return this.actionHistoryItems;

    }


    undoLastAction() {
        let lastEvent = this.actionHistoryItems.pop();

        if (this._utilsService.isNotNullOrUndefined(lastEvent)) {

            let undoAction: PlanningViewActionHistoryItem = {
                driver: lastEvent.driver,
                event: _.cloneDeep(lastEvent.event)
            }

            let temp = undoAction.event.eventInfo.newValue;
            undoAction.event.eventInfo.newValue = undoAction.event.eventInfo.previousValue == null ? "" : undoAction.event.eventInfo.previousValue;
            undoAction.event.eventInfo.previousValue = temp;
            undoAction.event.undoEvent = true;
            undoAction.driver.handleEvent(undoAction.event);

            this.undoneActionHistoryItems.push(undoAction);

            this._utilsService.showGeneralMessage("Undo");
        }
        else {
            this._utilsService.showGeneralMessage("No more actions to undo");
        }
    }

    redoLastAction() {
        let lastEvent = this.undoneActionHistoryItems.pop();

        if (this._utilsService.isNotNullOrUndefined(lastEvent)) {
            let redoAction: PlanningViewActionHistoryItem =
            {
                driver: lastEvent.driver,
                event: _.cloneDeep(lastEvent.event)
            }

            let temp = redoAction.event.eventInfo.newValue;
            redoAction.event.eventInfo.newValue = redoAction.event.eventInfo.previousValue;
            redoAction.event.eventInfo.previousValue = temp;
            redoAction.event.undoEvent = false;
            redoAction.driver.handleEvent(redoAction.event);

            this._utilsService.showGeneralMessage("Redo");
        }
        else {
            this._utilsService.showGeneralMessage("No more actions to redo");
        }
    }
}