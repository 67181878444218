import { NgModule } from '@angular/core';
import { Routes, RouterModule,InitialNavigation } from '@angular/router';
import { BrowserUtils } from '@azure/msal-browser';

const routes: Routes = [  
  { 
    path: '**',
    redirectTo: 'selection-view', 
    pathMatch: 'full'
  },
];

const enabled: InitialNavigation = 'enabledBlocking';
const disabled: InitialNavigation = 'disabled';

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // Don't perform initial navigation in iframes or popups
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? enabled : disabled // Remove this line to use Angular Universal
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
