import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class AggregatedRAndDPercentCalculator extends AbstractCalculator {

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let salesSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.AggregatedPMsEffectiveSalesPlanWeekly);
        //to handle the null R and D plan
        let randdvalue = 0;
        let isNotNull = false;
        currentPeriodWeekItems.forEach((periodDataItem: DepartmentCalculationDataItem) => {
            if (utils.isNotNullUndefinedOrZero(periodDataItem[CalculationDataItemType.AggregatedPMsRAndDPlan])) {
                isNotNull = true;
                randdvalue += periodDataItem[CalculationDataItemType.AggregatedPMsRAndDPlan];
            }
        });
        let rAndDsum = (isNotNull) ? randdvalue : null;

        // added the condition same as backend calculation, if randd sum is null, then value should be null, of sale plam is null or zero, then value is null
        if (utils.isNullOrUndefined(rAndDsum) || !utils.isNotNullUndefinedOrZero(salesSum)) {
            value = null;
        }
        else if (utils.isNotNullUndefinedOrZero(salesSum)) {
            value = (rAndDsum / salesSum) * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.AggregatedPMsRAndDPercentPeriodic] = value;
        });
    }
}