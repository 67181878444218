import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/modules/shared/types/api-routes';
import { environment } from 'src/environments/environment';
import { UtilsService } from '../../shared/services/utils.service';
import { CorporateBrand } from '../../shared/types/corporate-brand';
import { CustomerGroup } from '../../shared/types/customer-group';
import { Market } from '../../shared/types/market';
import { MarketType } from '../../shared/types/market-type';
import { Section } from '../../shared/types/section';
import { StructureTypes } from '../../shared/types/structure-types';
import { RegionMapping, SelectionPageResponse } from '../types/api/selection-page-response';
import { StructureType } from '../types/structure-type';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class SelectionPageService {

    private url = environment.baseApiUrl + ApiRoutes.selectionPage;


    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient, private _utilsService: UtilsService,) { }

    getSelectionPageData(): Observable<SelectionPageResponse> {
        return this.http.get<SelectionPageResponse>(this.url, this.httpOptions);
    }

    getAssortmentStructuresForBrand(selectionPageResponseData: SelectionPageResponse, selectedBrand: CorporateBrand): StructureType[] {
        // for non H&M, return everything except 'CustomerGroup-Division-Section-Department'
        if (selectedBrand.id != 0) {
            return selectionPageResponseData.assortment.structureTypes.filter((structureType: StructureType) => {
                return !structureType.name.startsWith(StructureTypes.CustomerGroup)
            });
        }
        else {
            // for H&M return everything except 'Brand-Division-Section-Department'
            return selectionPageResponseData.assortment.structureTypes.filter((structureType: StructureType) => {
                return !structureType.name.startsWith(StructureTypes.Brand)
            });
        }
    }

    getAssortmentStructureItemsForStructureType(selectionPageResponseData: SelectionPageResponse, selectedBrand: CorporateBrand, selectedStructureTypeItem: StructureType): Section[] | CustomerGroup[] | CorporateBrand[] {
        if (selectedStructureTypeItem.name.startsWith(StructureTypes.CustomerGroup)) {
            return selectionPageResponseData.assortment.customerGroups.filter((customerGroup: CustomerGroup) => {
                return customerGroup.corporateBrandId == selectedBrand.id
            });
        }
        else if (selectedStructureTypeItem.name.startsWith(StructureTypes.Section)) {
            return selectionPageResponseData.assortment.sections.filter((section: Section) => {
                return section.corporateBrandId == selectedBrand.id
            });
        }
        else if (selectedStructureTypeItem.name.startsWith(StructureTypes.Brand)) {
            return [selectedBrand];
        }
    }

    getSalesStructuresForBrand(selectionPageResponseData: SelectionPageResponse, selectedBrand: CorporateBrand): StructureType[] {
        // for non H&M, return everything except 'CustomerGroup-Division-Section-Department'
        if (selectedBrand.id != 0) {
            return selectionPageResponseData.sales.structureTypes.filter((structureType: StructureType) => {
                return !structureType.name.startsWith(StructureTypes.CustomerGroup)
            });
        }
        else {
            // for H&M return everything except 'Brand-Division-Section-Department'
            return selectionPageResponseData.sales.structureTypes.filter((structureType: StructureType) => {
                return structureType.name.startsWith(StructureTypes.CustomerGroup)
            });
        }
    }

    getSalesMarketsForBrand(selectionPageResponseData: SelectionPageResponse, selectedBrand: CorporateBrand, includePercentPms: boolean): Market[] {

        //we have to show regions, pms, percentpms, omni region except prpm

        let filteredMarkets = selectionPageResponseData.sales.markets.filter((market: Market) => {
            if (!includePercentPms) {
                return market.corporateBrandId == selectedBrand.id && market.marketType != MarketType.PercentPlanningMarket;
            }
            else {
                return market.corporateBrandId == selectedBrand.id
            }
        });

        // planning markets are to be shown below other markets
        let pms = filteredMarkets.filter((market: Market) => {
            return market.marketType == MarketType.PlanningMarket;
        });

        let nonPms = filteredMarkets.filter((market: Market) => {
            return market.marketType != MarketType.PlanningMarket;
        });

        let availableMarkets = nonPms.concat(pms);
        //If there is any PlanningRegionPlanningMarket included, then find the region instead and replace prpm
        //add check
        let planningRegionPlanningMarkets = availableMarkets.filter((market: Market) => {
            return market.marketType == MarketType.PlanningRegionPlanningMarket
        })

        if (planningRegionPlanningMarkets.length > 0) {
            // find region from the regon mappings, the pm values are lies in child markets
           let regions = this.getRegionByPRPM(planningRegionPlanningMarkets, selectionPageResponseData.sales.regionMappings);
           if(regions.length > 0 )
           {
            //remove pmpr from the available market
            let filteredMarkets = availableMarkets.filter((market: Market) =>  market.marketType != MarketType.PlanningRegionPlanningMarket);

            // insert regions without duplication
            //availableMarkets=  filteredMarkets.concat(regions).filter((v, i, a) => a.indexOf(v) === i);
            let nonDuplicatedRegions = regions.filter(region=>(filteredMarkets.findIndex(x=>x.shortName==region.shortName) == -1));
            nonDuplicatedRegions = _.uniqBy(nonDuplicatedRegions, "shortName");
            availableMarkets = filteredMarkets.concat(nonDuplicatedRegions);
           }
        }

        // sort by regions and non regions        
        let regions = availableMarkets.filter(market=>market.marketType==MarketType.PlanningRegion);
        regions = _.orderBy(regions, "sortOrder");
        let nonRegions = availableMarkets.filter(market=>market.marketType!=MarketType.PlanningRegion);
        nonRegions = _.orderBy(nonRegions, "sortOrder");

        availableMarkets = regions.concat(nonRegions);
        return availableMarkets;
    }



    getRegionByPRPM(planningRegionPlanningMarkets: Market[], regionMappings: RegionMapping[]) : Market[]{
        let regions: Market[] = [];

        planningRegionPlanningMarkets.forEach((prpm: Market) => {
            regionMappings.forEach(regionMapping => {
                let childMarkert = regionMapping.childMarkets.find((market: Market) => {
                    return market.marketIntegrationKey == prpm.marketIntegrationKey
                });
                if (this._utilsService.isNotNullOrUndefined(childMarkert))
                regions.push(regionMapping.region)
            });
        });
        return regions;
    }
}

