<div>
    <h2 mat-dialog-title>Publish</h2>
    <hr>
    <mat-dialog-content style="max-height: 70vh; overflow-y: auto;">
        <div style="color:blue" *ngIf="this.publishDisabledSeasonValues.length>0"> Note: {{responseMessage}}</div>
        <div *ngIf="this.publishDialogData != null" class="row">
            <div class="col-md-12">
                <table class="table table-striped table-bordered" id="publish-dialog-season-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Select</th>
                            <th>Season</th>
                            <th>Last Published Time</th>
                            <th>Last Published By</th>
                            <th>Last Publish Status</th>
                            <th>History</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let seasonStatusData of publishStatusDataItems; let dataIndex=index;">
                            <tr>
                                <td>{{dataIndex+1}}</td>
                                <td>
                                    <mat-checkbox [disabled]="!seasonStatusData.selectionEnabled" [(ngModel)]="seasonStatusData.selected" color="primary">
                                    </mat-checkbox>
                                </td>
                                <td>
                                    {{seasonStatusData?.seasonName}}
                                </td>
                                <td>
                                    {{seasonStatusData?.lastPublishedTime}}
                                </td>
                                <td>
                                    {{seasonStatusData?.lastPublishedBy}}
                                </td>
                                <td>
                                    {{seasonStatusData?.lastPublishedStatus}}
                                </td>
                                <td>
                                    <button mat-raised-button color="primary" *ngIf="seasonStatusData.viewHistoryEnabled" (click)="onViewHistoryClick(seasonStatusData?.assortmentPublishId)">{{ historyTableId == seasonStatusData?.assortmentPublishId ? "Hide History": "View History"}}</button>
                                    <button mat-raised-button color="primary" *ngIf="!seasonStatusData.viewHistoryEnabled" disabled>View History</button>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>

        <div *ngIf="historyTableId!=null" class="row d-flex justify-content-center">
            <div class="col-md-8">
                <div style="text-align: center">
                    <h3>History</h3>
                </div>                
                <hr>
                <table class="table table-bordered" id="publish-dialog-season-history-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Action</th>
                            <th>Time</th>
                            <th>Comments</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let seasonPublishHistoryData of publishHistoryDataItems; let dataIndex=index;">
                            <tr>
                                <td>{{dataIndex+1}}</td>
                                <td>{{seasonPublishHistoryData?.status}}</td>
                                <td>{{seasonPublishHistoryData?.time}}</td>
                                <td>{{seasonPublishHistoryData?.comments}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>            
        </div>
        

        <div class="row" style="margin: 0 auto; float: right;">
            <mat-dialog-actions>
                <button type="submit" mat-raised-button color="primary" [disabled]="isPublishDisabled()" (click)="publishSeason()">Publish</button>
                <button mat-raised-button mat-dialog-close>Cancel</button>
            </mat-dialog-actions>
        </div>
    </mat-dialog-content>
</div>