<h2>Impersonate User</h2>
<hr>

<ng-container *ngIf="userInfo!=null">
    <div class="col-md-6">
        <div class="form-group">
            <form class="example-form" [formGroup]="impersonateUserForm" (ngSubmit)="onFormSubmit()">
                <mat-form-field class="form-field" appearance="fill">
                    <mat-label>Email</mat-label>
                    <input formControlName="Email" matInput placeholder="Email">
                </mat-form-field>
                <mat-error>
                    <span
                        *ngIf="!impersonateUserForm.get('Email').value && impersonateUserForm.get('Email').touched"></span>
                    <p *ngIf="isEmailInvalid">Please enter a valid email id</p>
                </mat-error>
                <div class="impersonatebtndiv">
                    <button type="submit" mat-raised-button color="primary" 
                        [disabled]="!impersonateUserForm.valid">Impersonate</button>
                    <button mat-raised-button color="warn" 
                        (click)='stopImpersonating()' [disabled]="!enableStopImpersonation">Stop
                        Impersonating</button>
                </div>
            </form>
        </div>
    </div>
</ng-container>