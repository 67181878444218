import { Component, Inject } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogBoxService } from 'src/app/modules/shared/services/dialog-box-service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { Constants } from 'src/app/modules/shared/types/constants';
import { Market } from 'src/app/modules/shared/types/market';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { CopyService } from '../../services/copy.service';
import { CreateAssortmentCopyRequest } from '../../types/api/copy/create-copies/request/create-assortment-copy-request copy';
import { CreateSalesCopyRequest } from '../../types/api/copy/create-copies/request/create-sales-copy-request';
import { EditCopyRequest } from '../../types/api/copy/edit-copies/edit-copy-request';
import { AssortmentCopy } from '../../types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from '../../types/api/copy/get-all-copies/response/sales-copy';
import { AssortmentCreateCopyDialogData, AssortmentEditCopyDialogData, CopyDialogData, SalesCreateCopyDialogData, SalesEditCopyDialogData } from '../../types/copy-dialog-data';
import { MergeCopyDialogData } from '../../types/merge-copy-dialog-data';
import { CopyComponent } from '../copy/copy.component';

@Component({
  selector: 'omnitdp-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.css']
})
export class CopyDialogBoxComponent {


  copyDialogType: string;
  assortmentCreateCopyDataItem: AssortmentCreateCopyDialogData = null;
  assortmentEditCopyDataItem: AssortmentEditCopyDialogData = null;
  assortmentCopyForm: any;
  salesCopyForm: any;
  errorMessage: string;
  isErrorOccured: boolean = true;
  deleteButtonEnabled: boolean = false;
  cancelButtonDisabled: boolean = false;
  mergeButtonEnabled: boolean = false;

  selectedMarket: Market;

  salesCreateCopyDataItem: SalesCreateCopyDialogData = null;
  salesEditCopyDataItem: SalesEditCopyDialogData = null;

  assortmentCopyData: AssortmentCopy = null;
  salesCopyData: SalesCopy = null;

  copyNamePattern: string = "^(.*?(\\bmain\\b)[^$]*)$";

  brandstructurename: string = Constants.BRAND_STRUCTURE_NAME;

  constructor(
    private _formbulider: UntypedFormBuilder,
    private _copyService: CopyService,
    private _loadingAnimationService: LoadingAnimationsService,
    private _dialogBoxService: DialogBoxService,
    public copyParentDialogRef: MatDialogRef<CopyComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: CopyDialogData | MergeCopyDialogData) {
    if (dialogData) {
      if (dialogData.copyDialogType == "assortment-create-copy") {
        this.assortmentCreateCopyDataItem = <AssortmentCreateCopyDialogData>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "assortment-edit-copy") {
        this.assortmentEditCopyDataItem = <AssortmentEditCopyDialogData>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "assortment-delete-copy") {
        this.assortmentEditCopyDataItem = <AssortmentEditCopyDialogData>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "sales-create-copy") {
        this.salesCreateCopyDataItem = <SalesCreateCopyDialogData>dialogData.copyDialogDataItem;
        this.selectedMarket = this.salesCreateCopyDataItem.salesCopyDialogMarkets[0];
      }
      else if (dialogData.copyDialogType == "sales-edit-copy") {
        this.salesEditCopyDataItem = <SalesEditCopyDialogData>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "sales-delete-copy") {
        this.salesEditCopyDataItem = <SalesEditCopyDialogData>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "assortment-merge-copy") {
        this.assortmentCopyData = <AssortmentCopy>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "sales-merge-copy") {
        this.salesCopyData = <SalesCopy>dialogData.copyDialogDataItem;
      }
      else if (dialogData.copyDialogType == "sales-parent-trickle-down-save-by-copy"){
        this.salesCopyData = <SalesCopy>dialogData.copyDialogDataItem;
      }
    }
  }

  ngOnInit(): void {


    this.assortmentCopyForm = this._formbulider.group({
      name: ['', [Validators.required, Validators.maxLength(25)]],
    });

    this.salesCopyForm = this._formbulider.group({
      name: ['', [Validators.required, Validators.maxLength(25)]],
    });

    if (this.assortmentEditCopyDataItem)
      this.loadAssortmentCopyToEdit(this.assortmentEditCopyDataItem.copy)
    if (this.salesEditCopyDataItem)
      this.loadSalesCopyToEdit(this.salesEditCopyDataItem.copy)
  }

  onAssortmentFormSubmit() {
    this.assortmentCopyForm.status = 'INVALID'; // to disable the button while submitting the form
    this.cancelButtonDisabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this.isErrorOccured = false;
    const assortmentCopy = this.assortmentCopyForm.value;

    var regEx = new RegExp(this.copyNamePattern, "g");

    if (regEx.test(assortmentCopy.name.toLowerCase())) {
      this.isErrorOccured = true;
      this.assortmentCopyForm.controls['name'].setValue(assortmentCopy.name);
      this._loadingAnimationService.disableTopNavAnimation();
      this.assortmentCopyForm.status = 'VALID';
      this.cancelButtonDisabled = false;
      this.errorMessage = 'Please choose a different copy name';
    }
    else {
      this.assortmentEditCopyDataItem ? this.editAssortmentCopy(assortmentCopy) : this.createAssortmentCopy(assortmentCopy)
    }


  }

  onSalesFormSubmit() {
    this.salesCopyForm.status = 'INVALID'; // to disable the button while submitting the form
    this.cancelButtonDisabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this.isErrorOccured = false;
    const salesCopy = this.salesCopyForm.value;

    var regEx = new RegExp(this.copyNamePattern, "g");
    if (regEx.test(salesCopy.name.toLowerCase())) {
      this.isErrorOccured = true;
      this.salesCopyForm.controls['name'].setValue(salesCopy.name);
      this._loadingAnimationService.disableTopNavAnimation();
      this.salesCopyForm.status = 'VALID';
      this.cancelButtonDisabled = false;
      this.errorMessage = 'Please choose a different copy name';
    }
    else {
      this.salesEditCopyDataItem ? this.editSalesCopy(salesCopy) : this.createSalesCopy(salesCopy)
    }


  }

  loadAssortmentCopyToEdit(assortmentCopy: AssortmentCopy) {
    this.assortmentCopyForm.controls['name'].setValue(assortmentCopy.name);
  }

  loadSalesCopyToEdit(salesCopy: SalesCopy) {
    this.salesCopyForm.controls['name'].setValue(salesCopy.name);
  }

  createAssortmentCopy(copy: CreateAssortmentCopyRequest) {
    copy.brandId = this.assortmentCreateCopyDataItem.brand.id;
    copy.structureId = this.assortmentCreateCopyDataItem.structure.id;
    copy.StructureTypeId = this.assortmentCreateCopyDataItem.structureType.id;
    this._copyService.createAssortmentCopy(copy).subscribe(
      (copyData) => {
        if (copyData) {
          this.assortmentCopyForm.status = 'VALID';
          this.cancelButtonDisabled = false;
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
      },
      (error) => {
        this.assortmentCopyForm.status = 'VALID';
        this.cancelButtonDisabled = false;
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this.errorMessage = error.error;
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  editAssortmentCopy(copy: EditCopyRequest) {
    copy.copyId = this.assortmentEditCopyDataItem.copy.assortmentCopyId;
    this._copyService.editAssortmentCopy(copy).subscribe(
      (copyData) => {
        if (copyData) {
          this.assortmentCopyForm.status = 'VALID';
          this.cancelButtonDisabled = false;
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
      },
      (error) => {
        this.assortmentCopyForm.status = 'VALID';
        this.cancelButtonDisabled = false;
        this._loadingAnimationService.disableTopNavAnimation();
        this.isErrorOccured = true;
        this.errorMessage = error.error;
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  deleteAssortmentCopy(assortmentCopy: AssortmentEditCopyDialogData) {
    this.cancelButtonDisabled = true;
    this.deleteButtonEnabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this._copyService.deleteAssortmentCopy(assortmentCopy.copy.assortmentCopyId).subscribe(
      (copyData) => {
        if (copyData) {
          this.deleteButtonEnabled = false;
          this.cancelButtonDisabled = false;
          this.assortmentCopyForm.status = 'VALID';
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(assortmentCopy);
        }
      },
      (error) => {
        this.deleteButtonEnabled = false;
        this.cancelButtonDisabled = false;
        this.assortmentCopyForm.status = 'VALID';
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this.errorMessage = error.error;
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  createSalesCopy(copy: CreateSalesCopyRequest) {
    copy.brandId = this.salesCreateCopyDataItem.brand.id
    copy.marketType = MarketType[this.salesCreateCopyDataItem.mainMarket.marketType]
    copy.marketIntegrationKey = this.salesCreateCopyDataItem.mainMarket.marketIntegrationKey
    copy.isPercentPm = this.salesCreateCopyDataItem.isPercentPm
    copy.isPRCopy = (this.selectedMarket.marketType == MarketType.PlanningRegionPlanningMarket) ? false : this.salesCreateCopyDataItem.isPRCopy;  
    copy.pmId = (this.selectedMarket.marketType == MarketType.PlanningRegionPlanningMarket) ? this.selectedMarket.marketIntegrationKey : null;
    this._copyService.createSalesCopy(copy).subscribe(
      (copyData) => {
        if (copyData) {
          this.salesCopyForm.status = 'VALID';
          this.cancelButtonDisabled = false;
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
      },
      (error) => {
        this.salesCopyForm.status = 'VALID';
        this.cancelButtonDisabled = false;
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this.errorMessage = error.error;
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  editSalesCopy(copy: EditCopyRequest) {
    copy.copyId = this.salesEditCopyDataItem.copy.salesCopyId;
    this._copyService.editSalesCopy(copy).subscribe(
      (copyData) => {
        if (copyData) {
          this.salesCopyForm.status = 'VALID';
          this.cancelButtonDisabled = false;
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
      },
      (error) => {
        this.salesCopyForm.status = 'VALID';
        this.cancelButtonDisabled = false;
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this.errorMessage = error.error;
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  deleteSalesCopy(salesCopy: SalesEditCopyDialogData) {
    this.deleteButtonEnabled = true;
    this.cancelButtonDisabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this._copyService.deleteSalesCopy(salesCopy.copy.salesCopyId).subscribe(
      (copyData) => {
        if (copyData) {
          this.deleteButtonEnabled = false;
          this.cancelButtonDisabled = false;
          this.salesCopyForm.status = 'VALID';
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(salesCopy);
        }
      },
      (error) => {
        this.deleteButtonEnabled = false;
        this.cancelButtonDisabled = false;
        this.salesCopyForm.status = 'VALID';
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this.errorMessage = error.error;
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  mergeAssortmentCopy(assortmentCopy: AssortmentCopy) {
    this.cancelButtonDisabled = true;
    this.mergeButtonEnabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this._copyService.mergeAssortmentCopy(assortmentCopy.assortmentCopyId).subscribe(
      (copyData) => {
        if (copyData) {
          this.mergeButtonEnabled = false;
          this.cancelButtonDisabled = false;
          this.assortmentCopyForm.status = 'VALID';
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
        else {
          this.copyParentDialogRef.close(false);
          this.mergeButtonEnabled = false;
          this.cancelButtonDisabled = false;
          this.assortmentCopyForm.status = 'VALID';
          this._loadingAnimationService.disableTopNavAnimation();
          this._dialogBoxService.showMessage("Publish to Department Plan is in progress. Please try again later");
        }
      },
      (error) => {
        this.copyParentDialogRef.close(false);
        this.mergeButtonEnabled = false;
        this.cancelButtonDisabled = false;
        this.assortmentCopyForm.status = 'VALID';
        this._loadingAnimationService.disableTopNavAnimation();
        this._dialogBoxService.showMessage("Your goals have not been merged into the main. Try again later or contact IT helpdesk");
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  mergeSalesCopy(salesCopy: SalesCopy) {
    this.cancelButtonDisabled = true;
    this.mergeButtonEnabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this._copyService.mergeSalesCopy(salesCopy.salesCopyId).subscribe(
      (copyData) => {
        if (copyData) {
          this.mergeButtonEnabled = false;
          this.cancelButtonDisabled = false;
          this.assortmentCopyForm.status = 'VALID';
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
      },
      (error) => {
        this.copyParentDialogRef.close(false);
        this.mergeButtonEnabled = false;
        this.cancelButtonDisabled = false;
        this.assortmentCopyForm.status = 'VALID';
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this._dialogBoxService.showMessage("Your goals have not been merged into the main. Try again later or contact IT helpdesk");
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }

  salesParentTrickleDownSaveByCopy(salesCopy: SalesCopy) {
    this.cancelButtonDisabled = true;
    // this.mergeButtonEnabled = true;
    this._loadingAnimationService.enableTopNavAnimation();
    this._copyService.salesParentTrickleDownSaveByCopy(salesCopy.salesCopyId).subscribe(
      (copyData) => {
        if (copyData) {
          this.cancelButtonDisabled = false;
          this.isErrorOccured = false;
          this.copyParentDialogRef.close(copyData);
        }
      },
      (error) => {
        this.copyParentDialogRef.close(false);
        this.cancelButtonDisabled = false;
        this.assortmentCopyForm.status = 'VALID';
        this.isErrorOccured = true;
        this._loadingAnimationService.disableTopNavAnimation();
        this._dialogBoxService.showMessage("Trickle down on all plans for the copy is not successful. Try again later or contact IT helpdesk");
        console.log("Error: " + JSON.stringify(error));
      }
    )
  }
}
