import { Component } from "@angular/core";


@Component({
    selector: 'copy-forecast-dialog',
    templateUrl: "./copy-forecast-dialog.component.html",
    styleUrls: ['./copy-forecast-dialog.component.css']
})

export class CopyForecastDialogComponent {

}
