<mat-toolbar color="primary" class="top-navbar"
  [ngStyle]="{'background-image': bgImgUrl ? 'url(' + bgImgUrl + ')' : 'none' }">
  <span (click)="reloadSite()" style="cursor:pointer" class="material-icons site-logo">
    insights
  </span>
    <a class="title" (click)="reloadSite()" style="cursor:pointer; min-width: 30vh;">{{ title }}
      <div *ngIf="appVersion" class="nav-version">v{{ appVersion }}</div>
    </a>
    <div class="toolbar-spacer">
      <div class="impersonatediv" *ngIf="isImpersonating">
        <span class="material-icons">remove_red_eye</span>
        <span class="pImpersonateAs">Impersonating as {{impersonatedUserEmail}}</span>
      </div>
    </div>

  <span style="min-width: 38vh;">
    <button id="btnUserSettingMenu" mat-raised-button [matMenuTriggerFor]="logoutMenu"
      *ngIf="_authService.isUserLoggedIn() && (_authService.profile?.givenName || _authService.profile?.displayName)">
      <mat-icon>person</mat-icon>
      <span class="settings-username">
        <ng-container
          *ngIf="_authService.profile?.givenName != null && _authService.profile?.givenName.trim() != ''; then givenName; else displayName">
        </ng-container>
        <ng-template #displayName>
          {{_authService.profile?.displayName}}
        </ng-template>
        <ng-template #givenName>
          {{_authService.profile?.givenName}} {{_authService.profile?.surname}}
        </ng-template>
      </span>
    </button>
  </span>

  <mat-menu #logoutMenu="matMenu">
    <button mat-menu-item routerLink="user-settings">
      <mat-icon>manage_accounts</mat-icon>Settings
    </button>
    <button mat-menu-item (click)="_authService.logout()">
      <mat-icon>logout</mat-icon>Logout
    </button>
  </mat-menu>
</mat-toolbar>


<div *ngIf="!_utilsService.isNullOrEmpty(navMessageControlText)" class="nav-marquee">
  <div>
    <span>{{navMessageControlText}}</span>
    <span>{{navMessageControlText}}</span>
  </div>
</div>