<mat-tab-group animationDuration="0ms">

  <mat-tab label="User Details">

    <omnitdp-user-details></omnitdp-user-details>

  </mat-tab>

  <mat-tab label="Impersonate User" *ngIf="enableImpersonateUser">

    <omnitdp-impersonate-user></omnitdp-impersonate-user>

  </mat-tab>

</mat-tab-group>