import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogBoxComponent } from "../components/dialog-box/dialog-box.component";

@Injectable({
    providedIn: 'root'
})
export class DialogBoxService {

    constructor(private _dialogService: MatDialog) {
    }

    showMessage(message: string) {
        return this._dialogService.open(DialogBoxComponent, {
            data: {
                message: message,
                buttonText: {
                    cancel: 'OK'
                }
            },
        });
    }
}