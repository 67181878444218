import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rawValueConverter'
})
export class RawValueConverterPipe implements PipeTransform {

  transform(val: number, numberOfDecimals?: number): string {
    if (val !== undefined && val !== null) {
        if(numberOfDecimals !== undefined  && numberOfDecimals !== null){
            return val.toFixed(numberOfDecimals);
        }
        return val.toFixed(5);
    } else {
      return '';
    }
  }
}