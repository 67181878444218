import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { Constants } from "../../types/constants";
import { RetrievalMode } from "../../types/retrieval-mode";
import { AbstractCalculator } from "../abstract-calculator";

export class BoughtOfPlanCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Add/Remove
    // Purchase Plan
    // Bought Gross

    // Calculates
    // ----------
    // Bought of Plan


    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let organizationName = this.calculatorDriver.getUserConfig().selectionViewOptions.organization.name;
        let retrievalMode: RetrievalMode = this.calculatorDriver.getRetrievalMode();

        let BoughtGrossSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.BoughtGross);
        //Purchase Plan = Purchase Plan Fixed + Add Remove 
        let PurchasePlanFixedSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.PurchasePlanFixed);

        let addRemoveMSekSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.AddRemoveMSek);

        let addRemoveMSek =  addRemoveMSekSum * Constants.TSEKTOMSEK;

        if (retrievalMode == RetrievalMode.Initial) {
            // Default value in initial mode is 0
            value = 0;
        }
        else if (retrievalMode == RetrievalMode.Bought || retrievalMode == RetrievalMode.Plan) {
            if (organizationName == Constants.ASSORTMENT) {
                //Bought gross /  (Purchase plan + add/remove)
                value = utils.safeDivide(BoughtGrossSum, (PurchasePlanFixedSum + addRemoveMSek));
            }
            else {
                //(Bought gross + add/remove) / purchase plan
                value = utils.safeDivide((BoughtGrossSum + addRemoveMSek) ,PurchasePlanFixedSum);
            }
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.BoughtOfPlanPeriodic] = value * 100;
        });
    }
}