import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class DemdelPlanCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Forecast Goal Periodic
    // DemDelPlan
    // CubeDemDelPlan
    // GrossSalesGround
    // StartWeekSalesGrossGround

    // Calculates
    // ----------
    // DemDelPlan

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        // default to null
        let value = null;
        const utils = this.calculatorDriver.getUtilsService();
        const grossSalesGround = utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGround])
            ? (currentWeekItem[CalculationDataItemType.GrossSalesGround] + (currentWeekItem[CalculationDataItemType.StartWeekAfterCurrentWeek] ? currentWeekItem[CalculationDataItemType.StartWeekSalesGrossGround] : 0))
            : null;

        // check if there is value in the input forecast field and if the gross ground is not zero
        if ((!isNaN(parseInt(currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal]?.toString())) && utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal])) && utils.isNotNullOrUndefined(grossSalesGround)) {
            // calculate the forecasted sales plan/demdel plan based on forecast gross goal
            value = (currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal] * grossSalesGround) / 100;    
        }

        currentWeekItem[CalculationDataItemType.DemDelPlan] = value;
        currentWeekItem[CalculationDataItemType.DemDelPlanForReturns] = value 
    }
}
