import { Injectable } from '@angular/core';
import { AppInsightsLoggingService } from './app-insights-logging.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorLoggingService {

  constructor(private appInsightsLoggingService: AppInsightsLoggingService) {
    
    
  }
  logError(error: Error) {
    // Send errors to be saved here
    // The console.log is only for testing this example.
    console.log('Error: ' + error.message);

    // log to app insights
    this.appInsightsLoggingService.logException(error);
  }
}