import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/modules/shared/types/api-routes';
import { environment } from 'src/environments/environment';
import { CreateAssortmentCopyRequest } from '../types/api/copy/create-copies/request/create-assortment-copy-request copy';
import { CreateSalesCopyRequest } from '../types/api/copy/create-copies/request/create-sales-copy-request';
import { EditCopyRequest } from '../types/api/copy/edit-copies/edit-copy-request';
import { AssortmentCopy } from '../types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from '../types/api/copy/get-all-copies/response/sales-copy';


@Injectable({
    providedIn: 'root'
})
export class CopyService {

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient) { }

    private assortmentCopyUrl = environment.baseApiUrl + ApiRoutes.assortmentUserCopydata;
    private salesCopyUrl = environment.baseApiUrl + ApiRoutes.salesUserCopydata;
    private assortmentMergeCopyUrl = environment.baseApiUrl + ApiRoutes.mergeassortmentUserCopy;
    private salesMergeCopyUrl = environment.baseApiUrl + ApiRoutes.mergeSalesUserCopy;
    private salesParentTrickleDownSaveByCopyUrl = environment.baseApiUrl + ApiRoutes.salesParentTrickleDownSaveByCopy;

    // get all assortment copies by brand
    getAllAssortmentCopiesByBrandAndStructureId(brandId: number, structureTypeId: number): Observable<AssortmentCopy[]> {
        let url = this.assortmentCopyUrl + "?brandId=" + brandId + "&structureTypeId=" + structureTypeId;
        return this.http.get<AssortmentCopy[]>(url, this.httpOptions);
    }

    // get all sales copies by brand
    getAllSalesCopiesByBrandId(brandId: number): Observable<SalesCopy[]> {
        let url = this.salesCopyUrl + "?brandId=" + brandId;
        return this.http.get<SalesCopy[]>(url, this.httpOptions);
    }

    createAssortmentCopy(requestData: CreateAssortmentCopyRequest) {
        return this.http.post(this.assortmentCopyUrl, requestData, this.httpOptions);
    }

    createSalesCopy(requestData: CreateSalesCopyRequest) {
        return this.http.post(this.salesCopyUrl, requestData, this.httpOptions);
    }


    editAssortmentCopy(requestData: EditCopyRequest) {
        return this.http.patch(this.assortmentCopyUrl, requestData, this.httpOptions);
    }

    editSalesCopy(requestData: EditCopyRequest) {
        return this.http.patch(this.salesCopyUrl, requestData, this.httpOptions);
    }


    deleteAssortmentCopy(copyId: number) {
        let url = this.assortmentCopyUrl + "?copyId=" + copyId;
        return this.http.delete(url, this.httpOptions);
    }

    deleteSalesCopy(copyId: number) {
        let url = this.salesCopyUrl + "?copyId=" + copyId;
        return this.http.delete(url, this.httpOptions);
    }

    mergeAssortmentCopy(copyId: number) {
        let url = this.assortmentMergeCopyUrl + "?copyId=" + copyId;
        return this.http.post(url, this.httpOptions);
    }

    mergeSalesCopy(copyId: number) {
        let url = this.salesMergeCopyUrl + "?copyId=" + copyId;
        return this.http.post(url, this.httpOptions);
    }

    salesParentTrickleDownSaveByCopy(copyId: number) {
        let url = this.salesParentTrickleDownSaveByCopyUrl;
        return this.http.post(url, copyId, this.httpOptions);
    }
}
