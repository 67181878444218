import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRoutes } from 'src/app/modules/shared/types/api-routes';
import { environment } from 'src/environments/environment';
import { AssortmentCopy } from '../../selection-view/types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy, SalesCopyTrickleDownFailedStatus } from '../../selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { UtilsService } from '../../shared/services/utils.service';
import { Constants } from '../../shared/types/constants';
import { MarketType } from '../../shared/types/market-type';
import { SelectedOptions } from '../../shared/types/selected-options';
import { UserConfig } from '../../shared/types/user-config';
import { AssortmentPlanningViewRequest } from '../types/api/assortment-planning-view/assortment-planning-view-request';
import { AssortmentPlanningViewResponse } from '../types/api/assortment-planning-view/assortment-planning-view-response';
import { PlanningViewSaveCopyDataRequest } from '../types/api/planning-view-save-copy-data-request';
import { SalesPlanningViewRequest } from '../types/api/sales-planning-view/sales-planning-view-request';
import { SalesPlanningViewResponse } from '../types/api/sales-planning-view/sales-planning-view-response';
import { StashStatusData } from '../types/api/stash-status-data';
import { TotalViewRequestDataRequest } from '../types/api/total-view-request-data/total-view-request-data-request';
import { TotalViewRequestDataResponse } from '../types/api/total-view-request-data/total-view-request-data-response';
import { DepartmentSaveItem } from '../types/department-save-item';
import { SaveWarningDialogData } from '../types/save-warning-dialog-data';

@Injectable({
    providedIn: 'root'
})
export class PlanningViewService {

    private assortmentUrl = environment.baseApiUrl + ApiRoutes.assortmentPlanningView;
    private salesUrl = environment.baseApiUrl + ApiRoutes.salesPlanningView;

    private assortmentCopyDataUrl = environment.baseApiUrl + ApiRoutes.assortmentCopyData;
    private salesCopyDataUrl = environment.baseApiUrl + ApiRoutes.salesCopyData;
    private salesCopyLockStatusUrl = environment.baseApiUrl + ApiRoutes.salesCopyLockStatus;
    private salesCopyTrickleDownFailedStatusUrl = environment.baseApiUrl + ApiRoutes.salesCopyTrickleDownFailedStatus;
    private assortmentCopyLockStatusUrl = environment.baseApiUrl + ApiRoutes.assortmentCopyLockStatus;
    private salesParentTrickleDownSaveByCopyMarketStructureUrl = environment.baseApiUrl + ApiRoutes.salesParentTrickleDownSaveByCopyMarketStructure;

    private salesStashStatusByCopyUrl = environment.baseApiUrl + ApiRoutes.salesStashStatusByCopy;
    private assortmentCopyCheckStashedInputsUrl = environment.baseApiUrl + ApiRoutes.assortmentCheckStashedInput;
    private salesCheckStashedInputByCopyMarketStructureUrl = environment.baseApiUrl + ApiRoutes.salesCheckStashedInputByCopyMarketStructure;

    private assortmentCheckNegativePlansUrl = environment.baseApiUrl + ApiRoutes.assortmentCheckNegativePlans;

    private totalViewRequestsUrl = environment.baseHeavyLoadApiUrl + ApiRoutes.totalViewRequests;
    private generateTotalViewRequesUrl = environment.baseHeavyLoadApiUrl + ApiRoutes.generateTotalViewRequest;

    httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json'
        })
    }

    constructor(private http: HttpClient, private _utilsService: UtilsService) { }

    getAssortmentViewData(request: AssortmentPlanningViewRequest): Observable<AssortmentPlanningViewResponse> {
        return this.http.post<AssortmentPlanningViewResponse>(this.assortmentUrl, request, this.httpOptions);
    }

    getSalesViewData(request: SalesPlanningViewRequest): Observable<SalesPlanningViewResponse> {
        return this.http.post<SalesPlanningViewResponse>(this.salesUrl, request, this.httpOptions);
    }

    async getTotalViewData(selectedOptions: SelectedOptions): Promise<TotalViewRequestDataResponse>
    {
        let requestData: TotalViewRequestDataRequest ={
            copyId: (<SalesCopy>selectedOptions.selectionViewOptions.copy).salesCopyId,
            channelId: selectedOptions.planningViewOptions.channel,
            marketIntegrationKey: selectedOptions.planningViewOptions.market.marketIntegrationKey,
            marketType: selectedOptions.planningViewOptions.market.marketType,
            selectionStructureType: selectedOptions.selectionViewOptions.structureType
        }
        return this.http.post<TotalViewRequestDataResponse>(this.totalViewRequestsUrl, requestData, this.httpOptions).toPromise();
    }

    async createNewTotalViewRequest(selectedOptions: SelectedOptions){

        let requestData: TotalViewRequestDataRequest ={
            copyId: (<SalesCopy>selectedOptions.selectionViewOptions.copy).salesCopyId,
            channelId: selectedOptions.planningViewOptions.channel,
            marketIntegrationKey: selectedOptions.planningViewOptions.market.marketIntegrationKey,
            marketType: selectedOptions.planningViewOptions.market.marketType,
            selectionStructureType: selectedOptions.selectionViewOptions.structureType
        }
        return this.http.post(this.generateTotalViewRequesUrl, requestData, this.httpOptions).toPromise();
    }

    async checkAssortmentNegativePurchasePlanForTrickleDownCopyData(departmentSaveItem: DepartmentSaveItem, userConfig: UserConfig): Promise<SaveWarningDialogData> {
        let copyId: number;
        copyId = (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId;

        let request: PlanningViewSaveCopyDataRequest = {
            date: userConfig.planningViewOptions.viewDate,
            excludeOldSeasons: userConfig.planningViewOptions.areOldSeasonsExcluded,
            copyId: copyId,
            structureType: userConfig.planningViewOptions.structureType,
            structureId: userConfig.planningViewOptions.structureId,
            marketType: MarketType[userConfig.planningViewOptions.market.marketType],
            marketId: userConfig.planningViewOptions.market.marketIntegrationKey,            
            parentStructureId: userConfig.planningViewOptions.parentStructureId,
            parentStructureType: userConfig.planningViewOptions.parentStructureType,
            copyData: departmentSaveItem,
            stashInputs: false
        };

        return await this.http.post<SaveWarningDialogData>(this.assortmentCheckNegativePlansUrl, request, this.httpOptions).toPromise();

    }

    async saveCopyData(
        departmentSaveItem: DepartmentSaveItem,
        userConfig: UserConfig,
        stashInputs: boolean
    ) {

        let copyId: number;

        if (userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
            copyId = (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId;
        }
        else {
            copyId = (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId;
        }

        let request: PlanningViewSaveCopyDataRequest = {
            date: userConfig.planningViewOptions.viewDate,
            excludeOldSeasons: userConfig.planningViewOptions.areOldSeasonsExcluded,
            copyId: copyId,
            structureType: userConfig.planningViewOptions.structureType,
            structureId: userConfig.planningViewOptions.structureId,
            marketType: MarketType[userConfig.planningViewOptions.market.marketType],
            marketId: userConfig.planningViewOptions.market.marketIntegrationKey,
            parentStructureId: userConfig.planningViewOptions.parentStructureId,
            parentStructureType: userConfig.planningViewOptions.parentStructureType,
            copyData: departmentSaveItem,
            stashInputs: stashInputs
        };

        let copyDataUrl = userConfig.selectionViewOptions.organization.name == "Assortment" ? this.assortmentCopyDataUrl : this.salesCopyDataUrl;
        let savedStatus: boolean = true;
        await this.http.post(copyDataUrl, request, this.httpOptions).toPromise().catch((error) => {
            console.log('error', error);
            savedStatus = false;
        });


        return savedStatus;
    }

    async saveTrickleDownByCopyStructureMarketData(
        departmentSaveItem: DepartmentSaveItem,
        userConfig: UserConfig,
        stashInputs: boolean
    ) {

        let copyId: number;

        if (userConfig.selectionViewOptions.organization.name == Constants.SALES) {
            copyId = (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId;
        }

        let request: PlanningViewSaveCopyDataRequest = {
            date: userConfig.planningViewOptions.viewDate,
            excludeOldSeasons: userConfig.planningViewOptions.areOldSeasonsExcluded,
            copyId: copyId,
            structureType: userConfig.planningViewOptions.structureType,
            structureId: userConfig.planningViewOptions.structureId,
            marketType: MarketType[userConfig.planningViewOptions.market.marketType],
            marketId: userConfig.planningViewOptions.market.marketIntegrationKey,
            parentStructureId: userConfig.planningViewOptions.parentStructureId,
            parentStructureType: userConfig.planningViewOptions.parentStructureType,
            copyData: departmentSaveItem,
            stashInputs: stashInputs
        };

        let copyDataUrl = this.salesParentTrickleDownSaveByCopyMarketStructureUrl;
        let savedStatus: boolean = true;
        await this.http.post(copyDataUrl, request, this.httpOptions).toPromise().catch((error) => {
            console.log('error', error);
            savedStatus = false;
        });


        return savedStatus;
    }

    async getStashedData(
        userConfig: UserConfig
    ): Promise<StashStatusData>
    {
        let copyId: number;

        if (userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
            copyId = (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId;
        }
        else {
            copyId = (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId;
        }

        let request: PlanningViewSaveCopyDataRequest = {
            date: userConfig.planningViewOptions.viewDate,
            excludeOldSeasons: userConfig.planningViewOptions.areOldSeasonsExcluded,
            copyId: copyId,
            structureType: userConfig.planningViewOptions.structureType,
            structureId: userConfig.planningViewOptions.structureId,
            marketType: MarketType[userConfig.planningViewOptions.market.marketType],
            marketId: userConfig.planningViewOptions.market.marketIntegrationKey,
            parentStructureId: userConfig.planningViewOptions.parentStructureId,
            parentStructureType: userConfig.planningViewOptions.parentStructureType
        };

        let checkStashedStatusUrl = userConfig.selectionViewOptions.organization.name == "Assortment" ? this.assortmentCopyCheckStashedInputsUrl : this.salesCheckStashedInputByCopyMarketStructureUrl;
        let stashedStatusResponse: StashStatusData =  await this.http.post<StashStatusData>(checkStashedStatusUrl, request, this.httpOptions).toPromise();

        return stashedStatusResponse;
    }

    async getStashStatusByCopy(
        userConfig: UserConfig
    ): Promise<boolean>
    {
        let copyId: number;

        if (userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
            copyId = (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId;
        }
        else {
            copyId = (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId;
        }

        let checkStashedStatusUrl = userConfig.selectionViewOptions.organization.name == "Assortment" ? this.assortmentCopyCheckStashedInputsUrl : this.salesStashStatusByCopyUrl;
        let stashedStatusResponse: boolean =  await this.http.get<boolean>(checkStashedStatusUrl+ "?copyId=" + copyId, this.httpOptions).toPromise();

        return stashedStatusResponse;
    }

    async getCopyLockStatus(userConfig: UserConfig) : Promise<boolean>{
        let copyId: number;

        if (userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
            copyId = (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId;
        }
        else {
            copyId = (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId;
        }

        let copyLockStatusUrl = userConfig.selectionViewOptions.organization.name == "Assortment" ? this.assortmentCopyLockStatusUrl : this.salesCopyLockStatusUrl;
        let isLocked: boolean =  await this.http.get<boolean>(copyLockStatusUrl+ "?copyId=" + copyId, this.httpOptions).toPromise();
        return isLocked;
    }

    async getCopyTrickleDownFailedStatus(userConfig: UserConfig) : Promise<SalesCopyTrickleDownFailedStatus> {
        let copyId: number = (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId;
        return await this.http.get<SalesCopyTrickleDownFailedStatus>(this.salesCopyTrickleDownFailedStatusUrl + "?copyId=" + copyId, this.httpOptions).toPromise();
    }
}
