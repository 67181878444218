export enum CalculationDataItemType {
    AddMovesMSek = "addMovesMSek",
    AddRemoveMSek = "addRemoveMSek",
    AverageRAndDPercentGroundPeriodic = "averageRAndDPercentGroundPeriodic",
    AverageRAndDPercentPeriodic = "averageRAndDPercentPeriodic",
    AverageSPercentPeriodic = "averageSPercentPeriodic",
    AverageStockPrognosisPeriodic = "averageStockPrognosisPeriodic",
    BuyingWeekly = "buyingWeekly",
    BoughtOfPlanPeriodic = "boughtOfPlanPeriodic",
    BoughtGross = "boughtGross",
    BoughtGrossGround = "boughtGrossGround",
    BoughtOfPlanPercentWeekly = "boughtOfPlanPercentWeekly",
    AdjustedBudgetedReturn = "adjustedBudgetedReturn",
    CombinedNetSalesWeekly = "combinedNetSalesWeekly",
    CombinedRAndDWeekly = "combinedRAndDWeekly",
    CombinedSalesLyWeekly = "combinedSalesLyWeekly",
    CombinedSalesPlanWeekly = "combinedSalesPlanWeekly",
    CombinedSalesWeekly = "combinedSalesWeekly",
    ComplementaryDemDel = "complementaryDemDel",
    ComplementaryDemDelGround = "complementaryDemDelGround",
    CubeDemDelPlan = "cubeDemDelPlan",
    CubeDemDelPlanForReturns = "cubeDemDelPlanForReturns",
    EffectiveSystemGoalWeekly = "effectiveSystemGoalWeekly",
    SalesForecastWeekly = "salesForecastWeekly",
    DemDelNet = "demDelNet",
    DemDelNetGround = "demDelNetGround",
    DemDelPlan = "demDelPlan",
    DemDelPlanForReturns = "demDelPlanForReturns",
    EffectiveRAndDPlan = "effectiveRAndDPlan",
    EffectiveSalesPlanWeekly = "effectiveSalesPlanWeekly",
    EffectiveSalesPlanWeeklyWithGround = "effectiveSalesPlanWeeklyWithGround",
    EffectiveSystemGoalPlanWeekly ="effectiveSystemGoalPlanWeekly",
    ForecastedDemDelPlanWeekly = "forecastedDemDelPlanWeekly",
    ForecastedRAnDPlanWeekly = "forecastedRAnDPlanWeekly",
    ForecastGoalPeriodic = "forecastGoalPeriodic",
    GoalForecastPeriodic = "goalForecastPeriodic",
    GrossSales = "grossSales",
    GrossSales2Ground = "grossSales2Ground",
    GrossSalesGround = "grossSalesGround",
    GrossSalesGroundLy = "grossSalesGroundLy",
    GrossSalesLy = "grossSalesLy",
    GrossTaktGroundPeriodic = "grossTaktGroundPeriodic",
    GrossTaktGroundWeekly = "grossTaktGroundWeekly",
    GrossTaktPeriodic = "grossTaktPeriodic",
    GrossTaktWeekly = "grossTaktWeekly",
    GroundGrossTaktPeriodic = "groundGrossTaktPeriodic",
    GroundGrossTaktWeekly = "groundGrossTaktWeekly",
    GroundRAndDPercentPeriodic = "groundRAndDPercentPeriodic",
    GroundRAndDPercentWeekly = "groundRAndDPercentWeekly",
    GroundSPercentPeriodic = "groundSPercentPeriodic",
    GroundSPercentWeekly = "groundSPercentWeekly",
    GroundStockIndexPeriodic = "groundStockIndexPeriodic",
    InputAddMovesMSekWeekly = "inputAddMovesMSekWeekly",
    InputAddRemoveMSekWeekly = "inputAddRemoveMSekWeekly",
    InputForecastGrossPeriodic = "inputForecastGrossPeriodic",
    InputRAndDForecastPeriodic = "inputRAndDForecastPeriodic",
    InputForecastGrossPeriodicOriginal = "inputForecastGrossPeriodicOriginal",
    InputForecastGrossPeriodicPrevious = "inputForecastGrossPeriodicPrevious",
    NetSales = "salesNet",
    NetSalesGround = "salesNetGround",
    NetSales2Ground = "salesNet2Ground",
    NetSalesGroundWeekly = "netSalesGroundWeekly",
    NetSalesWeekly = "netSalesWeekly",
    NetTaktPeriodic = "netTaktPeriodic",
    NetTaktWeekly = "netTaktWeekly",
    OmniTaktPeriodic = "omniTaktPeriodic",
    OrderedGrossGround = "orderedGrossGround",
    OutgoingStockPrognosisWeekly = "outgoingStockPrognosisWeekly",
    EffectiveOutgoingStockPrognosis = "effectiveOutgoingStockPrognosis",
    PlannedCoverage = "plannedCoverage",
    PlannedReturn = "plannedReturn",
    PurchasePlan = "purchasePlan",
    PurchasePlanFixed = "purchasePlanFixed",
    RAndD = "rAndD",
    RAndDForecastPercentPeriodic = "rAndDForecastPercentPeriodic",
    RAndDForecastPercentWeekly = "rAndDForecastPercentWeekly",
    RAndDForecastWeekly = "rAndDForecastWeekly",
    RAndDGround = "rAndDGround",
    RAndD2Ground = "rAndD2Ground",
    RAndDLy = "rAndDLy",
    RAndDLyPercentWeekly = "rAndDLyPercentWeekly",
    RAndDPercentGroundWeekly = "rAndDPercentGroundWeekly",
    RAndDPercentPeriodic = "rAndDPercentPeriodic",
    RAndDPercentWeekly = "rAndDPercentWeekly",
    RAndDPlan = "rAndDPlan",
    RAndPercentWeekly = "rAndPercentWeekly",
    ReturnPlanFixed = "returnPlanFixed",
    ReturnsGross = "returnsGross",
    ReturnsGrossGround = "returnsGrossGround",
    SalesPlanFixed = "salesPlanFixed",
    SalesPlanLy = "salesPlanLy",
    SPercent = "sPercent",
    SPercentGroundWeekly = "sPercentGroundWeekly",
    SPercentPeriodic = "sPercentPeriodic",
    SPercentWeekly = "sPercentWeekly",
    StartWeekDemPlan = "startWeekDemPlan",
    StartWeekSalesGrossGround = "startWeekSalesGrossGround",
    StartWeekSalesPlanFixed = "startWeekSalesPlanFixed",
    StartWeekAfterCurrentWeek = "startWeekAfterCurrentWeek",
    StockForecastWeekly = "stockForecastWeekly",
    Stock2Ground = "stockGross2Ground",
    StockGross = "stockGross",
    StockGrossLy = "stockGrossLy",
    StockGround = "stockGround",
    StockGroundLy = "stockGrossGroundLy",
    StockIndexGroundWeekly = "stockIndexGroundWeekly",
    StockPerSalesNetWeekly = "stockPerSalesNetWeekly",
    StockPlanFixed = "stockPlanFixed",
    StockPlanIndexWeekly = "stockPlanIndexWeekly",
    StockPrognosisLy = "stockPrognosisLy",
    StockPrognosisPeriodic = "stockPrognosisPeriodic",
    StockPrognosisWeekly = "stockPrognosisWeekly",
    SystemGoalPeriodic = "systemGoalPeriodic",
    UnAdjustedBudgetedReturn = "unAdjustedBudgetedReturn",
    UnAdjustedPlannedReturn = "unAdjustedPlannedReturn",
    AccShrinkLy = "accShrinkLy",
    ShrinkPercentLy = "shrinkPercentLy",
    WeekName = "weekName",

    DiffFinPlanMSek = "diffFinPlanMSek",
    InputFinPlanMSekWeekly = "inputFinPlanMSekWeekly",
    FinPlanMSek = "finPlanMSek",

    AggregatedPMsRAndDPercentPeriodic = "aggregatedPMsRAndDPercentPeriodic",
    AggregatedPMsGrossTaktPeriodic = "aggregatedPMsGrossTaktPeriodic",
    AggregatedPMsCombinedSalesPlanWeekly = "aggregatedPMsCombinedSalesPlanWeekly",
    AggregatedPMsEffectiveSalesPlanWeekly = "aggregatedPMsEffectiveSalesPlanWeekly",
    AggregatedPMsGrossSalesGround = "aggregatedPMsGrossSalesGround",
    AggregatedPMsGrossSales = "aggregatedPMsGrossSales",
    AggregatedPMsRAndDPlan = "aggregatedPMsRAndDPlan",

    AggregatedPMsGrossSales2Ground = "aggregatedPMsGrossSales2Ground",
    AggregatedPMsGrossSalesLy = "aggregatedPMsGrossSalesLy",
    AggregatedPMsIsSaved = "aggregatedPMsIsSaved",


    MarketMainCopySystemGoal = "marketMainCopySystemGoal",
    MarketMainCopyCombinedSalesPlanWeekly = "marketMainCopyCombinedSalesPlanWeekly",
    MarketMainCopyEffectiveSalesPlanWeekly = "marketMainCopyEffectiveSalesPlanWeekly",
    MarketMainCopyGrossSalesGround = "marketMainCopyGrossSalesGround",
    MarketMainCopyGrossSales2Ground = "marketMainCopyGrossSales2Ground",
    MarketMainCopyGrossSalesLy = "marketMainCopyGrossSalesLy",

    //sales goal in assortment
    SalesMainCopyCombinedSalesPlanWeekly = "salesMainCopyCombinedSalesPlanWeekly",
    SalesMainCopyGrossSalesGround = "salesMainCopyGrossSalesGround",
    SalesMainCopySystemGoal = "salesMainCopySystemGoal"
}
