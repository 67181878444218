<ng-container>
  <div class="planning-view-main-pane organization-summary-view">

    <div style="text-align:center; margin:15px">
      <button (click)="fetchData(true)" class="btnGetTotalPlanViewData" mat-raised-button
        [disabled]="totalPlanViewLoading" color="primary">
        Refresh Data
      </button>
    </div>

    <ng-container *ngIf="totalPlanViewLoading; then totalPlanViewDataLoading; else totalPlanViewDataLoaded">
    </ng-container>

    <ng-template #totalPlanViewDataLoading>
      <omnitdp-skeleton-loader [skeletonType]="'planning-table'"></omnitdp-skeleton-loader>
    </ng-template>

    <ng-template #totalPlanViewDataLoaded>


      <div class="row d-flex justify-content-center" *ngIf="totalPlanViewData && !totalPlanViewLoading">

        <div class="col-md-12 week-picker" style="text-align:center">
          <mat-form-field appearance="outline" class="week-selection-section">
            <mat-label>Start Week</mat-label>
            <mat-select panelClass="custom-select-panel" [(ngModel)]="selectedStartWeek" [hideSingleSelectionIndicator]="true"
              (selectionChange)="presetCustomWeekSelections();weekSelectionChanged()"
              (openedChange)="focusCustomStartWeek($event);">
              <input type="number" [(ngModel)]="customSelectStartWeek" (blur)="validateCustomStartWeek($event);"
                class="custom-week-selection" id="txtCustomStartWeekSelection" />
              <div class="custom-week-section">
                <mat-option *ngFor="let week of startweeksList" [value]="week">
                  {{week}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" class="week-selection-section">
            <mat-label>End Week</mat-label>
            <mat-select panelClass="custom-select-panel" [(ngModel)]="selectedEndWeek" [hideSingleSelectionIndicator]="true"
              (selectionChange)="presetCustomWeekSelections();weekSelectionChanged()"
              (openedChange)="focusCustomEndWeek($event);">
              <input type="number" [(ngModel)]="customSelectEndWeek" (blur)="validateCustomEndWeek();"
                class="custom-week-selection" id="txtCustomEndWeekSelection" />
              <div class="custom-week-section">
                <mat-option *ngFor="let week of endweeksList" [value]="week">
                  {{week}}
                </mat-option>
              </div>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="totalSelectedRetrievalModeText.length != 0" class="selected-retrieval-mode-text">
        {{totalSelectedRetrievalModeText}}
      </div>

      <div class="form-group">
        <div class="col-12">
          <div class="table-container">
            <div class="index-values-table">
              <table class='table table-striped simulateTable index-values-table totalplanviewtable ng-star-inserted'
                *ngIf="totalPlanViewData && totalPlanViewData.length > 0 && !totalPlanViewLoading">
                <caption>Total view data</caption>
                <tbody>
                  <tr class="simulateTableSubHeaderRow">
                    <th class="simulateTableSubHeaderCell"></th>
                    <th class="simulateTableSubHeaderCell">Gross Takt</th>
                    <th class="simulateTableSubHeaderCell">Net Takt</th>
                    <th class="simulateTableSubHeaderCell">SOB %</th>
                    <th class="simulateTableSubHeaderCell">SOB % Gnd</th>
                    <th class="simulateTableSubHeaderCell">Ingoing Stock IDX</th>
                    <th class="simulateTableSubHeaderCell">Outgoing Stock IDX</th>
                    <th class="simulateTableSubHeaderCell">Average S%</th>
                    <th class="simulateTableSubHeaderCell">Average S% Gnd</th>
                    <th class="simulateTableSubHeaderCell">RED %</th>
                    <th class="simulateTableSubHeaderCell">Ordered Index</th>
                    <th *ngIf="isOnline" class="simulateTableSubHeaderCell">Returns %</th>
                    <th class="simulateTableSubHeaderCell">Stock % SalesNet52</th>
                  </tr>
                  <ng-container *ngFor="let dataItem of totalPlanViewData">
                    <tr class="totalplanviewtablerow">
                      <td class="simulateTableRowCell entity-name" data-toggle="tooltip" [title]="dataItem.name">
                        {{dataItem.name}}</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.grossTakt">
                        {{ dataItem.grossTakt == null ? "" : math.round(dataItem.grossTakt) }}</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.netTakt">
                        {{ dataItem.netTakt == null ? "" : math.round(dataItem.netTakt) }}</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.sobPercent">
                        {{ dataItem.sobPercent == null ? "" : math.round(dataItem.sobPercent*100)/100 }}%</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.sobPercentGround">
                        {{dataItem.sobPercentGround == null ? "" : math.round(dataItem.sobPercentGround*100)/100 }}%
                      </td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.stockGrossIngoingIndex">
                        {{dataItem.stockGrossIngoingIndex == null ? "" : math.round(dataItem.stockGrossIngoingIndex) }}
                      </td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.stockGrossOutgoingIndex">
                        {{dataItem.stockGrossOutgoingIndex == null ? "" : math.round(dataItem.stockGrossOutgoingIndex)
                        }}
                      </td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.avgSPercent">
                        {{dataItem.avgSPercent == null ? "" : dataItem.avgSPercent.toFixed(1)}}%</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.avgSPercentGround">
                        {{dataItem.avgSPercentGround == null ? "" : dataItem.avgSPercentGround.toFixed(1)}}%</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.redTsek">
                        {{dataItem.redTsek == null ? "" : math.round(dataItem.redTsek*100)/100 }}%</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.orderedIndex">
                        {{dataItem.orderedIndex == null ? "" : math.round(dataItem.orderedIndex) }}</td>
                      <td *ngIf="isOnline" class="simulateTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.returns">
                        {{dataItem.returns == null ? "" : math.round(dataItem.returns*100)/100 }}%</td>
                      <td class="simulateTableRowCell" data-toggle="tooltip" [title]="dataItem.stockPerSalesNet">
                        {{dataItem.returns == null ? "" : math.round(dataItem.stockPerSalesNet*100)/100 }}%</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>

            <div class="table-gap"></div>
            <div class="monetary-values-section"
              *ngIf="organizationMonetaryData && organizationMonetaryData.length > 0 && !totalPlanViewLoading && !isExpandMonetaryButton">
              <button matTooltip="Expand Monetary Values" (click)="showOrHideMonetaryValues(true)" mat-raised-button>
                <mat-icon>add_circle_outline</mat-icon> Expand monetary values
              </button>
            </div>
            <div class="monetary-values-section"
              *ngIf="organizationMonetaryData && organizationMonetaryData.length > 0 && !totalPlanViewLoading && isExpandMonetaryButton">
              <button matTooltip="Collapse Monetary Values" (click)="showOrHideMonetaryValues(false)" mat-raised-button>
                <mat-icon>remove_circle_outline</mat-icon> Collapse monetary values
              </button>
            </div>
            <div class="table-gap"></div>
            <div class="monetary-values-section" *ngIf="isExpandMonetaryButton">
              <table class='organizationSummaryTable monetary-values-table'
                *ngIf="organizationMonetaryData && organizationMonetaryData.length > 0 && !totalPlanViewLoading">
                <caption>Total view monetary values</caption>
                <tbody>
                  <tr class="organizationSummaryTableSubHeaderRow">
                    <th class="organizationSummaryTableSubHeaderCell"></th>
                    <th class="organizationSummaryTableSubHeaderCell">Sales Gross</th>
                    <th class="organizationSummaryTableSubHeaderCell">Sales Ground</th>
                    <th class="organizationSummaryTableSubHeaderCell">Sales Net</th>
                    <th class="organizationSummaryTableSubHeaderCell">Sales Net Ground</th>
                    <th class="organizationSummaryTableSubHeaderCell">Stock Gross Ingoing</th>
                    <th class="organizationSummaryTableSubHeaderCell">Stock Gross Ingoing Ground</th>
                    <th class="organizationSummaryTableSubHeaderCell">Stock Gross Outgoing</th>
                    <th class="organizationSummaryTableSubHeaderCell">Stock Gross Outgoing Ground</th>
                    <th class="organizationSummaryTableSubHeaderCell">OutgoingStockPrognosis</th>
                    <th class="organizationSummaryTableSubHeaderCell">Stock Gross Ground</th>
                    <th class="organizationSummaryTableSubHeaderCell">Buying</th>
                    <th class="organizationSummaryTableSubHeaderCell">Ordered Gross Ground</th>
                    <th class="organizationSummaryTableSubHeaderCell">RED TSek</th>
                    <th *ngIf="isOnline" class="organizationSummaryTableSubHeaderCell">Returns</th>
                  </tr>
                  <ng-container *ngFor="let dataItem of organizationMonetaryData">
                    <tr class="totalplanviewtablerow">
                      <td class="organizationSummaryTableRowCell entity-name" data-toggle="tooltip"
                        [title]="dataItem.name">
                        {{dataItem.name}}</td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip" [title]="dataItem.salesGross">
                        {{ dataItem.salesGross == null ? "" : math.round(dataItem.salesGross) | thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.salesGrossGround">
                        {{ dataItem.salesGrossGround == null ? "" : math.round(dataItem.salesGrossGround) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip" [title]="dataItem.salesNet">
                        {{ dataItem.salesNet == null ? "" : math.round(dataItem.salesNet) | thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.salesNetGround">
                        {{ dataItem.salesNetGround == null ? "" : math.round(dataItem.salesNetGround) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.stockGrossIngoing">
                        {{ dataItem.stockGrossIngoing == null ? "" : math.round(dataItem.stockGrossIngoing) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.stockGrossIngoingGround">
                        {{ dataItem.stockGrossIngoingGround == null ? "" :
                        math.round(dataItem.stockGrossIngoingGround)
                        |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.stockGrossOutgoing">
                        {{ dataItem.stockGrossOutgoing == null ? "" : math.round(dataItem.stockGrossOutgoing) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.stockGrossOutgoingGround">
                        {{ dataItem.stockGrossOutgoingGround == null ? "" :
                        math.round(dataItem.stockGrossOutgoingGround)
                        |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.outgoingStockPrognosis">
                        {{ dataItem.outgoingStockPrognosis == null ? "" : math.round(dataItem.outgoingStockPrognosis)
                        |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.stockGrossGround">
                        {{ dataItem.stockGrossGround == null ? "" : math.round(dataItem.stockGrossGround) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip" [title]="dataItem.buyingWeekly">
                        {{ dataItem.buyingWeekly == null ? "" : math.round(dataItem.buyingWeekly) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.orderGrossGround">
                        {{ dataItem.orderGrossGround == null ? "" : math.round(dataItem.orderGrossGround) |
                        thousandSeparator}}
                      </td>
                      <td class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.reductionAndDiscount">
                        {{ dataItem.reductionAndDiscount == null ? "" : math.round(dataItem.reductionAndDiscount) |
                        thousandSeparator}}
                      </td>
                      <td *ngIf="isOnline" class="organizationSummaryTableRowCell" data-toggle="tooltip"
                        [title]="dataItem.returns">
                        {{ dataItem.returns == null ? "" : math.round(dataItem.returns) | thousandSeparator}}
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
            <div class="table-gap"></div>
          </div>

        </div>
      </div>

    </ng-template>
  </div>
</ng-container>
