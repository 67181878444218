import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { AssortmentCopy } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/assortment-copy';
import { SalesCopy } from 'src/app/modules/selection-view/types/api/copy/get-all-copies/response/sales-copy';
import { ClearAllDialogData } from 'src/app/modules/selection-view/types/clear-all-dialog-data';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { ParentCalculatorDriverInterface } from 'src/app/modules/shared/calculators/parent-calculator-driver-interface';
import { AddMovesEvent } from 'src/app/modules/shared/events/department-events/add-moves-event';
import { AddRemoveEvent } from 'src/app/modules/shared/events/department-events/add-remove-event';
import { ClearAllEvent } from 'src/app/modules/shared/events/department-events/clear-all-event';
import { DepartmentEvent } from 'src/app/modules/shared/events/department-events/department-event';
import { DepartmentEventInfo } from 'src/app/modules/shared/events/department-events/department-event-info';
import { DepartmentEventType } from 'src/app/modules/shared/events/department-events/department-event-type';
import { FinPlanEvent } from 'src/app/modules/shared/events/department-events/fin-plan-event';
import { ForecastGrossEvent } from 'src/app/modules/shared/events/department-events/forecast-gross-event';
import { RAndDForecastPercentEvent } from 'src/app/modules/shared/events/department-events/r-and-d-forecast-percent-event';
import { RPercentEvent } from 'src/app/modules/shared/events/department-events/r-percent-event';
import { RetrievalModeEvent } from 'src/app/modules/shared/events/department-events/retrieval-mode-event';
import { AppInsightsLoggingService } from 'src/app/modules/shared/services/error-handling/app-insights-logging.service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UserConfigService } from 'src/app/modules/shared/services/user-config.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { ChannelType } from 'src/app/modules/shared/types/channel-type';
import { Constants } from 'src/app/modules/shared/types/constants';
import { MarketType } from 'src/app/modules/shared/types/market-type';
import { RetrievalMode } from 'src/app/modules/shared/types/retrieval-mode';
import { SeasonInfo } from 'src/app/modules/shared/types/season-info';
import { SeasonPlanningType } from 'src/app/modules/shared/types/season-planning-type';
import { SeasonType } from 'src/app/modules/shared/types/season-type';
import { SeasonViewType } from 'src/app/modules/shared/types/season-view-type';
import { StructureTypes } from 'src/app/modules/shared/types/structure-types';
import { EventHandlerService } from '../../services/event-handler-service';
import { PlanningViewActionHistoryService } from '../../services/planning-view-action-history-service';
import { PlanningViewService } from '../../services/planning-view.service';
import { SeasonDataService } from '../../services/season-data.service';
import { ParentSeasonTableInfo } from '../../types/api/parent-season-table-info';
import { PlanningViewDataRequest } from '../../types/api/planning-view-data-request';
import { ParentPlanningViewDataResponse } from '../../types/api/planning-view-data/parent-planning-view-data-response';
import { StashStatusData } from '../../types/api/stash-status-data';
import { CalculationDataItemType } from '../../types/calculation-data-item-type';
import { DepartmentCalculationDataItem } from '../../types/department-calculation-data-item';
import { InputLockStatus } from '../../types/input-lock-status-enum';
import { ParentCalculationDataItem } from '../../types/parent-calculation-data-item';
import { PlanningViewActionHistoryItem } from '../../types/planning-view-action-history-item';
import { ReturnsInfo } from '../../types/returns-info';
import { ClearAllDialogComponent } from '../clear-all/clear-all-dialog.component';
import { CopyForecastDialogComponent } from '../copy-forecast-dialog/copy-forecast-dialog.component';

@Component({
  selector: 'app-parent-season-table',
  templateUrl: './parent-season-table.component.html',
  styleUrls: ['./parent-season-table.component.css']
})
export class ParentSeasonTableComponent implements OnInit {

  @Input() seasonTableInfo: ParentSeasonTableInfo = null;
  @Input() debugMode: boolean = true;
  @Input() currentWeek: number = null;
  @Input() selectedKpis: string[] = null;
  @Input() isMain: boolean;
  @Input() channel: string;
  @Input() areSalesSystemGoalsIncludedForAssortment: boolean;
  @Input() totalViewDefaultRetrievalmode: string;

  driver: ParentCalculatorDriverInterface = null;
  dataSet: DepartmentCalculationDataItem[] = null;
  seasonInfo: SeasonInfo = null;
  returnsInfo: ReturnsInfo = null;
  isForecastEnabledList = [];
  isWeeklyInputEnabledList = [];
  quarters = [];
  quarterBorderForWeeks: boolean[] = [];
  quarterBorderForPeriods: boolean[] = [];
  quarterBorderForCalcPeriods: boolean[] = [];
  seasonTitle: string = null;

  dataSetIndexOffset = 0;

  lastWeekInCurrentView: number = null;

  disabledTillWeek: number = null;
  inputLockStatus: InputLockStatus = null;

  availableRetrievalModes: RetrievalMode[] = [];
  currentRetrievalMode: RetrievalMode = null;
  isChannelSum: boolean = null;
  isForecastDisabled: boolean = true;
  trickleDownDisabled: boolean = false;
  channelType: ChannelType = null;
  brandId: number = null;
  isAssortmentView: boolean = false;

  userConfigSubjectSubscription: Subscription;

  selectedMartketType: string = null;
  isPRCopy: boolean = false;
  addRemoveOrMovePattern: string = "^([0-9]{0,3}|\\-\\b[0-9]{0,3})([.|,][0-9]{0,3})?$";
  finPlanPattern: string = "^[0-9]+(.[0-9]{1})?$";


  seasonStartWeek: number = null;
  seasonEndWeek: number = null;
  areInputsDisabled: boolean = false;
  retrievalModeChangeErrorMessage = 'Failed to change the retrieval mode. Please try again.';
  constructor(private _utils: UtilsService,
    public dialog: MatDialog,
    private _ref: ChangeDetectorRef,
    private _eventHandlerService: EventHandlerService,
    private _seasonDataService: SeasonDataService,
    private _appInsightsLoggingService: AppInsightsLoggingService,
    private _loadingAnimationService: LoadingAnimationsService,
    private _planningViewActionHistoryService: PlanningViewActionHistoryService,
    private _planningViewService: PlanningViewService,
    private _userConfigService: UserConfigService,
    private _utilService: UtilsService) {
  }

  ngOnInit(): void {

    this.driver = this.seasonTableInfo.driver;
    this.dataSet = this.driver.getDataSet();
    this.seasonInfo = this.driver.getSeasonInfo();

    let userConfig = this.driver.getUserConfig();
    this.channelType = userConfig.planningViewOptions.channel;
    this.brandId = userConfig.selectionViewOptions.brand.id;
    this.isAssortmentView = userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT;

    let seasonViewType = this._utils.getSeasonViewType(this.driver.getViewDate());

    this.selectedMartketType = (userConfig.planningViewOptions.isChannelSum) ? "" : MarketType[userConfig.planningViewOptions.market.marketType];
    if (!this.isAssortmentView) {
      this.isPRCopy = (<SalesCopy>(userConfig.selectionViewOptions.copy)).isPRCopy;
    }

    switch (seasonViewType) {
      case SeasonViewType.View1:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW1_ENDWEEK))[0];
        break;
      case SeasonViewType.View2:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW2_ENDWEEK))[0];
        break;
      case SeasonViewType.View3:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW3_ENDWEEK))[0];
        break;
      case SeasonViewType.View4:
        this.lastWeekInCurrentView = _.filter(this.seasonInfo.weeksWithYear, x => x.toString().endsWith(Constants.TIMELINEVIEW4_ENDWEEK))[0];
        break;
    }

    this.seasonTitle = this.driver.getTitle();
    this.setupSeasonTable();
    this.setAvailableRetrievalModes();
    this.returnsInfo = this.driver.getReturnsInfo();
    this.isChannelSum = this.driver.getUserConfig().planningViewOptions.isChannelSum;

    this.seasonStartWeek = this._utils.getStartWeekForSeason(parseInt(this.seasonInfo.seasonCodeNames[0]));
    this.seasonEndWeek = this._utils.getEndWeekForSeason(parseInt(this.seasonInfo.seasonCodeNames[0]));

    //if isChannelSum or channel type is omni then disable trickleDown
    if (this.isChannelSum || this.channelType == 3) {
      this.trickleDownDisabled = true;
    }
    else
      this.trickleDownDisabled = false;

    this.areInputsDisabled = this.trickleDownDisabled || this.isMain;

    if (this.seasonInfo.seasonPlanningType == SeasonPlanningType.Total) {
      this.driver.addChangeEventListener(this.refreshDataSet.bind(this));
    }
    this.dataSetIndexOffset = _.findIndex(this.dataSet, x => x.weekName == this.driver.getSeasonInfo().weeksWithYear[0])

    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);

    this.userConfigSubjectSubscription = this._userConfigService.userConfigSubject.subscribe(userConfig => {

      // check if it's not null, don't get retrival mode from user config if total view
      if (userConfig && userConfig?.planningViewLayoutSettings?.seasonSettings && userConfig?.planningViewOptions?.structureType != StructureTypes.Total) {
        let seasonSetting = userConfig?.planningViewLayoutSettings?.seasonSettings.find(x => x.seasonPlanningType == this.driver.getSeasonInfo().seasonPlanningType);

        if (this._utils.isNotNullOrUndefined(seasonSetting)) {
          // check if the retrieval mode has changed
          if (seasonSetting.retrievalMode != this.driver.getRetrievalMode()) {
            // trigger a retrieval mode event
            this.handleEvent({
              "target": {
                "value": seasonSetting.retrievalMode
              }
            }, null, 'RetrievalMode');
          }
        }
      }
    })

  }

  async handleEvent(event, weekIndex, type: string) {
    let eventType: DepartmentEventType = this._utils.enumFromValue(type, DepartmentEventType)
    let eventObj = null;
    let previousValue = null;

    let eventInfo: DepartmentEventInfo = {
      eventType: eventType,
      newValue: event.target.value,
      previousValue: null,
      weekIndexes: null
    }

    let dataSet = this.driver.getDataSet();
    let weekName = weekIndex == null ? null : dataSet[weekIndex][CalculationDataItemType.WeekName];
    let actualWeekIndex = weekIndex == null ? null : dataSet.findIndex(x => x.weekName == weekName);

    switch (eventType) {
      case DepartmentEventType.RetrievalMode:
        let seasonDataReponse: ParentPlanningViewDataResponse = null;
        const retrievalModeBeforeChange = this.driver.getRetrievalMode();
        try {
          seasonDataReponse = await this.refreshSeasonDataTable(eventInfo);
        } catch {
          this._utilService.showGenericMessage(this.retrievalModeChangeErrorMessage, 'error-snackbar', 8000);
          // setting as null n then setting to previous value so that it comes under angular change detection.
          this.driver.setRetrievalMode(null);
          setTimeout(() => this.driver.setRetrievalMode(retrievalModeBeforeChange));
          this._loadingAnimationService.disableTopNavAnimation();
          return;
        }

        if (seasonDataReponse.weekData) {

          let stashData: StashStatusData = null;
          if (!this.isAssortmentView) {
            stashData = await this._planningViewService.getStashedData(this.driver.getUserConfig());
          }

          // since the data is fetched again from the backend, input values have to be reapplied
          for (let i = 0; i < seasonDataReponse.weekData.length; i++) {
            let dataSetItem = _.find(dataSet, x => x.weekName == seasonDataReponse.weekData[i].weekName);
            let stashItem = stashData && stashData.data ? _.find(stashData.data.weekData || [], x => x.weekId == dataSetItem.weekName && x.seasonId == dataSetItem.seasonName) : null;

            if (dataSetItem != undefined && dataSetItem) {
              if (dataSetItem['inputForecastGrossPeriodic']) {
                seasonDataReponse.weekData[i]['inputForecastGrossPeriodic'] = dataSetItem['inputForecastGrossPeriodic'];
              }
              if (dataSetItem['inputForecastGrossPeriodicOriginal']) {
                seasonDataReponse.weekData[i]['inputForecastGrossPeriodicOriginal'] = dataSetItem['inputForecastGrossPeriodicOriginal'];
              }
              if (dataSetItem['inputRAndDForecastPeriodic']) {
                seasonDataReponse.weekData[i]['inputRAndDForecastPeriodic'] = dataSetItem['inputRAndDForecastPeriodic'];
              }
            }

            // Don't keep unsaved inputted values for add moves and add/remove when changing retrieval mode, keep stashed values if any though (PSTDP-1294)
            if (stashItem) {
              if (stashItem['addMovesMSek']) {
                seasonDataReponse.weekData[i]['addMovesMSek'] = stashItem['addMovesMSek'];
              }
              if (stashItem['addRemoveMSek']) {
                seasonDataReponse.weekData[i]['addRemoveMSek'] = stashItem['addRemoveMSek'];
              }
            }

            // Need to set these either way since only addMovesMSek/addRemoveMSek have values if saved, not inputAddMovesMSekWeekly/inputAddRemoveMSekWeekly
            if (seasonDataReponse.weekData[i]['addMovesMSek']) seasonDataReponse.weekData[i]['inputAddMovesMSekWeekly'] = Number(seasonDataReponse.weekData[i]['addMovesMSek'].toFixed(3));
            if (seasonDataReponse.weekData[i]['addRemoveMSek']) seasonDataReponse.weekData[i]['inputAddRemoveMSekWeekly'] = Number(seasonDataReponse.weekData[i]['addRemoveMSek'].toFixed(3));
          }
          this.driver.setDataSet(seasonDataReponse.weekData);
          this.driver.setSalesAggregatedPmDataSet(dataSet);
        }
        previousValue = this.driver.getPreviousRetrievalMode();
        eventInfo.previousValue = previousValue;
        eventObj = new RetrievalModeEvent(eventInfo);
        break;
      case DepartmentEventType.ForecastGross:
        eventInfo.weekIndexes = weekIndex == null ? [] : this._utils.getPeriodIndexes(actualWeekIndex);
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputForecastGrossPeriodic);
        eventInfo.previousValue = previousValue;
        eventObj = new ForecastGrossEvent(eventInfo);
        break;
      case DepartmentEventType.RAndDForecastPercent:
        eventInfo.weekIndexes = weekIndex == null ? [] : this._utils.getPeriodIndexes(actualWeekIndex);
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputRAndDForecastPeriodic);
        eventInfo.previousValue = previousValue;
        eventObj = new RAndDForecastPercentEvent(eventInfo);
        break;
      case DepartmentEventType.AddMovesMsek:
        eventInfo.weekIndexes = weekIndex == null ? [] : [actualWeekIndex];
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputAddMovesMSekWeekly);
        eventInfo.previousValue = previousValue;
        eventObj = new AddMovesEvent(eventInfo);
        break;
      case DepartmentEventType.AddRemoveMsek:
        eventInfo.weekIndexes = weekIndex == null ? [] : [actualWeekIndex];
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputAddRemoveMSekWeekly);
        eventInfo.previousValue = previousValue;
        eventObj = new AddRemoveEvent(eventInfo);
        break;
      case DepartmentEventType.FinPlanMSek:
        eventInfo.weekIndexes = weekIndex == null ? [] : [actualWeekIndex];
        previousValue = this.driver.getPreviousInputItemValue(eventInfo.weekIndexes[0], CalculationDataItemType.InputFinPlanMSekWeekly);
        eventInfo.previousValue = previousValue;
        eventObj = new FinPlanEvent(eventInfo);
        break;
      case DepartmentEventType.RPercent:
        eventInfo.previousValue = this.driver.getPreviousAdjustedRPercent();
        break;
    }

    let processEvent = true;

    if (eventType != DepartmentEventType.ClearAll) {
      eventInfo.newValue = event.target.value;

      // both previous and new values are null and empty
      processEvent = !(this._utils.isNullOrEmpty(eventInfo.newValue.toString()) && this._utils.isNullOrEmpty(eventInfo.previousValue));

      // both values should not match 
      processEvent = processEvent && (parseFloat(eventInfo.newValue.toString()) !== eventInfo.previousValue);
    }

    if (processEvent) {

      if(eventType == DepartmentEventType.ForecastGross)
      {
        // set input dirty
        this.driver.rowInputsDirty = true;
      }


      if (eventType == DepartmentEventType.RPercent) {
        eventInfo.newValue = eventInfo.newValue.toString().trim() == "" ? null : parseFloat(eventInfo.newValue.toString());
        eventObj = new RPercentEvent(eventInfo);
      }

      if (eventType == DepartmentEventType.ClearAll) {

        eventInfo.previousValue = _.cloneDeep(this.driver.getDataSet());
        eventInfo.newValue = this.driver.getRawDataItems();
        eventObj = new ClearAllEvent(eventInfo);
      }
      this.driver.handleEvent(eventObj);

      if (eventType != DepartmentEventType.RetrievalMode && eventType != DepartmentEventType.ClearAll && eventType != DepartmentEventType.FinPlanMSek) {
        this.seasonTableInfo.driver.touched = true;
      }
      this._loadingAnimationService.disableTopNavAnimation();
      this.dataSet = this.driver.getDataSet();
      this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);
    }
  }

  refreshDataSet() {
    this.dataSet = this.driver.getDataSet();
    this.dataSet = this.dataSet.filter(el => this.seasonInfo.weeksWithYear.indexOf(el.weekName) > -1);
    this._ref.detectChanges();
  }

  async refreshSeasonDataTable(eventInfo: DepartmentEventInfo) {
    this._loadingAnimationService.enableTopNavAnimation();

    let userConfig = this.driver.getUserConfig();
    let isAssortmentView = false;

    if (userConfig.selectionViewOptions.organization.name == Constants.ASSORTMENT) {
      isAssortmentView = true
    }
    // create request for season level data // if channel sum is true, then we dont need to consider market. So its sending as 0
    let dataRequest: PlanningViewDataRequest = {
      isAssortmentCopy: isAssortmentView,
      copyId: isAssortmentView ? (<AssortmentCopy>userConfig.selectionViewOptions.copy).assortmentCopyId : (<SalesCopy>userConfig.selectionViewOptions.copy).salesCopyId,
      channelId: parseInt(userConfig.planningViewOptions.channel.toString()),
      marketId: (userConfig.planningViewOptions.isChannelSum) ? 0 : userConfig.planningViewOptions.market.marketIntegrationKey,
      marketType: (userConfig.planningViewOptions.isChannelSum) ? "" : MarketType[userConfig.planningViewOptions.market.marketType],
      planningSeasonType: this.seasonInfo.seasonPlanningType,
      structureId: userConfig.planningViewOptions.structureId,
      structureType: userConfig.planningViewOptions.structureType,
      selectedDate: new Date(),
      parentStructureId: userConfig.planningViewOptions.parentStructureId,
      parentStructureType: userConfig.planningViewOptions.parentStructureType,
      corporateBrandId: userConfig.selectionViewOptions.brand.id,
      retrievalMode: <string>eventInfo.newValue,
      isChannelSum: userConfig.planningViewOptions.isChannelSum,

      //added for creating main if its fake copy for sales
      isMain: userConfig.selectionViewOptions.copy.isMain,
      isPrCopy: !this.isAssortmentView ? (<SalesCopy>userConfig.selectionViewOptions.copy).isPRCopy : false,
      isPercentPm: !this.isAssortmentView ? (<SalesCopy>userConfig.selectionViewOptions.copy).isPercentPm : false,
      copyMarketType: !this.isAssortmentView ? MarketType[(<SalesCopy>userConfig.selectionViewOptions.copy).marketType] : '',
      copyMarketId: !this.isAssortmentView ? (<SalesCopy>userConfig.selectionViewOptions.copy).marketIntegrationKey : null,
      isStockVsSalesNet52Enabled: this.isAssortmentView
    }

    this._appInsightsLoggingService.logEvent("Requesting Parent Season Data When Retrieval Mode Change", dataRequest);
    // request for data
    return this._seasonDataService.getParentSeasonData(dataRequest, isAssortmentView);

  }

  private setupSeasonTable(): void {

    // quarters
    _.map(this.seasonInfo.quarters, quarter => {

      let colspan = (quarter.indexOf("Summer") != -1) ? 16 : 12;

      this.quarters.push({
        "name": quarter,
        "colspan": colspan
      });

    });

    // if the last quarter is summer, reduce 4 weeks from it
    if(this.quarters[this.quarters.length-1]["name"].indexOf("Summer") != -1)
    {
      this.quarters[this.quarters.length-1]["colspan"] = 12;
    }

    // this.quarters.push(
    //   {
    //     "name": "",
    //     "colspan": 4
    //   });


    this.isForecastEnabledList = [];

    this.isWeeklyInputEnabledList = [];

    // spring starts in the previous year
    // fall starts in the same year

    //Spring Season - Disabled till week 45 of previous year
    //Fall Season - Disabled till Week 17 of same year
    if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous) {
      if (this.seasonInfo.seasonType == SeasonType.Spring) {
        // get previous year number and append 45 to it
        this.disabledTillWeek = parseInt((parseInt(this.seasonInfo.seasonCodeNames[0].substring(0, 4)) - 1).toString() + "49");
      }
      else if (this.seasonInfo.seasonType == SeasonType.Fall) {
        // get same year number and append 16 to it
        this.disabledTillWeek = parseInt((parseInt(this.seasonInfo.seasonCodeNames[0].substring(0, 4))).toString() + "21");
      }
    }

    let viewPeriodWeeks = this.driver.getViewPeriodWeeksWithYear();
    // disable all periods before the current period
    for (let i = 0; i < this.seasonInfo.weeksWithYear.length; i++) {

      if (this.seasonInfo.weeksWithYear[i + 4] <= this.currentWeek) {
        this.isForecastEnabledList.push(false);
      }
      else if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this.seasonInfo.weeksWithYear[i + 4] <= this.disabledTillWeek) {
        this.isForecastEnabledList.push(false);
      }
      else {
        this.isForecastEnabledList.push(true);
      }

      // disable all weeks within the view period
      if (viewPeriodWeeks.indexOf(this.seasonInfo.weeksWithYear[i]) != -1 && viewPeriodWeeks.indexOf(this.seasonInfo.weeksWithYear[i]) > this.currentWeek) {
        this.isWeeklyInputEnabledList.push(false);
      }
      else {
        if (this.seasonInfo.weeksWithYear[i] < this.currentWeek) {
          this.isWeeklyInputEnabledList.push(false);
        }
        else if (this.seasonInfo.seasonPlanningType != SeasonPlanningType.Previous && this.seasonInfo.weeksWithYear[i] < this.disabledTillWeek) {
          this.isWeeklyInputEnabledList.push(false);
        }
        else {
          this.isWeeklyInputEnabledList.push(true);
        }
      }

    }

    //create a new list object and use to draw quarter in the season table
    this.quarters.forEach(quarter => {
      let length = quarter.colspan;
      let j = 1;
      for (let i = 0; i < length; i++) {
        this.quarterBorderForWeeks.push((i == length - 1) ? true : false);
        this.quarterBorderForCalcPeriods.push((i == length - 4) ? true : false);
        if (j % 4 == 0) {
          this.quarterBorderForPeriods.push((i == length - 1) ? true : false);
        }
        j++;
      }
    });
  }

  private setAvailableRetrievalModes(): void {

    const isTotalView = this.driver.getUserConfig().planningViewOptions.structureType == StructureTypes.Total;

    this.availableRetrievalModes = this._utils.getValidRetrievalModes(this.seasonInfo, this.isAssortmentView);

    // For total view always use default mode at load since that is the one used when generating total view
    if (isTotalView)
      this.driver.setRetrievalMode(this._utils.getSalesDefaultRetrievalModeBySeasonPlanningType(this.seasonInfo.seasonPlanningType, this.totalViewDefaultRetrievalmode));
  }

  filterInputCharacters(event) {
    // allow only numbers
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    }
    else if (charCode.keyCode === 109 || charCode.keyCode === 189) {
      return true;
    }
    return true;
  }

  lockInputs() {
    this.driver.setInputLockStatus(InputLockStatus.Locked);
  }

  unlockInputs() {
    this.driver.setInputLockStatus(InputLockStatus.Unlocked);
  }

  copyFromCellListener(event) {
    let eventText = document.getSelection();

    // skip formatting planning view details text
    if (this._utils.isNotNullOrUndefined(eventText) && !eventText.toString().startsWith("=====")) {
      event.preventDefault();
      event.clipboardData.setData('text/plain', eventText.toString().replace(/\n/g, '\t').replace(/,/g, "").replace(/ /g, ''));
    }
  }

  pasteIntoCellListener(event) {

    // blur out first
    document.getElementById("btnUserSettingMenu").focus();
    // for forecast gross input
    if (event.target && this._utils.isNotNullOrUndefined(event.target.getAttribute("data-cell-type"))) {

      // check if the event is for the current handler
      if (this._utils.isNotNullOrUndefined(event.target.getAttribute("data-season-planning-type")) && event.target.getAttribute("data-season-planning-type") == this.driver.getSeasonInfo().seasonPlanningType.toString()) {

        // if pasted from Excel there will be HTML we can parse to get empty cells etc, otherwise get text
        let incomingDataSet: string[];
        if (event.clipboardData.types.includes('text/html')) {
          const incomingDataHtml = event.clipboardData.getData('text/html');
          incomingDataSet = this._eventHandlerService.getPastedDataFromHtmlTextTable(incomingDataHtml);
        } else {
          // get the incoming clipboard data in text format
          const incomingData = event.clipboardData.getData('text');

          // remove all non numerals and -ve sign, replace multiple spaces with a single space and split into individual tokens
          incomingDataSet = incomingData.replace(/,/g, "").replace(/[^0-9.-]/g, ' ').replace(/\s\s+/g, ' ').split(" ");
        }

        // prevent the default copy operation
        event.preventDefault();

        // get the data set from the current driver
        let dataSet = this.driver.getDataSet();

        // check to ensure that only numbers go through
        let reg = /^([0-9]{0,5}|\-\b[0-9]{0,5})([.][0-9]{0,3})?$/;

        // remove non number tokens
        incomingDataSet = _.filter(incomingDataSet, (token) => reg.test(token));

        // if there are valid tokens to be pastd
        if (incomingDataSet.length != 0 && this._utils.isNotNullOrUndefined(event.target.getAttribute("data-cell-type"))) {

          let dataCellType = event.target.getAttribute("data-cell-type");

          // if pasted on to forecast gross input field
          if (dataCellType == "forecast-gross-input" || dataCellType == "forecast-rand-input" || dataCellType == "add-remove-input" || dataCellType == "add-moves-input" || dataCellType == "diff-plan-input") {

            let actionList: PlanningViewActionHistoryItem[] = [];

            // get the week index where the paste event was fired
            let weekNames = [];
            if (dataCellType == "add-remove-input" || dataCellType == "add-moves-input" || dataCellType == "diff-plan-input") {
              weekNames.push(parseInt(event.target.getAttribute("data-week-name")));
            }
            else {
              weekNames = event.target.getAttribute("data-period-weeks").split(',');
            }

            // get the list of valid weeks for the season and also the last possible week
            let validWeeks = this.driver.getSeasonInfo().weeksWithYear;
            let lastWeekOfSeason = validWeeks[validWeeks.length - 1];

            // loop over each token
            for (let i = 0; i < incomingDataSet.length; i++) {

              // to mark valid paste event
              let validEvent = true;

              let currentToken = incomingDataSet[i];

              // shift weeks for each consecutive period
              let adjustedPeriodWeeks = [];

              if (dataCellType == "add-remove-input" || dataCellType == "add-moves-input" || dataCellType == "diff-plan-input") {
                adjustedPeriodWeeks.push(this._utils.shiftWeeks(parseInt(weekNames[0]), i));
              }
              else {
                weekNames.forEach(weekName => {
                  adjustedPeriodWeeks.push(this._utils.shiftWeeks(parseInt(weekName), i * 4));
                });
              }



              // check if the period weeks are valid, if valid update the input field
              adjustedPeriodWeeks.forEach(adjustedWeekName => {

                let dataSetItemIndex = _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == adjustedWeekName);

                let validationRegex = null;

                // should be within season range and should exist
                if (parseInt(adjustedWeekName) <= lastWeekOfSeason && dataSetItemIndex != -1) {
                  switch (dataCellType) {
                    case "forecast-gross-input":
                      // validate forecast gross input

                      // only accept numbers of 1 to 5 digits length (6 if decimal)
                      validationRegex = /^[0-9.]{1,6}$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {

                        // get the indexes for all the weeks within the period and check the ground value
                        let periodIndexes = this._utils.getPeriodIndexes(dataSetItemIndex);

                        if (
                          dataSet[periodIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
                          dataSet[periodIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
                          dataSet[periodIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
                          dataSet[periodIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0
                        ) {
                          validEvent = false;
                        }
                        else {
                          dataSet[dataSetItemIndex][CalculationDataItemType.InputForecastGrossPeriodic] = parseFloat(currentToken);
                          // Update this as well, used for for change factor, might be calculated differently than the other one but should be the same on change
                          dataSet[dataSetItemIndex][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = dataSet[dataSetItemIndex][CalculationDataItemType.InputForecastGrossPeriodic];
                        }
                      }
                      else {
                        validEvent = false;
                      }
                      break;
                    case "forecast-rand-input":

                      // only accept numbers of 1 to 2 digits length (and maybe on decimal then it will be max 4 length)
                      validationRegex = /^[0-9.]{1,4}$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {

                        // get the indexes for all the weeks within the period and check the ground value
                        let periodIndexes = this._utils.getPeriodIndexes(dataSetItemIndex);

                        if (
                          (
                            dataSet[periodIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
                            dataSet[periodIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
                            dataSet[periodIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
                            dataSet[periodIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0
                          )
                          ||
                          (
                            dataSet[periodIndexes[0]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                            dataSet[periodIndexes[1]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                            dataSet[periodIndexes[2]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0 &&
                            dataSet[periodIndexes[3]][CalculationDataItemType.EffectiveSalesPlanWeekly] == 0
                          )
                        ) {
                          validEvent = false;
                        }
                        else {
                          dataSet[dataSetItemIndex][CalculationDataItemType.InputRAndDForecastPeriodic] = parseFloat(currentToken);
                        }

                      }
                      else {
                        validEvent = false;
                      }

                      break;
                    case "add-moves-input":

                      validationRegex = /^([0-9]{0,3}|\-\b[0-9]{0,3})([.][0-9]{0,3})?$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken) && parseInt(adjustedWeekName) <= lastWeekOfSeason) {
                        dataSet[dataSetItemIndex][CalculationDataItemType.InputAddMovesMSekWeekly] = parseFloat(currentToken);
                        dataSet[dataSetItemIndex][CalculationDataItemType.AddMovesMSek] = parseFloat(currentToken);
                      }
                      else {
                        validEvent = false;
                      }

                      break;
                    case "add-remove-input":

                      validationRegex = /^([0-9]{0,3}|\-\b[0-9]{0,3})([.][0-9]{0,3})?$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken) && parseInt(adjustedWeekName) <= this.seasonEndWeek) {
                        dataSet[dataSetItemIndex][CalculationDataItemType.InputAddRemoveMSekWeekly] = parseFloat(currentToken);
                        dataSet[dataSetItemIndex][CalculationDataItemType.AddRemoveMSek] = parseFloat(currentToken);
                      }
                      else {
                        validEvent = false;
                      }
                      break;

                    case "diff-plan-input":
                      validationRegex = /^[0-9]+(.[0-9]{1})?$/gm;

                      if (currentToken.trim() != "" && validationRegex.test(currentToken)) {
                        dataSet[dataSetItemIndex][CalculationDataItemType.InputFinPlanMSekWeekly] = parseFloat(currentToken);
                        dataSet[dataSetItemIndex][CalculationDataItemType.FinPlanMSek] = parseFloat(currentToken);
                      }
                      else {
                        validEvent = false;
                      }

                      break;
                  }
                }
                else {
                  validEvent = false;
                }
              });

              if (validEvent) {

                let dataSetItemPeriodIndex = _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == adjustedPeriodWeeks[0]);

                let eventInfo: DepartmentEventInfo;
                let event: DepartmentEvent;
                // create event objects
                if (dataCellType == "forecast-gross-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.ForecastGross,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputForecastGrossPeriodic),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex, dataSetItemPeriodIndex + 1, dataSetItemPeriodIndex + 2, dataSetItemPeriodIndex + 3]
                  }

                  event = new ForecastGrossEvent(eventInfo);
                }
                else if (dataCellType == "forecast-rand-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.RAndDForecastPercent,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputRAndDForecastPeriodic),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex, dataSetItemPeriodIndex + 1, dataSetItemPeriodIndex + 2, dataSetItemPeriodIndex + 3]
                  }
                  event = new RAndDForecastPercentEvent(eventInfo);
                }
                else if (dataCellType == "add-moves-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.AddMovesMsek,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputAddMovesMSekWeekly),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex]
                  }
                  event = new AddMovesEvent(eventInfo);
                }
                else if (dataCellType == "add-remove-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.AddRemoveMsek,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputAddRemoveMSekWeekly),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex]
                  }

                  event = new AddRemoveEvent(eventInfo);
                }
                else if (dataCellType == "diff-plan-input") {
                  eventInfo = {
                    eventType: DepartmentEventType.FinPlanMSek,
                    previousValue: this.driver.getPreviousInputItemValue(dataSetItemPeriodIndex, CalculationDataItemType.InputFinPlanMSekWeekly),
                    newValue: currentToken,
                    weekIndexes: [dataSetItemPeriodIndex]
                  }

                  event = new FinPlanEvent(eventInfo);
                }

                let eventItem: PlanningViewActionHistoryItem = {
                  driver: this.driver,
                  event: event
                }

                actionList.push(eventItem);

                if (this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.PreviousWithOld || this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Previous) {

                  // for previous and old, fire events as and when they happen

                  let eventObj = {
                    target: {
                      value: event.eventInfo.newValue
                    }
                  }

                  switch (dataCellType) {
                    case "forecast-gross-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
                      break;
                    case "forecast-rand-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.RAndDForecastPercent);
                      break;
                    case "add-moves-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.AddMovesMsek);
                      break;
                    case "add-remove-input":
                      this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.AddRemoveMsek);
                      break;
                  }

                }
              }
            }

            if (actionList.length > 0 && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOld && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Previous) {

              // fire event on the last token, to prevent calculations from happening for each event.            
              let lastEvent = actionList.pop();

              actionList.forEach(actionEvent => {
                this._planningViewActionHistoryService.addActionEvent(actionEvent.event, this.driver);
              });

              // set the dataset with the given values
              this.driver.setDataSet(dataSet);

              let eventObj = {
                target: {
                  value: lastEvent.event.eventInfo.newValue
                }
              }

              switch (dataCellType) {
                case "forecast-gross-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
                  break;
                case "forecast-rand-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.RAndDForecastPercent);
                  break;
                case "add-moves-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.AddMovesMsek);
                  break;
                case "add-remove-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.AddRemoveMsek);
                  break;
                case "diff-plan-input":
                  this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.FinPlanMSek);
                  break;
              }
            }
          }

        }



      }
    }
  }

  openClearDialog(event) {
    let clear: ClearAllDialogData;

    clear = {
      seasonName: this.seasonInfo.seasonCodeNames[0],
      driverDialogDataItem: <CalculatorDriverInterface|ParentCalculatorDriverInterface>this.driver
    }
    this.dialog.open<ClearAllDialogComponent, ClearAllDialogData>(ClearAllDialogComponent, {
      data: clear,
      disableClose: true
    }).afterClosed().subscribe(result => {
      if (result) {
        this.handleEvent(event, 0, DepartmentEventType.ClearAll);
      }
    });
  }

  openCopyForecastDialog() {

    this.dialog.open<CopyForecastDialogComponent>(CopyForecastDialogComponent)
      .afterClosed().subscribe(result => {
        if (result) {
          console.log("check");
          this.copyForecastGoals();
        }
      });
  }

  copyForecastGoals() {

    // get the data set from the current driver
    let dataSet = this.driver.getDataSet();

    let actionList: PlanningViewActionHistoryItem[] = [];

    // get the list of valid weeks for the season and also the last possible week
    let validWeeks = this.driver.getSeasonInfo().weeksWithYear;
    let lastWeekOfSeason = validWeeks[validWeeks.length - 1];

    let startWeekNumber = this.seasonStartWeek > this.currentWeek ? this.seasonStartWeek : this.currentWeek;


    let currentWeekItemIndex = _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == startWeekNumber);
    let lastWeekItemIndex =  _.findIndex(dataSet, dataSetItem => dataSetItem[CalculationDataItemType.WeekName] == lastWeekOfSeason);


    // loop over each week
    for (let index = currentWeekItemIndex; index <= lastWeekItemIndex; index++) {

      // to mark valid paste event
      let validEvent = true;

      let currentToken = dataSet[index][CalculationDataItemType.AggregatedPMsGrossTaktPeriodic];

      // get the indexes for all the weeks within the period and check the ground value
      let periodIndexes = this._utils.getPeriodIndexes(index);

      if (
        dataSet[periodIndexes[0]][CalculationDataItemType.GrossSalesGround] == 0 &&
        dataSet[periodIndexes[1]][CalculationDataItemType.GrossSalesGround] == 0 &&
        dataSet[periodIndexes[2]][CalculationDataItemType.GrossSalesGround] == 0 &&
        dataSet[periodIndexes[3]][CalculationDataItemType.GrossSalesGround] == 0
      ) {
        validEvent = false;
      }
      else {
        dataSet[index][CalculationDataItemType.InputForecastGrossPeriodic] = Number(currentToken.toFixed(2));
        // Update this as well, used for for change factor, might be calculated differently than the other one but should be the same on change
        dataSet[index][CalculationDataItemType.InputForecastGrossPeriodicOriginal] = dataSet[index][CalculationDataItemType.InputForecastGrossPeriodic];
      }

      if (validEvent && actionList.findIndex(x => x.event.eventInfo.weekIndexes[0] == periodIndexes[0]) == -1) {

        let eventInfo: DepartmentEventInfo;
        let event: DepartmentEvent;

        // create event objects
        eventInfo = {
          eventType: DepartmentEventType.ForecastGross,
          previousValue: this.driver.getPreviousInputItemValue(index, CalculationDataItemType.InputForecastGrossPeriodic),
          newValue: Number(currentToken.toFixed(2)),
          weekIndexes: [periodIndexes[0], periodIndexes[1], periodIndexes[2], periodIndexes[3]]
        }

        event = new ForecastGrossEvent(eventInfo);

        let eventItem: PlanningViewActionHistoryItem = {
          driver: this.driver,
          event: event
        }

        actionList.push(eventItem);

        if (this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.PreviousWithOld || this.driver.getSeasonInfo().seasonPlanningType == SeasonPlanningType.Previous) {

          // for previous and old, fire events as and when they happen
          let eventObj = {
            target: {
              value: event.eventInfo.newValue
            }
          }

          this.handleEvent(eventObj, event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
        }
      }
    }

    if (actionList.length > 0 && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.PreviousWithOld && this.driver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Previous) {

      // fire event on the last token, to prevent calculations from happening for each event.            
      let lastEvent = actionList.pop();

      actionList.forEach(actionEvent => {
        this._planningViewActionHistoryService.addActionEvent(actionEvent.event, this.driver);
      });

      // set the dataset with the given values
      this.driver.setDataSet(dataSet);

      let eventObj = {
        target: {
          value: lastEvent.event.eventInfo.newValue
        }
      }

      this.handleEvent(eventObj, lastEvent.event.eventInfo.weekIndexes[0], DepartmentEventType.ForecastGross);
    }
  }

  ngOnDestroy() {
    this.userConfigSubjectSubscription?.unsubscribe();
  }

  validateAddMoveInput(event, inputDataItem: DepartmentCalculationDataItem, inputType: string) {
    var regEx = new RegExp(this.addRemoveOrMovePattern, "g");
    if (!regEx.test(event.target.value)) {
      // if test fails
      event.target.value = event.target.value
        .replace(/,/g, ".")
        .replace(/[^(\-)?\d.]/g, '')
        .replace(/(^(-)?[\d]{3})[\d]/g, '$1')
        .replace(/(\..*)\./g, '$1')
        .replace(/(\.[\d]{3})./g, '$1');

      // check and reassign the value to inputdata item
      if (inputType === Constants.ADDREMOVEMSEK && regEx.test(event.target.value)) {
        inputDataItem.addRemoveMSek = event.target.value;
      }
      else if (inputType === Constants.ADDMOVESMSEK && regEx.test(event.target.value)) {
        inputDataItem.addMovesMSek = event.target.value;
      }
    }
  }

  validateRPercentInput(event, inputDataItem: DepartmentCalculationDataItem, inputType: string) {
    var regEx = new RegExp(this.addRemoveOrMovePattern, "g");
    if (!regEx.test(event.target.value)) {
      // if test fails
      event.target.value = event.target.value
        .replace(/,/g, ".")
        .replace(/[^(\-)?\d.]/g, '')
        .replace(/(^(-)?[\d]{3})[\d]/g, '$1')
        .replace(/(\..*)\./g, '$1')
        .replace(/(\.[\d]{3})./g, '$1');

      this.returnsInfo.adjustedReturnPercent = event.target.value;
    }
    else {
      // if test passes
      // replace comma wit decimal dots.
      let formattedValue = event.target.value.replace(/,/g, ".")
      if (inputType == "AdjustedRPercent") {
        this.returnsInfo.adjustedReturnPercent = formattedValue;
      }
    }

    // lock input fields
    this.driver.rPercentDirty = (this.returnsInfo.adjustedReturnPercent??'') != (this.driver.getInitialAdjustedRPercent()?? '');
  }

  validateFinPlanInput(event, inputDataItem: ParentCalculationDataItem) {
    var regEx = new RegExp(this.finPlanPattern, "g");

    if (!regEx.test(event.target.value)) {
      event.target.value = event.target.value
        .replace(/,/g, ".")
        .replace(/[^\d.]/g, '')
        .replace(/(^[\d]{10})[\d]/g, '$1')
        .replace(/(\..*)\./g, '$1')
        .replace(/(\.[\d]{1})./g, '$1');

      if (regEx.test(event.target.value)) {
        inputDataItem.inputFinPlanMSekWeekly = event.target.value;
      }
    }
  }

}
