<ng-container>
    <div class="planning-view-main-pane simulation-view">
        <div class="col-12">

            <div class="row d-flex justify-content-center">
                <div class="col-md-12 week-picker">
                    <mat-form-field appearance="outline" class="week-selection-section">
                        <mat-label>Start Week</mat-label>

                        <mat-select panelClass="custom-select-panel" [(ngModel)]="selectedStartWeek" [hideSingleSelectionIndicator]="true"
                            (selectionChange)="presetCustomWeekSelections();updateEndWeeks(); weekSelectionChanged(); emitSelectionChangedEvent();"
                            (openedChange)="focusCustomStartWeek($event);">
                            <input type="number" [(ngModel)]="customSelectStartWeek" (blur)="validateCustomStartWeek($event);"
                                class="custom-week-selection" id="txtCustomStartWeekSelection" />
                            <div class="custom-week-section">
                                <mat-option *ngFor="let week of startweeksList" [value]="week">
                                    {{week}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="week-selection-section">
                        <mat-label>End Week</mat-label>
                        <mat-select panelClass="custom-select-panel" [(ngModel)]="selectedEndWeek" [hideSingleSelectionIndicator]="true"
                            (selectionChange)="presetCustomWeekSelections();weekSelectionChanged(); emitSelectionChangedEvent();"
                            (openedChange)="focusCustomEndWeek($event);">
                            <input type="number" [(ngModel)]="customSelectEndWeek" (blur)="validateCustomEndWeek();"
                                class="custom-week-selection" id="txtCustomEndWeekSelection" />
                            <div class="custom-week-section">
                                <mat-option *ngFor="let week of endweeksList" [value]="week">
                                    {{week}}
                                </mat-option>
                            </div>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div *ngIf="selectedRetrievalModeText.length != 0" class="selected-retrieval-mode-text">
                {{selectedRetrievalModeText}}</div>
            <div class="form-group">
                <div class="col-12">
                    <div class="table-container">
                        <div class="index-values-section">
                            <table class='simulateTable index-values-table'>
                                <tbody>
                                    <tr class="simulateTableHeaderRow">
                                        <td colspan="18" class="simulateTableHeaderCell"
                                            style="background-color: #a2cdf5;">Index Values</td>
                                    </tr>
                                    <tr class="simulateTableHeaderRow">
                                        <td class="simulateTableHeaderCell"></td>
                                        <td colspan="5" class="simulateTableHeaderCell">
                                            SALES w.{{selectedStartWeek}}- {{selectedEndWeek}}</td>
                                        <td colspan="7" class="simulateTableHeaderCell">
                                            Stock w.{{selectedStartWeek}}- {{selectedEndWeek}}</td>
                                        <td colspan="3" class="simulateTableHeaderCell reduction-header">
                                            RedCost w.{{selectedStartWeek}} - {{selectedEndWeek}}</td>
                                        <td class="simulateTableHeaderCell ordered-index-header" style="width: 10%;">
                                            Order Idx w.{{orderIdxStartWeek}} - {{orderIdxEndWeek}}</td>
                                        <td *ngIf="isOnline" class="simulateTableHeaderCell returns-header"
                                            style="width: 10%;">
                                            R% w.{{selectedStartWeek}} - {{selectedEndWeek}}</td>
                                    </tr>
                                    <tr class="simulateTableSubHeaderRow">
                                        <td class="simulateTableSubHeaderCell">Season</td>
                                        <td class="simulateTableSubHeaderCell">Gross Takt</td>
                                        <td class="simulateTableSubHeaderCell">Net Takt</td>
                                        <td class="simulateTableSubHeaderCell">Diff vs Plan</td>
                                        <td class="simulateTableSubHeaderCell">SOB %</td>
                                        <td class="simulateTableSubHeaderCell">SOB % Gnd</td>
                                        <td class="simulateTableSubHeaderCell">Ingoing Stock IDX</td>
                                        <td class="simulateTableSubHeaderCell">Average Stock IDX</td>
                                        <td class="simulateTableSubHeaderCell">Outgoing Stock IDX</td>
                                        <td class="simulateTableSubHeaderCell">SOB %</td>
                                        <td class="simulateTableSubHeaderCell">SOB % Gnd</td>
                                        <td class="simulateTableSubHeaderCell">Average S%</td>
                                        <td class="simulateTableSubHeaderCell">Average S% Gnd</td>
                                        <td class="simulateTableSubHeaderCell">RED vs LY</td>
                                        <td class="simulateTableSubHeaderCell">RED %</td>
                                        <td class="simulateTableSubHeaderCell">RED % Gnd</td>
                                        <td class="simulateTableSubHeaderCell">Ordered Index</td>
                                        <td *ngIf="isOnline" class="simulateTableSubHeaderCell">Return %</td>
                                    </tr>
                                    <ng-container *ngFor="let seasonIndexData of seasonIndexDataSet">
                                        <tr
                                            [ngClass]="{'simulateTableTotalRow': seasonIndexData.seasonName == 'All Seasons', 'simulateTableRow' : seasonIndexData.seasonName != 'All Seasons' }">
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData?.seasonName.split('(')[0].replace('All Seasons', 'Total')">
                                                {{seasonIndexData?.seasonName.split('(')[0].replace("All
                                                Seasons","Total")}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.grossTakt == null ? '' : seasonIndexData.grossTakt">
                                                {{seasonIndexData.grossTakt == null ? "" :
                                                math.round(seasonIndexData.grossTakt)}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.netTakt == null ? '' : seasonIndexData.netTakt">
                                                {{seasonIndexData.netTakt == null ? ""
                                                :math.round(seasonIndexData.netTakt)}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.diffVsPlan == null ? '' : seasonIndexData.diffVsPlan">
                                                {{seasonIndexData.diffVsPlan == null ? "" :
                                                math.round(seasonIndexData.diffVsPlan)}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.salesSOBPercent == null ? '' : seasonIndexData.salesSOBPercent">
                                                {{seasonIndexData.salesSOBPercent == null ? "" :
                                                math.round(seasonIndexData.salesSOBPercent)}}%</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.salesSOBPercentGround == null ? '' : seasonIndexData.salesSOBPercentGround">
                                                {{seasonIndexData.salesSOBPercentGround == null ? "" :
                                                math.round(seasonIndexData.salesSOBPercentGround)}}%</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.ingoingStockIndex == null ? '' : seasonIndexData.ingoingStockIndex">
                                                {{seasonIndexData.ingoingStockIndex == null ? "" :
                                                math.round(seasonIndexData.ingoingStockIndex)}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.averageStockIndex == null ? '' : seasonIndexData.averageStockIndex">
                                                {{seasonIndexData.averageStockIndex == null ? "" :
                                                math.round(seasonIndexData.averageStockIndex)}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.outgoingStockIndex == null ? '' : seasonIndexData.outgoingStockIndex">
                                                {{seasonIndexData.outgoingStockIndex == null ? "" :
                                                math.round(seasonIndexData.outgoingStockIndex)}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.stockSOBPercent == null ? '' : seasonIndexData.stockSOBPercent">
                                                {{seasonIndexData.stockSOBPercent == null ? "" :
                                                math.round(seasonIndexData.stockSOBPercent)}}%</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.stockSOBPercentGround == null ? '' : seasonIndexData.stockSOBPercentGround">
                                                {{seasonIndexData.stockSOBPercentGround == null ? "" :
                                                math.round(seasonIndexData.stockSOBPercentGround)}}%</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.averageSPercent == null ? '' : seasonIndexData.averageSPercent">
                                                {{seasonIndexData.averageSPercent == null ? "" :
                                                seasonIndexData.averageSPercent.toFixed(1)}}%</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.averageSPercentGround == null ? '' : seasonIndexData.averageSPercentGround">
                                                {{seasonIndexData.averageSPercentGround == null ? "" :
                                                seasonIndexData.averageSPercentGround.toFixed(1)}}%</td>
                                            <td class="simulateTableRowCell reduction-cell" data-toggle="tooltip"
                                                [title]="seasonIndexData.redVsLy == null ? '' : seasonIndexData.redVsLy">
                                                {{seasonIndexData.redVsLy == null ? "" :
                                                math.round(seasonIndexData.redVsLy)}}</td>
                                            <td class="simulateTableRowCell reduction-cell" data-toggle="tooltip"
                                                [title]="seasonIndexData.redPercent == null ? '' : seasonIndexData.redPercent">
                                                {{seasonIndexData.redPercent == null ? "" :
                                                seasonIndexData.redPercent.toFixed(1)}}%</td>
                                            <td class="simulateTableRowCell reduction-cell" data-toggle="tooltip"
                                                [title]="seasonIndexData.redPercentGround == null ? '' : seasonIndexData.redPercentGround">
                                                {{seasonIndexData.redPercentGround == null ? "" :
                                                seasonIndexData.redPercentGround.toFixed(1)}}%</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.orderedIndex == null ? '' : seasonIndexData.orderedIndex">
                                                {{seasonIndexData.orderedIndex == null ? "" :
                                                math.round(seasonIndexData.orderedIndex)}}</td>
                                            <td *ngIf="isOnline" class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonIndexData.returnPercent == null ? '' : seasonIndexData.returnPercent">
                                                {{seasonIndexData.returnPercent == null ? "" :
                                                math.round(seasonIndexData.returnPercent)}}%</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <div class="monetary-values-section">

                            <table class='simulateTable monetary-values-table'>
                                <tbody>
                                    <tr class="simulateTableHeaderRow">
                                        <td colspan="16" class="simulateTableHeaderCell"
                                            style="background-color: #a2cdf5;">Monetary Values</td>
                                    </tr>
                                    <tr class="simulateTableHeaderRow">
                                        <td class="simulateTableHeaderCell"></td>
                                        <td colspan="4" class="simulateTableHeaderCell">
                                            SALES w.{{selectedStartWeek}}- {{selectedEndWeek}}</td>
                                        <td colspan="6" class="simulateTableHeaderCell">
                                            STOCK w.{{selectedStartWeek}}- {{selectedEndWeek}}</td>
                                        <td colspan="2" class="simulateTableHeaderCell reduction-header">
                                            RedCost w.{{selectedStartWeek}} - {{selectedEndWeek}}</td>
                                        <td colspan="2" class="simulateTableHeaderCell ordered-index-header"
                                            style="width: 10%;">
                                            Order w.{{orderIdxStartWeek}} - {{orderIdxEndWeek}}</td>
                                        <td *ngIf="isOnline" class="simulateTableHeaderCell returns-header"
                                            style="width: 10%;">
                                            Returns w.{{selectedStartWeek}} - {{selectedEndWeek}}</td>
                                    </tr>
                                    <tr class="simulateTableSubHeaderRow">
                                        <td class="simulateTableSubHeaderCell">Season</td>
                                        <td class="simulateTableSubHeaderCell">Sales Gross Actual</td>
                                        <td class="simulateTableSubHeaderCell">Sales Ground</td>
                                        <td class="simulateTableSubHeaderCell">Sales Net TY</td>
                                        <td class="simulateTableSubHeaderCell">Sales Net Ground</td>
                                        <td class="simulateTableSubHeaderCell">Stock Gross Outgoing TY</td>
                                        <td class="simulateTableSubHeaderCell">Stock Gross Outgoing Ground</td>
                                        <td class="simulateTableSubHeaderCell">Stock Gross Ingoing TY</td>
                                        <td class="simulateTableSubHeaderCell">Stock Gross Ingoing Ground</td>
                                        <td class="simulateTableSubHeaderCell">Acc Stock Gross</td>
                                        <td class="simulateTableSubHeaderCell">Acc Stock Gross Ground</td>
                                        <td class="simulateTableSubHeaderCell">RED TSEK</td>
                                        <td class="simulateTableSubHeaderCell">RED Ground</td>
                                        <td class="simulateTableSubHeaderCell">Ordered Planned</td>
                                        <td class="simulateTableSubHeaderCell">Ordered Ground</td>
                                        <td *ngIf="isOnline" class="simulateTableSubHeaderCell">Returns TSEK</td>
                                    </tr>
                                    <ng-container *ngFor="let seasonMonetaryData of seasonMonetaryValuesDataSet">
                                        <tr
                                            [ngClass]="{'simulateTableTotalRow': seasonMonetaryData.seasonName == 'All Seasons', 'simulateTableRow' : seasonMonetaryData.seasonName != 'All Seasons' }">
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData?.seasonName.split('(')[0].replace('All Seasons','Total')">
                                                {{seasonMonetaryData?.seasonName.split('(')[0].replace("All
                                                Seasons","Total")}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.salesDemdelGrossActual == null ? '' : seasonMonetaryData.salesDemdelGrossActual">
                                                {{seasonMonetaryData.salesDemdelGrossActual == null ? "" :
                                                math.round(seasonMonetaryData.salesDemdelGrossActual) |
                                                thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.salesDemdelGround == null ? '' : seasonMonetaryData.salesDemdelGround">
                                                {{seasonMonetaryData.salesDemdelGround == null ? "" :
                                                math.round(seasonMonetaryData.salesDemdelGround) | thousandSeparator }}
                                            </td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.salesDemdelNetTy == null ? '' : seasonMonetaryData.salesDemdelNetTy">
                                                {{seasonMonetaryData.salesDemdelNetTy == null ? "" :
                                                math.round(seasonMonetaryData.salesDemdelNetTy) | thousandSeparator }}
                                            </td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.salesDemdelNetGround == null ? '': seasonMonetaryData.salesDemdelNetGround">
                                                {{seasonMonetaryData.salesDemdelNetGround == null ? "" :
                                                math.round(seasonMonetaryData.salesDemdelNetGround) |
                                                thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.stockGrossOutgoingTy == null ? '' : seasonMonetaryData.stockGrossOutgoingTy">
                                                {{seasonMonetaryData.stockGrossOutgoingTy == null ? "" :
                                                math.round(seasonMonetaryData.stockGrossOutgoingTy) |
                                                thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.stockGrossOutgoingGround == null ? '' : seasonMonetaryData.stockGrossOutgoingGround">
                                                {{seasonMonetaryData.stockGrossOutgoingGround == null ? "" :
                                                math.round(seasonMonetaryData.stockGrossOutgoingGround) |
                                                thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.stockGrossIngoingTy == null ? '' : seasonMonetaryData.stockGrossIngoingTy">
                                                {{seasonMonetaryData.stockGrossIngoingTy == null ? "" :
                                                math.round(seasonMonetaryData.stockGrossIngoingTy) | thousandSeparator}}
                                            </td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.stockGrossIngoingGround == null ? '' : seasonMonetaryData.stockGrossIngoingGround">
                                                {{seasonMonetaryData.stockGrossIngoingGround == null ? "" :
                                                math.round(seasonMonetaryData.stockGrossIngoingGround) |
                                                thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.accStockGross == null ? '' : seasonMonetaryData.accStockGross">
                                                {{seasonMonetaryData.accStockGross == null ? "" :
                                                math.round(seasonMonetaryData.accStockGross) | thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.accStockGrossGround == null ? '' : seasonMonetaryData.accStockGrossGround">
                                                {{seasonMonetaryData.accStockGrossGround == null ? "" :
                                                math.round(seasonMonetaryData.accStockGrossGround) | thousandSeparator}}
                                            </td>
                                            <td class="simulateTableRowCell reduction-cell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.red == null ? '' : seasonMonetaryData.red">
                                                {{seasonMonetaryData.red == null ? "" :
                                                math.round(seasonMonetaryData.red) | thousandSeparator}}</td>
                                            <td class="simulateTableRowCell reduction-cell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.redGround == null ? '' : seasonMonetaryData.redGround">
                                                {{seasonMonetaryData.redGround == null ? "" :
                                                math.round(seasonMonetaryData.redGround) | thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.orderedPlanned == null ? '' : seasonMonetaryData.orderedPlanned">
                                                {{seasonMonetaryData.orderedPlanned == null ? "" :
                                                math.round(seasonMonetaryData.orderedPlanned) | thousandSeparator}}</td>
                                            <td class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.orderedGround == null ? '' : seasonMonetaryData.orderedGround">
                                                {{seasonMonetaryData.orderedGround == null ? "" :
                                                math.round(seasonMonetaryData.orderedGround) | thousandSeparator}}</td>
                                            <td *ngIf="isOnline" class="simulateTableRowCell" data-toggle="tooltip"
                                                [title]="seasonMonetaryData.returns == null ? '' : seasonMonetaryData.returns">
                                                {{seasonMonetaryData.returns == null ? "" :
                                                math.round(seasonMonetaryData.returns) | thousandSeparator}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>