import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class SalesSystemGoalCalculator extends AbstractCalculator {

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {

        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        let salesMainCopyCombinedSalesPlanWeeklySum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.SalesMainCopyCombinedSalesPlanWeekly);
        let salesMainCopyGrossSalesGroundSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.SalesMainCopyGrossSalesGround);;

        if (utils.isNotNullUndefinedOrZero(salesMainCopyGrossSalesGroundSum)) {
            value = salesMainCopyCombinedSalesPlanWeeklySum / salesMainCopyGrossSalesGroundSum * 100;
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.SalesMainCopySystemGoal] = value;
        });
    }
}