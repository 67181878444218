import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { StructureTypes } from "../../types/structure-types";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";

export class ClearAllEvent extends DepartmentEvent
{
    getEventType(): DepartmentEventType {
        return DepartmentEventType.ClearAll
    }

    validateEvent(): boolean {
        return true;
    }

    applyEvent(driver: CalculatorDriverInterface): void {   
        const seasonInfo = driver.getSeasonInfo();
        const userConfig = driver.getUserConfig();

        // skipping for previousnold because individual previousoldwithchild handle the event
        // & aggregated later to get PreviousWithOld old (if department)

        const isPrevious = seasonInfo.seasonPlanningType == SeasonPlanningType.PreviousWithOld ||
          seasonInfo.seasonPlanningType == SeasonPlanningType.Previous;
        const isDepartment = userConfig.planningViewOptions.structureType == StructureTypes.Department;

        if (!isPrevious || !isDepartment) {
            const unmodifiedDataSet = this.eventInfo.newValue;
            let dataSet = driver.getDataSet();

            dataSet.forEach((dataSetItem, index) => {
                Object.keys(dataSetItem).forEach(key => {
                    if (dataSetItem[key] != unmodifiedDataSet[index][key]) {
                        dataSetItem[key] = unmodifiedDataSet[index][key];
                    }
                });
            });
            
            driver.setDataSet(dataSet);
        }
    }

    configureCalculators(driver: CalculatorDriverInterface): void {       
        driver.configureCalculatorsForClear();
    }
}
