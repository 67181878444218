import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class RAndDPercentCalculator extends AbstractCalculator
{
    // Dependencies
    // ------------
    // Effective Sales Plan Weekly
    // R And D Plan
    // R And PercentPeriodic
    // Combined Sales Plan Weekly
    // Combined R and D Weekly 

    // Calculates
    // ----------
    // Ground R And D Percent Weekly
    // Ground R And D Percent Periodic


    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem)
    {
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        
        // R&D% = (R&D/Gross Sales) * 100

        if (
            utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSales]) &&
            utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.RAndD])
        ) {
            value = (currentWeekItem[CalculationDataItemType.RAndD] / currentWeekItem[CalculationDataItemType.GrossSales]) * 100;
        }

        currentWeekItem[CalculationDataItemType.RAndDPercentWeekly] = value;
    }

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[])
    {
        let value = 0;
        let utils = this.calculatorDriver.getUtilsService();

        var firstWeekOfViewPeriod  = this.calculatorDriver.getViewPeriodWeeksWithYear()[0];

        let seasonPlanningType = this.calculatorDriver.getSeasonInfo().seasonPlanningType;

        //Append forecast in case of total 
        if(currentPeriodWeekItems[0].weekName >= firstWeekOfViewPeriod)
        {
            // Current + Future
            // Average R&D% = (Sum of combined R&D for period/Sum of  Combined Gross Sales) * 100
            let combinedSalesSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedSalesPlanWeekly);
            let combinedRAndDSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.CombinedRAndDWeekly);

            // average it
            if (utils.isNotNullUndefinedOrZero(combinedSalesSum)) {
                value = (combinedRAndDSum / combinedSalesSum) * 100;
            }          
        }
        else{

            // Past (Non current and Future)
            // Average R&D% = (Sum of R&D for period/Sum of Gross Sales) * 100
            let grossSalesSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.GrossSales);
            let rAndDSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.RAndD);

            // average it
            if (utils.isNotNullUndefinedOrZero(grossSalesSum)) {
                value = (rAndDSum / grossSalesSum) * 100;
            }
        }

       currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.RAndDPercentPeriodic] = value;
        });
    }
}