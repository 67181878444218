import { Injectable } from "@angular/core";
import { UtilsService } from "./utils.service";

@Injectable({
    providedIn: 'root'
})
export class CacheService {

    public static cacheKeys = 
    {
        selectionPageResponse: "selection-page-response",
        userSettingsApiResponse: "user-settings-api-response"
    }

    getCachedData<T>(cacheKey: string): T
    {
        let cachedItem = sessionStorage.getItem(cacheKey);

        return cachedItem && cachedItem != undefined ? <T>JSON.parse(cachedItem): null;
    }

    setCachedData(cacheKey: string, data: any)
    {
        if( data != null && data != undefined && data != 'undefined' && data !='null')
        {
            sessionStorage.setItem(cacheKey, JSON.stringify(data));
        }
    }

    clearAll()
    {
        sessionStorage.clear();
    }
}
