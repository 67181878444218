import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CacheService } from 'src/app/modules/shared/services/cache-service';
import { LoadingAnimationsService } from 'src/app/modules/shared/services/loading-animations.service';
import { UiHelperService } from 'src/app/modules/shared/services/ui-helper.service';
import { UtilsService } from 'src/app/modules/shared/services/utils.service';
import { Constants } from 'src/app/modules/shared/types/constants';
import { UserInfo } from 'src/app/modules/shared/types/user-info';
import { UserSettingsService } from '../../services/user-settings.service';

@Component({
  selector: 'omnitdp-impersonate-user',
  templateUrl: './impersonate-user.component.html',
  styleUrls: ['./impersonate-user.component.css']
})
export class ImpersonateUserComponent implements OnInit {

  userInfo: UserInfo = null;

  isEmailInvalid: boolean = false;
  isCurrentlyImpersonating: boolean = false;
  impersonateUserForm: any;

  enableStopImpersonation: boolean = false;
  isPageLoading: boolean = true;

  constructor(
    private _formbulider: UntypedFormBuilder,
    private _userSettingsService: UserSettingsService,
    private _loadingAnimationService: LoadingAnimationsService,
    private _uiHelperService: UiHelperService,
    private _utilsService: UtilsService,
    private _cacheService: CacheService,
    private _router: Router) {


  }

  ngOnInit(): void {

    let emailPattern = "^[a-zA-Z0-9_.+-]+@((hm)).com";
    this.impersonateUserForm = this._formbulider.group({
      Email: ['', [Validators.required, Validators.pattern(emailPattern)]],
    });


    this._userSettingsService.userInfoSubject.subscribe(
      (userInfo: UserInfo) => {
        this.userInfo = userInfo;

        if (this.userInfo) {
          this.isPageLoading = false;
          this.onUserInfoUpdate();
        }

      }
    )



  }


  onUserInfoUpdate() {

    let impersonationPermission = this.userInfo.systemPermissions.find(systemPermissions => systemPermissions.name == Constants.FUNCTION_NAME_IMPERSONATE_USER);

    if (impersonationPermission != undefined) {
      // get impersonation settings

      let impersonationSettings = impersonationPermission.settings;

      if (impersonationSettings.isCurrentlyImpersonating && impersonationSettings.impersonatedUserEmail != null) {

        this.isCurrentlyImpersonating = true;
        this.impersonateUserForm.controls['Email'].setValue(impersonationSettings.impersonatedUserEmail);
        this.impersonateUserForm.controls['Email'].disable();

        this.enableStopImpersonation = true;
      }
      else {
        this.isCurrentlyImpersonating = false;
        this.impersonateUserForm.controls['Email'].setValue("");
        this.impersonateUserForm.controls['Email'].enable();

        this.enableStopImpersonation = false;
      }

    }
  }

  stopImpersonating() {
    this._userSettingsService.disableImpersonation();
    this.impersonateUserForm.reset();
    this.enableStopImpersonation = false;

    this._cacheService.clearAll();
    return false;
  }

  async validateImpersonateAsUserEmail(): Promise<boolean> {
    let formEmail = this.impersonateUserForm.value.Email.toLowerCase();

    // check if the email is same as the current user email
    if (formEmail == this.userInfo.email.toLowerCase()) {
      this._uiHelperService.showSnackBarMessage("Cannot impersonate as yourself!");

      return false;
    }

    let response = await this._utilsService.validateUserEmail(formEmail).toPromise();

    if (response == null || response == undefined) {
      return false;
    }
    else {
      return true;
    }
  }

  async onFormSubmit() {

    this.isEmailInvalid = false;
    this._loadingAnimationService.enableTopNavAnimation();

    this.impersonateUserForm.controls['Email'].disable();

    let formEmail = this.impersonateUserForm.value.Email.toLowerCase();

    let validEmail = await this.validateImpersonateAsUserEmail();

    if (validEmail) {
      this.isEmailInvalid = false;
      this.impersonateUserForm.controls['Email'].setValue(formEmail);
      this.impersonateUserForm.controls['Email'].disable();

      this.isCurrentlyImpersonating = true;
      this._userSettingsService.enableImpersonationAsUser(formEmail);
      
     // load user settings
      this._loadingAnimationService.disableTopNavAnimation();

      this._cacheService.clearAll();
      await this._userSettingsService.getUserInfo();

      this._router.navigateByUrl("/selection-view");
    }
    else {
      this.isEmailInvalid = true;
      this.impersonateUserForm.get('Email').value = false;
      this.impersonateUserForm.controls['Email'].enable();
      this._loadingAnimationService.disableTopNavAnimation();
    }
  }

}
