<div class="planning-view-column" *ngIf="selectedOptions != undefined && selectedOptions != null && userConfig">
    <mat-drawer-container [hasBackdrop]="true" class="planning-view-container" autosize>

        <mat-drawer #drawer class="planning-view-sidenav" mode="side" [opened]="sidenavExpanded"
            (closedStart)="onSideNavClosed();" (openedStart)="onSideNavOpened();">

            <div class="form-group" matTooltipPosition="right" [matTooltipDisabled]="!isEnabledForAnaplan" #tooltip="matTooltip"
            matTooltip = "{{getMergeAndPublishToolTipForAssortment()}}">
                <nav style="display: inline-block;">
                    <ol class="breadcrumb" style="margin: 0.5rem 0rem 0.5rem 0;">
                        <li class="breadcrumb-item"><a (click)="reloadSite()" style="cursor:pointer">Home</a></li>
                        <li class="breadcrumb-item active">Planning View</li>
                    </ol>
                </nav>

                <button mat-raised-button color="primary" (click)="openSalesParentTrickleDownSaveByCopyDialog()"
                    [hidden]="selectedOptions.selectionViewOptions.copy.isMain || 
                    selectedOptions.selectionViewOptions.organization.name === 'Assortment'"
                    [disabled]="this.selectedOptions.selectionViewOptions.copy.isLocked || !stashDataAvailableByCopy"
                    style="float: right;margin-left: 0.5rem;">
                    <mat-icon>arrow_downward</mat-icon>
                    TrickleDown All
                </button>
                &nbsp;

                <button mat-raised-button color="primary"
                    (click)="openMergeCopyCopyDialog()"
                    [hidden]="(selectedOptions.selectionViewOptions.copy.isMain || (!selectedOptions.selectionViewOptions.structureType.name.startsWith('Section')&& selectedOptions.selectionViewOptions.organization.name === 'Assortment'))"
                    [disabled]="isEnabledForAnaplan || isMergeButtonDisabled || stashDataAvailableByCopy"
                    style="float: right;">

                    <mat-icon>merge</mat-icon>
                    Merge Copy
                </button>

                <button mat-raised-button color="primary" style="float: right;"
                    *ngIf="selectedOptions.selectionViewOptions.copy.isMain && selectedOptions.selectionViewOptions.organization.name === 'Assortment'"
                    (click)="openPublishDialog()" [disabled]="isEnabledForAnaplan || isPublishButtonDisabled">
                    <mat-icon>publish</mat-icon>
                    Publish To DP
                </button>

            </div>

            <hr />

            <div class="form-group">
                <div class="col-md-12">
                    <mat-card class="planning-view-options-card">
                        <mat-card-content>
                            <table class="table table-bordered selected-options-table">
                                <caption>Selected options</caption>
                                <tbody>
                                    <tr>
                                        <th scope="row">Copy Name</th>
                                        <td>
                                            {{selectedOptions.selectionViewOptions.copy.name}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Brand</th>
                                        <td>{{selectedOptions.selectionViewOptions.brand.name}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Organization</th>
                                        <td>{{selectedOptions.selectionViewOptions.organization.name}}</td>
                                    </tr>
                                    <tr
                                        *ngIf="isAssortmentView && selectedOptions.selectionViewOptions.structure.name != brandstructurename">
                                        <ng-container
                                            *ngIf="selectedOptions.selectionViewOptions.structureType.name.startsWith('Section'); then showSection; else showCustomerGroup">
                                        </ng-container>
                                        <ng-template #showSection>
                                            <th scope="row">Section</th>
                                        </ng-template>
                                        <ng-template #showCustomerGroup>
                                            <th scope="row">Customer Group</th>
                                        </ng-template>
                                        <td>{{selectedOptions.selectionViewOptions.structure.code}} -
                                            {{selectedOptions.selectionViewOptions.structure.name}}</td>
                                    </tr>
                                    <tr *ngIf="isSalesView &&  selectedOptions.selectionViewOptions.region!=null">
                                        <th scope="row">Region</th>
                                        <td>{{selectedOptions.selectionViewOptions.region.shortName}}</td>
                                    </tr>
                                    <tr
                                        *ngIf="isSalesView &&  selectedOptions.selectionViewOptions.planningMarket!=null">
                                        <th scope="row">PM</th>
                                        <td>{{selectedOptions.selectionViewOptions.planningMarket.shortName}}</td>
                                    </tr>
                                    <tr *ngIf="isSalesView && selectedOptions.selectionViewOptions.isPercentPm">
                                        <th scope="row">%PM</th>
                                        <td>{{selectedOptions.selectionViewOptions.percentPM.shortName}}</td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Structure</th>
                                        <td>{{selectedOptions.selectionViewOptions.structureType.name}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
            <hr />
            <ng-container *ngIf="pageState.toString()=='Loaded'; then sideNavDataLoaded; else sideNavDataLoading">
            </ng-container>
            <ng-template #sideNavDataLoading>
                <omnitdp-skeleton-loader [skeletonType]="'side-nav'"></omnitdp-skeleton-loader>
            </ng-template>

            <ng-template #sideNavDataLoaded>

                <!-- Channel -->
                <div class="form-group">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="planning-view-navigation-header">Channel:</div>
                        </div>

                        <div class="col-md-12 optionSelectorRow">
                            <mat-button-toggle-group [(ngModel)]="this.selectedOptions.planningViewOptions.channel"
                                (click)="changeChannel()">
                                <mat-button-toggle [value]=3 *ngIf="enableOmniChannel">Omni
                                </mat-button-toggle>
                                <mat-button-toggle [value]=1 *ngIf="enableStoreChannel">Store</mat-button-toggle>
                                <mat-button-toggle [value]=2 *ngIf="enableOnlineChannel">Online</mat-button-toggle>
                            </mat-button-toggle-group>
                        </div>
                    </div>
                    <hr />
                </div>

                <!-- Exclude Old Season -->
                <!-- Disabling it for sales view,BTTDMFP-669 -->
                <div class="form-group" *ngIf="!isSalesView">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="planning-view-navigation-header headerExcludeOldSeason">Exclude old seasons:
                            </div>
                            <mat-checkbox class="chkboxExcludeOldSeason"
                                [(ngModel)]="this.selectedOptions.planningViewOptions.areOldSeasonsExcluded"
                                color="primary">
                            </mat-checkbox>
                        </div>

                    </div>
                    <hr />
                </div>
                <div class="form-group"
                    *ngIf="isAssortmentView && (this.selectedOptions.planningViewOptions.channel == '1' || this.selectedOptions.planningViewOptions.channel == '2')">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="planning-view-navigation-header">Channel Total: </h3>
                            <mat-slide-toggle color="primary" class="mat-slide-toggle-channel-sum"
                                [(ngModel)]="this.selectedOptions.planningViewOptions.isChannelSum">
                            </mat-slide-toggle>
                        </div>
                    </div>
                    <hr />
                </div>

                <!-- Assortment Specific Controls -->
                <div class="form-group"
                    *ngIf="isAssortmentView && !this.selectedOptions.planningViewOptions.isChannelSum">
                    <div class="row" *ngIf="assortmentRegionOptions.length>0">
                        <div class="col-md-12">
                            <div class="planning-view-navigation-header">Region:<span class="required-asterisk">*</span>
                            </div>
                        </div>
                        <div class="col-md-12 optionSelectorRow">

                            <mat-button-toggle-group [(ngModel)]="selectedRegion">
                                <mat-button-toggle *ngFor="let region of assortmentRegionOptions" [value]="region"
                                    (click)="setSelectedMarket(region)" matTooltip="{{region.description}}">
                                    {{region.shortName}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>

                        </div>
                    </div>

                    <div class="row"
                        *ngIf="assortmentPlanningMarketOptions!=null && assortmentPlanningMarketOptions.length>0">
                        <div class="col-md-12">
                            <div class="planning-view-navigation-header">PM:<span class="required-asterisk">*</span>
                            </div>
                        </div>
                        <div class="col-md-12 optionSelectorRow">

                            <mat-button-toggle-group [(ngModel)]="selectedPlanningMarket">
                                <mat-button-toggle *ngFor="let pm of assortmentPlanningMarketOptions" [value]="pm"
                                    (click)="setSelectedMarket(pm)">
                                    {{pm.shortName}}
                                </mat-button-toggle>
                            </mat-button-toggle-group>


                        </div>
                    </div>
                    <hr />
                </div>

                <!-- Sales Specific Controls -->
                <div class="form-group" *ngIf="isSalesView">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="planning-view-navigation-header">PM:<span class="required-asterisk">*</span>
                            </div>
                        </div>

                        <div class="col-md-12 optionSelectorRow">

                            <mat-button-toggle-group [(ngModel)]="selectedPm">
                                <mat-button-toggle
                                    *ngIf="salesRegionOption && this.selectedOptions.planningViewOptions.channel != '3'"
                                    [value]="salesRegionOption" (click)="setSelectedMarket(salesRegionOption)"
                                    matTooltip="{{salesRegionOption.description}}">
                                    {{salesRegionOption?.shortName}}
                                </mat-button-toggle>
                                <mat-button-toggle *ngFor="let planningMarket of salesPlanningMarketOptions"
                                    [value]="planningMarket" (click)="setSelectedMarket(planningMarket)"
                                    matTooltip="{{planningMarket.description}}">
                                    {{planningMarket.shortName}}</mat-button-toggle>
                            </mat-button-toggle-group>
                            <span
                                *ngIf="salesRegionOption == null && this.selectedOptions.planningViewOptions.channel == '3'"
                                class="region-validation-message validation-message">You dont have access to any omni
                                region for the selected region</span>
                        </div>
                    </div>
                    <hr />
                </div>

                <!-- Tree Selector -->
                <div class="form-group">
                    <div class="row">
                        <div class="planning-view-navigation-header">Structure:</div>

                        <mat-form-field id="treeFilterField">
                            <mat-label id="treeFilter">Filter</mat-label>
                            <input matInput minlength="2" (keyup)="applyFilter($event.target['value'])">
                        </mat-form-field>

                        <mat-tree [dataSource]="structureTreeData" [treeControl]="treeControl"
                            class="planning-view-tree">

                            <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding (click)="treeNodeclick(node)"
                                [ngClass]="{ 'background-highlight': activeNode === node }">
                                <span class="tree-node-icon" class="tree-node-icon-{{node.typeName}}"
                                    *ngIf="node.typeName && node.typeName=='Department'">{{translateTypeName(node.typeName)}}</span>
                                <div class="planning-view-tree-node" id="{{node.typeId}}"
                                    *ngIf="node.typeName=='Department'"> {{node.name}}</div>
                            </mat-tree-node>

                            <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">

                                <div class="mat-tree-node" (click)="treeNodeclick(node)"
                                    [ngClass]="{ 'background-highlight': activeNode === node }" matTreeNodeToggle>

                                    <button mat-icon-button>
                                        <mat-icon class="mat-icon-rtl-mirror">
                                            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                                        </mat-icon>
                                    </button>
                                    <span class="tree-node-icon" class="tree-node-icon-{{node.typeName}}"
                                        *ngIf="node.typeName">{{translateTypeName(node.typeName)}}</span>
                                    <div id="{{node.typeId}}" class="planning-view-tree-node"> {{node.name}}
                                    </div>
                                </div>
                                <div [class.planning-view-tree-invisible]="!treeControl.isExpanded(node)" role="group">
                                    <ng-container matTreeNodeOutlet></ng-container>
                                </div>
                            </mat-nested-tree-node>

                        </mat-tree>
                    </div>
                    <hr />
                </div>

                <div class="form-group">
                    <div class="col-12">
                        <button mat-raised-button color="warn" class="btn"
                            [disabled]="planningViewTablesInitialized && !this.planningViewTableRenderComplete"
                            (click)="getPlanningViewData()">Go</button>
                    </div>
                </div>
            </ng-template>
        </mat-drawer>

        <div class="planning-view-sidenav-content">
            <div class="row">

                <div class="col-12">
                    <div style="height:100%">
                        <div style="display: inline-block; width: 3%; height: 100%; text-align: center;">
                            <button style="height: 100%; width: 100%;" type="button" class="btnOpenNavigation"
                                mat-button (click)="drawer.toggle()">

                                <span class="material-icons" *ngIf="!sidenavExpanded">
                                    keyboard_double_arrow_right
                                </span>
                                <span class="material-icons" *ngIf="sidenavExpanded">
                                    keyboard_double_arrow_left
                                </span>
                            </button>

                        </div>

                        <div style="display: inline-block;width: 97%;vertical-align: top;">
                            <div class="planning-view-status-bar"
                                [ngStyle]="{'visibility': planningViewTablesLoaded  == true ? 'visible' : 'hidden'}">
                                <mat-toolbar class="planning-view-toolbar">
                                    <mat-toolbar-row>

                                        <span>
                                            {{ userConfig.selectionViewOptions.brand.name }}
                                            | {{ userConfig.selectionViewOptions.organization.name }}
                                            |
                                            <ng-container
                                                *ngIf="userConfig.planningViewOptions.isChannelSum; then showChannelSum; else hideChannelSum">
                                            </ng-container>
                                            <ng-template #showChannelSum>
                                                Channel Total
                                            </ng-template>
                                            <ng-template #hideChannelSum>
                                                {{ getMarketName() }} - {{
                                                userConfig?.planningViewOptions?.market?.shortName }}
                                                <span class="material-icons" style="font-size:18px; cursor: pointer;"
                                                    [matTooltip]="userConfig?.planningViewOptions?.market?.description">info</span>
                                            </ng-template>
                                            | {{ userConfig.planningViewOptions.channel == 1? "Store" :
                                            userConfig.planningViewOptions.channel == 2? "Online":
                                            "Omni"
                                            }}
                                            | {{ userConfig.planningViewOptions.structureType }}
                                            | {{
                                            userConfig.planningViewOptions.activeNode?userConfig.planningViewOptions.activeNode.name:""
                                            }}
                                            ({{
                                            userConfig.planningViewOptions.activeNode?userConfig.planningViewOptions.activeNode.code:""
                                            }})
                                        </span>
                                        <button class="toolbar-left-icons" mat-icon-button
                                            title="Copy Selected Configuration" (click)="copyPlanningViewDetails()">
                                            <mat-icon>content_copy</mat-icon>
                                        </button>
                                        <button class="toolbar-left-icons" mat-icon-button title="Share Link"
                                            (click)="generateLink()">
                                            <mat-icon>share</mat-icon>
                                        </button>
                                        <button class="toolbar-left-icons" mat-icon-button title="Reload Plan"
                                            [disabled]="planningViewTablesInitialized && !this.planningViewTableRenderComplete"
                                            (click)="getPlanningViewData()">
                                            <mat-icon>refresh</mat-icon>
                                        </button>
                                        <span style="flex: 1 1 auto;"></span>
                                        <div class="planning-view-controls-toolbar"
                                            [ngStyle]="{'visibility': (totalSeasonTableRendered == true || aggregatedTotalSeasonTableRendered == true) ? 'visible' : 'hidden'}">
                                            <span>
                                                Latest Data Refreshed :
                                                <ng-container
                                                    *ngIf="isLatestDataRefreshTimeRendered; then showLatestDataRefreshTime; else showLoadingIcon">
                                                </ng-container>
                                                <ng-template #showLoadingIcon>
                                                    <img src="./assets/images/ellipsis-animated.gif" alt="Loading" />
                                                </ng-template>
                                                <ng-template #showLatestDataRefreshTime>
                                                    {{ latestDataRefreshTime }}
                                                </ng-template>
                                            </span>
                                            <span
                                                *ngIf="isAssortmentView && isAggregatedDepartmentSeasonTablesRequested">
                                                <mat-label
                                                    [ngClass]="{'toggle-label-color-active':!isAggregatedMainViewEnabled, 'toggle-label-color': isAggregatedMainViewEnabled}">
                                                    Sim
                                                </mat-label>
                                                <mat-slide-toggle color="primary" class="mat-slide-toggle-sum-sim"
                                                    [(ngModel)]="isAggregatedMainViewEnabled"
                                                    (change)="changeToggleSimOrSum($event); updateSimulationView()"
                                                    [disabled]="!aggregatedTotalSeasonTableRendered">
                                                </mat-slide-toggle>
                                                <mat-label
                                                    [ngClass]="{'toggle-label-color-active':isAggregatedMainViewEnabled, 'toggle-label-color': !isAggregatedMainViewEnabled}">
                                                    Sum
                                                </mat-label>
                                            </span>
                                            <mat-form-field *ngIf="this.showDatepicker" id="datePicker">
                                                <input [(ngModel)]="selectedDate" matInput
                                                    [matDatepicker]="customDatePicker"
                                                    (dateChange)="selectedDateChange($event);updatePlanningViewLayoutSettings();" />
                                                <mat-datepicker-toggle matSuffix [for]="customDatePicker">
                                                    <mat-icon matDatepickerToggleIcon>event</mat-icon>
                                                </mat-datepicker-toggle>
                                                <mat-datepicker #customDatePicker>
                                                    <mat-datepicker-actions>
                                                        <button mat-button matDatepickerCancel>Cancel</button>
                                                        <button mat-raised-button color="primary"
                                                            matDatepickerApply>Apply</button>
                                                    </mat-datepicker-actions>
                                                </mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field>
                                                <mat-select [(ngModel)]="selectedViewSeasonsTablesList" multiple
                                                    [hideSingleSelectionIndicator]="true"
                                                    (selectionChange)="updatePlanningViewLayoutSettings();">
                                                    <mat-select-trigger>
                                                        <span>
                                                            Seasons ({{selectedViewSeasonsTablesList.length}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let season of availableViewSeasonsList"
                                                        [value]="season">
                                                        {{season.seasonDisplayName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-form-field id="kpi">
                                                <mat-select [(ngModel)]="selectedViewKpis" multiple
                                                    [hideSingleSelectionIndicator]="true"
                                                    (selectionChange)="updatePlanningViewLayoutSettings();">
                                                    <mat-select-trigger>
                                                        <span>
                                                            KPIs ({{selectedViewKpis.length}})
                                                        </span>
                                                    </mat-select-trigger>
                                                    <mat-option *ngFor="let kpi of availableViewKpiList" [value]="kpi"
                                                        (onSelectionChange)="selectOptionChange($event)">
                                                        {{kpi}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>

                                            <mat-checkbox id="graphCheckbox" [(ngModel)]="isPlanningViewGraphVisible"
                                                color="primary" (change)="updatePlanningViewLayoutSettings();">Graph
                                            </mat-checkbox>
                                            <mat-checkbox id="rawCheckbox" [(ngModel)]="seasonTableDebugMode"
                                                color="primary" (change)="updatePlanningViewLayoutSettings();">Raw
                                                Values
                                            </mat-checkbox>

                                            <button mat-icon-button *ngIf="totalSeasonTableRendered"
                                                (click)="_planningViewActionHistoryService.undoLastAction()"
                                                [disabled]="!_planningViewActionHistoryService.isUndoAvailable()"
                                                title="Undo(Ctrl+Z)">
                                                <mat-icon>undo</mat-icon>
                                            </button>
                                            <button mat-icon-button *ngIf="totalSeasonTableRendered"
                                                (click)="_planningViewActionHistoryService.redoLastAction()"
                                                [disabled]="!_planningViewActionHistoryService.isRedoAvailable()"
                                                title="Redo(Ctrl+Y)">
                                                <mat-icon>redo</mat-icon>
                                            </button>

                                            <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button (click)="exportToExcel()" mat-menu-item>
                                                    <mat-icon>file_download</mat-icon>
                                                    <span>Export to Excel</span>
                                                </button>
                                                <button (click)="resetPlanningViewSettings()" mat-menu-item>
                                                    <mat-icon>restore</mat-icon>
                                                    <span>Reset Settings</span>
                                                </button>
                                            </mat-menu>

                                            <!-- Save button for Department Level -->
                                            <button mat-raised-button style="margin-bottom: 15px;"
                                                *ngIf="totalSeasonTableRendered == true && !selectedOptions.selectionViewOptions.copy.isMain && isDepartmentLevelSeasonTablesRequested"
                                                (click)="saveDepartmentLevelCopy()" [disabled]="(!((previousDepartmentSeasonTableData.driver.touched || 
                                                actualDepartmentSeasonTableData.driver.touched || 
                                                comingDepartmentSeasonTableData.driver.touched || 
                                                futureDepartmentSeasonTableData.driver.touched))) 
                                                || isSaveButtonDisabled || isAggregatedMainViewEnabled">
                                                <mat-icon>save</mat-icon>
                                                Save
                                            </button>

                                            <!-- Save button for CG and Total -->
                                            <button mat-raised-button [matMenuTriggerFor]="saveMenu"
                                                style="margin-bottom: 15px;"
                                                *ngIf="totalSeasonTableRendered == true && 
                                                !selectedOptions.selectionViewOptions.copy.isMain && 
                                                isParentLevelSeasonTablesRequested && 
                                                (userConfig.planningViewOptions.structureType == 'CustomerGroup' || userConfig.planningViewOptions.structureType == 'Total')"
                                                [disabled]="selectedOptions.selectionViewOptions.copy.isLocked ||
                                                (!previousParentSeasonTableData.driver.touched &&
                                                             !actualParentSeasonTableData.driver.touched && 
                                                             !comingParentSeasonTableData.driver.touched &&
                                                             !futureParentSeasonTableData.driver.touched) ||
                                                              isSaveButtonDisabled">
                                                <mat-icon>save</mat-icon>
                                                Save
                                            </button>
                                            <mat-menu #saveMenu="matMenu">
                                                <button mat-menu-item (click)="stashInputs()">Save</button>
                                                <button mat-menu-item (click)="openSaveCopyAndTrickleDown()">Save +
                                                    Trickle Down</button>
                                            </mat-menu>

                                            <!-- Save button for Non CG and Total Levels -->
                                            <button mat-raised-button style="margin-bottom: 15px;"
                                                *ngIf="totalSeasonTableRendered == true &&
                                                 !selectedOptions.selectionViewOptions.copy.isMain &&
                                                 isParentLevelSeasonTablesRequested &&
                                                 userConfig.planningViewOptions.structureType != 'CustomerGroup' && userConfig.planningViewOptions.structureType != 'Total'"
                                                (click)="openSaveCopyAndTrickleDown()" [disabled]="(!previousParentSeasonTableData.driver.touched &&
                                                             !actualParentSeasonTableData.driver.touched && 
                                                             !comingParentSeasonTableData.driver.touched &&
                                                             !futureParentSeasonTableData.driver.touched) ||
                                                              isSaveButtonDisabled">
                                                <mat-icon>save</mat-icon>
                                                Save
                                            </button>

                                        </div>

                                    </mat-toolbar-row>
                                </mat-toolbar>
                            </div>

                            <nav aria-label="planning=view-menu-nav">
                                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                                    <button
                                        [disabled]="_loadingAnimationsService.AreLoadingAnimationsInProgress() || (!planningViewTablesLoaded && !planningViewTablesInitialized)"
                                        class="nav-link active" id="nav-planning-view-tab" data-bs-toggle="tab"
                                        (click)="currentSelectedTab=0" data-bs-target="#nav-planning-view" type="button"
                                        role="tab">Planning
                                        View</button>
                                    <button
                                        [disabled]="_loadingAnimationsService.AreLoadingAnimationsInProgress() || (!planningViewTablesLoaded && !planningViewTablesInitialized)"
                                        class="nav-link" id="nav-simulate-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-simulate" type="button" role="tab"
                                        (click)="currentSelectedTab=1;updateSimulationView()">Season Summary</button>
                                    <button
                                        [disabled]="_loadingAnimationsService.AreLoadingAnimationsInProgress() || (!planningViewTablesLoaded && !planningViewTablesInitialized)"
                                        class="nav-link" id="nav-total-plan-tab" data-bs-toggle="tab"
                                        data-bs-target="#nav-organization-summary-view" type="button" role="tab"
                                        *ngIf="userConfig?.planningViewOptions?.structureType != 'Department'"
                                        (click)="currentSelectedTab=2;">Org. Summary
                                    </button>
                                </div>
                                <div *ngIf="!selectedOptions?.selectionViewOptions?.copy?.isMain && selectedOptions?.selectionViewOptions?.copy?.isLocked"
                                    class="copy-lock-message">Copy is currently saving and therefore locked by the
                                    system. Please come back later.
                                </div>
                                <div *ngIf="!selectedOptions?.selectionViewOptions?.copy?.isMain && 
                                !selectedOptions?.selectionViewOptions?.copy?.isLocked && 
                                this.stashedDataExists && 
                                this.stashedData==null" class="copy-lock-message">
                                    <ng-container
                                        *ngIf="userConfig.planningViewOptions.structureType != this.stashStructureType; then showLockMessage; else showUnlockMessage">
                                    </ng-container>
                                    <ng-template #showLockMessage>
                                        Copy has data saved on {{this.stashStructureType}} level which has not
                                        yet been trickled down. This view is locked until plans have been trickled down.
                                    </ng-template>
                                    <ng-template #showUnlockMessage>
                                        Copy has data saved on {{this.stashStructureType}} level which has not
                                        yet been trickled down.
                                    </ng-template>
                                </div>
                                <div *ngIf="salesCopyTrickleDownFailedStatus?.trickleDownFailed && !selectedOptions?.selectionViewOptions?.copy?.isLocked"
                                    class="copy-lock-message">
                                    Previously triggered trickle down process failed<span
                                        *ngIf="salesCopyTrickleDownFailedStatus.trickleDownFailedMarket?.length && salesCopyTrickleDownFailedStatus.trickleDownFailedStructure?.length">
                                        (for {{salesCopyTrickleDownFailedStatus.trickleDownFailedMarket}} and
                                        {{salesCopyTrickleDownFailedStatus.trickleDownFailedStructure}})</span>, latest
                                    plans have not been trickled down. Please try trickling down again later.
                                </div>
                            </nav>

                            <div class="tab-content" id="nav-tabContent">

                                <div class="tab-pane show active" id="nav-planning-view" role="tabpanel">
                                    <!-- containing planning view  start-->
                                    <div class="planning-view-main-pane">
                                        <div *ngIf="!planningViewTablesLoaded && !planningViewTablesInitialized">
                                            <h3 style="margin-top:20px">Please select options from the Navigation Pane
                                            </h3>
                                        </div>

                                        <div class="col-12"
                                            [ngStyle]="{'visibility': planningViewTablesLoaded == true ? 'visible' : 'hidden'}">

                                            <div *ngIf="totalViewRequestsEnabled" style="text-align:center">

                                                <omnitdp-skeleton-loader [skeletonType]="'totalviewrequeststable'"
                                                    *ngIf="!totalViewRequestData">
                                                </omnitdp-skeleton-loader>

                                                <div *ngIf="totalViewRequestData">
                                                    <div><span class="totalviewrequesttable-heading">Request
                                                            status</span></div>
                                                    <table class="table table-bordered totalviewrequesttable">
                                                        <caption>Total view requests</caption>
                                                        <tbody>
                                                            <tr>
                                                                <th scope="row" class="totalviewrequesttable-heading">
                                                                    Last request status:</th>
                                                                <td>{{totalViewRequestStatus}}</td>
                                                                <th scope="row" class="totalviewrequesttable-heading">
                                                                    Requested at:</th>
                                                                <td>{{totalViewRequestedAt}}</td>
                                                                <th scope="row" class="totalviewrequesttable-heading">
                                                                    View last generated at:</th>
                                                                <td>{{totalViewProcessedAt}}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>

                                                <div
                                                    *ngIf="totalViewRequestData?.recentTotalViewRequest && totalViewRequestData?.recentTotalViewRequest.status == 3">
                                                    <div><span class="total-view-request-error-message">Kindly
                                                            re-generate again as the total view generation failed and
                                                            you may not be seeing the correct goals.</span></div>
                                                </div>
                                                <div
                                                    *ngIf="totalViewRequestData?.recentTotalViewRequest && totalViewRequestData?.recentTotalViewRequest.status == 5">
                                                    <div><span class="total-view-request-error-message">Kindly
                                                            re-generate again as the total view generation is invalid
                                                            since the timeline is changed.</span></div>
                                                </div>
                                                <button (click)="generateTotalViewRequest()"
                                                    class="btntotalviewrequestgenerateview" mat-raised-button
                                                    [disabled]="this.selectedOptions.selectionViewOptions.copy.isLocked || 
                                                    (totalViewRequestData?.recentTotalViewRequest && 
                                                        (totalViewRequestData?.recentTotalViewRequest.status == 1 || 
                                                        totalViewRequestData?.recentTotalViewRequest.status == 2)) || 
                                                    generateRequestLoading" color="primary">
                                                    <mat-icon>construction</mat-icon>
                                                    Generate View
                                                </button>

                                                <omnitdp-skeleton-loader [skeletonType]="'planning-table'"
                                                    *ngIf="totalPlanningViewResponseLoading">
                                                </omnitdp-skeleton-loader>
                                            </div>
                                            <!--graph region start -->

                                            <ng-container [ngTemplateOutlet]="(!isAggregatedMainViewEnabled && !totalSeasonTableRendered && !aggregatedTotalSeasonTableRendered) ? planningViewGraphLoading 
                                            :(!isAggregatedMainViewEnabled && totalSeasonTableRendered && !aggregatedTotalSeasonTableRendered) ? planningViewGraphLoaded 
                                            :(isAggregatedMainViewEnabled && !totalSeasonTableRendered && !aggregatedTotalSeasonTableRendered) ? planningViewGraphLoading 
                                            :(isAggregatedMainViewEnabled && totalSeasonTableRendered && !aggregatedTotalSeasonTableRendered) ? planningViewGraphLoading 
                                            :(!isAggregatedMainViewEnabled && !totalSeasonTableRendered && aggregatedTotalSeasonTableRendered) ? planningViewGraphLoading 
                                            : planningViewGraphLoaded 
                                            "></ng-container>

                                            <ng-template #planningViewGraphLoading>
                                                <omnitdp-skeleton-loader [skeletonType]="'planning-view-chart'"
                                                    [hidden]="!totalSeasonTableRendered">
                                                </omnitdp-skeleton-loader>
                                            </ng-template>
                                            <ng-template #planningViewGraphLoaded>

                                                <div class="planning-view-chart" [hidden]="!isPlanningViewGraphVisible"
                                                    [ngClass]="isChartPinned ? 'pin-planning-view-chart' : 'unpin-planning-view-chart'">
                                                    <ng-container
                                                        *ngIf="isChartPinned; then pinpushicon; else unpinpushicon">
                                                    </ng-container>
                                                    <ng-template #pinpushicon>
                                                        <mat-icon [inline]="true" (click)="pinChart()"
                                                            matTooltip="Click to unpin graph"
                                                            style="float: right; cursor: pointer; transform: rotate(90deg); font-size: 16px;">
                                                            push_pin
                                                        </mat-icon>
                                                    </ng-template>
                                                    <ng-template #unpinpushicon>
                                                        <mat-icon [inline]="true" (click)="pinChart()"
                                                            matTooltip="Click to pin graph"
                                                            style="float: right; cursor: pointer; font-size: 16px;">
                                                            push_pin
                                                        </mat-icon>
                                                    </ng-template>
                                                    <mat-checkbox color="primary"
                                                        matTooltip="show/hide all season in the graph"
                                                        class="checkbox-show-created-by-others"
                                                        [(ngModel)]="showAllLegends" (change)="changeLegend($event)">
                                                        <span>Show All</span>
                                                    </mat-checkbox>
                                                    <canvas #chartObj="base-chart" height="57" baseChart
                                                        [data]="chartData" [type]="'line'"
                                                        [options]="chartOptions"></canvas>
                                                </div>
                                            </ng-template>
                                            <!--graph region end -->

                                            <!-- normal season tables start -->
                                            <div class="form-group" [hidden]="isAggregatedMainViewEnabled">
                                                <div class="col-12">

                                                    <div id="totalSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Total') != -1)">
                                                        <ng-container
                                                            *ngIf="totalSeasonTableRendered; then totalSeasonTableLoaded; else totalSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #totalSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #totalSeasonTableLoaded>
                                                            <app-season-table
                                                                *ngIf="isDepartmentLevelSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="totalDepartmentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-season-table>
                                                            <app-readonly-season-table
                                                                *ngIf="isReadonlyDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="readonlyDepartmentTotalSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="false"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-readonly-season-table>
                                                            <app-parent-season-table
                                                                *ngIf="isParentLevelSeasonTablesRequested"
                                                                [totalViewDefaultRetrievalmode]="totalViewDefaultRetrievalmode"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="totalParentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-parent-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="previousSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Previous') != -1 || lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('PreviousWithOld') != -1)">
                                                        <ng-container
                                                            *ngIf="previousSeasonTableRendered; then previousSeasonTableLoaded; else previousSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #previousSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #previousSeasonTableLoaded>
                                                            <app-season-table
                                                                *ngIf="isDepartmentLevelSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="previousDepartmentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-season-table>
                                                            <app-readonly-season-table
                                                                *ngIf="isReadonlyDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="readonlyDepartmentPreviousSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="false"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>
                                                            <app-parent-season-table
                                                                *ngIf="isParentLevelSeasonTablesRequested"
                                                                [totalViewDefaultRetrievalmode]="totalViewDefaultRetrievalmode"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [(seasonTableInfo)]="previousParentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-parent-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="actualSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Actual') != -1)">
                                                        <ng-container
                                                            *ngIf="actualSeasonTableRendered; then actualSeasonTableLoaded; else actualSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #actualSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #actualSeasonTableLoaded>
                                                            <app-season-table
                                                                *ngIf="isDepartmentLevelSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="actualDepartmentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-season-table>

                                                            <app-readonly-season-table
                                                                *ngIf="isReadonlyDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="readonlyDepartmentActualSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="false"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-readonly-season-table>

                                                            <app-parent-season-table
                                                                *ngIf="isParentLevelSeasonTablesRequested"
                                                                [totalViewDefaultRetrievalmode]="totalViewDefaultRetrievalmode"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [(seasonTableInfo)]="actualParentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-parent-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="comingSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Coming') != -1)">
                                                        <ng-container
                                                            *ngIf="comingSeasonTableRendered; then comingSeasonTableLoaded; else comingSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #comingSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #comingSeasonTableLoaded>
                                                            <app-season-table
                                                                *ngIf="isDepartmentLevelSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="comingDepartmentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-season-table>

                                                            <app-readonly-season-table
                                                                *ngIf="isReadonlyDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="readonlyDepartmentComingSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="false"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>

                                                            <app-parent-season-table
                                                                *ngIf="isParentLevelSeasonTablesRequested"
                                                                [totalViewDefaultRetrievalmode]="totalViewDefaultRetrievalmode"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [(seasonTableInfo)]="comingParentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-parent-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="futureSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Future') != -1)">
                                                        <ng-container
                                                            *ngIf="futureSeasonTableRendered; then futureSeasonTableLoaded; else futureSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #futureSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #futureSeasonTableLoaded>
                                                            <app-season-table
                                                                *ngIf="isDepartmentLevelSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="futureDepartmentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-season-table>

                                                            <app-readonly-season-table
                                                                *ngIf="isReadonlyDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="readonlyDepartmentFutureSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="false"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>

                                                            <app-parent-season-table
                                                                *ngIf="isParentLevelSeasonTablesRequested"
                                                                [totalViewDefaultRetrievalmode]="totalViewDefaultRetrievalmode"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [(seasonTableInfo)]="futureParentSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">
                                                            </app-parent-season-table>
                                                        </ng-template>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <!-- normal season tables end -->

                                            <!-- aggreagated mains season table start -->
                                            <div class="form-group" [hidden]="!isAggregatedMainViewEnabled">
                                                <div class="col-12">

                                                    <div id="totalSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Total') != -1)">
                                                        <ng-container
                                                            *ngIf="aggregatedTotalSeasonTableRendered; then aggreagtedTotalSeasonTableLoaded; else aggreagtedTotalSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #aggreagtedTotalSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #aggreagtedTotalSeasonTableLoaded>
                                                            <app-readonly-season-table
                                                                *ngIf="isAggregatedDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="aggregatedDepartmentTotalSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="true"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="previousSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Previous') != -1 || lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('PreviousWithOld') != -1)">
                                                        <ng-container
                                                            *ngIf="aggregatedPreviousSeasonTableRendered; then aggreagtedPreviousSeasonTableLoaded; else aggreagtedPreviousSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #aggreagtedPreviousSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #aggreagtedPreviousSeasonTableLoaded>
                                                            <app-readonly-season-table
                                                                *ngIf="isAggregatedDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="aggregatedDepartmentPreviousSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="true"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="actualSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Actual') != -1)">
                                                        <ng-container
                                                            *ngIf="aggregatedActualSeasonTableRendered; then aggreagtedActualSeasonTableLoaded; else aggreagtedActualSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #aggreagtedActualSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #aggreagtedActualSeasonTableLoaded>
                                                            <app-readonly-season-table
                                                                *ngIf="isAggregatedDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="aggregatedDepartmentActualSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="true"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="comingSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Coming') != -1)">
                                                        <ng-container
                                                            *ngIf="aggregatedComingSeasonTableRendered; then aggreagtedComingSeasonTableLoaded; else aggreagtedComingSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #aggreagtedComingSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #aggreagtedComingSeasonTableLoaded>
                                                            <app-readonly-season-table
                                                                *ngIf="isAggregatedDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="aggregatedDepartmentComingSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="true"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>
                                                        </ng-template>
                                                    </div>


                                                    <div id="futureSeasonTable"
                                                        [hidden]="!(lodash.map(selectedViewSeasonsTablesList, 'seasonType').indexOf('Future') != -1)">
                                                        <ng-container
                                                            *ngIf="aggregatedFutureSeasonTableRendered; then aggreagtedFutureSeasonTableLoaded; else aggreagtedFutureSeasonTableLoading">
                                                        </ng-container>
                                                        <ng-template #aggreagtedFutureSeasonTableLoading>
                                                            <omnitdp-skeleton-loader [skeletonType]="'planning-table'">
                                                            </omnitdp-skeleton-loader>
                                                        </ng-template>

                                                        <ng-template #aggreagtedFutureSeasonTableLoaded>
                                                            <app-readonly-season-table
                                                                *ngIf="isAggregatedDepartmentSeasonTablesRequested"
                                                                [selectedKpis]="selectedViewKpis"
                                                                [currentWeek]="userConfig.planningViewOptions.weekNumber"
                                                                [seasonTableInfo]="aggregatedDepartmentFutureSeasonTableData"
                                                                [debugMode]="seasonTableDebugMode"
                                                                [isMain]="userConfig.selectionViewOptions.copy.isMain"
                                                                [areOldSeasonsExcluded]="userConfig.planningViewOptions.areOldSeasonsExcluded"
                                                                [channel]="userConfig.planningViewOptions.channel"
                                                                [isChannelSum]="userConfig.planningViewOptions.isChannelSum"
                                                                [isAggregatedMainViewEnabled]="isAggregatedMainViewEnabled"
                                                                [usedForSumView]="true"
                                                                [areSalesSystemGoalsIncludedForAssortment]="areSalesSystemGoalsIncludedForAssortment">

                                                            </app-readonly-season-table>
                                                        </ng-template>
                                                    </div>
                                                    <br />
                                                </div>
                                            </div>
                                            <!-- aggreagated mains season table end -->
                                        </div>
                                    </div>
                                    <!-- containing planning view  end-->

                                </div>
                                <div class="tab-pane" id="nav-simulate" role="tabpanel">
                                    <!-- Simulate  start-->
                                    <div [hidden]="isAggregatedMainViewEnabled">
                                        <app-simulation-view [(TotalSeasonDriver)]="totalSeasonTableDataDriver"
                                            [(PreviousSeasonDriver)]="previousSeasonTableDataDriver"
                                            [(ActualSeasonDriver)]="actualSeasonTableDataDriver"
                                            [(ComingSeasonDriver)]="comingSeasonTableDataDriver"
                                            [(FutureSeasonDriver)]="futureSeasonTableDataDriver"
                                            [(updateSubject)]="simulationViewUpdateSubject"
                                            (summarySelectionEvent)="summaryViewWeeksChanged($event)"
                                            *ngIf="totalSeasonTableRendered">
                                        </app-simulation-view>
                                    </div>
                                    <div [hidden]="!isAggregatedMainViewEnabled">
                                        <app-simulation-view
                                            [(TotalSeasonDriver)]="aggregatedTotalSeasonTableDataDriver"
                                            [(PreviousSeasonDriver)]="aggregatedPreviousSeasonTableDataDriver"
                                            [(ActualSeasonDriver)]="aggregatedActualSeasonTableDataDriver"
                                            [(ComingSeasonDriver)]="aggregatedComingSeasonTableDataDriver"
                                            [(FutureSeasonDriver)]="aggregatedFutureSeasonTableDataDriver"
                                            [(updateSubject)]="simulationViewUpdateSubject"
                                            (summarySelectionEvent)="summaryViewWeeksChanged($event)"
                                            *ngIf="aggregatedTotalSeasonTableRendered">
                                        </app-simulation-view>
                                    </div>

                                    <!-- Simulate  end-->
                                </div>
                                <div class="tab-pane" id="nav-organization-summary-view" role="tabpanel">
                                    <app-organization-summary-view
                                        [PreviousSeasonDriver]="previousSeasonTableDataDriver"
                                        [ActualSeasonDriver]="actualSeasonTableDataDriver"
                                        [ComingSeasonDriver]="comingSeasonTableDataDriver"
                                        [FutureSeasonDriver]="futureSeasonTableDataDriver"
                                        [selectionOptions]="selectedOptions" [activeNode]="activeNode"
                                        [childNodes]="childNodes" [organizationSummaryData]="organizationSummaryData"
                                        *ngIf="totalSeasonTableRendered"></app-organization-summary-view>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </mat-drawer-container>
</div>