import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { CalculatorDriverInterface } from "../../calculators/calculator-driver-interface";
import { CalculatorInterface } from "../../calculators/calculator-interface";
import { CalculatorTimeSpan } from "../../calculators/calculator-timespan";
import { DiffFinPlanCalculator } from "../../calculators/fin-plan-calculators/diff-fin-plan-calculator";
import { DepartmentEvent } from "./department-event";
import { DepartmentEventType } from "./department-event-type";

export class FinPlanEvent extends DepartmentEvent {
    finPlanPattern: string = "^[0-9]+(.[0-9]{1})?$";

    getEventType(): DepartmentEventType {

        return DepartmentEventType.FinPlanMSek
    }

    validateEvent(driver: CalculatorDriverInterface): boolean {
        var regEx = new RegExp(this.finPlanPattern, "g");
        if (regEx.test(this.eventInfo.newValue.toString())) {
            return true;
        }
        else {
            let dataSet = driver.getDataSet();
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.InputFinPlanMSekWeekly] = null;
            dataSet[this.eventInfo.weekIndexes[0]][CalculationDataItemType.FinPlanMSek] = null;
            driver.setDataSet(dataSet);
            return false;
        }
    }

    applyEvent(driver: CalculatorDriverInterface) {
        let dataSet = driver.getDataSet();
        
        this.eventInfo.weekIndexes.forEach((weekIndex: number) => {
            dataSet[weekIndex][CalculationDataItemType.InputFinPlanMSekWeekly] = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
            dataSet[weekIndex][CalculationDataItemType.FinPlanMSek] = !isNaN(parseFloat(this.eventInfo.newValue.toString())) ? parseFloat(this.eventInfo.newValue.toString()) : null;
        });

        driver.setDataSet(dataSet);
    }

    configureCalculators(driver: CalculatorDriverInterface) {

        let calculatorConfiguration: CalculatorInterface[][] = [];

        let pass1Calculators: CalculatorInterface[] = [];

        pass1Calculators.push(new DiffFinPlanCalculator(CalculatorTimeSpan.Periodic, driver));

        calculatorConfiguration.push(pass1Calculators);

        driver.setCalculatorConfiguration(calculatorConfiguration);
    }
}