<div>
    <h2 mat-dialog-title>Purchase plans missing
    </h2>
    <mat-dialog-content style="max-height: 60vh; overflow-y: auto;">
        <mat-error>
            <span *ngIf="isErrorOccured" style="color:rgb(245, 33, 33);">
                {{errorMessage}}
            </span>
        </mat-error>
        <span style="text-align: left;"
            *ngIf="futureSeasonNegPurchaseWeeks.length > 0 || comingSeasonNegPurchaseWeeks.length > 0 ||
            comingSeasonNegCoverageWeeks.length > 0 || futureSeasonNegCoverageWeeks.length > 0">
            <h3>
                The below listed weeks and departments have purchase plans missing and cannot be published from Department plan.
            </h3>
        </span>
        <div *ngIf="comingSeasonNegPurchaseWeeks.length > 0" class="d-flex justify-content-center">
            <table class="table table-bordered" id="negative-coming-purchase-week-table">
                <caption>Missing purchase plan coming season</caption>
                <thead>
                    <tr>
                        <th> Season : {{comingSeasonNegPurchaseWeeks[0].seasonName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let comingSeasonNegPurchaseWeek of comingSeasonNegPurchaseWeeks; let dataIndex=index;">
                        <tr>
                            <td>{{comingSeasonNegPurchaseWeek.departmentName}}</td>
                        </tr>
                    </ng-container>

                    <tr>
                        <td>
                            <span style="font-weight:bold">
                                {{comingSeasonNegPurchaseWeek.departmentName}}
                            </span>
                            <ul style="margin-top: 10px;">
                                <ng-container
                                    *ngFor="let weekName of comingSeasonNegPurchaseWeek.weekNames; let dataIndex=index;">

                                    <li style="width: 25%;display: inline-block;">{{weekName}}</li>

                                </ng-container>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="futureSeasonNegPurchaseWeeks.length > 0">
            <table class="table table-bordered" id="negative-future-purchase-week-table">
                <caption>Missing purchase plan future season</caption>
                <thead>
                    <tr>
                        <th>Season : {{futureSeasonNegPurchaseWeeks[0].seasonName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let futureSeasonNegPurchaseWeek of futureSeasonNegPurchaseWeeks; let dataIndex=index;">
                        <tr>
                            <td>
                                <span style="font-weight:bold">
                                    {{futureSeasonNegPurchaseWeek.departmentName}}
                                </span>
                                <ul style="margin-top: 10px;">
                                    <ng-container
                                        *ngFor="let weekName of futureSeasonNegPurchaseWeek.weekNames; let dataIndex=index;">

                                        <li style="width: 25%;display: inline-block;">{{weekName}}</li>

                                    </ng-container>
                                </ul>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
        </div>

        <div *ngIf="comingSeasonNegCoverageWeeks.length > 0" class="d-flex justify-content-center">
            <table class="table table-bordered" id="negative-coming-coverage-week-table">
                <caption>Missing coverage coming season</caption>
                <thead>
                    <tr>
                        <th> Season : {{comingSeasonNegCoverageWeeks[0].seasonName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let comingSeasonNegCoverageWeek of comingSeasonNegCoverageWeeks; let dataIndex=index;">
                        <tr>
                            <td>
                                <span style="font-weight:bold">
                                    {{comingSeasonNegCoverageWeek.departmentName}}
                                </span>
                                <ul style="margin-top: 10px;">
                                    <ng-container
                                        *ngFor="let weekName of comingSeasonNegCoverageWeek.weekNames; let dataIndex=index;">

                                        <li style="width: 25%;display: inline-block;">{{weekName}}</li>

                                    </ng-container>
                                </ul>
                            </td>
                        </tr>

                    </ng-container>
                </tbody>
            </table>
        </div>

        <div *ngIf="futureSeasonNegCoverageWeeks.length > 0">
            <table class="table table-bordered" id="negative-future-coverage-week-table">
                <caption>Missing coverage future season</caption>
                <thead>
                    <tr>
                        <th>Season : {{futureSeasonNegCoverageWeeks[0].seasonName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container
                        *ngFor="let futureSeasonNegCoverageWeek of futureSeasonNegCoverageWeeks; let dataIndex=index;">
                        <tr>
                            <td>
                                <span style="font-weight:bold">
                                    {{futureSeasonNegCoverageWeek.departmentName}}
                                </span>
                                <ul style="margin-top: 10px;">
                                    <ng-container
                                        *ngFor="let weekName of futureSeasonNegCoverageWeek.weekNames; let dataIndex=index;">

                                        <li style="width: 25%;display: inline-block;">{{weekName}}</li>

                                    </ng-container>
                                </ul>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>

        <span style="text-align: left;">
            <h3 style="display: inline-block;">
                Would you like to save anyway?
            </h3>
            <button class="toolbar-left-icons" mat-icon-button title="Copy List" (click)="copyListToClipboard()">
                <mat-icon>content_copy</mat-icon>
            </button>
        </span>
        <mat-dialog-actions align="center">
            <button type="button" mat-raised-button color="primary" [mat-dialog-close]="true">Yes</button>
            <button mat-raised-button mat-dialog-close>Cancel</button>
        </mat-dialog-actions>
    </mat-dialog-content>
</div>