import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalculatorDriverInterface } from 'src/app/modules/shared/calculators/calculator-driver-interface';
import { ClearAllDialogData } from '../../../selection-view/types/clear-all-dialog-data';
import { SeasonTableComponent } from '../season-table/season-table.component';

@Component({
  selector: 'omnitdp-clear-all-dialog',
  templateUrl: './clear-all-dialog.component.html',
  styleUrls: ['./clear-all-dialog.component.css']
})
export class ClearAllDialogComponent {

  driver: CalculatorDriverInterface = null;
  seasonName: string;

  constructor(
    public seasonParentDialogRef: MatDialogRef<SeasonTableComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ClearAllDialogData) {
    if (dialogData) {

      this.driver = <CalculatorDriverInterface>dialogData.driverDialogDataItem;
      this.seasonName = dialogData.seasonName;
    }
  }

  ngOnInit(): void {

  }
}
