import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { AbstractCalculator } from "../abstract-calculator";

export class SalesForecastCalculator extends AbstractCalculator {

    // Dependencies
    // ------------
    // Effective Sales Plan Weekly (calculated)
    // Gross Sales Ground (raw)
    // Start week sales gross ground (raw)
    // Forecast Goal Periodic (calculated)

    // Calculates
    // ----------
    // Sales Forecast Weekly (calculated)

    _calculateWeeklyValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[], previousWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem) {
        // default to null
        let value = 0;

        let utils = this.calculatorDriver.getUtilsService();
        let startWeekForCurrentSeason = utils.getStartWeekForSeason(parseInt(this.calculatorDriver.getSeasonInfo().seasonCodeNames[0]))

        let grossSalesGround = 0;
        if (utils.isNotNullUndefinedOrZero(currentWeekItem[CalculationDataItemType.GrossSalesGround]))
            grossSalesGround = currentWeekItem[CalculationDataItemType.GrossSalesGround];

        if (currentWeekItem[CalculationDataItemType.StartWeekAfterCurrentWeek])
            grossSalesGround += currentWeekItem[CalculationDataItemType.StartWeekSalesGrossGround];

        // check if there is value in the input forecast field and if the gross ground is not zero

        // if the current week is 1 week before start week
        if (currentWeekItem[CalculationDataItemType.WeekName] + 1 == startWeekForCurrentSeason) {
            let startWeekItem = this.calculatorDriver.getDataSetRange(startWeekForCurrentSeason, startWeekForCurrentSeason)[0];

            let nextPeriodGoal = startWeekItem[CalculationDataItemType.ForecastGoalPeriodic];
            value = startWeekItem[CalculationDataItemType.StartWeekSalesGrossGround] * nextPeriodGoal / 100;
        }
        // for all the weeks before the start week - 1
        else if (currentWeekItem[CalculationDataItemType.WeekName] + 1 < startWeekForCurrentSeason) {
            value = 0;
        }
        // for all weeks after start week
        else {
            // get the combined sales value for this week
            value = (currentWeekItem[CalculationDataItemType.ForecastGoalPeriodic] / 100) * grossSalesGround;
        }

        currentWeekItem[CalculationDataItemType.SalesForecastWeekly] = value;
    }
}
