import { UtilsService } from "src/app/modules/shared/services/utils.service";
import { SeasonInfo } from "src/app/modules/shared/types/season-info";
import { CalculationDataItemType } from "../../../types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "../../../types/department-calculation-data-item";
import { ParentCalculationDataItem } from "../../../types/parent-calculation-data-item";

export class SeasonDataAggregator {

    static aggregate(dataSets: DepartmentCalculationDataItem[][], fields: CalculationDataItemType[], seasonInfo: SeasonInfo, utilsService: UtilsService): DepartmentCalculationDataItem[] {
        if (dataSets == null || dataSets.length == 0) {
            return null;
        }
        else if (dataSets.length == 1) {
            return dataSets[0];
        }
        else {
            let sumDataSet: DepartmentCalculationDataItem[] = [];

            let sumDataSetItem: DepartmentCalculationDataItem = null;
            //Dataset[1] refers actual season , we can base it on Actual so that Coming, future, Previous & Old should also have the same weeks
            let weekList = dataSets[1].map(el => el.weekName);
            let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);


            // iterate over each week
            uniqueWeekList.forEach((week: number, index: number) => {

                sumDataSetItem = {
                    weekName: week
                }

                // each field per week
                fields.forEach((field: CalculationDataItemType) => {
                    if(seasonInfo.seasonPlanningType === "PreviousWithOld" && field === CalculationDataItemType.DemDelPlan){
                        utilsService
                        .replaceFieldInSeasonWeekDataWithoutValueWithRelatedFieldValue(dataSets, CalculationDataItemType.DemDelPlan,
                             [CalculationDataItemType.CubeDemDelPlan, CalculationDataItemType.GrossSalesGround], index);
                    }    
                    // reset sum value
                    let sumValue: number = null;
                    let aggregatedPMIsSaved: boolean = false;
                    // loop each dataset for each field per week
                    dataSets.forEach((dataSetItems: DepartmentCalculationDataItem[]) => {

                        // get the item corresponding to the current week from the current dataset. Get its value for the field in scope
                        if (utilsService.isNotNullOrUndefined(dataSetItems[index][field])) {

                            if (field === 'aggregatedPMsIsSaved' && !aggregatedPMIsSaved) {
                                aggregatedPMIsSaved = (dataSetItems[index][field] != null && dataSetItems[index][field] != undefined) ? Boolean(dataSetItems[index][field]) : false;
                            }
                            else {
                                if (seasonInfo.seasonPlanningType === "PreviousWithOld" && (field === 'addMovesMSek' || field === 'addRemoveMSek')) {
                                    sumValue = parseFloat(dataSetItems[index][field].toString());
                                }
                                else {
                                    // sum it up
                                    sumValue += parseFloat(dataSetItems[index][field].toString());
                                }
                            }
                        }
                    });

                    if (field === 'aggregatedPMsIsSaved') {
                        sumDataSetItem[field] = aggregatedPMIsSaved;
                    }
                    else {
                        sumDataSetItem[field] = !isNaN(sumValue) ? sumValue : null;
                    }
                });

                sumDataSet.push(sumDataSetItem);
            });

            return sumDataSet;
        }
    }

    static aggregateParent(dataSets: ParentCalculationDataItem[][], fields: CalculationDataItemType[], seasonInfo: SeasonInfo, utilsService: UtilsService): ParentCalculationDataItem[] {
        if (dataSets == null || dataSets.length == 0) {
            return null;
        }
        else if (dataSets.length == 1) {
            return dataSets[0];
        }
        else {
            let sumDataSet: ParentCalculationDataItem[] = [];

            let sumDataSetItem: ParentCalculationDataItem = null;

            let weekList = dataSets[1].map(el => el.weekName);
            let uniqueWeekList: number[] = weekList.filter((item, i, ar) => ar.indexOf(item) === i);

            // iterate over each week
            uniqueWeekList.forEach((week: number, index: number) => {

                sumDataSetItem = {
                    weekName: week
                }

                // each field per week
                fields.forEach((field: CalculationDataItemType) => {

                    // reset sum value
                    let sumValue: number = null;
                    let aggregatedPMIsSaved: boolean = false;

                    // loop each dataset for each field per week
                    dataSets.forEach((dataSetItems: ParentCalculationDataItem[]) => {

                        // get the item corresponding to the current week from the current dataset. Get its value for the field in scope
                        if (utilsService.isNotNullOrUndefined(dataSetItems[index][field])) {

                            if (field === 'aggregatedPMsIsSaved' && !aggregatedPMIsSaved) {
                                aggregatedPMIsSaved = (dataSetItems[index][field] != null && dataSetItems[index][field] != undefined) ? Boolean(dataSetItems[index][field]) : false;
                            }
                            else {
                                if (seasonInfo.seasonPlanningType === "PreviousWithOld" && (field === 'addMovesMSek' || field === 'addRemoveMSek')) {
                                    sumValue = parseFloat(dataSetItems[index][field].toString());
                                }
                                else {
                                    // sum it up
                                    sumValue += parseFloat(dataSetItems[index][field].toString());
                                }
                            }

                        }
                    });

                    if (field === 'aggregatedPMsIsSaved') {
                        sumDataSetItem[field] = aggregatedPMIsSaved;
                    }
                    else {
                        sumDataSetItem[field] = !isNaN(sumValue) ? sumValue : null;
                    }
                });

                sumDataSet.push(sumDataSetItem);
            });

            return sumDataSet;
        }
    }
}