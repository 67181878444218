<ng-container *ngIf="isCopyComponentReady; then copiesLoaded; else copiesLoading">
</ng-container>
<ng-template #copiesLoading>
    <omnitdp-skeleton-loader [skeletonType]="'copies'"></omnitdp-skeleton-loader>
</ng-template>
<ng-template #copiesLoaded>
    <div class="col-12">
        <span class="control-title">Copies<span class="required-asterisk">*</span></span>
        <div class="row" *ngIf="areCopiesAccessible">
            <div class="col-md-5">
                <mat-selection-list [multiple]="false" class="background-list user-copy-list"
                    [hideSingleSelectionIndicator]="true" [(ngModel)]="selectedCopyList"
                    (selectionChange)="changeCopy()">
                    <mat-list-option *ngFor="let copy of copies" [value]="copy" [hidden]="isSumSimView && copy.isMain">
                        <span [ngStyle]="{'font-weight':copy.isMain ? ' bold' : 'normal'}"> {{copy.name}}</span>
                    </mat-list-option>
                </mat-selection-list>
                <mat-checkbox color="primary" class="checkbox-show-created-by-others"
                    [(ngModel)]="selectedShowCopyByOthers" (change)="changeShowCopiesOfOthers($event)">
                    <span class="control-sub-title">Show copies created by others</span>
                </mat-checkbox>
            </div>
            <div class="col-md-1 copy-controls">
                <div class="row">
                    <button matTooltip="Create Copy" id="createCopy" (click)="openCreateCopyDialog()"
                        [disabled]="areCopyActionButtonsDisabled" class="copy-controls-button" mat-raised-button
                        [hidden]="!selectedCopyList[0].isMain && !isSumSimView">
                        <mat-icon>content_copy</mat-icon>
                    </button>
                </div>
                <div class="row">
                    <button matTooltip="Rename Copy" (click)="openEditCopyCopyDialog()"
                        [disabled]="areCopyActionButtonsDisabled" [hidden]="selectedCopyList[0].isMain"
                        class="copy-controls-button" mat-raised-button>
                        <mat-icon>drive_file_rename_outline</mat-icon>
                    </button>
                </div>
                <div class="row">
                    <button matTooltip="Delete Copy" (click)="openDeleteCopyCopyDialog()"
                        [disabled]="areCopyActionButtonsDisabled"
                        [hidden]="(selectedCopyList[0].isMain || selectedCopyList[0].createdBy !=copyCreatedUserEmail)"
                        class="copy-controls-button" mat-raised-button>
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <div class="row" [matTooltipDisabled]="!isEnabledForAnaplan" #tooltip="matTooltip"
                    matTooltip="{{getMergeToolTipForAssortment()}}">
                    <button (click)="openMergeCopyCopyDialog()"
                        [disabled]="isEnabledForAnaplan || areCopyActionButtonsDisabled || selectedCopyList[0].isMerged || isMergeButtonDisabled"
                        [hidden]="(selectedCopyList[0].isMain) || isSumSimView" class="copy-controls-button"
                        mat-raised-button>
                        <mat-icon>merge</mat-icon>
                    </button>
                </div>
                <div class="row" [matTooltipDisabled]="!isEnabledForAnaplan" #tooltip="matTooltip"
                    matTooltip="{{getPublishToolTipForAssortment()}}">
                    <button matTooltip="Publish Copy" (click)="openPublishDepartmentPlanDialog()"
                        [disabled]="isEnabledForAnaplan || areCopyActionButtonsDisabled" [hidden]="(
                                    !selectedCopyList[0].isMain 
                                    ||
                                    selectedCopyList[0].assortmentCopyId == 0
                                    ||
                                    organization.name !='Assortment' 
                                    ||
                                    (
                                        selectedCopyList[0].assortmentCopyId > 0 
                                        &&
                                        selectedCopyList[0].modifiedBy == '' 
                                    ) 
                                    && 
                                    isSumSimView)" class="copy-controls-button" mat-raised-button>
                        <mat-icon>publish</mat-icon>
                    </button>
                </div>
            </div>
            <div class="col-md-6" *ngIf="selectedCopyList.length == 1"
                [hidden]="isSumSimView && selectedCopyList[0].isMain">
                <mat-card class="copy-card">
                    <mat-card-content class="copy-card-contents">
                        <div class="copy-card-title">Copy Details - {{selectedCopyList[0].name}}</div>
                        <hr class="copy-card-hr">
                        <table class="copy-card-table">
                            <tr class="copy-card-table-row">
                                <td class="copy-card-table-row-cell">
                                    Created
                                </td>
                                <td class="copy-card-table-row-cell">
                                    <ul class="copy-card-table-list">
                                        <li class="copy-card-table-list-item">
                                            <ng-container
                                                *ngIf="selectedCopyList[0].isMain; then createdBySystem; else createdByUser">
                                            </ng-container>
                                            <ng-template #createdBySystem>System</ng-template>
                                            <ng-template #createdByUser>{{selectedCopyList[0].createdBy}}</ng-template>
                                        </li>
                                        <li class="copy-card-table-list-item">
                                            {{formatDate(selectedCopyList[0].createdTime)}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr class="copy-card-table-row">
                                <td class="copy-card-table-row-cell">
                                    Modified
                                </td>
                                <td class="copy-card-table-row-cell">
                                    <ul class="copy-card-table-list">
                                        <li class="copy-card-table-list-item">
                                            {{selectedCopyList[0].modifiedBy}}
                                        </li>
                                        <li class="copy-card-table-list-item">
                                            {{formatDate(selectedCopyList[0].modifiedTime)}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>
                            <tr class="copy-card-table-row " *ngIf="!selectedCopyList[0].isMain"
                                [hidden]="isSumSimView">
                                <td class="copy-card-table-row-cell">
                                    Merged
                                </td>
                                <td class="copy-card-table-row-cell">
                                    <ul class="copy-card-table-list">
                                        <li class="copy-card-table-list-item" *ngIf="selectedCopyList[0].isMerged">
                                            {{selectedCopyList[0].mergedBy}}
                                        </li>
                                        <li class="copy-card-table-list-item" *ngIf="selectedCopyList[0].isMerged">
                                            {{formatDate(selectedCopyList[0].mergedTime)}}
                                        </li>
                                        <li class="copy-card-table-list-item" *ngIf="!selectedCopyList[0].isMerged">
                                            Not Merged
                                        </li>

                                    </ul>
                                </td>
                            </tr>

                            <tr class="copy-card-table-row"
                                *ngIf="!selectedCopyList[0].isMain && organization.name=='Sales' && mainMarket.marketType == 2">
                                <td class="copy-card-table-row-cell">
                                    Access
                                </td>
                                <td class="copy-card-table-row-cell">
                                    <ul class="copy-card-table-list">
                                        <li class="copy-card-table-list-item" *ngIf="!selectedCopyList[0].isPRCopy">
                                            {{selectedCopyList[0].pmName}}
                                        </li>
                                        <li class="copy-card-table-list-item" *ngIf="selectedCopyList[0].isPRCopy">
                                            {{mainMarket.shortName}}
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                            <tr class="copy-card-table-row"
                                *ngIf="!selectedCopyList[0].isMain && organization.name=='Sales'">
                                <td class="copy-card-table-row-cell">
                                    Lock
                                </td>
                                <td class="copy-card-table-row-cell">
                                    <ul class="copy-card-table-list">
                                        <li class="copy-card-table-list-item" *ngIf="!selectedCopyList[0].isLocked"
                                            title="Not locked">
                                            <mat-icon>lock_open</mat-icon>
                                        </li>
                                        <li class="copy-card-table-list-item" *ngIf="selectedCopyList[0].isLocked"
                                            title="Locked">
                                            <mat-icon>lock</mat-icon>
                                        </li>
                                    </ul>
                                </td>
                            </tr>

                        </table>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div class="row" *ngIf="!areCopiesAccessible">
            <span class="validation-message">You dont have access to copies</span>
        </div>
    </div>
</ng-template>