import { CalculationDataItemType } from "src/app/modules/planning-view/types/calculation-data-item-type";
import { DepartmentCalculationDataItem } from "src/app/modules/planning-view/types/department-calculation-data-item";
import { ParentCalculationDataItem } from "src/app/modules/planning-view/types/parent-calculation-data-item";
import { SeasonPlanningType } from "../../types/season-planning-type";
import { AbstractCalculator } from "../abstract-calculator";

export class ForecastGoalCalculator extends AbstractCalculator {
    // Dependencies
    // ------------
    // Gross Sales Ground
    // Start Week Sales Gross Ground
    // Cube DemDel Plan

    // Calculates
    // ----------
    // Forecast Goal Periodic

    _calculatePeriodicValue(currentWeekItem: DepartmentCalculationDataItem | ParentCalculationDataItem, currentPeriodWeekItems: DepartmentCalculationDataItem[] | ParentCalculationDataItem[]) {
        let value = 0;
        let grossSalesGroundPeriodSum = 0;
        let effectivePlanSum = 0;

        let utils = this.calculatorDriver.getUtilsService();

        currentPeriodWeekItems.forEach(item => {
            if (utils.isNotNullUndefinedOrZero(item[CalculationDataItemType.GrossSalesGround]))
                grossSalesGroundPeriodSum += item[CalculationDataItemType.GrossSalesGround];
        });

        if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal])) {
            value = currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodicOriginal];
        }

        else if (utils.isNotNullOrUndefined(currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodic])) {
            value = currentWeekItem[CalculationDataItemType.InputForecastGrossPeriodic];
        }

        else {

            effectivePlanSum = utils.sumKpiForPeriod(currentPeriodWeekItems, CalculationDataItemType.EffectiveSalesPlanWeekly);

            if (utils.isNotNullUndefinedOrZero(currentPeriodWeekItems[0][CalculationDataItemType.StartWeekSalesGrossGround]) && 
                currentPeriodWeekItems[0][CalculationDataItemType.StartWeekAfterCurrentWeek] &&
                this.calculatorDriver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Coming &&
                this.calculatorDriver.getSeasonInfo().seasonPlanningType != SeasonPlanningType.Future
                )
                grossSalesGroundPeriodSum += currentPeriodWeekItems[0][CalculationDataItemType.StartWeekSalesGrossGround]

            // calculate forecast goal
            if (utils.isNotNullUndefinedOrZero(grossSalesGroundPeriodSum)) {
                value = effectivePlanSum / grossSalesGroundPeriodSum * 100;
            }
        }

        currentPeriodWeekItems.forEach((periodWeekItem: DepartmentCalculationDataItem) => {
            periodWeekItem[CalculationDataItemType.ForecastGoalPeriodic] = value;
        });
    }
}
